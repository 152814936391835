import Button, { ButtonProps } from '@atlaskit/button'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as _Icon } from './Maxio.svg'

const Icon = styled(_Icon)`
  width: 20px;
  height: 20px;
`

type MaxioButtonProps = ButtonProps & {
  maxioUrl: string
}

const MaxioLinkButton = ({ maxioUrl, ...props }: MaxioButtonProps) => (
  <Button
    {...props}
    appearance={'subtle'}
    iconBefore={<Icon />}
    target={'_blank'}
    href={maxioUrl}
  />
)

export default MaxioLinkButton
