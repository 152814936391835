export const ZIRTUAL_CHURN_REASONS = [
  'Cancellation',
  'Hired In House - Outsourced',
  'Hired In House - Needed More Coverage',
  'Budgetary - Cost Too High',
  'Budgetary - Company Cutting Cost',
  'Scope - Needs Changed',
  'Scope - Z Model / Working Hours / Physical Tasking',
  'Scope - HIPPAA',
  'Scope - Finance',
  'Scope - Data Security',
  'Scope - Cold Calling / Call Service / Inbound Calling',
  'Scope - Content Creation',
  'Scope - HR/ interviews/recruiting',
  'Scope - Client Combative / Refuse to Work Within',
  'Bad Sale - Scope',
  'Bad Sale - Hours of Operation / Z Model',
  'Bad Sale - Not Ready to Start',
  'No Response - Sign Up',
  'No Response - New Client Post Intro',
  'No Response - Established Client',
  'No Response - After Transfer',
  'No Response - After VA Resignation',
  'No Response - ZAway',
  'VA Buyout',
  'VA Performance - VA Terminated',
  'VA Performance - Medical/Personal Absence',
  'VA Performance - VA Resignation',
  'VA Performance - VA Resignation No Notice',
  'VA Performance - Poor Service Experience',
  'VA Performance - No Value',
  'VA Performance - Two Transfers',
  'VA Performance - Three Transfers',
  'VA Performance - Four Transfers +',
  'Bad Client / Client Fired - Abusive / Disrespectful',
  'Bad Client - Chargeback Threat',
  'Bad Client - Does Not Understand Z Model',
  'Bad Client - Request 2+ VA Transfers',
  'Bad Client - Nonpayment',
  'Business Change - Contact Left Company',
  'Business Change - Company Merger/Buyout',
  'Business Change - Business Closing',
  'Business Change - Restructure',
  "Don't Need - No More Tasks",
  "Don't Need - Short Term Use",
  "Don't Need - No Delegation",
  "Don't Need - Declined to provide",
  'Administrative - Combining Plans',
  'Administrative - Changing Plans',
  'Administrative - Error',
  'Administrative - Duplicate',
]
