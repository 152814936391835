import { DatePicker } from '@atlaskit/datetime-picker'
import { RadioGroup } from '@atlaskit/radio'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, ComponentProps } from 'react'
import { useHistory } from 'react-router-dom'

import {
  MultiAirtableLeadSelect,
  SingleAirtableLeadSelect,
} from '../../../../components/AirtableLeadSelect'
import Modal from '../../../../components/Modal'
import PricingSelect from '../../../../components/PricingSelect'
import { LoadingSpinner } from '../../../../components/Spinner'
import Toggle from '../../../../components/Toggle'
import { Field, TextField } from '../../../../components/form'
import TeamSelect from '../../../../components/teams/Select'
import UserCell from '../../../../components/users/Cell'
import { renderTextWithLineBreaks } from '../../../../components/utils'
import {
  AirtableLead_BundlePrincipalFragment,
  useCreateMatchingFromLeadMutation,
  useListPricingsQuery,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'

import {
  DatePickerContainer,
  FieldsRow,
  Outer,
  Panel,
  PanelInner,
  PanelTitle,
  ToggleContainer,
} from './styled'
import useCreateMatchingModalTeam, {
  TEAM_RADIO_OPTIONS,
  TeamRadioValue,
} from './useCreateMatchingModalTeam'

const DATE_PICKER_MIN_DATE = DateTime.now().toISODate()

type ConfigurationValues = {
  matchDueDate: string | undefined
  internalMatchingDate: string | undefined
  isHighTouch: boolean
  isHiddenFromCatalog: boolean
  assignedPricingPlanId: string | null
  maxioSubscriptionId: string | undefined
}

type Props = ComponentProps<typeof Modal> & {
  refetchMatchingsList: () => void
}

const CreateMatchingModal = ({
  refetchMatchingsList,
  ...modalProps
}: Props) => {
  const history = useHistory()

  const { data: pricingsData, loading: listPricingsLoading } =
    useListPricingsQuery()

  // Configuration values
  const [
    {
      matchDueDate,
      internalMatchingDate,
      isHighTouch,
      isHiddenFromCatalog,
      assignedPricingPlanId,
      maxioSubscriptionId,
    },
    {
      reset: resetConfigValues,
      setters: configSetters,
      isValid: isConfigValid,
    },
  ] = useValues<ConfigurationValues>(
    {
      matchDueDate: undefined,
      internalMatchingDate: undefined,
      isHighTouch: false,
      isHiddenFromCatalog: true,
      assignedPricingPlanId: null,
      maxioSubscriptionId: undefined,
    },
    [
      'matchDueDate',
      'internalMatchingDate',
      'isHighTouch',
      'isHiddenFromCatalog',
      'assignedPricingPlanId',
      'maxioSubscriptionId',
    ],
    {
      validate: (configValues) => {
        const { matchDueDate, internalMatchingDate } = configValues

        return Boolean(matchDueDate && internalMatchingDate)
      },
    },
  )

  const updateBundlePrincipalConfigValues = useCallback(
    (leadRecord: AirtableLead_BundlePrincipalFragment) => {
      const { fields } = leadRecord

      // update matching config data with new bundle principal lead details
      const matchDueDateValue = fields['Match Due Date'] as string | undefined
      const internalMatchingDateValue = fields['Internal Matching Date'] as
        | string
        | undefined
      if (matchDueDateValue) {
        configSetters.matchDueDate(matchDueDateValue)
      }
      if (internalMatchingDateValue) {
        configSetters.internalMatchingDate(internalMatchingDateValue)
      }

      const isHighTouchValue = Boolean(fields['High Touch Match?'])
      configSetters.isHighTouch(isHighTouchValue)

      const planAssignedAtSignUp = fields['Plan assigned at sign up'] as
        | string
        | null
      if (planAssignedAtSignUp && pricingsData) {
        const pricing = pricingsData.list.items.find(
          (pricing) => pricing.ref === planAssignedAtSignUp,
        )
        pricing && configSetters.assignedPricingPlanId(pricing.id)
      }

      configSetters.maxioSubscriptionId(
        fields['[Eng] Maxio Subscription Id'] || undefined,
      )
    },
    [configSetters, pricingsData],
  )

  // Team values
  const {
    teamValues: {
      bundlePrincipalId,
      bundlePrincipalUserId,
      plusOneIds,
      existingTeamId,
      newTeamName,
      teamPrincipalUserId,
      teamRadioValue,
    },
    teamSetters,
    resetTeamValues,
    onSelectTeam,
    onSelectBundlePrincipalLead,
    onSelectAirtableTeammate,
    isCreatingNewTeam,
    isTeamValid,
    loading: teamLoading,
  } = useCreateMatchingModalTeam({
    onBundlePrincipalChange: updateBundlePrincipalConfigValues,
  })

  const [createMatchingFromLead, { loading: createMatchingLoading }] =
    useCreateMatchingFromLeadMutation({
      onCompleted: ({ createMatchingFromLead: { matching } }) => {
        refetchMatchingsList()
        modalProps.onClose?.()
        history.push(`/matchings/${matching.id}`)
      },
    })

  const reset = useCallback(() => {
    resetTeamValues()
    resetConfigValues()
  }, [resetConfigValues, resetTeamValues])

  const isValidForm = useMemo(
    () =>
      isTeamValid &&
      isConfigValid &&
      (!isCreatingNewTeam || maxioSubscriptionId || assignedPricingPlanId),
    [
      isTeamValid,
      isConfigValid,
      isCreatingNewTeam,
      maxioSubscriptionId,
      assignedPricingPlanId,
    ],
  )

  const onSubmit = useCallback(() => {
    if (
      !(isCreatingNewTeam
        ? bundlePrincipalId && (maxioSubscriptionId || assignedPricingPlanId)
        : plusOneIds.length && existingTeamId) ||
      !matchDueDate ||
      !internalMatchingDate
    )
      return

    if (isCreatingNewTeam && bundlePrincipalUserId) {
      const alert = window.confirm(
        'This lead already has an associated user in Cockpit. This should ' +
          'only be the case if this is a new matching for an existing user or ' +
          'a client returning from churn.\n\nDo you want to continue?',
      )

      if (!alert) {
        return
      }
    }

    const bundlePrincipalRecordId =
      // based on the early return above, we know that at least one of these will be defined
      (isCreatingNewTeam ? bundlePrincipalId : plusOneIds[0]) as string
    const plusOneRecordIds = isCreatingNewTeam
      ? plusOneIds
      : plusOneIds.slice(1)

    createMatchingFromLead({
      variables: {
        input: {
          bundlePrincipalRecordId,
          plusOneRecordIds,
          newTeamName,
          addToTeamId: existingTeamId,
          matchDueDate,
          internalMatchingDate,
          isHighTouch,
          isHiddenFromCatalog,
          pricingId: assignedPricingPlanId,
          maxioSubscriptionId,
        },
      },
    })
  }, [
    isCreatingNewTeam,
    bundlePrincipalId,
    maxioSubscriptionId,
    assignedPricingPlanId,
    plusOneIds,
    existingTeamId,
    matchDueDate,
    internalMatchingDate,
    bundlePrincipalUserId,
    createMatchingFromLead,
    newTeamName,
    isHighTouch,
    isHiddenFromCatalog,
  ])

  const loading = teamLoading || createMatchingLoading || listPricingsLoading

  return (
    <Modal
      onCloseComplete={reset}
      width={'large'}
      heading={'Create new matching'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm,
          isLoading: loading,
        },
        { text: 'Cancel', onClick: modalProps.onClose },
      ]}
      {...modalProps}
    >
      <Outer>
        <Panel>
          <PanelTitle>{'Team Configuration'}</PanelTitle>
          <PanelInner>
            <RadioGroup
              value={teamRadioValue}
              onChange={(e) => {
                if (e.target.value === TeamRadioValue.NEW_TEAM) {
                  teamSetters.existingTeamId(null)
                } else {
                  teamSetters.bundlePrincipalId(null)
                  teamSetters.newTeamName(undefined)
                }

                resetConfigValues()

                teamSetters.teamRadioValue(e.target.value as TeamRadioValue)
              }}
              options={TEAM_RADIO_OPTIONS}
            />
            {!isCreatingNewTeam ? (
              <>
                <Field label={'Team'} isRequired>
                  <TeamSelect
                    value={existingTeamId ? { id: existingTeamId } : null}
                    onChange={(team) => {
                      onSelectTeam(team?.id)
                    }}
                    isClearable
                  />
                </Field>
                {teamPrincipalUserId && (
                  <Field
                    label={'Team Principal User'}
                    helperMessage={
                      'This user will NOT be bundled in the matching.'
                    }
                    isDisabled
                  >
                    <UserCell
                      user={
                        teamPrincipalUserId
                          ? { id: teamPrincipalUserId }
                          : undefined
                      }
                      size={'small'}
                    />
                  </Field>
                )}
              </>
            ) : (
              <Field
                label={'Bundle Team Principal'}
                helperMessage={
                  'This user will be created and set as the team principal'
                }
                isRequired
              >
                <SingleAirtableLeadSelect
                  value={bundlePrincipalId ? { id: bundlePrincipalId } : null}
                  onChangeValue={onSelectBundlePrincipalLead}
                />
              </Field>
            )}
            <Field
              label={"Bundle +1's"}
              helperMessage={renderTextWithLineBreaks(
                `These teammates will be bundled in the matching${
                  !isCreatingNewTeam ? ' and added to the existing team' : ''
                }\n(click to open lead card)`,
              )}
              isRequired={!isCreatingNewTeam}
            >
              <MultiAirtableLeadSelect
                ignoreLeadIds={bundlePrincipalId ? [bundlePrincipalId] : []}
                value={plusOneIds.map((id) => ({ id }))}
                onChangeValues={onSelectAirtableTeammate}
                maxMenuHeight={160}
              />
            </Field>
            {isCreatingNewTeam && (
              <Field label={'Team Name'}>
                <TextField
                  value={newTeamName}
                  onChangeValue={teamSetters.newTeamName}
                />
              </Field>
            )}
          </PanelInner>
        </Panel>
        <Panel>
          <PanelTitle>{'Matching Configuration'}</PanelTitle>
          <PanelInner>
            {isCreatingNewTeam && (
              <Field label={'Plan assigned'} isRequired>
                {!maxioSubscriptionId ? (
                  <PricingSelect
                    valueId={assignedPricingPlanId}
                    onChangeValueId={configSetters.assignedPricingPlanId}
                    excludeExceptionPricing
                  />
                ) : (
                  <div>{'On Maxio Billing Plan'}</div>
                )}
              </Field>
            )}
            <FieldsRow>
              <Field label={'Match Due Date'} isRequired>
                <DatePickerContainer>
                  <DatePicker
                    placeholder={'--/--/----'}
                    minDate={DATE_PICKER_MIN_DATE}
                    value={matchDueDate}
                    onChange={configSetters.matchDueDate}
                  />
                </DatePickerContainer>
              </Field>
              <Field label={'Internal Matching Date'} isRequired>
                <DatePickerContainer>
                  <DatePicker
                    placeholder={'--/--/----'}
                    minDate={DATE_PICKER_MIN_DATE}
                    value={internalMatchingDate}
                    onChange={configSetters.internalMatchingDate}
                  />
                </DatePickerContainer>
              </Field>
            </FieldsRow>
            <FieldsRow>
              <Field label={'Is high touch?'} isRequired>
                <ToggleContainer>
                  <Toggle
                    isChecked={isHighTouch}
                    onChange={(e) =>
                      configSetters.isHighTouch(e.target.checked)
                    }
                  />
                </ToggleContainer>
              </Field>
              <Field label={'Exclude from catalog?'} isRequired>
                <ToggleContainer>
                  <Toggle
                    isChecked={isHiddenFromCatalog}
                    onChange={(e) =>
                      configSetters.isHiddenFromCatalog(e.target.checked)
                    }
                  />
                </ToggleContainer>
              </Field>
            </FieldsRow>
          </PanelInner>
        </Panel>

        <LoadingSpinner show={loading} />
      </Outer>
    </Modal>
  )
}

export default CreateMatchingModal
