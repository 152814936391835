import Panel from '@atlaskit/panel'

import DateFormater from '../../../../components/DateFormater'
import { ErrorEmptyState } from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import TimelineContainer from '../../../../components/timeline/TimelineContainer'
import {
  Outer as TimelineOuter,
  Icon,
  IconOuter,
  Line,
  Step,
  StepHeader,
  StepTitle,
  StepContent,
} from '../../../../components/timeline/styled'
import useTimeline from '../../../../components/timeline/useTimeline'
import { TimelineEvent, useGetTeamTimelineQuery } from '../../../../graphql'
import useLocalStorage from '../../../../lib/useLocalStorage'

type Props = {
  teamId: string
}

const EVENTS_EXPANDED_DEFAULT = [
  TimelineEvent.WORKSPACE_CREATED,
  TimelineEvent.WORKSPACE_ARCHIVED,
]

const TITLES_HIDDEN_BY_DEFAULT: string[] = []

const Timeline = ({ teamId }: Props) => {
  const [excludeList, setExcludeList, clearExcludeList] = useLocalStorage<
    TimelineEvent[]
  >({
    key: 'teamTimelineFilterExcludes',
    initialValue: [],
  })

  const { data, loading, error } = useGetTeamTimelineQuery({
    variables: {
      teamId,
      excludes: excludeList,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { sortedItems, lastItemIndex, isSortedItemsEmpty, uniqueCategories } =
    useTimeline(data?.teamTimelineItemsList.items, excludeList)

  if (error) return <ErrorEmptyState error={error} />

  return (
    <TimelineContainer
      uniqueCategories={uniqueCategories}
      excludeList={excludeList}
      setExcludeList={setExcludeList}
      clearExcludeList={clearExcludeList}
    >
      <TimelineOuter>
        {isSortedItemsEmpty ? (
          loading ? (
            <LoadingSpinner />
          ) : (
            <ErrorEmptyState error={new Error('Woops, there is no data')} />
          )
        ) : (
          sortedItems.map((item, index) => (
            <Step key={`event-${index}`}>
              <IconOuter>
                <Icon $filled>{item.emoji}</Icon>
                {index !== lastItemIndex && <Line $startFilled $endFilled />}
              </IconOuter>
              <StepContent>
                <Panel
                  isDefaultExpanded={
                    EVENTS_EXPANDED_DEFAULT.includes(item.event) &&
                    !TITLES_HIDDEN_BY_DEFAULT.includes(item.title.toLowerCase())
                  }
                  header={
                    <StepHeader>
                      <StepTitle>{item.title}</StepTitle>
                      <DateFormater dateTime={item.activityAt} noTime />
                    </StepHeader>
                  }
                  // 💩 Panel doesn't like it when it's children prop is empty
                  children={<></>}
                />
              </StepContent>
            </Step>
          ))
        )}
      </TimelineOuter>
    </TimelineContainer>
  )
}

export default Timeline
