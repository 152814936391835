import styled from 'styled-components'

import _PopupButton from '../../PopupButton'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  margin-top: 4px;
`

export const RowItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
