import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'

import {
  InvoicingReportTogglUsageFragment,
  InvoicingReportFragment,
  PricingFragment,
  StripeInvoiceLineItemInput,
  StripeCustomer_PaymentStatusFragment,
  StripeCustomerPaymentStatus,
  StripeInvoiceStatus,
  StripeCustomer_InvoicesFragment,
  Executivable_InvoicedUserFragment,
  InvoicingRefundFragment,
  InvoicingAgreementFragment,
  onlyIfExecutiveUser,
  isInvoicingRefundCreditAmount,
  isInvoicingRefundCreditHours,
  Executivable,
  InvoicingDiscountFragment,
  isInvoicingDiscountCreditHours,
  isInvoicingDiscountCreditAmount,
  isInvoicingRefundCredit,
  EntityPricingFragment,
} from '../../../graphql'
import { formatHours } from '../Contractors/utils'
import {
  computeAmount,
  ComputeParams as PricingComputeParams,
  getPricingFormulaWithValues,
} from '../Pricings/utils'

export enum SortKey {
  EXECUTIVE_GIVEN_NAME = 'Executive given name',
  EXECUTIVE_FAMILY_NAME = 'Executive family name',
  ASSISTANT_GIVEN_NAME = 'Assistant given name',
  ASSISTANT_FAMILY_NAME = 'Assistant family name',
  EXCEPTION = 'Exception',
  START_DATE = 'Start date',
  PAYMENT_STATUS = 'Payment status',
  TOTAL_LOW_TO_HIGH = 'Total low to high',
  TOTAL_HIGH_TO_LOW = 'Total high to low',
}

export enum Exception {
  CHURNED_NO_GRACE = 'CHURNED',
  CHURNED_WITHIN_GRACE = 'CHURNED (GRACE)',
  PAUSED = 'PAUSED',
  TRANSITIONING = 'TRANSITIONING',
  NO_START_DATE = 'NO START DATE',
  NO_ASSISTANT = 'NO ASSISTANT',
  AGREEMENT = 'AGREEMENT',
  REFUND = 'REFUND',
  DISCOUNT = 'DISCOUNT',
}

export const NO_INVOICE = 'NO_INVOICE' as const

export const TIME_ZONE = {
  abbr: 'ET',
  iana: 'America/New_York',
}

export enum ReportsSection {
  INDIVIDUALS = 'INDIVIDUALS',
  GROUPS = 'GROUPS',
  CHURN_PAUSE = 'CHURN_PAUSE',
  NEW = 'NEW',
  TRANSITION = 'TRANSITION',
  PRICING_EXCEPTION = 'PRICING_EXCEPTION',
  MISSING_PACKAGE = 'MISSING_PACKAGE',
  MAXIO_BILLING = 'MAXIO_BILLING',
}

export const SECTIONS: { value: ReportsSection; label: string }[] = [
  {
    value: ReportsSection.INDIVIDUALS,
    label: 'Individuals',
  },
  {
    value: ReportsSection.GROUPS,
    label: 'Groups',
  },
  {
    value: ReportsSection.CHURN_PAUSE,
    label: 'Churn Pause',
  },
  {
    value: ReportsSection.NEW,
    label: 'New',
  },
  {
    value: ReportsSection.TRANSITION,
    label: 'Transition',
  },
  {
    value: ReportsSection.PRICING_EXCEPTION,
    label: 'Pricing Exception',
  },
  {
    value: ReportsSection.MISSING_PACKAGE,
    label: 'Missing Package',
  },
  {
    value: ReportsSection.MAXIO_BILLING,
    label: 'Maxio Billing',
  },
]

export const getZonedDateTimeFromJSDate = (date: Date): DateTime =>
  DateTime.fromJSDate(date, { zone: TIME_ZONE.iana })

export const getZonedDateTimeFromDateString = (dateStr: string): DateTime =>
  DateTime.fromISO(`${dateStr}T00:00:00`, { zone: TIME_ZONE.iana })

export type ReportsQuery = {
  from: Date
  to: Date
}

export const CHURN_GRACE_PERIOD_DAYS = 10

export function isValidQuery(
  query: Partial<ReportsQuery> | undefined,
): query is ReportsQuery {
  return Boolean(
    query?.from?.valueOf?.() && query.to?.valueOf?.() && query.from < query.to,
  )
}

export type Filter = {
  search?: string
  exception?: Exception
  paymentStatus?: StripeCustomerPaymentStatus
  invoiceStatus?: typeof NO_INVOICE | StripeInvoiceStatus
  excludeChurnedNoHours?: boolean
  successManagerId?: string
}

const DEFAULT_SORT_FIELD = 'zzz'

function getReportSortField(
  report: ReportSummary,
  sortKey: SortKey,
): string | number | null | undefined {
  if (sortKey === SortKey.EXECUTIVE_FAMILY_NAME) {
    return (
      report.invoicedUser.profile.familyName ||
      report.invoicedUser.profile.displayName?.split(' ').pop() ||
      DEFAULT_SORT_FIELD
    ).toLowerCase()
  } else if (sortKey === SortKey.EXECUTIVE_GIVEN_NAME) {
    return (
      report.invoicedUser.profile.displayName?.toLowerCase() ||
      DEFAULT_SORT_FIELD
    )
  }

  const usageWithAssistant = report.packages[0].togglUsages.find(
    ({ assistant }) => assistant,
  )
  const assistant = usageWithAssistant?.assistant
  if (sortKey === SortKey.ASSISTANT_FAMILY_NAME) {
    return (
      assistant?.profile.familyName ||
      assistant?.profile.displayName?.split(' ').pop() ||
      DEFAULT_SORT_FIELD
    ).toLowerCase()
  } else if (sortKey === SortKey.ASSISTANT_GIVEN_NAME) {
    return assistant?.profile.givenName || DEFAULT_SORT_FIELD
  }

  if (sortKey === SortKey.PAYMENT_STATUS) {
    return (
      report.invoicedUser.stripeCustomer?.paymentStatus || DEFAULT_SORT_FIELD
    )
  }

  if (sortKey === SortKey.START_DATE) {
    return report.invoicedUser.startDate || DEFAULT_SORT_FIELD
  }

  if (sortKey === SortKey.EXCEPTION) {
    for (const p of report.packages) {
      for (const usage of p.togglUsages) {
        const exception = getUsageException(usage)
        if (exception) {
          return exception
        }
      }
    }
  }

  if (sortKey === SortKey.TOTAL_LOW_TO_HIGH) {
    return getReportTotals(report).amount
  }

  if (sortKey === SortKey.TOTAL_HIGH_TO_LOW) {
    return -getReportTotals(report).amount
  }

  return DEFAULT_SORT_FIELD
}

export function getSortedReports(
  reports: ReportSummary[],
  sortKeys: SortKey[],
): typeof reports {
  return [...reports].sort((a, b) => {
    for (const sortKey of sortKeys) {
      const aField = getReportSortField(a, sortKey)
      const bField = getReportSortField(b, sortKey)

      // Handle numbers and strings differently
      if (typeof aField === 'number' && typeof bField === 'number') {
        // Numeric comparison for numbers
        if (aField !== bField) return aField - bField
      } else {
        // Lexicographic comparison for strings
        const aStr = String(aField || '').toLowerCase()
        const bStr = String(bField || '').toLowerCase()
        if (aStr < bStr) return -1
        if (aStr > bStr) return 1
      }
    }

    return 0 // If all fields are equal
  })
}

export function excludeReports(
  reports: ReportSummary[],
  edits: Record<InvoicingReportFragment['id'], InvoiceEdit | null>,
  patchEdit: (reportId: string, patch: Partial<InvoiceEdit>) => void,
): typeof reports {
  reports.forEach((summary) => {
    const summaryTotals = getReportTotals(summary)

    // Exclude report if:
    // - All packages are excluded
    // - The amount is 0: https://double.height.app/T-4913
    if (
      (summaryTotals?.amount === 0 ||
        summary.packages.every(
          ({ id: packageId }) =>
            edits[summary.id]?.excludedPackages?.[packageId],
        )) &&
      typeof edits[summary.id]?.isExcluded === 'undefined'
    ) {
      patchEdit(summary.id, {
        isExcluded: true,
      })
    }
  })

  return reports
}

export const filterReports = (
  filter: Filter,
  report: ReportSummary,
): boolean => {
  if (filter.search) {
    const keyword = filter.search.toLowerCase().trim()
    const isMatch =
      report.invoicedUser.profile.displayName
        ?.toLowerCase()
        .includes(keyword) ||
      report.packages.some(
        ({ principalUser, togglUsages }) =>
          principalUser.profile.displayName?.toLowerCase().includes(keyword) ||
          togglUsages.some(
            ({ executive, assistant }) =>
              executive?.profile.displayName?.toLowerCase().includes(keyword) ||
              assistant?.profile.displayName?.toLowerCase().includes(keyword),
          ),
      )

    if (!isMatch) {
      return false
    }
  }

  if (filter.exception === Exception.AGREEMENT) {
    if (!getReportInvoicingAgreements(report).length) {
      return false
    }
  } else if (filter.exception === Exception.REFUND) {
    if (!getReportInvoicingRefunds(report).length) {
      return false
    }
  } else if (filter.exception === Exception.DISCOUNT) {
    if (!getReportInvoicingDiscounts(report).length) {
      return false
    }
  } else if (
    filter.exception &&
    !report.packages.some(({ togglUsages }) =>
      togglUsages.some(
        (usage) => filter.exception === getUsageException(usage),
      ),
    )
  ) {
    return false
  }

  if (
    filter.paymentStatus &&
    (report.invoicedUser.stripeCustomer?.paymentStatus ||
      StripeCustomerPaymentStatus.MISSING) !== filter.paymentStatus
  ) {
    return false
  }

  if (filter.invoiceStatus === NO_INVOICE) {
    if (report.invoicedUser.stripeCustomer?.invoices?.[0]?.status) {
      return false
    }
  } else if (
    filter.invoiceStatus &&
    report.invoicedUser.stripeCustomer?.invoices?.[0]?.status !==
      filter.invoiceStatus
  ) {
    return false
  }

  // Exclude packages for CHURNED customers with 0 hours: https://double.height.app/T-4913
  if (filter.excludeChurnedNoHours) {
    if (
      report.packages.every(
        (p) =>
          !p.hours &&
          p.togglUsages.some(
            (usage) =>
              getUsageException(usage) == Exception.CHURNED_WITHIN_GRACE,
          ),
      )
    ) {
      return false
    }
  }

  if (filter.successManagerId) {
    if (
      !(
        report.invoicedUser.csm?.id === filter.successManagerId ||
        report.packages.some(({ togglUsages }) =>
          togglUsages.some(
            ({ executive }) => executive.csm?.id === filter.successManagerId,
          ),
        )
      )
    ) {
      return false
    }
  }

  return true
}

export const makePackageFilter =
  (filter: Filter) =>
  (pack: ReportSummaryPackage): boolean => {
    // Exclude packages for CHURNED customers with 0 hours: https://double.height.app/T-4913
    if (
      filter.excludeChurnedNoHours &&
      !pack.hours &&
      pack.togglUsages.some(
        (usage) => getUsageException(usage) == Exception.CHURNED_WITHIN_GRACE,
      )
    ) {
      return false
    }

    return true
  }

export function isReport(
  report: null | undefined | InvoicingReportFragment,
): report is InvoicingReportFragment {
  return !!report
}

export function getUsageException(
  usage: ReportSummaryTogglUsage,
): Exception | undefined {
  if (usage.hasChurnedDuringGracePeriod) {
    return Exception.CHURNED_WITHIN_GRACE
  }
  if (usage.hasChurned) {
    return Exception.CHURNED_NO_GRACE
  }
  if (usage.isPaused) {
    return Exception.PAUSED
  }
  if (!usage.executive.startDate) {
    return Exception.NO_START_DATE
  }
  if (!usage.assistant) {
    return Exception.NO_ASSISTANT
  }
  if (usage.isTransitioning) {
    return Exception.TRANSITIONING
  }
}

const getPackagesTotals = (
  report: ReportSummary,
  edit?: InvoiceEdit | null,
): {
  amount: number
  hours: number
} => {
  return report.packages.reduce(
    (totals, { id, hours, amount }) => {
      if (!edit?.excludedPackages?.[id]) {
        totals.hours += hours || 0
        totals.amount += amount || 0
      }
      return totals
    },
    { hours: 0, amount: 0 },
  )
}

const getInvoiceItemAmount = (
  item: InvoiceItem,
  packagesTotalAmount: number,
) => {
  if ('amount' in item) {
    return item.amount
  }
  if ('percentage' in item) {
    return (packagesTotalAmount / 100) * item.percentage
  }
  return 0
}

export const getReportTotals = (
  report: ReportSummary,
  edit?: InvoiceEdit | null,
): {
  amount: number
  hours: number
  calculatedAmount: number
  invoicedAmount?: number
  invoicedCurrency?: string
} => {
  const { hours, amount: packagesAmount } = getPackagesTotals(report, edit)

  let calculatedAmount =
    packagesAmount +
    (edit?.additionalItems || []).reduce(
      (total, item) => total + getInvoiceItemAmount(item, packagesAmount),
      0,
    ) +
    (report.items || []).reduce((total, item) => {
      if (!edit?.excludedItems?.[item.id]) {
        total += getInvoiceItemAmount(item, packagesAmount)
      }
      return total
    }, 0)

  calculatedAmount = calculatedAmount < 0 ? 0 : calculatedAmount

  const invoicedAmount =
    report.invoicedUser.stripeCustomer?.invoices?.[0]?.amount

  return {
    hours,
    calculatedAmount,
    invoicedAmount,
    invoicedCurrency:
      report.invoicedUser.stripeCustomer?.invoices?.[0]?.currency,
    amount: invoicedAmount || calculatedAmount,
  }
}

interface InvoiceItemAbstract {
  id: string
  label?: string
  description: string
  url?: string
  badge?: Exception
}

export type InvoiceItemAbsolute = InvoiceItemAbstract & {
  amount: number
  formula?: string
}
type InvoiceItemRelative = InvoiceItemAbstract & { percentage: number }
type InvoiceItemHourly = InvoiceItemAbstract & { hours: number; amount: number }
export type InvoiceItem =
  | InvoiceItemAbsolute
  | InvoiceItemRelative
  | InvoiceItemHourly

export const getStripeInvoiceLineItems = (
  query: ReportsQuery,
  report: ReportSummary,
  edit?: InvoiceEdit | null,
): StripeInvoiceLineItemInput[] => {
  const period = DateTime.fromJSDate(query.from)
    .plus({ day: 2 })
    .toLocaleString({ month: 'short', year: 'numeric' }, { locale: 'en-US' })
  const currency = 'USD'

  const lineItems: StripeInvoiceLineItemInput[] = []

  for (const pack of report.packages) {
    if (edit?.excludedPackages?.[pack.id]) {
      continue
    }

    const packageLineItem: StripeInvoiceLineItemInput = {
      description: `${period} | ${formatHours(pack.hours)} - ${
        pack.pricing?.label
      }${
        pack.pricing?.isSharable
          ? ''
          : ` (${pack.principalUser.profile.displayName})`
      }`,
      currency,
      amount: pack.amount,
      periodStartAt: query.from,
      periodEndAt: query.to,
    }

    const usageLineItems: StripeInvoiceLineItemInput[] = pack.togglUsages
      .filter(({ id }) => !edit?.excludedUsages?.[id])
      // Remove 0 hours usages https://double.height.app/T-4913#d79cf129-5d21-4214-8f6b-4b22b65dd106
      .filter(({ hours }) => hours !== 0)
      .map((usage) => ({
        description: `${period} | ${formatHours(usage.hours)} - ${
          pack.pricing?.label
        } (${[
          usage.executive.profile.displayName,
          usage.assistant?.profile.displayName,
        ]
          .filter(Boolean)
          .join(' & ')})`,
        currency,
        amount: 0,
        periodStartAt: query.from,
        periodEndAt: query.to,
      }))

    if (usageLineItems.length === 1) {
      // Use description of the single usage for the package
      // and just use that single line item
      lineItems.push({
        ...packageLineItem,
        description: usageLineItems[0].description,
      })
    } else {
      lineItems.push(packageLineItem, ...usageLineItems)
    }
  }

  const { amount: packagesTotalAmount } = getPackagesTotals(report, edit)

  for (const item of report.items || []) {
    lineItems.push({
      description: item.description,
      currency,
      amount: getInvoiceItemAmount(item, packagesTotalAmount),
      periodStartAt: query.from,
      periodEndAt: query.to,
    })
  }

  for (const additionalItem of edit?.additionalItems || []) {
    lineItems.push({
      description: `${period} | ${additionalItem.description}`,
      currency,
      amount: additionalItem.amount,
      periodStartAt: query.from,
      periodEndAt: query.to,
    })
  }

  // Add free line items for Double AI usage

  lineItems.push({
    description: `${period} | Mingo AI Copilot Tokens - Unlimited`,
    currency,
    amount: 0,
    periodStartAt: query.from,
    periodEndAt: query.to,
  })

  return lineItems
}

export interface InvoiceEdit {
  isExcluded?: boolean
  additionalItems?: InvoiceItemAbsolute[]
  excludedPackages?: Record<ReportSummaryPackage['id'], boolean>
  excludedUsages?: Record<InvoicingReportTogglUsageFragment['id'], boolean>
  excludedItems?: Record<InvoiceItem['id'], boolean>
}

interface SummaryArgs {
  query: ReportsQuery
  data: (InvoicingReportFragment | null | undefined)[] | undefined
  edits: Record<InvoicingReportFragment['id'], InvoiceEdit | null>
  patchEdit: (reportId: string, patch: Partial<InvoiceEdit>) => void
  publicPricings: PricingFragment[]
}

type Executive = InvoicingReportFragment['executive']

type InvoicedUser = Executivable_InvoicedUserFragment & {
  stripeCustomer?: Partial<StripeCustomer_PaymentStatusFragment> | null
} & {
  stripeCustomer?: Partial<StripeCustomer_InvoicesFragment> | null
}

interface ReportSummaryTogglUsage extends InvoicingReportTogglUsageFragment {
  executive: Executive
  isPaused: boolean
  isTransitioning: boolean
  churnedAt: Date | null
  hasChurned: boolean
  hasChurnedDuringGracePeriod: boolean
}

interface ReportSummaryPackage {
  id: string
  principalUser: Executive
  monthsSinceStartDate: number
  userPricing?: EntityPricingFragment | null
  pricing?: PricingFragment | null
  pricingFormula?: string | null
  togglUsages: ReportSummaryTogglUsage[]
  amount: number
  hours: number
}

export interface ReportSummary {
  id: string
  invoicedUser: InvoicedUser
  packages: ReportSummaryPackage[]
  mainPackage: ReportSummaryPackage | undefined
  items: InvoiceItem[]
}

export const getInvoicedUserRefunds = (
  executive: InvoicedUser,
): InvoicingRefundFragment[] => {
  const invoicingRefunds = onlyIfExecutiveUser(executive)?.invoicingRefunds

  return invoicingRefunds || []
}

export const getReportInvoicingRefunds = (
  report: ReportSummary,
): InvoicingRefundFragment[] => {
  const invoicingRefunds = [
    // Check for a refund on invoiced user
    ...getInvoicedUserRefunds(report.invoicedUser),

    // or on any user
    ...report.packages
      .map(({ principalUser, togglUsages }) => [
        ...getInvoicedUserRefunds(principalUser),
        ...togglUsages
          .map(({ executive }) => getInvoicedUserRefunds(executive))
          .flat(),
      ])
      .flat(),
  ]

  return uniqBy(invoicingRefunds, 'id')
}

export const getInvoicedUserDiscounts = (
  executive: InvoicedUser,
): InvoicingDiscountFragment[] => {
  const invoicingDiscounts = onlyIfExecutiveUser(executive)?.invoicingDiscounts

  return invoicingDiscounts || []
}

export const getReportInvoicingDiscounts = (
  report: ReportSummary,
): InvoicingDiscountFragment[] => {
  const invoicingDiscounts = [
    // Check for a discount on invoiced user
    ...getInvoicedUserDiscounts(report.invoicedUser),

    // or on any user
    ...report.packages
      .map(({ principalUser, togglUsages }) => [
        ...getInvoicedUserDiscounts(principalUser),
        ...togglUsages
          .map(({ executive }) => getInvoicedUserDiscounts(executive))
          .flat(),
      ])
      .flat(),
  ]

  return uniqBy(invoicingDiscounts, 'id')
}

const getInvoicedUserAgreements = (
  executive: InvoicedUser,
): InvoicingAgreementFragment[] => {
  return onlyIfExecutiveUser(executive)?.invoicingAgreements || []
}

export const getReportInvoicingAgreements = (
  report: ReportSummary,
): InvoicingAgreementFragment[] => {
  const invoicingAgreements = [
    // Check for an agreement on invoiced user
    ...getInvoicedUserAgreements(report.invoicedUser),

    // or on any user
    ...report.packages
      .map(({ principalUser, togglUsages }) => [
        ...getInvoicedUserAgreements(principalUser),
        ...togglUsages
          .map(({ executive }) => getInvoicedUserAgreements(executive))
          .flat(),
      ])
      .flat(),
  ]

  return uniqBy(invoicingAgreements, 'id')
}

export const getPackagePricingParams = (
  report: ReportSummary,
  pack: ReportSummaryPackage,
): PricingComputeParams => {
  const hasAgreement = getReportInvoicingAgreements(report).length > 0
  const hasException = pack.togglUsages.every(
    (t) => t.hasChurnedDuringGracePeriod || t.isPaused || t.isTransitioning,
  )

  return {
    hours: pack.hours,
    monthsSinceStartDate: pack.monthsSinceStartDate,
    // Do not bill the base price on the first month or in case of valid exception
    ignoreBasePrice:
      pack.monthsSinceStartDate === 0 || (!hasAgreement && hasException),
  }
}

export const getReportsSummaries = ({
  data,
  query,
  edits,
  patchEdit,
}: SummaryArgs): ReportSummary[] => {
  const fromDateTime = getZonedDateTimeFromJSDate(query.from)
  const churnGraceUntil = fromDateTime
    .plus({ days: CHURN_GRACE_PERIOD_DAYS })
    .endOf('day')
    .toJSDate()

  const extractReportTogglUsages = ({
    togglUsages,
    executive,
    isPaused,
    churnedAt,
    isTransitioning,
  }: InvoicingReportFragment): ReportSummaryTogglUsage[] => {
    togglUsages = togglUsages.filter(Boolean)
    if (!togglUsages.length) {
      togglUsages = [{ id: executive.id, hours: 0 }]
    }
    return togglUsages.map((usage) => {
      const _churnedAt = churnedAt ? new Date(churnedAt) : null
      return {
        ...usage,
        executive,
        isPaused,
        churnedAt: _churnedAt,
        hasChurned: Boolean(_churnedAt && _churnedAt <= query.to),
        hasChurnedDuringGracePeriod: Boolean(
          _churnedAt && _churnedAt <= churnGraceUntil,
        ),
        isTransitioning,
      }
    })
  }

  // Keep non-null reports
  const filteredReports = (data || []).filter(isReport)

  const summaries: ReportSummary[] = []
  const dependentReports: InvoicingReportFragment[] = []

  // Initialize summaries for principal reports
  filteredReports.forEach((report) => {
    if (
      report.executive.invoicedUser?.id &&
      report.executive.id !== report.executive.invoicedUser.id
    ) {
      // Dependent report
      dependentReports.push(report)
    } else {
      // Principal report
      const mainPackage: ReportSummaryPackage = {
        id: report.executive.id,
        principalUser: report.executive,
        monthsSinceStartDate: Infinity,
        togglUsages: extractReportTogglUsages(report),
        userPricing: report.executive.invoicedPricing,
        pricing: report.executive.invoicedPricing?.pricing,
        pricingFormula: null,
        amount: 0,
        hours: 0,
      }

      summaries.push({
        id: report.id,
        invoicedUser: report.executive,
        packages: [mainPackage],
        mainPackage,
        items: [],
      })
    }
  })

  // Handle dependent reports
  dependentReports
    // First sort reports with those having their own pricing to the top
    .sort((a, b) => {
      const aPricing = a.executive.invoicedPricing
      if (!aPricing) {
        return 1 // B first
      }
      if (aPricing?.owner.id === a.executive.id) {
        return -1 // A first
      }

      const bPricing = b.executive.invoicedPricing
      if (!bPricing) {
        return -1 // A first
      }
      if (bPricing?.owner.id === b.executive.id) {
        return 1 // B first
      }

      return 0
    })
    // Triage dependent reports into summaries
    .forEach((dependentReport) => {
      const { invoicedUser } = dependentReport.executive
      let summary = summaries.find((s) => s.invoicedUser.id === invoicedUser.id)

      if (!summary) {
        // Create a summary for billed user
        summary = {
          id: invoicedUser.id,
          invoicedUser,
          packages: [],
          mainPackage: undefined,
          items: [],
        }
        summaries.push(summary)
      }

      // Create/update packages
      const userPricing = dependentReport.executive.invoicedPricing
      const dependentTogglUsages = extractReportTogglUsages(dependentReport)

      let masterPackage: ReportSummaryPackage | undefined

      if (userPricing?.owner.id !== dependentReport.executive.id) {
        // No self-pricing
        masterPackage =
          // so try to find a master package in the summary
          (userPricing?.owner.id &&
            summary.packages.find(
              (pack) => pack.userPricing?.owner.id === userPricing?.owner.id,
            )) ||
          // Try to use main package
          summary.mainPackage
      }

      if (masterPackage?.pricing?.isSharable) {
        // we found a master pricing to attach to and it is sharable, yay!
        masterPackage.togglUsages.push(...dependentTogglUsages)
      } else {
        // Separate package
        summary.packages.push({
          id: dependentReport.executive.id,
          principalUser: dependentReport.executive,
          monthsSinceStartDate: Infinity,
          userPricing,
          pricing: userPricing?.pricing,
          pricingFormula: null,
          togglUsages: dependentTogglUsages,
          amount: 0,
          hours: 0,
        })
      }
    })

  // Process summaries
  summaries.forEach((summary) => {
    // Create item for agreements
    getReportInvoicingAgreements(summary).forEach((invoicingAgreement) => {
      summary.items.push({
        id: invoicingAgreement.id,
        url: invoicingAgreement.airtableUrl,
        description: invoicingAgreement.description,
        percentage: -1 * (invoicingAgreement.discountPercentage || 0),
      })
    })

    // Process each package
    summary.packages.forEach((p) => {
      // Exclude toggl usages if at 0 hours and user is paused, transitioning or churned during grace
      // https://double.height.app/T-2964
      p.togglUsages.forEach(
        ({
          id: usageId,
          hours,
          hasChurnedDuringGracePeriod,
          isPaused,
          isTransitioning,
        }) => {
          if (
            !hours &&
            (hasChurnedDuringGracePeriod || isPaused || isTransitioning) &&
            typeof edits[summary.id]?.excludedUsages?.[usageId] === 'undefined'
          ) {
            patchEdit(summary.id, {
              excludedUsages: {
                ...edits[summary.id]?.excludedUsages,
                [usageId]: true,
              },
            })
          }
        },
      )

      if (p.principalUser.startDate) {
        p.monthsSinceStartDate = fromDateTime.diff(
          getZonedDateTimeFromDateString(p.principalUser.startDate).startOf(
            'month',
          ),
          'months',
        ).months
      }

      // Exclude package if start date is missing => https://double.height.app/T-2963
      // or start date is after billing period => https://double.height.app/T-2965
      // or all usages are excluded => https://double.height.app/T-2964
      // EXCEPT if they have an agreement https://double.height.app/T-4913#edf4a410-c3db-4234-acac-a9b1c6fa14bd
      if (
        getReportInvoicingAgreements(summary).length === 0 &&
        (!p.principalUser.startDate ||
          p.monthsSinceStartDate < 0 ||
          p.togglUsages.every(
            ({ id: usageId }) => edits[summary.id]?.excludedUsages?.[usageId],
          )) &&
        typeof edits[summary.id]?.excludedPackages?.[p.id] === 'undefined'
      ) {
        patchEdit(summary.id, {
          excludedPackages: {
            ...edits[summary.id]?.excludedPackages,
            [p.id]: true,
          },
        })
      }

      // Sum hours across usages
      p.hours = p.togglUsages.reduce((total, { id, hours }) => {
        if (hours && !edits[summary.id]?.excludedUsages?.[id]) {
          total += hours
        }
        return total
      }, 0)

      if (p.hours === 0 && p.monthsSinceStartDate === 0) {
        // Don't charge for first month if it's 0 hours
        // https://double.height.app/T-5073
        p.pricingFormula = '$0 on first month and 0 hours'
        p.amount = 0
      } else {
        p.pricing = p.pricing

        if (p.pricing) {
          const pricingParams = getPackagePricingParams(summary, p)
          p.pricingFormula = getPricingFormulaWithValues(
            p.pricing,
            pricingParams,
          )
          p.amount = computeAmount(p.pricing, pricingParams)
        }
      }
    })

    // Get refunds
    const invoiceRefunds = getReportInvoicingRefunds(summary)
    const invoiceDiscounts = getReportInvoicingDiscounts(summary)
    const invoiceCredits = [...invoiceRefunds, ...invoiceDiscounts]
    const userPackage = summary.mainPackage

    invoiceCredits.forEach(
      ({ credit, id: refundId, description, airtableUrl, reason }) => {
        let amount = 0
        let formula = ''
        let hours = 0

        if (
          isInvoicingRefundCreditHours(credit) ||
          isInvoicingDiscountCreditHours(credit)
        ) {
          hours = Number(credit.hours)
          const basePrice = userPackage?.pricing?.modelConfig?.basePrice || 0
          const baseHours = userPackage?.pricing?.modelConfig?.baseHours || 1
          formula = `${hours} credits * $${basePrice} base price / ${baseHours} included hours`
          amount = ((hours * basePrice) / baseHours) * -1
        } else if (
          isInvoicingRefundCreditAmount(credit) ||
          isInvoicingDiscountCreditAmount(credit)
        ) {
          formula = `$${credit.amount} credit refund`
          amount = Number(credit.amount) * -1
        }

        // Add reason in description

        summary.items.push({
          id: refundId,
          url: airtableUrl,
          label: reason,
          description,
          amount,
          formula,
          hours,
          badge: isInvoicingRefundCredit(credit)
            ? reason === 'LTA Agreement'
              ? Exception.AGREEMENT
              : Exception.REFUND
            : Exception.DISCOUNT,
        })
      },
    )
  })

  const finalReports = excludeReports(summaries, edits, patchEdit)
  return finalReports
}

export const mapStripeInformationToInvoicingReport = (
  report: ReportSummary,
  users: Partial<Executivable>[],
): ReportSummary => {
  if (!report?.invoicedUser?.stripeCustomer?.id) {
    return report
  }

  const stripeCustomer = users.find(
    (user) =>
      user?.stripeCustomer?.id === report?.invoicedUser?.stripeCustomer?.id,
  )?.stripeCustomer

  const finalReport = {
    ...report,
    invoicedUser: {
      ...report.invoicedUser,
      stripeCustomer,
    },
  }

  return finalReport
}

export function getReportSection(report: ReportSummary): ReportsSection {
  // If maxio billing, immediately return since cockpit rules don't apply
  if (report.invoicedUser.isOnMaxioBilling) {
    return ReportsSection.MAXIO_BILLING
  }

  const packages = report.packages
  const isMissingPackage = packages.some((p) => !p.pricing)
  const isPricingException = packages.some((p) =>
    p.pricing?.ref?.includes('EXCEPTION'),
  )
  const hasTransitioned = packages.some((p) =>
    p.togglUsages.every((u) => u.isTransitioning),
  )
  const isNew = packages.some((p) => p.monthsSinceStartDate === 0)
  const churnedOrPaused = packages.some((p) =>
    p.togglUsages.every((u) => u.hasChurned || u.isPaused),
  )
  const isGroup =
    packages.length > 1 ||
    uniq(packages.map((p) => p.togglUsages.map((tu) => tu.executive.id)).flat())
      .length > 1

  if (isMissingPackage) {
    return ReportsSection.MISSING_PACKAGE
  } else if (isPricingException) {
    return ReportsSection.PRICING_EXCEPTION
  } else if (churnedOrPaused) {
    return ReportsSection.CHURN_PAUSE
  } else if (hasTransitioned) {
    return ReportsSection.TRANSITION
  } else if (isNew) {
    return ReportsSection.NEW
  } else if (isGroup) {
    return ReportsSection.GROUPS
  } else {
    return ReportsSection.INDIVIDUALS
  }
}

// A team report is a report that includes more than one exectuive
export function isTeam(report: ReportSummary): Boolean {
  const packages = report.packages

  return (
    uniq(packages.map((p) => p.togglUsages.map((tu) => tu.executive.id)).flat())
      .length > 1
  )
}
