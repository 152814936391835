import styled from 'styled-components'

import _PopupButton from '../../../../components/PopupButton'
import { StepContent as _StepContent } from '../../../../components/timeline/styled'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  margin-top: 4px;
`

export const StepContent = styled(_StepContent)`
  & > div > div > div {
    margin-right: 24px;
  }
`

export const RowItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
