import Avatar, { AvatarItem } from '@atlaskit/avatar'
import ConfirmIcon from '@atlaskit/icon/glyph/check-circle-outline'
import DragIcon from '@atlaskit/icon/glyph/drag-handler'
import DequeueIcon from '@atlaskit/icon/glyph/editor/remove-emoji'
import ReplyIcon from '@atlaskit/icon/glyph/questions'
import { colors } from '@atlaskit/theme'
import React, { useCallback, useMemo } from 'react'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItemCopyText,
  DropdownItem,
} from '../../../../../components/DropdownMenu'
import {
  SlackLinkButton,
  COMMUNITY_TEAM_ID,
} from '../../../../../components/SlackButton'
import { LoadingSpinner } from '../../../../../components/Spinner'
import {
  MatchingProposal_FullFragment,
  useDequeueMatchingProposalMutation,
  useConfirmMatchingAssistantMutation,
} from '../../../../../graphql'
import useSwitch from '../../../../../lib/useSwitch'
import { getAssistantCountryRateCurrency } from '../utils'

import ReplyModal from './ReplyModal'
import Timeline from './Timeline'
import { Outer, replyTint, Header, DragHandle } from './styled'

interface MatchingProps {
  matchingId: string
  proposal: MatchingProposal_FullFragment
  canSend: boolean
  canConfirm: boolean
  isSortable: boolean
}

const MatchingProposal = ({
  matchingId,
  proposal,
  canSend,
  canConfirm,
  isSortable,
}: MatchingProps) => {
  const { assistant, sentAt, reply, slackChannelId } = proposal
  const [replyModalIsOpen, openReplyModal, closeReplyModal] = useSwitch(false)

  const [dequeue, dequeueMutation] = useDequeueMatchingProposalMutation({
    variables: {
      input: {
        matchingId,
        assistantId: assistant.id,
      },
    },
  })

  const [confirmAssistant, confirmMutation] =
    useConfirmMatchingAssistantMutation({
      variables: {
        input: {
          matchingId,
          assistantId: assistant.id,
        },
      },
    })

  const mayConfirmAssistant = useCallback(() => {
    if (
      window.confirm(
        `Do you want to confirm ${proposal.assistant.profile.displayName} as final match?`,
      )
    ) {
      confirmAssistant()
    }
  }, [confirmAssistant, proposal])

  const isLoading = dequeueMutation.loading || confirmMutation.loading

  const secondaryText = useMemo(() => {
    return getAssistantCountryRateCurrency(
      assistant.city?.address?.countryCode,
      assistant.currency,
      assistant.hourlyRate,
    )
  }, [assistant])

  return (
    <Outer $tint={sentAt ? replyTint(reply) : colors.N20}>
      <Header>
        <AvatarItem
          avatar={<Avatar src={assistant.profile.avatarUrl!} />}
          primaryText={assistant.profile.displayName}
          href={`/users/${assistant.id}`}
          target={'_blank'}
          secondaryText={secondaryText}
        />
        {slackChannelId && (
          <SlackLinkButton
            teamId={COMMUNITY_TEAM_ID}
            channelId={slackChannelId}
            title={'Open matching Slack channel'}
          />
        )}
        <MoreDropdownMenu>
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => dequeue()}
              isDisabled={!!sentAt}
              elemBefore={<DequeueIcon label={''} size={'small'} />}
            >
              {'Remove from queue'}
            </DropdownItem>
            <DropdownItem
              onClick={openReplyModal}
              elemBefore={<ReplyIcon label={''} size={'small'} />}
              description={"Manually set/change assistant's reply"}
            >
              {reply ? 'Change reply' : 'Set reply'}
            </DropdownItem>
            <DropdownItem
              onClick={mayConfirmAssistant}
              isDisabled={!canConfirm}
              elemBefore={<ConfirmIcon label={''} size={'small'} />}
              description={'Final word on this match: that’s the one!'}
            >
              {'Confirm assistant'}
            </DropdownItem>
            <DropdownItemCopyText text={assistant.id}>
              {'Copy Assistant ID'}
            </DropdownItemCopyText>
          </DropdownItemGroup>
        </MoreDropdownMenu>
        <DragHandle isDisabled={!isSortable} title={'Drag to re-order queue'}>
          <DragIcon label={''} />
        </DragHandle>
      </Header>

      <Timeline
        matchingId={matchingId}
        proposal={proposal}
        canSend={canSend}
        canConfirm={canConfirm}
      />

      <ReplyModal
        isOpen={replyModalIsOpen}
        onClose={closeReplyModal}
        matchingId={matchingId}
        proposal={proposal}
      />

      <LoadingSpinner show={isLoading} />
    </Outer>
  )
}

export default MatchingProposal
