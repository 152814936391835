import { useMemo } from 'react'

import { TimelineItemFragment } from '../../graphql'

const useTimeline = (
  timelineItems: TimelineItemFragment[] | undefined,
  excludeList: string[],
) => {
  const sortedItems = useMemo(() => {
    return (timelineItems || [])
      .filter((item) => {
        return !excludeList.some((exclude) => item.event === exclude)
      })
      .sort((a, b) => {
        return (
          new Date(b.activityAt).getTime() - new Date(a.activityAt).getTime()
        )
      })
  }, [timelineItems, excludeList])

  const lastItemIndex = (sortedItems?.length || 1) - 1
  const isSortedItemsEmpty = sortedItems.length === 0

  const uniqueCategories = useMemo(() => {
    return sortedItems.reduce((acc, item) => {
      if (!acc.includes(item.category)) {
        acc.push(item.category)
      }
      return acc
    }, [] as string[])
  }, [sortedItems])

  return {
    sortedItems,
    lastItemIndex,
    isSortedItemsEmpty,
    uniqueCategories,
  }
}

export default useTimeline
