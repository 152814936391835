import SendIcon from '@atlaskit/icon/glyph/send'
import Panel from '@atlaskit/panel'
import React, { useCallback } from 'react'

import DateFormater from '../../../../../../components/DateFormater'
import { Field } from '../../../../../../components/form'
import {
  Outer,
  IconOuter,
  Icon,
  Step,
  StepHeader,
  StepTitle,
  Line,
  StepContent,
} from '../../../../../../components/timeline/styled'
import UserCell from '../../../../../../components/users/Cell'
import {
  MatchingProposalReply,
  MatchingProposal_FullFragment,
  useGetMatchingByIdQuery,
  useSendMatchingProposalMutation,
  useUpsertMatchingProposalMutation,
} from '../../../../../../graphql'

import {
  ApplicationMotivation,
  ReplyComment,
  TextArea,
  SendButton,
} from './styled'

type Props = {
  matchingId: string
  proposal: MatchingProposal_FullFragment
  canSend: boolean
  canConfirm: boolean
}

const ProposalTimeline = ({
  matchingId,
  proposal,
  canSend,
  canConfirm,
}: Props) => {
  const [upsertProposal, upsertProposalMutation] =
    useUpsertMatchingProposalMutation()

  const [sendProposal, sendProposalMutation] = useSendMatchingProposalMutation({
    variables: {
      input: {
        matchingId,
        assistantId: proposal.assistant.id,
      },
    },
  })

  const matchingQuery = useGetMatchingByIdQuery({
    variables: {
      matchingId,
    },
  })

  const updateReasonForMatch = useCallback(
    (reasonForMatch: string) => {
      upsertProposal({
        variables: {
          input: {
            matchingId,
            assistantId: proposal.assistant.id,
            reasonForMatch,
          },
        },
      })
    },
    [upsertProposal, matchingId, proposal.assistant.id],
  )

  const updateExecutiveReasonForMatch = useCallback(
    (executiveReasonForMatch: string) => {
      upsertProposal({
        variables: {
          input: {
            matchingId,
            assistantId: proposal.assistant.id,
            executiveReasonForMatch,
          },
        },
      })
    },
    [upsertProposal, matchingId, proposal.assistant.id],
  )

  const updateAdditionalContext = useCallback(
    (additionalContext: string) => {
      upsertProposal({
        variables: {
          input: {
            matchingId,
            assistantId: proposal.assistant.id,
            additionalContext,
          },
        },
      })
    },
    [upsertProposal, matchingId, proposal.assistant.id],
  )

  return (
    <Outer>
      {!!proposal.application && (
        <Step>
          <IconOuter>
            <Icon $filled>{'💁'}</Icon>
            <Line $startFilled $endFilled />
          </IconOuter>
          <StepContent>
            <Panel
              header={
                <StepHeader>
                  <StepTitle>{'Applied'}</StepTitle>
                  <DateFormater dateTime={proposal.application.at} noTime />
                </StepHeader>
              }
            >
              <ApplicationMotivation
                text={proposal.application.motivation}
                collapsedTextLength={100}
                withQuote
                noCollapse
              />
            </Panel>
          </StepContent>
        </Step>
      )}

      <Step>
        <IconOuter>
          <Icon $filled>{'➕'}</Icon>
          <Line $startFilled $endFilled={!!proposal.sentAt} />
        </IconOuter>
        <StepContent>
          <Panel
            isDefaultExpanded
            header={
              <StepHeader>
                <StepTitle>{'Queued'}</StepTitle>
                <DateFormater dateTime={proposal.queuedAt} noTime />
              </StepHeader>
            }
          ></Panel>
        </StepContent>
      </Step>

      <Step>
        <IconOuter>
          <Icon $filled={!!proposal.sentAt}>{'📬'}</Icon>
          <Line
            $startFilled={!!proposal.sentAt}
            $endFilled={!!proposal.repliedAt}
          />
        </IconOuter>
        <StepContent>
          <Panel
            isDefaultExpanded={Boolean(!proposal.repliedAt)}
            header={
              <StepHeader>
                <StepTitle>
                  {proposal.isInstantInvite && '⚡️ Instantly '}
                  {proposal.sentAt ? 'Sent' : 'Not sent yet'}
                </StepTitle>
                <DateFormater dateTime={proposal.sentAt} noTime />
              </StepHeader>
            }
          >
            <Field label={'Reason for match'}>
              <TextArea
                value={proposal.reasonForMatch || ''}
                appearance={proposal.repliedAt ? 'subtle' : 'standard'}
                placeholder={'Add reason for match'}
                minimumRows={proposal.reasonForMatch ? 4 : 1}
                onChangeValue={updateReasonForMatch}
                debounceMs={500}
                resize={'smart'}
              />
            </Field>
            <Field label={'Additional context'}>
              <TextArea
                value={proposal.additionalContext || ''}
                appearance={proposal.repliedAt ? 'subtle' : 'standard'}
                placeholder={'Add context'}
                minimumRows={proposal.additionalContext ? 4 : 1}
                onChangeValue={updateAdditionalContext}
                debounceMs={500}
                resize={'smart'}
              />
            </Field>
            {(matchingQuery.data?.matching?.targetCandidatesCount || 1) > 1 && (
              <Field
                label={'Why this assistant?'}
                helperMessage={
                  'Visible to execs in multiple proposals. Make it personal 🙏'
                }
              >
                <TextArea
                  value={proposal.executiveReasonForMatch || ''}
                  appearance={proposal.repliedAt ? 'subtle' : 'standard'}
                  placeholder={'Why this assistant?'}
                  minimumRows={proposal.executiveReasonForMatch ? 4 : 1}
                  onChangeValue={updateExecutiveReasonForMatch}
                  debounceMs={500}
                  resize={'smart'}
                />
              </Field>
            )}
            {(!proposal.sentAt ||
              proposal.reply === MatchingProposalReply.NO) && (
              <SendButton
                spacing={'compact'}
                onClick={() => sendProposal()}
                isLoading={sendProposalMutation.loading}
                appearance={canSend ? 'warning' : 'default'}
                isDisabled={Boolean(
                  !canSend ||
                    upsertProposalMutation.loading ||
                    upsertProposalMutation.error,
                )}
                iconBefore={<SendIcon label={''} size={'small'} />}
              >
                {!proposal.sentAt ? 'Send' : 'Re-send'}
              </SendButton>
            )}
          </Panel>
        </StepContent>
      </Step>

      <Step>
        <IconOuter>
          <Icon $filled={!!proposal.repliedAt}>
            {!proposal.reply
              ? '💆'
              : {
                  [MatchingProposalReply.YES]: '🙋',
                  [MatchingProposalReply.NO]: '🙅',
                  [MatchingProposalReply.NEED_MORE_INFO]: '🤷',
                }[proposal.reply]}
          </Icon>
          {proposal.reply === MatchingProposalReply.YES && (
            <Line
              $startFilled={!!proposal.repliedAt}
              $endFilled={!!proposal.confirmedAt}
            />
          )}
        </IconOuter>
        <StepContent>
          <Panel
            isDefaultExpanded
            header={
              <StepHeader>
                <StepTitle>
                  {!proposal.sentAt
                    ? ''
                    : !proposal.reply
                    ? 'Not replied yet'
                    : {
                        [MatchingProposalReply.YES]: 'Accepted',
                        [MatchingProposalReply.NO]: 'Declined',
                        [MatchingProposalReply.NEED_MORE_INFO]:
                          'Need more info',
                      }[proposal.reply]}
                </StepTitle>
                <DateFormater dateTime={proposal.repliedAt} noTime />
              </StepHeader>
            }
          >
            <ReplyComment text={proposal.replyComment} withQuote noCollapse />
          </Panel>
        </StepContent>
      </Step>

      {proposal.reply === MatchingProposalReply.YES && (
        <Step>
          <IconOuter>
            <Icon $filled={!!proposal.confirmedAt || !canConfirm}>
              {proposal.confirmedAt ? '👍' : canConfirm ? '🤜' : '👎'}
            </Icon>
          </IconOuter>
          <StepContent>
            <Panel
              isDefaultExpanded
              header={
                <StepHeader>
                  <StepTitle>
                    {proposal.confirmedAt
                      ? proposal.confirmedBy
                        ? 'Confirmed by'
                        : 'Confirmed'
                      : canConfirm
                      ? 'Pending confirmation'
                      : 'Someone else was confirmed'}
                  </StepTitle>
                  <DateFormater dateTime={proposal.confirmedAt} noTime />
                </StepHeader>
              }
            >
              {!!proposal.confirmedBy && (
                <UserCell user={proposal.confirmedBy} />
              )}
            </Panel>
          </StepContent>
        </Step>
      )}
    </Outer>
  )
}

export default ProposalTimeline
