/* eslint-disable camelcase */

export type PossibleTypesResultData = {
  possibleTypes: {
    Adminable: ['AssistantUser', 'HqUser']
    Assistantable: ['AssistantUser', 'HqUser', 'SandboxUser']
    BillableEntity: ['ExecutiveUser', 'HqUser', 'SandboxUser', 'Team']
    CopilotPromptSettings: [
      'CategoryCopilotPromptSettings',
      'CopilotPromptSettingsOverride',
      'MainCopilotPromptSettings',
      'TaskCopilotPromptSettings',
    ]
    CopilotPromptSettingsOverridable: [
      'CategoryCopilotPromptSettings',
      'MainCopilotPromptSettings',
      'TaskCopilotPromptSettings',
    ]
    CopilotSubpromptable: [
      'CategoryCopilotPromptSettings',
      'TaskCopilotPromptSettings',
    ]
    DossierInputable: [
      'DossierInputableAnniversaryDate',
      'DossierInputableEmail',
      'DossierInputablePhoneNumber',
      'DossierInputablePlace',
      'DossierInputableText',
    ]
    Executivable: ['ExecutiveUser', 'HqUser', 'SandboxUser']
    ExecutivableCsmSource: [
      'AssistantUser',
      'ExecutiveUser',
      'HqUser',
      'SandboxUser',
      'Team',
    ]
    FeatureTargetPredicate: [
      'FeatureTargetAssistantPredicate',
      'FeatureTargetConstantPredicate',
      'FeatureTargetCreatedAtPredicate',
      'FeatureTargetExecutivePredicate',
      'FeatureTargetFeaturePredicate',
    ]
    Feedbackable: ['CsatFeedback']
    Filable: ['AudioFile', 'File', 'ImageFile']
    InvoicingDiscountCredit: [
      'InvoicingDiscountCreditAmount',
      'InvoicingDiscountCreditHours',
    ]
    InvoicingRefundCredit: [
      'InvoicingRefundCreditAmount',
      'InvoicingRefundCreditHours',
    ]
    Matchable: ['AssistantUser', 'ExecutiveUser', 'HqUser', 'SandboxUser']
    MessageTemplate: [
      'EmailMessageTemplate',
      'SlackMessageTemplate',
      'SmsMessageTemplate',
      'TextMessageTemplate',
    ]
    TimelineItem: [
      'FeedbackTimelineItem',
      'MatchingTimelineItem',
      'StatusTimelineItem',
      'UserTimelineItem',
      'WorkspaceTimelineItem',
      'WorkspaceUserTimelineItem',
    ]
    TopicExtendedPropValue: [
      'TopicExtendedPropValueAudio',
      'TopicExtendedPropValueNumber',
      'TopicExtendedPropValueText',
    ]
    TopicFormCondition: [
      'TopicFormFieldValueCondition',
      'TopicFormOperatorCondition',
    ]
    TopicFormField: ['TopicFormFieldText']
    TopicFormFieldOption: ['TopicFormFieldOptionText']
    Userable: ['AssistantUser', 'ExecutiveUser', 'HqUser', 'SandboxUser']
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Adminable: ['AssistantUser', 'HqUser'],
    Assistantable: ['AssistantUser', 'HqUser', 'SandboxUser'],
    BillableEntity: ['ExecutiveUser', 'HqUser', 'SandboxUser', 'Team'],
    CopilotPromptSettings: [
      'CategoryCopilotPromptSettings',
      'CopilotPromptSettingsOverride',
      'MainCopilotPromptSettings',
      'TaskCopilotPromptSettings',
    ],
    CopilotPromptSettingsOverridable: [
      'CategoryCopilotPromptSettings',
      'MainCopilotPromptSettings',
      'TaskCopilotPromptSettings',
    ],
    CopilotSubpromptable: [
      'CategoryCopilotPromptSettings',
      'TaskCopilotPromptSettings',
    ],
    DossierInputable: [
      'DossierInputableAnniversaryDate',
      'DossierInputableEmail',
      'DossierInputablePhoneNumber',
      'DossierInputablePlace',
      'DossierInputableText',
    ],
    Executivable: ['ExecutiveUser', 'HqUser', 'SandboxUser'],
    ExecutivableCsmSource: [
      'AssistantUser',
      'ExecutiveUser',
      'HqUser',
      'SandboxUser',
      'Team',
    ],
    FeatureTargetPredicate: [
      'FeatureTargetAssistantPredicate',
      'FeatureTargetConstantPredicate',
      'FeatureTargetCreatedAtPredicate',
      'FeatureTargetExecutivePredicate',
      'FeatureTargetFeaturePredicate',
    ],
    Feedbackable: ['CsatFeedback'],
    Filable: ['AudioFile', 'File', 'ImageFile'],
    InvoicingDiscountCredit: [
      'InvoicingDiscountCreditAmount',
      'InvoicingDiscountCreditHours',
    ],
    InvoicingRefundCredit: [
      'InvoicingRefundCreditAmount',
      'InvoicingRefundCreditHours',
    ],
    Matchable: ['AssistantUser', 'ExecutiveUser', 'HqUser', 'SandboxUser'],
    MessageTemplate: [
      'EmailMessageTemplate',
      'SlackMessageTemplate',
      'SmsMessageTemplate',
      'TextMessageTemplate',
    ],
    TimelineItem: [
      'FeedbackTimelineItem',
      'MatchingTimelineItem',
      'StatusTimelineItem',
      'UserTimelineItem',
      'WorkspaceTimelineItem',
      'WorkspaceUserTimelineItem',
    ],
    TopicExtendedPropValue: [
      'TopicExtendedPropValueAudio',
      'TopicExtendedPropValueNumber',
      'TopicExtendedPropValueText',
    ],
    TopicFormCondition: [
      'TopicFormFieldValueCondition',
      'TopicFormOperatorCondition',
    ],
    TopicFormField: ['TopicFormFieldText'],
    TopicFormFieldOption: ['TopicFormFieldOptionText'],
    Userable: ['AssistantUser', 'ExecutiveUser', 'HqUser', 'SandboxUser'],
  },
}
export default result

type Interface = keyof PossibleTypesResultData['possibleTypes']

type PossibleTypes<I extends Interface> =
  PossibleTypesResultData['possibleTypes'][I]

type PossibleType =
  | 'AdhocExecutive'
  | 'AdhocTodo'
  | 'AdhocTodoCompletionReport'
  | 'AdhocTodoFeedback'
  | 'AdhocTodoSettings'
  | 'AdhocTodosList'
  | 'AdminRole'
  | 'AdminRolesList'
  | 'AirtableClient'
  | 'AirtableCollaborator'
  | 'AirtableDouble'
  | 'AirtableGenericNote'
  | 'AirtableLead'
  | 'AirtableLeadsList'
  | 'AirtableRecord'
  | 'AirtableTeamInvite'
  | 'AirtableTransitionRequest'
  | 'AnniversaryDate'
  | 'ArchiveFeatureOutput'
  | 'ArchiveMatchingOutput'
  | 'ArchiveTeamOutput'
  | 'ArchiveTopicTemplateOutput'
  | 'ArchiveTransitionOutput'
  | 'ArchiveUserOutput'
  | 'ArchiveWorkspaceOutput'
  | 'AssignOnboardingSpecialistOutput'
  | 'AssistantCopilot'
  | 'AssistantPerformanceMetric'
  | 'AssistantPerformanceMetricEvent'
  | 'AssistantPerformanceScore'
  | 'AssistantPerformanceScoreSeries'
  | 'AssistantPerformanceTag'
  | 'AssistantUser'
  | 'AssistantableHourlyRateUpdate'
  | 'Attachment'
  | 'AudioFile'
  | 'AudioFileTranscript'
  | 'Automation'
  | 'AutomationAction'
  | 'AutomationActionGroup'
  | 'AutomationJob'
  | 'AutomationJobLog'
  | 'AutomationJobsList'
  | 'AutomationParameter'
  | 'AutomationTrigger'
  | 'AutomationTriggersList'
  | 'AutomationsList'
  | 'BillingPauseStatus'
  | 'BugReport'
  | 'BugReportFile'
  | 'BugReportsList'
  | 'CancelAutomationJobOutput'
  | 'CancelOnboardingCallOutput'
  | 'CancelScheduledChurnOutput'
  | 'CancelScheduledEntityPricingUpdateOutput'
  | 'CancelScheduledTeamChurnOutput'
  | 'CategoryCopilotPromptSettings'
  | 'ChatThreadItem'
  | 'CheckoutSession'
  | 'CheckoutSessionLineItem'
  | 'ChurnExecutiveOutput'
  | 'ClearAssistantHourlyRateUpdatesOutput'
  | 'ClearDataloadersOutput'
  | 'ClearTopicsOutput'
  | 'CommunicationThreads'
  | 'CompleteOnboardingOutput'
  | 'CompleteTransitionCallOutput'
  | 'CompleteTransitionOutput'
  | 'ConfirmMatchingAssistantOutput'
  | 'ContractorReport'
  | 'ContractorReportsList'
  | 'Copilot'
  | 'CopilotFunction'
  | 'CopilotPromptSettingsOverride'
  | 'CreateExecutiveWorkspaceOutput'
  | 'CreateMatchingFromLeadOutput'
  | 'CreateStripeCustomerOutput'
  | 'CreateStripeInvoiceOutput'
  | 'CreateTestExecLeadOutput'
  | 'CreateTestExecMatchingOutput'
  | 'CreateTransitionSlackChannelOutput'
  | 'CsatFeedback'
  | 'Delay'
  | 'DelegationCoach'
  | 'DeleteFeedbackOutput'
  | 'DeleteTimeTrackingEntryOutput'
  | 'DequeueMatchingProposalOutput'
  | 'DiscScore'
  | 'DossierField'
  | 'DossierFolder'
  | 'DossierFolderHeader'
  | 'DossierInputableAnniversaryDate'
  | 'DossierInputableEmail'
  | 'DossierInputablePhoneNumber'
  | 'DossierInputablePlace'
  | 'DossierInputableText'
  | 'DossierInputableTextOption'
  | 'DossierSection'
  | 'EmailActivity'
  | 'EmailActivityClicked'
  | 'EmailActivityOpened'
  | 'EmailData'
  | 'EmailMessageTemplate'
  | 'EmailTemplate'
  | 'EmailThreadItem'
  | 'EntityPricing'
  | 'EventCategoryMapping'
  | 'ExecutivableCsm'
  | 'ExecutiveUser'
  | 'ExpireUserPricingOutput'
  | 'ExportChatToStudioOutput'
  | 'ExportUserBillingOutput'
  | 'Feature'
  | 'FeatureTarget'
  | 'FeatureTargetAssistantPredicate'
  | 'FeatureTargetConstantPredicate'
  | 'FeatureTargetCreatedAtPredicate'
  | 'FeatureTargetExecutivePredicate'
  | 'FeatureTargetFeaturePredicate'
  | 'FeaturesList'
  | 'FeedbackTimelineItem'
  | 'FeedbacksList'
  | 'File'
  | 'FirstCalledFunctionInfo'
  | 'FollowUpWithNewTransitionAssistantOutput'
  | 'GeoCoordinates'
  | 'GrantPoolTokensOutput'
  | 'GuardedOperation'
  | 'HqUser'
  | 'ImageFile'
  | 'InvoicedCycle'
  | 'InvoicingAgreement'
  | 'InvoicingDiscount'
  | 'InvoicingDiscountCreditAmount'
  | 'InvoicingDiscountCreditHours'
  | 'InvoicingHistoryList'
  | 'InvoicingRefund'
  | 'InvoicingRefundCreditAmount'
  | 'InvoicingRefundCreditHours'
  | 'InvoicingReport'
  | 'InvoicingReportTogglUsage'
  | 'InvoicingReportsList'
  | 'LeverOpportunitiesList'
  | 'LeverOpportunity'
  | 'LockTimeTrackingEntriesOutput'
  | 'LoginOutput'
  | 'MainCopilotPromptSettings'
  | 'MarkPaidTimeTrackingEntriesOutput'
  | 'Matching'
  | 'MatchingApplication'
  | 'MatchingOnboarding'
  | 'MatchingProfile'
  | 'MatchingProfileBioSection'
  | 'MatchingProfileHoursNeededRange'
  | 'MatchingProposal'
  | 'MatchingTeamInvite'
  | 'MatchingTimelineItem'
  | 'MatchingTransition'
  | 'MatchingTransitionSentMessage'
  | 'MatchingsList'
  | 'MessageTemplateLanguage'
  | 'MessageTemplatesList'
  | 'MetricEvent'
  | 'MetricEventProperty'
  | 'MetricEventsList'
  | 'MutateWorkspaceUserOutput'
  | 'Mutation'
  | 'Onboarding'
  | 'OnboardingAvailability'
  | 'OnboardingAvailabilitySlot'
  | 'OnboardingChecklist'
  | 'OnboardingChecklistTask'
  | 'OnboardingSplash'
  | 'OnboardingStep'
  | 'Orientation'
  | 'PayPeriod'
  | 'PayStripeInvoiceOutput'
  | 'Place'
  | 'PlaceAddress'
  | 'PlaceGeography'
  | 'PoolConfiguration'
  | 'PoolTokenBalance'
  | 'Pricing'
  | 'PricingSelection'
  | 'PricingsList'
  | 'Query'
  | 'ReadTopicsOutput'
  | 'RemoveAdhocTodoAssistantOutput'
  | 'RemoveOnboardingSpecialistOutput'
  | 'ResetOnboardingOutput'
  | 'ResetTestExecLifecycleOutput'
  | 'ReviewAutomationJobOutput'
  | 'RunAutopilotJobManuallyOutput'
  | 'SandboxUser'
  | 'SaveTopicTemplateFormOutput'
  | 'ScheduleAutopilotJobsOutput'
  | 'ScheduleOnboardingCalendar'
  | 'ScheduleOnboardingCallOutput'
  | 'ScheduleOnboardingConfig'
  | 'ScheduleTeamChurnOutput'
  | 'SelectEntityPricingOutput'
  | 'SendContractorsPaymentsEmailsOutput'
  | 'SendCsatFeedbackRequestOutput'
  | 'SendEmailFromTemplateOutput'
  | 'SendEmailMessageOutput'
  | 'SendLoginEmailOutput'
  | 'SendMatchingProposalOutput'
  | 'SendOnboardingEmailOutput'
  | 'SendSlackMessageOutput'
  | 'SendSmsMessageOutput'
  | 'SendSmsOutput'
  | 'SendStripeInvoiceOutput'
  | 'SentOnboardingEmailOutput'
  | 'SentPostOnboardingEmailOutput'
  | 'SentTransitionMessageOutput'
  | 'Setting'
  | 'SlackMessageTemplate'
  | 'SmsMessageTemplate'
  | 'SpectateWorkspaceOutput'
  | 'StatusTimelineItem'
  | 'StripeCoupon'
  | 'StripeCustomer'
  | 'StripeInvoice'
  | 'StripeInvoiceLineItem'
  | 'StripePrice'
  | 'StripeProduct'
  | 'Subscription'
  | 'SubscriptionBillingCycle'
  | 'SwitchTransitionAssistantsOutput'
  | 'SystemFeatureFlag'
  | 'SystemFeatureFlagsList'
  | 'TaskCopilotPromptSettings'
  | 'Team'
  | 'TeamSubscription'
  | 'TeamsList'
  | 'TestExec'
  | 'TextMessageTemplate'
  | 'TimeTrackingEntriesList'
  | 'TimeTrackingEntry'
  | 'TimeTrackingEntryTemplate'
  | 'TimeTrackingEntryTemplatesList'
  | 'TimeTrackingTag'
  | 'TimeTrackingTagCategory'
  | 'TimelineItemsList'
  | 'TopicExtendedPropValueAudio'
  | 'TopicExtendedPropValueNumber'
  | 'TopicExtendedPropValueText'
  | 'TopicForm'
  | 'TopicFormFieldOptionText'
  | 'TopicFormFieldText'
  | 'TopicFormFieldValueCondition'
  | 'TopicFormOperatorCondition'
  | 'TopicTemplate'
  | 'TopicTemplatesList'
  | 'UnarchiveTransitionOutput'
  | 'UnarchiveUserOutput'
  | 'UpdateAdhocTodoPoolVisibilityOutput'
  | 'UpdateAdhocTodoSettingsOutput'
  | 'UpdateAdminableOutput'
  | 'UpdateAdminableRolesOutput'
  | 'UpdateAssistantCopilotSettingsOutput'
  | 'UpdateCopilotSettingsOutput'
  | 'UpdateCsatFeedbackOutput'
  | 'UpdateDelegationCoachSettingsOutput'
  | 'UpdateMatchingOutput'
  | 'UpdateMetricEventOutput'
  | 'UpdateMetricEventPropertyOutput'
  | 'UpdateOnboardingStepOutput'
  | 'UpdateOrientationOutput'
  | 'UpdatePayPeriodOutput'
  | 'UpdateStripeCustomerIdOutput'
  | 'UpdateSystemFeatureFlagOutput'
  | 'UpdateTimeTrackingEntryBillToOutput'
  | 'UpdateTimeTrackingEntryOutput'
  | 'UpdateTransitionChangeoverOutput'
  | 'UpdateTransitionHandoverOutput'
  | 'UpdateTransitionInformingOutput'
  | 'UpdateTransitionMatchingStatusOutput'
  | 'UpdateTransitionNotepadOutput'
  | 'UpdateWorkspaceOutput'
  | 'UploadPublicFileOutput'
  | 'UpsertAdminRoleOutput'
  | 'UpsertDossierFieldOutput'
  | 'UpsertDossierFolderOutput'
  | 'UpsertDossierSectionOutput'
  | 'UpsertFeatureOutput'
  | 'UpsertMatchingProposalOutput'
  | 'UpsertMessageTemplateOutput'
  | 'UpsertPricingOutput'
  | 'UpsertSettingOutput'
  | 'UpsertTopicTemplateOutput'
  | 'UserAccount'
  | 'UserFieldsOptions'
  | 'UserProfile'
  | 'UserTimelineItem'
  | 'UsersList'
  | 'VoidStripeInvoiceOutput'
  | 'Who'
  | 'WhosList'
  | 'WorkExperience'
  | 'Workspace'
  | 'WorkspaceCategoryUsage'
  | 'WorkspaceFeature'
  | 'WorkspaceMonthlyUsage'
  | 'WorkspaceQuarterlyReport'
  | 'WorkspaceReport'
  | 'WorkspaceTimelineItem'
  | 'WorkspaceUsage'
  | 'WorkspaceUserTimelineItem'
  | 'WorkspaceWeeklyUsage'
  | 'WorkspacesList'

export function isOfType<T extends PossibleType>(typename: T) {
  return function <O extends { __typename?: string | undefined | T }>(
    o: null | undefined | O,
  ): o is O & { __typename: T } {
    return Boolean(o && '__typename' in o && o.__typename === typename)
  }
}

export function onlyIfType<T extends PossibleType>(typename: T) {
  const isValid = isOfType(typename)
  return function <O extends { __typename?: string | undefined | T }>(
    o: null | undefined | O,
  ) {
    return isValid(o) ? o : undefined
  }
}

export function isOfInterface<I extends Interface>(iFace: I) {
  return function (
    o:
      | null
      | undefined
      | { __typename?: string | undefined | PossibleTypes<I>[number] },
  ): o is { __typename: PossibleTypes<I>[number] } {
    if (!(o && '__typename' in o)) {
      return false
    }
    const possibleTypes = result.possibleTypes[iFace]
    for (const possibleType of possibleTypes) {
      if (possibleType === o.__typename) {
        return true
      }
    }
    return false
  }
}

export function onlyIfInterface<I extends Interface>(iFace: I) {
  const isValid = isOfInterface(iFace)
  return function <
    O extends { __typename?: string | undefined | PossibleTypes<I>[number] },
  >(o: null | undefined | O) {
    return isValid(o) ? o : undefined
  }
}

export const isAdminable = isOfInterface('Adminable')

export const onlyIfAdminable = onlyIfInterface('Adminable')

export const isAssistantable = isOfInterface('Assistantable')

export const onlyIfAssistantable = onlyIfInterface('Assistantable')

export const isCopilotPromptSettings = isOfInterface('CopilotPromptSettings')

export const onlyIfCopilotPromptSettings = onlyIfInterface(
  'CopilotPromptSettings',
)

export const isCopilotPromptSettingsOverridable = isOfInterface(
  'CopilotPromptSettingsOverridable',
)

export const onlyIfCopilotPromptSettingsOverridable = onlyIfInterface(
  'CopilotPromptSettingsOverridable',
)

export const isCopilotSubpromptable = isOfInterface('CopilotSubpromptable')

export const onlyIfCopilotSubpromptable = onlyIfInterface(
  'CopilotSubpromptable',
)

export const isDossierInputable = isOfInterface('DossierInputable')

export const onlyIfDossierInputable = onlyIfInterface('DossierInputable')

export const isExecutivable = isOfInterface('Executivable')

export const onlyIfExecutivable = onlyIfInterface('Executivable')

export const isFeedbackable = isOfInterface('Feedbackable')

export const onlyIfFeedbackable = onlyIfInterface('Feedbackable')

export const isFilable = isOfInterface('Filable')

export const onlyIfFilable = onlyIfInterface('Filable')

export const isMatchable = isOfInterface('Matchable')

export const onlyIfMatchable = onlyIfInterface('Matchable')

export const isMessageTemplate = isOfInterface('MessageTemplate')

export const onlyIfMessageTemplate = onlyIfInterface('MessageTemplate')

export const isTimelineItem = isOfInterface('TimelineItem')

export const onlyIfTimelineItem = onlyIfInterface('TimelineItem')

export const isTopicFormField = isOfInterface('TopicFormField')

export const onlyIfTopicFormField = onlyIfInterface('TopicFormField')

export const isUserable = isOfInterface('Userable')

export const onlyIfUserable = onlyIfInterface('Userable')

export const isBillableEntity = isOfInterface('BillableEntity')

export const onlyIfBillableEntity = onlyIfInterface('BillableEntity')

export const isExecutiveUser = isOfType('ExecutiveUser')

export const onlyIfExecutiveUser = onlyIfType('ExecutiveUser')

export const isHqUser = isOfType('HqUser')

export const onlyIfHqUser = onlyIfType('HqUser')

export const isSandboxUser = isOfType('SandboxUser')

export const onlyIfSandboxUser = onlyIfType('SandboxUser')

export const isTeam = isOfType('Team')

export const onlyIfTeam = onlyIfType('Team')

export const isExecutivableCsmSource = isOfInterface('ExecutivableCsmSource')

export const onlyIfExecutivableCsmSource = onlyIfInterface(
  'ExecutivableCsmSource',
)

export const isAssistantUser = isOfType('AssistantUser')

export const onlyIfAssistantUser = onlyIfType('AssistantUser')

export const isFeatureTargetPredicate = isOfInterface('FeatureTargetPredicate')

export const onlyIfFeatureTargetPredicate = onlyIfInterface(
  'FeatureTargetPredicate',
)

export const isFeatureTargetAssistantPredicate = isOfType(
  'FeatureTargetAssistantPredicate',
)

export const onlyIfFeatureTargetAssistantPredicate = onlyIfType(
  'FeatureTargetAssistantPredicate',
)

export const isFeatureTargetConstantPredicate = isOfType(
  'FeatureTargetConstantPredicate',
)

export const onlyIfFeatureTargetConstantPredicate = onlyIfType(
  'FeatureTargetConstantPredicate',
)

export const isFeatureTargetCreatedAtPredicate = isOfType(
  'FeatureTargetCreatedAtPredicate',
)

export const onlyIfFeatureTargetCreatedAtPredicate = onlyIfType(
  'FeatureTargetCreatedAtPredicate',
)

export const isFeatureTargetExecutivePredicate = isOfType(
  'FeatureTargetExecutivePredicate',
)

export const onlyIfFeatureTargetExecutivePredicate = onlyIfType(
  'FeatureTargetExecutivePredicate',
)

export const isFeatureTargetFeaturePredicate = isOfType(
  'FeatureTargetFeaturePredicate',
)

export const onlyIfFeatureTargetFeaturePredicate = onlyIfType(
  'FeatureTargetFeaturePredicate',
)

export const isInvoicingDiscountCredit = isOfInterface(
  'InvoicingDiscountCredit',
)

export const onlyIfInvoicingDiscountCredit = onlyIfInterface(
  'InvoicingDiscountCredit',
)

export const isInvoicingDiscountCreditAmount = isOfType(
  'InvoicingDiscountCreditAmount',
)

export const onlyIfInvoicingDiscountCreditAmount = onlyIfType(
  'InvoicingDiscountCreditAmount',
)

export const isInvoicingDiscountCreditHours = isOfType(
  'InvoicingDiscountCreditHours',
)

export const onlyIfInvoicingDiscountCreditHours = onlyIfType(
  'InvoicingDiscountCreditHours',
)

export const isInvoicingRefundCredit = isOfInterface('InvoicingRefundCredit')

export const onlyIfInvoicingRefundCredit = onlyIfInterface(
  'InvoicingRefundCredit',
)

export const isInvoicingRefundCreditAmount = isOfType(
  'InvoicingRefundCreditAmount',
)

export const onlyIfInvoicingRefundCreditAmount = onlyIfType(
  'InvoicingRefundCreditAmount',
)

export const isInvoicingRefundCreditHours = isOfType(
  'InvoicingRefundCreditHours',
)

export const onlyIfInvoicingRefundCreditHours = onlyIfType(
  'InvoicingRefundCreditHours',
)

export const isTopicExtendedPropValue = isOfInterface('TopicExtendedPropValue')

export const onlyIfTopicExtendedPropValue = onlyIfInterface(
  'TopicExtendedPropValue',
)

export const isTopicExtendedPropValueAudio = isOfType(
  'TopicExtendedPropValueAudio',
)

export const onlyIfTopicExtendedPropValueAudio = onlyIfType(
  'TopicExtendedPropValueAudio',
)

export const isTopicExtendedPropValueNumber = isOfType(
  'TopicExtendedPropValueNumber',
)

export const onlyIfTopicExtendedPropValueNumber = onlyIfType(
  'TopicExtendedPropValueNumber',
)

export const isTopicExtendedPropValueText = isOfType(
  'TopicExtendedPropValueText',
)

export const onlyIfTopicExtendedPropValueText = onlyIfType(
  'TopicExtendedPropValueText',
)

export const isTopicFormCondition = isOfInterface('TopicFormCondition')

export const onlyIfTopicFormCondition = onlyIfInterface('TopicFormCondition')

export const isTopicFormFieldValueCondition = isOfType(
  'TopicFormFieldValueCondition',
)

export const onlyIfTopicFormFieldValueCondition = onlyIfType(
  'TopicFormFieldValueCondition',
)

export const isTopicFormOperatorCondition = isOfType(
  'TopicFormOperatorCondition',
)

export const onlyIfTopicFormOperatorCondition = onlyIfType(
  'TopicFormOperatorCondition',
)

export const isTopicFormFieldOption = isOfInterface('TopicFormFieldOption')

export const onlyIfTopicFormFieldOption = onlyIfInterface(
  'TopicFormFieldOption',
)

export const isTopicFormFieldOptionText = isOfType('TopicFormFieldOptionText')

export const onlyIfTopicFormFieldOptionText = onlyIfType(
  'TopicFormFieldOptionText',
)

export const isAudioFile = isOfType('AudioFile')

export const onlyIfAudioFile = onlyIfType('AudioFile')

export const isCategoryCopilotPromptSettings = isOfType(
  'CategoryCopilotPromptSettings',
)

export const onlyIfCategoryCopilotPromptSettings = onlyIfType(
  'CategoryCopilotPromptSettings',
)

export const isCopilotPromptSettingsOverride = isOfType(
  'CopilotPromptSettingsOverride',
)

export const onlyIfCopilotPromptSettingsOverride = onlyIfType(
  'CopilotPromptSettingsOverride',
)

export const isCsatFeedback = isOfType('CsatFeedback')

export const onlyIfCsatFeedback = onlyIfType('CsatFeedback')

export const isDossierInputableAnniversaryDate = isOfType(
  'DossierInputableAnniversaryDate',
)

export const onlyIfDossierInputableAnniversaryDate = onlyIfType(
  'DossierInputableAnniversaryDate',
)

export const isDossierInputableEmail = isOfType('DossierInputableEmail')

export const onlyIfDossierInputableEmail = onlyIfType('DossierInputableEmail')

export const isDossierInputablePhoneNumber = isOfType(
  'DossierInputablePhoneNumber',
)

export const onlyIfDossierInputablePhoneNumber = onlyIfType(
  'DossierInputablePhoneNumber',
)

export const isDossierInputablePlace = isOfType('DossierInputablePlace')

export const onlyIfDossierInputablePlace = onlyIfType('DossierInputablePlace')

export const isDossierInputableText = isOfType('DossierInputableText')

export const onlyIfDossierInputableText = onlyIfType('DossierInputableText')

export const isEmailMessageTemplate = isOfType('EmailMessageTemplate')

export const onlyIfEmailMessageTemplate = onlyIfType('EmailMessageTemplate')

export const isFeedbackTimelineItem = isOfType('FeedbackTimelineItem')

export const onlyIfFeedbackTimelineItem = onlyIfType('FeedbackTimelineItem')

export const isFile = isOfType('File')

export const onlyIfFile = onlyIfType('File')

export const isImageFile = isOfType('ImageFile')

export const onlyIfImageFile = onlyIfType('ImageFile')

export const isMainCopilotPromptSettings = isOfType('MainCopilotPromptSettings')

export const onlyIfMainCopilotPromptSettings = onlyIfType(
  'MainCopilotPromptSettings',
)

export const isMatchingTimelineItem = isOfType('MatchingTimelineItem')

export const onlyIfMatchingTimelineItem = onlyIfType('MatchingTimelineItem')

export const isSlackMessageTemplate = isOfType('SlackMessageTemplate')

export const onlyIfSlackMessageTemplate = onlyIfType('SlackMessageTemplate')

export const isSmsMessageTemplate = isOfType('SmsMessageTemplate')

export const onlyIfSmsMessageTemplate = onlyIfType('SmsMessageTemplate')

export const isStatusTimelineItem = isOfType('StatusTimelineItem')

export const onlyIfStatusTimelineItem = onlyIfType('StatusTimelineItem')

export const isTaskCopilotPromptSettings = isOfType('TaskCopilotPromptSettings')

export const onlyIfTaskCopilotPromptSettings = onlyIfType(
  'TaskCopilotPromptSettings',
)

export const isTextMessageTemplate = isOfType('TextMessageTemplate')

export const onlyIfTextMessageTemplate = onlyIfType('TextMessageTemplate')

export const isTopicFormFieldText = isOfType('TopicFormFieldText')

export const onlyIfTopicFormFieldText = onlyIfType('TopicFormFieldText')

export const isUserTimelineItem = isOfType('UserTimelineItem')

export const onlyIfUserTimelineItem = onlyIfType('UserTimelineItem')

export const isWorkspaceTimelineItem = isOfType('WorkspaceTimelineItem')

export const onlyIfWorkspaceTimelineItem = onlyIfType('WorkspaceTimelineItem')

export const isWorkspaceUserTimelineItem = isOfType('WorkspaceUserTimelineItem')

export const onlyIfWorkspaceUserTimelineItem = onlyIfType(
  'WorkspaceUserTimelineItem',
)
