import Panel from '@atlaskit/panel'

import DateFormater from '../../../../components/DateFormater'
import { ErrorEmptyState } from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import TimelineContainer from '../../../../components/timeline/TimelineContainer'
import {
  Outer as TimelineOuter,
  Icon,
  IconOuter,
  Line,
  Step,
  StepHeader,
  StepTitle,
} from '../../../../components/timeline/styled'
import useTimeline from '../../../../components/timeline/useTimeline'
import { TimelineEvent, useGetUserTimelineQuery } from '../../../../graphql'
import useLocalStorage from '../../../../lib/useLocalStorage'
import Item from '../../../operations/Matchings/List/Item'
import WorkspaceItem from '../../Workspace/WorkspaceItem'

import CSATFeedbackRow from './components/CSATFeedbackRow'
import WorkspaceUserRow from './components/WorkspaceUserRow'
import { StepContent } from './styled'

type Props = {
  userId: string
}

const EVENTS_EXPANDED_DEFAULT = [
  TimelineEvent.MATCHING_CREATED,
  TimelineEvent.WORKSPACE_USER_ADDED,
  TimelineEvent.MATCHING_PROPOSAL_REPLIED,
]

const TITLES_HIDDEN_BY_DEFAULT = ['declined proposal']

const Timeline = ({ userId }: Props) => {
  const [excludeList, setExcludeList, clearExcludeList] = useLocalStorage<
    TimelineEvent[]
  >({
    key: 'timelineFilterExcludes',
    initialValue: [],
  })

  const { data, loading, error } = useGetUserTimelineQuery({
    variables: {
      userId,
      excludes: excludeList,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { sortedItems, lastItemIndex, isSortedItemsEmpty, uniqueCategories } =
    useTimeline(data?.timelineItemsList.items, excludeList)

  if (error) return <ErrorEmptyState error={error} />

  return (
    <TimelineContainer
      uniqueCategories={uniqueCategories}
      excludeList={excludeList}
      setExcludeList={setExcludeList}
      clearExcludeList={clearExcludeList}
    >
      <TimelineOuter>
        {isSortedItemsEmpty ? (
          loading ? (
            <LoadingSpinner />
          ) : (
            <ErrorEmptyState error={new Error('Woops, there is no data')} />
          )
        ) : (
          sortedItems.map((item, index) => (
            <Step key={`event-${index}`}>
              <IconOuter>
                <Icon $filled>{item.emoji}</Icon>
                {index !== lastItemIndex && <Line $startFilled $endFilled />}
              </IconOuter>
              <StepContent>
                <Panel
                  isDefaultExpanded={
                    EVENTS_EXPANDED_DEFAULT.includes(item.event) &&
                    !TITLES_HIDDEN_BY_DEFAULT.includes(item.title.toLowerCase())
                  }
                  header={
                    <StepHeader>
                      <StepTitle>{item.title}</StepTitle>
                      <DateFormater dateTime={item.activityAt} noTime />
                    </StepHeader>
                  }
                >
                  {item.__typename === 'WorkspaceUserTimelineItem' ? (
                    <>
                      <WorkspaceUserRow
                        user={item.user}
                        startDate={item.startDate}
                        endDate={item.endDate}
                        isCoverage={item.isCoverage}
                      />
                      <WorkspaceItem
                        key={item.workspace.id}
                        isSelected={false}
                        workspace={item.workspace}
                        userId={userId}
                      />
                    </>
                  ) : item.__typename === 'WorkspaceTimelineItem' ? (
                    <WorkspaceItem
                      key={item.workspace.id}
                      isSelected={false}
                      workspace={item.workspace}
                      userId={userId}
                    />
                  ) : item.__typename === 'MatchingTimelineItem' ? (
                    <Item matching={item.matching} />
                  ) : item.__typename === 'FeedbackTimelineItem' ? (
                    item.feedback.workspace?.id && (
                      <CSATFeedbackRow
                        counterpartUser={item.counterpartUser}
                        feedback={item.feedback}
                        workspaceId={item.feedback.workspace.id}
                      />
                    )
                  ) : (
                    // 💩 Panel doesn't like it when it's children prop is empty
                    <></>
                  )}
                </Panel>
              </StepContent>
            </Step>
          ))
        )}
      </TimelineOuter>
    </TimelineContainer>
  )
}

export default Timeline
