import { useCallback } from 'react'

import Modal from '../../../../components/Modal'
import { TextField } from '../../../../components/form'
import {
  UpdateTeamBillingInput,
  useUpdateTeamBillingMutation,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import useValues from '../../../../lib/useValues'

import { Button } from './styled'

type Props = {
  teamId: string
  maxioSubscriptionId?: string | null
}

const MaxioIdInput = ({ teamId, maxioSubscriptionId }: Props) => {
  const [isConfirming, showConfirming, hideConfirming] = useSwitch(false)

  const [formValues, { setters }] = useValues<UpdateTeamBillingInput>(
    {
      maxioSubscriptionId: maxioSubscriptionId ?? null,
    },
    ['maxioSubscriptionId'],
  )

  const [_updateTeamBilling, { loading }] = useUpdateTeamBillingMutation()

  const updateTeamBilling = useCallback(() => {
    _updateTeamBilling({
      variables: {
        teamId,
        input: {
          maxioSubscriptionId: formValues.maxioSubscriptionId || null,
        },
      },
    })
  }, [formValues.maxioSubscriptionId, teamId, _updateTeamBilling])

  const onUpdateMaxioSubscriptionId = useCallback(() => {
    if (!formValues.maxioSubscriptionId) {
      showConfirming()
    } else {
      updateTeamBilling()
    }
  }, [formValues.maxioSubscriptionId, showConfirming, updateTeamBilling])

  const onConfirm = useCallback(() => {
    updateTeamBilling()
    hideConfirming()
  }, [hideConfirming, updateTeamBilling])

  const isInvalid = !formValues.maxioSubscriptionId?.match(/^\d*$/)
  const isDisabled =
    (!maxioSubscriptionId && !formValues.maxioSubscriptionId) ||
    formValues.maxioSubscriptionId === maxioSubscriptionId ||
    isInvalid

  return (
    <>
      <Modal
        isOpen={isConfirming}
        actions={[
          {
            text: 'Remove Maxio Subscription Id',
            isLoading: loading,
            onClick: onConfirm,
          },
          { text: 'Cancel', onClick: hideConfirming },
        ]}
        appearance={'danger'}
        autoFocus={false}
        onClose={close}
      >
        <br />
        {`Please confirm you want to remove Maxio Billing and revert to legacy Cockpit Billing.`}
        <br />
      </Modal>
      <TextField
        defaultValue={formValues.maxioSubscriptionId ?? undefined}
        onChangeValue={setters.maxioSubscriptionId}
        placeholder={'Add Maxio Subscription ID'}
        isInvalid={isInvalid}
      />
      <Button
        appearance={'primary'}
        isLoading={loading}
        onClick={onUpdateMaxioSubscriptionId}
        isDisabled={isDisabled}
      >
        {'Save'}
      </Button>
    </>
  )
}

export default MaxioIdInput
