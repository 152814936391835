import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { borderRadius, colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React, { ComponentProps, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import ErrorBanner from '../../../components/ErrorBanner'
import Section from '../../../components/Section'
import { LoadingSpinner } from '../../../components/Spinner'
import History from '../../../components/billing/History'
import PricingPackage from '../../../components/billing/PricingPackage'
import StripeCustomer from '../../../components/billing/StripeCustomer'
import { Field } from '../../../components/form'
import UserCell from '../../../components/users/Cell'
import {
  useCreateStripeCustomerMutation,
  useGetUserBillingInfoQuery,
  onlyIfExecutivable,
  useExpireUserPricingMutation,
  useSelectEntityPricingMutation,
  useCancelScheduledEntityPricingUpdateMutation,
  useGetUserInvoicingHistoryListQuery,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import { PricingDrawer } from '../../finances/Pricings/Pricing'

const InvoicedUserRow = styled.div`
  border-radius: ${borderRadius}px;
  &:hover {
    background-color: ${colors.backgroundHover()};
  }
`

interface Props extends ComponentProps<'div'> {
  userId: string
}

const graphDateToJsDate = (date: string | Date | undefined) =>
  date ? new Date(date) : undefined

const Billing = ({ userId }: Props) => {
  const [isSelectingPricing, startSelectingPricing, stopSelectingPricing] =
    useSwitch(false)

  const [isExpiringPricing, startExpiringPricing, stopExpiringPricing] =
    useSwitch(false)

  const {
    data: userbillingInfoData,
    loading: loadingGet,
    error: errorGet,
  } = useGetUserBillingInfoQuery({
    variables: { userId },
  })

  const {
    data: invoicingHistoryData,
    loading: loadingInvoicingHistory,
    error: errorInvoicingHistory,
    refetch: refetchInvoicingHistory,
  } = useGetUserInvoicingHistoryListQuery({
    variables: { userId },
  })

  const [
    createStripeCustomer,
    { loading: loadingCreateStripeCustomer, error: errorCreateStripeCustomer },
  ] = useCreateStripeCustomerMutation({
    variables: { input: { entityId: userId } },
  })

  const [
    selectEntityPricing,
    { loading: entityPricingLoading, error: entityPricingError },
  ] = useSelectEntityPricingMutation({
    onCompleted: () => {
      stopSelectingPricing()
      refetchInvoicingHistory()
    },
  })

  const [
    cancelScheduledPricing,
    {
      loading: loadingCancelScheduledPricing,
      error: errorCancelScheduledPricing,
    },
  ] = useCancelScheduledEntityPricingUpdateMutation({
    onCompleted: () => {
      refetchInvoicingHistory()
    },
  })

  const [
    expireUserPricing,
    { loading: loadingExpirePricing, error: errorExpirePricing },
  ] = useExpireUserPricingMutation({
    onCompleted: stopExpiringPricing,
  })

  const user = onlyIfExecutivable(userbillingInfoData?.user)
  const invoicedUsersData = userbillingInfoData?.dependentUsersList

  const stripeCustomer = user?.stripeCustomer

  const currentUserPricing = user?.currentPricing
  const pricingSelections = user?.pricingSelections || []

  const isNotOwnUserPricing = currentUserPricing?.owner?.id !== userId
  const error =
    errorGet ||
    errorCreateStripeCustomer ||
    entityPricingError ||
    errorExpirePricing ||
    errorInvoicingHistory ||
    errorCancelScheduledPricing

  const loading =
    loadingGet ||
    loadingCreateStripeCustomer ||
    entityPricingLoading ||
    loadingExpirePricing ||
    loadingInvoicingHistory ||
    loadingCancelScheduledPricing

  const otherInvoicedUsers = invoicedUsersData?.items?.filter(
    ({ id }) => id !== userId,
  )
  const hasOtherInvoicedUsers = !!otherInvoicedUsers?.length

  const invoicingItems = invoicingHistoryData?.invoicingHistoryList.items

  const nextInvoicedCycle = useMemo(
    () => invoicingItems?.find((item) => item.status === 'NEXT'),
    [invoicingItems],
  )
  const nextInvoicingEntityPricing = nextInvoicedCycle?.invoicedPricing

  const currentInvoicedCycle = useMemo(
    () => invoicingItems?.find((item) => item.status === 'CURRENT'),
    [invoicingItems],
  )
  const currentInvoicingEntityPricing = currentInvoicedCycle?.invoicedPricing

  const thisCycleDates = {
    start: graphDateToJsDate(currentInvoicedCycle?.cycleStartDate),
    end: graphDateToJsDate(currentInvoicedCycle?.cycleEndDate),
  }

  const nextCycleDates = {
    start: graphDateToJsDate(nextInvoicedCycle?.cycleStartDate),
    end: graphDateToJsDate(nextInvoicedCycle?.cycleEndDate),
  }

  const startDate = useMemo(() => {
    const startDate = invoicingHistoryData?.user.startDate
    return startDate ? new Date(startDate) : undefined
  }, [invoicingHistoryData?.user.startDate])

  return (
    <>
      <ErrorBanner error={error} />

      {!isNotOwnUserPricing && (
        <StripeCustomer
          stripeCustomer={stripeCustomer}
          loading={loading}
          createStripeCustomer={createStripeCustomer}
        />
      )}

      {/* PRICING PACKAGE */}

      <PricingPackage
        entity={user}
        isExpiringPricing={isExpiringPricing}
        currentEntityPricing={currentInvoicingEntityPricing}
        nextEntityPricing={nextInvoicingEntityPricing}
        startExpiringPricing={startExpiringPricing}
        stopExpiringPricing={stopExpiringPricing}
        selectEntityPricing={selectEntityPricing}
        isSelectingPricing={isSelectingPricing}
        startSelectingPricing={startSelectingPricing}
        stopSelectingPricing={stopSelectingPricing}
        cancelScheduledPricing={cancelScheduledPricing}
        expireUserPricing={expireUserPricing}
        billingCycleDates={{
          thisCycle: thisCycleDates,
          nextCycle: nextCycleDates,
        }}
      />

      {/* INVOICED USER */}

      <Section title={'Invoicing info'}>
        <SectionMessage
          appearance={'information'}
          title={'Bill To User'}
          actions={[
            user?.invoicedUser?.stripeCustomer?.id ? (
              <SectionMessageAction
                onClick={() =>
                  window.open(
                    `https://dashboard.stripe.com/customers/${encodeURIComponent(
                      user?.invoicedUser?.stripeCustomer?.id || '',
                    )}`,
                    '_blank',
                  )
                }
              >
                {'Open in Stripe'}
              </SectionMessageAction>
            ) : (
              <SectionMessageAction onClick={() => createStripeCustomer()}>
                {'Create Stripe customer'}
              </SectionMessageAction>
            ),
          ]}
        >
          <Tooltip
            content={
              "The billed user is based on the user's team principal and cannot be edited."
            }
          >
            <Field>
              <UserCell user={user?.invoicedUser} size={'small'} />
            </Field>
          </Tooltip>
        </SectionMessage>

        {hasOtherInvoicedUsers && (
          <Section title={'Other users billing to this customer'}>
            {otherInvoicedUsers.map((invoicedUser) => (
              <InvoicedUserRow key={invoicedUser.id}>
                <NavLink to={`/users/${invoicedUser.id}`}>
                  <UserCell user={invoicedUser} />
                </NavLink>
              </InvoicedUserRow>
            ))}
          </Section>
        )}
      </Section>

      {/* PRICING PACKAGE HISTORY */}

      <History
        pricingSelections={pricingSelections}
        pricingSelectionsLoading={loading}
        invoicedCycleItems={
          invoicingHistoryData?.invoicingHistoryList.items || []
        }
        invoicingHistoryError={errorInvoicingHistory}
        invoicingHistoryLoading={loadingInvoicingHistory}
        startDate={startDate}
        isArchived={invoicingHistoryData?.user.isArchived || false}
      />

      <LoadingSpinner show={loading} />
      <PricingDrawer />
    </>
  )
}

export default Billing
