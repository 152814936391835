import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import { Duration } from 'luxon'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Field } from '../../../../components/form'
import { useTimeTrackingGeneralInfosQuery } from '../../../../graphql'
import getTimeTrackingEntriesListQueryVariables from '../getTimeTrackingEntriesListQueryVariables'
import useTimeEntriesLockedAt from '../useTimeEntriesLockedAt'

import Actions from './Actions'
import FilterInputs, { Props as FilterInputsProps } from './FilterInputs'

const Outer = styled.div`
  padding: 0px 16px;
  border-bottom: 1px solid ${colors.backgroundHover};

  & > div {
    margin-bottom: 8px;
  }
`

const BottomBarOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

const DurationOuter = styled.div`
  margin: 7px 60px 0px 0px;
  font-weight: 500;
  font-size: 26px;
  line-height: 20px;
`

type Props = FilterInputsProps

const Header = ({ filter, setters }: Props) => {
  const timeTrackingEntriesListQueryVariables = useMemo(
    () => getTimeTrackingEntriesListQueryVariables(filter),
    [filter],
  )
  const { data } = useTimeTrackingGeneralInfosQuery({
    variables: timeTrackingEntriesListQueryVariables,
  })

  const totalDuration = useMemo(() => {
    const maybeISODuration = data?.timeTrackingEntriesList.totalDuration
    if (!maybeISODuration) return

    return Duration.fromISO(maybeISODuration)
  }, [data])

  const lockedAt = useTimeEntriesLockedAt(timeTrackingEntriesListQueryVariables)

  return (
    <Outer>
      <PageHeader
        actions={<Actions filter={filter} lockedAt={lockedAt} />}
        bottomBar={
          <BottomBarOuter>
            <FilterInputs filter={filter} setters={setters} />
            {totalDuration && (
              <Field label={'Total hours'}>
                <Tooltip
                  content={`${totalDuration.as('hours').toFixed(2)} hours`}
                >
                  <DurationOuter>
                    {totalDuration.toFormat('hh:mm')}
                  </DurationOuter>
                </Tooltip>
              </Field>
            )}
          </BottomBarOuter>
        }
      >
        {'Time Entries'}
      </PageHeader>
    </Outer>
  )
}

export default Header
