import Button from '@atlaskit/button'

import { formatDateTime } from '../../pages/autopilot/utils'

import { Outer } from './styled'

type Props = {
  name?: string | null
  delayUntil: string | Date
  onCancelScheduledChurn: () => void
}

const PendingChurnAutomationJob = ({
  name = 'Unknown entity',
  delayUntil,
  onCancelScheduledChurn,
}: Props) => {
  return (
    <Outer>
      <p>
        {`🤖 ${name} is scheduled for churn on `}
        <b>{formatDateTime(delayUntil)}</b>
      </p>
      <Button appearance={'danger'} onClick={onCancelScheduledChurn}>
        {'Cancel Scheduled Churn'}
      </Button>
    </Outer>
  )
}

export default PendingChurnAutomationJob
