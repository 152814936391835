import LockIcon from '@atlaskit/icon/glyph/lock-filled'
import UnlockIcon from '@atlaskit/icon/glyph/unlock-filled'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React, { ComponentProps, useMemo } from 'react'
import styled from 'styled-components'

import {
  TimeTrackingEntry_TaskInfoFragment,
  TimeTrackingEntry_StatusInfoFragment,
} from '../../../../graphql'
import { ReactComponent as TogglIcon } from '../icons/toggl.svg'

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconOuter = styled.div`
  display: flex;
  margin-right: 16px;
  border-radius: 3px;
  /* T-4768 Disbaled colors.N60 */
  color: ${colors.N100};
  :hover {
    background-color: ${colors.backgroundHover};
  }
`

const Tag = styled.span`
  white-space: pre-wrap;
  color: ${colors.purple};
`

enum EntryStatus {
  Locked = 'locked',
  Unlocked = 'unlocked',
  Toggl = 'toggl',
}

// T-5254 Ask Amy about editable status
type IconProps = {
  entryStatus: EntryStatus
}

const Icon = ({ entryStatus }: IconProps) => {
  const tooltipContent = useMemo(() => {
    switch (entryStatus) {
      case EntryStatus.Locked:
        return 'The time entry is locked'
      case EntryStatus.Toggl:
        return 'This time entry can be edited in Toggl only'
      case EntryStatus.Unlocked:
        return 'The time entry is unlocked for EAs'
    }
  }, [entryStatus])

  return (
    <Tooltip content={tooltipContent}>
      {(tooltipProps) => (
        <IconOuter {...tooltipProps}>
          {(() => {
            switch (entryStatus) {
              case EntryStatus.Locked:
                return <LockIcon label={'Locked'} />
              case EntryStatus.Toggl:
                return <TogglIcon />
              case EntryStatus.Unlocked:
                return <UnlockIcon label={'Unlocked'} />
            }
          })()}
        </IconOuter>
      )}
    </Tooltip>
  )
}

type Props = Pick<ComponentProps<'div'>, 'className'> & {
  entry: TimeTrackingEntry_TaskInfoFragment &
    TimeTrackingEntry_StatusInfoFragment
}

const Title = ({
  className,
  entry: { title, isToggl, lockedAt, tags },
}: Props) => {
  const tagLabel = tags[0]?.label

  const entryStatus = useMemo((): EntryStatus => {
    if (isToggl) {
      return EntryStatus.Toggl
    }

    if (lockedAt) {
      return EntryStatus.Locked
    }

    return EntryStatus.Unlocked
  }, [isToggl, lockedAt])

  return (
    <Outer className={className}>
      <Icon entryStatus={entryStatus} />
      <div>
        <span>{title || '[REDACTED]'}</span>
        {tagLabel && <Tag>{` • ${tagLabel}`}</Tag>}
      </div>
    </Outer>
  )
}

export default Title
