import { DateTime } from 'luxon'
import { ComponentProps } from 'react'

import { Dd, Dl, Dt } from '../../../../components/DlDtDd'
import ErrorBanner from '../../../../components/ErrorBanner'
import Guard from '../../../../components/Guard'
import MaxioLinkButton from '../../../../components/MaxioLinkButton'
import Spinner from '../../../../components/Spinner'
import TeamStateBanner from '../../../../components/billing/TeamStateBanner'
import { Field } from '../../../../components/form'
import {
  TeamSubscriptionType,
  useGetTeamBillingInfoQuery,
} from '../../../../graphql'
import { formatDateTime } from '../../../autopilot/utils'

import MaxioIdInput from './MaxioIdInput'
import { Outer, Row } from './styled'

interface Props extends ComponentProps<'div'> {
  teamId: string
}

const Billing = ({ teamId }: Props) => {
  const {
    data,
    loading: getTeamBillingLoading,
    error: getTeamBillingError,
  } = useGetTeamBillingInfoQuery({
    variables: { id: teamId },
  })

  const { billingPauseStatus, subscription } = data?.team || {}

  const pauseStartAt = billingPauseStatus?.startAt
    ? DateTime.fromJSDate(new Date(billingPauseStatus.startAt))
    : undefined
  const pauseEndAt = billingPauseStatus?.endAt
    ? DateTime.fromJSDate(new Date(billingPauseStatus.endAt))
    : undefined

  const subId =
    subscription?.type === TeamSubscriptionType.MAXIO ? subscription?.id : ''

  const error =
    getTeamBillingError ||
    // 🚸 display error if it is a Maxio subscription but we don't have a
    // subscription state. It should always be there, so we use it as a proxy
    // to know if we failed to retrieve the subscription from Maxio.
    (data?.team.subscription?.type === TeamSubscriptionType.MAXIO &&
    !data.team.subscription.state
      ? new Error('Maxio subscription could not be found')
      : undefined)

  return (
    <>
      <Outer>
        <ErrorBanner error={error} />
        {getTeamBillingLoading ? (
          <Spinner />
        ) : (
          <>
            <Guard
              operationId={'Mutation.updateTeamBilling'}
              policy={'overlay'}
            >
              <Field label={'Maxio Subscription ID'}>
                <Row>
                  <MaxioIdInput maxioSubscriptionId={subId} teamId={teamId} />
                </Row>
              </Field>
            </Guard>
            <TeamStateBanner
              teamId={teamId}
              isPaused={!!billingPauseStatus}
              startAt={pauseStartAt}
              endAt={pauseEndAt}
              subscription={subscription}
            />
            {subscription?.state && subscription.currentBillingCycle && (
              <Dl dtWidth={'220px'}>
                {subId ? (
                  <>
                    <Dt>{'Subscription Id'}</Dt>
                    <Dd>
                      {subId}{' '}
                      {subscription.maxioUrl && (
                        <MaxioLinkButton maxioUrl={subscription.maxioUrl} />
                      )}
                    </Dd>
                    <Dt>{'Customer'}</Dt>
                    <Dd>{subscription.customerDisplayName}</Dd>
                  </>
                ) : (
                  <>
                    <Dt>{'Billing Type'}</Dt>
                    <Dd>{'Cockpit'}</Dd>
                  </>
                )}
                <Dt>{'State'}</Dt>
                <Dd>{subscription.state}</Dd>
                <Dt>{'Product Name'}</Dt>
                <Dd>{subscription?.productName}</Dd>
                <Dt>{'Product Price'}</Dt>
                <Dd>{`$${subscription?.productPrice}/month`}</Dd>
                <Dt>{'Current Cycle Start Date'}</Dt>
                <Dd>
                  {formatDateTime(subscription.currentBillingCycle.startDate)}
                </Dd>
                <Dt>{'Current Cycle End Date'}</Dt>
                <Dd>
                  {formatDateTime(subscription.currentBillingCycle.endDate)}
                </Dd>
                <Dt>{'Current Cycle Hours Allocated'}</Dt>
                <Dd>{subscription.currentBillingCycle.hours}</Dd>
              </Dl>
            )}
          </>
        )}
      </Outer>
    </>
  )
}

export default Billing
