import { DatePicker } from '@atlaskit/datetime-picker'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Banner } from '../../../components/Banners/style'
import Modal, { Props as ModalProps } from '../../../components/Modal'
import { ZIRTUAL_CHURN_REASONS } from '../../../components/churn/constants'
import { Field, Select, TextArea, Checkbox } from '../../../components/form'
import { formatDate } from '../../../components/utils'
import {
  ScheduleTeamChurnInput,
  TeamFragment,
  useScheduleTeamChurnMutation,
} from '../../../graphql'
import useValues from '../../../lib/useValues'

const Notice = styled.p`
  font-size: smaller;
  ul {
    padding-left: 16px;
    li {
      list-style: disc;
    }
  }
`

type Props = ModalProps & {
  team: TeamFragment
  scheduleTeamChurn: ReturnType<typeof useScheduleTeamChurnMutation>[0]
  loading: boolean
}

const today = DateTime.local()
const endOfMonth = today.endOf('month')

const ChurnTeamModal = ({
  team,
  scheduleTeamChurn,
  loading,
  onClose,
  onCloseComplete: _onCloseComplete,
  ...modalProps
}: Props) => {
  const { endDate: endDateData } = team.subscription?.currentBillingCycle || {}
  const endDateTime =
    typeof endDateData === 'string'
      ? DateTime.fromISO(endDateData)
      : endDateData
      ? DateTime.fromJSDate(endDateData)
      : endOfMonth
  const [values, { setters, reset }] = useValues<
    Partial<ScheduleTeamChurnInput>
  >(
    {
      churnDecisionOn: today.toISODate(),
      churnAt: endDateTime.toISODate(),
      endDoubleSupportOn: endDateTime.toISODate(),
    },
    [
      'notepad',
      'churnDecisionOn',
      'churnAt',
      'endDoubleSupportOn',
      'reason',
      'interestedInPlacement',
    ],
  )

  const onRecordChurn = useCallback(() => {
    const {
      churnDecisionOn,
      reason,
      endDoubleSupportOn,
      interestedInPlacement,
      churnAt,
      ...input
    } = values
    if (team?.id && reason) {
      scheduleTeamChurn({
        variables: {
          input: {
            teamId: team.id,
            ...input,
            reason,
            churnDecisionOn,
            endDoubleSupportOn,
            interestedInPlacement,
            churnAt,
          },
        },
      })
      onClose && onClose()
    }
  }, [onClose, scheduleTeamChurn, team.id, values])

  const actions = useMemo(
    () => [
      {
        text: 'Schedule churn',
        isDisabled: !(
          team?.id &&
          values.reason &&
          values.churnDecisionOn &&
          values.endDoubleSupportOn
        ),
        isLoading: loading,
        onClick: onRecordChurn,
      },
      { text: 'Cancel', onClick: onClose },
    ],
    [
      team?.id,
      values.reason,
      values.churnDecisionOn,
      values.endDoubleSupportOn,
      loading,
      onRecordChurn,
      onClose,
    ],
  )

  const onChurnAtChange = useCallback(
    (value: string) => {
      if (!setters.churnAt || !setters.endDoubleSupportOn) return
      setters.churnAt(value)
      setters.endDoubleSupportOn(value)
    },
    [setters],
  )

  return (
    <Modal
      actions={actions}
      heading={`Is ${team?.name} Team churning?`}
      appearance={'danger'}
      width={'small'}
      autoFocus={false}
      onCloseComplete={(e) => [reset(), _onCloseComplete?.(e)]}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      {...modalProps}
    >
      <Banner $index={0} $isExpanded>
        {`Team will be scheduled to churn on ${formatDate(values.churnAt)}.`}
      </Banner>
      <Field
        label={'Churn Decision Date'}
        isRequired
        helperMessage={'The day the exec communicated their decision to churn.'}
      >
        <DatePicker
          value={values.churnDecisionOn || undefined}
          onChange={setters.churnDecisionOn}
          maxDate={today.toISODate()}
        />
      </Field>
      <Field
        label={'Churn At'}
        isRequired
        helperMessage={
          'The end date of billing cycle will compute their last day'
        }
      >
        <Select
          value={{
            value: values.churnAt || undefined,
            label: values.churnAt
              ? DateTime.fromISO(values.churnAt).toLocaleString()
              : undefined,
          }}
          options={[
            { label: 'This Billing Cycle', value: endDateTime.toISODate() },
            {
              label: 'Next Billing Cycle',
              value: endDateTime.plus({ month: 1 }).toISODate(),
            },
            {
              label: 'In 2 Billing Cycles',
              value: endDateTime.plus({ month: 2 }).toISODate(),
            },
            {
              label: 'In 3 Billing Cycles',
              value: endDateTime.plus({ month: 3 }).toISODate(),
            },
          ]}
          onChangeValue={onChurnAtChange}
        />
      </Field>

      <Field
        label={'End Double Support On'}
        isRequired
        helperMessage={'The day the double should stop supporting the team.'}
      >
        <DatePicker
          value={values.endDoubleSupportOn || undefined}
          onChange={setters.endDoubleSupportOn}
          maxDate={values.endDoubleSupportOn || undefined}
        />
      </Field>
      <Field
        label={'Reason'}
        isRequired
        helperMessage={'Will be recorded in cockpit.'}
      >
        <Select
          options={ZIRTUAL_CHURN_REASONS.map((r) => ({ label: r, value: r }))}
          value={{ label: values.reason, value: values.reason }}
          onChangeValue={setters.reason}
          maxMenuHeight={190}
        />
      </Field>
      <Field label={'Notes'} helperMessage={'Will be recorded in cockpit.'}>
        <TextArea
          value={values.notepad || undefined}
          onChangeValue={setters.notepad}
          rows={4}
        />
      </Field>
      <Field label={'Interested in double placement'}>
        <Checkbox
          isChecked={values.interestedInPlacement || undefined}
          onChangeValue={setters.interestedInPlacement}
        />
      </Field>

      <br />
      <Notice>
        {'Scheduling a churn will:'}
        <ul>
          <li>
            {'Immediately post to HQ Slack in #churn to let the team know.'}
          </li>
          <li>{'Store information in cockpit.'}</li>
          <li>
            {
              'Will churn the team on the last day of the selected billing cycle.'
            }
          </li>
          <li>
            {
              'Will churn any executives who are only on this team at the end of the billing cycle.'
            }
          </li>
          <li>
            {
              "You will be able to cancel a scheduled team churn automation until it's scheduled date."
            }
          </li>
        </ul>
      </Notice>
    </Modal>
  )
}

export default ChurnTeamModal
