/* eslint-disable camelcase */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
export const namedOperations = {
  Query: {
    GetUserFieldsOptions: 'GetUserFieldsOptions',
    ListUsers: 'ListUsers',
    GetUser: 'GetUser',
    GetWorkspaceUser: 'GetWorkspaceUser',
    GetUserNavigation: 'GetUserNavigation',
    GetUserProfile: 'GetUserProfile',
    GetUserBillingInfo: 'GetUserBillingInfo',
    GetAssistantOnboardingAvailability: 'GetAssistantOnboardingAvailability',
    GetAssistant: 'GetAssistant',
    GetAssistantHourlyRateUpdates: 'GetAssistantHourlyRateUpdates',
    GetWho: 'GetWho',
    ListWhos: 'ListWhos',
    ListEmailTemplates: 'ListEmailTemplates',
    GetEmailTemplateById: 'GetEmailTemplateById',
    GetEmailActivities: 'GetEmailActivities',
    ListFeatures: 'ListFeatures',
    ListWorkspace: 'ListWorkspace',
    ListTopicTemplates: 'ListTopicTemplates',
    GetWorkspace: 'GetWorkspace',
    GetWorkspaceOnboarding: 'GetWorkspaceOnboarding',
    GetWorkspaceOnboardingChecklist: 'GetWorkspaceOnboardingChecklist',
    GetWorkspaceOnboardingAvailability: 'GetWorkspaceOnboardingAvailability',
    GetOnboardingCallAvailability: 'GetOnboardingCallAvailability',
    ListFeebacks: 'ListFeebacks',
    ListWorkspaceFeebacks: 'ListWorkspaceFeebacks',
    ListMatcherAssistants: 'ListMatcherAssistants',
    GetSettingById: 'GetSettingById',
    GetCityById: 'GetCityById',
    ListInvoicingReports: 'ListInvoicingReports',
    GetInvoicingReportUserStripeStatus: 'GetInvoicingReportUserStripeStatus',
    ListContractorReports: 'ListContractorReports',
    GetPayPeriod: 'GetPayPeriod',
    ListPricings: 'ListPricings',
    ListBugReports: 'ListBugReports',
    GetUserReports: 'GetUserReports',
    GetWorkspaceReport: 'GetWorkspaceReport',
    ListMatchings: 'ListMatchings',
    ListTransitionMatchings: 'ListTransitionMatchings',
    getLatestExecutiveCompletedTransition:
      'getLatestExecutiveCompletedTransition',
    GetMatchingById: 'GetMatchingById',
    GetUserPhoneNumber: 'GetUserPhoneNumber',
    ListLeverOpportunities: 'ListLeverOpportunities',
    ListAirtableLeads: 'ListAirtableLeads',
    GetAirtableLeadByLeadRecordId: 'GetAirtableLeadByLeadRecordId',
    GetAirtableBundlePrincipalByLeadRecordId:
      'GetAirtableBundlePrincipalByLeadRecordId',
    GetStandardDossierFolders: 'GetStandardDossierFolders',
    ListAssistantsInOrientation: 'ListAssistantsInOrientation',
    ListExecutivesStripeCustomer: 'ListExecutivesStripeCustomer',
    ListAutomationTriggers: 'ListAutomationTriggers',
    ListAutomations: 'ListAutomations',
    ListAutomationJobs: 'ListAutomationJobs',
    GetAutomationJobById: 'GetAutomationJobById',
    ListMessageTemplates: 'ListMessageTemplates',
    GetMessageTemplate: 'GetMessageTemplate',
    GetAdminableConfig: 'GetAdminableConfig',
    PerformanceScoreMonth: 'PerformanceScoreMonth',
    PerformanceScoreHistorical: 'PerformanceScoreHistorical',
    PerformanceScoreEvents: 'PerformanceScoreEvents',
    ListMetricEvents: 'ListMetricEvents',
    TimeTrackingEntriesList: 'TimeTrackingEntriesList',
    TimeTrackingGeneralInfos: 'TimeTrackingGeneralInfos',
    GetTimeTrackingEntryTemplatesList: 'GetTimeTrackingEntryTemplatesList',
    TimeTrackingEntriesListBulk: 'TimeTrackingEntriesListBulk',
    ListTeams: 'ListTeams',
    GetTeam: 'GetTeam',
    GetTeamBillingInfo: 'GetTeamBillingInfo',
    ListAdminRoles: 'ListAdminRoles',
    GetMe: 'GetMe',
    GetCopilot: 'GetCopilot',
    AssistantCopilot: 'AssistantCopilot',
    DelegationCoach: 'DelegationCoach',
    AdhocTodoSettings: 'AdhocTodoSettings',
    AdhocTodosList: 'AdhocTodosList',
    GetUserTimeline: 'GetUserTimeline',
    GetTeamTimeline: 'GetTeamTimeline',
    GetEventCategoryMappingList: 'GetEventCategoryMappingList',
    GetUserInvoicingHistoryList: 'GetUserInvoicingHistoryList',
    GetTeamInvoicingHistoryList: 'GetTeamInvoicingHistoryList',
    GetTestExec: 'GetTestExec',
    ListSystemFeatureFlags: 'ListSystemFeatureFlags',
  },
  Mutation: {
    ClearAssistantHourlyRateUpdates: 'ClearAssistantHourlyRateUpdates',
    CreateExecutiveWorkspace: 'CreateExecutiveWorkspace',
    UpdateWorkspace: 'UpdateWorkspace',
    ArchiveWorkspace: 'ArchiveWorkspace',
    LinkWorkspaceUserAccount: 'LinkWorkspaceUserAccount',
    UnlinkWorkspaceUserAccount: 'UnlinkWorkspaceUserAccount',
    AddWorkspaceAssistant: 'AddWorkspaceAssistant',
    RemoveWorkspaceUser: 'RemoveWorkspaceUser',
    SpectateWorkspace: 'SpectateWorkspace',
    AddUserAccount: 'AddUserAccount',
    DeleteUserAccount: 'DeleteUserAccount',
    SelectPrimaryUserAccount: 'SelectPrimaryUserAccount',
    CreateAssistant: 'CreateAssistant',
    CreateExecutive: 'CreateExecutive',
    CreateHq: 'CreateHq',
    CreateSandbox: 'CreateSandbox',
    SendEmailFromTemplate: 'SendEmailFromTemplate',
    SendEmailMessage: 'SendEmailMessage',
    UpdateUser: 'UpdateUser',
    ArchiveUser: 'ArchiveUser',
    UnarchiveUser: 'UnarchiveUser',
    ChurnExecutive: 'ChurnExecutive',
    ScheduleTeamChurn: 'ScheduleTeamChurn',
    CancelScheduledTeamChurn: 'CancelScheduledTeamChurn',
    UpdateAssistant: 'UpdateAssistant',
    Login: 'Login',
    CreateStripeCustomer: 'CreateStripeCustomer',
    UpdateStripeCustomerId: 'UpdateStripeCustomerId',
    UpsertFeature: 'UpsertFeature',
    UpsertTopicTemplate: 'UpsertTopicTemplate',
    ArchiveTopicTemplate: 'ArchiveTopicTemplate',
    SaveTopicTemplateForm: 'SaveTopicTemplateForm',
    AssignOnboardingSpecialist: 'AssignOnboardingSpecialist',
    RemoveOnboardingSpecialist: 'RemoveOnboardingSpecialist',
    UpdateOnboardingStep: 'UpdateOnboardingStep',
    ScheduleOnboardingCall: 'ScheduleOnboardingCall',
    CompleteOnboarding: 'CompleteOnboarding',
    ResetOnboarding: 'ResetOnboarding',
    CancelOnboardingCall: 'CancelOnboardingCall',
    SendOnboardingEmail: 'SendOnboardingEmail',
    SentOnboardingEmail: 'SentOnboardingEmail',
    SentPostOnboardingEmail: 'SentPostOnboardingEmail',
    CompleteTransition: 'CompleteTransition',
    UpdateCsatFeedback: 'UpdateCsatFeedback',
    DeleteFeedback: 'DeleteFeedback',
    UpsertSetting: 'UpsertSetting',
    CreateStripeInvoice: 'CreateStripeInvoice',
    VoidStripeInvoice: 'VoidStripeInvoice',
    PayStripeInvoice: 'PayStripeInvoice',
    SendStripeInvoice: 'SendStripeInvoice',
    UpdatePayPeriod: 'UpdatePayPeriod',
    SendContractorsPaymentsEmails: 'SendContractorsPaymentsEmails',
    UploadPublicFile: 'UploadPublicFile',
    UpsertPricing: 'UpsertPricing',
    SelectEntityPricing: 'SelectEntityPricing',
    CancelScheduledEntityPricingUpdate: 'CancelScheduledEntityPricingUpdate',
    ExpireUserPricing: 'ExpireUserPricing',
    UpdateMatching: 'UpdateMatching',
    UpsertMatchingProposal: 'UpsertMatchingProposal',
    SendMatchingProposal: 'SendMatchingProposal',
    DequeueMatchingProposal: 'DequeueMatchingProposal',
    ConfirmMatchingAssistant: 'ConfirmMatchingAssistant',
    SendLoginEmail: 'SendLoginEmail',
    SendCsatFeedbackRequest: 'SendCsatFeedbackRequest',
    SendSms: 'SendSms',
    CreateTransitionSlackChannel: 'CreateTransitionSlackChannel',
    UpdateTransitionInforming: 'UpdateTransitionInforming',
    UpdateTransitionMatchingStatus: 'UpdateTransitionMatchingStatus',
    UpdateTransitionHandover: 'UpdateTransitionHandover',
    UpdateTransitionChangeover: 'UpdateTransitionChangeover',
    UpdateTransitionNotepad: 'UpdateTransitionNotepad',
    SwitchTransitionAssistants: 'SwitchTransitionAssistants',
    CompleteTransitionCall: 'CompleteTransitionCall',
    FollowUpWithNewTransitionAssistant: 'FollowUpWithNewTransitionAssistant',
    ArchiveTransition: 'ArchiveTransition',
    UnarchiveTransition: 'UnarchiveTransition',
    SentTransitionMessage: 'SentTransitionMessage',
    ReadTopics: 'ReadTopics',
    ClearTopics: 'ClearTopics',
    UpsertDossierFolder: 'UpsertDossierFolder',
    UpsertDossierSection: 'UpsertDossierSection',
    UpsertDossierField: 'UpsertDossierField',
    UpdateOrientation: 'UpdateOrientation',
    ReviewAutomationJob: 'ReviewAutomationJob',
    CancelAutomationJob: 'CancelAutomationJob',
    CancelScheduledChurn: 'CancelScheduledChurn',
    ScheduleAutopilotJobs: 'ScheduleAutopilotJobs',
    RunAutopilotJobManually: 'RunAutopilotJobManually',
    UpsertMessageTemplate: 'UpsertMessageTemplate',
    UpdateAdminable: 'UpdateAdminable',
    UpdateMetricEvent: 'UpdateMetricEvent',
    UpdateMetricEventProperty: 'UpdateMetricEventProperty',
    UpdateTimeTrackingEntry: 'UpdateTimeTrackingEntry',
    UpdateTimeTrackingEntryBillTo: 'UpdateTimeTrackingEntryBillTo',
    DeleteTimeTrackingEntry: 'DeleteTimeTrackingEntry',
    LockTimeTrackingEntries: 'LockTimeTrackingEntries',
    UpsertAdminRole: 'UpsertAdminRole',
    UpdateAdminableRoles: 'UpdateAdminableRoles',
    UpdateTeam: 'UpdateTeam',
    UpdateTeamBilling: 'UpdateTeamBilling',
    ClearDataloaders: 'ClearDataloaders',
    UpdateCopilotSettings: 'UpdateCopilotSettings',
    UpdateAssistantCopilotSettings: 'UpdateAssistantCopilotSettings',
    ArchiveMatching: 'ArchiveMatching',
    UpdateDelegationCoachSettings: 'UpdateDelegationCoachSettings',
    UpdateAdhocTodoSettings: 'UpdateAdhocTodoSettings',
    RemoveAdhocTodoAssistant: 'RemoveAdhocTodoAssistant',
    UpdateAdhocTodoPoolVisibility: 'UpdateAdhocTodoPoolVisibility',
    GrantPoolTokens: 'GrantPoolTokens',
    ExportChatToStudio: 'ExportChatToStudio',
    UpdateWorkspaceTeam: 'UpdateWorkspaceTeam',
    CreateTestExecLead: 'CreateTestExecLead',
    CreateTestExecMatching: 'CreateTestExecMatching',
    ResetTestExecLifecycle: 'ResetTestExecLifecycle',
    PauseTeam: 'PauseTeam',
    UnpauseTeam: 'UnpauseTeam',
    UnarchiveTeam: 'UnarchiveTeam',
    UpdateSystemFeatureFlag: 'UpdateSystemFeatureFlag',
    CreateMatchingFromLead: 'CreateMatchingFromLead',
  },
  Subscription: {
    TimeTrackingEntryUpserted: 'TimeTrackingEntryUpserted',
  },
  Fragment: {
    WorkspaceFeature: 'WorkspaceFeature',
    UserProfile_Names: 'UserProfile_Names',
    UserProfile_NamesPhoto: 'UserProfile_NamesPhoto',
    UserProfile_Email: 'UserProfile_Email',
    UserProfile_NamesPhotoEmails: 'UserProfile_NamesPhotoEmails',
    PlaceAddress_CountryCode: 'PlaceAddress_CountryCode',
    PlaceAddress_Formatted: 'PlaceAddress_Formatted',
    Place_Address: 'Place_Address',
    Place_GoogleMapsPlaceId: 'Place_GoogleMapsPlaceId',
    City: 'City',
    Userable_Profile: 'Userable_Profile',
    Userable_CountryCode: 'Userable_CountryCode',
    Userable_Category: 'Userable_Category',
    Userable_Minimal: 'Userable_Minimal',
    Userable_PendingChurnAutomationJob: 'Userable_PendingChurnAutomationJob',
    Executivable_Teammate: 'Executivable_Teammate',
    Userable_StartDate: 'Userable_StartDate',
    Executivable_IsOnPause: 'Executivable_IsOnPause',
    PoolTokenBalance: 'PoolTokenBalance',
    BasicUser: 'BasicUser',
    Workspace_Settings: 'Workspace_Settings',
    Workspace_Users: 'Workspace_Users',
    BasicWorkspace: 'BasicWorkspace',
    WorkspaceWithTeam: 'WorkspaceWithTeam',
    WorkspaceWithTeamAndMaxioSubscription:
      'WorkspaceWithTeamAndMaxioSubscription',
    OnboardingAvailability: 'OnboardingAvailability',
    Onboarding_Completion: 'Onboarding_Completion',
    Onboarding_Call: 'Onboarding_Call',
    Onboarding_Availability: 'Onboarding_Availability',
    Onboarding_EmailStatus: 'Onboarding_EmailStatus',
    Onboarding_Specialist: 'Onboarding_Specialist',
    Onboarding_Checklist: 'Onboarding_Checklist',
    Onboarding_Steps: 'Onboarding_Steps',
    WorkspaceWithOnboarding: 'WorkspaceWithOnboarding',
    DiscScore: 'DiscScore',
    Assistantable_MatchingData: 'Assistantable_MatchingData',
    AssistantableHourlyRateUpdate: 'AssistantableHourlyRateUpdate',
    Assistantable_HourlyRateUpdates: 'Assistantable_HourlyRateUpdates',
    Userable_Workspaces: 'Userable_Workspaces',
    Userable_AirtableId: 'Userable_AirtableId',
    UserAccount: 'UserAccount',
    ExecutivableCsm: 'ExecutivableCsm',
    BasicUserWithAccounts: 'BasicUserWithAccounts',
    FullUser: 'FullUser',
    EntityPricing: 'EntityPricing',
    Executivable_PricingSelections: 'Executivable_PricingSelections',
    StripeCustomer_PaymentStatus: 'StripeCustomer_PaymentStatus',
    StripeInvoice: 'StripeInvoice',
    StripeCustomer_Invoices: 'StripeCustomer_Invoices',
    Executivable_StripeCustomerId: 'Executivable_StripeCustomerId',
    Executivable_StripeCustomer: 'Executivable_StripeCustomer',
    BillableEntity_PricingSelections: 'BillableEntity_PricingSelections',
    BillableEntity_StripeCustomer: 'BillableEntity_StripeCustomer',
    Assistantable_PrimaryEmail: 'Assistantable_PrimaryEmail',
    Assistantable_ContractorData: 'Assistantable_ContractorData',
    Assistantable_AssistantData: 'Assistantable_AssistantData',
    Feature: 'Feature',
    TopicFormFieldValueCondition: 'TopicFormFieldValueCondition',
    TopicFormFieldOptionText: 'TopicFormFieldOptionText',
    TopicFormField: 'TopicFormField',
    TopicForm: 'TopicForm',
    TopicTemplate: 'TopicTemplate',
    CsatFeedback: 'CsatFeedback',
    MinimalWho: 'MinimalWho',
    BasicWho: 'BasicWho',
    ExecutivableBilling: 'ExecutivableBilling',
    MatcherAssistant: 'MatcherAssistant',
    Setting: 'Setting',
    InvoicingReportTogglUsage: 'InvoicingReportTogglUsage',
    InvoicingRefundCreditHours: 'InvoicingRefundCreditHours',
    InvoicingRefundCreditAmount: 'InvoicingRefundCreditAmount',
    InvoicingRefund: 'InvoicingRefund',
    InvoicingDiscountCreditHours: 'InvoicingDiscountCreditHours',
    InvoicingDiscountCreditAmount: 'InvoicingDiscountCreditAmount',
    InvoicingDiscount: 'InvoicingDiscount',
    InvoicingAgreement: 'InvoicingAgreement',
    Executivable_InvoicedUser: 'Executivable_InvoicedUser',
    InvoicedCycle: 'InvoicedCycle',
    InvoicingReport: 'InvoicingReport',
    ContractorReport: 'ContractorReport',
    PayPeriod: 'PayPeriod',
    Pricing: 'Pricing',
    BugReportFile: 'BugReportFile',
    BugReport: 'BugReport',
    WorkspaceCategoryUsage: 'WorkspaceCategoryUsage',
    WorkspaceWeeklyUsage: 'WorkspaceWeeklyUsage',
    WorkspaceMonthlyUsage: 'WorkspaceMonthlyUsage',
    WorkspaceWeeklyUsageWithCategories: 'WorkspaceWeeklyUsageWithCategories',
    WorkspaceMonthlyUsageWithCategories: 'WorkspaceMonthlyUsageWithCategories',
    WorkspaceUsage: 'WorkspaceUsage',
    WorkspaceReport: 'WorkspaceReport',
    MatchingProposal_SentReply: 'MatchingProposal_SentReply',
    MatchingProposal_Assistant: 'MatchingProposal_Assistant',
    MatchingProposal_Confirmation: 'MatchingProposal_Confirmation',
    MatchingProposal_ReasonForMatch: 'MatchingProposal_ReasonForMatch',
    MatchingProposal_IsInstantInvite: 'MatchingProposal_IsInstantInvite',
    MatchingProposal_Full: 'MatchingProposal_Full',
    MatchingApplication_Motivation: 'MatchingApplication_Motivation',
    MatchingApplication: 'MatchingApplication',
    MatchingProfile_Name: 'MatchingProfile_Name',
    MatchingProfile_ForSorting: 'MatchingProfile_ForSorting',
    MatchingProfile_Principal: 'MatchingProfile_Principal',
    MatchingProfile: 'MatchingProfile',
    Matching_IsHighTouch: 'Matching_IsHighTouch',
    Matching_BookmarksCount: 'Matching_BookmarksCount',
    Matching_IsTransition: 'Matching_IsTransition',
    Matching_CatalogVisibility: 'Matching_CatalogVisibility',
    Matching_TeamInvite: 'Matching_TeamInvite',
    Matching_isTeamInvite: 'Matching_isTeamInvite',
    Matching_InstantInvite: 'Matching_InstantInvite',
    Matching_ProposalsReply: 'Matching_ProposalsReply',
    Matching_ApplicationsId: 'Matching_ApplicationsId',
    Matching_CompletedAt: 'Matching_CompletedAt',
    Matching_ForMatchingListItem: 'Matching_ForMatchingListItem',
    MatchingTransition_SentMessages: 'MatchingTransition_SentMessages',
    MatchingTransition_Informing: 'MatchingTransition_Informing',
    MatchingTransition_MatchingStatus: 'MatchingTransition_MatchingStatus',
    MatchingTransition_HandoverStatus: 'MatchingTransition_HandoverStatus',
    MatchingTransition_Handover: 'MatchingTransition_Handover',
    MatchingTransition_ChangeoverStatuses:
      'MatchingTransition_ChangeoverStatuses',
    MatchingTransition_SlackChannelId: 'MatchingTransition_SlackChannelId',
    MatchingTransition_Notepad: 'MatchingTransition_Notepad',
    MatchingTransition_ListItem: 'MatchingTransition_ListItem',
    MatchingTransition_Full: 'MatchingTransition_Full',
    Matching_OnboardingCallAt: 'Matching_OnboardingCallAt',
    Matching_ForTransitionListItem: 'Matching_ForTransitionListItem',
    Matching_ForTransitionFull: 'Matching_ForTransitionFull',
    Matching_Full: 'Matching_Full',
    LeverOpportunity: 'LeverOpportunity',
    BasicAirtableLead: 'BasicAirtableLead',
    AirtableLead: 'AirtableLead',
    AirtableLead_BundlePrincipal: 'AirtableLead_BundlePrincipal',
    Assistantable_ForTransition: 'Assistantable_ForTransition',
    AirtableTransitionRequest: 'AirtableTransitionRequest',
    DossierFolderHeader: 'DossierFolderHeader',
    DossierFolder: 'DossierFolder',
    DossierSection: 'DossierSection',
    DossierField: 'DossierField',
    Orientation: 'Orientation',
    Userable_InOrientation: 'Userable_InOrientation',
    Delay: 'Delay',
    AutomationParameter: 'AutomationParameter',
    AutomationTrigger: 'AutomationTrigger',
    AutomationAction_NoConfig: 'AutomationAction_NoConfig',
    AutomationAction: 'AutomationAction',
    AutomationActionGroup: 'AutomationActionGroup',
    Automation_Name: 'Automation_Name',
    Automation: 'Automation',
    AutomationJobLog: 'AutomationJobLog',
    AutomationJob_Item: 'AutomationJob_Item',
    AutomationJob_Full: 'AutomationJob_Full',
    MessageTemplateLanguage: 'MessageTemplateLanguage',
    MessageTemplate_Labels: 'MessageTemplate_Labels',
    MessageTemplate: 'MessageTemplate',
    ScheduleOnboardingConfig_Writable: 'ScheduleOnboardingConfig_Writable',
    Adminable_Config: 'Adminable_Config',
    AssistantUser_CsatAverage: 'AssistantUser_CsatAverage',
    AssistantUser_PerformanceScoreBadge: 'AssistantUser_PerformanceScoreBadge',
    ScoreSeriesBasicInfo: 'ScoreSeriesBasicInfo',
    PerformanceScoreMonthSeries: 'PerformanceScoreMonthSeries',
    AssistantUser_PerformanceScoreMetrics:
      'AssistantUser_PerformanceScoreMetrics',
    PerformanceScoreHistoricalSeries: 'PerformanceScoreHistoricalSeries',
    PerformanceScoreEventsSeries: 'PerformanceScoreEventsSeries',
    AssistantUser_PerformanceScoreMetricsPartial:
      'AssistantUser_PerformanceScoreMetricsPartial',
    AssistantUser_PerformanceScoreMetricEvents:
      'AssistantUser_PerformanceScoreMetricEvents',
    AssistantUser_PerformanceScoreTags: 'AssistantUser_PerformanceScoreTags',
    MetricEventProperty: 'MetricEventProperty',
    MetricEvent: 'MetricEvent',
    TimeTrackingEntry_DateInfo: 'TimeTrackingEntry_DateInfo',
    TimeTrackingEntry_TimeInfo: 'TimeTrackingEntry_TimeInfo',
    TimeTrackingTag: 'TimeTrackingTag',
    TimeTrackingEntry_TaskInfo: 'TimeTrackingEntry_TaskInfo',
    TimeTrackingEntry_TogglInfo: 'TimeTrackingEntry_TogglInfo',
    TimeTrackingEntry_StatusInfo: 'TimeTrackingEntry_StatusInfo',
    TimeTrackingEntry: 'TimeTrackingEntry',
    TimeTrackingEntryTemplate: 'TimeTrackingEntryTemplate',
    Assistantable_TimeTrackingEntryBulk: 'Assistantable_TimeTrackingEntryBulk',
    Userable_TimeTrackingEntryBulk: 'Userable_TimeTrackingEntryBulk',
    TimeTrackingEntry_Bulk: 'TimeTrackingEntry_Bulk',
    BasicTeam: 'BasicTeam',
    BasicTeamWithPrincipal: 'BasicTeamWithPrincipal',
    BillingPause: 'BillingPause',
    Team_PendingChurnAutomationJob: 'Team_PendingChurnAutomationJob',
    Team: 'Team',
    TeamBilling: 'TeamBilling',
    Team_StripeCustomer: 'Team_StripeCustomer',
    Team_Subscription: 'Team_Subscription',
    Team_PricingSelections: 'Team_PricingSelections',
    GuardedOperation: 'GuardedOperation',
    AdminRole: 'AdminRole',
    CopilotFunction: 'CopilotFunction',
    Copilot: 'Copilot',
    FirstCalledFunctionInfo: 'FirstCalledFunctionInfo',
    CopilotSubpromptable: 'CopilotSubpromptable',
    CopilotPromptSettings: 'CopilotPromptSettings',
    CopilotPromptSettingsOverride: 'CopilotPromptSettingsOverride',
    CopilotPromptSettingsOverridable: 'CopilotPromptSettingsOverridable',
    MainCopilotPromptSettings: 'MainCopilotPromptSettings',
    CategoryCopilotPromptSettings: 'CategoryCopilotPromptSettings',
    TaskCopilotPromptSettings: 'TaskCopilotPromptSettings',
    AssistantCopilot: 'AssistantCopilot',
    DelegationCoach: 'DelegationCoach',
    AdhocTodoSettings: 'AdhocTodoSettings',
    HourlyRateCurrency: 'HourlyRateCurrency',
    AdhocExecutive: 'AdhocExecutive',
    EmailThreadItem: 'EmailThreadItem',
    ChatThreadItem: 'ChatThreadItem',
    CommunicationThreads: 'CommunicationThreads',
    CheckoutSessionLineItem: 'CheckoutSessionLineItem',
    Feedback: 'Feedback',
    AdhocTodo: 'AdhocTodo',
    TimelineItem: 'TimelineItem',
    TestExecLead: 'TestExecLead',
    TestExecMatching: 'TestExecMatching',
    TestExecUser: 'TestExecUser',
    TestExec: 'TestExec',
    SystemFeatureFlag: 'SystemFeatureFlag',
  },
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  CountryCode: string
  CurrencyCode: string
  Date: string
  DateTime: string | Date
  Duration: string
  EmailAddress: string
  GUID: string
  HexColorCode: string
  JSON: any
  JSONObject: Record<string, any>
  LanguageCode: string
  MIME: string
  NegativeFloat: number
  NegativeInt: number
  NonNegativeFloat: number
  NonNegativeInt: number
  NonPositiveFloat: number
  NonPositiveInt: number
  PhoneNumber: string
  PositiveFloat: number
  PositiveInt: number
  Time: string
  URL: string
}

export type AddUserAccountInput = {
  email: Scalars['EmailAddress']
  familyName?: InputMaybe<Scalars['String']>
  givenName: Scalars['String']
}

export type AddWorkspaceAssistantInput = {
  assistantId: Scalars['ID']
  isCoverage?: InputMaybe<Scalars['Boolean']>
  isTransition?: InputMaybe<Scalars['Boolean']>
  subscribeToCsatEmail?: InputMaybe<Scalars['Boolean']>
  workspaceId: Scalars['ID']
}

export type AdhocExecutive = {
  __typename?: 'AdhocExecutive'
  displayName: Scalars['String']
  email: Scalars['EmailAddress']
  familyName: Scalars['String']
  givenName: Scalars['String']
  phoneNumber?: Maybe<Scalars['PhoneNumber']>
}

export enum AdhocTaskAcceptabilityStatus {
  ACCEPTABLE = 'ACCEPTABLE',
  NOT_A_TASK = 'NOT_A_TASK',
  REQUIRE_ACCESS_TO_SENSITIVE_INFORMATION = 'REQUIRE_ACCESS_TO_SENSITIVE_INFORMATION',
}

export type AdhocTodo = {
  __typename?: 'AdhocTodo'
  /** @deprecated use 'communicationThreads.chatThread' instead */
  chatThread?: Maybe<ChatThreadItem[]>
  checkoutSession?: Maybe<CheckoutSession>
  /**  Ad Hoc Todo / Read communication threads  */
  communicationThreads?: Maybe<CommunicationThreads>
  completedAt?: Maybe<Scalars['DateTime']>
  completionReport?: Maybe<AdhocTodoCompletionReport>
  createdAt: Scalars['DateTime']
  dispatchedTo?: Maybe<AssistantUser>
  /** @deprecated use 'communicationThreads.emailThread' instead */
  emailThread?: Maybe<EmailThreadItem[]>
  executive?: Maybe<AdhocExecutive>
  executiveUser?: Maybe<ExecutiveUser>
  feedback?: Maybe<AdhocTodoFeedback>
  id: Scalars['GUID']
  isCompleted: Scalars['Boolean']
  isHiddenFromPool: Scalars['Boolean']
  isPool: Scalars['Boolean']
  /**  @deprecated(reason: "Use 'feedback.rating' instead")  */
  rating?: Maybe<Scalars['PositiveInt']>
  status: TodoStatus
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type AdhocTodoCompletionReport = {
  __typename?: 'AdhocTodoCompletionReport'
  notes: Scalars['String']
}

export type AdhocTodoFeedback = {
  __typename?: 'AdhocTodoFeedback'
  rating?: Maybe<Scalars['PositiveInt']>
  ratingComment?: Maybe<Scalars['String']>
}

export type AdhocTodoSettings = {
  __typename?: 'AdhocTodoSettings'
  id: Scalars['ID']
  isOpenForSubmissions: Scalars['Boolean']
  systemPrompt: Scalars['String']
  taskAcceptabilityPrompt: Scalars['String']
  titlePrompt: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export enum AdhocTodoType {
  BOOK_RESTAURANT = 'BOOK_RESTAURANT',
  GENERIC_TASK = 'GENERIC_TASK',
}

export type AdhocTodosList = {
  __typename?: 'AdhocTodosList'
  after?: Maybe<Scalars['String']>
  items: AdhocTodo[]
}

export type AdminRole = {
  __typename?: 'AdminRole'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  inheritedRoles: AdminRole[]
  name: Scalars['String']
  operations: GuardedOperation[]
}

export type AdminRolesList = {
  __typename?: 'AdminRolesList'
  items: AdminRole[]
}

export type Adminable = {
  adminRoles: AdminRole[]
  allowedOperations: GuardedOperation[]
  id: Scalars['ID']
  scheduleOnboardingConfig?: Maybe<ScheduleOnboardingConfig>
  thirtyDayCheckInCallUrl?: Maybe<Scalars['URL']>
}

export type AirtableClient = {
  __typename?: 'AirtableClient'
  fields: Scalars['JSONObject']
  id: Scalars['ID']
  successSegment?: Maybe<Scalars['String']>
  transitionsCount: Scalars['NonNegativeInt']
  user?: Maybe<Executivable>
  userId?: Maybe<Scalars['String']>
}

export enum AirtableClientFieldName {
  /** Activated? */
  ACTIVATED = 'ACTIVATED',
  /** Activation Email Send Date */
  ACTIVATION_EMAIL_SEND_DATE = 'ACTIVATION_EMAIL_SEND_DATE',
  /** Active Flows */
  ACTIVE_FLOWS = 'ACTIVE_FLOWS',
  /** Active Status - Current Month */
  ACTIVE_STATUS_CURRENT_MONTH = 'ACTIVE_STATUS_CURRENT_MONTH',
  /** Active Status - Last Month */
  ACTIVE_STATUS_LAST_MONTH = 'ACTIVE_STATUS_LAST_MONTH',
  /** (Archive) Inactive additional comments */
  ARCHIVE_INACTIVE_ADDITIONAL_COMMENTS = 'ARCHIVE_INACTIVE_ADDITIONAL_COMMENTS',
  /** (Archive) Inactive Reason */
  ARCHIVE_INACTIVE_REASON = 'ARCHIVE_INACTIVE_REASON',
  /** Attachments */
  ATTACHMENTS = 'ATTACHMENTS',
  /** Average CSAT */
  AVERAGE_CSAT = 'AVERAGE_CSAT',
  /** Average Hours (3 months) */
  AVERAGE_HOURS_3_MONTHS = 'AVERAGE_HOURS_3_MONTHS',
  /** Avg. last 2 invoices */
  AVG_LAST_2_INVOICES = 'AVG_LAST_2_INVOICES',
  /** Billable hours, previous invoice */
  BILLABLE_HOURS_PREVIOUS_INVOICE = 'BILLABLE_HOURS_PREVIOUS_INVOICE',
  /** Billing to Team Lead */
  BILLING_TO_TEAM_LEAD = 'BILLING_TO_TEAM_LEAD',
  /** Calendar shared */
  CALENDAR_SHARED = 'CALENDAR_SHARED',
  /** Call notes */
  CALL_NOTES = 'CALL_NOTES',
  /** Churn/Pause Next Step */
  CHURNPAUSE_NEXT_STEP = 'CHURNPAUSE_NEXT_STEP',
  /** Churn date */
  CHURN_DATE = 'CHURN_DATE',
  /** Churn Reasons */
  CHURN_REASONS = 'CHURN_REASONS',
  /** Churn Survey - Competitor Question 1 */
  CHURN_SURVEY_COMPETITOR_QUESTION_1 = 'CHURN_SURVEY_COMPETITOR_QUESTION_1',
  /** Churn Survey - Competitor Question 2 */
  CHURN_SURVEY_COMPETITOR_QUESTION_2 = 'CHURN_SURVEY_COMPETITOR_QUESTION_2',
  /** Churn Typeform URL */
  CHURN_TYPEFORM_URL = 'CHURN_TYPEFORM_URL',
  /** ⭐️ Clients */
  CLIENTS = 'CLIENTS',
  /** Client Card */
  CLIENT_CARD = 'CLIENT_CARD',
  /** Client Goals */
  CLIENT_GOALS = 'CLIENT_GOALS',
  /** Client Goals - Overall Achievement Rate */
  CLIENT_GOALS_OVERALL_ACHIEVEMENT_RATE = 'CLIENT_GOALS_OVERALL_ACHIEVEMENT_RATE',
  /** Client Hours (Mid-Month) > 50 */
  CLIENT_HOURS_MIDMONTH_50 = 'CLIENT_HOURS_MIDMONTH_50',
  /** 💤 Client Inactivity */
  CLIENT_INACTIVITY = 'CLIENT_INACTIVITY',
  /** 💤 Client Inactivity - Assistant Feedback */
  CLIENT_INACTIVITY_ASSISTANT_FEEDBACK = 'CLIENT_INACTIVITY_ASSISTANT_FEEDBACK',
  /** 💤 Client Inactivity Records */
  CLIENT_INACTIVITY_RECORDS = 'CLIENT_INACTIVITY_RECORDS',
  /** Client Score (Points) */
  CLIENT_SCORE_POINTS = 'CLIENT_SCORE_POINTS',
  /** Client Score (Points for Automation) */
  CLIENT_SCORE_POINTS_FOR_AUTOMATION = 'CLIENT_SCORE_POINTS_FOR_AUTOMATION',
  /** Coaching - Double */
  COACHING_DOUBLE = 'COACHING_DOUBLE',
  /** Cockpit ID */
  COCKPIT_ID = 'COCKPIT_ID',
  /** Combined Hours - Week 2/3 */
  COMBINED_HOURS_WEEK_23 = 'COMBINED_HOURS_WEEK_23',
  /** Comment */
  COMMENT = 'COMMENT',
  /** Comments */
  COMMENTS = 'COMMENTS',
  /** Company */
  COMPANY = 'COMPANY',
  /** Company Hours last 30 days >50 */
  COMPANY_HOURS_LAST_30_DAYS_50 = 'COMPANY_HOURS_LAST_30_DAYS_50',
  /** Contact Date */
  CONTACT_DATE = 'CONTACT_DATE',
  /** Contact Date (past) */
  CONTACT_DATE_PAST = 'CONTACT_DATE_PAST',
  /** Contact Notes */
  CONTACT_NOTES = 'CONTACT_NOTES',
  /** Contact notes (past) */
  CONTACT_NOTES_PAST = 'CONTACT_NOTES_PAST',
  /** Country */
  COUNTRY = 'COUNTRY',
  /** 💤 Count Inactivity Records */
  COUNT_INACTIVITY_RECORDS = 'COUNT_INACTIVITY_RECORDS',
  /** ✈ Coverage */
  COVERAGE = 'COVERAGE',
  /** ✈ Coverage 2 */
  COVERAGE_2 = 'COVERAGE_2',
  /** ✈ Coverage 3 */
  COVERAGE_3 = 'COVERAGE_3',
  /** ✈ Coverage 4 */
  COVERAGE_4 = 'COVERAGE_4',
  /** ✈ Coverage 5 */
  COVERAGE_5 = 'COVERAGE_5',
  /** ✈ Coverage 6 */
  COVERAGE_6 = 'COVERAGE_6',
  /** Created by */
  CREATED_BY = 'CREATED_BY',
  /** Credit/Refunds */
  CREDITREFUNDS = 'CREDITREFUNDS',
  /** CSAT Score 4 of below in the last 30 days */
  CSAT_SCORE_4_OF_BELOW_IN_THE_LAST_30_DAYS = 'CSAT_SCORE_4_OF_BELOW_IN_THE_LAST_30_DAYS',
  /** CS Outreach for +1 Status */
  CS_OUTREACH_FOR_1_STATUS = 'CS_OUTREACH_FOR_1_STATUS',
  /** Current Package */
  CURRENT_PACKAGE = 'CURRENT_PACKAGE',
  /** Days from signup to onboarding */
  DAYS_FROM_SIGNUP_TO_ONBOARDING = 'DAYS_FROM_SIGNUP_TO_ONBOARDING',
  /** Discount Code for HS */
  DISCOUNT_CODE_FOR_HS = 'DISCOUNT_CODE_FOR_HS',
  /** Domain */
  DOMAIN = 'DOMAIN',
  /** Dormant Outreach */
  DORMANT_OUTREACH = 'DORMANT_OUTREACH',
  /** Double */
  DOUBLE = 'DOUBLE',
  /** Double (Churned Tracking) */
  DOUBLE_CHURNED_TRACKING = 'DOUBLE_CHURNED_TRACKING',
  /** Double (Paused Tracking) */
  DOUBLE_PAUSED_TRACKING = 'DOUBLE_PAUSED_TRACKING',
  /** Double teams */
  DOUBLE_TEAMS = 'DOUBLE_TEAMS',
  /** Do we reach out (Points) */
  DO_WE_REACH_OUT_POINTS = 'DO_WE_REACH_OUT_POINTS',
  /** Email */
  EMAIL = 'EMAIL',
  /** Email created */
  EMAIL_CREATED = 'EMAIL_CREATED',
  /** End of Pause date */
  END_OF_PAUSE_DATE = 'END_OF_PAUSE_DATE',
  /** [Eng] Double Churn Cockpit ID */
  ENG_DOUBLE_CHURN_COCKPIT_ID = 'ENG_DOUBLE_CHURN_COCKPIT_ID',
  /** [Eng] Double Cockpit Id */
  ENG_DOUBLE_COCKPIT_ID = 'ENG_DOUBLE_COCKPIT_ID',
  /** Enroll in +1 Onboarding Email Campaign */
  ENROLL_IN_1_ONBOARDING_EMAIL_CAMPAIGN = 'ENROLL_IN_1_ONBOARDING_EMAIL_CAMPAIGN',
  /** Exclude from Hours Escalation */
  EXCLUDE_FROM_HOURS_ESCALATION = 'EXCLUDE_FROM_HOURS_ESCALATION',
  /** External - Churn Reason */
  EXTERNAL_CHURN_REASON = 'EXTERNAL_CHURN_REASON',
  /** External - Other churn reason */
  EXTERNAL_OTHER_CHURN_REASON = 'EXTERNAL_OTHER_CHURN_REASON',
  /** First Onboarding date */
  FIRST_ONBOARDING_DATE = 'FIRST_ONBOARDING_DATE',
  /** Front Modification Date */
  FRONT_MODIFICATION_DATE = 'FRONT_MODIFICATION_DATE',
  /** GA Campaign */
  GA_CAMPAIGN = 'GA_CAMPAIGN',
  /** Hours - 2 months ago */
  HOURS_2_MONTHS_AGO = 'HOURS_2_MONTHS_AGO',
  /** Hours - 7-14 days */
  HOURS_714_DAYS = 'HOURS_714_DAYS',
  /** Hours - Current month */
  HOURS_CURRENT_MONTH = 'HOURS_CURRENT_MONTH',
  /** Hours - First week post onboarding */
  HOURS_FIRST_WEEK_POST_ONBOARDING = 'HOURS_FIRST_WEEK_POST_ONBOARDING',
  /** Hours - Fourth week post onboarding */
  HOURS_FOURTH_WEEK_POST_ONBOARDING = 'HOURS_FOURTH_WEEK_POST_ONBOARDING',
  /** Hours, last 6 months */
  HOURS_LAST_6_MONTHS = 'HOURS_LAST_6_MONTHS',
  /** Hours, last 7 days */
  HOURS_LAST_7_DAYS = 'HOURS_LAST_7_DAYS',
  /** Hours last 7 days +/-25%+ of average weekly hours */
  HOURS_LAST_7_DAYS_25_OF_AVERAGE_WEEKLY_HOURS = 'HOURS_LAST_7_DAYS_25_OF_AVERAGE_WEEKLY_HOURS',
  /** Hours, last 30 days */
  HOURS_LAST_30_DAYS = 'HOURS_LAST_30_DAYS',
  /** Hours - last 30-60 days */
  HOURS_LAST_3060_DAYS = 'HOURS_LAST_3060_DAYS',
  /** Hours - last 60-90 days */
  HOURS_LAST_6090_DAYS = 'HOURS_LAST_6090_DAYS',
  /** Hours - Last month */
  HOURS_LAST_MONTH = 'HOURS_LAST_MONTH',
  /** Hours Retention */
  HOURS_RETENTION = 'HOURS_RETENTION',
  /** Hours - Second week post onboarding */
  HOURS_SECOND_WEEK_POST_ONBOARDING = 'HOURS_SECOND_WEEK_POST_ONBOARDING',
  /** Hours - Third week post onboarding */
  HOURS_THIRD_WEEK_POST_ONBOARDING = 'HOURS_THIRD_WEEK_POST_ONBOARDING',
  /** Hours, week-2 */
  HOURS_WEEK2 = 'HOURS_WEEK2',
  /** Hours, week-3 */
  HOURS_WEEK3 = 'HOURS_WEEK3',
  /** Hours, week-4 */
  HOURS_WEEK4 = 'HOURS_WEEK4',
  /** Hours, week-5 */
  HOURS_WEEK5 = 'HOURS_WEEK5',
  /** Hours - Weekly Average (30d) */
  HOURS_WEEKLY_AVERAGE_30D = 'HOURS_WEEKLY_AVERAGE_30D',
  /** HS Utilization */
  HS_UTILIZATION = 'HS_UTILIZATION',
  /** HS Utilization Decimal */
  HS_UTILIZATION_DECIMAL = 'HS_UTILIZATION_DECIMAL',
  /** Importance (Points) */
  IMPORTANCE_POINTS = 'IMPORTANCE_POINTS',
  /** Inactive company */
  INACTIVE_COMPANY = 'INACTIVE_COMPANY',
  /** 💤 Inactivity Exception */
  INACTIVITY_EXCEPTION = 'INACTIVITY_EXCEPTION',
  /** Inactivity week 2 */
  INACTIVITY_WEEK_2 = 'INACTIVITY_WEEK_2',
  /** Inbox delegated */
  INBOX_DELEGATED = 'INBOX_DELEGATED',
  /** Invoiced Client */
  INVOICED_CLIENT = 'INVOICED_CLIENT',
  /** In conversation */
  IN_CONVERSATION = 'IN_CONVERSATION',
  /** Last CSAT date */
  LAST_CSAT_DATE = 'LAST_CSAT_DATE',
  /** Last CSAT score */
  LAST_CSAT_SCORE = 'LAST_CSAT_SCORE',
  /** Last CSM Touchpoint */
  LAST_CSM_TOUCHPOINT = 'LAST_CSM_TOUCHPOINT',
  /** Last Invoice Above $2k */
  LAST_INVOICE_ABOVE_2K = 'LAST_INVOICE_ABOVE_2K',
  /** Last modi */
  LAST_MODI = 'LAST_MODI',
  /** Last modified delegation hours at */
  LAST_MODIFIED_DELEGATION_HOURS_AT = 'LAST_MODIFIED_DELEGATION_HOURS_AT',
  /** Last modi (name/status/email/double) */
  LAST_MODI_NAMESTATUSEMAILDOUBLE = 'LAST_MODI_NAMESTATUSEMAILDOUBLE',
  /** Last transition date */
  LAST_TRANSITION_DATE = 'LAST_TRANSITION_DATE',
  /** Lead */
  LEAD = 'LEAD',
  /** ✨ Leads */
  LEADS = 'LEADS',
  /** ✨ Leads 2 */
  LEADS_2 = 'LEADS_2',
  /** ✨ Leads 3 */
  LEADS_3 = 'LEADS_3',
  /** M1 Goals Achieved? */
  M1_GOALS_ACHIEVED = 'M1_GOALS_ACHIEVED',
  /** M/F */
  MF = 'MF',
  /** Milestone? */
  MILESTONE = 'MILESTONE',
  /** Minimum Hours by Package */
  MINIMUM_HOURS_BY_PACKAGE = 'MINIMUM_HOURS_BY_PACKAGE',
  /** Minimum Hours by Package (in Hours) */
  MINIMUM_HOURS_BY_PACKAGE_IN_HOURS = 'MINIMUM_HOURS_BY_PACKAGE_IN_HOURS',
  /** Most recent delegation date */
  MOST_RECENT_DELEGATION_DATE = 'MOST_RECENT_DELEGATION_DATE',
  /** Most recent delegation more than 7 days ago */
  MOST_RECENT_DELEGATION_MORE_THAN_7_DAYS_AGO = 'MOST_RECENT_DELEGATION_MORE_THAN_7_DAYS_AGO',
  /** Name */
  NAME = 'NAME',
  /** Name (from ✨ Leads 3) */
  NAME_FROM_LEADS_3 = 'NAME_FROM_LEADS_3',
  /** New transition request */
  NEW_TRANSITION_REQUEST = 'NEW_TRANSITION_REQUEST',
  /** Number of transitions */
  NUMBER_OF_TRANSITIONS = 'NUMBER_OF_TRANSITIONS',
  /** Number of yellow flags */
  NUMBER_OF_YELLOW_FLAGS = 'NUMBER_OF_YELLOW_FLAGS',
  /** Onboarding checklist incomplete */
  ONBOARDING_CHECKLIST_INCOMPLETE = 'ONBOARDING_CHECKLIST_INCOMPLETE',
  /** Onboarding date */
  ONBOARDING_DATE = 'ONBOARDING_DATE',
  /** Onboarding Goals */
  ONBOARDING_GOALS = 'ONBOARDING_GOALS',
  /** Onboarding Notes */
  ONBOARDING_NOTES = 'ONBOARDING_NOTES',
  /** Onboarding Notes 2 */
  ONBOARDING_NOTES_2 = 'ONBOARDING_NOTES_2',
  /** Onboarding Short-term Goals */
  ONBOARDING_SHORTTERM_GOALS = 'ONBOARDING_SHORTTERM_GOALS',
  /** Onboarding Short-term Goals Achieved */
  ONBOARDING_SHORTTERM_GOALS_ACHIEVED = 'ONBOARDING_SHORTTERM_GOALS_ACHIEVED',
  /** Onboarding Short-term Goals Achievement */
  ONBOARDING_SHORTTERM_GOALS_ACHIEVEMENT = 'ONBOARDING_SHORTTERM_GOALS_ACHIEVEMENT',
  /** Onboarding Short-term Goal Achievement - at least 1 */
  ONBOARDING_SHORTTERM_GOAL_ACHIEVEMENT_AT_LEAST_1 = 'ONBOARDING_SHORTTERM_GOAL_ACHIEVEMENT_AT_LEAST_1',
  /** Onboarding Short-term Goal set */
  ONBOARDING_SHORTTERM_GOAL_SET = 'ONBOARDING_SHORTTERM_GOAL_SET',
  /** Opportunities */
  OPPORTUNITIES = 'OPPORTUNITIES',
  /** Ops Manager Slack ID */
  OPS_MANAGER_SLACK_ID = 'OPS_MANAGER_SLACK_ID',
  /** Order Forms */
  ORDER_FORMS = 'ORDER_FORMS',
  /** Package selected on */
  PACKAGE_SELECTED_ON = 'PACKAGE_SELECTED_ON',
  /** Paused date */
  PAUSED_DATE = 'PAUSED_DATE',
  /** Payer Status */
  PAYER_STATUS = 'PAYER_STATUS',
  /** Performance Score Changes */
  PERFORMANCE_SCORE_CHANGES = 'PERFORMANCE_SCORE_CHANGES',
  /** Placements */
  PLACEMENTS = 'PLACEMENTS',
  /** Placements 2 */
  PLACEMENTS_2 = 'PLACEMENTS_2',
  /** Placements 3 */
  PLACEMENTS_3 = 'PLACEMENTS_3',
  /** Plan hours vs Company Hours last 30 days off by +/-25%+ */
  PLAN_HOURS_VS_COMPANY_HOURS_LAST_30_DAYS_OFF_BY_25 = 'PLAN_HOURS_VS_COMPANY_HOURS_LAST_30_DAYS_OFF_BY_25',
  /** Plan is 50 hours or more */
  PLAN_IS_50_HOURS_OR_MORE = 'PLAN_IS_50_HOURS_OR_MORE',
  /** Potential Returning Client */
  POTENTIAL_RETURNING_CLIENT = 'POTENTIAL_RETURNING_CLIENT',
  /** Previous Package */
  PREVIOUS_PACKAGE = 'PREVIOUS_PACKAGE',
  /** Pricing package */
  PRICING_PACKAGE = 'PRICING_PACKAGE',
  /** Pricing Selected in Cockpit */
  PRICING_SELECTED_IN_COCKPIT = 'PRICING_SELECTED_IN_COCKPIT',
  /** Priority Support */
  PRIORITY_SUPPORT = 'PRIORITY_SUPPORT',
  /** Product */
  PRODUCT = 'PRODUCT',
  /** Product engagement */
  PRODUCT_ENGAGEMENT = 'PRODUCT_ENGAGEMENT',
  /** Proposed Churned Date */
  PROPOSED_CHURNED_DATE = 'PROPOSED_CHURNED_DATE',
  /** Reasons for transition */
  REASONS_FOR_TRANSITION = 'REASONS_FOR_TRANSITION',
  /** Reason for churn */
  REASON_FOR_CHURN = 'REASON_FOR_CHURN',
  /** Reason for pause */
  REASON_FOR_PAUSE = 'REASON_FOR_PAUSE',
  /** Receive FR post-onboarding emails */
  RECEIVE_FR_POSTONBOARDING_EMAILS = 'RECEIVE_FR_POSTONBOARDING_EMAILS',
  /** Recurring 1:1 */
  RECURRING_11 = 'RECURRING_11',
  /** Re-engage Client Date */
  REENGAGE_CLIENT_DATE = 'REENGAGE_CLIENT_DATE',
  /** Referral Code */
  REFERRAL_CODE = 'REFERRAL_CODE',
  /** Refunds */
  REFUNDS = 'REFUNDS',
  /** Resources */
  RESOURCES = 'RESOURCES',
  /** Returning Clients */
  RETURNING_CLIENTS = 'RETURNING_CLIENTS',
  /** Return Date from Churned */
  RETURN_DATE_FROM_CHURNED = 'RETURN_DATE_FROM_CHURNED',
  /** Revenue, last 12 months */
  REVENUE_LAST_12_MONTHS = 'REVENUE_LAST_12_MONTHS',
  /** Revenue last 12 months >$20k */
  REVENUE_LAST_12_MONTHS_20K = 'REVENUE_LAST_12_MONTHS_20K',
  /** Revenue - Plus Plan  */
  REVENUE_PLUS_PLAN = 'REVENUE_PLUS_PLAN',
  /** Revenue, previous invoice */
  REVENUE_PREVIOUS_INVOICE = 'REVENUE_PREVIOUS_INVOICE',
  /** Revenue - Pro Plan */
  REVENUE_PRO_PLAN = 'REVENUE_PRO_PLAN',
  /** Revenue - Sept Advanced Plan */
  REVENUE_SEPT_ADVANCED_PLAN = 'REVENUE_SEPT_ADVANCED_PLAN',
  /** Revenue - Sept Pro */
  REVENUE_SEPT_PRO = 'REVENUE_SEPT_PRO',
  /** Revenue - Sept Starter Plan */
  REVENUE_SEPT_STARTER_PLAN = 'REVENUE_SEPT_STARTER_PLAN',
  /** Revenue - Sept Team Build */
  REVENUE_SEPT_TEAM_BUILD = 'REVENUE_SEPT_TEAM_BUILD',
  /** Revenue - Sept Team Enterprise Plan */
  REVENUE_SEPT_TEAM_ENTERPRISE_PLAN = 'REVENUE_SEPT_TEAM_ENTERPRISE_PLAN',
  /** Revenue - Sept Team Scale Plan */
  REVENUE_SEPT_TEAM_SCALE_PLAN = 'REVENUE_SEPT_TEAM_SCALE_PLAN',
  /** Revenue - Team Plan */
  REVENUE_TEAM_PLAN = 'REVENUE_TEAM_PLAN',
  /** See in Cockpit */
  SEE_IN_COCKPIT = 'SEE_IN_COCKPIT',
  /** Sentiment */
  SENTIMENT = 'SENTIMENT',
  /** Sept Pricing Package */
  SEPT_PRICING_PACKAGE = 'SEPT_PRICING_PACKAGE',
  /** Shortcuts */
  SHORTCUTS = 'SHORTCUTS',
  /** Source */
  SOURCE = 'SOURCE',
  /** Special profile */
  SPECIAL_PROFILE = 'SPECIAL_PROFILE',
  /** Status */
  STATUS = 'STATUS',
  /** Success Check-In */
  SUCCESS_CHECKIN = 'SUCCESS_CHECKIN',
  /** Success Check-In 2 */
  SUCCESS_CHECKIN_2 = 'SUCCESS_CHECKIN_2',
  /** Success Manager */
  SUCCESS_MANAGER = 'SUCCESS_MANAGER',
  /** Success Segment */
  SUCCESS_SEGMENT = 'SUCCESS_SEGMENT',
  /** Success Touchpoints */
  SUCCESS_TOUCHPOINTS = 'SUCCESS_TOUCHPOINTS',
  /** Suspension Date */
  SUSPENSION_DATE = 'SUSPENSION_DATE',
  /** Tasks categories first 30 days */
  TASKS_CATEGORIES_FIRST_30_DAYS = 'TASKS_CATEGORIES_FIRST_30_DAYS',
  /** Tasks categories last 30 days */
  TASKS_CATEGORIES_LAST_30_DAYS = 'TASKS_CATEGORIES_LAST_30_DAYS',
  /** Tenure */
  TENURE = 'TENURE',
  /** Tenure <3 months or >10 months */
  TENURE_3_MONTHS_OR_10_MONTHS = 'TENURE_3_MONTHS_OR_10_MONTHS',
  /** Termination Requests */
  TERMINATION_REQUESTS = 'TERMINATION_REQUESTS',
  /** Total # of CSATs submitted */
  TOTAL_OF_CSATS_SUBMITTED = 'TOTAL_OF_CSATS_SUBMITTED',
  /** Transitions */
  TRANSITIONS = 'TRANSITIONS',
  /** Transition Milestone? */
  TRANSITION_MILESTONE = 'TRANSITION_MILESTONE',
  /** Transition Request past 30 days */
  TRANSITION_REQUEST_PAST_30_DAYS = 'TRANSITION_REQUEST_PAST_30_DAYS',
  /** Transition Tenure */
  TRANSITION_TENURE = 'TRANSITION_TENURE',
  /** Upgrade Candidate? */
  UPGRADE_CANDIDATE = 'UPGRADE_CANDIDATE',
  /** Utilization - Current Month */
  UTILIZATION_CURRENT_MONTH = 'UTILIZATION_CURRENT_MONTH',
  /** Utilization - Prev Month */
  UTILIZATION_PREV_MONTH = 'UTILIZATION_PREV_MONTH',
  /** Yellow flags */
  YELLOW_FLAGS = 'YELLOW_FLAGS',
  /** 1Password Invite Date */
  _1PASSWORD_INVITE_DATE = '_1PASSWORD_INVITE_DATE',
  /** 1Password Status */
  _1PASSWORD_STATUS = '_1PASSWORD_STATUS',
  /** +1's team lead in the organization */
  _1S_TEAM_LEAD_IN_THE_ORGANIZATION = '_1S_TEAM_LEAD_IN_THE_ORGANIZATION',
  /** 3-Day Post onboarding survey */
  _3DAY_POST_ONBOARDING_SURVEY = '_3DAY_POST_ONBOARDING_SURVEY',
  /** 3-Day Post Transition Survey */
  _3DAY_POST_TRANSITION_SURVEY = '_3DAY_POST_TRANSITION_SURVEY',
  /** 3-Week Post-Onboarding Survey */
  _3WEEK_POSTONBOARDING_SURVEY = '_3WEEK_POSTONBOARDING_SURVEY',
  /** 7-day Checkin Status */
  _7DAY_CHECKIN_STATUS = '_7DAY_CHECKIN_STATUS',
  /** 7-day Transition Checkin Status */
  _7DAY_TRANSITION_CHECKIN_STATUS = '_7DAY_TRANSITION_CHECKIN_STATUS',
  /** 30-day Checkin First Email Sent Date */
  _30DAY_CHECKIN_FIRST_EMAIL_SENT_DATE = '_30DAY_CHECKIN_FIRST_EMAIL_SENT_DATE',
  /** 30-day Checkin Status */
  _30DAY_CHECKIN_STATUS = '_30DAY_CHECKIN_STATUS',
}

export type AirtableCollaborator = {
  __typename?: 'AirtableCollaborator'
  email: Scalars['EmailAddress']
  id: Scalars['ID']
  name: Scalars['String']
  user?: Maybe<Adminable>
}

export type AirtableDouble = {
  __typename?: 'AirtableDouble'
  fields: Scalars['JSONObject']
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  user?: Maybe<Assistantable>
  userId?: Maybe<Scalars['String']>
}

export type AirtableGenericNote = {
  __typename?: 'AirtableGenericNote'
  date?: Maybe<Scalars['Date']>
  fields: Scalars['JSONObject']
  id: Scalars['ID']
  notepad?: Maybe<Scalars['String']>
}

export type AirtableLead = {
  __typename?: 'AirtableLead'
  city?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  doubles?: Maybe<Maybe<AirtableDouble>[]>
  email?: Maybe<Scalars['EmailAddress']>
  fields: Scalars['JSONObject']
  id: Scalars['ID']
  introCalls?: Maybe<Maybe<AirtableRecord>[]>
  name?: Maybe<Scalars['String']>
  nextStep?: Maybe<Scalars['String']>
  owner?: Maybe<AirtableCollaborator>
  phoneNumber?: Maybe<Scalars['PhoneNumber']>
  reasonForMatch?: Maybe<Scalars['String']>
  teamInvites?: Maybe<Maybe<AirtableTeamInvite>[]>
  teammatesForMatching: AirtableLead[]
  user?: Maybe<Executivable>
  userId?: Maybe<Scalars['String']>
}

export type AirtableLeadsList = {
  __typename?: 'AirtableLeadsList'
  after?: Maybe<Scalars['String']>
  items: Maybe<AirtableLead>[]
}

export type AirtableRecord = {
  __typename?: 'AirtableRecord'
  fields: Scalars['JSONObject']
  id: Scalars['ID']
}

export type AirtableTeamInvite = {
  __typename?: 'AirtableTeamInvite'
  fields: Scalars['JSONObject']
  hoursLimit?: Maybe<Scalars['NonNegativeInt']>
  id: Scalars['ID']
  isAllowedPersonal: Scalars['Boolean']
  teamLeads?: Maybe<Maybe<AirtableClient>[]>
}

export type AirtableTransitionRequest = {
  __typename?: 'AirtableTransitionRequest'
  additionalFeedback?: Maybe<Scalars['String']>
  airtableClient?: Maybe<AirtableClient>
  airtableDouble?: Maybe<AirtableDouble>
  airtableLead?: Maybe<AirtableLead>
  completionDeadline?: Maybe<Scalars['Date']>
  fields: Scalars['JSONObject']
  id: Scalars['ID']
  owner?: Maybe<AirtableCollaborator>
  reasons?: Maybe<Scalars['String'][]>
  submittedBy?: Maybe<Scalars['String']>
  submittedOn?: Maybe<Scalars['Date']>
}

export type AnniversaryDate = {
  __typename?: 'AnniversaryDate'
  day?: Maybe<Scalars['PositiveInt']>
  month: Scalars['PositiveInt']
  year?: Maybe<Scalars['PositiveInt']>
}

export type AnniversaryDateInput = {
  day?: InputMaybe<Scalars['PositiveInt']>
  month: Scalars['PositiveInt']
  year?: InputMaybe<Scalars['Int']>
}

export type ArchiveFeatureInput = {
  featureId: Scalars['ID']
}

export type ArchiveFeatureOutput = {
  __typename?: 'ArchiveFeatureOutput'
  isSuccess: Scalars['Boolean']
}

export type ArchiveMatchingInput = {
  matchingId: Scalars['ID']
}

export type ArchiveMatchingOutput = {
  __typename?: 'ArchiveMatchingOutput'
  isSuccess: Scalars['Boolean']
}

export type ArchiveTeamInput = {
  churnDecisionOn: Scalars['DateTime']
  endDoubleSupportOn: Scalars['DateTime']
  initiatedBy?: InputMaybe<Scalars['String']>
  interestedInPlacement?: InputMaybe<Scalars['Boolean']>
  reason?: InputMaybe<Scalars['String']>
  teamId: Scalars['ID']
}

export type ArchiveTeamOutput = {
  __typename?: 'ArchiveTeamOutput'
  isSuccess: Scalars['Boolean']
}

export type ArchiveTopicTemplateInput = {
  templateId: Scalars['GUID']
}

export type ArchiveTopicTemplateOutput = {
  __typename?: 'ArchiveTopicTemplateOutput'
  isSuccess: Scalars['Boolean']
  template: TopicTemplate
}

export type ArchiveTransitionInput = {
  matchingId: Scalars['GUID']
}

export type ArchiveTransitionOutput = {
  __typename?: 'ArchiveTransitionOutput'
  matching: Matching
}

export type ArchiveUserInput = {
  endDate: Scalars['Date']
  reasons?: InputMaybe<Scalars['String'][]>
  userId: Scalars['ID']
}

export type ArchiveUserOutput = {
  __typename?: 'ArchiveUserOutput'
  isSuccess: Scalars['Boolean']
}

export type ArchiveWorkspaceInput = {
  workspaceId: Scalars['ID']
}

export type ArchiveWorkspaceOutput = {
  __typename?: 'ArchiveWorkspaceOutput'
  isSuccess: Scalars['Boolean']
}

export type AssignOnboardingSpecialistInput = {
  matchingId?: InputMaybe<Scalars['ID']>
  specialistId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type AssignOnboardingSpecialistOutput = {
  __typename?: 'AssignOnboardingSpecialistOutput'
  matching?: Maybe<Matching>
  workspace: Workspace
}

export type AssistantCopilot = {
  __typename?: 'AssistantCopilot'
  categoryPrompts: CategoryCopilotPromptSettings[]
  everydayTasksCopilotPromptSettings: MainCopilotPromptSettings
  execTasksCopilotPromptSettings: MainCopilotPromptSettings
  id: Scalars['ID']
  taskPrompts: TaskCopilotPromptSettings[]
}

export type AssistantPerformanceMetric = {
  __typename?: 'AssistantPerformanceMetric'
  events: AssistantPerformanceMetricEvent[]
  eventsReviewURL?: Maybe<Scalars['URL']>
  id: Scalars['ID']
  metricKind: AssistantPerformanceMetricKind
  value: Scalars['NonNegativeFloat']
  weight: Scalars['NonNegativeInt']
}

export type AssistantPerformanceMetricEvent = {
  __typename?: 'AssistantPerformanceMetricEvent'
  date: Scalars['Date']
  executive?: Maybe<Executivable>
  id: Scalars['ID']
  tags: AssistantPerformanceTag[]
}

export enum AssistantPerformanceMetricKind {
  ASSISTANT_REQUESTED_TRANSITION = 'ASSISTANT_REQUESTED_TRANSITION',
  CHURN = 'CHURN',
  EXEC_ENGAGEMENT = 'EXEC_ENGAGEMENT',
  EXEC_FEEDBACK = 'EXEC_FEEDBACK',
  HQ_FEEDBACK = 'HQ_FEEDBACK',
  REFUND = 'REFUND',
}

export type AssistantPerformanceScore = {
  __typename?: 'AssistantPerformanceScore'
  date: Scalars['Date']
  id: Scalars['ID']
  metrics: AssistantPerformanceMetric[]
  value: Scalars['NonNegativeFloat']
}

export type AssistantPerformanceScoreSeries = {
  __typename?: 'AssistantPerformanceScoreSeries'
  id: Scalars['ID']
  series: AssistantPerformanceScore[]
}

export enum AssistantPerformanceSentiment {
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
}

export type AssistantPerformanceTag = {
  __typename?: 'AssistantPerformanceTag'
  id: Scalars['ID']
  sentiment: AssistantPerformanceSentiment
  text: Scalars['String']
}

export type AssistantUser = Adminable &
  Assistantable &
  Matchable &
  Userable & {
    __typename?: 'AssistantUser'
    acceptedPptcAt?: Maybe<Scalars['DateTime']>
    accounts: UserAccount[]
    adminRoles: AdminRole[]
    airtableDoubleRecordId?: Maybe<Scalars['String']>
    allowedOperations: GuardedOperation[]
    averageWeeklyWorkedHours: Scalars['NonNegativeFloat']
    biography?: Maybe<Scalars['String']>
    birthday?: Maybe<AnniversaryDate>
    bugReportsList: BugReportsList
    category?: Maybe<UserCategory>
    city?: Maybe<Place>
    createdAt: Scalars['DateTime']
    csatAverage?: Maybe<Scalars['Float']>
    currency?: Maybe<Scalars['CurrencyCode']>
    dsm?: Maybe<HqUser>
    ein?: Maybe<Scalars['String']>
    endDate?: Maybe<Scalars['Date']>
    englishLevel?: Maybe<CefrScale>
    experienceHighlights?: Maybe<Scalars['String']>
    experienceInTools: Scalars['String'][]
    externalDisc?: Maybe<DiscScore>
    funFacts?: Maybe<Scalars['String']>
    hasPoolWorkspace: Scalars['Boolean']
    hasSlackPush: Scalars['Boolean']
    /**  Users / Read hourly rate of assistants  */
    hourlyRate?: Maybe<Scalars['PositiveFloat']>
    /**  Users / Read hourly rate history of assistants  */
    hourlyRateUpdates?: Maybe<AssistantableHourlyRateUpdate[]>
    id: Scalars['ID']
    industries: Scalars['String'][]
    interestedInIndustries: Scalars['String'][]
    internalDisc?: Maybe<DiscScore>
    isArchived: Scalars['Boolean']
    isOpenToMatch?: Maybe<Scalars['Boolean']>
    isOpenToSupport?: Maybe<Scalars['Boolean']>
    isSaas: Scalars['Boolean']
    justworksFamilyName?: Maybe<Scalars['String']>
    justworksGivenName?: Maybe<Scalars['String']>
    justworksVendorName?: Maybe<Scalars['String']>
    languages: Scalars['LanguageCode'][]
    latestOnboardingAt?: Maybe<Scalars['DateTime']>
    leverId?: Maybe<Scalars['GUID']>
    matchingNotepad?: Maybe<Scalars['String']>
    mobileOs?: Maybe<MobileOs>
    onboardingAvailability: OnboardingAvailability
    /** @deprecated use targetTotalWeeklyHours instead */
    openToAdditionalWeeklyHours?: Maybe<Scalars['String']>
    openToUpdatedAt?: Maybe<Scalars['DateTime']>
    orientation?: Maybe<Orientation>
    pendingChurnAutomationJob?: Maybe<AutomationJob>
    /** The performance score is updated daily, return the last n 1st of the month or the score for the start of the month of the date provided. If a date is provided, last is ignored. */
    performanceScore?: Maybe<AssistantPerformanceScoreSeries>
    /**  Current performance score value, is calculated on a cron job and updated in the database  */
    performanceScoreValue?: Maybe<Scalars['Float']>
    personalEmail?: Maybe<Scalars['String']>
    personalityTraits: Scalars['String'][]
    phoneNumber?: Maybe<Scalars['PhoneNumber']>
    primaryEmail?: Maybe<Scalars['String']>
    profile: UserProfile
    pronoun: UserPronoun
    reasonToTurnOffOpenToMatch?: Maybe<Scalars['String']>
    reports: WorkspaceReport[]
    resumeUrl: Scalars['URL']
    scheduleOnboardingConfig?: Maybe<ScheduleOnboardingConfig>
    schedulingUrl?: Maybe<Scalars['URL']>
    skills: Scalars['String'][]
    skillsetRating?: Maybe<Scalars['JSONObject']>
    slackUserId?: Maybe<Scalars['String']>
    slackUsername?: Maybe<Scalars['String']>
    startDate?: Maybe<Scalars['Date']>
    targetAdditionalWeeklyHours?: Maybe<Scalars['String']>
    targetTotalWeeklyHours?: Maybe<Scalars['String']>
    thirtyDayCheckInCallUrl?: Maybe<Scalars['URL']>
    updatedAt: Scalars['DateTime']
    workExperiences: WorkExperience[]
    workTimeZones?: Maybe<Scalars['String'][]>
    workspaces: Workspace[]
    yearsOfExperience?: Maybe<Scalars['NonNegativeFloat']>
  }

export type AssistantUserExperienceHighlightsArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type AssistantUserPerformanceScoreArgs = {
  date?: InputMaybe<Scalars['Date']>
  last?: InputMaybe<Scalars['PositiveInt']>
}

export type AssistantUserReportsArgs = {
  monthsCount?: InputMaybe<Scalars['NonNegativeInt']>
  quartersCount?: InputMaybe<Scalars['NonNegativeInt']>
  until?: InputMaybe<Scalars['DateTime']>
  weeksCount?: InputMaybe<Scalars['NonNegativeInt']>
}

export type AssistantUserWorkspacesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>
  isSandbox?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<WorkspaceUserRole>
}

export type Assistantable = {
  airtableDoubleRecordId?: Maybe<Scalars['String']>
  averageWeeklyWorkedHours: Scalars['NonNegativeFloat']
  biography?: Maybe<Scalars['String']>
  csatAverage?: Maybe<Scalars['Float']>
  currency?: Maybe<Scalars['CurrencyCode']>
  dsm?: Maybe<HqUser>
  ein?: Maybe<Scalars['String']>
  englishLevel?: Maybe<CefrScale>
  experienceHighlights?: Maybe<Scalars['String']>
  experienceInTools: Scalars['String'][]
  externalDisc?: Maybe<DiscScore>
  funFacts?: Maybe<Scalars['String']>
  hasSlackPush: Scalars['Boolean']
  hourlyRate?: Maybe<Scalars['PositiveFloat']>
  hourlyRateUpdates?: Maybe<AssistantableHourlyRateUpdate[]>
  id: Scalars['ID']
  interestedInIndustries: Scalars['String'][]
  internalDisc?: Maybe<DiscScore>
  isOpenToMatch?: Maybe<Scalars['Boolean']>
  isOpenToSupport?: Maybe<Scalars['Boolean']>
  justworksFamilyName?: Maybe<Scalars['String']>
  justworksGivenName?: Maybe<Scalars['String']>
  justworksVendorName?: Maybe<Scalars['String']>
  latestOnboardingAt?: Maybe<Scalars['DateTime']>
  leverId?: Maybe<Scalars['GUID']>
  matchingNotepad?: Maybe<Scalars['String']>
  mobileOs?: Maybe<MobileOs>
  onboardingAvailability: OnboardingAvailability
  /** @deprecated use targetTotalWeeklyHours instead */
  openToAdditionalWeeklyHours?: Maybe<Scalars['String']>
  openToUpdatedAt?: Maybe<Scalars['DateTime']>
  orientation?: Maybe<Orientation>
  /** The performance score is updated daily, return the last n 1st of the month or the score for the start of the month of the date provided. If a date is provided, last is ignored. */
  performanceScore?: Maybe<AssistantPerformanceScoreSeries>
  /**  Current performance score value, is calculated on a cron job and updated in the database  */
  performanceScoreValue?: Maybe<Scalars['Float']>
  personalEmail?: Maybe<Scalars['String']>
  primaryEmail?: Maybe<Scalars['String']>
  reasonToTurnOffOpenToMatch?: Maybe<Scalars['String']>
  resumeUrl: Scalars['URL']
  schedulingUrl?: Maybe<Scalars['URL']>
  skills: Scalars['String'][]
  skillsetRating?: Maybe<Scalars['JSONObject']>
  slackUserId?: Maybe<Scalars['String']>
  slackUsername?: Maybe<Scalars['String']>
  targetAdditionalWeeklyHours?: Maybe<Scalars['String']>
  targetTotalWeeklyHours?: Maybe<Scalars['String']>
  workExperiences: WorkExperience[]
  workTimeZones?: Maybe<Scalars['String'][]>
  yearsOfExperience?: Maybe<Scalars['NonNegativeFloat']>
}

export type AssistantableExperienceHighlightsArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type AssistantablePerformanceScoreArgs = {
  date?: InputMaybe<Scalars['Date']>
  last?: InputMaybe<Scalars['PositiveInt']>
}

export type AssistantableHourlyRateUpdate = {
  __typename?: 'AssistantableHourlyRateUpdate'
  hourlyRate: Scalars['PositiveFloat']
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<HqUser>
}

export type Attachment = {
  __typename?: 'Attachment'
  filename: Scalars['String']
  type: Scalars['String']
}

export type AudioFile = Filable & {
  __typename?: 'AudioFile'
  createdAt: Scalars['DateTime']
  /**  Constant URL to download file data, will redirect to expiring AWS S3 URL  */
  downloadUrl: Scalars['URL']
  /**  Duration in seconds  */
  duration?: Maybe<Scalars['NonNegativeFloat']>
  id: Scalars['GUID']
  /**  Know whether data is available to download or not  */
  isUploaded: Scalars['Boolean']
  mime: Scalars['MIME']
  name: Scalars['String']
  /**  File size in bytes  */
  size?: Maybe<Scalars['NonNegativeInt']>
  transcript?: Maybe<AudioFileTranscript>
  /**  Expiring AWS S3 URL to PUT file data  */
  uploadUrl: Scalars['URL']
}

export type AudioFileTranscript = {
  __typename?: 'AudioFileTranscript'
  /**  Score between 0 (not confident) and 1 (most confident)  */
  confidence: Scalars['NonNegativeFloat']
  text: Scalars['String']
}

export type Automation = {
  __typename?: 'Automation'
  actionGroups: AutomationActionGroup[]
  cancelOn: AutomationTrigger[]
  delay: Delay
  description?: Maybe<Scalars['String']>
  id: Scalars['GUID']
  isEnabled: Scalars['Boolean']
  name: Scalars['String']
  triggerOn: AutomationTrigger
}

export type AutomationAction = {
  __typename?: 'AutomationAction'
  config?: Maybe<Scalars['JSONObject']>
  description?: Maybe<Scalars['String']>
  id: Scalars['GUID']
  name: Scalars['String']
}

export type AutomationActionGroup = {
  __typename?: 'AutomationActionGroup'
  actions: AutomationAction[]
}

export type AutomationJob = {
  __typename?: 'AutomationJob'
  assistant?: Maybe<Assistantable>
  automation: Automation
  cancelledAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  delayUntil: Scalars['DateTime']
  endedAt?: Maybe<Scalars['DateTime']>
  executive?: Maybe<Executivable>
  hasError: Scalars['Boolean']
  id: Scalars['GUID']
  logs: AutomationJobLog[]
  params?: Maybe<Scalars['JSONObject']>
  results?: Maybe<Scalars['JSONObject']>
  reviewedAt?: Maybe<Scalars['DateTime']>
  reviewedBy?: Maybe<Adminable>
  startedAt?: Maybe<Scalars['DateTime']>
  updatedAt: Scalars['DateTime']
  user?: Maybe<Userable>
  workspace?: Maybe<Workspace>
}

export type AutomationJobLog = {
  __typename?: 'AutomationJobLog'
  action?: Maybe<AutomationAction>
  at: Scalars['DateTime']
  level?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['JSONObject']>
}

export type AutomationJobsList = {
  __typename?: 'AutomationJobsList'
  before?: Maybe<Scalars['String']>
  items: AutomationJob[]
}

export type AutomationParameter = {
  __typename?: 'AutomationParameter'
  isRequired: Scalars['Boolean']
  name: Scalars['String']
  type: Scalars['String']
}

export type AutomationTrigger = {
  __typename?: 'AutomationTrigger'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  parameters: Maybe<AutomationParameter>[]
}

export type AutomationTriggersList = {
  __typename?: 'AutomationTriggersList'
  items: AutomationTrigger[]
}

export type AutomationsList = {
  __typename?: 'AutomationsList'
  after?: Maybe<Scalars['String']>
  items: Automation[]
}

export type BillableEntity = ExecutiveUser | HqUser | SandboxUser | Team

export type BillingPauseStatus = {
  __typename?: 'BillingPauseStatus'
  endAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  startAt: Scalars['DateTime']
}

export type BugReport = {
  __typename?: 'BugReport'
  createdAt: Scalars['DateTime']
  files: BugReportFile[]
  id: Scalars['GUID']
  message?: Maybe<Scalars['String']>
  metadata: Scalars['JSONObject']
  properties: Scalars['JSONObject']
  user?: Maybe<Userable>
}

export type BugReportFile = {
  __typename?: 'BugReportFile'
  downloadUrl: Scalars['URL']
  id: Scalars['ID']
  mime: Scalars['MIME']
  name: Scalars['String']
  /**  File size in bytes  */
  size?: Maybe<Scalars['NonNegativeInt']>
}

export type BugReportsList = {
  __typename?: 'BugReportsList'
  items: BugReport[]
}

export type CancelAutomationJobInput = {
  id: Scalars['GUID']
}

export type CancelAutomationJobOutput = {
  __typename?: 'CancelAutomationJobOutput'
  automationJob: AutomationJob
}

export type CancelOnboardingCallInput = {
  workspaceId: Scalars['ID']
}

export type CancelOnboardingCallOutput = {
  __typename?: 'CancelOnboardingCallOutput'
  workspace: Workspace
}

export type CancelScheduledChurnInput = {
  userId: Scalars['ID']
}

export type CancelScheduledChurnOutput = {
  __typename?: 'CancelScheduledChurnOutput'
  canceledJobs: Maybe<AutomationJob>[]
}

export type CancelScheduledEntityPricingUpdateInput = {
  archivingUserPricingId: Scalars['ID']
  userId: Scalars['ID']
}

export type CancelScheduledEntityPricingUpdateOutput = {
  __typename?: 'CancelScheduledEntityPricingUpdateOutput'
  entity: BillableEntity
}

export type CancelScheduledTeamChurnInput = {
  teamId: Scalars['ID']
}

export type CancelScheduledTeamChurnOutput = {
  __typename?: 'CancelScheduledTeamChurnOutput'
  canceledJobs: AutomationJob[]
}

export type CategoryCopilotPromptSettings = CopilotPromptSettings &
  CopilotPromptSettingsOverridable &
  CopilotSubpromptable & {
    __typename?: 'CategoryCopilotPromptSettings'
    firstCalledFunctionInfo: FirstCalledFunctionInfo
    functions?: Maybe<CopilotFunction[]>
    id: TimeTrackingCategoryId
    label: Scalars['String']
    overridePrompts: CopilotPromptSettingsOverride[]
    systemPrompt?: Maybe<Scalars['String']>
  }

export type CategoryCopilotPromptSettingsInput = {
  firstCalledFunctionInfo?: InputMaybe<FirstCalledFunctionInfoInput>
  functions?: InputMaybe<UpdateCopilotSettingsFunctionInput[]>
  id?: InputMaybe<TimeTrackingCategoryId>
  overridePrompts?: InputMaybe<CopilotPromptSettingsOverrideInput[]>
  systemPrompt?: InputMaybe<Scalars['String']>
}

export enum CefrScale {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export type ChatThreadItem = {
  __typename?: 'ChatThreadItem'
  author: Userable
  content: Scalars['String']
  createdAt: Scalars['DateTime']
}

export enum ChatType {
  ASSISTANT = 'ASSISTANT',
  SHARED = 'SHARED',
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession'
  clientSecret?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lineItems?: Maybe<Maybe<CheckoutSessionLineItem>[]>
  status?: Maybe<CheckoutSessionStatus>
  total?: Maybe<Scalars['NonNegativeInt']>
}

export type CheckoutSessionLineItem = {
  __typename?: 'CheckoutSessionLineItem'
  description: Scalars['String']
  id: Scalars['ID']
  price?: Maybe<StripePrice>
  quantity?: Maybe<Scalars['NonNegativeInt']>
  total: Scalars['NonNegativeInt']
}

export enum CheckoutSessionStatus {
  COMPLETE = 'COMPLETE',
  EXPIRED = 'EXPIRED',
  OPEN = 'OPEN',
}

export type ChurnExecutiveInput = {
  /**  The day the executive communicated their decision, taken into account for billing. Defaults to today.  */
  churnDecisionOn?: InputMaybe<Scalars['Date']>
  endDoubleSupportOn?: InputMaybe<Scalars['Date']>
  interestedInPlacement?: InputMaybe<Scalars['Boolean']>
  notepad?: InputMaybe<Scalars['String']>
  /**  @deprecated(reason: "Use churnDecisionOn and let the churn date be the last of the month") The date the user's churn will be effective. Defaults to the end of the month of the churn decision.  */
  on?: InputMaybe<Scalars['Date']>
  reason: Scalars['String']
  teamNotes?: InputMaybe<Scalars['String'][]>
  userId: Scalars['ID']
}

export type ChurnExecutiveOutput = {
  __typename?: 'ChurnExecutiveOutput'
  isSuccess: Scalars['Boolean']
}

export type ClearAssistantHourlyRateUpdatesOutput = {
  __typename?: 'ClearAssistantHourlyRateUpdatesOutput'
  isSuccess: Scalars['Boolean']
}

export type ClearDataloadersOutput = {
  __typename?: 'ClearDataloadersOutput'
  isSuccess: Scalars['Boolean']
}

export type ClearTopicsInput = {
  leaveTodo?: InputMaybe<Scalars['PositiveInt']>
  workspaceId: Scalars['ID']
}

export type ClearTopicsOutput = {
  __typename?: 'ClearTopicsOutput'
  isSuccess: Scalars['Boolean']
}

export type CommunicationThreads = {
  __typename?: 'CommunicationThreads'
  chatThread: ChatThreadItem[]
  emailThread: EmailThreadItem[]
}

export type CompleteOnboardingInput = {
  attendedOnboarding?: InputMaybe<Scalars['Boolean']>
  enrollInNoCallOnboardingCampaign?: InputMaybe<Scalars['Boolean']>
  workspaceId: Scalars['ID']
}

export type CompleteOnboardingOutput = {
  __typename?: 'CompleteOnboardingOutput'
  workspace: Workspace
}

export type CompleteTransitionCallInput = {
  matchingId: Scalars['GUID']
}

export type CompleteTransitionCallOutput = {
  __typename?: 'CompleteTransitionCallOutput'
  matching: Matching
}

export type CompleteTransitionInput = {
  workspaceId: Scalars['ID']
}

export type CompleteTransitionOutput = {
  __typename?: 'CompleteTransitionOutput'
  workspace: Workspace
}

export type ConfirmMatchingAssistantInput = {
  assistantId: Scalars['ID']
  matchingId: Scalars['GUID']
}

export type ConfirmMatchingAssistantOutput = {
  __typename?: 'ConfirmMatchingAssistantOutput'
  matching: Matching
}

export type ContractorReport = {
  __typename?: 'ContractorReport'
  doubleHours: Scalars['NonNegativeFloat']
  id: Scalars['ID']
  totalHours: Scalars['NonNegativeFloat']
  user?: Maybe<Assistantable>
}

export type ContractorReportsList = {
  __typename?: 'ContractorReportsList'
  items: ContractorReport[]
}

export type Copilot = {
  __typename?: 'Copilot'
  /**  @deprecated(reason: "Use messagesPrompt instead")  */
  defaultPrompt?: Maybe<Scalars['String']>
  functions: Maybe<CopilotFunction>[]
  functionsEnabled: Scalars['Boolean']
  functionsPrompt: Scalars['String']
  id: Scalars['ID']
  messageCap: Scalars['PositiveInt']
  messagesPrompt: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CopilotFunction = {
  __typename?: 'CopilotFunction'
  description: Scalars['String']
  name: Scalars['String']
  parameters?: Maybe<Scalars['JSONObject']>
}

export type CopilotPreferencesFunctionInput = {
  description: Scalars['String']
  name: Scalars['String']
  parameters?: InputMaybe<Scalars['JSONObject']>
}

export type CopilotPreferencesInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  functions?: InputMaybe<InputMaybe<CopilotPreferencesFunctionInput>[]>
  functionsEnabled?: InputMaybe<Scalars['Boolean']>
  functionsPrompt?: InputMaybe<Scalars['String']>
  messagesPrompt?: InputMaybe<Scalars['String']>
}

export type CopilotPromptSettings = {
  firstCalledFunctionInfo: FirstCalledFunctionInfo
  functions?: Maybe<CopilotFunction[]>
  systemPrompt?: Maybe<Scalars['String']>
}

export type CopilotPromptSettingsOverridable = {
  overridePrompts: CopilotPromptSettingsOverride[]
}

export type CopilotPromptSettingsOverride = CopilotPromptSettings & {
  __typename?: 'CopilotPromptSettingsOverride'
  firstCalledFunctionInfo: FirstCalledFunctionInfo
  functions?: Maybe<CopilotFunction[]>
  id: Scalars['ID']
  systemPrompt?: Maybe<Scalars['String']>
  users: Assistantable[]
}

export type CopilotPromptSettingsOverrideInput = {
  firstCalledFunctionInfo?: InputMaybe<FirstCalledFunctionInfoInput>
  functions?: InputMaybe<UpdateCopilotSettingsFunctionInput[]>
  id: Scalars['ID']
  systemPrompt?: InputMaybe<Scalars['String']>
  userIds: Scalars['ID'][]
}

export type CopilotSubpromptable = {
  label: Scalars['String']
}

export type CreateAssistantInput = {
  addToAirtable?: InputMaybe<Scalars['Boolean']>
  cityPlaceId?: InputMaybe<Scalars['ID']>
  email: Scalars['EmailAddress']
  familyName: Scalars['String']
  givenName: Scalars['String']
  leverId?: InputMaybe<Scalars['GUID']>
  personalEmail: Scalars['EmailAddress']
  successManagerId?: InputMaybe<Scalars['ID']>
}

export type CreateExecutiveInput = {
  airtableLeadRecordId?: InputMaybe<Scalars['String']>
  assistantId?: InputMaybe<Scalars['ID']>
  cityPlaceId?: InputMaybe<Scalars['ID']>
  email: Scalars['EmailAddress']
  facet?: InputMaybe<UserFacet>
  familyName: Scalars['String']
  givenName: Scalars['String']
  hoursLimit?: InputMaybe<Scalars['PositiveInt']>
  isAllowedPersonal?: InputMaybe<Scalars['Boolean']>
  matchingRationale?: InputMaybe<Scalars['String']>
  principalUserId?: InputMaybe<Scalars['ID']>
  pronoun?: InputMaybe<UserPronoun>
  sendOnboardingEmail?: InputMaybe<Scalars['Boolean']>
  teamId?: InputMaybe<Scalars['ID']>
  workingHoursTimeZone: Scalars['String']
}

export type CreateExecutiveWorkspaceInput = {
  assistantId?: InputMaybe<Scalars['ID']>
  executiveId: Scalars['ID']
  matchingRationale?: InputMaybe<Scalars['String']>
  sendOnboardingEmail?: InputMaybe<Scalars['Boolean']>
  teamId?: InputMaybe<Scalars['ID']>
  workingHoursTimeZone: Scalars['String']
}

export type CreateExecutiveWorkspaceOutput = {
  __typename?: 'CreateExecutiveWorkspaceOutput'
  assistant?: Maybe<Assistantable>
  executive: Executivable
  workspace: Workspace
}

export type CreateHqInput = {
  cityPlaceId?: InputMaybe<Scalars['ID']>
  email: Scalars['EmailAddress']
  familyName: Scalars['String']
  givenName: Scalars['String']
  leverId?: InputMaybe<Scalars['GUID']>
  position?: InputMaybe<Scalars['String']>
}

export type CreateMatchingFromLeadInput = {
  addToTeamId?: InputMaybe<Scalars['ID']>
  bundlePrincipalRecordId: Scalars['String']
  internalMatchingDate: Scalars['Date']
  isHiddenFromCatalog?: InputMaybe<Scalars['Boolean']>
  isHighTouch?: InputMaybe<Scalars['Boolean']>
  matchDueDate: Scalars['Date']
  maxioSubscriptionId?: InputMaybe<Scalars['ID']>
  newTeamName?: InputMaybe<Scalars['String']>
  plusOneRecordIds?: InputMaybe<Scalars['String'][]>
  pricingId?: InputMaybe<Scalars['GUID']>
}

export type CreateMatchingFromLeadOutput = {
  __typename?: 'CreateMatchingFromLeadOutput'
  matching: Matching
}

export type CreateSandboxInput = {
  createExecutiveWorkspace?: InputMaybe<Scalars['Boolean']>
  email: Scalars['EmailAddress']
  familyName: Scalars['String']
  givenName: Scalars['String']
  teamId?: InputMaybe<Scalars['ID']>
}

export type CreateStripeCustomerInput = {
  entityId: Scalars['ID']
}

export type CreateStripeCustomerOutput = {
  __typename?: 'CreateStripeCustomerOutput'
  entity: BillableEntity
}

export type CreateStripeInvoiceInput = {
  autoCharge: Scalars['Boolean']
  customerId: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  lineItems: StripeInvoiceLineItemInput[]
}

export type CreateStripeInvoiceOutput = {
  __typename?: 'CreateStripeInvoiceOutput'
  customer: StripeCustomer
  invoice: StripeInvoice
}

export type CreateTeamInput = {
  iconUrl?: InputMaybe<Scalars['URL']>
  isSandbox?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  /**  🚟 Can be removed once we don't need to migrate data anymore  */
  pricingActiveAt?: InputMaybe<Scalars['DateTime']>
  pricingId?: InputMaybe<Scalars['ID']>
  principalUserId: Scalars['ID']
  stripeCustomerId?: InputMaybe<Scalars['ID']>
  successManagerId?: InputMaybe<Scalars['ID']>
  /**  This should include the workspaceId of the principal user  */
  teammateWorkspaceIds?: InputMaybe<Scalars['ID'][]>
}

export type CreateTestExecLeadInput = {
  numberOfTeammates?: InputMaybe<Scalars['NonNegativeInt']>
}

export type CreateTestExecLeadOutput = {
  __typename?: 'CreateTestExecLeadOutput'
  isSuccess: Scalars['Boolean']
}

export type CreateTestExecMatchingOutput = {
  __typename?: 'CreateTestExecMatchingOutput'
  isSuccess: Scalars['Boolean']
}

export type CreateTransitionSlackChannelInput = {
  matchingId: Scalars['ID']
}

export type CreateTransitionSlackChannelOutput = {
  __typename?: 'CreateTransitionSlackChannelOutput'
  matching: Matching
}

export type CsatFeedback = Feedbackable & {
  __typename?: 'CsatFeedback'
  assistant: Assistantable
  author: Userable
  commentText?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  executive: Executivable
  id: Scalars['GUID']
  rating: Scalars['PositiveInt']
  tags: Scalars['String'][]
  workspace?: Maybe<Workspace>
}

export type Delay = {
  __typename?: 'Delay'
  day?: Maybe<Scalars['PositiveInt']>
  hour?: Maybe<Scalars['NonNegativeInt']>
  minute?: Maybe<Scalars['NonNegativeInt']>
  timeZone?: Maybe<Scalars['String']>
  unit: DelayUnit
  value: Scalars['Int']
  weekday?: Maybe<Scalars['PositiveInt']>
}

export enum DelayUnit {
  BUSINESS_DAY = 'BUSINESS_DAY',
  BUSINESS_HOUR = 'BUSINESS_HOUR',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export type DelegationCoach = {
  __typename?: 'DelegationCoach'
  id: Scalars['ID']
  systemPrompt: Scalars['String']
  taskSuggestionsFunction: Scalars['String']
}

export type DeleteFeedbackInput = {
  feedbackId: Scalars['GUID']
}

export type DeleteFeedbackOutput = {
  __typename?: 'DeleteFeedbackOutput'
  feedback?: Maybe<Feedbackable>
}

export type DeleteTimeTrackingEntryInput = {
  timeTrackingEntryId: Scalars['GUID']
}

export type DeleteTimeTrackingEntryOutput = {
  __typename?: 'DeleteTimeTrackingEntryOutput'
  timeTrackingEntry: TimeTrackingEntry
}

export type DeleteUserAccountInput = {
  accountKey: Scalars['ID']
  userId: Scalars['ID']
}

export type DequeueMatchingProposalInput = {
  assistantId: Scalars['ID']
  matchingId: Scalars['GUID']
}

export type DequeueMatchingProposalOutput = {
  __typename?: 'DequeueMatchingProposalOutput'
  matching: Matching
}

export type DiscScore = {
  __typename?: 'DiscScore'
  c: Scalars['NonNegativeInt']
  d: Scalars['NonNegativeInt']
  i: Scalars['NonNegativeInt']
  s: Scalars['NonNegativeInt']
}

export type DiscScoreInput = {
  c: Scalars['NonNegativeInt']
  d: Scalars['NonNegativeInt']
  i: Scalars['NonNegativeInt']
  s: Scalars['NonNegativeInt']
}

export enum DiscoverCategory {
  ADMIN = 'ADMIN',
  CALENDAR = 'CALENDAR',
  CORE = 'CORE',
  FOOD = 'FOOD',
  INTERNAL = 'INTERNAL',
  PEOPLE = 'PEOPLE',
  PERSONAL = 'PERSONAL',
  TRAVEL = 'TRAVEL',
}

export enum DiscoverSectionKey {
  ADMIN = 'ADMIN',
  CALENDAR = 'CALENDAR',
  CONTACTS = 'CONTACTS',
  COVID19 = 'COVID19',
  FEATURED = 'FEATURED',
  HIRING = 'HIRING',
  IMPACT = 'IMPACT',
  MEETINGS = 'MEETINGS',
  PERSONAL = 'PERSONAL',
  RESEARCH = 'RESEARCH',
  SOCIAL = 'SOCIAL',
  TRAVEL = 'TRAVEL',
}

export type DossierField = {
  __typename?: 'DossierField'
  id: Scalars['GUID']
  input?: Maybe<DossierInputable>
  label?: Maybe<Scalars['String']>
  orderIndex?: Maybe<Scalars['String']>
}

export type DossierFolder = {
  __typename?: 'DossierFolder'
  header?: Maybe<DossierFolderHeader>
  id: Scalars['GUID']
  isPrivate: Scalars['Boolean']
  label?: Maybe<Scalars['String']>
  orderIndex?: Maybe<Scalars['String']>
  sections: DossierSection[]
}

export type DossierFolderHeader = {
  __typename?: 'DossierFolderHeader'
  description: Scalars['String']
  isEnabled: Scalars['Boolean']
  title: Scalars['String']
}

export type DossierFolderHeaderInput = {
  description: Scalars['String']
  isEnabled: Scalars['Boolean']
  title: Scalars['String']
}

export type DossierInputable = {
  maxValues?: Maybe<Scalars['PositiveInt']>
}

export type DossierInputableAnniversaryDate = DossierInputable & {
  __typename?: 'DossierInputableAnniversaryDate'
  maxValues?: Maybe<Scalars['PositiveInt']>
}

export type DossierInputableEmail = DossierInputable & {
  __typename?: 'DossierInputableEmail'
  maxValues?: Maybe<Scalars['PositiveInt']>
}

export type DossierInputableInput = {
  maxValues?: InputMaybe<Scalars['PositiveInt']>
  place?: InputMaybe<DossierInputablePlaceInput>
  text?: InputMaybe<DossierInputableTextInput>
  type: DossierInputableType
}

export type DossierInputablePhoneNumber = DossierInputable & {
  __typename?: 'DossierInputablePhoneNumber'
  maxValues?: Maybe<Scalars['PositiveInt']>
}

export type DossierInputablePlace = DossierInputable & {
  __typename?: 'DossierInputablePlace'
  hint?: Maybe<DossierInputablePlaceHint>
  maxValues?: Maybe<Scalars['PositiveInt']>
}

export enum DossierInputablePlaceHint {
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  ESTABLISHMENT = 'ESTABLISHMENT',
}

export type DossierInputablePlaceInput = {
  hint?: InputMaybe<DossierInputablePlaceHint>
}

export type DossierInputableText = DossierInputable & {
  __typename?: 'DossierInputableText'
  freeFormPlaceholder?: Maybe<Scalars['String']>
  isAllowingFreeForm: Scalars['Boolean']
  maxValues?: Maybe<Scalars['PositiveInt']>
  options: DossierInputableTextOption[]
}

export type DossierInputableTextInput = {
  freeFormPlaceholder?: InputMaybe<Scalars['String']>
  isAllowingFreeForm?: InputMaybe<Scalars['Boolean']>
  options?: InputMaybe<DossierInputableTextOptionInput[]>
}

export type DossierInputableTextOption = {
  __typename?: 'DossierInputableTextOption'
  isRecommended: Scalars['Boolean']
  text: Scalars['String']
}

export type DossierInputableTextOptionInput = {
  isRecommended?: InputMaybe<Scalars['Boolean']>
  text: Scalars['String']
}

export enum DossierInputableType {
  ANNIVERSARY_DATE = 'ANNIVERSARY_DATE',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PLACE = 'PLACE',
  TEXT = 'TEXT',
}

export type DossierSection = {
  __typename?: 'DossierSection'
  defaultInputType?: Maybe<DossierInputableType>
  emoji?: Maybe<Scalars['String']>
  fields: DossierField[]
  id: Scalars['GUID']
  isPrivate: Scalars['Boolean']
  label?: Maybe<Scalars['String']>
  orderIndex?: Maybe<Scalars['String']>
}

export type EmailActivity = {
  __typename?: 'EmailActivity'
  clicked: EmailActivityClicked[]
  deliveredAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  metadata?: Maybe<Scalars['JSONObject']>
  opened: EmailActivityOpened[]
  processedAt: Scalars['DateTime']
  tags: Scalars['String'][]
  template?: Maybe<EmailTemplate>
  to: Scalars['EmailAddress']
  user?: Maybe<Userable>
  workspace?: Maybe<Workspace>
}

export type EmailActivityClicked = {
  __typename?: 'EmailActivityClicked'
  at: Scalars['DateTime']
  url?: Maybe<Scalars['URL']>
}

export type EmailActivityOpened = {
  __typename?: 'EmailActivityOpened'
  at: Scalars['DateTime']
  userAgent?: Maybe<Scalars['String']>
}

export type EmailAddressWithNameInput = {
  email: Scalars['EmailAddress']
  name?: InputMaybe<Scalars['String']>
}

export type EmailData = {
  __typename?: 'EmailData'
  email: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type EmailMessageTemplate = MessageTemplate & {
  __typename?: 'EmailMessageTemplate'
  description: Scalars['String']
  id: Scalars['GUID']
  languages: MessageTemplateLanguage[]
  name: Scalars['String']
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate'
  html: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  placeholders: Scalars['String'][]
  subject: Scalars['String']
  text: Scalars['String']
  thumbnailUrl: Scalars['URL']
}

export type EmailThreadItem = {
  __typename?: 'EmailThreadItem'
  attachments?: Maybe<Attachment[]>
  forwardedAt: Scalars['DateTime']
  from: EmailData
  text: Scalars['String']
  to: EmailData
}

export type EntityPricing = {
  __typename?: 'EntityPricing'
  activeAt: Scalars['DateTime']
  expireAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  notepad: Scalars['String']
  owner: BillableEntity
  pricing: Pricing
  selectedAt: Scalars['DateTime']
}

export type EventCategoryMapping = {
  __typename?: 'EventCategoryMapping'
  category: TimelineItemCategory
  event: TimelineEvent
}

export type Executivable = {
  activePricings: EntityPricing[]
  airtableClient?: Maybe<AirtableClient>
  airtableClientRecordId?: Maybe<Scalars['String']>
  airtableLeadRecordId?: Maybe<Scalars['String']>
  csm?: Maybe<ExecutivableCsm>
  currentPricing?: Maybe<EntityPricing>
  desiredSkills: Scalars['String'][]
  facet?: Maybe<UserFacet>
  hoursLimit?: Maybe<Scalars['PositiveInt']>
  id: Scalars['ID']
  invoicedCycle: InvoicedCycle
  /** @deprecated Use invoicedCycle.invoicedPricing instead */
  invoicedPricing?: Maybe<EntityPricing>
  invoicedUser: Executivable
  isAllowedPersonal?: Maybe<Scalars['Boolean']>
  /** ❗️ Only utilize this for Cockpit invoicing. Can be removed once all users are on Maxio billing. */
  isOnMaxioBilling: Scalars['Boolean']
  isOnPause: Scalars['Boolean']
  matchingProfile?: Maybe<MatchingProfile>
  pricingSelections: PricingSelection[]
  principalUser?: Maybe<Executivable>
  stripeCustomer?: Maybe<StripeCustomer>
}

export type ExecutivableActivePricingsArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type ExecutivableInvoicedCycleArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type ExecutivableInvoicedPricingArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type ExecutivableCsm = {
  __typename?: 'ExecutivableCsm'
  id: Scalars['ID']
  source: ExecutivableCsmSource
  user: HqUser
}

export type ExecutivableCsmSource =
  | AssistantUser
  | ExecutiveUser
  | HqUser
  | SandboxUser
  | Team

export type ExecutiveUser = Executivable &
  Matchable &
  Userable & {
    __typename?: 'ExecutiveUser'
    acceptedPptcAt?: Maybe<Scalars['DateTime']>
    accounts: UserAccount[]
    activePricings: EntityPricing[]
    airtableClient?: Maybe<AirtableClient>
    airtableClientRecordId?: Maybe<Scalars['String']>
    airtableLeadRecordId?: Maybe<Scalars['String']>
    birthday?: Maybe<AnniversaryDate>
    bugReportsList: BugReportsList
    category?: Maybe<UserCategory>
    city?: Maybe<Place>
    createdAt: Scalars['DateTime']
    csm?: Maybe<ExecutivableCsm>
    currentPricing?: Maybe<EntityPricing>
    desiredSkills: Scalars['String'][]
    endDate?: Maybe<Scalars['Date']>
    facet?: Maybe<UserFacet>
    hasPoolWorkspace: Scalars['Boolean']
    hoursLimit?: Maybe<Scalars['PositiveInt']>
    id: Scalars['ID']
    industries: Scalars['String'][]
    invoicedCycle: InvoicedCycle
    /** @deprecated Use invoicedCycle.invoicedPricing instead */
    invoicedPricing?: Maybe<EntityPricing>
    invoicedUser: Executivable
    invoicingAgreements: InvoicingAgreement[]
    invoicingDiscounts: InvoicingDiscount[]
    invoicingRefunds: InvoicingRefund[]
    isAllowedPersonal?: Maybe<Scalars['Boolean']>
    isArchived: Scalars['Boolean']
    /** ❗️ Only utilize this for Cockpit invoicing */
    isOnMaxioBilling: Scalars['Boolean']
    isOnPause: Scalars['Boolean']
    isSaas: Scalars['Boolean']
    languages: Scalars['LanguageCode'][]
    matchingProfile?: Maybe<MatchingProfile>
    pendingChurnAutomationJob?: Maybe<AutomationJob>
    personalityTraits: Scalars['String'][]
    phoneNumber?: Maybe<Scalars['PhoneNumber']>
    pricingSelections: PricingSelection[]
    principalUser?: Maybe<Executivable>
    profile: UserProfile
    pronoun: UserPronoun
    startDate?: Maybe<Scalars['Date']>
    /**  Users / Read Stripe customer on executive user  */
    stripeCustomer?: Maybe<StripeCustomer>
    updatedAt: Scalars['DateTime']
    workspaces: Workspace[]
  }

export type ExecutiveUserActivePricingsArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type ExecutiveUserInvoicedCycleArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type ExecutiveUserInvoicedPricingArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type ExecutiveUserInvoicingAgreementsArgs = {
  from?: InputMaybe<Scalars['DateTime']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type ExecutiveUserInvoicingDiscountsArgs = {
  from?: InputMaybe<Scalars['DateTime']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type ExecutiveUserInvoicingRefundsArgs = {
  from?: InputMaybe<Scalars['DateTime']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type ExecutiveUserWorkspacesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>
  isSandbox?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<WorkspaceUserRole>
}

export type ExpireUserPricingInput = {
  expireAt: Scalars['DateTime']
  userPricingId: Scalars['ID']
}

export type ExpireUserPricingOutput = {
  __typename?: 'ExpireUserPricingOutput'
  user: Userable
}

export type ExportChatToStudioInput = {
  chatType: ChatType
  mutationId: Scalars['GUID']
  topicId: Scalars['GUID']
}

export type ExportChatToStudioOutput = {
  __typename?: 'ExportChatToStudioOutput'
  isSuccess: Scalars['Boolean']
}

export type ExportUserBillingInput = {
  userId: Scalars['ID']
}

export type ExportUserBillingOutput = {
  __typename?: 'ExportUserBillingOutput'
  user: Executivable
}

export type Feature = {
  __typename?: 'Feature'
  appVersions: Scalars['JSONObject']
  description: Scalars['String']
  id: Scalars['ID']
  targets: FeatureTarget[]
}

export type FeatureTarget = {
  __typename?: 'FeatureTarget'
  predicates: FeatureTargetPredicate[]
}

export type FeatureTargetAssistantPredicate = {
  __typename?: 'FeatureTargetAssistantPredicate'
  assistantIds: Scalars['ID'][]
  isNegated: Scalars['Boolean']
}

export type FeatureTargetConstantPredicate = {
  __typename?: 'FeatureTargetConstantPredicate'
  constant: Scalars['Boolean']
}

export type FeatureTargetCreatedAtPredicate = {
  __typename?: 'FeatureTargetCreatedAtPredicate'
  createdAfter: Scalars['DateTime']
  isNegated: Scalars['Boolean']
}

export type FeatureTargetExecutivePredicate = {
  __typename?: 'FeatureTargetExecutivePredicate'
  executiveIds: Scalars['ID'][]
  isNegated: Scalars['Boolean']
}

export type FeatureTargetFeaturePredicate = {
  __typename?: 'FeatureTargetFeaturePredicate'
  featureId: Scalars['ID']
  isNegated: Scalars['Boolean']
}

export type FeatureTargetInput = {
  predicates: FeatureTargetPredicateInput[]
}

export type FeatureTargetPredicate =
  | FeatureTargetAssistantPredicate
  | FeatureTargetConstantPredicate
  | FeatureTargetCreatedAtPredicate
  | FeatureTargetExecutivePredicate
  | FeatureTargetFeaturePredicate

export type FeatureTargetPredicateInput = {
  assistantIds?: InputMaybe<Scalars['ID'][]>
  constant?: InputMaybe<Scalars['Boolean']>
  createdAfter?: InputMaybe<Scalars['DateTime']>
  executiveIds?: InputMaybe<Scalars['ID'][]>
  featureId?: InputMaybe<Scalars['ID']>
  isNegated?: InputMaybe<Scalars['Boolean']>
}

export type FeaturesList = {
  __typename?: 'FeaturesList'
  items: Feature[]
}

export type FeedbackTimelineItem = TimelineItem & {
  __typename?: 'FeedbackTimelineItem'
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  counterpartUser: Userable
  emoji?: Maybe<Scalars['String']>
  event: TimelineEvent
  feedback: CsatFeedback
  title: Scalars['String']
}

export type Feedbackable = {
  assistant: Assistantable
  author: Userable
  createdAt: Scalars['DateTime']
  executive: Executivable
  id: Scalars['GUID']
  workspace?: Maybe<Workspace>
}

export type FeedbacksList = {
  __typename?: 'FeedbacksList'
  items: Feedbackable[]
}

export type Filable = {
  createdAt: Scalars['DateTime']
  /**  Constant URL to download file data, will redirect to expiring AWS S3 URL  */
  downloadUrl: Scalars['URL']
  id: Scalars['GUID']
  /**  Know whether data is available to download or not  */
  isUploaded: Scalars['Boolean']
  mime: Scalars['MIME']
  name: Scalars['String']
  /**  File size in bytes  */
  size?: Maybe<Scalars['NonNegativeInt']>
  /**  Expiring AWS S3 URL to PUT file data  */
  uploadUrl: Scalars['URL']
}

export type File = Filable & {
  __typename?: 'File'
  createdAt: Scalars['DateTime']
  /**  Constant URL to download file data, will redirect to expiring AWS S3 URL  */
  downloadUrl: Scalars['URL']
  id: Scalars['GUID']
  /**  Know whether data is available to download or not  */
  isUploaded: Scalars['Boolean']
  mime: Scalars['MIME']
  name: Scalars['String']
  /**  File size in bytes  */
  size?: Maybe<Scalars['NonNegativeInt']>
  /**  Expiring AWS S3 URL to PUT file data  */
  uploadUrl: Scalars['URL']
}

export type FirstCalledFunctionInfo = {
  __typename?: 'FirstCalledFunctionInfo'
  functionName?: Maybe<Scalars['String']>
  inerhitIfNull?: Maybe<Scalars['Boolean']>
}

export type FirstCalledFunctionInfoInput = {
  functionName?: InputMaybe<Scalars['String']>
  inerhitIfNull?: InputMaybe<Scalars['Boolean']>
}

export type FollowUpWithNewTransitionAssistantInput = {
  matchingId: Scalars['GUID']
}

export type FollowUpWithNewTransitionAssistantOutput = {
  __typename?: 'FollowUpWithNewTransitionAssistantOutput'
  matching: Matching
}

export type GeoCoordinates = {
  __typename?: 'GeoCoordinates'
  lat: Scalars['Float']
  lng: Scalars['Float']
}

export type GrantPoolTokensInput = {
  poolTokens: Scalars['PositiveInt']
  source: PoolTokenGrantSource
  userId: Scalars['ID']
}

export type GrantPoolTokensOutput = {
  __typename?: 'GrantPoolTokensOutput'
  workspace: Workspace
}

export type GuardedOperation = {
  __typename?: 'GuardedOperation'
  description?: Maybe<Scalars['String']>
  fieldName: Scalars['String']
  id: Scalars['ID']
  typeName: Scalars['String']
}

export type HqUser = Adminable &
  Assistantable &
  Executivable &
  Matchable &
  Userable & {
    __typename?: 'HqUser'
    acceptedPptcAt?: Maybe<Scalars['DateTime']>
    accounts: UserAccount[]
    activePricings: EntityPricing[]
    adminRoles: AdminRole[]
    airtableClient?: Maybe<AirtableClient>
    airtableClientRecordId?: Maybe<Scalars['String']>
    airtableDoubleRecordId?: Maybe<Scalars['String']>
    airtableLeadRecordId?: Maybe<Scalars['String']>
    allowedOperations: GuardedOperation[]
    averageWeeklyWorkedHours: Scalars['NonNegativeFloat']
    biography?: Maybe<Scalars['String']>
    birthday?: Maybe<AnniversaryDate>
    bugReportsList: BugReportsList
    category?: Maybe<UserCategory>
    city?: Maybe<Place>
    createdAt: Scalars['DateTime']
    csatAverage?: Maybe<Scalars['Float']>
    csm?: Maybe<ExecutivableCsm>
    currency?: Maybe<Scalars['CurrencyCode']>
    currentPricing?: Maybe<EntityPricing>
    desiredSkills: Scalars['String'][]
    dsm?: Maybe<HqUser>
    ein?: Maybe<Scalars['String']>
    endDate?: Maybe<Scalars['Date']>
    englishLevel?: Maybe<CefrScale>
    experienceHighlights?: Maybe<Scalars['String']>
    experienceInTools: Scalars['String'][]
    externalDisc?: Maybe<DiscScore>
    facet?: Maybe<UserFacet>
    funFacts?: Maybe<Scalars['String']>
    hasPoolWorkspace: Scalars['Boolean']
    hasSlackPush: Scalars['Boolean']
    /**  Users / Read hourly rate of HQ Team member  */
    hourlyRate?: Maybe<Scalars['PositiveFloat']>
    /**  Users / Read hourly rate history of HQ team member  */
    hourlyRateUpdates?: Maybe<AssistantableHourlyRateUpdate[]>
    hoursLimit?: Maybe<Scalars['PositiveInt']>
    id: Scalars['ID']
    industries: Scalars['String'][]
    interestedInIndustries: Scalars['String'][]
    internalDisc?: Maybe<DiscScore>
    invoicedCycle: InvoicedCycle
    /** @deprecated Use invoicedCycle.invoicedPricing instead */
    invoicedPricing?: Maybe<EntityPricing>
    invoicedUser: Executivable
    isAllowedPersonal?: Maybe<Scalars['Boolean']>
    isArchived: Scalars['Boolean']
    /** ❗️ Only utilize this for Cockpit invoicing */
    isOnMaxioBilling: Scalars['Boolean']
    isOnPause: Scalars['Boolean']
    isOpenToMatch?: Maybe<Scalars['Boolean']>
    isOpenToSupport?: Maybe<Scalars['Boolean']>
    isSaas: Scalars['Boolean']
    justworksFamilyName?: Maybe<Scalars['String']>
    justworksGivenName?: Maybe<Scalars['String']>
    justworksVendorName?: Maybe<Scalars['String']>
    languages: Scalars['LanguageCode'][]
    latestOnboardingAt?: Maybe<Scalars['DateTime']>
    leverId?: Maybe<Scalars['GUID']>
    matchingNotepad?: Maybe<Scalars['String']>
    matchingProfile?: Maybe<MatchingProfile>
    mobileOs?: Maybe<MobileOs>
    onboardingAvailability: OnboardingAvailability
    /** @deprecated use targetTotalWeeklyHours instead */
    openToAdditionalWeeklyHours?: Maybe<Scalars['String']>
    openToUpdatedAt?: Maybe<Scalars['DateTime']>
    orientation?: Maybe<Orientation>
    pendingChurnAutomationJob?: Maybe<AutomationJob>
    /** The performance score is updated daily, return the last n 1st of the month or the score for the start of the month of the date provided. If a date is provided, last is ignored. */
    performanceScore?: Maybe<AssistantPerformanceScoreSeries>
    /**  Current performance score value, is calculated on a cron job and updated in the database  */
    performanceScoreValue?: Maybe<Scalars['Float']>
    personalEmail?: Maybe<Scalars['String']>
    personalityTraits: Scalars['String'][]
    phoneNumber?: Maybe<Scalars['PhoneNumber']>
    position?: Maybe<Scalars['String']>
    pricingSelections: PricingSelection[]
    primaryEmail?: Maybe<Scalars['String']>
    principalUser?: Maybe<Executivable>
    profile: UserProfile
    pronoun: UserPronoun
    reasonToTurnOffOpenToMatch?: Maybe<Scalars['String']>
    resumeUrl: Scalars['URL']
    scheduleOnboardingConfig?: Maybe<ScheduleOnboardingConfig>
    schedulingUrl?: Maybe<Scalars['URL']>
    skills: Scalars['String'][]
    skillsetRating?: Maybe<Scalars['JSONObject']>
    slackUserId?: Maybe<Scalars['String']>
    slackUsername?: Maybe<Scalars['String']>
    startDate?: Maybe<Scalars['Date']>
    stripeCustomer?: Maybe<StripeCustomer>
    targetAdditionalWeeklyHours?: Maybe<Scalars['String']>
    targetTotalWeeklyHours?: Maybe<Scalars['String']>
    thirtyDayCheckInCallUrl?: Maybe<Scalars['URL']>
    updatedAt: Scalars['DateTime']
    workExperiences: WorkExperience[]
    workTimeZones?: Maybe<Scalars['String'][]>
    workspaces: Workspace[]
    yearsOfExperience?: Maybe<Scalars['NonNegativeFloat']>
  }

export type HqUserActivePricingsArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type HqUserExperienceHighlightsArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type HqUserInvoicedCycleArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type HqUserInvoicedPricingArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type HqUserPerformanceScoreArgs = {
  date?: InputMaybe<Scalars['Date']>
  last?: InputMaybe<Scalars['PositiveInt']>
}

export type HqUserWorkspacesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>
  isSandbox?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<WorkspaceUserRole>
}

export type ImageFile = Filable & {
  __typename?: 'ImageFile'
  createdAt: Scalars['DateTime']
  /**  Constant URL to download file data, will redirect to expiring AWS S3 URL  */
  downloadUrl: Scalars['URL']
  height?: Maybe<Scalars['NonNegativeInt']>
  id: Scalars['GUID']
  /**  Know whether data is available to download or not  */
  isUploaded: Scalars['Boolean']
  mime: Scalars['MIME']
  name: Scalars['String']
  /**  File size in bytes  */
  size?: Maybe<Scalars['NonNegativeInt']>
  /**  Expiring AWS S3 URL to PUT file data  */
  uploadUrl: Scalars['URL']
  width?: Maybe<Scalars['NonNegativeInt']>
}

export type InvoicedCycle = {
  __typename?: 'InvoicedCycle'
  cycleEndDate: Scalars['DateTime']
  cycleStartDate: Scalars['DateTime']
  invoicedPricing?: Maybe<EntityPricing>
  status: InvoicedCycleStatus
}

export enum InvoicedCycleStatus {
  CURRENT = 'CURRENT',
  NEXT = 'NEXT',
  NO_INVOICE = 'NO_INVOICE',
  UNKNOWN = 'UNKNOWN',
  deleted = 'deleted',
  draft = 'draft',
  open = 'open',
  paid = 'paid',
  uncollectible = 'uncollectible',
  void = 'void',
}

export type InvoicingAgreement = {
  __typename?: 'InvoicingAgreement'
  activeAt?: Maybe<Scalars['DateTime']>
  airtableUrl: Scalars['URL']
  description: Scalars['String']
  discountPercentage?: Maybe<Scalars['NonNegativeFloat']>
  expireAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
}

export type InvoicingDiscount = {
  __typename?: 'InvoicingDiscount'
  airtableUrl: Scalars['URL']
  credit: InvoicingDiscountCredit
  description: Scalars['String']
  id: Scalars['ID']
  reason: Scalars['String']
}

export type InvoicingDiscountCredit =
  | InvoicingDiscountCreditAmount
  | InvoicingDiscountCreditHours

export type InvoicingDiscountCreditAmount = {
  __typename?: 'InvoicingDiscountCreditAmount'
  amount?: Maybe<Scalars['PositiveFloat']>
}

export type InvoicingDiscountCreditHours = {
  __typename?: 'InvoicingDiscountCreditHours'
  hours?: Maybe<Scalars['PositiveInt']>
}

export type InvoicingHistoryList = {
  __typename?: 'InvoicingHistoryList'
  items?: Maybe<InvoicedCycle[]>
}

export type InvoicingRefund = {
  __typename?: 'InvoicingRefund'
  airtableUrl: Scalars['URL']
  credit: InvoicingRefundCredit
  description: Scalars['String']
  id: Scalars['ID']
  reason: Scalars['String']
}

export type InvoicingRefundCredit =
  | InvoicingRefundCreditAmount
  | InvoicingRefundCreditHours

export type InvoicingRefundCreditAmount = {
  __typename?: 'InvoicingRefundCreditAmount'
  amount?: Maybe<Scalars['PositiveFloat']>
}

export type InvoicingRefundCreditHours = {
  __typename?: 'InvoicingRefundCreditHours'
  hours?: Maybe<Scalars['PositiveInt']>
}

export type InvoicingReport = {
  __typename?: 'InvoicingReport'
  churnedAt?: Maybe<Scalars['DateTime']>
  executive: Executivable
  id: Scalars['ID']
  isPaused: Scalars['Boolean']
  isTransitioning: Scalars['Boolean']
  togglUsages: InvoicingReportTogglUsage[]
}

export type InvoicingReportTogglUsage = {
  __typename?: 'InvoicingReportTogglUsage'
  assistant?: Maybe<Assistantable>
  executive: Executivable
  hours: Scalars['NonNegativeFloat']
  id: Scalars['ID']
  workspace?: Maybe<Workspace>
}

export type InvoicingReportsList = {
  __typename?: 'InvoicingReportsList'
  items: Maybe<InvoicingReport>[]
}

export type LeverOpportunitiesList = {
  __typename?: 'LeverOpportunitiesList'
  after?: Maybe<Scalars['String']>
  items: Maybe<LeverOpportunity>[]
}

export type LeverOpportunity = {
  __typename?: 'LeverOpportunity'
  displayName: Scalars['String']
  emails: Scalars['EmailAddress'][]
  id: Scalars['GUID']
  location?: Maybe<Scalars['String']>
  phoneNumbers: Scalars['String'][]
  postingTitle?: Maybe<Scalars['String']>
}

export type LockTimeTrackingEntriesInput = {
  endedBefore: Scalars['DateTime']
}

export type LockTimeTrackingEntriesOutput = {
  __typename?: 'LockTimeTrackingEntriesOutput'
  isSuccess: Scalars['Boolean']
  lockedEntriesCount: Scalars['NonNegativeInt']
}

export type LoginOutput = {
  __typename?: 'LoginOutput'
  me: Adminable
  token: Scalars['String']
}

export type MainCopilotPromptSettings = CopilotPromptSettings &
  CopilotPromptSettingsOverridable & {
    __typename?: 'MainCopilotPromptSettings'
    firstCalledFunctionInfo: FirstCalledFunctionInfo
    functions?: Maybe<CopilotFunction[]>
    overridePrompts: CopilotPromptSettingsOverride[]
    systemPrompt?: Maybe<Scalars['String']>
  }

export type MainCopilotPromptSettingsInput = {
  firstCalledFunctionInfo?: InputMaybe<FirstCalledFunctionInfoInput>
  functions?: InputMaybe<UpdateCopilotSettingsFunctionInput[]>
  overridePrompts?: InputMaybe<CopilotPromptSettingsOverrideInput[]>
  systemPrompt?: InputMaybe<Scalars['String']>
}

export type MarkPaidTimeTrackingEntriesInput = {
  endedBefore: Scalars['DateTime']
}

export type MarkPaidTimeTrackingEntriesOutput = {
  __typename?: 'MarkPaidTimeTrackingEntriesOutput'
  isSuccess: Scalars['Boolean']
  markedEntriesCount: Scalars['NonNegativeInt']
}

export type Matchable = {
  id: Scalars['ID']
  industries: Scalars['String'][]
  personalityTraits: Scalars['String'][]
}

export type Matching = {
  __typename?: 'Matching'
  applications: MatchingApplication[]
  bookmarksCount: Scalars['NonNegativeInt']
  candidates?: Maybe<MatchingProposal[]>
  completedAt?: Maybe<Scalars['DateTime']>
  confirmedAssistant?: Maybe<Assistantable>
  executive?: Maybe<Executivable>
  hasToBeMatched: Scalars['Boolean']
  id: Scalars['GUID']
  isHiddenFromCatalog: Scalars['Boolean']
  isHighTouch: Scalars['Boolean']
  isHighTouchMaybe: Scalars['Boolean']
  isMatched: Scalars['Boolean']
  isTeamInvite?: Maybe<Scalars['Boolean']>
  isTransition: Scalars['Boolean']
  onboarding: MatchingOnboarding
  principalProfile?: Maybe<MatchingProfile>
  profiles: MatchingProfile[]
  profilesCount: Scalars['NonNegativeInt']
  proposals: MatchingProposal[]
  targetCandidatesCount: Scalars['PositiveInt']
  teamInvite?: Maybe<MatchingTeamInvite>
  transition?: Maybe<MatchingTransition>
  workspace?: Maybe<Workspace>
}

export type MatchingProposalsArgs = {
  excludeReplies?: InputMaybe<MatchingProposalReply[]>
  isSent?: InputMaybe<Scalars['Boolean']>
  onlyReplies?: InputMaybe<MatchingProposalReply[]>
}

export type MatchingApplication = {
  __typename?: 'MatchingApplication'
  assistant: Assistantable
  at?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  motivation?: Maybe<Scalars['String']>
}

export type MatchingOnboarding = {
  __typename?: 'MatchingOnboarding'
  callStartAt?: Maybe<Scalars['DateTime']>
  id: Scalars['GUID']
  isCompleted: Scalars['Boolean']
}

export type MatchingProfile = {
  __typename?: 'MatchingProfile'
  airtableLeadId?: Maybe<Scalars['String']>
  bioSections: MatchingProfileBioSection[]
  city?: Maybe<Scalars['String']>
  companyIndustry?: Maybe<Scalars['String']>
  companySize?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  desiredAvailability?: Maybe<Scalars['String']>
  eaPersonalityTraits: Scalars['String'][]
  /** @deprecated Use 'matchDueDate' instead */
  expectedMatchingDate?: Maybe<Scalars['Date']>
  hoursNeededRange?: Maybe<MatchingProfileHoursNeededRange>
  id: Scalars['ID']
  internalMatchingDate?: Maybe<Scalars['Date']>
  introCallDate?: Maybe<Scalars['Date']>
  jobRole?: Maybe<Scalars['String']>
  linkedInUrl?: Maybe<Scalars['URL']>
  matchDueDate?: Maybe<Scalars['Date']>
  name?: Maybe<Scalars['String']>
  tasks: Scalars['String'][]
  tools: Scalars['String'][]
}

export type MatchingProfileBioSection = {
  __typename?: 'MatchingProfileBioSection'
  text: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type MatchingProfileHoursNeededRange = {
  __typename?: 'MatchingProfileHoursNeededRange'
  from: Scalars['NonNegativeInt']
  to?: Maybe<Scalars['NonNegativeInt']>
}

export type MatchingProposal = {
  __typename?: 'MatchingProposal'
  additionalContext?: Maybe<Scalars['String']>
  application?: Maybe<MatchingApplication>
  assistant: Assistantable
  confirmedAt?: Maybe<Scalars['DateTime']>
  confirmedBy?: Maybe<Userable>
  executiveReasonForMatch?: Maybe<Scalars['String']>
  id: Scalars['ID']
  introCallAt?: Maybe<Scalars['DateTime']>
  isInstantInvite: Scalars['Boolean']
  orderIndex: Scalars['String']
  queuedAt: Scalars['DateTime']
  reasonForMatch?: Maybe<Scalars['String']>
  repliedAt?: Maybe<Scalars['DateTime']>
  reply?: Maybe<MatchingProposalReply>
  replyComment?: Maybe<Scalars['String']>
  sentAt?: Maybe<Scalars['DateTime']>
  slackChannelId?: Maybe<Scalars['ID']>
}

export enum MatchingProposalReply {
  NEED_MORE_INFO = 'NEED_MORE_INFO',
  NO = 'NO',
  YES = 'YES',
}

export type MatchingTeamInvite = {
  __typename?: 'MatchingTeamInvite'
  companyName?: Maybe<Scalars['String']>
  hoursLimit: Scalars['NonNegativeInt']
  id: Scalars['ID']
  isAllowedPersonal: Scalars['Boolean']
}

export type MatchingTimelineItem = TimelineItem & {
  __typename?: 'MatchingTimelineItem'
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  emoji?: Maybe<Scalars['String']>
  event: TimelineEvent
  matching: Matching
  title: Scalars['String']
}

export type MatchingTransition = {
  __typename?: 'MatchingTransition'
  airtableGenericNotes: AirtableGenericNote[]
  airtableTransitionRequest?: Maybe<AirtableTransitionRequest>
  airtableTransitionRequestId?: Maybe<Scalars['String']>
  csm?: Maybe<HqUser>
  deadlineOn?: Maybe<Scalars['Date']>
  didFollowUpWithNewAssistantAt?: Maybe<Scalars['DateTime']>
  didRequestExecutiveFeedbackAt?: Maybe<Scalars['DateTime']>
  didSwitch1PasswordAt?: Maybe<Scalars['DateTime']>
  didSwitchAssistantsAt?: Maybe<Scalars['DateTime']>
  fromAssistant?: Maybe<Assistantable>
  handoverAt?: Maybe<Scalars['DateTime']>
  handoverStatus?: Maybe<TransitionHandoverStatus>
  hasDisabledSlackMessages: Scalars['Boolean']
  highTouchReasons: Scalars['String'][]
  id: Scalars['GUID']
  informingCategory?: Maybe<TransitionInformingCategory>
  informingStatus: TransitionInformingStatus
  informingStatusUpdatedAt?: Maybe<Scalars['DateTime']>
  /** @deprecated use isHighTouch on matching */
  isHighTouch: Scalars['Boolean']
  matchingStatus: TransitionMatchingStatus
  notepad: Scalars['String']
  reasons: Scalars['String'][]
  requestedByUserCategory?: Maybe<UserCategory>
  requestedOn: Scalars['Date']
  scheduleCallToken: Scalars['String']
  sentMessages: MatchingTransitionSentMessage[]
  shouldDsmAttendHandover?: Maybe<Scalars['Boolean']>
  slackChannelId?: Maybe<Scalars['ID']>
}

export type MatchingTransitionSlackChannelIdArgs = {
  onlyIfExists?: InputMaybe<Scalars['Boolean']>
}

export type MatchingTransitionSentMessage = {
  __typename?: 'MatchingTransitionSentMessage'
  at: Scalars['DateTime']
  id: Scalars['ID']
  messageTemplate: MessageTemplate
}

export type MatchingsList = {
  __typename?: 'MatchingsList'
  after?: Maybe<Scalars['String']>
  items: Matching[]
  total: Scalars['NonNegativeInt']
}

export type MessageTemplate = {
  description: Scalars['String']
  id: Scalars['GUID']
  languages: MessageTemplateLanguage[]
  name: Scalars['String']
}

export enum MessageTemplateKind {
  EMAIL = 'EMAIL',
  SLACK = 'SLACK',
  SMS = 'SMS',
  TEXT = 'TEXT',
}

export type MessageTemplateLanguage = {
  __typename?: 'MessageTemplateLanguage'
  fields: Scalars['JSONObject']
  id: Scalars['GUID']
  languageCode: Scalars['LanguageCode']
  placeholders: Scalars['String'][]
}

export type MessageTemplatesList = {
  __typename?: 'MessageTemplatesList'
  after?: Maybe<Scalars['String']>
  items: MessageTemplate[]
}

export type MetricEvent = {
  __typename?: 'MetricEvent'
  apps: Scalars['String'][]
  columnName: Scalars['String']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  properties: MetricEventProperty[]
  sql: Scalars['String']
  tableName: Scalars['String']
  trackedEventName: Scalars['String']
}

export type MetricEventProperty = {
  __typename?: 'MetricEventProperty'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  type: Scalars['String']
}

export type MetricEventsList = {
  __typename?: 'MetricEventsList'
  items: MetricEvent[]
}

export enum MobileOs {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export type MutateWorkspaceUserOutput = {
  __typename?: 'MutateWorkspaceUserOutput'
  user: Userable
  workspace: Workspace
}

export type Mutation = {
  __typename?: 'Mutation'
  /** @deprecated INTERNAL USE ONLY */
  _mock?: Maybe<Scalars['Boolean']>
  /**  Users / Add an external account (email) to a user  */
  addUserAccount: Userable
  /**  Workspaces / Add assistant to a workspace  */
  addWorkspaceAssistant: MutateWorkspaceUserOutput
  /**  Feature flags / Archive a feature flag  */
  archiveFeature: ArchiveFeatureOutput
  /**  Matching / Archive a matching  */
  archiveMatching: ArchiveMatchingOutput
  /**  Team / Archive a team  */
  archiveTeam: ArchiveTeamOutput
  /**  TeamWorkspaces / Archive a team workspace  */
  archiveTeamWorkspace?: Maybe<Workspace>
  /**  Tasks / Archive a task template  */
  archiveTopicTemplate: ArchiveTopicTemplateOutput
  /**  Transitions / Archive a transition  */
  archiveTransition: ArchiveTransitionOutput
  /**  Users / Archive a user  */
  archiveUser: ArchiveUserOutput
  /**  Workspaces / Archive a workspace  */
  archiveWorkspace: ArchiveWorkspaceOutput
  /**  Onboarding / Assign onboarding specialist  */
  assignOnboardingSpecialist: AssignOnboardingSpecialistOutput
  /**  Autopilot / Cancel an automation job  */
  cancelAutomationJob: CancelAutomationJobOutput
  /**  Onboarding / Cancel onboarding call  */
  cancelOnboardingCall: CancelOnboardingCallOutput
  /**  Users / Cancel a scheduled churn  */
  cancelScheduledChurn: CancelScheduledChurnOutput
  /**  Pricings / Cancel a scheduled pricing update for a user or team  */
  cancelScheduledEntityPricingUpdate: CancelScheduledEntityPricingUpdateOutput
  /**  Teams / Cancel a scheduled churn  */
  cancelScheduledTeamChurn: CancelScheduledTeamChurnOutput
  /**  Users / Churn an executive  */
  churnExecutive: ChurnExecutiveOutput
  /**  Users / Clear assistant hourly rate updates cache  */
  clearAssistantHourlyRateUpdates: ClearAssistantHourlyRateUpdatesOutput
  /**  Engineering tools / Clear dataloaders  */
  clearDataloaders: ClearDataloadersOutput
  /**  Tasks / Mark todos with status TODO as DONE and leave leaveTodo number of TODO todos  */
  clearTopics: ClearTopicsOutput
  /**  Onboarding / Mark onboarding as completed  */
  completeOnboarding: CompleteOnboardingOutput
  /**  Transition / Mark transition as completed  */
  completeTransition: CompleteTransitionOutput
  /**  Transitions / Mark a transition call as completed  */
  completeTransitionCall: CompleteTransitionCallOutput
  /**  Matching / Confirm matching assistant  */
  confirmMatchingAssistant: ConfirmMatchingAssistantOutput
  /**  Users / Create assistant user  */
  createAssistant: AssistantUser
  /**  Users / Create an executive user  */
  createExecutive: ExecutiveUser
  /**  Workspaces / Create an executive workspace  */
  createExecutiveWorkspace: CreateExecutiveWorkspaceOutput
  /**  Users / Create an HQ team member user  */
  createHq: HqUser
  /**  Matching / Create a matching from a lead  */
  createMatchingFromLead: CreateMatchingFromLeadOutput
  /**  Users / Create a sandbox user  */
  createSandbox: SandboxUser
  /**  Users / Create a Stripe customer for a user or team  */
  createStripeCustomer: CreateStripeCustomerOutput
  /**  Invoicing / Create Stripe invoice  */
  createStripeInvoice: CreateStripeInvoiceOutput
  /**  Teams / Create a team  */
  createTeam: Team
  /**  Engineering tools / Create test exec lead  */
  createTestExecLead: CreateTestExecLeadOutput
  /**  Engineering tools / Create matching for test exec  */
  createTestExecMatching: CreateTestExecMatchingOutput
  /**  Transitions / Create a Slack channel for a transition  */
  createTransitionSlackChannel: CreateTransitionSlackChannelOutput
  /**  Feedbacks / Delete a feedback  */
  deleteFeedback: DeleteFeedbackOutput
  /**  Time tracking / Delete a time tracking entry  */
  deleteTimeTrackingEntry: DeleteTimeTrackingEntryOutput
  /**  Users / Remove a user external account  */
  deleteUserAccount: Userable
  /**  Matching / Remove proposal from matching queue  */
  dequeueMatchingProposal: DequeueMatchingProposalOutput
  /**  Pricings / Expire a user pricing  */
  expireUserPricing: ExpireUserPricingOutput
  /**  Copilot / Export chat to studio  */
  exportChatToStudio: ExportChatToStudioOutput
  /**  Invoicing / Export a user billing to Airtable  */
  exportUserBilling: ExportUserBillingOutput
  /**  Transitions / Follow up with new assistant after transition  */
  followUpWithNewTransitionAssistant: FollowUpWithNewTransitionAssistantOutput
  /**  Pool / grant pool tokens  */
  grantPoolTokens: GrantPoolTokensOutput
  /**  Workspaces / Link a user account to a workspace  */
  linkWorkspaceUserAccount: Workspace
  /**  Time tracking / Lock time tracking entries  */
  lockTimeTrackingEntries: LockTimeTrackingEntriesOutput
  login: LoginOutput
  /**
   *  Time tracking / Mark time tracking entries as paid
   * @deprecated Never utilized and no longer relevant
   */
  markPaidTimeTrackingEntries: MarkPaidTimeTrackingEntriesOutput
  /**  Teams / Pause a team for billing purposes  */
  pauseTeam: Team
  /**  Invoicing / Pay a Stripe invoice  */
  payStripeInvoice: PayStripeInvoiceOutput
  /**  Tasks / Mark all tasks as read for a user in a workspace  */
  readTopics: ReadTopicsOutput
  /**  Ad Hoc Todo / Remove assistant from ad hoc todo  */
  removeAdhocTodoAssistant: RemoveAdhocTodoAssistantOutput
  /**  Onboarding / Remove onboarding specialist  */
  removeOnboardingSpecialist: RemoveOnboardingSpecialistOutput
  /**  Workspaces / Remove a user from a workspace  */
  removeWorkspaceUser: MutateWorkspaceUserOutput
  /**  Onboarding / Reset a workspace onboarding  */
  resetOnboarding: ResetOnboardingOutput
  /**  Engineering tools / Reset test exec lifecycle  */
  resetTestExecLifecycle: ResetTestExecLifecycleOutput
  /**  Autopilot / Review automation job  */
  reviewAutomationJob: ReviewAutomationJobOutput
  /**  Engineering tools / Run Autopilot job manually  */
  runAutopilotJobManually: RunAutopilotJobManuallyOutput
  /**  Tasks / Save a task template form (preferences)  */
  saveTopicTemplateForm: SaveTopicTemplateFormOutput
  /**  Engineering tools / Schedule Autopilot jobs manually  */
  scheduleAutopilotJobs: ScheduleAutopilotJobsOutput
  /**  Onboarding / Schedule an onboarding call  */
  scheduleOnboardingCall: ScheduleOnboardingCallOutput
  /**  Teams / Schedule a team churn  */
  scheduleTeamChurn: ScheduleTeamChurnOutput
  /**  Pricings / Select a pricing for a user or team  */
  selectEntityPricing: SelectEntityPricingOutput
  /**  Users / Select primary external account for a user  */
  selectPrimaryUserAccount: Userable
  /**  Contractors / Send payment emails to contractors  */
  sendContractorsPaymentsEmails: SendContractorsPaymentsEmailsOutput
  /**  Feedbacks / Send a CSAT feedback request  */
  sendCsatFeedbackRequest: SendCsatFeedbackRequestOutput
  /**  Messaging / Send email from a Sendgrid template  */
  sendEmailFromTemplate: SendEmailFromTemplateOutput
  /**  Messaging / Send an email from a message template  */
  sendEmailMessage: SendEmailMessageOutput
  /**  Users / Send magic link email to sign-in  */
  sendLoginEmail: SendLoginEmailOutput
  /**  Matching / Send a matching proposal to an assistant  */
  sendMatchingProposal: SendMatchingProposalOutput
  /**  Onboarding / Send an onboarding email  */
  sendOnboardingEmail: SendOnboardingEmailOutput
  /**  Messaging / Send a Slack message from a message template  */
  sendSlackMessage: SendSlackMessageOutput
  /**  Messaging / Send SMS from Twilio  */
  sendSms: SendSmsOutput
  /**  Messaging / Send a SMS from a message template  */
  sendSmsMessage: SendSmsMessageOutput
  /**  Invoicing / Send a Stripe Invoice  */
  sendStripeInvoice: SendStripeInvoiceOutput
  /**  Onboarding / Record that an onboarding email was sent  */
  sentOnboardingEmail: SentOnboardingEmailOutput
  /**  Onboarding / Record that a post onboarding email was sent  */
  sentPostOnboardingEmail: SentPostOnboardingEmailOutput
  /**  Transitions / Record that a transition message was sent  */
  sentTransitionMessage: SentTransitionMessageOutput
  /**  Workspaces / Spectate a workspace  */
  spectateWorkspace: SpectateWorkspaceOutput
  /**  Transitions / Switch assistants for a transition  */
  switchTransitionAssistants: SwitchTransitionAssistantsOutput
  /**  Teams / Unarchive a team  */
  unarchiveTeam: Team
  /**  Transitions / Un-archive a transition  */
  unarchiveTransition: UnarchiveTransitionOutput
  /**  Users / Un-archive users  */
  unarchiveUser: UnarchiveUserOutput
  /**  Workspaces / Unlink a user account from a workspace   */
  unlinkWorkspaceUserAccount: Workspace
  /**  Teams / Unpause a team for billing purposes  */
  unpauseTeam: Team
  /**  Ad Hoc Todo / Update pool visibility  */
  updateAdhocTodoPoolVisibility: UpdateAdhocTodoPoolVisibilityOutput
  /**  AdhocTodo / Update Settings  */
  updateAdhocTodoSettings: UpdateAdhocTodoSettingsOutput
  /**  Users / Update self-admin profile  */
  updateAdminable: UpdateAdminableOutput
  /**  Admin / Update a user's admin roles  */
  updateAdminableRoles: UpdateAdminableRolesOutput
  /**  Users / Update assistant info  */
  updateAssistant: Assistantable
  /**  Assistant Copilot / Update Settings  */
  updateAssistantCopilotSettings: UpdateAssistantCopilotSettingsOutput
  /**  Copilot / Update Settings  */
  updateCopilotSettings: UpdateCopilotSettingsOutput
  /**  Feedbacks / Update a CSAT feedback  */
  updateCsatFeedback: UpdateCsatFeedbackOutput
  /**  DelegationCoach / Update Settings  */
  updateDelegationCoachSettings: UpdateDelegationCoachSettingsOutput
  /**  Matching / Update a matching configuration  */
  updateMatching: UpdateMatchingOutput
  /**  Metrics / Update a metric event info  */
  updateMetricEvent: UpdateMetricEventOutput
  /**  Metrics / Update a metric event property info  */
  updateMetricEventProperty: UpdateMetricEventPropertyOutput
  /**  Onboarding / Update a workspace onboarding step  */
  updateOnboardingStep: UpdateOnboardingStepOutput
  /**  Orientation / Update users' orientations  */
  updateOrientation: UpdateOrientationOutput
  /**  Contractors / Update pay period  */
  updatePayPeriod: UpdatePayPeriodOutput
  /**  Users / Update the Stripe customer id of a user or team  */
  updateStripeCustomerId: UpdateStripeCustomerIdOutput
  /**  System feature flags / Update system-level feature flags  */
  updateSystemFeatureFlag: UpdateSystemFeatureFlagOutput
  /**  Teams / Update a team  */
  updateTeam: Team
  /**  Teams / Update team billing  */
  updateTeamBilling: Team
  /**  Time tracking / Update a time tracking entry  */
  updateTimeTrackingEntry: UpdateTimeTrackingEntryOutput
  /**  Time tracking / Update the bill to of a time tracking entry  */
  updateTimeTrackingEntryBillTo: UpdateTimeTrackingEntryBillToOutput
  /**  Transitions / Update a transition changeover  */
  updateTransitionChangeover: UpdateTransitionChangeoverOutput
  /**  Transitions / Update a transition handover  */
  updateTransitionHandover: UpdateTransitionHandoverOutput
  /**  Transitions / Update a transition informing status  */
  updateTransitionInforming: UpdateTransitionInformingOutput
  /**  Transitions / Update a transition matching status  */
  updateTransitionMatchingStatus: UpdateTransitionMatchingStatusOutput
  /**  Transitions / Update a transition notepad  */
  updateTransitionNotepad: UpdateTransitionNotepadOutput
  /**  Users / Update user info  */
  updateUser: Userable
  /**  Workspaces / Update a workspace info  */
  updateWorkspace: UpdateWorkspaceOutput
  /**  TeamWorkspaces / Update the team of a workspace  */
  updateWorkspaceTeam: Workspace
  /**  Store a file in the public AWS S3 bucket of Double  */
  uploadPublicFile: UploadPublicFileOutput
  /**  Admin / Create or update an admin role  */
  upsertAdminRole: UpsertAdminRoleOutput
  /**  Dossier / Create and update standard dossier field  */
  upsertDossierField: UpsertDossierFieldOutput
  /**  Dossier / Create and update standard dossier folder  */
  upsertDossierFolder: UpsertDossierFolderOutput
  /**  Dossier / Create and update standard dossier section  */
  upsertDossierSection: UpsertDossierSectionOutput
  /**  Feature flags / Create and update feature flags  */
  upsertFeature: UpsertFeatureOutput
  /**  Matching / Add an assistant to a matching proposals queue  */
  upsertMatchingProposal: UpsertMatchingProposalOutput
  /**  Messaging / Create and update message templates  */
  upsertMessageTemplate: UpsertMessageTemplateOutput
  /**  Pricings / Create and update pricing configurations (not users' pricings)  */
  upsertPricing: UpsertPricingOutput
  upsertSetting: UpsertSettingOutput
  /**  Tasks / Create and update task templates  */
  upsertTopicTemplate: UpsertTopicTemplateOutput
  /**  Invoicing / Cancel a Stripe invoice  */
  voidStripeInvoice: VoidStripeInvoiceOutput
}

export type MutationAddUserAccountArgs = {
  input: AddUserAccountInput
  userId: Scalars['ID']
}

export type MutationAddWorkspaceAssistantArgs = {
  input: AddWorkspaceAssistantInput
}

export type MutationArchiveFeatureArgs = {
  input: ArchiveFeatureInput
}

export type MutationArchiveMatchingArgs = {
  input: ArchiveMatchingInput
}

export type MutationArchiveTeamArgs = {
  input: ArchiveTeamInput
}

export type MutationArchiveTeamWorkspaceArgs = {
  workspaceId: Scalars['ID']
}

export type MutationArchiveTopicTemplateArgs = {
  input: ArchiveTopicTemplateInput
}

export type MutationArchiveTransitionArgs = {
  input: ArchiveTransitionInput
}

export type MutationArchiveUserArgs = {
  input: ArchiveUserInput
}

export type MutationArchiveWorkspaceArgs = {
  input: ArchiveWorkspaceInput
}

export type MutationAssignOnboardingSpecialistArgs = {
  input: AssignOnboardingSpecialistInput
}

export type MutationCancelAutomationJobArgs = {
  input: CancelAutomationJobInput
}

export type MutationCancelOnboardingCallArgs = {
  input: CancelOnboardingCallInput
}

export type MutationCancelScheduledChurnArgs = {
  input: CancelScheduledChurnInput
}

export type MutationCancelScheduledEntityPricingUpdateArgs = {
  input: CancelScheduledEntityPricingUpdateInput
}

export type MutationCancelScheduledTeamChurnArgs = {
  input: CancelScheduledTeamChurnInput
}

export type MutationChurnExecutiveArgs = {
  input: ChurnExecutiveInput
}

export type MutationClearTopicsArgs = {
  input: ClearTopicsInput
}

export type MutationCompleteOnboardingArgs = {
  input: CompleteOnboardingInput
}

export type MutationCompleteTransitionArgs = {
  input: CompleteTransitionInput
}

export type MutationCompleteTransitionCallArgs = {
  input: CompleteTransitionCallInput
}

export type MutationConfirmMatchingAssistantArgs = {
  input: ConfirmMatchingAssistantInput
}

export type MutationCreateAssistantArgs = {
  input: CreateAssistantInput
}

export type MutationCreateExecutiveArgs = {
  input: CreateExecutiveInput
}

export type MutationCreateExecutiveWorkspaceArgs = {
  input: CreateExecutiveWorkspaceInput
}

export type MutationCreateHqArgs = {
  input: CreateHqInput
}

export type MutationCreateMatchingFromLeadArgs = {
  input: CreateMatchingFromLeadInput
}

export type MutationCreateSandboxArgs = {
  input: CreateSandboxInput
}

export type MutationCreateStripeCustomerArgs = {
  input: CreateStripeCustomerInput
}

export type MutationCreateStripeInvoiceArgs = {
  input: CreateStripeInvoiceInput
}

export type MutationCreateTeamArgs = {
  input: CreateTeamInput
}

export type MutationCreateTestExecLeadArgs = {
  input: CreateTestExecLeadInput
}

export type MutationCreateTransitionSlackChannelArgs = {
  input: CreateTransitionSlackChannelInput
}

export type MutationDeleteFeedbackArgs = {
  input: DeleteFeedbackInput
}

export type MutationDeleteTimeTrackingEntryArgs = {
  input: DeleteTimeTrackingEntryInput
}

export type MutationDeleteUserAccountArgs = {
  input: DeleteUserAccountInput
}

export type MutationDequeueMatchingProposalArgs = {
  input: DequeueMatchingProposalInput
}

export type MutationExpireUserPricingArgs = {
  input: ExpireUserPricingInput
}

export type MutationExportChatToStudioArgs = {
  input: ExportChatToStudioInput
}

export type MutationExportUserBillingArgs = {
  input: ExportUserBillingInput
}

export type MutationFollowUpWithNewTransitionAssistantArgs = {
  input: FollowUpWithNewTransitionAssistantInput
}

export type MutationGrantPoolTokensArgs = {
  input: GrantPoolTokensInput
}

export type MutationLinkWorkspaceUserAccountArgs = {
  accountId: Scalars['ID']
  userId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type MutationLockTimeTrackingEntriesArgs = {
  input: LockTimeTrackingEntriesInput
}

export type MutationLoginArgs = {
  idToken?: InputMaybe<Scalars['String']>
}

export type MutationMarkPaidTimeTrackingEntriesArgs = {
  input: MarkPaidTimeTrackingEntriesInput
}

export type MutationPauseTeamArgs = {
  input: PauseTeamInput
}

export type MutationPayStripeInvoiceArgs = {
  input: PayStripeInvoiceInput
}

export type MutationReadTopicsArgs = {
  input: ReadTopicsInput
}

export type MutationRemoveAdhocTodoAssistantArgs = {
  input: RemoveAdhocTodoAssistantInput
}

export type MutationRemoveOnboardingSpecialistArgs = {
  input: RemoveOnboardingSpecialistInput
}

export type MutationRemoveWorkspaceUserArgs = {
  input: RemoveWorkspaceUserInput
}

export type MutationResetOnboardingArgs = {
  input: ResetOnboardingInput
}

export type MutationReviewAutomationJobArgs = {
  input: ReviewAutomationJobInput
}

export type MutationRunAutopilotJobManuallyArgs = {
  input: RunAutopilotJobManuallyInput
}

export type MutationSaveTopicTemplateFormArgs = {
  input: SaveTopicTemplateFormInput
}

export type MutationScheduleAutopilotJobsArgs = {
  input: ScheduleAutopilotJobsInput
}

export type MutationScheduleOnboardingCallArgs = {
  input: ScheduleOnboardingCallInput
}

export type MutationScheduleTeamChurnArgs = {
  input: ScheduleTeamChurnInput
}

export type MutationSelectEntityPricingArgs = {
  input: SelectEntityPricingInput
}

export type MutationSelectPrimaryUserAccountArgs = {
  input: SelectPrimaryUserAccountInput
}

export type MutationSendContractorsPaymentsEmailsArgs = {
  input: SendContractorsPaymentsEmailsInput
}

export type MutationSendCsatFeedbackRequestArgs = {
  input: SendCsatFeedbackRequestInput
}

export type MutationSendEmailFromTemplateArgs = {
  input: SendEmailFromTemplateInput
}

export type MutationSendEmailMessageArgs = {
  input: SendEmailMessageInput
}

export type MutationSendLoginEmailArgs = {
  input: SendLoginEmailInput
}

export type MutationSendMatchingProposalArgs = {
  input: SendMatchingProposalInput
}

export type MutationSendOnboardingEmailArgs = {
  input: SendOnboardingEmailInput
}

export type MutationSendSlackMessageArgs = {
  input: SendSlackMessageInput
}

export type MutationSendSmsArgs = {
  input: SendSmsInput
}

export type MutationSendSmsMessageArgs = {
  input: SendSmsMessageInput
}

export type MutationSendStripeInvoiceArgs = {
  input: SendStripeInvoiceInput
}

export type MutationSentOnboardingEmailArgs = {
  input: SentOnboardingEmailInput
}

export type MutationSentPostOnboardingEmailArgs = {
  input: SentPostOnboardingEmailInput
}

export type MutationSentTransitionMessageArgs = {
  input: SentTransitionMessageInput
}

export type MutationSpectateWorkspaceArgs = {
  input: SpectateWorkspaceInput
}

export type MutationSwitchTransitionAssistantsArgs = {
  input: SwitchTransitionAssistantsInput
}

export type MutationUnarchiveTeamArgs = {
  input: UnarchiveTeamInput
}

export type MutationUnarchiveTransitionArgs = {
  input: UnarchiveTransitionInput
}

export type MutationUnarchiveUserArgs = {
  input: UnarchiveUserInput
}

export type MutationUnlinkWorkspaceUserAccountArgs = {
  accountId: Scalars['ID']
  userId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type MutationUnpauseTeamArgs = {
  input: UnpauseTeamInput
}

export type MutationUpdateAdhocTodoPoolVisibilityArgs = {
  input: UpdateAdhocTodoPoolVisibilityInput
}

export type MutationUpdateAdhocTodoSettingsArgs = {
  input: UpdateAdhocTodoSettingsInput
}

export type MutationUpdateAdminableArgs = {
  input: UpdateAdminableInput
}

export type MutationUpdateAdminableRolesArgs = {
  input: UpdateAdminableRolesInput
}

export type MutationUpdateAssistantArgs = {
  id: Scalars['ID']
  input: UpdateAssistantInput
}

export type MutationUpdateAssistantCopilotSettingsArgs = {
  input: UpdateAssistantCopilotSettingsInput
}

export type MutationUpdateCopilotSettingsArgs = {
  input: UpdateCopilotSettingsInput
}

export type MutationUpdateCsatFeedbackArgs = {
  input: UpdateCsatFeedbackInput
}

export type MutationUpdateDelegationCoachSettingsArgs = {
  input: UpdateDelegationCoachSettingsInput
}

export type MutationUpdateMatchingArgs = {
  input: UpdateMatchingInput
}

export type MutationUpdateMetricEventArgs = {
  input: UpdateMetricEventInput
}

export type MutationUpdateMetricEventPropertyArgs = {
  input: UpdateMetricEventPropertyInput
}

export type MutationUpdateOnboardingStepArgs = {
  input: UpdateOnboardingStepInput
}

export type MutationUpdateOrientationArgs = {
  input: UpdateOrientationInput
}

export type MutationUpdatePayPeriodArgs = {
  input: UpdatePayPeriodInput
}

export type MutationUpdateStripeCustomerIdArgs = {
  input: UpdateStripeCustomerIdInput
}

export type MutationUpdateSystemFeatureFlagArgs = {
  input: UpdateSystemFeatureFlagInput
}

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput
  teamId: Scalars['ID']
}

export type MutationUpdateTeamBillingArgs = {
  input: UpdateTeamBillingInput
  teamId: Scalars['ID']
}

export type MutationUpdateTimeTrackingEntryArgs = {
  input: UpdateTimeTrackingEntryInput
}

export type MutationUpdateTimeTrackingEntryBillToArgs = {
  input: UpdateTimeTrackingEntryBillToInput
}

export type MutationUpdateTransitionChangeoverArgs = {
  input: UpdateTransitionChangeoverInput
}

export type MutationUpdateTransitionHandoverArgs = {
  input: UpdateTransitionHandoverInput
}

export type MutationUpdateTransitionInformingArgs = {
  input: UpdateTransitionInformingInput
}

export type MutationUpdateTransitionMatchingStatusArgs = {
  input: UpdateTransitionMatchingStatusInput
}

export type MutationUpdateTransitionNotepadArgs = {
  input: UpdateTransitionNotepadInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
  userId: Scalars['ID']
}

export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput
}

export type MutationUpdateWorkspaceTeamArgs = {
  input: UpdateWorkspaceTeamInput
}

export type MutationUploadPublicFileArgs = {
  input: UploadPublicFileInput
}

export type MutationUpsertAdminRoleArgs = {
  input: UpsertAdminRoleInput
}

export type MutationUpsertDossierFieldArgs = {
  input: UpsertDossierFieldInput
}

export type MutationUpsertDossierFolderArgs = {
  input: UpsertDossierFolderInput
}

export type MutationUpsertDossierSectionArgs = {
  input: UpsertDossierSectionInput
}

export type MutationUpsertFeatureArgs = {
  input: UpsertFeatureInput
}

export type MutationUpsertMatchingProposalArgs = {
  input: UpsertMatchingProposalInput
}

export type MutationUpsertMessageTemplateArgs = {
  input: UpsertMessageTemplateInput
}

export type MutationUpsertPricingArgs = {
  input: UpsertPricingInput
}

export type MutationUpsertSettingArgs = {
  input: UpsertSettingInput
}

export type MutationUpsertTopicTemplateArgs = {
  input: UpsertTopicTemplateInput
}

export type MutationVoidStripeInvoiceArgs = {
  input: VoidStripeInvoiceInput
}

export type Onboarding = {
  __typename?: 'Onboarding'
  availability: OnboardingAvailability
  callStartAt?: Maybe<Scalars['DateTime']>
  checklist: OnboardingChecklist
  hasSentEmail: Scalars['Boolean']
  hasSentEmail2: Scalars['Boolean']
  hasSentEmail3: Scalars['Boolean']
  hasSentEmail4: Scalars['Boolean']
  hasSentPostOnboardingEmail: Scalars['Boolean']
  id: Scalars['GUID']
  isChecklistDismissed: Scalars['Boolean']
  isCompleted: Scalars['Boolean']
  rescheduleCallUrl: Scalars['URL']
  specialist?: Maybe<HqUser>
  splash: OnboardingSplash
  steps: OnboardingStep[]
  token: Scalars['String']
  url: Scalars['URL']
}

export type OnboardingAvailability = {
  __typename?: 'OnboardingAvailability'
  id: Scalars['ID']
  slots: OnboardingAvailabilitySlot[]
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type OnboardingAvailabilitySlot = {
  __typename?: 'OnboardingAvailabilitySlot'
  endAt: Scalars['DateTime']
  id: Scalars['ID']
  meta?: Maybe<Scalars['String']>
  startAt: Scalars['DateTime']
}

export type OnboardingAvailabilitySlotInput = {
  endAt: Scalars['DateTime']
  startAt: Scalars['DateTime']
}

export type OnboardingChecklist = {
  __typename?: 'OnboardingChecklist'
  id: Scalars['ID']
  /** @deprecated not used :/ */
  isCompleted?: Maybe<Scalars['Boolean']>
  tasks: OnboardingChecklistTask[]
}

export type OnboardingChecklistTask = {
  __typename?: 'OnboardingChecklistTask'
  id: Scalars['GUID']
  isCompleted: Scalars['Boolean']
  title: Scalars['String']
}

export enum OnboardingEmailIndex {
  FOUR = 'FOUR',
  ONE = 'ONE',
  THREE = 'THREE',
  TWO = 'TWO',
}

export type OnboardingSplash = {
  __typename?: 'OnboardingSplash'
  assistantAvatarUrl: Scalars['URL']
  assistantBiography: Scalars['String']
  assistantExperienceHighlights?: Maybe<Scalars['String']>
  assistantGivenName: Scalars['String']
  assistantLocation: Scalars['String']
  availability: OnboardingAvailability
  executiveEmail: Scalars['EmailAddress']
  matchingRationale?: Maybe<Scalars['String']>
  /** @deprecated Use in-house avialabilities instead */
  schedulingUrl?: Maybe<Scalars['URL']>
}

export type OnboardingSplashAssistantAvatarUrlArgs = {
  size?: InputMaybe<Scalars['PositiveInt']>
}

export type OnboardingSplashAssistantExperienceHighlightsArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type OnboardingSplashMatchingRationaleArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type OnboardingStep = {
  __typename?: 'OnboardingStep'
  completedAt?: Maybe<Scalars['DateTime']>
  data?: Maybe<Scalars['JSONObject']>
  id: OnboardingStepId
  isCompleted: Scalars['Boolean']
}

export enum OnboardingStepId {
  BILLING = 'BILLING',
  CALENDAR = 'CALENDAR',
  CREDENTIALS = 'CREDENTIALS',
  DEMO = 'DEMO',
  DOUBLE_APPS = 'DOUBLE_APPS',
  GOALS = 'GOALS',
  INBOX = 'INBOX',
  PREFERENCES = 'PREFERENCES',
  SCHEDULE_CALL = 'SCHEDULE_CALL',
  TASKS = 'TASKS',
  THIRD_PARTY_APPS = 'THIRD_PARTY_APPS',
}

export type Orientation = {
  __typename?: 'Orientation'
  hasAppAccess: Scalars['Boolean']
  hasCompletedSetup: Scalars['Boolean']
  hasEnabledGoogle2Fa: Scalars['Boolean']
  hasFilledProfile: Scalars['Boolean']
  hasJoinedSlack: Scalars['Boolean']
  hasSeenIntro: Scalars['Boolean']
  id: Scalars['GUID']
  isCompleted: Scalars['Boolean']
  sentPostDay1Email: Scalars['Boolean']
  sentPostDay2Email: Scalars['Boolean']
  sentPostDay3Email: Scalars['Boolean']
  sentPreDay1Email: Scalars['Boolean']
}

export type PauseTeamInput = {
  endAt?: InputMaybe<Scalars['DateTime']>
  startAt?: InputMaybe<Scalars['DateTime']>
  teamId: Scalars['ID']
}

export type PayPeriod = {
  __typename?: 'PayPeriod'
  id: Scalars['ID']
  lockTimeTrackingOn: Scalars['Date']
}

export type PayStripeInvoiceInput = {
  invoiceId: Scalars['String']
}

export type PayStripeInvoiceOutput = {
  __typename?: 'PayStripeInvoiceOutput'
  customer: StripeCustomer
  invoice: StripeInvoice
  isSuccess: Scalars['Boolean']
}

export type Place = {
  __typename?: 'Place'
  address?: Maybe<PlaceAddress>
  geo?: Maybe<PlaceGeography>
  googleMapsPlaceId?: Maybe<Scalars['ID']>
  googleMapsUrl?: Maybe<Scalars['URL']>
  id: Scalars['GUID']
  name?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
}

export type PlaceAddress = {
  __typename?: 'PlaceAddress'
  country?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['CountryCode']>
  county?: Maybe<Scalars['String']>
  formatted?: Maybe<Scalars['String']>
  id: Scalars['GUID']
  line1?: Maybe<Scalars['String']>
  line2?: Maybe<Scalars['String']>
  locality?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
}

export type PlaceAddressRegionArgs = {
  long?: InputMaybe<Scalars['Boolean']>
}

export type PlaceAddressInput = {
  countryCode?: InputMaybe<Scalars['CountryCode']>
  county?: InputMaybe<Scalars['String']>
  line1?: InputMaybe<Scalars['String']>
  line2?: InputMaybe<Scalars['String']>
  locality?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  regionLong?: InputMaybe<Scalars['String']>
  regionShort?: InputMaybe<Scalars['String']>
}

export type PlaceGeography = {
  __typename?: 'PlaceGeography'
  coordinates: GeoCoordinates
}

export type PlaceInput = {
  address?: InputMaybe<PlaceAddressInput>
  googleMapsPlaceId?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export type PoolConfiguration = {
  __typename?: 'PoolConfiguration'
  /**  The current value of a Pool Token (i.e. user facing "token") in internal task estimation token units */
  tokenUnitsPerPoolToken: Scalars['NonNegativeInt']
}

export type PoolTokenBalance = {
  __typename?: 'PoolTokenBalance'
  /** @deprecated Use 'availablePoolTokenCount' instead */
  availableCount: Scalars['NonNegativeInt']
  availablePoolTokenCount: Scalars['NonNegativeInt']
  id: Scalars['ID']
}

export enum PoolTokenGrantSource {
  GIFT = 'GIFT',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export type Pricing = {
  __typename?: 'Pricing'
  description?: Maybe<Scalars['String']>
  hoursDescription?: Maybe<Scalars['String']>
  id: Scalars['GUID']
  /**  The Pricing plans includes extra features such as 1Password vault, dedicated CSM, etc.  */
  includesPremiumPerks: Scalars['Boolean']
  /**  The Pricing plan is compatible with Double for Teams features such as Shared Tasks  */
  includesTeamFeatures: Scalars['Boolean']
  instantInviteMaxUsers: Scalars['NonNegativeInt']
  isAvailable: Scalars['Boolean']
  isPublic: Scalars['Boolean']
  isSharable: Scalars['Boolean']
  label: Scalars['String']
  learnMoreUrl?: Maybe<Scalars['URL']>
  model?: Maybe<PricingModel>
  modelConfig: Scalars['JSONObject']
  priceDescription?: Maybe<Scalars['String']>
  ref: Scalars['String']
  sellingPoints: Scalars['String'][]
  sellingPointsNotice?: Maybe<Scalars['String']>
}

export enum PricingModel {
  HOURLY = 'HOURLY',
  ORIGINAL = 'ORIGINAL',
}

export type PricingSelection = {
  __typename?: 'PricingSelection'
  activeAt: Scalars['DateTime']
  expireAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  notepad: Scalars['String']
  pricing?: Maybe<Pricing>
  selectedAt: Scalars['DateTime']
}

export type PricingsList = {
  __typename?: 'PricingsList'
  items: Pricing[]
}

export type Query = {
  __typename?: 'Query'
  /** @deprecated INTERNAL USE ONLY */
  _mock?: Maybe<Scalars['Boolean']>
  /**  Adhoc Todo / View Adhoc Todo settings  */
  adhocTodoSettings: AdhocTodoSettings
  /**  Ad Hoc Todo / Read list of ad hoc todos  */
  adhocTodosList: AdhocTodosList
  /**  Admin / Read list of admin roles  */
  adminRolesList: AdminRolesList
  /**  Airtable / Get a lead by record ID  */
  airtableLeadByLeadRecordId: AirtableLead
  airtableLeadsList: AirtableLeadsList
  /**  Users / Read an assistant detail info  */
  assistantById: AssistantUser
  /**  Assistant Copilot / View Assistant Copilot settings  */
  assistantCopilot: AssistantCopilot
  /**  Autopilot / Read an automation job  */
  automationJobById: AutomationJob
  /**  Autopilot / Read list of automation jobs  */
  automationJobsList: AutomationJobsList
  /**  Autopilot / Read list of Autopilot automation triggers  */
  automationTriggersList: AutomationTriggersList
  /**  Autopilot / Read list of automations  */
  automationsList: AutomationsList
  /**  Bug reports / Read list of bug reports  */
  bugReportsList: BugReportsList
  /**  Contractors / Read list of contractor reports  */
  contractorReportsList: ContractorReportsList
  /**  Copilot / View Copilot settings  */
  copilot: Copilot
  /**  Delegation Coach / View Delegation Coach settings  */
  delegationCoach: DelegationCoach
  /**  Messaging / Read history of emails sent  */
  emailActivities: EmailActivity[]
  /**  Messaging / Read a Sendgrid email template  */
  emailTemplateById: EmailTemplate
  /**  Messaging / Read list of Sendgrid email templates  */
  emailTemplates: EmailTemplate[]
  /**  Event Timeline / View Event Category Mapping List  */
  eventCategoryMappingList?: Maybe<EventCategoryMapping[]>
  /**  Feature flags / read list of feature flags  */
  featuresList: FeaturesList
  /**  Feedbacks / Read list of feedbacks  */
  feedbacksList: FeedbacksList
  /**  Admin / Read list of guarded operations  */
  guardedOperations: GuardedOperation[]
  /**  Invoicing / Read list of Invoicing History  */
  invoicingHistoryList: InvoicingHistoryList
  /**  Invoicing / Read list of invoicing reports  */
  invoicingReportsList: InvoicingReportsList
  /**  Lever / Read list of Lever opportunities  */
  leverOpportunitiesList: LeverOpportunitiesList
  /**  Matching / Read a matching  */
  matchingById?: Maybe<Matching>
  /**  Matching / Read list of matchings  */
  matchingsList: MatchingsList
  /**  Users / Authenticated user  */
  me: Adminable
  /**  Messaging / Read a message template  */
  messageTemplateById: MessageTemplate
  /**  Messaging / Read list of message templates  */
  messageTemplatesList: MessageTemplatesList
  /**  Metrics / Read list of metric events  */
  metricEventsList: MetricEventsList
  /**  Onboarding / Read onboarding call availability  */
  onboardingCallAvailability: OnboardingAvailability
  /**  Contractors / Read pay period  */
  payPeriod: PayPeriod
  placeByGoogleMapsPlaceId: Place
  /**  Pool Membership / View Pool Membership configuration  */
  poolConfiguration: PoolConfiguration
  /**  Pricings / Read list of pricings  */
  pricingsList: PricingsList
  settingById: Setting
  /**  Dossier / Read standard dossier entities  */
  standardDossierFolders: DossierFolder[]
  /**  System feature flags / Read list of system-level feature flags  */
  systemFeatureFlagsList: SystemFeatureFlagsList
  /**  Teams / Read a team  */
  teamById: Team
  /**  Event Timeline / Read list of team event timeline items  */
  teamTimelineItemsList: TimelineItemsList
  /**  Teams / Read list of teams  */
  teamsList: TeamsList
  /**  Engineering tools / Get test exec lifecycle data  */
  testExec?: Maybe<TestExec>
  /**  Time tracking / Read list of time tracking entries  */
  timeTrackingEntriesList: TimeTrackingEntriesList
  /**  Time Tracking / View Time Tracking Entry Templates List  */
  timeTrackingEntryTemplatesList: TimeTrackingEntryTemplatesList
  /**  Event Timeline / Read list of event timeline items  */
  timelineItemsList: TimelineItemsList
  /**  Tasks / Read list of task templates  */
  topicTemplatesList: TopicTemplatesList
  /**  Users / Read a user  */
  userById: Userable
  /**  Users / Get fields options  */
  userFieldsOptions: UserFieldsOptions
  /**  Users / Read list of users  */
  usersList: UsersList
  /**  Who's who / Read a profile  */
  whoById: Who
  /**  Who's who / Read list of profiles  */
  whosList: WhosList
  /**  Workspaces / Read a workspace  */
  workspaceById: Workspace
  /**  Workspaces / Read list of workspaces of a user  */
  workspacesByUserId: Workspace[]
  /**  Workspaces / Read list of workspaces  */
  workspacesList: WorkspacesList
}

export type QueryAdhocTodosListArgs = {
  after?: InputMaybe<Scalars['String']>
  excludedStatuses?: InputMaybe<TodoStatus[]>
  first?: InputMaybe<Scalars['PositiveInt']>
}

export type QueryAirtableLeadByLeadRecordIdArgs = {
  leadRecordId: Scalars['String']
}

export type QueryAirtableLeadsListArgs = {
  inNextSteps?: InputMaybe<Scalars['String'][]>
  isClient?: InputMaybe<Scalars['Boolean']>
  nameContains?: InputMaybe<Scalars['String']>
}

export type QueryAssistantByIdArgs = {
  id: Scalars['ID']
}

export type QueryAutomationJobByIdArgs = {
  id: Scalars['GUID']
}

export type QueryAutomationJobsListArgs = {
  automationIds?: InputMaybe<Scalars['GUID'][]>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  hasCompleted?: InputMaybe<Scalars['Boolean']>
  hasError?: InputMaybe<Scalars['Boolean']>
  hasFailed?: InputMaybe<Scalars['Boolean']>
  hasParams?: InputMaybe<Scalars['JSONObject']>
  isPending?: InputMaybe<Scalars['Boolean']>
  isReviewed?: InputMaybe<Scalars['Boolean']>
  userId?: InputMaybe<Scalars['String']>
  workspaceId?: InputMaybe<Scalars['String']>
}

export type QueryAutomationsListArgs = {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  messageTemplateIds?: InputMaybe<Scalars['GUID'][]>
  triggerIds?: InputMaybe<Scalars['String'][]>
}

export type QueryContractorReportsListArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type QueryEmailActivitiesArgs = {
  allOfTags?: InputMaybe<Scalars['String'][]>
  dateAfter?: InputMaybe<Scalars['DateTime']>
  messageTemplateIds?: InputMaybe<Scalars['String'][]>
  oneOfTags?: InputMaybe<Scalars['String'][]>
  recipients?: InputMaybe<Scalars['EmailAddress'][]>
  sendgridTemplateIds?: InputMaybe<Scalars['String'][]>
  userId?: InputMaybe<Scalars['ID']>
  workspaceId?: InputMaybe<Scalars['ID']>
}

export type QueryEmailTemplateByIdArgs = {
  id: Scalars['ID']
}

export type QueryFeedbacksListArgs = {
  assistantId?: InputMaybe<Scalars['ID']>
  createdAfter?: InputMaybe<Scalars['DateTime']>
  createdBefore?: InputMaybe<Scalars['DateTime']>
  maxRating?: InputMaybe<Scalars['PositiveInt']>
  minRating?: InputMaybe<Scalars['PositiveInt']>
  workspaceId?: InputMaybe<Scalars['ID']>
}

export type QueryInvoicingHistoryListArgs = {
  entityId: Scalars['ID']
  from?: InputMaybe<Scalars['DateTime']>
  to?: InputMaybe<Scalars['DateTime']>
}

export type QueryInvoicingReportsListArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type QueryLeverOpportunitiesListArgs = {
  after?: InputMaybe<Scalars['String']>
  hiredAfter?: InputMaybe<Scalars['DateTime']>
  isUser?: InputMaybe<Scalars['Boolean']>
  offeredAfter?: InputMaybe<Scalars['DateTime']>
  onboardingAfter?: InputMaybe<Scalars['DateTime']>
}

export type QueryMatchingByIdArgs = {
  matchingId: Scalars['GUID']
}

export type QueryMatchingsListArgs = {
  after?: InputMaybe<Scalars['String']>
  executiveId?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  isArchived?: InputMaybe<Scalars['Boolean']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  isHiddenFromCatalog?: InputMaybe<Scalars['Boolean']>
  isPrincipalLead?: InputMaybe<Scalars['Boolean']>
  isTransition?: InputMaybe<Scalars['Boolean']>
}

export type QueryMessageTemplateByIdArgs = {
  id: Scalars['GUID']
}

export type QueryMessageTemplatesListArgs = {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  ids?: InputMaybe<Scalars['GUID'][]>
  kinds?: InputMaybe<MessageTemplateKind[]>
  q?: InputMaybe<Scalars['String']>
}

export type QueryOnboardingCallAvailabilityArgs = {
  assistantId: Scalars['ID']
  specialistId?: InputMaybe<Scalars['ID']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type QueryPlaceByGoogleMapsPlaceIdArgs = {
  googleMapsPlaceId: Scalars['ID']
}

export type QuerySettingByIdArgs = {
  id: Scalars['ID']
}

export type QueryTeamByIdArgs = {
  date?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
}

export type QueryTeamTimelineItemsListArgs = {
  excludes?: InputMaybe<TimelineEvent[]>
  teamId: Scalars['ID']
}

export type QueryTeamsListArgs = {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  isArchived?: InputMaybe<Scalars['Boolean']>
  q?: InputMaybe<Scalars['String']>
}

export type QueryTimeTrackingEntriesListArgs = {
  after?: InputMaybe<Scalars['String']>
  assistantIds?: InputMaybe<Scalars['String'][]>
  endedAfter?: InputMaybe<Scalars['DateTime']>
  endedBefore?: InputMaybe<Scalars['DateTime']>
  executiveIds?: InputMaybe<Scalars['String'][]>
  first?: InputMaybe<Scalars['PositiveInt']>
  isToggl?: InputMaybe<Scalars['Boolean']>
  tagIds?: InputMaybe<Scalars['String'][]>
  workspaceIds?: InputMaybe<InputMaybe<Scalars['String']>[]>
}

export type QueryTimeTrackingEntryTemplatesListArgs = {
  isWorkspaced?: InputMaybe<Scalars['Boolean']>
}

export type QueryTimelineItemsListArgs = {
  excludes?: InputMaybe<TimelineEvent[]>
  userId: Scalars['ID']
}

export type QueryUserByIdArgs = {
  id: Scalars['ID']
}

export type QueryUsersListArgs = {
  adminRoles?: InputMaybe<Scalars['String'][]>
  after?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<UserCategory[]>
  countryCodes?: InputMaybe<Scalars['String'][]>
  first?: InputMaybe<Scalars['PositiveInt']>
  hasSuccessManager?: InputMaybe<Scalars['Boolean']>
  industries?: InputMaybe<Scalars['String'][]>
  invoicedToUserId?: InputMaybe<Scalars['ID']>
  isArchived?: InputMaybe<Scalars['Boolean']>
  isPendingOrientation?: InputMaybe<Scalars['Boolean']>
  isSaas?: InputMaybe<Scalars['Boolean']>
  q?: InputMaybe<Scalars['String']>
  successManagerId?: InputMaybe<Scalars['ID']>
  tools?: InputMaybe<Scalars['String'][]>
  userIds?: InputMaybe<Scalars['ID'][]>
}

export type QueryWhoByIdArgs = {
  id: Scalars['GUID']
}

export type QueryWhosListArgs = {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  q?: InputMaybe<Scalars['String']>
}

export type QueryWorkspaceByIdArgs = {
  id: Scalars['ID']
}

export type QueryWorkspacesByUserIdArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<WorkspaceUserRole>
  userId: Scalars['ID']
}

export type QueryWorkspacesListArgs = {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  isOverridingFeature?: InputMaybe<Scalars['String']>
}

export type ReadTopicsInput = {
  lastUpdatedBefore?: InputMaybe<Scalars['DateTime']>
  userId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type ReadTopicsOutput = {
  __typename?: 'ReadTopicsOutput'
  isSuccess: Scalars['Boolean']
}

export type RemoveAdhocTodoAssistantInput = {
  adhocTodoId: Scalars['GUID']
  mutationId: Scalars['GUID']
}

export type RemoveAdhocTodoAssistantOutput = {
  __typename?: 'RemoveAdhocTodoAssistantOutput'
  adhocTodo: AdhocTodo
}

export type RemoveOnboardingSpecialistInput = {
  workspaceId: Scalars['ID']
}

export type RemoveOnboardingSpecialistOutput = {
  __typename?: 'RemoveOnboardingSpecialistOutput'
  workspace: Workspace
}

export type RemoveWorkspaceUserInput = {
  /** Use for removalReason = EXEC_CHURN. Ignored otherwise. */
  execChurnReasons?: InputMaybe<Scalars['String'][]>
  removalReason?: InputMaybe<WorkspaceUserRemovalReason>
  sendNotOnboardedSlackMessage?: InputMaybe<Scalars['Boolean']>
  userId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type ResetOnboardingInput = {
  workspaceId: Scalars['ID']
}

export type ResetOnboardingOutput = {
  __typename?: 'ResetOnboardingOutput'
  workspace: Workspace
}

export type ResetTestExecLifecycleOutput = {
  __typename?: 'ResetTestExecLifecycleOutput'
  isSuccess: Scalars['Boolean']
}

export type ReviewAutomationJobInput = {
  clearLogs?: InputMaybe<Scalars['Boolean']>
  id: Scalars['GUID']
}

export type ReviewAutomationJobOutput = {
  __typename?: 'ReviewAutomationJobOutput'
  automationJob: AutomationJob
}

export type RunAutopilotJobManuallyInput = {
  jobId: Scalars['GUID']
}

export type RunAutopilotJobManuallyOutput = {
  __typename?: 'RunAutopilotJobManuallyOutput'
  isSuccess: Scalars['Boolean']
}

export type SandboxUser = Assistantable &
  Executivable &
  Matchable &
  Userable & {
    __typename?: 'SandboxUser'
    acceptedPptcAt?: Maybe<Scalars['DateTime']>
    accounts: UserAccount[]
    activePricings: EntityPricing[]
    airtableClient?: Maybe<AirtableClient>
    airtableClientRecordId?: Maybe<Scalars['String']>
    airtableDoubleRecordId?: Maybe<Scalars['String']>
    airtableLeadRecordId?: Maybe<Scalars['String']>
    averageWeeklyWorkedHours: Scalars['NonNegativeFloat']
    biography?: Maybe<Scalars['String']>
    birthday?: Maybe<AnniversaryDate>
    bugReportsList: BugReportsList
    category?: Maybe<UserCategory>
    city?: Maybe<Place>
    createdAt: Scalars['DateTime']
    csatAverage?: Maybe<Scalars['Float']>
    csm?: Maybe<ExecutivableCsm>
    currency?: Maybe<Scalars['CurrencyCode']>
    currentPricing?: Maybe<EntityPricing>
    desiredSkills: Scalars['String'][]
    dsm?: Maybe<HqUser>
    ein?: Maybe<Scalars['String']>
    endDate?: Maybe<Scalars['Date']>
    englishLevel?: Maybe<CefrScale>
    experienceHighlights?: Maybe<Scalars['String']>
    experienceInTools: Scalars['String'][]
    externalDisc?: Maybe<DiscScore>
    facet?: Maybe<UserFacet>
    funFacts?: Maybe<Scalars['String']>
    hasPoolWorkspace: Scalars['Boolean']
    hasSlackPush: Scalars['Boolean']
    hourlyRate?: Maybe<Scalars['PositiveFloat']>
    hourlyRateUpdates?: Maybe<AssistantableHourlyRateUpdate[]>
    hoursLimit?: Maybe<Scalars['PositiveInt']>
    id: Scalars['ID']
    industries: Scalars['String'][]
    interestedInIndustries: Scalars['String'][]
    internalDisc?: Maybe<DiscScore>
    invoicedCycle: InvoicedCycle
    /** @deprecated Use invoicedCycle.invoicedPricing instead */
    invoicedPricing?: Maybe<EntityPricing>
    invoicedUser: Executivable
    isAllowedPersonal?: Maybe<Scalars['Boolean']>
    isArchived: Scalars['Boolean']
    /** ❗️ Only utilize this for Cockpit invoicing */
    isOnMaxioBilling: Scalars['Boolean']
    isOnPause: Scalars['Boolean']
    isOpenToMatch?: Maybe<Scalars['Boolean']>
    isOpenToSupport?: Maybe<Scalars['Boolean']>
    isSaas: Scalars['Boolean']
    justworksFamilyName?: Maybe<Scalars['String']>
    justworksGivenName?: Maybe<Scalars['String']>
    justworksVendorName?: Maybe<Scalars['String']>
    languages: Scalars['LanguageCode'][]
    latestOnboardingAt?: Maybe<Scalars['DateTime']>
    leverId?: Maybe<Scalars['GUID']>
    matchingNotepad?: Maybe<Scalars['String']>
    matchingProfile?: Maybe<MatchingProfile>
    mobileOs?: Maybe<MobileOs>
    onboardingAvailability: OnboardingAvailability
    /** @deprecated use targetTotalWeeklyHours instead */
    openToAdditionalWeeklyHours?: Maybe<Scalars['String']>
    openToUpdatedAt?: Maybe<Scalars['DateTime']>
    orientation?: Maybe<Orientation>
    pendingChurnAutomationJob?: Maybe<AutomationJob>
    /** The performance score is updated daily, return the last n 1st of the month or the score for the start of the month of the date provided. If a date is provided, last is ignored. */
    performanceScore?: Maybe<AssistantPerformanceScoreSeries>
    /**  Current performance score value, is calculated on a cron job and updated in the database  */
    performanceScoreValue?: Maybe<Scalars['Float']>
    personalEmail?: Maybe<Scalars['String']>
    personalityTraits: Scalars['String'][]
    phoneNumber?: Maybe<Scalars['PhoneNumber']>
    pricingSelections: PricingSelection[]
    primaryEmail?: Maybe<Scalars['String']>
    principalUser?: Maybe<Executivable>
    profile: UserProfile
    pronoun: UserPronoun
    reasonToTurnOffOpenToMatch?: Maybe<Scalars['String']>
    resumeUrl: Scalars['URL']
    schedulingUrl?: Maybe<Scalars['URL']>
    skills: Scalars['String'][]
    skillsetRating?: Maybe<Scalars['JSONObject']>
    slackUserId?: Maybe<Scalars['String']>
    slackUsername?: Maybe<Scalars['String']>
    startDate?: Maybe<Scalars['Date']>
    stripeCustomer?: Maybe<StripeCustomer>
    targetAdditionalWeeklyHours?: Maybe<Scalars['String']>
    targetTotalWeeklyHours?: Maybe<Scalars['String']>
    updatedAt: Scalars['DateTime']
    workExperiences: WorkExperience[]
    workTimeZones?: Maybe<Scalars['String'][]>
    workspaces: Workspace[]
    yearsOfExperience?: Maybe<Scalars['NonNegativeFloat']>
  }

export type SandboxUserActivePricingsArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type SandboxUserExperienceHighlightsArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type SandboxUserInvoicedCycleArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type SandboxUserInvoicedPricingArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type SandboxUserPerformanceScoreArgs = {
  date?: InputMaybe<Scalars['Date']>
  last?: InputMaybe<Scalars['PositiveInt']>
}

export type SandboxUserWorkspacesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>
  isSandbox?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<WorkspaceUserRole>
}

export type SaveTopicTemplateFormInput = {
  fields: TopicFormFieldInput[]
  templateId: Scalars['GUID']
}

export type SaveTopicTemplateFormOutput = {
  __typename?: 'SaveTopicTemplateFormOutput'
  template: TopicTemplate
}

export type ScheduleAutopilotJobsInput = {
  automationIds: Scalars['GUID'][]
  params?: InputMaybe<Scalars['JSONObject']>
  triggerId: Scalars['String']
}

export type ScheduleAutopilotJobsOutput = {
  __typename?: 'ScheduleAutopilotJobsOutput'
  scheduledJobs: AutomationJob[]
}

export type ScheduleOnboardingCalendar = {
  __typename?: 'ScheduleOnboardingCalendar'
  id: Scalars['ID']
  isWritable: Scalars['Boolean']
  title: Scalars['String']
}

export type ScheduleOnboardingCallInput = {
  at: Scalars['DateTime']
  specialistId?: InputMaybe<Scalars['ID']>
  validateAvailability: Scalars['Boolean']
  workspaceId: Scalars['ID']
}

export type ScheduleOnboardingCallOutput = {
  __typename?: 'ScheduleOnboardingCallOutput'
  workspace: Workspace
}

export type ScheduleOnboardingConfig = {
  __typename?: 'ScheduleOnboardingConfig'
  availabilityCalendarIds: Scalars['String'][]
  calendars: ScheduleOnboardingCalendar[]
  coveredTimeZones: Scalars['String'][]
  externalEventCalendarId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  specialistEventCalendarId?: Maybe<Scalars['String']>
  workingEndHour?: Maybe<Scalars['PositiveInt']>
  workingStartHour?: Maybe<Scalars['PositiveInt']>
}

export type ScheduleOnboardingConfigInput = {
  availabilityCalendarIds?: InputMaybe<Scalars['String'][]>
  coveredTimeZones?: InputMaybe<Scalars['String'][]>
  externalEventCalendarId?: InputMaybe<Scalars['String']>
  languages?: InputMaybe<Scalars['LanguageCode'][]>
  specialistEventCalendarId?: InputMaybe<Scalars['String']>
  workingEndHour?: InputMaybe<Scalars['PositiveInt']>
  workingStartHour?: InputMaybe<Scalars['PositiveInt']>
}

export type ScheduleTeamChurnInput = {
  /**  The end of their billing cycle for that month. Defaults to end of current billing cycle.  */
  churnAt?: InputMaybe<Scalars['Date']>
  /**  The day the principal communicated their decision, taken into account for billing. Defaults to today.  */
  churnDecisionOn?: InputMaybe<Scalars['Date']>
  endDoubleSupportOn?: InputMaybe<Scalars['Date']>
  interestedInPlacement?: InputMaybe<Scalars['Boolean']>
  notepad?: InputMaybe<Scalars['String']>
  reason: Scalars['String']
  teamId: Scalars['ID']
}

export type ScheduleTeamChurnOutput = {
  __typename?: 'ScheduleTeamChurnOutput'
  isSuccess: Scalars['Boolean']
}

export type SelectEntityPricingInput = {
  activeAt: Scalars['DateTime']
  entityId: Scalars['ID']
  expireAt?: InputMaybe<Scalars['DateTime']>
  notepad?: InputMaybe<Scalars['String']>
  pricingId: Scalars['GUID']
}

export type SelectEntityPricingOutput = {
  __typename?: 'SelectEntityPricingOutput'
  entity: BillableEntity
}

export type SelectPrimaryUserAccountInput = {
  accountKey: Scalars['ID']
  userId: Scalars['ID']
}

export type SendContractorsPaymentsEmailsContractorInput = {
  amount: Scalars['PositiveFloat']
  currency: Scalars['CurrencyCode']
  displayName: Scalars['String']
  email: Scalars['EmailAddress']
  givenName?: InputMaybe<Scalars['String']>
  hours: Scalars['PositiveFloat']
}

export type SendContractorsPaymentsEmailsInput = {
  contractors: SendContractorsPaymentsEmailsContractorInput[]
  creditDate: Scalars['Date']
  fromDate: Scalars['Date']
  greeting?: InputMaybe<Scalars['String']>
  toDate: Scalars['Date']
}

export type SendContractorsPaymentsEmailsOutput = {
  __typename?: 'SendContractorsPaymentsEmailsOutput'
  isSuccess: Scalars['Boolean']
}

export type SendCsatFeedbackRequestInput = {
  userId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type SendCsatFeedbackRequestOutput = {
  __typename?: 'SendCsatFeedbackRequestOutput'
  isSuccess: Scalars['Boolean']
}

export type SendEmailFromTemplateInput = {
  bcc?: InputMaybe<EmailAddressWithNameInput[]>
  cc?: InputMaybe<EmailAddressWithNameInput[]>
  /**  Deprecated in favor of 'vars'  */
  data?: InputMaybe<Scalars['JSONObject']>
  dontValidateVars?: InputMaybe<Scalars['Boolean']>
  from?: InputMaybe<EmailAddressWithNameInput>
  metadata?: InputMaybe<Scalars['JSONObject']>
  replyTo?: InputMaybe<EmailAddressWithNameInput>
  sendAt?: InputMaybe<Scalars['DateTime']>
  tags?: InputMaybe<Scalars['String'][]>
  templateId: Scalars['ID']
  to: EmailAddressWithNameInput[]
  userId?: InputMaybe<Scalars['String']>
  vars?: InputMaybe<Scalars['JSONObject']>
  workspaceId?: InputMaybe<Scalars['String']>
}

export type SendEmailFromTemplateOutput = {
  __typename?: 'SendEmailFromTemplateOutput'
  isSuccess: Scalars['Boolean']
}

export type SendEmailMessageInput = {
  fields?: InputMaybe<Scalars['JSONObject']>
  /**  @deprecated(reason: "Use languageCodes instead")  */
  languageCode?: InputMaybe<Scalars['LanguageCode']>
  languageCodes?: InputMaybe<Scalars['LanguageCode'][]>
  messageTemplateId: Scalars['ID']
  vars?: InputMaybe<Scalars['JSONObject']>
}

export type SendEmailMessageOutput = {
  __typename?: 'SendEmailMessageOutput'
  isSuccess: Scalars['Boolean']
}

export type SendLoginEmailInput = {
  userId: Scalars['ID']
}

export type SendLoginEmailOutput = {
  __typename?: 'SendLoginEmailOutput'
  email: Scalars['EmailAddress']
  isSuccess: Scalars['Boolean']
}

export type SendMatchingProposalInput = {
  assistantId: Scalars['ID']
  matchingId: Scalars['GUID']
}

export type SendMatchingProposalOutput = {
  __typename?: 'SendMatchingProposalOutput'
  matching: Matching
}

export type SendOnboardingEmailInput = {
  emailIndex: OnboardingEmailIndex
  workspaceId: Scalars['ID']
}

export type SendOnboardingEmailOutput = {
  __typename?: 'SendOnboardingEmailOutput'
  workspace: Workspace
}

export type SendSlackMessageInput = {
  fields?: InputMaybe<Scalars['JSONObject']>
  /**  @deprecated(reason: "Use languageCodes instead")  */
  languageCode?: InputMaybe<Scalars['LanguageCode']>
  languageCodes?: InputMaybe<Scalars['LanguageCode'][]>
  messageTemplateId: Scalars['ID']
  vars?: InputMaybe<Scalars['JSONObject']>
}

export type SendSlackMessageOutput = {
  __typename?: 'SendSlackMessageOutput'
  isSuccess: Scalars['Boolean']
}

export type SendSmsInput = {
  text: Scalars['String']
  to: Scalars['String']
}

export type SendSmsMessageInput = {
  fields?: InputMaybe<Scalars['JSONObject']>
  /**  @deprecated(reason: "Use languageCodes instead")  */
  languageCode?: InputMaybe<Scalars['LanguageCode']>
  languageCodes?: InputMaybe<Scalars['LanguageCode'][]>
  messageTemplateId: Scalars['ID']
  vars?: InputMaybe<Scalars['JSONObject']>
}

export type SendSmsMessageOutput = {
  __typename?: 'SendSmsMessageOutput'
  isSuccess: Scalars['Boolean']
}

export type SendSmsOutput = {
  __typename?: 'SendSmsOutput'
  isSuccess: Scalars['Boolean']
}

export type SendStripeInvoiceInput = {
  invoiceId: Scalars['String']
}

export type SendStripeInvoiceOutput = {
  __typename?: 'SendStripeInvoiceOutput'
  customer: StripeCustomer
  invoice: StripeInvoice
}

export type SentOnboardingEmailInput = {
  emailIndex?: InputMaybe<OnboardingEmailIndex>
  workspaceId: Scalars['ID']
}

export type SentOnboardingEmailOutput = {
  __typename?: 'SentOnboardingEmailOutput'
  workspace: Workspace
}

export type SentPostOnboardingEmailInput = {
  workspaceId: Scalars['ID']
}

export type SentPostOnboardingEmailOutput = {
  __typename?: 'SentPostOnboardingEmailOutput'
  workspace: Workspace
  workspaceId: Scalars['GUID']
}

export type SentTransitionMessageInput = {
  matchingId: Scalars['GUID']
  messageTemplateId: Scalars['String']
}

export type SentTransitionMessageOutput = {
  __typename?: 'SentTransitionMessageOutput'
  matching: Matching
}

export type Setting = {
  __typename?: 'Setting'
  data: Scalars['JSONObject']
  id: Scalars['ID']
}

export type SlackMessageTemplate = MessageTemplate & {
  __typename?: 'SlackMessageTemplate'
  description: Scalars['String']
  id: Scalars['GUID']
  languages: MessageTemplateLanguage[]
  name: Scalars['String']
}

export type SmsMessageTemplate = MessageTemplate & {
  __typename?: 'SmsMessageTemplate'
  description: Scalars['String']
  id: Scalars['GUID']
  languages: MessageTemplateLanguage[]
  name: Scalars['String']
}

export type SpectateWorkspaceInput = {
  workspaceId: Scalars['ID']
}

export type SpectateWorkspaceOutput = {
  __typename?: 'SpectateWorkspaceOutput'
  user: Adminable
  workspace: Workspace
}

export type StatusTimelineItem = TimelineItem & {
  __typename?: 'StatusTimelineItem'
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  emoji?: Maybe<Scalars['String']>
  event: TimelineEvent
  title: Scalars['String']
}

export type StripeCoupon = {
  __typename?: 'StripeCoupon'
  amountOff?: Maybe<Scalars['Int']>
  currency?: Maybe<Scalars['CurrencyCode']>
  id: Scalars['ID']
  percentOff?: Maybe<Scalars['Int']>
}

export type StripeCustomer = {
  __typename?: 'StripeCustomer'
  email?: Maybe<Scalars['EmailAddress']>
  id: Scalars['ID']
  invoices: StripeInvoice[]
  paymentStatus: StripeCustomerPaymentStatus
}

export type StripeCustomerInvoicesArgs = {
  createdAfter?: InputMaybe<Scalars['DateTime']>
  createdBefore?: InputMaybe<Scalars['DateTime']>
  excludePlacements?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['NonNegativeInt']>
  hasStatus?: InputMaybe<StripeInvoiceStatus[]>
}

export enum StripeCustomerPaymentStatus {
  CARD = 'CARD',
  MANUAL = 'MANUAL',
  MISSING = 'MISSING',
  REJECTED = 'REJECTED',
  WIRE = 'WIRE',
}

export type StripeInvoice = {
  __typename?: 'StripeInvoice'
  amount: Scalars['Float']
  createdAt: Scalars['DateTime']
  currency: Scalars['CurrencyCode']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lineItems: StripeInvoiceLineItem[]
  periodEndAt?: Maybe<Scalars['DateTime']>
  periodStartAt?: Maybe<Scalars['DateTime']>
  status: StripeInvoiceStatus
}

export type StripeInvoiceLineItem = {
  __typename?: 'StripeInvoiceLineItem'
  amount: Scalars['Float']
  currency: Scalars['CurrencyCode']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
}

export type StripeInvoiceLineItemInput = {
  amount: Scalars['Float']
  currency: Scalars['CurrencyCode']
  description: Scalars['String']
  hours?: InputMaybe<Scalars['Float']>
  periodEndAt?: InputMaybe<Scalars['DateTime']>
  periodStartAt?: InputMaybe<Scalars['DateTime']>
}

export enum StripeInvoiceStatus {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
}

export type StripePrice = {
  __typename?: 'StripePrice'
  currency: Scalars['CurrencyCode']
  id: Scalars['ID']
  metadata?: Maybe<Scalars['JSON']>
  product?: Maybe<StripeProduct>
  unitAmount?: Maybe<Scalars['Int']>
}

export type StripeProduct = {
  __typename?: 'StripeProduct'
  id: Scalars['ID']
  metadata?: Maybe<Scalars['JSON']>
}

export type Subscription = {
  __typename?: 'Subscription'
  /** @deprecated INTERNAL USE ONLY */
  _mock?: Maybe<Scalars['Boolean']>
  featureUpserted: Feature
  timeTrackingEntryUpserted: TimeTrackingEntry
}

export type SubscriptionTimeTrackingEntryUpsertedArgs = {
  assistantIds?: InputMaybe<Scalars['String'][]>
  endedAfter?: InputMaybe<Scalars['DateTime']>
  endedBefore?: InputMaybe<Scalars['DateTime']>
  executiveIds?: InputMaybe<Scalars['String'][]>
  isToggl?: InputMaybe<Scalars['Boolean']>
  tagIds?: InputMaybe<Scalars['String'][]>
  workspaceIds?: InputMaybe<InputMaybe<Scalars['String']>[]>
}

export type SubscriptionBillingCycle = {
  __typename?: 'SubscriptionBillingCycle'
  endDate: Scalars['DateTime']
  hours: Scalars['NonNegativeInt']
  startDate: Scalars['DateTime']
}

export enum SubscriptionState {
  active = 'active',
  assessing = 'assessing',
  awaiting_signup = 'awaiting_signup',
  canceled = 'canceled',
  expired = 'expired',
  failed_to_create = 'failed_to_create',
  on_hold = 'on_hold',
  past_due = 'past_due',
  paused = 'paused',
  pending = 'pending',
  soft_failure = 'soft_failure',
  suspended = 'suspended',
  trial_ended = 'trial_ended',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export type SwitchTransitionAssistantsInput = {
  matchingId: Scalars['ID']
}

export type SwitchTransitionAssistantsOutput = {
  __typename?: 'SwitchTransitionAssistantsOutput'
  matching: Matching
}

export type SystemFeatureFlag = {
  __typename?: 'SystemFeatureFlag'
  description: Scalars['String']
  id: Scalars['ID']
  isEnabled: Scalars['Boolean']
}

export type SystemFeatureFlagsList = {
  __typename?: 'SystemFeatureFlagsList'
  items: SystemFeatureFlag[]
}

export type TaskCopilotPromptSettings = CopilotPromptSettings &
  CopilotPromptSettingsOverridable &
  CopilotSubpromptable & {
    __typename?: 'TaskCopilotPromptSettings'
    firstCalledFunctionInfo: FirstCalledFunctionInfo
    functions?: Maybe<CopilotFunction[]>
    id: TimeTrackingEntryTemplateId
    label: Scalars['String']
    overridePrompts: CopilotPromptSettingsOverride[]
    systemPrompt?: Maybe<Scalars['String']>
  }

export type TaskCopilotPromptSettingsInput = {
  firstCalledFunctionInfo?: InputMaybe<FirstCalledFunctionInfoInput>
  functions?: InputMaybe<UpdateCopilotSettingsFunctionInput[]>
  id?: InputMaybe<TimeTrackingEntryTemplateId>
  overridePrompts?: InputMaybe<CopilotPromptSettingsOverrideInput[]>
  systemPrompt?: InputMaybe<Scalars['String']>
}

export type Team = {
  __typename?: 'Team'
  activePricings: EntityPricing[]
  billingPauseStatus?: Maybe<BillingPauseStatus>
  createdAt: Scalars['DateTime']
  currentPricing?: Maybe<EntityPricing>
  iconUrl?: Maybe<Scalars['URL']>
  id: Scalars['ID']
  isArchived: Scalars['Boolean']
  isSandbox: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  pendingChurnAutomationJob?: Maybe<AutomationJob>
  pricingSelections: PricingSelection[]
  principalUser: Executivable
  status: TeamStatus
  stripeCustomer?: Maybe<StripeCustomer>
  subscription?: Maybe<TeamSubscription>
  successManager?: Maybe<HqUser>
  updatedAt: Scalars['DateTime']
  workspaces: Workspace[]
}

export type TeamActivePricingsArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type TeamBillingPauseStatusArgs = {
  date?: InputMaybe<Scalars['DateTime']>
}

export type TeamIconUrlArgs = {
  size?: InputMaybe<Scalars['PositiveInt']>
}

export enum TeamStatus {
  ACTIVE = 'ACTIVE',
  CHURNED = 'CHURNED',
  PAUSED = 'PAUSED',
  PENDING_CHURN = 'PENDING_CHURN',
  PRE_ONBOARDED = 'PRE_ONBOARDED',
}

export type TeamSubscription = {
  __typename?: 'TeamSubscription'
  currentBillingCycle?: Maybe<SubscriptionBillingCycle>
  customerDisplayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  maxioUrl?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  productPrice?: Maybe<Scalars['PositiveFloat']>
  state?: Maybe<SubscriptionState>
  type: TeamSubscriptionType
}

export enum TeamSubscriptionType {
  COCKPIT = 'COCKPIT',
  MAXIO = 'MAXIO',
}

export type TeamsList = {
  __typename?: 'TeamsList'
  after?: Maybe<Scalars['String']>
  hasAfter: Scalars['Boolean']
  items: Team[]
}

export type TestExec = {
  __typename?: 'TestExec'
  lead?: Maybe<AirtableLead>
  matching?: Maybe<Matching>
  user?: Maybe<ExecutiveUser>
}

export type TextMessageTemplate = MessageTemplate & {
  __typename?: 'TextMessageTemplate'
  description: Scalars['String']
  id: Scalars['GUID']
  languages: MessageTemplateLanguage[]
  name: Scalars['String']
}

export enum TimeTrackingCategoryId {
  CALENDAR = 'CALENDAR',
  COMMUNICATION = 'COMMUNICATION',
  CONTACT_MANAGEMENT = 'CONTACT_MANAGEMENT',
  DOCUMENT = 'DOCUMENT',
  EMAIL = 'EMAIL',
  EVENT = 'EVENT',
  FINANCE = 'FINANCE',
  HR = 'HR',
  MANAGEMENT = 'MANAGEMENT',
  MEETING = 'MEETING',
  PERSONAL = 'PERSONAL',
  PHONE = 'PHONE',
  RESEARCH = 'RESEARCH',
  SALES = 'SALES',
  SHIPPING = 'SHIPPING',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  TECH = 'TECH',
  TRAVEL = 'TRAVEL',
}

export type TimeTrackingEntriesList = {
  __typename?: 'TimeTrackingEntriesList'
  after?: Maybe<Scalars['String']>
  id: Scalars['GUID']
  items: TimeTrackingEntry[]
  lockedAt?: Maybe<Scalars['DateTime']>
  /** @deprecated Never utilized and no longer relevant */
  paidAt?: Maybe<Scalars['DateTime']>
  totalDuration?: Maybe<Scalars['Duration']>
}

export type TimeTrackingEntry = {
  __typename?: 'TimeTrackingEntry'
  deletedAt?: Maybe<Scalars['DateTime']>
  duration?: Maybe<Scalars['Duration']>
  endedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['GUID']
  isToggl: Scalars['Boolean']
  lockedAt?: Maybe<Scalars['DateTime']>
  /** @deprecated Never utilized and no longer relevant */
  paidAt?: Maybe<Scalars['DateTime']>
  startedAt: Scalars['DateTime']
  tags: TimeTrackingTag[]
  template?: Maybe<TimeTrackingEntryTemplate>
  /**  Time tracking / Read time tracking entry title  */
  title?: Maybe<Scalars['String']>
  topicId?: Maybe<Scalars['GUID']>
  user: Assistantable
  workspace?: Maybe<Workspace>
}

export type TimeTrackingEntryTemplate = {
  __typename?: 'TimeTrackingEntryTemplate'
  id: TimeTrackingEntryTemplateId
  title: Scalars['String']
}

export enum TimeTrackingEntryTemplateId {
  ASSISTING_ANOTHER_DOUBLE = 'ASSISTING_ANOTHER_DOUBLE',
  COVERAGE_PREPARATION = 'COVERAGE_PREPARATION',
  DOUBLE_AI_AMBASSADOR = 'DOUBLE_AI_AMBASSADOR',
  DOUBLE_BUDDY_COMMUNICATION = 'DOUBLE_BUDDY_COMMUNICATION',
  DOUBLE_CONNECTS = 'DOUBLE_CONNECTS',
  DOUBLE_GPT = 'DOUBLE_GPT',
  DOUBLE_MEETING = 'DOUBLE_MEETING',
  DOUBLE_ORIENTATION = 'DOUBLE_ORIENTATION',
  DOUBLE_SLACK_COMMUNICATION = 'DOUBLE_SLACK_COMMUNICATION',
  DOUBLE_SURVEY = 'DOUBLE_SURVEY',
  EXECUTIVE_COMMUNICATION = 'EXECUTIVE_COMMUNICATION',
  EXECUTIVE_DELEGATION_SUGGESTIONS = 'EXECUTIVE_DELEGATION_SUGGESTIONS',
  EXECUTIVE_ONBOARDING = 'EXECUTIVE_ONBOARDING',
  EXECUTIVE_PROFILE_UPDATE = 'EXECUTIVE_PROFILE_UPDATE',
  HQ_TRAINING = 'HQ_TRAINING',
  MANAGE_CALENDAR = 'MANAGE_CALENDAR',
  MANAGE_CONTACTS = 'MANAGE_CONTACTS',
  MANAGE_EMAIL_INBOX = 'MANAGE_EMAIL_INBOX',
  MANAGE_FINANCES = 'MANAGE_FINANCES',
  MANAGE_TRAVEL = 'MANAGE_TRAVEL',
  MATCH_INTRO_CALL = 'MATCH_INTRO_CALL',
  MEETING_ASSISTANCE = 'MEETING_ASSISTANCE',
  MEETING_WITH_TEAM_LEAD = 'MEETING_WITH_TEAM_LEAD',
  ORGANIZE_DOCUMENTS = 'ORGANIZE_DOCUMENTS',
  PREPARATION_FOR_EXEC_ONBOARDING = 'PREPARATION_FOR_EXEC_ONBOARDING',
  PRODUCT_RESEARCH = 'PRODUCT_RESEARCH',
  SHIPPING = 'SHIPPING',
  SOCIAL_MEDIA_MANAGEMENT = 'SOCIAL_MEDIA_MANAGEMENT',
  TRANSITION_INTRO_CALL = 'TRANSITION_INTRO_CALL',
  TRANSITION_ONBOARDING = 'TRANSITION_ONBOARDING',
  TRANSITION_PREPARATION = 'TRANSITION_PREPARATION',
}

export type TimeTrackingEntryTemplatesList = {
  __typename?: 'TimeTrackingEntryTemplatesList'
  items: TimeTrackingEntryTemplate[]
}

export type TimeTrackingTag = {
  __typename?: 'TimeTrackingTag'
  category: TimeTrackingTagCategory
  id: Scalars['ID']
  label: Scalars['String']
}

export type TimeTrackingTagCategory = {
  __typename?: 'TimeTrackingTagCategory'
  emoji: Scalars['String']
  id: TimeTrackingCategoryId
  label: Scalars['String']
  tags: TimeTrackingTag[]
}

export enum TimelineEvent {
  ACCOUNT_ARCHIVED = 'ACCOUNT_ARCHIVED',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  ACCOUNT_STARTED = 'ACCOUNT_STARTED',
  FEEDBACK_CREATED = 'FEEDBACK_CREATED',
  FEEDBACK_RECEIVED = 'FEEDBACK_RECEIVED',
  MATCHING_ARCHIVED = 'MATCHING_ARCHIVED',
  MATCHING_CONFIRMED = 'MATCHING_CONFIRMED',
  MATCHING_CREATED = 'MATCHING_CREATED',
  MATCHING_PROPOSAL_REPLIED = 'MATCHING_PROPOSAL_REPLIED',
  ONBOARDING_CALL_DATE = 'ONBOARDING_CALL_DATE',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  ORIENTATION_COMPLETED = 'ORIENTATION_COMPLETED',
  STATUS_UPDATED = 'STATUS_UPDATED',
  TRANSITION_CREATED = 'TRANSITION_CREATED',
  WORKSPACE_ARCHIVED = 'WORKSPACE_ARCHIVED',
  WORKSPACE_CREATED = 'WORKSPACE_CREATED',
  WORKSPACE_USER_ADDED = 'WORKSPACE_USER_ADDED',
  WORKSPACE_USER_REMOVED = 'WORKSPACE_USER_REMOVED',
}

export type TimelineItem = {
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  emoji?: Maybe<Scalars['String']>
  event: TimelineEvent
  title: Scalars['String']
}

export enum TimelineItemCategory {
  FEEDBACK = 'FEEDBACK',
  MATCHING = 'MATCHING',
  NOTE = 'NOTE',
  STATUS = 'STATUS',
  USER_SETTING = 'USER_SETTING',
  WORKSPACE = 'WORKSPACE',
  WORKSPACE_USER = 'WORKSPACE_USER',
}

export type TimelineItemsList = {
  __typename?: 'TimelineItemsList'
  items: TimelineItem[]
}

export enum TodoStatus {
  /**  Done  */
  DONE = 'DONE',
  /**  Exec's drafts  */
  DRAFT = 'DRAFT',
  /**  In progress  */
  IN_PROGRESS = 'IN_PROGRESS',
  /**  Need exec's attention  */
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  /**  Waiting for 3rd party  */
  ON_HOLD = 'ON_HOLD',
  /**  To do  */
  READY = 'READY',
  /**  Later  */
  SNOOZED = 'SNOOZED',
}

export type TopicExtendedPropValue =
  | TopicExtendedPropValueAudio
  | TopicExtendedPropValueNumber
  | TopicExtendedPropValueText

export type TopicExtendedPropValueAudio = {
  __typename?: 'TopicExtendedPropValueAudio'
  audio: AudioFile
}

export type TopicExtendedPropValueInput = {
  audioId?: InputMaybe<Scalars['GUID']>
  number?: InputMaybe<Scalars['Float']>
  text?: InputMaybe<Scalars['String']>
}

export type TopicExtendedPropValueNumber = {
  __typename?: 'TopicExtendedPropValueNumber'
  number: Scalars['Float']
}

export type TopicExtendedPropValueText = {
  __typename?: 'TopicExtendedPropValueText'
  text: Scalars['String']
}

export type TopicForm = {
  __typename?: 'TopicForm'
  fields: TopicFormField[]
  id: Scalars['GUID']
  ref?: Maybe<Scalars['String']>
}

export type TopicFormCondition =
  | TopicFormFieldValueCondition
  | TopicFormOperatorCondition

export type TopicFormConditionInput = {
  conditions?: InputMaybe<TopicFormConditionInput[]>
  fieldId?: InputMaybe<Scalars['GUID']>
  operator?: InputMaybe<TopicFormOperator>
  value?: InputMaybe<TopicExtendedPropValueInput>
}

export type TopicFormField = {
  airtableClientField?: Maybe<Scalars['String']>
  enabledIf?: Maybe<TopicFormCondition>
  id: Scalars['GUID']
  imageUrl?: Maybe<Scalars['URL']>
  isRequired?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  maxValues?: Maybe<Scalars['PositiveInt']>
  message?: Maybe<Scalars['String']>
  question: Scalars['String']
  ref?: Maybe<Scalars['String']>
}

export type TopicFormFieldLabelArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type TopicFormFieldMessageArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type TopicFormFieldQuestionArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type TopicFormFieldInput = {
  airtableClientField?: InputMaybe<Scalars['String']>
  enabledIf?: InputMaybe<TopicFormConditionInput>
  freeFormPlaceholder?: InputMaybe<Scalars['String']>
  id: Scalars['GUID']
  imageUrl?: InputMaybe<Scalars['URL']>
  isAllowingAudio?: InputMaybe<Scalars['Boolean']>
  isAllowingFreeForm?: InputMaybe<Scalars['Boolean']>
  isRequired?: InputMaybe<Scalars['Boolean']>
  maxValues?: InputMaybe<Scalars['PositiveInt']>
  message?: InputMaybe<Scalars['String']>
  options?: InputMaybe<TopicFormFieldOptionInput[]>
  question: Scalars['String']
  typename: TopicFormFieldTypename
}

export type TopicFormFieldOption = TopicFormFieldOptionText

export type TopicFormFieldOptionInput = {
  tags?: InputMaybe<TopicFormFieldOptionTag[]>
  text: Scalars['String']
}

export enum TopicFormFieldOptionTag {
  /**  Contacts HQ for help  */
  CALL_FOR_HELP = 'CALL_FOR_HELP',
  /**  Forbids mark as done  */
  DISABLE_MARK_AS_DONE = 'DISABLE_MARK_AS_DONE',
  /**  Mark as recommended  */
  IS_RECOMMENDED = 'IS_RECOMMENDED',
  /**  Mark as done  */
  MARK_AS_DONE = 'MARK_AS_DONE',
}

export type TopicFormFieldOptionText = {
  __typename?: 'TopicFormFieldOptionText'
  /** @deprecated Use tags instead */
  isRecommended: Scalars['Boolean']
  label?: Maybe<Scalars['String']>
  tags: TopicFormFieldOptionTag[]
  text: Scalars['String']
}

export type TopicFormFieldText = TopicFormField & {
  __typename?: 'TopicFormFieldText'
  airtableClientField?: Maybe<Scalars['String']>
  enabledIf?: Maybe<TopicFormCondition>
  freeFormPlaceholder?: Maybe<Scalars['String']>
  id: Scalars['GUID']
  imageUrl?: Maybe<Scalars['URL']>
  isAllowingAudio: Scalars['Boolean']
  isAllowingFreeForm: Scalars['Boolean']
  isRequired?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  maxValues?: Maybe<Scalars['PositiveInt']>
  message?: Maybe<Scalars['String']>
  options: TopicFormFieldOptionText[]
  question: Scalars['String']
  ref?: Maybe<Scalars['String']>
}

export type TopicFormFieldTextLabelArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type TopicFormFieldTextMessageArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export type TopicFormFieldTextQuestionArgs = {
  html?: InputMaybe<Scalars['Boolean']>
}

export enum TopicFormFieldTypename {
  TopicFormFieldText = 'TopicFormFieldText',
}

export type TopicFormFieldValueCondition = {
  __typename?: 'TopicFormFieldValueCondition'
  fieldId: Scalars['GUID']
  value: TopicExtendedPropValue
}

export enum TopicFormOperator {
  AND = 'AND',
  OR = 'OR',
}

export type TopicFormOperatorCondition = {
  __typename?: 'TopicFormOperatorCondition'
  conditions: TopicFormCondition[]
  operator: TopicFormOperator
}

export type TopicTemplate = {
  __typename?: 'TopicTemplate'
  assigneeRole?: Maybe<WorkspaceUserRole>
  category: DiscoverCategory
  description: Scalars['String']
  form?: Maybe<TopicForm>
  id: Scalars['GUID']
  imageUrl?: Maybe<Scalars['URL']>
  instructions: Scalars['String']
  introduction?: Maybe<Scalars['String']>
  introductionImageUrl?: Maybe<Scalars['URL']>
  introductionTitle?: Maybe<Scalars['String']>
  /**  Templates can be draft, not visible to  users  */
  isDraft: Scalars['Boolean']
  isNew: Scalars['Boolean']
  keywords: Scalars['String'][]
  labels: Scalars['String'][]
  notepad?: Maybe<Scalars['String']>
  privateTo?: Maybe<WorkspaceUserRole>
  sectionKey: DiscoverSectionKey
  title: Scalars['String']
  type: TopicTemplateType
}

export enum TopicTemplateType {
  TODO = 'TODO',
}

export type TopicTemplatesList = {
  __typename?: 'TopicTemplatesList'
  items: TopicTemplate[]
}

export enum TransitionHandoverStatus {
  /** ✅ Handover call completed */
  COMPLETED = 'COMPLETED',
  /** ❌ Handover call not necessary */
  NOT_NECESSARY = 'NOT_NECESSARY',
  /** 📅 Handover call scheduled */
  SCHEDULED = 'SCHEDULED',
  /** 💬 Handover to be scheduled */
  TO_BE_SCHEDULED = 'TO_BE_SCHEDULED',
}

export enum TransitionInformingCategory {
  /** Accepted transition */
  ACCEPTED = 'ACCEPTED',
  /** Churn risk */
  CHURN_RISK = 'CHURN_RISK',
  /** Determining needs */
  DETERMINING_NEEDS = 'DETERMINING_NEEDS',
  /** Non responsive */
  NON_RESPONSIVE = 'NON_RESPONSIVE',
}

export enum TransitionInformingStatus {
  /** ✅ Double informed */
  ASSISTANT_INFORMED = 'ASSISTANT_INFORMED',
  /** 💬 Executive to be informed by Double */
  ASSISTANT_TO_INFORM_EXECUTIVE = 'ASSISTANT_TO_INFORM_EXECUTIVE',
  /** ✅ Exec informed */
  EXECUTIVE_INFORMED = 'EXECUTIVE_INFORMED',
  /** 💬 Double to be informed by Exec */
  EXECUTIVE_TO_INFORM_ASSISTANT = 'EXECUTIVE_TO_INFORM_ASSISTANT',
  /** Hold */
  HOLD = 'HOLD',
  /** 🛑 Wait to inform Double */
  HOLD_TO_INFORM_ASSISTANT = 'HOLD_TO_INFORM_ASSISTANT',
  /** 🛑 Wait to inform Executive */
  HOLD_TO_INFORM_EXECUTIVE = 'HOLD_TO_INFORM_EXECUTIVE',
  /** 💌 Double to be informed by TL */
  MANAGER_TO_INFORM_ASSISTANT = 'MANAGER_TO_INFORM_ASSISTANT',
  /** 💌 Executive to be informed by CS */
  SPECIALIST_TO_INFORM_EXECUTIVE = 'SPECIALIST_TO_INFORM_EXECUTIVE',
}

export enum TransitionMatchingStatus {
  /** On Hold */
  HOLD = 'HOLD',
  /** Match confirmed */
  MATCH_CONFIRMED = 'MATCH_CONFIRMED',
  /** Match proposed */
  MATCH_PROPOSED = 'MATCH_PROPOSED',
  /** Ready to match */
  READY_TO_MATCH = 'READY_TO_MATCH',
}

export type UnarchiveTeamInput = {
  teamId: Scalars['ID']
}

export type UnarchiveTransitionInput = {
  matchingId: Scalars['GUID']
}

export type UnarchiveTransitionOutput = {
  __typename?: 'UnarchiveTransitionOutput'
  matching: Matching
}

export type UnarchiveUserInput = {
  userId: Scalars['ID']
}

export type UnarchiveUserOutput = {
  __typename?: 'UnarchiveUserOutput'
  user: Userable
}

export type UnpauseTeamInput = {
  endAt: Scalars['DateTime']
  teamId: Scalars['ID']
}

export type UpdateAdhocTodoPoolVisibilityInput = {
  adhocTodoId: Scalars['GUID']
  isHidden: Scalars['Boolean']
  mutationId: Scalars['GUID']
}

export type UpdateAdhocTodoPoolVisibilityOutput = {
  __typename?: 'UpdateAdhocTodoPoolVisibilityOutput'
  adhocTodo: AdhocTodo
}

export type UpdateAdhocTodoSettingsInput = {
  isOpenForSubmissions?: InputMaybe<Scalars['Boolean']>
  systemPrompt?: InputMaybe<Scalars['String']>
  taskAcceptabilityPrompt?: InputMaybe<Scalars['String']>
  titlePrompt?: InputMaybe<Scalars['String']>
}

export type UpdateAdhocTodoSettingsOutput = {
  __typename?: 'UpdateAdhocTodoSettingsOutput'
  adhocTodoSettings: AdhocTodoSettings
}

export type UpdateAdminableInput = {
  scheduleOnboardingConfig?: InputMaybe<ScheduleOnboardingConfigInput>
  thirtyDayCheckInCallUrl?: InputMaybe<Scalars['URL']>
}

export type UpdateAdminableOutput = {
  __typename?: 'UpdateAdminableOutput'
  user: Adminable
}

export type UpdateAdminableRolesInput = {
  adminRoleIds: Scalars['String'][]
  userId: Scalars['ID']
}

export type UpdateAdminableRolesOutput = {
  __typename?: 'UpdateAdminableRolesOutput'
  user: Adminable
}

export type UpdateAssistantCopilotSettingsInput = {
  categoryPrompts?: InputMaybe<CategoryCopilotPromptSettingsInput[]>
  everydayTasksCopilotPromptSettings: MainCopilotPromptSettingsInput
  execTasksCopilotPromptSettings: MainCopilotPromptSettingsInput
  id?: InputMaybe<Scalars['ID']>
  taskPrompts?: InputMaybe<TaskCopilotPromptSettingsInput[]>
}

export type UpdateAssistantCopilotSettingsOutput = {
  __typename?: 'UpdateAssistantCopilotSettingsOutput'
  assistantCopilot: AssistantCopilot
}

export type UpdateAssistantInput = {
  biography?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['CurrencyCode']>
  ein?: InputMaybe<Scalars['String']>
  funFacts?: InputMaybe<Scalars['String']>
  hasSlackPush?: InputMaybe<Scalars['Boolean']>
  /**  Users / Update assistant's hourly rate  */
  hourlyRate?: InputMaybe<Scalars['PositiveFloat']>
  justworksFamilyName?: InputMaybe<Scalars['String']>
  justworksGivenName?: InputMaybe<Scalars['String']>
  justworksVendorName?: InputMaybe<Scalars['String']>
  leverId?: InputMaybe<Scalars['GUID']>
  mobileOs?: InputMaybe<MobileOs>
  personalEmail?: InputMaybe<Scalars['String']>
  primaryEmail?: InputMaybe<Scalars['String']>
  schedulingUrl?: InputMaybe<Scalars['URL']>
  skills?: InputMaybe<Scalars['String'][]>
  slackUserId?: InputMaybe<Scalars['String']>
  slackUsername?: InputMaybe<Scalars['String']>
  yearsOfExperience?: InputMaybe<Scalars['NonNegativeFloat']>
}

export type UpdateCopilotSettingsFunctionInput = {
  description: Scalars['String']
  name: Scalars['String']
  parameters?: InputMaybe<Scalars['JSONObject']>
}

export type UpdateCopilotSettingsInput = {
  functions?: InputMaybe<InputMaybe<UpdateCopilotSettingsFunctionInput>[]>
  functionsEnabled?: InputMaybe<Scalars['Boolean']>
  functionsPrompt?: InputMaybe<Scalars['String']>
  messageCap?: InputMaybe<Scalars['PositiveInt']>
  messagesPrompt?: InputMaybe<Scalars['String']>
  /**  @deprecated(reason: "Use messagesPrompt instead")  */
  prompt?: InputMaybe<Scalars['String']>
}

export type UpdateCopilotSettingsOutput = {
  __typename?: 'UpdateCopilotSettingsOutput'
  copilot: Copilot
}

export type UpdateCsatFeedbackInput = {
  commentText?: InputMaybe<Scalars['String']>
  feedbackId: Scalars['GUID']
  rating?: InputMaybe<Scalars['PositiveInt']>
  tags?: InputMaybe<Scalars['String'][]>
}

export type UpdateCsatFeedbackOutput = {
  __typename?: 'UpdateCsatFeedbackOutput'
  feedback: CsatFeedback
}

export type UpdateDelegationCoachSettingsInput = {
  systemPrompt?: InputMaybe<Scalars['String']>
  taskSuggestionsFunction?: InputMaybe<Scalars['String']>
}

export type UpdateDelegationCoachSettingsOutput = {
  __typename?: 'UpdateDelegationCoachSettingsOutput'
  delegationCoach: DelegationCoach
}

export type UpdateMatchingInput = {
  isHiddenFromCatalog?: InputMaybe<Scalars['Boolean']>
  isHighTouch?: InputMaybe<Scalars['Boolean']>
  matchingId: Scalars['GUID']
  targetCandidatesCount?: InputMaybe<Scalars['PositiveInt']>
}

export type UpdateMatchingOutput = {
  __typename?: 'UpdateMatchingOutput'
  matching: Matching
}

export type UpdateMetricEventInput = {
  description?: InputMaybe<Scalars['String']>
  metricEventId: Scalars['ID']
}

export type UpdateMetricEventOutput = {
  __typename?: 'UpdateMetricEventOutput'
  metricEvent: MetricEvent
}

export type UpdateMetricEventPropertyInput = {
  description?: InputMaybe<Scalars['String']>
  metricEventId: Scalars['ID']
  propertyName: Scalars['String']
}

export type UpdateMetricEventPropertyOutput = {
  __typename?: 'UpdateMetricEventPropertyOutput'
  metricEvent: MetricEvent
}

export type UpdateOnboardingStepInput = {
  data: Scalars['JSONObject']
  isCompleted: Scalars['Boolean']
  stepId: OnboardingStepId
  workspaceId: Scalars['ID']
}

export type UpdateOnboardingStepOutput = {
  __typename?: 'UpdateOnboardingStepOutput'
  workspace: Workspace
}

export type UpdateOrientationInput = {
  grantAppAccess?: InputMaybe<Scalars['Boolean']>
  markAsComplete?: InputMaybe<Scalars['Boolean']>
  reset?: InputMaybe<Scalars['Boolean']>
  sentPostDay1Email?: InputMaybe<Scalars['Boolean']>
  sentPostDay2Email?: InputMaybe<Scalars['Boolean']>
  sentPostDay3Email?: InputMaybe<Scalars['Boolean']>
  sentPreDay1Email?: InputMaybe<Scalars['Boolean']>
  userIds: Scalars['ID'][]
}

export type UpdateOrientationOutput = {
  __typename?: 'UpdateOrientationOutput'
  users: Maybe<Assistantable>[]
}

export type UpdatePayPeriodInput = {
  lockTimeTrackingOn: Scalars['Date']
}

export type UpdatePayPeriodOutput = {
  __typename?: 'UpdatePayPeriodOutput'
  payPeriod: PayPeriod
}

export type UpdateStripeCustomerIdInput = {
  entityId: Scalars['ID']
  stripeCustomerId: Scalars['ID']
}

export type UpdateStripeCustomerIdOutput = {
  __typename?: 'UpdateStripeCustomerIdOutput'
  entity: BillableEntity
}

export type UpdateSystemFeatureFlagInput = {
  featureFlagId: Scalars['ID']
  isEnabled: Scalars['Boolean']
}

export type UpdateSystemFeatureFlagOutput = {
  __typename?: 'UpdateSystemFeatureFlagOutput'
  systemFeatureFlag: SystemFeatureFlag
}

export type UpdateTeamBillingInput = {
  maxioSubscriptionId?: InputMaybe<Scalars['String']>
}

export type UpdateTeamInput = {
  iconUrl?: InputMaybe<Scalars['URL']>
  name?: InputMaybe<Scalars['String']>
  principalUserId?: InputMaybe<Scalars['ID']>
  successManagerId?: InputMaybe<Scalars['ID']>
}

export type UpdateTimeTrackingEntryBillToInput = {
  templateId?: InputMaybe<Scalars['ID']>
  timeTrackingEntryId: Scalars['GUID']
  title?: InputMaybe<Scalars['String']>
  workspaceId?: InputMaybe<Scalars['ID']>
}

export type UpdateTimeTrackingEntryBillToOutput = {
  __typename?: 'UpdateTimeTrackingEntryBillToOutput'
  timeTrackingEntry: TimeTrackingEntry
}

export type UpdateTimeTrackingEntryInput = {
  endedAt?: InputMaybe<Scalars['DateTime']>
  startedAt?: InputMaybe<Scalars['DateTime']>
  timeTrackingEntryId: Scalars['GUID']
}

export type UpdateTimeTrackingEntryOutput = {
  __typename?: 'UpdateTimeTrackingEntryOutput'
  timeTrackingEntry: TimeTrackingEntry
}

export type UpdateTransitionChangeoverInput = {
  changeoverAt?: InputMaybe<Scalars['DateTime']>
  matchingId: Scalars['GUID']
}

export type UpdateTransitionChangeoverOutput = {
  __typename?: 'UpdateTransitionChangeoverOutput'
  matching: Matching
}

export type UpdateTransitionHandoverInput = {
  handoverAt?: InputMaybe<Scalars['DateTime']>
  handoverStatus?: InputMaybe<TransitionHandoverStatus>
  matchingId: Scalars['GUID']
  shouldManagerAttendHandover?: InputMaybe<Scalars['Boolean']>
}

export type UpdateTransitionHandoverOutput = {
  __typename?: 'UpdateTransitionHandoverOutput'
  matching: Matching
}

export type UpdateTransitionInformingInput = {
  hasDisabledSlackMessages?: InputMaybe<Scalars['Boolean']>
  informingCategory?: InputMaybe<TransitionInformingCategory>
  informingStatus?: InputMaybe<TransitionInformingStatus>
  matchingId: Scalars['GUID']
  successSpecialistId?: InputMaybe<Scalars['ID']>
}

export type UpdateTransitionInformingOutput = {
  __typename?: 'UpdateTransitionInformingOutput'
  matching: Matching
}

export type UpdateTransitionMatchingStatusInput = {
  matchingId: Scalars['GUID']
  matchingStatus: TransitionMatchingStatus
}

export type UpdateTransitionMatchingStatusOutput = {
  __typename?: 'UpdateTransitionMatchingStatusOutput'
  matching: Matching
}

export type UpdateTransitionNotepadInput = {
  matchingId: Scalars['GUID']
  notepad: Scalars['String']
}

export type UpdateTransitionNotepadOutput = {
  __typename?: 'UpdateTransitionNotepadOutput'
  matching: Matching
}

export type UpdateUserInput = {
  birthday?: InputMaybe<AnniversaryDateInput>
  category?: InputMaybe<UserCategory>
  cityPlaceId?: InputMaybe<Scalars['ID']>
  desiredSkills?: InputMaybe<Scalars['String'][]>
  englishLevel?: InputMaybe<CefrScale>
  experienceHighlights?: InputMaybe<Scalars['String']>
  experienceInTools?: InputMaybe<Scalars['String'][]>
  externalDisc?: InputMaybe<DiscScoreInput>
  facet?: InputMaybe<UserFacet>
  familyName?: InputMaybe<Scalars['String']>
  givenName?: InputMaybe<Scalars['String']>
  hoursLimit?: InputMaybe<Scalars['PositiveInt']>
  industries?: InputMaybe<Scalars['String'][]>
  interestedInIndustries?: InputMaybe<Scalars['String'][]>
  internalDisc?: InputMaybe<DiscScoreInput>
  invoicedUserId?: InputMaybe<Scalars['ID']>
  isAllowedPersonal?: InputMaybe<Scalars['Boolean']>
  isOnPause?: InputMaybe<Scalars['Boolean']>
  isOpenToMatch?: InputMaybe<Scalars['Boolean']>
  isOpenToSupport?: InputMaybe<Scalars['Boolean']>
  isSaas?: InputMaybe<Scalars['Boolean']>
  languages?: InputMaybe<Scalars['LanguageCode'][]>
  matchingNotepad?: InputMaybe<Scalars['String']>
  /**  @deprecated  */
  openToAdditionalWeeklyHours?: InputMaybe<Scalars['String']>
  personaId?: InputMaybe<Scalars['GUID']>
  personalityTraits?: InputMaybe<Scalars['String'][]>
  photoUrl?: InputMaybe<Scalars['URL']>
  position?: InputMaybe<Scalars['String']>
  principalUserId?: InputMaybe<Scalars['ID']>
  pronoun?: InputMaybe<UserPronoun>
  skillsetRating?: InputMaybe<Scalars['JSONObject']>
  startDate?: InputMaybe<Scalars['Date']>
  successManagerId?: InputMaybe<Scalars['ID']>
  targetAdditionalWeeklyHours?: InputMaybe<Scalars['String']>
  targetTotalWeeklyHours?: InputMaybe<Scalars['String']>
  workTimeZones?: InputMaybe<Scalars['String'][]>
}

export type UpdateWorkspaceInput = {
  copilot?: InputMaybe<CopilotPreferencesInput>
  /**  @deprecated(reason: "Use copilot instead")  */
  copilotEnabled?: InputMaybe<Scalars['Boolean']>
  eveningDigestEmail?: InputMaybe<Scalars['Boolean']>
  feedbackRequestEmail?: InputMaybe<Scalars['Boolean']>
  groupedNotificationsEmail?: InputMaybe<Scalars['Boolean']>
  usageAlertAssistantEmail?: InputMaybe<Scalars['Boolean']>
  usageAlertEmail?: InputMaybe<Scalars['Boolean']>
  usageLimitsEmail?: InputMaybe<Scalars['Boolean']>
  weeklyDigestEmail?: InputMaybe<Scalars['Boolean']>
  workingHoursTimeZone?: InputMaybe<Scalars['String']>
  workspaceId: Scalars['ID']
}

export type UpdateWorkspaceOutput = {
  __typename?: 'UpdateWorkspaceOutput'
  workspace: Workspace
}

export type UpdateWorkspaceTeamInput = {
  teamId: Scalars['ID']
  workspaceId: Scalars['ID']
}

export type UploadPublicFileInput = {
  mime: Scalars['MIME']
  name: Scalars['String']
}

export type UploadPublicFileOutput = {
  __typename?: 'UploadPublicFileOutput'
  filename: Scalars['String']
  publicUrl: Scalars['URL']
  signedUrl: Scalars['URL']
}

export type UpsertAdminRoleInput = {
  description?: InputMaybe<Scalars['String']>
  inheritedRoleIds: Scalars['String'][]
  name: Scalars['String']
  operationIds: Scalars['String'][]
  roleId: Scalars['ID']
}

export type UpsertAdminRoleOutput = {
  __typename?: 'UpsertAdminRoleOutput'
  adminRole: AdminRole
}

export type UpsertDossierFieldInput = {
  fieldId: Scalars['GUID']
  input?: InputMaybe<DossierInputableInput>
  label?: InputMaybe<Scalars['String']>
  orderIndex?: InputMaybe<Scalars['String']>
  sectionId?: InputMaybe<Scalars['GUID']>
}

export type UpsertDossierFieldOutput = {
  __typename?: 'UpsertDossierFieldOutput'
  field: DossierField
}

export type UpsertDossierFolderInput = {
  folderId: Scalars['GUID']
  header?: InputMaybe<DossierFolderHeaderInput>
  isPrivate?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  orderIndex?: InputMaybe<Scalars['String']>
}

export type UpsertDossierFolderOutput = {
  __typename?: 'UpsertDossierFolderOutput'
  folder: DossierFolder
}

export type UpsertDossierSectionInput = {
  defaultInputType?: InputMaybe<DossierInputableType>
  emoji?: InputMaybe<Scalars['String']>
  folderId?: InputMaybe<Scalars['GUID']>
  isPrivate?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  orderIndex?: InputMaybe<Scalars['String']>
  sectionId: Scalars['GUID']
}

export type UpsertDossierSectionOutput = {
  __typename?: 'UpsertDossierSectionOutput'
  section: DossierSection
}

export type UpsertFeatureInput = {
  appVersions?: InputMaybe<Scalars['JSONObject']>
  description?: InputMaybe<Scalars['String']>
  featureId: Scalars['ID']
  targets?: InputMaybe<FeatureTargetInput[]>
}

export type UpsertFeatureOutput = {
  __typename?: 'UpsertFeatureOutput'
  feature: Feature
}

export type UpsertMatchingProposalInput = {
  additionalContext?: InputMaybe<Scalars['String']>
  assistantId: Scalars['ID']
  executiveReasonForMatch?: InputMaybe<Scalars['String']>
  matchingId: Scalars['GUID']
  orderIndex?: InputMaybe<Scalars['String']>
  reasonForMatch?: InputMaybe<Scalars['String']>
  reply?: InputMaybe<MatchingProposalReply>
  replyComment?: InputMaybe<Scalars['String']>
}

export type UpsertMatchingProposalOutput = {
  __typename?: 'UpsertMatchingProposalOutput'
  matching: Matching
}

export type UpsertMessageTemplateInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['GUID']
  /**  Only for creation, cannot be updated  */
  kind?: InputMaybe<MessageTemplateKind>
  languages?: InputMaybe<UpsertMessageTemplateLanguageInput[]>
  name?: InputMaybe<Scalars['String']>
}

export type UpsertMessageTemplateLanguageInput = {
  fields: Scalars['JSONObject']
  languageCode: Scalars['LanguageCode']
}

export type UpsertMessageTemplateOutput = {
  __typename?: 'UpsertMessageTemplateOutput'
  messageTemplate: MessageTemplate
}

export type UpsertPricingInput = {
  description?: InputMaybe<Scalars['String']>
  hoursDescription?: InputMaybe<Scalars['String']>
  includesPremiumPerks?: InputMaybe<Scalars['Boolean']>
  includesTeamFeatures?: InputMaybe<Scalars['Boolean']>
  instantInviteMaxUsers?: InputMaybe<Scalars['NonNegativeInt']>
  isAvailable?: InputMaybe<Scalars['Boolean']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  isSharable?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  learnMoreUrl?: InputMaybe<Scalars['URL']>
  model?: InputMaybe<PricingModel>
  modelConfig?: InputMaybe<Scalars['JSONObject']>
  priceDescription?: InputMaybe<Scalars['String']>
  pricingId: Scalars['GUID']
  ref?: InputMaybe<Scalars['String']>
  sellingPoints?: InputMaybe<Scalars['String'][]>
  sellingPointsNotice?: InputMaybe<Scalars['String']>
}

export type UpsertPricingOutput = {
  __typename?: 'UpsertPricingOutput'
  pricing: Pricing
}

export type UpsertSettingInput = {
  data: Scalars['JSONObject']
  settingId: Scalars['ID']
}

export type UpsertSettingOutput = {
  __typename?: 'UpsertSettingOutput'
  setting: Setting
}

export type UpsertTopicTemplateInput = {
  assigneeRole?: InputMaybe<WorkspaceUserRole>
  category?: InputMaybe<DiscoverCategory>
  description?: InputMaybe<Scalars['String']>
  iconColor?: InputMaybe<Scalars['HexColorCode']>
  iconName?: InputMaybe<Scalars['String']>
  imageUrl?: InputMaybe<Scalars['URL']>
  instructions?: InputMaybe<Scalars['String']>
  introduction?: InputMaybe<Scalars['String']>
  introductionImageUrl?: InputMaybe<Scalars['URL']>
  introductionTitle?: InputMaybe<Scalars['String']>
  isDraft?: InputMaybe<Scalars['Boolean']>
  isNew?: InputMaybe<Scalars['Boolean']>
  keywords?: InputMaybe<Scalars['String'][]>
  labels?: InputMaybe<Scalars['String'][]>
  notepad?: InputMaybe<Scalars['String']>
  privateTo?: InputMaybe<WorkspaceUserRole>
  reportTopicTemplate?: InputMaybe<Scalars['String']>
  sectionKey?: InputMaybe<DiscoverSectionKey>
  shortDescription?: InputMaybe<Scalars['String']>
  templateId: Scalars['GUID']
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<TopicTemplateType>
}

export type UpsertTopicTemplateOutput = {
  __typename?: 'UpsertTopicTemplateOutput'
  topicTemplate: TopicTemplate
}

export type UserAccount = {
  __typename?: 'UserAccount'
  id: Scalars['ID']
  isDisconnected: Scalars['Boolean']
  isPrimary: Scalars['Boolean']
  key: Scalars['GUID']
  label: Scalars['String']
  profile: UserProfile
  scopes: Scalars['String'][]
  type: UserAccountType
}

export enum UserAccountType {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  SLACK = 'SLACK',
}

export enum UserCategory {
  ASSISTANT = 'ASSISTANT',
  EXECUTIVE = 'EXECUTIVE',
  SANDBOX = 'SANDBOX',
  TEAM = 'TEAM',
}

export enum UserFacet {
  PRINCIPAL = 'PRINCIPAL',
  SIGNIFICANT_OTHER = 'SIGNIFICANT_OTHER',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export type UserFieldsOptions = {
  __typename?: 'UserFieldsOptions'
  assistantSkills: Scalars['String'][]
  industries: Scalars['String'][]
  personalityTraits: Scalars['String'][]
  tools: Scalars['String'][]
}

export type UserProfile = {
  __typename?: 'UserProfile'
  avatarUrl?: Maybe<Scalars['URL']>
  displayName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['EmailAddress']>
  emails: Scalars['EmailAddress'][]
  familyName?: Maybe<Scalars['String']>
  givenName?: Maybe<Scalars['String']>
  id: Scalars['ID']
}

export type UserProfileAvatarUrlArgs = {
  size?: InputMaybe<Scalars['PositiveInt']>
}

export enum UserPronoun {
  HE = 'HE',
  SHE = 'SHE',
  THEY = 'THEY',
}

export type UserTimelineItem = TimelineItem & {
  __typename?: 'UserTimelineItem'
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  emoji?: Maybe<Scalars['String']>
  event: TimelineEvent
  title: Scalars['String']
}

export type Userable = {
  acceptedPptcAt?: Maybe<Scalars['DateTime']>
  accounts: UserAccount[]
  birthday?: Maybe<AnniversaryDate>
  bugReportsList: BugReportsList
  category?: Maybe<UserCategory>
  city?: Maybe<Place>
  createdAt: Scalars['DateTime']
  endDate?: Maybe<Scalars['Date']>
  hasPoolWorkspace: Scalars['Boolean']
  id: Scalars['ID']
  isArchived: Scalars['Boolean']
  isSaas: Scalars['Boolean']
  languages: Scalars['LanguageCode'][]
  pendingChurnAutomationJob?: Maybe<AutomationJob>
  phoneNumber?: Maybe<Scalars['PhoneNumber']>
  profile: UserProfile
  pronoun: UserPronoun
  startDate?: Maybe<Scalars['Date']>
  updatedAt: Scalars['DateTime']
  workspaces: Workspace[]
}

export type UserableWorkspacesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>
  isSandbox?: InputMaybe<Scalars['Boolean']>
  role?: InputMaybe<WorkspaceUserRole>
}

export type UsersList = {
  __typename?: 'UsersList'
  after?: Maybe<Scalars['String']>
  items: Userable[]
}

export type VoidStripeInvoiceInput = {
  invoiceId: Scalars['String']
}

export type VoidStripeInvoiceOutput = {
  __typename?: 'VoidStripeInvoiceOutput'
  customer: StripeCustomer
  invoice: StripeInvoice
}

export type Who = {
  __typename?: 'Who'
  assistant?: Maybe<Who>
  avatarUrl?: Maybe<Scalars['URL']>
  biography?: Maybe<Scalars['String']>
  birthday?: Maybe<AnniversaryDate>
  category: UserCategory
  city?: Maybe<Place>
  funFacts?: Maybe<Scalars['String']>
  givenName: Scalars['String']
  id: Scalars['GUID']
  languages: Scalars['LanguageCode'][]
  /**  For team members  */
  position?: Maybe<Scalars['String']>
  skills: Scalars['String'][]
  startDate?: Maybe<Scalars['Date']>
}

export type WhosList = {
  __typename?: 'WhosList'
  after?: Maybe<Scalars['String']>
  items: Who[]
}

export type WorkExperience = {
  __typename?: 'WorkExperience'
  companyName: Scalars['String']
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<AnniversaryDate>
  startDate: AnniversaryDate
  title: Scalars['String']
}

export type WorkExperienceInput = {
  companyName: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<AnniversaryDateInput>
  startDate: AnniversaryDateInput
  title: Scalars['String']
}

export type Workspace = {
  __typename?: 'Workspace'
  accountKeys: Scalars['ID'][]
  assistants: Assistantable[]
  copilotEnabled: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  eveningDigestEmail: Scalars['Boolean']
  executives: Executivable[]
  features: WorkspaceFeature[]
  feedbackRequestEmail: Scalars['Boolean']
  /**  Feedbacks / Read list of feedbacks in a specific workspace  */
  feedbacksList: FeedbacksList
  groupedNotificationsEmail: Scalars['Boolean']
  hasCoverage: Scalars['Boolean']
  hasHadCoverage: Scalars['Boolean']
  id: Scalars['ID']
  isArchived: Scalars['Boolean']
  isPendingTransition: Scalars['Boolean']
  isPool: Scalars['Boolean']
  isSandbox: Scalars['Boolean']
  /**  True if workspace belongs to a team and exec is not the principal user  */
  isTeamPlusOneWorkspace: Scalars['Boolean']
  onboarding?: Maybe<Onboarding>
  poolTokenBalance?: Maybe<PoolTokenBalance>
  shortId: Scalars['ID']
  spectators: HqUser[]
  team?: Maybe<Team>
  updatedAt: Scalars['DateTime']
  usage: WorkspaceUsage
  usageAlertAssistantEmail: Scalars['Boolean']
  usageAlertEmail: Scalars['Boolean']
  usageLimitsEmail: Scalars['Boolean']
  users: Userable[]
  weeklyDigestEmail: Scalars['Boolean']
  workingHoursTimeZone?: Maybe<Scalars['String']>
}

export type WorkspaceHasCoverageArgs = {
  assistantId?: InputMaybe<Scalars['ID']>
}

export type WorkspaceHasHadCoverageArgs = {
  assistantId?: InputMaybe<Scalars['ID']>
}

export type WorkspaceUsersArgs = {
  role?: InputMaybe<WorkspaceUserRole>
}

export type WorkspaceCategoryUsage = {
  __typename?: 'WorkspaceCategoryUsage'
  category?: Maybe<Scalars['String']>
  hours: Scalars['NonNegativeFloat']
}

export type WorkspaceFeature = {
  __typename?: 'WorkspaceFeature'
  feature: Feature
  id: Scalars['ID']
  isEnabled: Scalars['Boolean']
  isOverridden: Scalars['Boolean']
}

export type WorkspaceMonthlyUsage = {
  __typename?: 'WorkspaceMonthlyUsage'
  categories: WorkspaceCategoryUsage[]
  hours: Scalars['NonNegativeFloat']
  id: Scalars['ID']
  /**  Month number, from 1 to 12  */
  month: Scalars['PositiveInt']
  year: Scalars['PositiveInt']
}

export type WorkspaceQuarterlyReport = {
  __typename?: 'WorkspaceQuarterlyReport'
  hours: Scalars['NonNegativeFloat']
  id: Scalars['ID']
  /**  Month number, from 1 to 4  */
  quarter: Scalars['PositiveInt']
  workspaceId: Scalars['ID']
  year: Scalars['PositiveInt']
}

export type WorkspaceReport = {
  __typename?: 'WorkspaceReport'
  id: Scalars['ID']
  monthlyUsages: WorkspaceMonthlyUsage[]
  quarterlyReports: WorkspaceQuarterlyReport[]
  weeklyUsages: WorkspaceWeeklyUsage[]
  /**  If workspace is missing, then it's the internal 'Double' and 'Double Buddy' projects  */
  workspace?: Maybe<Workspace>
}

export type WorkspaceTimelineItem = TimelineItem & {
  __typename?: 'WorkspaceTimelineItem'
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  emoji?: Maybe<Scalars['String']>
  event: TimelineEvent
  title: Scalars['String']
  workspace: Workspace
}

export type WorkspaceUsage = {
  __typename?: 'WorkspaceUsage'
  averageWeeklyHours: Scalars['NonNegativeFloat']
  hours: Scalars['NonNegativeFloat']
  hoursLimit?: Maybe<Scalars['PositiveInt']>
  id: Scalars['ID']
  isAllowedPersonal?: Maybe<Scalars['Boolean']>
  monthlyUsages: WorkspaceMonthlyUsage[]
  startDate: Scalars['Date']
  weeklyUsages: WorkspaceWeeklyUsage[]
}

export type WorkspaceUsageAverageWeeklyHoursArgs = {
  excludeNoUsageWeeks?: InputMaybe<Scalars['Boolean']>
}

export type WorkspaceUsageHoursArgs = {
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}

export type WorkspaceUsageMonthlyUsagesArgs = {
  last?: InputMaybe<Scalars['PositiveInt']>
}

export type WorkspaceUsageWeeklyUsagesArgs = {
  last?: InputMaybe<Scalars['PositiveInt']>
}

export enum WorkspaceUserRemovalReason {
  ASSISTANT_CHURN = 'ASSISTANT_CHURN',
  ASSISTANT_REQUESTED_TRANSITION = 'ASSISTANT_REQUESTED_TRANSITION',
  EXEC_CHURN = 'EXEC_CHURN',
  EXEC_REQUESTED_TRANSITION = 'EXEC_REQUESTED_TRANSITION',
  NOT_ONBOARDED = 'NOT_ONBOARDED',
}

export enum WorkspaceUserRole {
  ASSISTANT = 'ASSISTANT',
  EXECUTIVE = 'EXECUTIVE',
  SPECTATOR = 'SPECTATOR',
}

export type WorkspaceUserTimelineItem = TimelineItem & {
  __typename?: 'WorkspaceUserTimelineItem'
  activityAt: Scalars['DateTime']
  category: TimelineItemCategory
  emoji?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['DateTime']>
  event: TimelineEvent
  isCoverage: Scalars['Boolean']
  startDate: Scalars['DateTime']
  title: Scalars['String']
  user: Userable
  workspace: Workspace
}

export type WorkspaceWeeklyUsage = {
  __typename?: 'WorkspaceWeeklyUsage'
  categories: WorkspaceCategoryUsage[]
  hours: Scalars['NonNegativeFloat']
  id: Scalars['ID']
  /**  @deprecated(reason: "Our weeks start on Sunday")  */
  monday: Scalars['Date']
  /**  Date of sunday corresponding to that week */
  sunday: Scalars['Date']
  /**  Week number, from 1 to 52-ish https://en.wikipedia.org/wiki/ISO_week_date  */
  week: Scalars['PositiveInt']
  /**  Might not be what you expect, careful! https://en.wikipedia.org/wiki/ISO_week_date  */
  weekYear: Scalars['PositiveInt']
}

export type WorkspacesList = {
  __typename?: 'WorkspacesList'
  after?: Maybe<Scalars['String']>
  hasAfter: Scalars['Boolean']
  items: Workspace[]
}

export type WorkspaceFeatureFragment = {
  __typename?: 'WorkspaceFeature'
  id: string
  isEnabled: boolean
  isOverridden: boolean
  feature: { __typename?: 'Feature'; id: string }
}

export type UserProfile_NamesFragment = {
  __typename?: 'UserProfile'
  id: string
  givenName?: string | null
  familyName?: string | null
  displayName?: string | null
}

export type UserProfile_NamesPhotoFragment = {
  __typename?: 'UserProfile'
  id: string
  avatarUrl?: string | null
  givenName?: string | null
  familyName?: string | null
  displayName?: string | null
}

export type UserProfile_EmailFragment = {
  __typename?: 'UserProfile'
  id: string
  email?: string | null
}

export type UserProfile_NamesPhotoEmailsFragment = {
  __typename?: 'UserProfile'
  id: string
  emails: string[]
  avatarUrl?: string | null
  email?: string | null
  givenName?: string | null
  familyName?: string | null
  displayName?: string | null
}

export type PlaceAddress_CountryCodeFragment = {
  __typename?: 'PlaceAddress'
  id: string
  countryCode?: string | null
}

export type PlaceAddress_FormattedFragment = {
  __typename?: 'PlaceAddress'
  id: string
  formatted?: string | null
}

export type Place_AddressFragment = {
  __typename?: 'Place'
  id: string
  address?: {
    __typename?: 'PlaceAddress'
    id: string
    formatted?: string | null
  } | null
}

export type Place_GoogleMapsPlaceIdFragment = {
  __typename?: 'Place'
  id: string
  googleMapsPlaceId?: string | null
}

export type CityFragment = {
  __typename?: 'Place'
  id: string
  timeZone?: string | null
  googleMapsPlaceId?: string | null
  address?: {
    __typename?: 'PlaceAddress'
    id: string
    formatted?: string | null
  } | null
}

type Userable_Profile_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_Profile_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_Profile_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_Profile_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type Userable_ProfileFragment =
  | Userable_Profile_AssistantUser_Fragment
  | Userable_Profile_ExecutiveUser_Fragment
  | Userable_Profile_HqUser_Fragment
  | Userable_Profile_SandboxUser_Fragment

type Userable_CountryCode_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type Userable_CountryCode_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type Userable_CountryCode_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type Userable_CountryCode_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

export type Userable_CountryCodeFragment =
  | Userable_CountryCode_AssistantUser_Fragment
  | Userable_CountryCode_ExecutiveUser_Fragment
  | Userable_CountryCode_HqUser_Fragment
  | Userable_CountryCode_SandboxUser_Fragment

type Userable_Category_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  category?: UserCategory | null
}

type Userable_Category_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  category?: UserCategory | null
}

type Userable_Category_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  category?: UserCategory | null
}

type Userable_Category_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  category?: UserCategory | null
}

export type Userable_CategoryFragment =
  | Userable_Category_AssistantUser_Fragment
  | Userable_Category_ExecutiveUser_Fragment
  | Userable_Category_HqUser_Fragment
  | Userable_Category_SandboxUser_Fragment

type Userable_Minimal_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_Minimal_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_Minimal_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_Minimal_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type Userable_MinimalFragment =
  | Userable_Minimal_AssistantUser_Fragment
  | Userable_Minimal_ExecutiveUser_Fragment
  | Userable_Minimal_HqUser_Fragment
  | Userable_Minimal_SandboxUser_Fragment

type Userable_PendingChurnAutomationJob_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  pendingChurnAutomationJob?: {
    __typename?: 'AutomationJob'
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    automation: { __typename?: 'Automation'; id: string; name: string }
  } | null
}

type Userable_PendingChurnAutomationJob_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  pendingChurnAutomationJob?: {
    __typename?: 'AutomationJob'
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    automation: { __typename?: 'Automation'; id: string; name: string }
  } | null
}

type Userable_PendingChurnAutomationJob_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  pendingChurnAutomationJob?: {
    __typename?: 'AutomationJob'
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    automation: { __typename?: 'Automation'; id: string; name: string }
  } | null
}

type Userable_PendingChurnAutomationJob_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  pendingChurnAutomationJob?: {
    __typename?: 'AutomationJob'
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    automation: { __typename?: 'Automation'; id: string; name: string }
  } | null
}

export type Userable_PendingChurnAutomationJobFragment =
  | Userable_PendingChurnAutomationJob_AssistantUser_Fragment
  | Userable_PendingChurnAutomationJob_ExecutiveUser_Fragment
  | Userable_PendingChurnAutomationJob_HqUser_Fragment
  | Userable_PendingChurnAutomationJob_SandboxUser_Fragment

type Executivable_Teammate_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  hoursLimit?: number | null
  isAllowedPersonal?: boolean | null
}

type Executivable_Teammate_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  hoursLimit?: number | null
  isAllowedPersonal?: boolean | null
}

type Executivable_Teammate_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  hoursLimit?: number | null
  isAllowedPersonal?: boolean | null
}

export type Executivable_TeammateFragment =
  | Executivable_Teammate_ExecutiveUser_Fragment
  | Executivable_Teammate_HqUser_Fragment
  | Executivable_Teammate_SandboxUser_Fragment

type Userable_StartDate_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  startDate?: string | null
}

type Userable_StartDate_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  startDate?: string | null
}

type Userable_StartDate_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  startDate?: string | null
}

type Userable_StartDate_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  startDate?: string | null
}

export type Userable_StartDateFragment =
  | Userable_StartDate_AssistantUser_Fragment
  | Userable_StartDate_ExecutiveUser_Fragment
  | Userable_StartDate_HqUser_Fragment
  | Userable_StartDate_SandboxUser_Fragment

type Executivable_IsOnPause_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  isOnPause: boolean
}

type Executivable_IsOnPause_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  isOnPause: boolean
}

type Executivable_IsOnPause_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  isOnPause: boolean
}

export type Executivable_IsOnPauseFragment =
  | Executivable_IsOnPause_ExecutiveUser_Fragment
  | Executivable_IsOnPause_HqUser_Fragment
  | Executivable_IsOnPause_SandboxUser_Fragment

export type PoolTokenBalanceFragment = {
  __typename?: 'PoolTokenBalance'
  id: string
  availablePoolTokenCount: number
}

type BasicUser_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type BasicUser_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  facet?: UserFacet | null
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type BasicUser_HqUser_Fragment = {
  __typename?: 'HqUser'
  facet?: UserFacet | null
  position?: string | null
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type BasicUser_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  facet?: UserFacet | null
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type BasicUserFragment =
  | BasicUser_AssistantUser_Fragment
  | BasicUser_ExecutiveUser_Fragment
  | BasicUser_HqUser_Fragment
  | BasicUser_SandboxUser_Fragment

export type Workspace_SettingsFragment = {
  __typename?: 'Workspace'
  id: string
  workingHoursTimeZone?: string | null
  usageAlertEmail: boolean
  usageAlertAssistantEmail: boolean
  usageLimitsEmail: boolean
  feedbackRequestEmail: boolean
  groupedNotificationsEmail: boolean
  eveningDigestEmail: boolean
  weeklyDigestEmail: boolean
  copilotEnabled: boolean
}

export type Workspace_UsersFragment = {
  __typename?: 'Workspace'
  id: string
  isPendingTransition: boolean
  hasCoverage: boolean
  assistants: (
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  executives: (
    | {
        __typename?: 'ExecutiveUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  spectators: {
    __typename?: 'HqUser'
    facet?: UserFacet | null
    position?: string | null
    industries: string[]
    id: string
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }[]
}

export type BasicWorkspaceFragment = {
  __typename?: 'Workspace'
  id: string
  isArchived: boolean
  accountKeys: string[]
  isPool: boolean
  workingHoursTimeZone?: string | null
  usageAlertEmail: boolean
  usageAlertAssistantEmail: boolean
  usageLimitsEmail: boolean
  feedbackRequestEmail: boolean
  groupedNotificationsEmail: boolean
  eveningDigestEmail: boolean
  weeklyDigestEmail: boolean
  copilotEnabled: boolean
  isPendingTransition: boolean
  hasCoverage: boolean
  onboarding?: {
    __typename?: 'Onboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  } | null
  poolTokenBalance?: {
    __typename?: 'PoolTokenBalance'
    id: string
    availablePoolTokenCount: number
  } | null
  assistants: (
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  executives: (
    | {
        __typename?: 'ExecutiveUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  spectators: {
    __typename?: 'HqUser'
    facet?: UserFacet | null
    position?: string | null
    industries: string[]
    id: string
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }[]
}

export type WorkspaceWithTeamFragment = {
  __typename?: 'Workspace'
  id: string
  isArchived: boolean
  accountKeys: string[]
  isPool: boolean
  workingHoursTimeZone?: string | null
  usageAlertEmail: boolean
  usageAlertAssistantEmail: boolean
  usageLimitsEmail: boolean
  feedbackRequestEmail: boolean
  groupedNotificationsEmail: boolean
  eveningDigestEmail: boolean
  weeklyDigestEmail: boolean
  copilotEnabled: boolean
  isPendingTransition: boolean
  hasCoverage: boolean
  team?: {
    __typename?: 'Team'
    id: string
    name?: string | null
    iconUrl?: string | null
    isArchived: boolean
    principalUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  } | null
  onboarding?: {
    __typename?: 'Onboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  } | null
  poolTokenBalance?: {
    __typename?: 'PoolTokenBalance'
    id: string
    availablePoolTokenCount: number
  } | null
  assistants: (
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  executives: (
    | {
        __typename?: 'ExecutiveUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  spectators: {
    __typename?: 'HqUser'
    facet?: UserFacet | null
    position?: string | null
    industries: string[]
    id: string
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }[]
}

export type WorkspaceWithTeamAndMaxioSubscriptionFragment = {
  __typename?: 'Workspace'
  id: string
  isArchived: boolean
  accountKeys: string[]
  isPool: boolean
  workingHoursTimeZone?: string | null
  usageAlertEmail: boolean
  usageAlertAssistantEmail: boolean
  usageLimitsEmail: boolean
  feedbackRequestEmail: boolean
  groupedNotificationsEmail: boolean
  eveningDigestEmail: boolean
  weeklyDigestEmail: boolean
  copilotEnabled: boolean
  isPendingTransition: boolean
  hasCoverage: boolean
  team?: {
    __typename?: 'Team'
    id: string
    name?: string | null
    iconUrl?: string | null
    isArchived: boolean
    principalUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    subscription?: {
      __typename?: 'TeamSubscription'
      id: string
      type: TeamSubscriptionType
      productName?: string | null
      productPrice?: number | null
      state?: SubscriptionState | null
      maxioUrl?: string | null
      customerDisplayName?: string | null
      currentBillingCycle?: {
        __typename?: 'SubscriptionBillingCycle'
        startDate: string | Date
        endDate: string | Date
        hours: number
      } | null
    } | null
  } | null
  onboarding?: {
    __typename?: 'Onboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  } | null
  poolTokenBalance?: {
    __typename?: 'PoolTokenBalance'
    id: string
    availablePoolTokenCount: number
  } | null
  assistants: (
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  executives: (
    | {
        __typename?: 'ExecutiveUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  spectators: {
    __typename?: 'HqUser'
    facet?: UserFacet | null
    position?: string | null
    industries: string[]
    id: string
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }[]
}

export type OnboardingAvailabilityFragment = {
  __typename?: 'OnboardingAvailability'
  id: string
  slots: {
    __typename?: 'OnboardingAvailabilitySlot'
    startAt: string | Date
    endAt: string | Date
  }[]
}

export type Onboarding_CompletionFragment = {
  __typename?: 'Onboarding'
  id: string
  isCompleted: boolean
}

export type Onboarding_CallFragment = {
  __typename?: 'Onboarding'
  id: string
  callStartAt?: string | Date | null
}

export type Onboarding_AvailabilityFragment = {
  __typename?: 'Onboarding'
  id: string
  availability: {
    __typename?: 'OnboardingAvailability'
    id: string
    slots: {
      __typename?: 'OnboardingAvailabilitySlot'
      startAt: string | Date
      endAt: string | Date
    }[]
  }
}

export type Onboarding_EmailStatusFragment = {
  __typename?: 'Onboarding'
  id: string
  hasSentEmail: boolean
  hasSentEmail2: boolean
  hasSentEmail3: boolean
  hasSentEmail4: boolean
  hasSentPostOnboardingEmail: boolean
}

export type Onboarding_SpecialistFragment = {
  __typename?: 'Onboarding'
  id: string
  specialist?: {
    __typename?: 'HqUser'
    facet?: UserFacet | null
    position?: string | null
    industries: string[]
    id: string
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
}

export type Onboarding_ChecklistFragment = {
  __typename?: 'Onboarding'
  id: string
  checklist: {
    __typename?: 'OnboardingChecklist'
    id: string
    tasks: {
      __typename?: 'OnboardingChecklistTask'
      id: string
      title: string
      isCompleted: boolean
    }[]
  }
}

export type Onboarding_StepsFragment = {
  __typename?: 'Onboarding'
  id: string
  steps: {
    __typename?: 'OnboardingStep'
    id: OnboardingStepId
    isCompleted: boolean
    data?: Record<string, any> | null
  }[]
}

export type WorkspaceWithOnboardingFragment = {
  __typename?: 'Workspace'
  id: string
  onboarding?: {
    __typename?: 'Onboarding'
    id: string
    url: string
    rescheduleCallUrl: string
    isCompleted: boolean
    callStartAt?: string | Date | null
    hasSentEmail: boolean
    hasSentEmail2: boolean
    hasSentEmail3: boolean
    hasSentEmail4: boolean
    hasSentPostOnboardingEmail: boolean
    splash: {
      __typename?: 'OnboardingSplash'
      executiveEmail: string
      assistantGivenName: string
      assistantAvatarUrl: string
      assistantBiography: string
      assistantLocation: string
    }
    steps: {
      __typename?: 'OnboardingStep'
      id: OnboardingStepId
      isCompleted: boolean
      data?: Record<string, any> | null
    }[]
    specialist?: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
  } | null
}

export type DiscScoreFragment = {
  __typename?: 'DiscScore'
  d: number
  i: number
  s: number
  c: number
}

type Assistantable_MatchingData_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  currency?: string | null
  hourlyRate?: number | null
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type Assistantable_MatchingData_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  currency?: string | null
  hourlyRate?: number | null
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type Assistantable_MatchingData_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  currency?: string | null
  hourlyRate?: number | null
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

export type Assistantable_MatchingDataFragment =
  | Assistantable_MatchingData_AssistantUser_Fragment
  | Assistantable_MatchingData_HqUser_Fragment
  | Assistantable_MatchingData_SandboxUser_Fragment

export type AssistantableHourlyRateUpdateFragment = {
  __typename?: 'AssistantableHourlyRateUpdate'
  hourlyRate: number
  updatedAt: string | Date
  updatedBy?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
}

type Assistantable_HourlyRateUpdates_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
        updatedBy?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      }[]
    | null
}

type Assistantable_HourlyRateUpdates_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
        updatedBy?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      }[]
    | null
}

type Assistantable_HourlyRateUpdates_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
        updatedBy?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      }[]
    | null
}

export type Assistantable_HourlyRateUpdatesFragment =
  | Assistantable_HourlyRateUpdates_AssistantUser_Fragment
  | Assistantable_HourlyRateUpdates_HqUser_Fragment
  | Assistantable_HourlyRateUpdates_SandboxUser_Fragment

type Userable_Workspaces_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
}

type Userable_Workspaces_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
}

type Userable_Workspaces_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
}

type Userable_Workspaces_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
}

export type Userable_WorkspacesFragment =
  | Userable_Workspaces_AssistantUser_Fragment
  | Userable_Workspaces_ExecutiveUser_Fragment
  | Userable_Workspaces_HqUser_Fragment
  | Userable_Workspaces_SandboxUser_Fragment

type Userable_AirtableId_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  airtableDoubleRecordId?: string | null
  id: string
}

type Userable_AirtableId_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  airtableClientRecordId?: string | null
  airtableLeadRecordId?: string | null
  id: string
}

type Userable_AirtableId_HqUser_Fragment = {
  __typename?: 'HqUser'
  airtableClientRecordId?: string | null
  airtableLeadRecordId?: string | null
  airtableDoubleRecordId?: string | null
  id: string
}

type Userable_AirtableId_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  airtableClientRecordId?: string | null
  airtableLeadRecordId?: string | null
  airtableDoubleRecordId?: string | null
  id: string
}

export type Userable_AirtableIdFragment =
  | Userable_AirtableId_AssistantUser_Fragment
  | Userable_AirtableId_ExecutiveUser_Fragment
  | Userable_AirtableId_HqUser_Fragment
  | Userable_AirtableId_SandboxUser_Fragment

export type UserAccountFragment = {
  __typename?: 'UserAccount'
  key: string
  type: UserAccountType
  id: string
  isPrimary: boolean
  label: string
  scopes: string[]
  isDisconnected: boolean
  profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
}

export type ExecutivableCsmFragment = {
  __typename?: 'ExecutivableCsm'
  id: string
  source:
    | { __typename?: 'AssistantUser' }
    | { __typename?: 'ExecutiveUser' }
    | { __typename?: 'HqUser' }
    | { __typename?: 'SandboxUser' }
    | {
        __typename?: 'Team'
        id: string
        name?: string | null
        iconUrl?: string | null
        isArchived: boolean
      }
  user: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }
}

type BasicUserWithAccounts_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type BasicUserWithAccounts_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  facet?: UserFacet | null
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type BasicUserWithAccounts_HqUser_Fragment = {
  __typename?: 'HqUser'
  facet?: UserFacet | null
  position?: string | null
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type BasicUserWithAccounts_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  facet?: UserFacet | null
  industries: string[]
  id: string
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type BasicUserWithAccountsFragment =
  | BasicUserWithAccounts_AssistantUser_Fragment
  | BasicUserWithAccounts_ExecutiveUser_Fragment
  | BasicUserWithAccounts_HqUser_Fragment
  | BasicUserWithAccounts_SandboxUser_Fragment

type FullUser_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  personalityTraits: string[]
  workTimeZones?: string[] | null
  matchingNotepad?: string | null
  phoneNumber?: string | null
  acceptedPptcAt?: string | Date | null
  id: string
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  csatAverage?: number | null
  performanceScoreValue?: number | null
  airtableDoubleRecordId?: string | null
  currency?: string | null
  hourlyRate?: number | null
  industries: string[]
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
      formatted?: string | null
    } | null
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type FullUser_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  desiredSkills: string[]
  personalityTraits: string[]
  acceptedPptcAt?: string | Date | null
  id: string
  airtableClientRecordId?: string | null
  airtableLeadRecordId?: string | null
  facet?: UserFacet | null
  industries: string[]
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  principalUser?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  invoicedUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  csm?: {
    __typename?: 'ExecutivableCsm'
    id: string
    source:
      | { __typename?: 'AssistantUser' }
      | { __typename?: 'ExecutiveUser' }
      | { __typename?: 'HqUser' }
      | { __typename?: 'SandboxUser' }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
    user: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }
  } | null
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type FullUser_HqUser_Fragment = {
  __typename?: 'HqUser'
  desiredSkills: string[]
  personalityTraits: string[]
  workTimeZones?: string[] | null
  matchingNotepad?: string | null
  acceptedPptcAt?: string | Date | null
  id: string
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  airtableClientRecordId?: string | null
  airtableLeadRecordId?: string | null
  airtableDoubleRecordId?: string | null
  currency?: string | null
  hourlyRate?: number | null
  facet?: UserFacet | null
  position?: string | null
  industries: string[]
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  principalUser?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  invoicedUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  csm?: {
    __typename?: 'ExecutivableCsm'
    id: string
    source:
      | { __typename?: 'AssistantUser' }
      | { __typename?: 'ExecutiveUser' }
      | { __typename?: 'HqUser' }
      | { __typename?: 'SandboxUser' }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
    user: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }
  } | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
      formatted?: string | null
    } | null
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type FullUser_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  desiredSkills: string[]
  personalityTraits: string[]
  workTimeZones?: string[] | null
  matchingNotepad?: string | null
  acceptedPptcAt?: string | Date | null
  id: string
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  airtableClientRecordId?: string | null
  airtableLeadRecordId?: string | null
  airtableDoubleRecordId?: string | null
  currency?: string | null
  hourlyRate?: number | null
  facet?: UserFacet | null
  industries: string[]
  languages: string[]
  pronoun: UserPronoun
  isSaas: boolean
  hasPoolWorkspace: boolean
  isOnPause: boolean
  startDate?: string | null
  isArchived: boolean
  category?: UserCategory | null
  principalUser?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  invoicedUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  csm?: {
    __typename?: 'ExecutivableCsm'
    id: string
    source:
      | { __typename?: 'AssistantUser' }
      | { __typename?: 'ExecutiveUser' }
      | { __typename?: 'HqUser' }
      | { __typename?: 'SandboxUser' }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
    user: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }
  } | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  accounts: {
    __typename?: 'UserAccount'
    key: string
    type: UserAccountType
    id: string
    isPrimary: boolean
    label: string
    scopes: string[]
    isDisconnected: boolean
    profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
  }[]
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      subscription?: {
        __typename?: 'TeamSubscription'
        id: string
        type: TeamSubscriptionType
        productName?: string | null
        productPrice?: number | null
        state?: SubscriptionState | null
        maxioUrl?: string | null
        customerDisplayName?: string | null
        currentBillingCycle?: {
          __typename?: 'SubscriptionBillingCycle'
          startDate: string | Date
          endDate: string | Date
          hours: number
        } | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
      formatted?: string | null
    } | null
  } | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
  } | null
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
    year?: number | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type FullUserFragment =
  | FullUser_AssistantUser_Fragment
  | FullUser_ExecutiveUser_Fragment
  | FullUser_HqUser_Fragment
  | FullUser_SandboxUser_Fragment

export type EntityPricingFragment = {
  __typename?: 'EntityPricing'
  id: string
  selectedAt: string | Date
  activeAt: string | Date
  expireAt?: string | Date | null
  notepad: string
  pricing: {
    __typename?: 'Pricing'
    id: string
    ref: string
    label: string
    description?: string | null
    priceDescription?: string | null
    hoursDescription?: string | null
    sellingPoints: string[]
    sellingPointsNotice?: string | null
    learnMoreUrl?: string | null
    isPublic: boolean
    isAvailable: boolean
    isSharable: boolean
    instantInviteMaxUsers: number
    model?: PricingModel | null
    modelConfig: Record<string, any>
    includesTeamFeatures: boolean
    includesPremiumPerks: boolean
  }
  owner:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'Team'
        id: string
        name?: string | null
        iconUrl?: string | null
        isArchived: boolean
      }
}

type Executivable_PricingSelections_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type Executivable_PricingSelections_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type Executivable_PricingSelections_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

export type Executivable_PricingSelectionsFragment =
  | Executivable_PricingSelections_ExecutiveUser_Fragment
  | Executivable_PricingSelections_HqUser_Fragment
  | Executivable_PricingSelections_SandboxUser_Fragment

export type StripeCustomer_PaymentStatusFragment = {
  __typename?: 'StripeCustomer'
  id: string
  paymentStatus: StripeCustomerPaymentStatus
}

export type StripeInvoiceFragment = {
  __typename?: 'StripeInvoice'
  id: string
  description?: string | null
  amount: number
  currency: string
  status: StripeInvoiceStatus
  createdAt: string | Date
  periodStartAt?: string | Date | null
  periodEndAt?: string | Date | null
  lineItems: {
    __typename?: 'StripeInvoiceLineItem'
    id: string
    amount: number
    currency: string
    description?: string | null
  }[]
}

export type StripeCustomer_InvoicesFragment = {
  __typename?: 'StripeCustomer'
  id: string
  invoices: {
    __typename?: 'StripeInvoice'
    id: string
    description?: string | null
    amount: number
    currency: string
    status: StripeInvoiceStatus
    createdAt: string | Date
    periodStartAt?: string | Date | null
    periodEndAt?: string | Date | null
    lineItems: {
      __typename?: 'StripeInvoiceLineItem'
      id: string
      amount: number
      currency: string
      description?: string | null
    }[]
  }[]
}

type Executivable_StripeCustomerId_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
}

type Executivable_StripeCustomerId_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
}

type Executivable_StripeCustomerId_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
}

export type Executivable_StripeCustomerIdFragment =
  | Executivable_StripeCustomerId_ExecutiveUser_Fragment
  | Executivable_StripeCustomerId_HqUser_Fragment
  | Executivable_StripeCustomerId_SandboxUser_Fragment

type Executivable_StripeCustomer_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

type Executivable_StripeCustomer_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

type Executivable_StripeCustomer_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

export type Executivable_StripeCustomerFragment =
  | Executivable_StripeCustomer_ExecutiveUser_Fragment
  | Executivable_StripeCustomer_HqUser_Fragment
  | Executivable_StripeCustomer_SandboxUser_Fragment

type BillableEntity_PricingSelections_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type BillableEntity_PricingSelections_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type BillableEntity_PricingSelections_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type BillableEntity_PricingSelections_Team_Fragment = {
  __typename?: 'Team'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

export type BillableEntity_PricingSelectionsFragment =
  | BillableEntity_PricingSelections_ExecutiveUser_Fragment
  | BillableEntity_PricingSelections_HqUser_Fragment
  | BillableEntity_PricingSelections_SandboxUser_Fragment
  | BillableEntity_PricingSelections_Team_Fragment

type BillableEntity_StripeCustomer_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

type BillableEntity_StripeCustomer_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

type BillableEntity_StripeCustomer_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

type BillableEntity_StripeCustomer_Team_Fragment = {
  __typename?: 'Team'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

export type BillableEntity_StripeCustomerFragment =
  | BillableEntity_StripeCustomer_ExecutiveUser_Fragment
  | BillableEntity_StripeCustomer_HqUser_Fragment
  | BillableEntity_StripeCustomer_SandboxUser_Fragment
  | BillableEntity_StripeCustomer_Team_Fragment

type Assistantable_PrimaryEmail_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  primaryEmail?: string | null
}

type Assistantable_PrimaryEmail_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  primaryEmail?: string | null
}

type Assistantable_PrimaryEmail_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  primaryEmail?: string | null
}

export type Assistantable_PrimaryEmailFragment =
  | Assistantable_PrimaryEmail_AssistantUser_Fragment
  | Assistantable_PrimaryEmail_HqUser_Fragment
  | Assistantable_PrimaryEmail_SandboxUser_Fragment

type Assistantable_ContractorData_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  ein?: string | null
  justworksVendorName?: string | null
  justworksGivenName?: string | null
  justworksFamilyName?: string | null
  personalEmail?: string | null
}

type Assistantable_ContractorData_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  ein?: string | null
  justworksVendorName?: string | null
  justworksGivenName?: string | null
  justworksFamilyName?: string | null
  personalEmail?: string | null
}

type Assistantable_ContractorData_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  ein?: string | null
  justworksVendorName?: string | null
  justworksGivenName?: string | null
  justworksFamilyName?: string | null
  personalEmail?: string | null
}

export type Assistantable_ContractorDataFragment =
  | Assistantable_ContractorData_AssistantUser_Fragment
  | Assistantable_ContractorData_HqUser_Fragment
  | Assistantable_ContractorData_SandboxUser_Fragment

type Assistantable_AssistantData_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  biography?: string | null
  funFacts?: string | null
  skills: string[]
  mobileOs?: MobileOs | null
  leverId?: string | null
  yearsOfExperience?: number | null
  slackUsername?: string | null
  slackUserId?: string | null
  hasSlackPush: boolean
  personalEmail?: string | null
  schedulingUrl?: string | null
  primaryEmail?: string | null
  hourlyRate?: number | null
  currency?: string | null
  ein?: string | null
  justworksVendorName?: string | null
  justworksGivenName?: string | null
  justworksFamilyName?: string | null
}

type Assistantable_AssistantData_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  biography?: string | null
  funFacts?: string | null
  skills: string[]
  mobileOs?: MobileOs | null
  leverId?: string | null
  yearsOfExperience?: number | null
  slackUsername?: string | null
  slackUserId?: string | null
  hasSlackPush: boolean
  personalEmail?: string | null
  schedulingUrl?: string | null
  primaryEmail?: string | null
  hourlyRate?: number | null
  currency?: string | null
  ein?: string | null
  justworksVendorName?: string | null
  justworksGivenName?: string | null
  justworksFamilyName?: string | null
}

type Assistantable_AssistantData_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  biography?: string | null
  funFacts?: string | null
  skills: string[]
  mobileOs?: MobileOs | null
  leverId?: string | null
  yearsOfExperience?: number | null
  slackUsername?: string | null
  slackUserId?: string | null
  hasSlackPush: boolean
  personalEmail?: string | null
  schedulingUrl?: string | null
  primaryEmail?: string | null
  hourlyRate?: number | null
  currency?: string | null
  ein?: string | null
  justworksVendorName?: string | null
  justworksGivenName?: string | null
  justworksFamilyName?: string | null
}

export type Assistantable_AssistantDataFragment =
  | Assistantable_AssistantData_AssistantUser_Fragment
  | Assistantable_AssistantData_HqUser_Fragment
  | Assistantable_AssistantData_SandboxUser_Fragment

export type FeatureFragment = {
  __typename?: 'Feature'
  id: string
  description: string
  appVersions: Record<string, any>
  targets: {
    __typename?: 'FeatureTarget'
    predicates: (
      | {
          __typename: 'FeatureTargetAssistantPredicate'
          assistantIds: string[]
          isNegated: boolean
        }
      | { __typename: 'FeatureTargetConstantPredicate'; constant: boolean }
      | {
          __typename: 'FeatureTargetCreatedAtPredicate'
          createdAfter: string | Date
          isNegated: boolean
        }
      | {
          __typename: 'FeatureTargetExecutivePredicate'
          executiveIds: string[]
          isNegated: boolean
        }
      | {
          __typename: 'FeatureTargetFeaturePredicate'
          featureId: string
          isNegated: boolean
        }
    )[]
  }[]
}

export type TopicFormFieldValueConditionFragment = {
  __typename?: 'TopicFormFieldValueCondition'
  fieldId: string
  value:
    | { __typename?: 'TopicExtendedPropValueAudio' }
    | { __typename?: 'TopicExtendedPropValueNumber' }
    | { __typename?: 'TopicExtendedPropValueText'; text: string }
}

export type TopicFormFieldOptionTextFragment = {
  __typename?: 'TopicFormFieldOptionText'
  text: string
  tags: TopicFormFieldOptionTag[]
}

export type TopicFormFieldFragment = {
  __typename?: 'TopicFormFieldText'
  isAllowingAudio: boolean
  isAllowingFreeForm: boolean
  freeFormPlaceholder?: string | null
  id: string
  question: string
  message?: string | null
  imageUrl?: string | null
  isRequired?: boolean | null
  airtableClientField?: string | null
  maxValues?: number | null
  options: {
    __typename?: 'TopicFormFieldOptionText'
    text: string
    tags: TopicFormFieldOptionTag[]
  }[]
  enabledIf?:
    | {
        __typename?: 'TopicFormFieldValueCondition'
        fieldId: string
        value:
          | { __typename?: 'TopicExtendedPropValueAudio' }
          | { __typename?: 'TopicExtendedPropValueNumber' }
          | { __typename?: 'TopicExtendedPropValueText'; text: string }
      }
    | {
        __typename?: 'TopicFormOperatorCondition'
        operator: TopicFormOperator
        conditions: (
          | {
              __typename?: 'TopicFormFieldValueCondition'
              fieldId: string
              value:
                | { __typename?: 'TopicExtendedPropValueAudio' }
                | { __typename?: 'TopicExtendedPropValueNumber' }
                | { __typename?: 'TopicExtendedPropValueText'; text: string }
            }
          | {
              __typename?: 'TopicFormOperatorCondition'
              operator: TopicFormOperator
              conditions: (
                | {
                    __typename?: 'TopicFormFieldValueCondition'
                    fieldId: string
                    value:
                      | { __typename?: 'TopicExtendedPropValueAudio' }
                      | { __typename?: 'TopicExtendedPropValueNumber' }
                      | {
                          __typename?: 'TopicExtendedPropValueText'
                          text: string
                        }
                  }
                | { __typename?: 'TopicFormOperatorCondition' }
              )[]
            }
        )[]
      }
    | null
}

export type TopicFormFragment = {
  __typename?: 'TopicForm'
  id: string
  ref?: string | null
  fields: {
    __typename?: 'TopicFormFieldText'
    isAllowingAudio: boolean
    isAllowingFreeForm: boolean
    freeFormPlaceholder?: string | null
    id: string
    question: string
    message?: string | null
    imageUrl?: string | null
    isRequired?: boolean | null
    airtableClientField?: string | null
    maxValues?: number | null
    options: {
      __typename?: 'TopicFormFieldOptionText'
      text: string
      tags: TopicFormFieldOptionTag[]
    }[]
    enabledIf?:
      | {
          __typename?: 'TopicFormFieldValueCondition'
          fieldId: string
          value:
            | { __typename?: 'TopicExtendedPropValueAudio' }
            | { __typename?: 'TopicExtendedPropValueNumber' }
            | { __typename?: 'TopicExtendedPropValueText'; text: string }
        }
      | {
          __typename?: 'TopicFormOperatorCondition'
          operator: TopicFormOperator
          conditions: (
            | {
                __typename?: 'TopicFormFieldValueCondition'
                fieldId: string
                value:
                  | { __typename?: 'TopicExtendedPropValueAudio' }
                  | { __typename?: 'TopicExtendedPropValueNumber' }
                  | { __typename?: 'TopicExtendedPropValueText'; text: string }
              }
            | {
                __typename?: 'TopicFormOperatorCondition'
                operator: TopicFormOperator
                conditions: (
                  | {
                      __typename?: 'TopicFormFieldValueCondition'
                      fieldId: string
                      value:
                        | { __typename?: 'TopicExtendedPropValueAudio' }
                        | { __typename?: 'TopicExtendedPropValueNumber' }
                        | {
                            __typename?: 'TopicExtendedPropValueText'
                            text: string
                          }
                    }
                  | { __typename?: 'TopicFormOperatorCondition' }
                )[]
              }
          )[]
        }
      | null
  }[]
}

export type TopicTemplateFragment = {
  __typename?: 'TopicTemplate'
  id: string
  type: TopicTemplateType
  category: DiscoverCategory
  sectionKey: DiscoverSectionKey
  title: string
  description: string
  imageUrl?: string | null
  instructions: string
  introduction?: string | null
  introductionTitle?: string | null
  introductionImageUrl?: string | null
  isDraft: boolean
  isNew: boolean
  keywords: string[]
  labels: string[]
  privateTo?: WorkspaceUserRole | null
  notepad?: string | null
  assigneeRole?: WorkspaceUserRole | null
  form?: {
    __typename?: 'TopicForm'
    id: string
    ref?: string | null
    fields: {
      __typename?: 'TopicFormFieldText'
      isAllowingAudio: boolean
      isAllowingFreeForm: boolean
      freeFormPlaceholder?: string | null
      id: string
      question: string
      message?: string | null
      imageUrl?: string | null
      isRequired?: boolean | null
      airtableClientField?: string | null
      maxValues?: number | null
      options: {
        __typename?: 'TopicFormFieldOptionText'
        text: string
        tags: TopicFormFieldOptionTag[]
      }[]
      enabledIf?:
        | {
            __typename?: 'TopicFormFieldValueCondition'
            fieldId: string
            value:
              | { __typename?: 'TopicExtendedPropValueAudio' }
              | { __typename?: 'TopicExtendedPropValueNumber' }
              | { __typename?: 'TopicExtendedPropValueText'; text: string }
          }
        | {
            __typename?: 'TopicFormOperatorCondition'
            operator: TopicFormOperator
            conditions: (
              | {
                  __typename?: 'TopicFormFieldValueCondition'
                  fieldId: string
                  value:
                    | { __typename?: 'TopicExtendedPropValueAudio' }
                    | { __typename?: 'TopicExtendedPropValueNumber' }
                    | {
                        __typename?: 'TopicExtendedPropValueText'
                        text: string
                      }
                }
              | {
                  __typename?: 'TopicFormOperatorCondition'
                  operator: TopicFormOperator
                  conditions: (
                    | {
                        __typename?: 'TopicFormFieldValueCondition'
                        fieldId: string
                        value:
                          | { __typename?: 'TopicExtendedPropValueAudio' }
                          | { __typename?: 'TopicExtendedPropValueNumber' }
                          | {
                              __typename?: 'TopicExtendedPropValueText'
                              text: string
                            }
                      }
                    | { __typename?: 'TopicFormOperatorCondition' }
                  )[]
                }
            )[]
          }
        | null
    }[]
  } | null
}

export type CsatFeedbackFragment = {
  __typename?: 'CsatFeedback'
  id: string
  rating: number
  tags: string[]
  commentText?: string | null
  createdAt: string | Date
  assistant:
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  workspace?: { __typename?: 'Workspace'; id: string } | null
}

export type MinimalWhoFragment = {
  __typename?: 'Who'
  id: string
  avatarUrl?: string | null
  givenName: string
}

export type BasicWhoFragment = {
  __typename?: 'Who'
  category: UserCategory
  startDate?: string | null
  biography?: string | null
  funFacts?: string | null
  position?: string | null
  skills: string[]
  languages: string[]
  id: string
  avatarUrl?: string | null
  givenName: string
  birthday?: {
    __typename?: 'AnniversaryDate'
    day?: number | null
    month: number
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
    geo?: {
      __typename?: 'PlaceGeography'
      coordinates: { __typename?: 'GeoCoordinates'; lat: number; lng: number }
    } | null
  } | null
  assistant?: {
    __typename?: 'Who'
    id: string
    avatarUrl?: string | null
    givenName: string
  } | null
}

export type GetUserFieldsOptionsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserFieldsOptionsQuery = {
  __typename?: 'Query'
  userFieldsOptions: {
    __typename?: 'UserFieldsOptions'
    industries: string[]
    assistantSkills: string[]
    personalityTraits: string[]
    tools: string[]
  }
}

export type ListUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  q?: InputMaybe<Scalars['String']>
  isSaas?: InputMaybe<Scalars['Boolean']>
  isArchived?: InputMaybe<Scalars['Boolean']>
  categories?: InputMaybe<UserCategory[] | UserCategory>
  adminRoles?: InputMaybe<Scalars['String'][] | Scalars['String']>
  hasSuccessManager?: InputMaybe<Scalars['Boolean']>
  successManagerId?: InputMaybe<Scalars['ID']>
  invoicedToUserId?: InputMaybe<Scalars['ID']>
  industries?: InputMaybe<Scalars['String'][] | Scalars['String']>
  tools?: InputMaybe<Scalars['String'][] | Scalars['String']>
  userIds?: InputMaybe<Scalars['ID'][] | Scalars['ID']>
}>

export type ListUsersQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'UsersList'
    after?: string | null
    items: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
  }
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetUserQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        phoneNumber?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        csatAverage?: number | null
        performanceScoreValue?: number | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        pendingChurnAutomationJob?: {
          __typename?: 'AutomationJob'
          id: string
          delayUntil: string | Date
          createdAt: string | Date
          startedAt?: string | Date | null
          endedAt?: string | Date | null
          cancelledAt?: string | Date | null
          updatedAt: string | Date
          reviewedAt?: string | Date | null
          hasError: boolean
          workspace?: {
            __typename?: 'Workspace'
            id: string
            isPendingTransition: boolean
            hasCoverage: boolean
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          } | null
          user?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          executive?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          assistant?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          automation: { __typename?: 'Automation'; id: string; name: string }
        } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        desiredSkills: string[]
        personalityTraits: string[]
        acceptedPptcAt?: string | Date | null
        id: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        pendingChurnAutomationJob?: {
          __typename?: 'AutomationJob'
          id: string
          delayUntil: string | Date
          createdAt: string | Date
          startedAt?: string | Date | null
          endedAt?: string | Date | null
          cancelledAt?: string | Date | null
          updatedAt: string | Date
          reviewedAt?: string | Date | null
          hasError: boolean
          workspace?: {
            __typename?: 'Workspace'
            id: string
            isPendingTransition: boolean
            hasCoverage: boolean
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          } | null
          user?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          executive?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          assistant?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          automation: { __typename?: 'Automation'; id: string; name: string }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        pendingChurnAutomationJob?: {
          __typename?: 'AutomationJob'
          id: string
          delayUntil: string | Date
          createdAt: string | Date
          startedAt?: string | Date | null
          endedAt?: string | Date | null
          cancelledAt?: string | Date | null
          updatedAt: string | Date
          reviewedAt?: string | Date | null
          hasError: boolean
          workspace?: {
            __typename?: 'Workspace'
            id: string
            isPendingTransition: boolean
            hasCoverage: boolean
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          } | null
          user?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          executive?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          assistant?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          automation: { __typename?: 'Automation'; id: string; name: string }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        pendingChurnAutomationJob?: {
          __typename?: 'AutomationJob'
          id: string
          delayUntil: string | Date
          createdAt: string | Date
          startedAt?: string | Date | null
          endedAt?: string | Date | null
          cancelledAt?: string | Date | null
          updatedAt: string | Date
          reviewedAt?: string | Date | null
          hasError: boolean
          workspace?: {
            __typename?: 'Workspace'
            id: string
            isPendingTransition: boolean
            hasCoverage: boolean
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          } | null
          user?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          executive?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          assistant?:
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          automation: { __typename?: 'Automation'; id: string; name: string }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type GetWorkspaceUserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetWorkspaceUserQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type GetUserNavigationQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetUserNavigationQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type GetUserProfileQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetUserProfileQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        facet?: UserFacet | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type GetUserBillingInfoQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetUserBillingInfoQuery = {
  __typename?: 'Query'
  user:
    | { __typename?: 'AssistantUser' }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        hoursLimit?: number | null
        isAllowedPersonal?: boolean | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        principalUser?:
          | { __typename?: 'ExecutiveUser'; id: string }
          | { __typename?: 'HqUser'; id: string }
          | { __typename?: 'SandboxUser'; id: string }
          | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        pricingSelections: {
          __typename?: 'PricingSelection'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing?: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          } | null
        }[]
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          }
          owner:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
        } | null
      }
    | {
        __typename?: 'HqUser'
        id: string
        hoursLimit?: number | null
        isAllowedPersonal?: boolean | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        principalUser?:
          | { __typename?: 'ExecutiveUser'; id: string }
          | { __typename?: 'HqUser'; id: string }
          | { __typename?: 'SandboxUser'; id: string }
          | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        pricingSelections: {
          __typename?: 'PricingSelection'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing?: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          } | null
        }[]
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          }
          owner:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
        } | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        hoursLimit?: number | null
        isAllowedPersonal?: boolean | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        principalUser?:
          | { __typename?: 'ExecutiveUser'; id: string }
          | { __typename?: 'HqUser'; id: string }
          | { __typename?: 'SandboxUser'; id: string }
          | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        pricingSelections: {
          __typename?: 'PricingSelection'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing?: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          } | null
        }[]
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          }
          owner:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
        } | null
      }
  dependentUsersList: {
    __typename?: 'UsersList'
    items: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
  }
}

type ExecutivableBilling_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  hoursLimit?: number | null
  isAllowedPersonal?: boolean | null
  invoicedUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  principalUser?:
    | { __typename?: 'ExecutiveUser'; id: string }
    | { __typename?: 'HqUser'; id: string }
    | { __typename?: 'SandboxUser'; id: string }
    | null
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type ExecutivableBilling_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  hoursLimit?: number | null
  isAllowedPersonal?: boolean | null
  invoicedUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  principalUser?:
    | { __typename?: 'ExecutiveUser'; id: string }
    | { __typename?: 'HqUser'; id: string }
    | { __typename?: 'SandboxUser'; id: string }
    | null
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

type ExecutivableBilling_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  hoursLimit?: number | null
  isAllowedPersonal?: boolean | null
  invoicedUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  principalUser?:
    | { __typename?: 'ExecutiveUser'; id: string }
    | { __typename?: 'HqUser'; id: string }
    | { __typename?: 'SandboxUser'; id: string }
    | null
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

export type ExecutivableBillingFragment =
  | ExecutivableBilling_ExecutiveUser_Fragment
  | ExecutivableBilling_HqUser_Fragment
  | ExecutivableBilling_SandboxUser_Fragment

export type GetAssistantOnboardingAvailabilityQueryVariables = Exact<{
  assistantId: Scalars['ID']
}>

export type GetAssistantOnboardingAvailabilityQuery = {
  __typename?: 'Query'
  assistant:
    | {
        __typename?: 'AssistantUser'
        onboardingAvailability: {
          __typename?: 'OnboardingAvailability'
          id: string
          slots: {
            __typename?: 'OnboardingAvailabilitySlot'
            startAt: string | Date
            endAt: string | Date
          }[]
        }
      }
    | { __typename?: 'ExecutiveUser' }
    | {
        __typename?: 'HqUser'
        onboardingAvailability: {
          __typename?: 'OnboardingAvailability'
          id: string
          slots: {
            __typename?: 'OnboardingAvailabilitySlot'
            startAt: string | Date
            endAt: string | Date
          }[]
        }
      }
    | {
        __typename?: 'SandboxUser'
        onboardingAvailability: {
          __typename?: 'OnboardingAvailability'
          id: string
          slots: {
            __typename?: 'OnboardingAvailabilitySlot'
            startAt: string | Date
            endAt: string | Date
          }[]
        }
      }
}

export type GetAssistantQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetAssistantQuery = {
  __typename?: 'Query'
  assistant:
    | {
        __typename?: 'AssistantUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
    | { __typename?: 'ExecutiveUser' }
    | {
        __typename?: 'HqUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
}

export type GetAssistantHourlyRateUpdatesQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetAssistantHourlyRateUpdatesQuery = {
  __typename?: 'Query'
  assistant:
    | {
        __typename?: 'AssistantUser'
        id: string
        hourlyRateUpdates?:
          | {
              __typename?: 'AssistantableHourlyRateUpdate'
              hourlyRate: number
              updatedAt: string | Date
              updatedBy?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
            }[]
          | null
      }
    | { __typename?: 'ExecutiveUser' }
    | {
        __typename?: 'HqUser'
        id: string
        hourlyRateUpdates?:
          | {
              __typename?: 'AssistantableHourlyRateUpdate'
              hourlyRate: number
              updatedAt: string | Date
              updatedBy?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
            }[]
          | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        hourlyRateUpdates?:
          | {
              __typename?: 'AssistantableHourlyRateUpdate'
              hourlyRate: number
              updatedAt: string | Date
              updatedBy?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
            }[]
          | null
      }
}

export type ClearAssistantHourlyRateUpdatesMutationVariables = Exact<{
  [key: string]: never
}>

export type ClearAssistantHourlyRateUpdatesMutation = {
  __typename?: 'Mutation'
  clearAssistantHourlyRateUpdates: {
    __typename?: 'ClearAssistantHourlyRateUpdatesOutput'
    isSuccess: boolean
  }
}

export type GetWhoQueryVariables = Exact<{
  userId: Scalars['GUID']
}>

export type GetWhoQuery = {
  __typename?: 'Query'
  who: {
    __typename?: 'Who'
    category: UserCategory
    startDate?: string | null
    biography?: string | null
    funFacts?: string | null
    position?: string | null
    skills: string[]
    languages: string[]
    id: string
    avatarUrl?: string | null
    givenName: string
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
      geo?: {
        __typename?: 'PlaceGeography'
        coordinates: { __typename?: 'GeoCoordinates'; lat: number; lng: number }
      } | null
    } | null
    assistant?: {
      __typename?: 'Who'
      id: string
      avatarUrl?: string | null
      givenName: string
    } | null
  }
}

export type ListWhosQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  q?: InputMaybe<Scalars['String']>
}>

export type ListWhosQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'WhosList'
    after?: string | null
    items: {
      __typename?: 'Who'
      category: UserCategory
      startDate?: string | null
      biography?: string | null
      funFacts?: string | null
      position?: string | null
      skills: string[]
      languages: string[]
      id: string
      avatarUrl?: string | null
      givenName: string
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
        geo?: {
          __typename?: 'PlaceGeography'
          coordinates: {
            __typename?: 'GeoCoordinates'
            lat: number
            lng: number
          }
        } | null
      } | null
      assistant?: {
        __typename?: 'Who'
        id: string
        avatarUrl?: string | null
        givenName: string
      } | null
    }[]
  }
}

export type CreateExecutiveWorkspaceMutationVariables = Exact<{
  input: CreateExecutiveWorkspaceInput
}>

export type CreateExecutiveWorkspaceMutation = {
  __typename?: 'Mutation'
  createExecutiveWorkspace: {
    __typename?: 'CreateExecutiveWorkspaceOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }
    executive:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'HqUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'HqUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | null
  }
}

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput
}>

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation'
  updateWorkspace: {
    __typename?: 'UpdateWorkspaceOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
    }
  }
}

export type ArchiveWorkspaceMutationVariables = Exact<{
  input: ArchiveWorkspaceInput
}>

export type ArchiveWorkspaceMutation = {
  __typename?: 'Mutation'
  archiveWorkspace: {
    __typename?: 'ArchiveWorkspaceOutput'
    isSuccess: boolean
  }
}

export type LinkWorkspaceUserAccountMutationVariables = Exact<{
  workspaceId: Scalars['ID']
  userId: Scalars['ID']
  accountId: Scalars['ID']
}>

export type LinkWorkspaceUserAccountMutation = {
  __typename?: 'Mutation'
  linkWorkspaceUserAccount: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }
}

export type UnlinkWorkspaceUserAccountMutationVariables = Exact<{
  workspaceId: Scalars['ID']
  userId: Scalars['ID']
  accountId: Scalars['ID']
}>

export type UnlinkWorkspaceUserAccountMutation = {
  __typename?: 'Mutation'
  unlinkWorkspaceUserAccount: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }
}

export type AddWorkspaceAssistantMutationVariables = Exact<{
  input: AddWorkspaceAssistantInput
}>

export type AddWorkspaceAssistantMutation = {
  __typename?: 'Mutation'
  addWorkspaceAssistant: {
    __typename?: 'MutateWorkspaceUserOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }
    user:
      | {
          __typename?: 'AssistantUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'HqUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
  }
}

export type RemoveWorkspaceUserMutationVariables = Exact<{
  input: RemoveWorkspaceUserInput
}>

export type RemoveWorkspaceUserMutation = {
  __typename?: 'Mutation'
  removeWorkspaceUser: {
    __typename?: 'MutateWorkspaceUserOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }
    user:
      | {
          __typename?: 'AssistantUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'HqUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
  }
}

export type SpectateWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type SpectateWorkspaceMutation = {
  __typename?: 'Mutation'
  spectateWorkspace: {
    __typename?: 'SpectateWorkspaceOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }
    user:
      | {
          __typename?: 'AssistantUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
      | {
          __typename?: 'HqUser'
          id: string
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
        }
  }
}

export type AddUserAccountMutationVariables = Exact<{
  userId: Scalars['ID']
  input: AddUserAccountInput
}>

export type AddUserAccountMutation = {
  __typename?: 'Mutation'
  addUserAccount:
    | {
        __typename?: 'AssistantUser'
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        phoneNumber?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        csatAverage?: number | null
        performanceScoreValue?: number | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        desiredSkills: string[]
        personalityTraits: string[]
        acceptedPptcAt?: string | Date | null
        id: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type DeleteUserAccountMutationVariables = Exact<{
  input: DeleteUserAccountInput
}>

export type DeleteUserAccountMutation = {
  __typename?: 'Mutation'
  deleteUserAccount:
    | {
        __typename?: 'AssistantUser'
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        phoneNumber?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        csatAverage?: number | null
        performanceScoreValue?: number | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        desiredSkills: string[]
        personalityTraits: string[]
        acceptedPptcAt?: string | Date | null
        id: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type SelectPrimaryUserAccountMutationVariables = Exact<{
  input: SelectPrimaryUserAccountInput
}>

export type SelectPrimaryUserAccountMutation = {
  __typename?: 'Mutation'
  selectPrimaryUserAccount:
    | {
        __typename?: 'AssistantUser'
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        phoneNumber?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        csatAverage?: number | null
        performanceScoreValue?: number | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        desiredSkills: string[]
        personalityTraits: string[]
        acceptedPptcAt?: string | Date | null
        id: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type CreateAssistantMutationVariables = Exact<{
  input: CreateAssistantInput
}>

export type CreateAssistantMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'AssistantUser'
    personalityTraits: string[]
    workTimeZones?: string[] | null
    matchingNotepad?: string | null
    phoneNumber?: string | null
    acceptedPptcAt?: string | Date | null
    id: string
    interestedInIndustries: string[]
    experienceInTools: string[]
    skillsetRating?: Record<string, any> | null
    isOpenToMatch?: boolean | null
    isOpenToSupport?: boolean | null
    openToUpdatedAt?: string | Date | null
    targetTotalWeeklyHours?: string | null
    targetAdditionalWeeklyHours?: string | null
    experienceHighlights?: string | null
    englishLevel?: CefrScale | null
    resumeUrl: string
    csatAverage?: number | null
    performanceScoreValue?: number | null
    airtableDoubleRecordId?: string | null
    currency?: string | null
    hourlyRate?: number | null
    industries: string[]
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    internalDisc?: {
      __typename?: 'DiscScore'
      d: number
      i: number
      s: number
      c: number
    } | null
    externalDisc?: {
      __typename?: 'DiscScore'
      d: number
      i: number
      s: number
      c: number
    } | null
    accounts: {
      __typename?: 'UserAccount'
      key: string
      type: UserAccountType
      id: string
      isPrimary: boolean
      label: string
      scopes: string[]
      isDisconnected: boolean
      profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
    }[]
    workspaces: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      team?: {
        __typename?: 'Team'
        id: string
        name?: string | null
        iconUrl?: string | null
        isArchived: boolean
        principalUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        subscription?: {
          __typename?: 'TeamSubscription'
          id: string
          type: TeamSubscriptionType
          productName?: string | null
          productPrice?: number | null
          state?: SubscriptionState | null
          maxioUrl?: string | null
          customerDisplayName?: string | null
          currentBillingCycle?: {
            __typename?: 'SubscriptionBillingCycle'
            startDate: string | Date
            endDate: string | Date
            hours: number
          } | null
        } | null
      } | null
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        countryCode?: string | null
        formatted?: string | null
      } | null
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }
}

export type CreateExecutiveMutationVariables = Exact<{
  input: CreateExecutiveInput
}>

export type CreateExecutiveMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'ExecutiveUser'
    desiredSkills: string[]
    personalityTraits: string[]
    acceptedPptcAt?: string | Date | null
    id: string
    airtableClientRecordId?: string | null
    airtableLeadRecordId?: string | null
    facet?: UserFacet | null
    industries: string[]
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    principalUser?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    invoicedUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    csm?: {
      __typename?: 'ExecutivableCsm'
      id: string
      source:
        | { __typename?: 'AssistantUser' }
        | { __typename?: 'ExecutiveUser' }
        | { __typename?: 'HqUser' }
        | { __typename?: 'SandboxUser' }
        | {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
          }
      user: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    } | null
    stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
    accounts: {
      __typename?: 'UserAccount'
      key: string
      type: UserAccountType
      id: string
      isPrimary: boolean
      label: string
      scopes: string[]
      isDisconnected: boolean
      profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
    }[]
    workspaces: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      team?: {
        __typename?: 'Team'
        id: string
        name?: string | null
        iconUrl?: string | null
        isArchived: boolean
        principalUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        subscription?: {
          __typename?: 'TeamSubscription'
          id: string
          type: TeamSubscriptionType
          productName?: string | null
          productPrice?: number | null
          state?: SubscriptionState | null
          maxioUrl?: string | null
          customerDisplayName?: string | null
          currentBillingCycle?: {
            __typename?: 'SubscriptionBillingCycle'
            startDate: string | Date
            endDate: string | Date
            hours: number
          } | null
        } | null
      } | null
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        formatted?: string | null
      } | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }
}

export type CreateHqMutationVariables = Exact<{
  input: CreateHqInput
}>

export type CreateHqMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'HqUser'
    desiredSkills: string[]
    personalityTraits: string[]
    workTimeZones?: string[] | null
    matchingNotepad?: string | null
    acceptedPptcAt?: string | Date | null
    id: string
    interestedInIndustries: string[]
    experienceInTools: string[]
    skillsetRating?: Record<string, any> | null
    isOpenToMatch?: boolean | null
    isOpenToSupport?: boolean | null
    openToUpdatedAt?: string | Date | null
    targetTotalWeeklyHours?: string | null
    targetAdditionalWeeklyHours?: string | null
    experienceHighlights?: string | null
    englishLevel?: CefrScale | null
    resumeUrl: string
    airtableClientRecordId?: string | null
    airtableLeadRecordId?: string | null
    airtableDoubleRecordId?: string | null
    currency?: string | null
    hourlyRate?: number | null
    facet?: UserFacet | null
    position?: string | null
    industries: string[]
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    principalUser?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    invoicedUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    csm?: {
      __typename?: 'ExecutivableCsm'
      id: string
      source:
        | { __typename?: 'AssistantUser' }
        | { __typename?: 'ExecutiveUser' }
        | { __typename?: 'HqUser' }
        | { __typename?: 'SandboxUser' }
        | {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
          }
      user: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    } | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
    internalDisc?: {
      __typename?: 'DiscScore'
      d: number
      i: number
      s: number
      c: number
    } | null
    externalDisc?: {
      __typename?: 'DiscScore'
      d: number
      i: number
      s: number
      c: number
    } | null
    accounts: {
      __typename?: 'UserAccount'
      key: string
      type: UserAccountType
      id: string
      isPrimary: boolean
      label: string
      scopes: string[]
      isDisconnected: boolean
      profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
    }[]
    workspaces: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      team?: {
        __typename?: 'Team'
        id: string
        name?: string | null
        iconUrl?: string | null
        isArchived: boolean
        principalUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        subscription?: {
          __typename?: 'TeamSubscription'
          id: string
          type: TeamSubscriptionType
          productName?: string | null
          productPrice?: number | null
          state?: SubscriptionState | null
          maxioUrl?: string | null
          customerDisplayName?: string | null
          currentBillingCycle?: {
            __typename?: 'SubscriptionBillingCycle'
            startDate: string | Date
            endDate: string | Date
            hours: number
          } | null
        } | null
      } | null
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        countryCode?: string | null
        formatted?: string | null
      } | null
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }
}

export type CreateSandboxMutationVariables = Exact<{
  input: CreateSandboxInput
}>

export type CreateSandboxMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'SandboxUser'
    desiredSkills: string[]
    personalityTraits: string[]
    workTimeZones?: string[] | null
    matchingNotepad?: string | null
    acceptedPptcAt?: string | Date | null
    id: string
    interestedInIndustries: string[]
    experienceInTools: string[]
    skillsetRating?: Record<string, any> | null
    isOpenToMatch?: boolean | null
    isOpenToSupport?: boolean | null
    openToUpdatedAt?: string | Date | null
    targetTotalWeeklyHours?: string | null
    targetAdditionalWeeklyHours?: string | null
    experienceHighlights?: string | null
    englishLevel?: CefrScale | null
    resumeUrl: string
    airtableClientRecordId?: string | null
    airtableLeadRecordId?: string | null
    airtableDoubleRecordId?: string | null
    currency?: string | null
    hourlyRate?: number | null
    facet?: UserFacet | null
    industries: string[]
    languages: string[]
    pronoun: UserPronoun
    isSaas: boolean
    hasPoolWorkspace: boolean
    isOnPause: boolean
    startDate?: string | null
    isArchived: boolean
    category?: UserCategory | null
    principalUser?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    invoicedUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    csm?: {
      __typename?: 'ExecutivableCsm'
      id: string
      source:
        | { __typename?: 'AssistantUser' }
        | { __typename?: 'ExecutiveUser' }
        | { __typename?: 'HqUser' }
        | { __typename?: 'SandboxUser' }
        | {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
          }
      user: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    } | null
    dsm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
    internalDisc?: {
      __typename?: 'DiscScore'
      d: number
      i: number
      s: number
      c: number
    } | null
    externalDisc?: {
      __typename?: 'DiscScore'
      d: number
      i: number
      s: number
      c: number
    } | null
    accounts: {
      __typename?: 'UserAccount'
      key: string
      type: UserAccountType
      id: string
      isPrimary: boolean
      label: string
      scopes: string[]
      isDisconnected: boolean
      profile: { __typename?: 'UserProfile'; id: string; email?: string | null }
    }[]
    workspaces: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      team?: {
        __typename?: 'Team'
        id: string
        name?: string | null
        iconUrl?: string | null
        isArchived: boolean
        principalUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        subscription?: {
          __typename?: 'TeamSubscription'
          id: string
          type: TeamSubscriptionType
          productName?: string | null
          productPrice?: number | null
          state?: SubscriptionState | null
          maxioUrl?: string | null
          customerDisplayName?: string | null
          currentBillingCycle?: {
            __typename?: 'SubscriptionBillingCycle'
            startDate: string | Date
            endDate: string | Date
            hours: number
          } | null
        } | null
      } | null
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
    city?: {
      __typename?: 'Place'
      id: string
      timeZone?: string | null
      googleMapsPlaceId?: string | null
      address?: {
        __typename?: 'PlaceAddress'
        id: string
        countryCode?: string | null
        formatted?: string | null
      } | null
    } | null
    orientation?: {
      __typename?: 'Orientation'
      id: string
      isCompleted: boolean
    } | null
    birthday?: {
      __typename?: 'AnniversaryDate'
      day?: number | null
      month: number
      year?: number | null
    } | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  }
}

export type ListEmailTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type ListEmailTemplatesQuery = {
  __typename?: 'Query'
  emailTemplates: { __typename?: 'EmailTemplate'; id: string; name: string }[]
}

export type GetEmailTemplateByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetEmailTemplateByIdQuery = {
  __typename?: 'Query'
  emailTemplate: {
    __typename?: 'EmailTemplate'
    id: string
    name: string
    subject: string
    html: string
    placeholders: string[]
  }
}

export type SendEmailFromTemplateMutationVariables = Exact<{
  input: SendEmailFromTemplateInput
}>

export type SendEmailFromTemplateMutation = {
  __typename?: 'Mutation'
  sendEmailFromTemplate: {
    __typename?: 'SendEmailFromTemplateOutput'
    isSuccess: boolean
  }
}

export type SendEmailMessageMutationVariables = Exact<{
  input: SendEmailMessageInput
}>

export type SendEmailMessageMutation = {
  __typename?: 'Mutation'
  sendEmailMessage: { __typename: 'SendEmailMessageOutput' }
}

export type GetEmailActivitiesQueryVariables = Exact<{
  dateAfter?: InputMaybe<Scalars['DateTime']>
  recipients?: InputMaybe<Scalars['EmailAddress'][] | Scalars['EmailAddress']>
  sendgridTemplateIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  messageTemplateIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
  workspaceId?: InputMaybe<Scalars['ID']>
  allOfTags?: InputMaybe<Scalars['String'][] | Scalars['String']>
  oneOfTags?: InputMaybe<Scalars['String'][] | Scalars['String']>
}>

export type GetEmailActivitiesQuery = {
  __typename?: 'Query'
  emailActivities: {
    __typename?: 'EmailActivity'
    id: string
    processedAt: string | Date
    deliveredAt?: string | Date | null
    opened: { __typename?: 'EmailActivityOpened'; at: string | Date }[]
    clicked: { __typename?: 'EmailActivityClicked'; at: string | Date }[]
    template?: { __typename?: 'EmailTemplate'; name: string } | null
  }[]
}

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ID']
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser:
    | {
        __typename?: 'AssistantUser'
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        phoneNumber?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        csatAverage?: number | null
        performanceScoreValue?: number | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        desiredSkills: string[]
        personalityTraits: string[]
        acceptedPptcAt?: string | Date | null
        id: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        desiredSkills: string[]
        personalityTraits: string[]
        workTimeZones?: string[] | null
        matchingNotepad?: string | null
        acceptedPptcAt?: string | Date | null
        id: string
        interestedInIndustries: string[]
        experienceInTools: string[]
        skillsetRating?: Record<string, any> | null
        isOpenToMatch?: boolean | null
        isOpenToSupport?: boolean | null
        openToUpdatedAt?: string | Date | null
        targetTotalWeeklyHours?: string | null
        targetAdditionalWeeklyHours?: string | null
        experienceHighlights?: string | null
        englishLevel?: CefrScale | null
        resumeUrl: string
        airtableClientRecordId?: string | null
        airtableLeadRecordId?: string | null
        airtableDoubleRecordId?: string | null
        currency?: string | null
        hourlyRate?: number | null
        facet?: UserFacet | null
        industries: string[]
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
                paymentStatus: StripeCustomerPaymentStatus
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
        internalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        externalDisc?: {
          __typename?: 'DiscScore'
          d: number
          i: number
          s: number
          c: number
        } | null
        accounts: {
          __typename?: 'UserAccount'
          key: string
          type: UserAccountType
          id: string
          isPrimary: boolean
          label: string
          scopes: string[]
          isDisconnected: boolean
          profile: {
            __typename?: 'UserProfile'
            id: string
            email?: string | null
          }
        }[]
        workspaces: {
          __typename?: 'Workspace'
          id: string
          isArchived: boolean
          accountKeys: string[]
          isPool: boolean
          workingHoursTimeZone?: string | null
          usageAlertEmail: boolean
          usageAlertAssistantEmail: boolean
          usageLimitsEmail: boolean
          feedbackRequestEmail: boolean
          groupedNotificationsEmail: boolean
          eveningDigestEmail: boolean
          weeklyDigestEmail: boolean
          copilotEnabled: boolean
          isPendingTransition: boolean
          hasCoverage: boolean
          team?: {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
            principalUser:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            subscription?: {
              __typename?: 'TeamSubscription'
              id: string
              type: TeamSubscriptionType
              productName?: string | null
              productPrice?: number | null
              state?: SubscriptionState | null
              maxioUrl?: string | null
              customerDisplayName?: string | null
              currentBillingCycle?: {
                __typename?: 'SubscriptionBillingCycle'
                startDate: string | Date
                endDate: string | Date
                hours: number
              } | null
            } | null
          } | null
          onboarding?: {
            __typename?: 'Onboarding'
            id: string
            isCompleted: boolean
            callStartAt?: string | Date | null
          } | null
          poolTokenBalance?: {
            __typename?: 'PoolTokenBalance'
            id: string
            availablePoolTokenCount: number
          } | null
          assistants: (
            | {
                __typename?: 'AssistantUser'
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          executives: (
            | {
                __typename?: 'ExecutiveUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                facet?: UserFacet | null
                position?: string | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                facet?: UserFacet | null
                industries: string[]
                id: string
                languages: string[]
                pronoun: UserPronoun
                isSaas: boolean
                hasPoolWorkspace: boolean
                isOnPause: boolean
                startDate?: string | null
                isArchived: boolean
                category?: UserCategory | null
                principalUser?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                orientation?: {
                  __typename?: 'Orientation'
                  id: string
                  isCompleted: boolean
                } | null
                birthday?: {
                  __typename?: 'AnniversaryDate'
                  day?: number | null
                  month: number
                  year?: number | null
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  timeZone?: string | null
                  googleMapsPlaceId?: string | null
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    formatted?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          spectators: {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }[]
        }[]
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
            formatted?: string | null
          } | null
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type ArchiveUserMutationVariables = Exact<{
  input: ArchiveUserInput
}>

export type ArchiveUserMutation = {
  __typename?: 'Mutation'
  archiveUser: { __typename?: 'ArchiveUserOutput'; isSuccess: boolean }
}

export type UnarchiveUserMutationVariables = Exact<{
  input: UnarchiveUserInput
}>

export type UnarchiveUserMutation = {
  __typename?: 'Mutation'
  unarchiveUser: {
    __typename?: 'UnarchiveUserOutput'
    user:
      | {
          __typename?: 'AssistantUser'
          personalityTraits: string[]
          workTimeZones?: string[] | null
          matchingNotepad?: string | null
          phoneNumber?: string | null
          acceptedPptcAt?: string | Date | null
          id: string
          interestedInIndustries: string[]
          experienceInTools: string[]
          skillsetRating?: Record<string, any> | null
          isOpenToMatch?: boolean | null
          isOpenToSupport?: boolean | null
          openToUpdatedAt?: string | Date | null
          targetTotalWeeklyHours?: string | null
          targetAdditionalWeeklyHours?: string | null
          experienceHighlights?: string | null
          englishLevel?: CefrScale | null
          resumeUrl: string
          csatAverage?: number | null
          performanceScoreValue?: number | null
          airtableDoubleRecordId?: string | null
          currency?: string | null
          hourlyRate?: number | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          internalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          externalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          accounts: {
            __typename?: 'UserAccount'
            key: string
            type: UserAccountType
            id: string
            isPrimary: boolean
            label: string
            scopes: string[]
            isDisconnected: boolean
            profile: {
              __typename?: 'UserProfile'
              id: string
              email?: string | null
            }
          }[]
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
              formatted?: string | null
            } | null
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          desiredSkills: string[]
          personalityTraits: string[]
          acceptedPptcAt?: string | Date | null
          id: string
          airtableClientRecordId?: string | null
          airtableLeadRecordId?: string | null
          facet?: UserFacet | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          invoicedUser:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          csm?: {
            __typename?: 'ExecutivableCsm'
            id: string
            source:
              | { __typename?: 'AssistantUser' }
              | { __typename?: 'ExecutiveUser' }
              | { __typename?: 'HqUser' }
              | { __typename?: 'SandboxUser' }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
            user: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          } | null
          stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
          accounts: {
            __typename?: 'UserAccount'
            key: string
            type: UserAccountType
            id: string
            isPrimary: boolean
            label: string
            scopes: string[]
            isDisconnected: boolean
            profile: {
              __typename?: 'UserProfile'
              id: string
              email?: string | null
            }
          }[]
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          desiredSkills: string[]
          personalityTraits: string[]
          workTimeZones?: string[] | null
          matchingNotepad?: string | null
          acceptedPptcAt?: string | Date | null
          id: string
          interestedInIndustries: string[]
          experienceInTools: string[]
          skillsetRating?: Record<string, any> | null
          isOpenToMatch?: boolean | null
          isOpenToSupport?: boolean | null
          openToUpdatedAt?: string | Date | null
          targetTotalWeeklyHours?: string | null
          targetAdditionalWeeklyHours?: string | null
          experienceHighlights?: string | null
          englishLevel?: CefrScale | null
          resumeUrl: string
          airtableClientRecordId?: string | null
          airtableLeadRecordId?: string | null
          airtableDoubleRecordId?: string | null
          currency?: string | null
          hourlyRate?: number | null
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          invoicedUser:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          csm?: {
            __typename?: 'ExecutivableCsm'
            id: string
            source:
              | { __typename?: 'AssistantUser' }
              | { __typename?: 'ExecutiveUser' }
              | { __typename?: 'HqUser' }
              | { __typename?: 'SandboxUser' }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
            user: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
          internalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          externalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          accounts: {
            __typename?: 'UserAccount'
            key: string
            type: UserAccountType
            id: string
            isPrimary: boolean
            label: string
            scopes: string[]
            isDisconnected: boolean
            profile: {
              __typename?: 'UserProfile'
              id: string
              email?: string | null
            }
          }[]
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
              formatted?: string | null
            } | null
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          desiredSkills: string[]
          personalityTraits: string[]
          workTimeZones?: string[] | null
          matchingNotepad?: string | null
          acceptedPptcAt?: string | Date | null
          id: string
          interestedInIndustries: string[]
          experienceInTools: string[]
          skillsetRating?: Record<string, any> | null
          isOpenToMatch?: boolean | null
          isOpenToSupport?: boolean | null
          openToUpdatedAt?: string | Date | null
          targetTotalWeeklyHours?: string | null
          targetAdditionalWeeklyHours?: string | null
          experienceHighlights?: string | null
          englishLevel?: CefrScale | null
          resumeUrl: string
          airtableClientRecordId?: string | null
          airtableLeadRecordId?: string | null
          airtableDoubleRecordId?: string | null
          currency?: string | null
          hourlyRate?: number | null
          facet?: UserFacet | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          invoicedUser:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                stripeCustomer?: {
                  __typename?: 'StripeCustomer'
                  id: string
                  paymentStatus: StripeCustomerPaymentStatus
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          csm?: {
            __typename?: 'ExecutivableCsm'
            id: string
            source:
              | { __typename?: 'AssistantUser' }
              | { __typename?: 'ExecutiveUser' }
              | { __typename?: 'HqUser' }
              | { __typename?: 'SandboxUser' }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
            user: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
          internalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          externalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          accounts: {
            __typename?: 'UserAccount'
            key: string
            type: UserAccountType
            id: string
            isPrimary: boolean
            label: string
            scopes: string[]
            isDisconnected: boolean
            profile: {
              __typename?: 'UserProfile'
              id: string
              email?: string | null
            }
          }[]
          workspaces: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
              subscription?: {
                __typename?: 'TeamSubscription'
                id: string
                type: TeamSubscriptionType
                productName?: string | null
                productPrice?: number | null
                state?: SubscriptionState | null
                maxioUrl?: string | null
                customerDisplayName?: string | null
                currentBillingCycle?: {
                  __typename?: 'SubscriptionBillingCycle'
                  startDate: string | Date
                  endDate: string | Date
                  hours: number
                } | null
              } | null
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }[]
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
              formatted?: string | null
            } | null
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  }
}

export type ChurnExecutiveMutationVariables = Exact<{
  input: ChurnExecutiveInput
}>

export type ChurnExecutiveMutation = {
  __typename?: 'Mutation'
  churnExecutive: { __typename?: 'ChurnExecutiveOutput'; isSuccess: boolean }
}

export type ScheduleTeamChurnMutationVariables = Exact<{
  input: ScheduleTeamChurnInput
}>

export type ScheduleTeamChurnMutation = {
  __typename?: 'Mutation'
  scheduleTeamChurn: {
    __typename?: 'ScheduleTeamChurnOutput'
    isSuccess: boolean
  }
}

export type CancelScheduledTeamChurnMutationVariables = Exact<{
  input: CancelScheduledTeamChurnInput
}>

export type CancelScheduledTeamChurnMutation = {
  __typename?: 'Mutation'
  cancelScheduledTeamChurn: {
    __typename?: 'CancelScheduledTeamChurnOutput'
    canceledJobs: {
      __typename?: 'AutomationJob'
      params?: Record<string, any> | null
      results?: Record<string, any> | null
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      reviewedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      logs: {
        __typename?: 'AutomationJobLog'
        at: string | Date
        level?: string | null
        message?: string | null
        meta?: Record<string, any> | null
        action?: {
          __typename?: 'AutomationAction'
          id: string
          name: string
          description?: string | null
        } | null
      }[]
      automation: {
        __typename?: 'Automation'
        description?: string | null
        isEnabled: boolean
        id: string
        name: string
        triggerOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }
        cancelOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }[]
        delay: {
          __typename?: 'Delay'
          value: number
          unit: DelayUnit
          hour?: number | null
          minute?: number | null
          weekday?: number | null
          day?: number | null
          timeZone?: string | null
        }
        actionGroups: {
          __typename?: 'AutomationActionGroup'
          actions: {
            __typename?: 'AutomationAction'
            config?: Record<string, any> | null
            id: string
            name: string
            description?: string | null
          }[]
        }[]
      }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }[]
  }
}

export type UpdateAssistantMutationVariables = Exact<{
  userId: Scalars['ID']
  input: UpdateAssistantInput
}>

export type UpdateAssistantMutation = {
  __typename?: 'Mutation'
  updateAssistant:
    | {
        __typename?: 'AssistantUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
    | {
        __typename?: 'HqUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
}

export type LoginMutationVariables = Exact<{
  idToken: Scalars['String']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: { __typename?: 'LoginOutput'; token: string }
}

export type CreateStripeCustomerMutationVariables = Exact<{
  input: CreateStripeCustomerInput
}>

export type CreateStripeCustomerMutation = {
  __typename?: 'Mutation'
  createStripeCustomer: {
    __typename?: 'CreateStripeCustomerOutput'
    entity:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
      | {
          __typename?: 'Team'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
  }
}

export type UpdateStripeCustomerIdMutationVariables = Exact<{
  input: UpdateStripeCustomerIdInput
}>

export type UpdateStripeCustomerIdMutation = {
  __typename?: 'Mutation'
  updateStripeCustomerId: {
    __typename?: 'UpdateStripeCustomerIdOutput'
    entity:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
      | {
          __typename?: 'Team'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
          } | null
        }
  }
}

export type ListFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type ListFeaturesQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'FeaturesList'
    items: {
      __typename?: 'Feature'
      id: string
      description: string
      appVersions: Record<string, any>
      targets: {
        __typename?: 'FeatureTarget'
        predicates: (
          | {
              __typename: 'FeatureTargetAssistantPredicate'
              assistantIds: string[]
              isNegated: boolean
            }
          | { __typename: 'FeatureTargetConstantPredicate'; constant: boolean }
          | {
              __typename: 'FeatureTargetCreatedAtPredicate'
              createdAfter: string | Date
              isNegated: boolean
            }
          | {
              __typename: 'FeatureTargetExecutivePredicate'
              executiveIds: string[]
              isNegated: boolean
            }
          | {
              __typename: 'FeatureTargetFeaturePredicate'
              featureId: string
              isNegated: boolean
            }
        )[]
      }[]
    }[]
  }
}

export type UpsertFeatureMutationVariables = Exact<{
  input: UpsertFeatureInput
}>

export type UpsertFeatureMutation = {
  __typename?: 'Mutation'
  upsertFeature: {
    __typename?: 'UpsertFeatureOutput'
    feature: {
      __typename?: 'Feature'
      id: string
      description: string
      appVersions: Record<string, any>
      targets: {
        __typename?: 'FeatureTarget'
        predicates: (
          | {
              __typename: 'FeatureTargetAssistantPredicate'
              assistantIds: string[]
              isNegated: boolean
            }
          | { __typename: 'FeatureTargetConstantPredicate'; constant: boolean }
          | {
              __typename: 'FeatureTargetCreatedAtPredicate'
              createdAfter: string | Date
              isNegated: boolean
            }
          | {
              __typename: 'FeatureTargetExecutivePredicate'
              executiveIds: string[]
              isNegated: boolean
            }
          | {
              __typename: 'FeatureTargetFeaturePredicate'
              featureId: string
              isNegated: boolean
            }
        )[]
      }[]
    }
  }
}

export type ListWorkspaceQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']>
  after?: InputMaybe<Scalars['String']>
  isOverridingFeature?: InputMaybe<Scalars['String']>
}>

export type ListWorkspaceQuery = {
  __typename?: 'Query'
  workspacesList: {
    __typename?: 'WorkspacesList'
    after?: string | null
    hasAfter: boolean
    items: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
  }
}

export type ListTopicTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type ListTopicTemplatesQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'TopicTemplatesList'
    items: {
      __typename?: 'TopicTemplate'
      id: string
      type: TopicTemplateType
      category: DiscoverCategory
      sectionKey: DiscoverSectionKey
      title: string
      description: string
      imageUrl?: string | null
      instructions: string
      introduction?: string | null
      introductionTitle?: string | null
      introductionImageUrl?: string | null
      isDraft: boolean
      isNew: boolean
      keywords: string[]
      labels: string[]
      privateTo?: WorkspaceUserRole | null
      notepad?: string | null
      assigneeRole?: WorkspaceUserRole | null
      form?: {
        __typename?: 'TopicForm'
        id: string
        ref?: string | null
        fields: {
          __typename?: 'TopicFormFieldText'
          isAllowingAudio: boolean
          isAllowingFreeForm: boolean
          freeFormPlaceholder?: string | null
          id: string
          question: string
          message?: string | null
          imageUrl?: string | null
          isRequired?: boolean | null
          airtableClientField?: string | null
          maxValues?: number | null
          options: {
            __typename?: 'TopicFormFieldOptionText'
            text: string
            tags: TopicFormFieldOptionTag[]
          }[]
          enabledIf?:
            | {
                __typename?: 'TopicFormFieldValueCondition'
                fieldId: string
                value:
                  | { __typename?: 'TopicExtendedPropValueAudio' }
                  | { __typename?: 'TopicExtendedPropValueNumber' }
                  | { __typename?: 'TopicExtendedPropValueText'; text: string }
              }
            | {
                __typename?: 'TopicFormOperatorCondition'
                operator: TopicFormOperator
                conditions: (
                  | {
                      __typename?: 'TopicFormFieldValueCondition'
                      fieldId: string
                      value:
                        | { __typename?: 'TopicExtendedPropValueAudio' }
                        | { __typename?: 'TopicExtendedPropValueNumber' }
                        | {
                            __typename?: 'TopicExtendedPropValueText'
                            text: string
                          }
                    }
                  | {
                      __typename?: 'TopicFormOperatorCondition'
                      operator: TopicFormOperator
                      conditions: (
                        | {
                            __typename?: 'TopicFormFieldValueCondition'
                            fieldId: string
                            value:
                              | { __typename?: 'TopicExtendedPropValueAudio' }
                              | { __typename?: 'TopicExtendedPropValueNumber' }
                              | {
                                  __typename?: 'TopicExtendedPropValueText'
                                  text: string
                                }
                          }
                        | { __typename?: 'TopicFormOperatorCondition' }
                      )[]
                    }
                )[]
              }
            | null
        }[]
      } | null
    }[]
  }
}

export type UpsertTopicTemplateMutationVariables = Exact<{
  input: UpsertTopicTemplateInput
}>

export type UpsertTopicTemplateMutation = {
  __typename?: 'Mutation'
  upsertTopicTemplate: {
    __typename?: 'UpsertTopicTemplateOutput'
    topicTemplate: {
      __typename?: 'TopicTemplate'
      id: string
      type: TopicTemplateType
      category: DiscoverCategory
      sectionKey: DiscoverSectionKey
      title: string
      description: string
      imageUrl?: string | null
      instructions: string
      introduction?: string | null
      introductionTitle?: string | null
      introductionImageUrl?: string | null
      isDraft: boolean
      isNew: boolean
      keywords: string[]
      labels: string[]
      privateTo?: WorkspaceUserRole | null
      notepad?: string | null
      assigneeRole?: WorkspaceUserRole | null
      form?: {
        __typename?: 'TopicForm'
        id: string
        ref?: string | null
        fields: {
          __typename?: 'TopicFormFieldText'
          isAllowingAudio: boolean
          isAllowingFreeForm: boolean
          freeFormPlaceholder?: string | null
          id: string
          question: string
          message?: string | null
          imageUrl?: string | null
          isRequired?: boolean | null
          airtableClientField?: string | null
          maxValues?: number | null
          options: {
            __typename?: 'TopicFormFieldOptionText'
            text: string
            tags: TopicFormFieldOptionTag[]
          }[]
          enabledIf?:
            | {
                __typename?: 'TopicFormFieldValueCondition'
                fieldId: string
                value:
                  | { __typename?: 'TopicExtendedPropValueAudio' }
                  | { __typename?: 'TopicExtendedPropValueNumber' }
                  | { __typename?: 'TopicExtendedPropValueText'; text: string }
              }
            | {
                __typename?: 'TopicFormOperatorCondition'
                operator: TopicFormOperator
                conditions: (
                  | {
                      __typename?: 'TopicFormFieldValueCondition'
                      fieldId: string
                      value:
                        | { __typename?: 'TopicExtendedPropValueAudio' }
                        | { __typename?: 'TopicExtendedPropValueNumber' }
                        | {
                            __typename?: 'TopicExtendedPropValueText'
                            text: string
                          }
                    }
                  | {
                      __typename?: 'TopicFormOperatorCondition'
                      operator: TopicFormOperator
                      conditions: (
                        | {
                            __typename?: 'TopicFormFieldValueCondition'
                            fieldId: string
                            value:
                              | { __typename?: 'TopicExtendedPropValueAudio' }
                              | { __typename?: 'TopicExtendedPropValueNumber' }
                              | {
                                  __typename?: 'TopicExtendedPropValueText'
                                  text: string
                                }
                          }
                        | { __typename?: 'TopicFormOperatorCondition' }
                      )[]
                    }
                )[]
              }
            | null
        }[]
      } | null
    }
  }
}

export type ArchiveTopicTemplateMutationVariables = Exact<{
  input: ArchiveTopicTemplateInput
}>

export type ArchiveTopicTemplateMutation = {
  __typename?: 'Mutation'
  archiveTopicTemplate: {
    __typename?: 'ArchiveTopicTemplateOutput'
    isSuccess: boolean
  }
}

export type SaveTopicTemplateFormMutationVariables = Exact<{
  input: SaveTopicTemplateFormInput
}>

export type SaveTopicTemplateFormMutation = {
  __typename?: 'Mutation'
  saveTopicTemplateForm: {
    __typename?: 'SaveTopicTemplateFormOutput'
    template: {
      __typename?: 'TopicTemplate'
      id: string
      type: TopicTemplateType
      category: DiscoverCategory
      sectionKey: DiscoverSectionKey
      title: string
      description: string
      imageUrl?: string | null
      instructions: string
      introduction?: string | null
      introductionTitle?: string | null
      introductionImageUrl?: string | null
      isDraft: boolean
      isNew: boolean
      keywords: string[]
      labels: string[]
      privateTo?: WorkspaceUserRole | null
      notepad?: string | null
      assigneeRole?: WorkspaceUserRole | null
      form?: {
        __typename?: 'TopicForm'
        id: string
        ref?: string | null
        fields: {
          __typename?: 'TopicFormFieldText'
          isAllowingAudio: boolean
          isAllowingFreeForm: boolean
          freeFormPlaceholder?: string | null
          id: string
          question: string
          message?: string | null
          imageUrl?: string | null
          isRequired?: boolean | null
          airtableClientField?: string | null
          maxValues?: number | null
          options: {
            __typename?: 'TopicFormFieldOptionText'
            text: string
            tags: TopicFormFieldOptionTag[]
          }[]
          enabledIf?:
            | {
                __typename?: 'TopicFormFieldValueCondition'
                fieldId: string
                value:
                  | { __typename?: 'TopicExtendedPropValueAudio' }
                  | { __typename?: 'TopicExtendedPropValueNumber' }
                  | { __typename?: 'TopicExtendedPropValueText'; text: string }
              }
            | {
                __typename?: 'TopicFormOperatorCondition'
                operator: TopicFormOperator
                conditions: (
                  | {
                      __typename?: 'TopicFormFieldValueCondition'
                      fieldId: string
                      value:
                        | { __typename?: 'TopicExtendedPropValueAudio' }
                        | { __typename?: 'TopicExtendedPropValueNumber' }
                        | {
                            __typename?: 'TopicExtendedPropValueText'
                            text: string
                          }
                    }
                  | {
                      __typename?: 'TopicFormOperatorCondition'
                      operator: TopicFormOperator
                      conditions: (
                        | {
                            __typename?: 'TopicFormFieldValueCondition'
                            fieldId: string
                            value:
                              | { __typename?: 'TopicExtendedPropValueAudio' }
                              | { __typename?: 'TopicExtendedPropValueNumber' }
                              | {
                                  __typename?: 'TopicExtendedPropValueText'
                                  text: string
                                }
                          }
                        | { __typename?: 'TopicFormOperatorCondition' }
                      )[]
                    }
                )[]
              }
            | null
        }[]
      } | null
    }
  }
}

export type GetWorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type GetWorkspaceQuery = {
  __typename?: 'Query'
  workspace: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }
}

export type GetWorkspaceOnboardingQueryVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type GetWorkspaceOnboardingQuery = {
  __typename?: 'Query'
  workspace: {
    __typename?: 'Workspace'
    workingHoursTimeZone?: string | null
    id: string
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          hoursLimit?: number | null
          isAllowedPersonal?: boolean | null
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          hoursLimit?: number | null
          isAllowedPersonal?: boolean | null
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          hoursLimit?: number | null
          isAllowedPersonal?: boolean | null
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                workspaces: {
                  __typename?: 'Workspace'
                  assistants: (
                    | {
                        __typename?: 'AssistantUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        isArchived: boolean
                        category?: UserCategory | null
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                  )[]
                }[]
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    assistants: (
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      url: string
      rescheduleCallUrl: string
      isCompleted: boolean
      callStartAt?: string | Date | null
      hasSentEmail: boolean
      hasSentEmail2: boolean
      hasSentEmail3: boolean
      hasSentEmail4: boolean
      hasSentPostOnboardingEmail: boolean
      splash: {
        __typename?: 'OnboardingSplash'
        executiveEmail: string
        assistantGivenName: string
        assistantAvatarUrl: string
        assistantBiography: string
        assistantLocation: string
      }
      steps: {
        __typename?: 'OnboardingStep'
        id: OnboardingStepId
        isCompleted: boolean
        data?: Record<string, any> | null
      }[]
      specialist?: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
    } | null
  }
}

export type GetWorkspaceOnboardingChecklistQueryVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type GetWorkspaceOnboardingChecklistQuery = {
  __typename?: 'Query'
  workspace: {
    __typename?: 'Workspace'
    id: string
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      checklist: {
        __typename?: 'OnboardingChecklist'
        id: string
        tasks: {
          __typename?: 'OnboardingChecklistTask'
          id: string
          title: string
          isCompleted: boolean
        }[]
      }
    } | null
  }
}

export type GetWorkspaceOnboardingAvailabilityQueryVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type GetWorkspaceOnboardingAvailabilityQuery = {
  __typename?: 'Query'
  workspace: {
    __typename?: 'Workspace'
    id: string
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      availability: {
        __typename?: 'OnboardingAvailability'
        id: string
        slots: {
          __typename?: 'OnboardingAvailabilitySlot'
          startAt: string | Date
          endAt: string | Date
        }[]
      }
    } | null
  }
}

export type GetOnboardingCallAvailabilityQueryVariables = Exact<{
  specialistId?: InputMaybe<Scalars['ID']>
  assistantId: Scalars['ID']
  timeZone?: InputMaybe<Scalars['String']>
}>

export type GetOnboardingCallAvailabilityQuery = {
  __typename?: 'Query'
  onboardingCallAvailability: {
    __typename?: 'OnboardingAvailability'
    id: string
    slots: {
      __typename?: 'OnboardingAvailabilitySlot'
      startAt: string | Date
      endAt: string | Date
    }[]
  }
}

export type AssignOnboardingSpecialistMutationVariables = Exact<{
  input: AssignOnboardingSpecialistInput
}>

export type AssignOnboardingSpecialistMutation = {
  __typename?: 'Mutation'
  assignOnboardingSpecialist: {
    __typename?: 'AssignOnboardingSpecialistOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        specialist?: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
    }
  }
}

export type RemoveOnboardingSpecialistMutationVariables = Exact<{
  input: RemoveOnboardingSpecialistInput
}>

export type RemoveOnboardingSpecialistMutation = {
  __typename?: 'Mutation'
  removeOnboardingSpecialist: {
    __typename?: 'RemoveOnboardingSpecialistOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        specialist?: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
    }
  }
}

export type UpdateOnboardingStepMutationVariables = Exact<{
  input: UpdateOnboardingStepInput
}>

export type UpdateOnboardingStepMutation = {
  __typename?: 'Mutation'
  updateOnboardingStep: {
    __typename?: 'UpdateOnboardingStepOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        steps: {
          __typename?: 'OnboardingStep'
          id: OnboardingStepId
          isCompleted: boolean
          data?: Record<string, any> | null
        }[]
      } | null
    }
  }
}

export type ScheduleOnboardingCallMutationVariables = Exact<{
  input: ScheduleOnboardingCallInput
}>

export type ScheduleOnboardingCallMutation = {
  __typename?: 'Mutation'
  scheduleOnboardingCall: {
    __typename?: 'ScheduleOnboardingCallOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        url: string
        rescheduleCallUrl: string
        isCompleted: boolean
        callStartAt?: string | Date | null
        hasSentEmail: boolean
        hasSentEmail2: boolean
        hasSentEmail3: boolean
        hasSentEmail4: boolean
        hasSentPostOnboardingEmail: boolean
        splash: {
          __typename?: 'OnboardingSplash'
          executiveEmail: string
          assistantGivenName: string
          assistantAvatarUrl: string
          assistantBiography: string
          assistantLocation: string
        }
        steps: {
          __typename?: 'OnboardingStep'
          id: OnboardingStepId
          isCompleted: boolean
          data?: Record<string, any> | null
        }[]
        specialist?: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
    }
  }
}

export type CompleteOnboardingMutationVariables = Exact<{
  input: CompleteOnboardingInput
}>

export type CompleteOnboardingMutation = {
  __typename?: 'Mutation'
  completeOnboarding: {
    __typename?: 'CompleteOnboardingOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
      } | null
    }
  }
}

export type ResetOnboardingMutationVariables = Exact<{
  input: ResetOnboardingInput
}>

export type ResetOnboardingMutation = {
  __typename?: 'Mutation'
  resetOnboarding: {
    __typename?: 'ResetOnboardingOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        url: string
        rescheduleCallUrl: string
        isCompleted: boolean
        callStartAt?: string | Date | null
        hasSentEmail: boolean
        hasSentEmail2: boolean
        hasSentEmail3: boolean
        hasSentEmail4: boolean
        hasSentPostOnboardingEmail: boolean
        splash: {
          __typename?: 'OnboardingSplash'
          executiveEmail: string
          assistantGivenName: string
          assistantAvatarUrl: string
          assistantBiography: string
          assistantLocation: string
        }
        steps: {
          __typename?: 'OnboardingStep'
          id: OnboardingStepId
          isCompleted: boolean
          data?: Record<string, any> | null
        }[]
        specialist?: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
    }
  }
}

export type CancelOnboardingCallMutationVariables = Exact<{
  input: CancelOnboardingCallInput
}>

export type CancelOnboardingCallMutation = {
  __typename?: 'Mutation'
  cancelOnboardingCall: {
    __typename?: 'CancelOnboardingCallOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        url: string
        rescheduleCallUrl: string
        isCompleted: boolean
        callStartAt?: string | Date | null
        hasSentEmail: boolean
        hasSentEmail2: boolean
        hasSentEmail3: boolean
        hasSentEmail4: boolean
        hasSentPostOnboardingEmail: boolean
        splash: {
          __typename?: 'OnboardingSplash'
          executiveEmail: string
          assistantGivenName: string
          assistantAvatarUrl: string
          assistantBiography: string
          assistantLocation: string
        }
        steps: {
          __typename?: 'OnboardingStep'
          id: OnboardingStepId
          isCompleted: boolean
          data?: Record<string, any> | null
        }[]
        specialist?: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
    }
  }
}

export type SendOnboardingEmailMutationVariables = Exact<{
  input: SendOnboardingEmailInput
}>

export type SendOnboardingEmailMutation = {
  __typename?: 'Mutation'
  sendOnboardingEmail: {
    __typename?: 'SendOnboardingEmailOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        hasSentEmail: boolean
        hasSentEmail2: boolean
        hasSentEmail3: boolean
        hasSentEmail4: boolean
        hasSentPostOnboardingEmail: boolean
      } | null
    }
  }
}

export type SentOnboardingEmailMutationVariables = Exact<{
  input: SentOnboardingEmailInput
}>

export type SentOnboardingEmailMutation = {
  __typename?: 'Mutation'
  sentOnboardingEmail: {
    __typename?: 'SentOnboardingEmailOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        hasSentEmail: boolean
        hasSentEmail2: boolean
        hasSentEmail3: boolean
        hasSentEmail4: boolean
        hasSentPostOnboardingEmail: boolean
      } | null
    }
  }
}

export type SentPostOnboardingEmailMutationVariables = Exact<{
  input: SentPostOnboardingEmailInput
}>

export type SentPostOnboardingEmailMutation = {
  __typename?: 'Mutation'
  sentPostOnboardingEmail: {
    __typename?: 'SentPostOnboardingEmailOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        hasSentEmail: boolean
        hasSentEmail2: boolean
        hasSentEmail3: boolean
        hasSentEmail4: boolean
        hasSentPostOnboardingEmail: boolean
      } | null
    }
  }
}

export type CompleteTransitionMutationVariables = Exact<{
  input: CompleteTransitionInput
}>

export type CompleteTransitionMutation = {
  __typename?: 'Mutation'
  completeTransition: {
    __typename?: 'CompleteTransitionOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
    }
  }
}

export type ListFeebacksQueryVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['ID']>
  assistantId?: InputMaybe<Scalars['ID']>
  minRating?: InputMaybe<Scalars['PositiveInt']>
  maxRating?: InputMaybe<Scalars['PositiveInt']>
  createdAfter?: InputMaybe<Scalars['DateTime']>
  createdBefore?: InputMaybe<Scalars['DateTime']>
}>

export type ListFeebacksQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'FeedbacksList'
    items: {
      __typename?: 'CsatFeedback'
      id: string
      rating: number
      tags: string[]
      commentText?: string | null
      createdAt: string | Date
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      assistant:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    }[]
  }
}

export type ListWorkspaceFeebacksQueryVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type ListWorkspaceFeebacksQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'FeedbacksList'
    items: {
      __typename?: 'CsatFeedback'
      id: string
      rating: number
      tags: string[]
      commentText?: string | null
      createdAt: string | Date
      assistant:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      workspace?: { __typename?: 'Workspace'; id: string } | null
    }[]
  }
  workspace: {
    __typename?: 'Workspace'
    id: string
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
  }
}

export type UpdateCsatFeedbackMutationVariables = Exact<{
  input: UpdateCsatFeedbackInput
}>

export type UpdateCsatFeedbackMutation = {
  __typename?: 'Mutation'
  updateCsatFeedback: {
    __typename?: 'UpdateCsatFeedbackOutput'
    feedback: {
      __typename?: 'CsatFeedback'
      id: string
      rating: number
      tags: string[]
      commentText?: string | null
      createdAt: string | Date
      assistant:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      workspace?: { __typename?: 'Workspace'; id: string } | null
    }
  }
}

export type DeleteFeedbackMutationVariables = Exact<{
  input: DeleteFeedbackInput
}>

export type DeleteFeedbackMutation = {
  __typename?: 'Mutation'
  deleteFeedback: {
    __typename?: 'DeleteFeedbackOutput'
    feedback?: { __typename?: 'CsatFeedback'; id: string } | null
  }
}

type MatcherAssistant_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  industries: string[]
  personalityTraits: string[]
  skills: string[]
  biography?: string | null
  funFacts?: string | null
  latestOnboardingAt?: string | Date | null
  workTimeZones?: string[] | null
  matchingNotepad?: string | null
  averageWeeklyWorkedHours: number
  leverId?: string | null
  airtableDoubleRecordId?: string | null
  id: string
  languages: string[]
  csatAverage?: number | null
  performanceScoreValue?: number | null
  startDate?: string | null
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  currency?: string | null
  hourlyRate?: number | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
      formatted?: string | null
    } | null
  } | null
  workspaces: {
    __typename?: 'Workspace'
    id: string
    workingHoursTimeZone?: string | null
    usage: { __typename?: 'WorkspaceUsage'; id: string; startDate: string }
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
    } | null
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
    )[]
  }[]
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
}

type MatcherAssistant_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  industries: string[]
  personalityTraits: string[]
  id: string
  languages: string[]
  startDate?: string | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  workspaces: {
    __typename?: 'Workspace'
    id: string
    workingHoursTimeZone?: string | null
    usage: { __typename?: 'WorkspaceUsage'; id: string; startDate: string }
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
    } | null
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
    )[]
  }[]
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type MatcherAssistant_HqUser_Fragment = {
  __typename?: 'HqUser'
  industries: string[]
  personalityTraits: string[]
  skills: string[]
  biography?: string | null
  funFacts?: string | null
  latestOnboardingAt?: string | Date | null
  workTimeZones?: string[] | null
  matchingNotepad?: string | null
  averageWeeklyWorkedHours: number
  leverId?: string | null
  airtableDoubleRecordId?: string | null
  id: string
  languages: string[]
  startDate?: string | null
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  currency?: string | null
  hourlyRate?: number | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
      formatted?: string | null
    } | null
  } | null
  workspaces: {
    __typename?: 'Workspace'
    id: string
    workingHoursTimeZone?: string | null
    usage: { __typename?: 'WorkspaceUsage'; id: string; startDate: string }
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
    } | null
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
    )[]
  }[]
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
}

type MatcherAssistant_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  industries: string[]
  personalityTraits: string[]
  skills: string[]
  biography?: string | null
  funFacts?: string | null
  latestOnboardingAt?: string | Date | null
  workTimeZones?: string[] | null
  matchingNotepad?: string | null
  averageWeeklyWorkedHours: number
  leverId?: string | null
  airtableDoubleRecordId?: string | null
  id: string
  languages: string[]
  startDate?: string | null
  interestedInIndustries: string[]
  experienceInTools: string[]
  skillsetRating?: Record<string, any> | null
  isOpenToMatch?: boolean | null
  isOpenToSupport?: boolean | null
  openToUpdatedAt?: string | Date | null
  targetTotalWeeklyHours?: string | null
  targetAdditionalWeeklyHours?: string | null
  experienceHighlights?: string | null
  englishLevel?: CefrScale | null
  resumeUrl: string
  currency?: string | null
  hourlyRate?: number | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
      formatted?: string | null
    } | null
  } | null
  workspaces: {
    __typename?: 'Workspace'
    id: string
    workingHoursTimeZone?: string | null
    usage: { __typename?: 'WorkspaceUsage'; id: string; startDate: string }
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
    } | null
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          startDate?: string | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            pricing: {
              __typename?: 'Pricing'
              id: string
              modelConfig: Record<string, any>
            }
          } | null
        }
    )[]
  }[]
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
  internalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
  externalDisc?: {
    __typename?: 'DiscScore'
    d: number
    i: number
    s: number
    c: number
  } | null
}

export type MatcherAssistantFragment =
  | MatcherAssistant_AssistantUser_Fragment
  | MatcherAssistant_ExecutiveUser_Fragment
  | MatcherAssistant_HqUser_Fragment
  | MatcherAssistant_SandboxUser_Fragment

export type ListMatcherAssistantsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  countryCodes?: InputMaybe<Scalars['String'][] | Scalars['String']>
}>

export type ListMatcherAssistantsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'UsersList'
    after?: string | null
    items: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          personalityTraits: string[]
          skills: string[]
          biography?: string | null
          funFacts?: string | null
          latestOnboardingAt?: string | Date | null
          workTimeZones?: string[] | null
          matchingNotepad?: string | null
          averageWeeklyWorkedHours: number
          leverId?: string | null
          airtableDoubleRecordId?: string | null
          id: string
          languages: string[]
          csatAverage?: number | null
          performanceScoreValue?: number | null
          startDate?: string | null
          interestedInIndustries: string[]
          experienceInTools: string[]
          skillsetRating?: Record<string, any> | null
          isOpenToMatch?: boolean | null
          isOpenToSupport?: boolean | null
          openToUpdatedAt?: string | Date | null
          targetTotalWeeklyHours?: string | null
          targetAdditionalWeeklyHours?: string | null
          experienceHighlights?: string | null
          englishLevel?: CefrScale | null
          resumeUrl: string
          currency?: string | null
          hourlyRate?: number | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
              formatted?: string | null
            } | null
          } | null
          workspaces: {
            __typename?: 'Workspace'
            id: string
            workingHoursTimeZone?: string | null
            usage: {
              __typename?: 'WorkspaceUsage'
              id: string
              startDate: string
            }
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
            } | null
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
            )[]
          }[]
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
          internalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          externalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
        }
      | {
          __typename?: 'ExecutiveUser'
          industries: string[]
          personalityTraits: string[]
          id: string
          languages: string[]
          startDate?: string | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          workspaces: {
            __typename?: 'Workspace'
            id: string
            workingHoursTimeZone?: string | null
            usage: {
              __typename?: 'WorkspaceUsage'
              id: string
              startDate: string
            }
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
            } | null
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
            )[]
          }[]
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          industries: string[]
          personalityTraits: string[]
          skills: string[]
          biography?: string | null
          funFacts?: string | null
          latestOnboardingAt?: string | Date | null
          workTimeZones?: string[] | null
          matchingNotepad?: string | null
          averageWeeklyWorkedHours: number
          leverId?: string | null
          airtableDoubleRecordId?: string | null
          id: string
          languages: string[]
          startDate?: string | null
          interestedInIndustries: string[]
          experienceInTools: string[]
          skillsetRating?: Record<string, any> | null
          isOpenToMatch?: boolean | null
          isOpenToSupport?: boolean | null
          openToUpdatedAt?: string | Date | null
          targetTotalWeeklyHours?: string | null
          targetAdditionalWeeklyHours?: string | null
          experienceHighlights?: string | null
          englishLevel?: CefrScale | null
          resumeUrl: string
          currency?: string | null
          hourlyRate?: number | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
              formatted?: string | null
            } | null
          } | null
          workspaces: {
            __typename?: 'Workspace'
            id: string
            workingHoursTimeZone?: string | null
            usage: {
              __typename?: 'WorkspaceUsage'
              id: string
              startDate: string
            }
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
            } | null
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
            )[]
          }[]
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
          internalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          externalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          industries: string[]
          personalityTraits: string[]
          skills: string[]
          biography?: string | null
          funFacts?: string | null
          latestOnboardingAt?: string | Date | null
          workTimeZones?: string[] | null
          matchingNotepad?: string | null
          averageWeeklyWorkedHours: number
          leverId?: string | null
          airtableDoubleRecordId?: string | null
          id: string
          languages: string[]
          startDate?: string | null
          interestedInIndustries: string[]
          experienceInTools: string[]
          skillsetRating?: Record<string, any> | null
          isOpenToMatch?: boolean | null
          isOpenToSupport?: boolean | null
          openToUpdatedAt?: string | Date | null
          targetTotalWeeklyHours?: string | null
          targetAdditionalWeeklyHours?: string | null
          experienceHighlights?: string | null
          englishLevel?: CefrScale | null
          resumeUrl: string
          currency?: string | null
          hourlyRate?: number | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
              formatted?: string | null
            } | null
          } | null
          workspaces: {
            __typename?: 'Workspace'
            id: string
            workingHoursTimeZone?: string | null
            usage: {
              __typename?: 'WorkspaceUsage'
              id: string
              startDate: string
            }
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
            } | null
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  startDate?: string | null
                  currentPricing?: {
                    __typename?: 'EntityPricing'
                    pricing: {
                      __typename?: 'Pricing'
                      id: string
                      modelConfig: Record<string, any>
                    }
                  } | null
                }
            )[]
          }[]
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
          internalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
          externalDisc?: {
            __typename?: 'DiscScore'
            d: number
            i: number
            s: number
            c: number
          } | null
        }
    )[]
  }
}

export type SettingFragment = {
  __typename?: 'Setting'
  id: string
  data: Record<string, any>
}

export type GetSettingByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSettingByIdQuery = {
  __typename?: 'Query'
  settingById: { __typename?: 'Setting'; id: string; data: Record<string, any> }
}

export type UpsertSettingMutationVariables = Exact<{
  input: UpsertSettingInput
}>

export type UpsertSettingMutation = {
  __typename?: 'Mutation'
  upsertSetting: {
    __typename?: 'UpsertSettingOutput'
    setting: { __typename?: 'Setting'; id: string; data: Record<string, any> }
  }
}

export type GetCityByIdQueryVariables = Exact<{
  placeId: Scalars['ID']
}>

export type GetCityByIdQuery = {
  __typename?: 'Query'
  city: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  }
}

export type InvoicingReportTogglUsageFragment = {
  __typename?: 'InvoicingReportTogglUsage'
  id: string
  hours: number
  assistant?:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
}

export type InvoicingRefundCreditHoursFragment = {
  __typename?: 'InvoicingRefundCreditHours'
  hours?: number | null
}

export type InvoicingRefundCreditAmountFragment = {
  __typename?: 'InvoicingRefundCreditAmount'
  amount?: number | null
}

export type InvoicingRefundFragment = {
  __typename?: 'InvoicingRefund'
  id: string
  reason: string
  description: string
  airtableUrl: string
  credit:
    | { __typename?: 'InvoicingRefundCreditAmount'; amount?: number | null }
    | { __typename?: 'InvoicingRefundCreditHours'; hours?: number | null }
}

export type InvoicingDiscountCreditHoursFragment = {
  __typename?: 'InvoicingDiscountCreditHours'
  hours?: number | null
}

export type InvoicingDiscountCreditAmountFragment = {
  __typename?: 'InvoicingDiscountCreditAmount'
  amount?: number | null
}

export type InvoicingDiscountFragment = {
  __typename?: 'InvoicingDiscount'
  id: string
  reason: string
  description: string
  airtableUrl: string
  credit:
    | { __typename?: 'InvoicingDiscountCreditAmount'; amount?: number | null }
    | { __typename?: 'InvoicingDiscountCreditHours'; hours?: number | null }
}

export type InvoicingAgreementFragment = {
  __typename?: 'InvoicingAgreement'
  id: string
  activeAt?: string | Date | null
  expireAt?: string | Date | null
  discountPercentage?: number | null
  airtableUrl: string
  description: string
}

type Executivable_InvoicedUser_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  isOnMaxioBilling: boolean
  id: string
  isArchived: boolean
  startDate?: string | null
  isOnPause: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
  }
  invoicingRefunds: {
    __typename?: 'InvoicingRefund'
    id: string
    reason: string
    description: string
    airtableUrl: string
    credit:
      | { __typename?: 'InvoicingRefundCreditAmount'; amount?: number | null }
      | { __typename?: 'InvoicingRefundCreditHours'; hours?: number | null }
  }[]
  invoicingAgreements: {
    __typename?: 'InvoicingAgreement'
    id: string
    activeAt?: string | Date | null
    expireAt?: string | Date | null
    discountPercentage?: number | null
    airtableUrl: string
    description: string
  }[]
  invoicingDiscounts: {
    __typename?: 'InvoicingDiscount'
    id: string
    reason: string
    description: string
    airtableUrl: string
    credit:
      | { __typename?: 'InvoicingDiscountCreditAmount'; amount?: number | null }
      | { __typename?: 'InvoicingDiscountCreditHours'; hours?: number | null }
  }[]
  csm?: {
    __typename?: 'ExecutivableCsm'
    id: string
    source:
      | { __typename?: 'AssistantUser' }
      | { __typename?: 'ExecutiveUser' }
      | { __typename?: 'HqUser' }
      | { __typename?: 'SandboxUser' }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
    user: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }
  } | null
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
}

type Executivable_InvoicedUser_HqUser_Fragment = {
  __typename?: 'HqUser'
  isOnMaxioBilling: boolean
  id: string
  isArchived: boolean
  startDate?: string | null
  isOnPause: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
  }
  csm?: {
    __typename?: 'ExecutivableCsm'
    id: string
    source:
      | { __typename?: 'AssistantUser' }
      | { __typename?: 'ExecutiveUser' }
      | { __typename?: 'HqUser' }
      | { __typename?: 'SandboxUser' }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
    user: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }
  } | null
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
}

type Executivable_InvoicedUser_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  isOnMaxioBilling: boolean
  id: string
  isArchived: boolean
  startDate?: string | null
  isOnPause: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
  }
  csm?: {
    __typename?: 'ExecutivableCsm'
    id: string
    source:
      | { __typename?: 'AssistantUser' }
      | { __typename?: 'ExecutiveUser' }
      | { __typename?: 'HqUser' }
      | { __typename?: 'SandboxUser' }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
    user: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }
  } | null
  stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
}

export type Executivable_InvoicedUserFragment =
  | Executivable_InvoicedUser_ExecutiveUser_Fragment
  | Executivable_InvoicedUser_HqUser_Fragment
  | Executivable_InvoicedUser_SandboxUser_Fragment

export type InvoicedCycleFragment = {
  __typename?: 'InvoicedCycle'
  cycleStartDate: string | Date
  cycleEndDate: string | Date
  status: InvoicedCycleStatus
  invoicedPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

export type InvoicingReportFragment = {
  __typename?: 'InvoicingReport'
  id: string
  isPaused: boolean
  churnedAt?: string | Date | null
  isTransitioning: boolean
  executive:
    | {
        __typename?: 'ExecutiveUser'
        isOnMaxioBilling: boolean
        id: string
        isArchived: boolean
        startDate?: string | null
        isOnPause: boolean
        category?: UserCategory | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              invoicingRefunds: {
                __typename?: 'InvoicingRefund'
                id: string
                reason: string
                description: string
                airtableUrl: string
                credit:
                  | {
                      __typename?: 'InvoicingRefundCreditAmount'
                      amount?: number | null
                    }
                  | {
                      __typename?: 'InvoicingRefundCreditHours'
                      hours?: number | null
                    }
              }[]
              invoicingAgreements: {
                __typename?: 'InvoicingAgreement'
                id: string
                activeAt?: string | Date | null
                expireAt?: string | Date | null
                discountPercentage?: number | null
                airtableUrl: string
                description: string
              }[]
              invoicingDiscounts: {
                __typename?: 'InvoicingDiscount'
                id: string
                reason: string
                description: string
                airtableUrl: string
                credit:
                  | {
                      __typename?: 'InvoicingDiscountCreditAmount'
                      amount?: number | null
                    }
                  | {
                      __typename?: 'InvoicingDiscountCreditHours'
                      hours?: number | null
                    }
              }[]
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
          | {
              __typename?: 'HqUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
          | {
              __typename?: 'SandboxUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
        invoicedPricing?: {
          __typename?: 'EntityPricing'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          }
          owner:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
        }
        invoicingRefunds: {
          __typename?: 'InvoicingRefund'
          id: string
          reason: string
          description: string
          airtableUrl: string
          credit:
            | {
                __typename?: 'InvoicingRefundCreditAmount'
                amount?: number | null
              }
            | {
                __typename?: 'InvoicingRefundCreditHours'
                hours?: number | null
              }
        }[]
        invoicingAgreements: {
          __typename?: 'InvoicingAgreement'
          id: string
          activeAt?: string | Date | null
          expireAt?: string | Date | null
          discountPercentage?: number | null
          airtableUrl: string
          description: string
        }[]
        invoicingDiscounts: {
          __typename?: 'InvoicingDiscount'
          id: string
          reason: string
          description: string
          airtableUrl: string
          credit:
            | {
                __typename?: 'InvoicingDiscountCreditAmount'
                amount?: number | null
              }
            | {
                __typename?: 'InvoicingDiscountCreditHours'
                hours?: number | null
              }
        }[]
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
      }
    | {
        __typename?: 'HqUser'
        isOnMaxioBilling: boolean
        id: string
        isArchived: boolean
        startDate?: string | null
        isOnPause: boolean
        category?: UserCategory | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              invoicingRefunds: {
                __typename?: 'InvoicingRefund'
                id: string
                reason: string
                description: string
                airtableUrl: string
                credit:
                  | {
                      __typename?: 'InvoicingRefundCreditAmount'
                      amount?: number | null
                    }
                  | {
                      __typename?: 'InvoicingRefundCreditHours'
                      hours?: number | null
                    }
              }[]
              invoicingAgreements: {
                __typename?: 'InvoicingAgreement'
                id: string
                activeAt?: string | Date | null
                expireAt?: string | Date | null
                discountPercentage?: number | null
                airtableUrl: string
                description: string
              }[]
              invoicingDiscounts: {
                __typename?: 'InvoicingDiscount'
                id: string
                reason: string
                description: string
                airtableUrl: string
                credit:
                  | {
                      __typename?: 'InvoicingDiscountCreditAmount'
                      amount?: number | null
                    }
                  | {
                      __typename?: 'InvoicingDiscountCreditHours'
                      hours?: number | null
                    }
              }[]
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
          | {
              __typename?: 'HqUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
          | {
              __typename?: 'SandboxUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
        invoicedPricing?: {
          __typename?: 'EntityPricing'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          }
          owner:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
        }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
      }
    | {
        __typename?: 'SandboxUser'
        isOnMaxioBilling: boolean
        id: string
        isArchived: boolean
        startDate?: string | null
        isOnPause: boolean
        category?: UserCategory | null
        invoicedUser:
          | {
              __typename?: 'ExecutiveUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              invoicingRefunds: {
                __typename?: 'InvoicingRefund'
                id: string
                reason: string
                description: string
                airtableUrl: string
                credit:
                  | {
                      __typename?: 'InvoicingRefundCreditAmount'
                      amount?: number | null
                    }
                  | {
                      __typename?: 'InvoicingRefundCreditHours'
                      hours?: number | null
                    }
              }[]
              invoicingAgreements: {
                __typename?: 'InvoicingAgreement'
                id: string
                activeAt?: string | Date | null
                expireAt?: string | Date | null
                discountPercentage?: number | null
                airtableUrl: string
                description: string
              }[]
              invoicingDiscounts: {
                __typename?: 'InvoicingDiscount'
                id: string
                reason: string
                description: string
                airtableUrl: string
                credit:
                  | {
                      __typename?: 'InvoicingDiscountCreditAmount'
                      amount?: number | null
                    }
                  | {
                      __typename?: 'InvoicingDiscountCreditHours'
                      hours?: number | null
                    }
              }[]
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
          | {
              __typename?: 'HqUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
          | {
              __typename?: 'SandboxUser'
              isOnMaxioBilling: boolean
              id: string
              isArchived: boolean
              startDate?: string | null
              isOnPause: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
              }
              csm?: {
                __typename?: 'ExecutivableCsm'
                id: string
                source:
                  | { __typename?: 'AssistantUser' }
                  | { __typename?: 'ExecutiveUser' }
                  | { __typename?: 'HqUser' }
                  | { __typename?: 'SandboxUser' }
                  | {
                      __typename?: 'Team'
                      id: string
                      name?: string | null
                      iconUrl?: string | null
                      isArchived: boolean
                    }
                user: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              } | null
              stripeCustomer?: {
                __typename?: 'StripeCustomer'
                id: string
              } | null
            }
        invoicedPricing?: {
          __typename?: 'EntityPricing'
          id: string
          selectedAt: string | Date
          activeAt: string | Date
          expireAt?: string | Date | null
          notepad: string
          pricing: {
            __typename?: 'Pricing'
            id: string
            ref: string
            label: string
            description?: string | null
            priceDescription?: string | null
            hoursDescription?: string | null
            sellingPoints: string[]
            sellingPointsNotice?: string | null
            learnMoreUrl?: string | null
            isPublic: boolean
            isAvailable: boolean
            isSharable: boolean
            instantInviteMaxUsers: number
            model?: PricingModel | null
            modelConfig: Record<string, any>
            includesTeamFeatures: boolean
            includesPremiumPerks: boolean
          }
          owner:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
        }
        csm?: {
          __typename?: 'ExecutivableCsm'
          id: string
          source:
            | { __typename?: 'AssistantUser' }
            | { __typename?: 'ExecutiveUser' }
            | { __typename?: 'HqUser' }
            | { __typename?: 'SandboxUser' }
            | {
                __typename?: 'Team'
                id: string
                name?: string | null
                iconUrl?: string | null
                isArchived: boolean
              }
          user: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        } | null
        stripeCustomer?: { __typename?: 'StripeCustomer'; id: string } | null
      }
  togglUsages: {
    __typename?: 'InvoicingReportTogglUsage'
    id: string
    hours: number
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  }[]
}

export type ListInvoicingReportsQueryVariables = Exact<{
  from: Scalars['DateTime']
  to: Scalars['DateTime']
  invoicedPricingDate: Scalars['DateTime']
}>

export type ListInvoicingReportsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'InvoicingReportsList'
    items: ({
      __typename?: 'InvoicingReport'
      id: string
      isPaused: boolean
      churnedAt?: string | Date | null
      isTransitioning: boolean
      executive:
        | {
            __typename?: 'ExecutiveUser'
            isOnMaxioBilling: boolean
            id: string
            isArchived: boolean
            startDate?: string | null
            isOnPause: boolean
            category?: UserCategory | null
            invoicedUser:
              | {
                  __typename?: 'ExecutiveUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  invoicingRefunds: {
                    __typename?: 'InvoicingRefund'
                    id: string
                    reason: string
                    description: string
                    airtableUrl: string
                    credit:
                      | {
                          __typename?: 'InvoicingRefundCreditAmount'
                          amount?: number | null
                        }
                      | {
                          __typename?: 'InvoicingRefundCreditHours'
                          hours?: number | null
                        }
                  }[]
                  invoicingAgreements: {
                    __typename?: 'InvoicingAgreement'
                    id: string
                    activeAt?: string | Date | null
                    expireAt?: string | Date | null
                    discountPercentage?: number | null
                    airtableUrl: string
                    description: string
                  }[]
                  invoicingDiscounts: {
                    __typename?: 'InvoicingDiscount'
                    id: string
                    reason: string
                    description: string
                    airtableUrl: string
                    credit:
                      | {
                          __typename?: 'InvoicingDiscountCreditAmount'
                          amount?: number | null
                        }
                      | {
                          __typename?: 'InvoicingDiscountCreditHours'
                          hours?: number | null
                        }
                  }[]
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
            invoicedPricing?: {
              __typename?: 'EntityPricing'
              id: string
              selectedAt: string | Date
              activeAt: string | Date
              expireAt?: string | Date | null
              notepad: string
              pricing: {
                __typename?: 'Pricing'
                id: string
                ref: string
                label: string
                description?: string | null
                priceDescription?: string | null
                hoursDescription?: string | null
                sellingPoints: string[]
                sellingPointsNotice?: string | null
                learnMoreUrl?: string | null
                isPublic: boolean
                isAvailable: boolean
                isSharable: boolean
                instantInviteMaxUsers: number
                model?: PricingModel | null
                modelConfig: Record<string, any>
                includesTeamFeatures: boolean
                includesPremiumPerks: boolean
              }
              owner:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'Team'
                    id: string
                    name?: string | null
                    iconUrl?: string | null
                    isArchived: boolean
                  }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
            }
            invoicingRefunds: {
              __typename?: 'InvoicingRefund'
              id: string
              reason: string
              description: string
              airtableUrl: string
              credit:
                | {
                    __typename?: 'InvoicingRefundCreditAmount'
                    amount?: number | null
                  }
                | {
                    __typename?: 'InvoicingRefundCreditHours'
                    hours?: number | null
                  }
            }[]
            invoicingAgreements: {
              __typename?: 'InvoicingAgreement'
              id: string
              activeAt?: string | Date | null
              expireAt?: string | Date | null
              discountPercentage?: number | null
              airtableUrl: string
              description: string
            }[]
            invoicingDiscounts: {
              __typename?: 'InvoicingDiscount'
              id: string
              reason: string
              description: string
              airtableUrl: string
              credit:
                | {
                    __typename?: 'InvoicingDiscountCreditAmount'
                    amount?: number | null
                  }
                | {
                    __typename?: 'InvoicingDiscountCreditHours'
                    hours?: number | null
                  }
            }[]
            csm?: {
              __typename?: 'ExecutivableCsm'
              id: string
              source:
                | { __typename?: 'AssistantUser' }
                | { __typename?: 'ExecutiveUser' }
                | { __typename?: 'HqUser' }
                | { __typename?: 'SandboxUser' }
                | {
                    __typename?: 'Team'
                    id: string
                    name?: string | null
                    iconUrl?: string | null
                    isArchived: boolean
                  }
              user: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            } | null
            stripeCustomer?: {
              __typename?: 'StripeCustomer'
              id: string
            } | null
          }
        | {
            __typename?: 'HqUser'
            isOnMaxioBilling: boolean
            id: string
            isArchived: boolean
            startDate?: string | null
            isOnPause: boolean
            category?: UserCategory | null
            invoicedUser:
              | {
                  __typename?: 'ExecutiveUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  invoicingRefunds: {
                    __typename?: 'InvoicingRefund'
                    id: string
                    reason: string
                    description: string
                    airtableUrl: string
                    credit:
                      | {
                          __typename?: 'InvoicingRefundCreditAmount'
                          amount?: number | null
                        }
                      | {
                          __typename?: 'InvoicingRefundCreditHours'
                          hours?: number | null
                        }
                  }[]
                  invoicingAgreements: {
                    __typename?: 'InvoicingAgreement'
                    id: string
                    activeAt?: string | Date | null
                    expireAt?: string | Date | null
                    discountPercentage?: number | null
                    airtableUrl: string
                    description: string
                  }[]
                  invoicingDiscounts: {
                    __typename?: 'InvoicingDiscount'
                    id: string
                    reason: string
                    description: string
                    airtableUrl: string
                    credit:
                      | {
                          __typename?: 'InvoicingDiscountCreditAmount'
                          amount?: number | null
                        }
                      | {
                          __typename?: 'InvoicingDiscountCreditHours'
                          hours?: number | null
                        }
                  }[]
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
            invoicedPricing?: {
              __typename?: 'EntityPricing'
              id: string
              selectedAt: string | Date
              activeAt: string | Date
              expireAt?: string | Date | null
              notepad: string
              pricing: {
                __typename?: 'Pricing'
                id: string
                ref: string
                label: string
                description?: string | null
                priceDescription?: string | null
                hoursDescription?: string | null
                sellingPoints: string[]
                sellingPointsNotice?: string | null
                learnMoreUrl?: string | null
                isPublic: boolean
                isAvailable: boolean
                isSharable: boolean
                instantInviteMaxUsers: number
                model?: PricingModel | null
                modelConfig: Record<string, any>
                includesTeamFeatures: boolean
                includesPremiumPerks: boolean
              }
              owner:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'Team'
                    id: string
                    name?: string | null
                    iconUrl?: string | null
                    isArchived: boolean
                  }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
            }
            csm?: {
              __typename?: 'ExecutivableCsm'
              id: string
              source:
                | { __typename?: 'AssistantUser' }
                | { __typename?: 'ExecutiveUser' }
                | { __typename?: 'HqUser' }
                | { __typename?: 'SandboxUser' }
                | {
                    __typename?: 'Team'
                    id: string
                    name?: string | null
                    iconUrl?: string | null
                    isArchived: boolean
                  }
              user: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            } | null
            stripeCustomer?: {
              __typename?: 'StripeCustomer'
              id: string
            } | null
          }
        | {
            __typename?: 'SandboxUser'
            isOnMaxioBilling: boolean
            id: string
            isArchived: boolean
            startDate?: string | null
            isOnPause: boolean
            category?: UserCategory | null
            invoicedUser:
              | {
                  __typename?: 'ExecutiveUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  invoicingRefunds: {
                    __typename?: 'InvoicingRefund'
                    id: string
                    reason: string
                    description: string
                    airtableUrl: string
                    credit:
                      | {
                          __typename?: 'InvoicingRefundCreditAmount'
                          amount?: number | null
                        }
                      | {
                          __typename?: 'InvoicingRefundCreditHours'
                          hours?: number | null
                        }
                  }[]
                  invoicingAgreements: {
                    __typename?: 'InvoicingAgreement'
                    id: string
                    activeAt?: string | Date | null
                    expireAt?: string | Date | null
                    discountPercentage?: number | null
                    airtableUrl: string
                    description: string
                  }[]
                  invoicingDiscounts: {
                    __typename?: 'InvoicingDiscount'
                    id: string
                    reason: string
                    description: string
                    airtableUrl: string
                    credit:
                      | {
                          __typename?: 'InvoicingDiscountCreditAmount'
                          amount?: number | null
                        }
                      | {
                          __typename?: 'InvoicingDiscountCreditHours'
                          hours?: number | null
                        }
                  }[]
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
              | {
                  __typename?: 'HqUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
              | {
                  __typename?: 'SandboxUser'
                  isOnMaxioBilling: boolean
                  id: string
                  isArchived: boolean
                  startDate?: string | null
                  isOnPause: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                  }
                  csm?: {
                    __typename?: 'ExecutivableCsm'
                    id: string
                    source:
                      | { __typename?: 'AssistantUser' }
                      | { __typename?: 'ExecutiveUser' }
                      | { __typename?: 'HqUser' }
                      | { __typename?: 'SandboxUser' }
                      | {
                          __typename?: 'Team'
                          id: string
                          name?: string | null
                          iconUrl?: string | null
                          isArchived: boolean
                        }
                    user: {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  } | null
                  stripeCustomer?: {
                    __typename?: 'StripeCustomer'
                    id: string
                  } | null
                }
            invoicedPricing?: {
              __typename?: 'EntityPricing'
              id: string
              selectedAt: string | Date
              activeAt: string | Date
              expireAt?: string | Date | null
              notepad: string
              pricing: {
                __typename?: 'Pricing'
                id: string
                ref: string
                label: string
                description?: string | null
                priceDescription?: string | null
                hoursDescription?: string | null
                sellingPoints: string[]
                sellingPointsNotice?: string | null
                learnMoreUrl?: string | null
                isPublic: boolean
                isAvailable: boolean
                isSharable: boolean
                instantInviteMaxUsers: number
                model?: PricingModel | null
                modelConfig: Record<string, any>
                includesTeamFeatures: boolean
                includesPremiumPerks: boolean
              }
              owner:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'Team'
                    id: string
                    name?: string | null
                    iconUrl?: string | null
                    isArchived: boolean
                  }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
            }
            csm?: {
              __typename?: 'ExecutivableCsm'
              id: string
              source:
                | { __typename?: 'AssistantUser' }
                | { __typename?: 'ExecutiveUser' }
                | { __typename?: 'HqUser' }
                | { __typename?: 'SandboxUser' }
                | {
                    __typename?: 'Team'
                    id: string
                    name?: string | null
                    iconUrl?: string | null
                    isArchived: boolean
                  }
              user: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            } | null
            stripeCustomer?: {
              __typename?: 'StripeCustomer'
              id: string
            } | null
          }
      togglUsages: {
        __typename?: 'InvoicingReportTogglUsage'
        id: string
        hours: number
        assistant?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
    } | null)[]
  }
}

export type GetInvoicingReportUserStripeStatusQueryVariables = Exact<{
  userId: Scalars['ID']
  invoiceStatus?: InputMaybe<StripeInvoiceStatus[] | StripeInvoiceStatus>
  invoicesFirst?: InputMaybe<Scalars['NonNegativeInt']>
  invoicesCreatedAfter?: InputMaybe<Scalars['DateTime']>
  invoicesCreatedBefore?: InputMaybe<Scalars['DateTime']>
}>

export type GetInvoicingReportUserStripeStatusQuery = {
  __typename?: 'Query'
  user:
    | { __typename?: 'AssistantUser'; id: string }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
          invoices: {
            __typename?: 'StripeInvoice'
            id: string
            description?: string | null
            amount: number
            currency: string
            status: StripeInvoiceStatus
            createdAt: string | Date
            periodStartAt?: string | Date | null
            periodEndAt?: string | Date | null
            lineItems: {
              __typename?: 'StripeInvoiceLineItem'
              id: string
              amount: number
              currency: string
              description?: string | null
            }[]
          }[]
        } | null
      }
    | {
        __typename?: 'HqUser'
        id: string
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
          invoices: {
            __typename?: 'StripeInvoice'
            id: string
            description?: string | null
            amount: number
            currency: string
            status: StripeInvoiceStatus
            createdAt: string | Date
            periodStartAt?: string | Date | null
            periodEndAt?: string | Date | null
            lineItems: {
              __typename?: 'StripeInvoiceLineItem'
              id: string
              amount: number
              currency: string
              description?: string | null
            }[]
          }[]
        } | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        stripeCustomer?: {
          __typename?: 'StripeCustomer'
          id: string
          paymentStatus: StripeCustomerPaymentStatus
          invoices: {
            __typename?: 'StripeInvoice'
            id: string
            description?: string | null
            amount: number
            currency: string
            status: StripeInvoiceStatus
            createdAt: string | Date
            periodStartAt?: string | Date | null
            periodEndAt?: string | Date | null
            lineItems: {
              __typename?: 'StripeInvoiceLineItem'
              id: string
              amount: number
              currency: string
              description?: string | null
            }[]
          }[]
        } | null
      }
}

export type CreateStripeInvoiceMutationVariables = Exact<{
  input: CreateStripeInvoiceInput
  invoiceStatus?: InputMaybe<StripeInvoiceStatus[] | StripeInvoiceStatus>
  invoicesFirst?: InputMaybe<Scalars['NonNegativeInt']>
  invoicesCreatedAfter?: InputMaybe<Scalars['DateTime']>
  invoicesCreatedBefore?: InputMaybe<Scalars['DateTime']>
}>

export type CreateStripeInvoiceMutation = {
  __typename?: 'Mutation'
  createStripeInvoice: {
    __typename?: 'CreateStripeInvoiceOutput'
    customer: {
      __typename?: 'StripeCustomer'
      id: string
      paymentStatus: StripeCustomerPaymentStatus
      invoices: {
        __typename?: 'StripeInvoice'
        id: string
        description?: string | null
        amount: number
        currency: string
        status: StripeInvoiceStatus
        createdAt: string | Date
        periodStartAt?: string | Date | null
        periodEndAt?: string | Date | null
        lineItems: {
          __typename?: 'StripeInvoiceLineItem'
          id: string
          amount: number
          currency: string
          description?: string | null
        }[]
      }[]
    }
    invoice: {
      __typename?: 'StripeInvoice'
      id: string
      description?: string | null
      amount: number
      currency: string
      status: StripeInvoiceStatus
      createdAt: string | Date
      periodStartAt?: string | Date | null
      periodEndAt?: string | Date | null
      lineItems: {
        __typename?: 'StripeInvoiceLineItem'
        id: string
        amount: number
        currency: string
        description?: string | null
      }[]
    }
  }
}

export type VoidStripeInvoiceMutationVariables = Exact<{
  input: VoidStripeInvoiceInput
  invoiceStatus?: InputMaybe<StripeInvoiceStatus[] | StripeInvoiceStatus>
  invoicesFirst?: InputMaybe<Scalars['NonNegativeInt']>
  invoicesCreatedAfter?: InputMaybe<Scalars['DateTime']>
  invoicesCreatedBefore?: InputMaybe<Scalars['DateTime']>
}>

export type VoidStripeInvoiceMutation = {
  __typename?: 'Mutation'
  voidStripeInvoice: {
    __typename?: 'VoidStripeInvoiceOutput'
    customer: {
      __typename?: 'StripeCustomer'
      id: string
      paymentStatus: StripeCustomerPaymentStatus
      invoices: {
        __typename?: 'StripeInvoice'
        id: string
        description?: string | null
        amount: number
        currency: string
        status: StripeInvoiceStatus
        createdAt: string | Date
        periodStartAt?: string | Date | null
        periodEndAt?: string | Date | null
        lineItems: {
          __typename?: 'StripeInvoiceLineItem'
          id: string
          amount: number
          currency: string
          description?: string | null
        }[]
      }[]
    }
  }
}

export type PayStripeInvoiceMutationVariables = Exact<{
  input: PayStripeInvoiceInput
  invoiceStatus?: InputMaybe<StripeInvoiceStatus[] | StripeInvoiceStatus>
  invoicesFirst?: InputMaybe<Scalars['NonNegativeInt']>
  invoicesCreatedAfter?: InputMaybe<Scalars['DateTime']>
  invoicesCreatedBefore?: InputMaybe<Scalars['DateTime']>
}>

export type PayStripeInvoiceMutation = {
  __typename?: 'Mutation'
  payStripeInvoice: {
    __typename?: 'PayStripeInvoiceOutput'
    isSuccess: boolean
    customer: {
      __typename?: 'StripeCustomer'
      id: string
      paymentStatus: StripeCustomerPaymentStatus
      invoices: {
        __typename?: 'StripeInvoice'
        id: string
        description?: string | null
        amount: number
        currency: string
        status: StripeInvoiceStatus
        createdAt: string | Date
        periodStartAt?: string | Date | null
        periodEndAt?: string | Date | null
        lineItems: {
          __typename?: 'StripeInvoiceLineItem'
          id: string
          amount: number
          currency: string
          description?: string | null
        }[]
      }[]
    }
    invoice: {
      __typename?: 'StripeInvoice'
      id: string
      description?: string | null
      amount: number
      currency: string
      status: StripeInvoiceStatus
      createdAt: string | Date
      periodStartAt?: string | Date | null
      periodEndAt?: string | Date | null
      lineItems: {
        __typename?: 'StripeInvoiceLineItem'
        id: string
        amount: number
        currency: string
        description?: string | null
      }[]
    }
  }
}

export type SendStripeInvoiceMutationVariables = Exact<{
  input: SendStripeInvoiceInput
  invoiceStatus?: InputMaybe<StripeInvoiceStatus[] | StripeInvoiceStatus>
  invoicesFirst?: InputMaybe<Scalars['NonNegativeInt']>
  invoicesCreatedAfter?: InputMaybe<Scalars['DateTime']>
  invoicesCreatedBefore?: InputMaybe<Scalars['DateTime']>
}>

export type SendStripeInvoiceMutation = {
  __typename?: 'Mutation'
  sendStripeInvoice: {
    __typename?: 'SendStripeInvoiceOutput'
    customer: {
      __typename?: 'StripeCustomer'
      id: string
      paymentStatus: StripeCustomerPaymentStatus
      invoices: {
        __typename?: 'StripeInvoice'
        id: string
        description?: string | null
        amount: number
        currency: string
        status: StripeInvoiceStatus
        createdAt: string | Date
        periodStartAt?: string | Date | null
        periodEndAt?: string | Date | null
        lineItems: {
          __typename?: 'StripeInvoiceLineItem'
          id: string
          amount: number
          currency: string
          description?: string | null
        }[]
      }[]
    }
  }
}

export type ContractorReportFragment = {
  __typename?: 'ContractorReport'
  id: string
  totalHours: number
  doubleHours: number
  user?:
    | {
        __typename?: 'AssistantUser'
        id: string
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
        personalEmail?: string | null
        primaryEmail?: string | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
        personalEmail?: string | null
        primaryEmail?: string | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
        personalEmail?: string | null
        primaryEmail?: string | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
}

export type ListContractorReportsQueryVariables = Exact<{
  from: Scalars['DateTime']
  to: Scalars['DateTime']
}>

export type ListContractorReportsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'ContractorReportsList'
    items: {
      __typename?: 'ContractorReport'
      id: string
      totalHours: number
      doubleHours: number
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            hourlyRate?: number | null
            currency?: string | null
            ein?: string | null
            justworksVendorName?: string | null
            justworksGivenName?: string | null
            justworksFamilyName?: string | null
            personalEmail?: string | null
            primaryEmail?: string | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            hourlyRate?: number | null
            currency?: string | null
            ein?: string | null
            justworksVendorName?: string | null
            justworksGivenName?: string | null
            justworksFamilyName?: string | null
            personalEmail?: string | null
            primaryEmail?: string | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            hourlyRate?: number | null
            currency?: string | null
            ein?: string | null
            justworksVendorName?: string | null
            justworksGivenName?: string | null
            justworksFamilyName?: string | null
            personalEmail?: string | null
            primaryEmail?: string | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }[]
  }
}

export type PayPeriodFragment = {
  __typename?: 'PayPeriod'
  id: string
  lockTimeTrackingOn: string
}

export type GetPayPeriodQueryVariables = Exact<{ [key: string]: never }>

export type GetPayPeriodQuery = {
  __typename?: 'Query'
  payPeriod: {
    __typename?: 'PayPeriod'
    id: string
    lockTimeTrackingOn: string
  }
}

export type UpdatePayPeriodMutationVariables = Exact<{
  input: UpdatePayPeriodInput
}>

export type UpdatePayPeriodMutation = {
  __typename?: 'Mutation'
  updatePayPeriod: {
    __typename?: 'UpdatePayPeriodOutput'
    payPeriod: {
      __typename?: 'PayPeriod'
      id: string
      lockTimeTrackingOn: string
    }
  }
}

export type SendContractorsPaymentsEmailsMutationVariables = Exact<{
  input: SendContractorsPaymentsEmailsInput
}>

export type SendContractorsPaymentsEmailsMutation = {
  __typename?: 'Mutation'
  sendContractorsPaymentsEmails: {
    __typename?: 'SendContractorsPaymentsEmailsOutput'
    isSuccess: boolean
  }
}

export type UploadPublicFileMutationVariables = Exact<{
  input: UploadPublicFileInput
}>

export type UploadPublicFileMutation = {
  __typename?: 'Mutation'
  upload: {
    __typename?: 'UploadPublicFileOutput'
    signedUrl: string
    publicUrl: string
    filename: string
  }
}

export type PricingFragment = {
  __typename?: 'Pricing'
  id: string
  ref: string
  label: string
  description?: string | null
  priceDescription?: string | null
  hoursDescription?: string | null
  sellingPoints: string[]
  sellingPointsNotice?: string | null
  learnMoreUrl?: string | null
  isPublic: boolean
  isAvailable: boolean
  isSharable: boolean
  instantInviteMaxUsers: number
  model?: PricingModel | null
  modelConfig: Record<string, any>
  includesTeamFeatures: boolean
  includesPremiumPerks: boolean
}

export type ListPricingsQueryVariables = Exact<{ [key: string]: never }>

export type ListPricingsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'PricingsList'
    items: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }[]
  }
}

export type UpsertPricingMutationVariables = Exact<{
  input: UpsertPricingInput
}>

export type UpsertPricingMutation = {
  __typename?: 'Mutation'
  upsertPricing: {
    __typename?: 'UpsertPricingOutput'
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
  }
}

export type SelectEntityPricingMutationVariables = Exact<{
  input: SelectEntityPricingInput
}>

export type SelectEntityPricingMutation = {
  __typename?: 'Mutation'
  selectEntityPricing: {
    __typename?: 'SelectEntityPricingOutput'
    entity:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'Team'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
  }
}

export type CancelScheduledEntityPricingUpdateMutationVariables = Exact<{
  input: CancelScheduledEntityPricingUpdateInput
}>

export type CancelScheduledEntityPricingUpdateMutation = {
  __typename?: 'Mutation'
  cancelScheduledEntityPricingUpdate: {
    __typename?: 'CancelScheduledEntityPricingUpdateOutput'
    entity:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'Team'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
  }
}

export type ExpireUserPricingMutationVariables = Exact<{
  input: ExpireUserPricingInput
}>

export type ExpireUserPricingMutation = {
  __typename?: 'Mutation'
  expireUserPricing: {
    __typename?: 'ExpireUserPricingOutput'
    user:
      | { __typename?: 'AssistantUser' }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          pricingSelections: {
            __typename?: 'PricingSelection'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing?: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            } | null
          }[]
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }
  }
}

export type BugReportFileFragment = {
  __typename?: 'BugReportFile'
  id: string
  mime: string
  name: string
  downloadUrl: string
}

export type BugReportFragment = {
  __typename?: 'BugReport'
  id: string
  message?: string | null
  properties: Record<string, any>
  metadata: Record<string, any>
  createdAt: string | Date
  user?:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  files: {
    __typename?: 'BugReportFile'
    id: string
    mime: string
    name: string
    downloadUrl: string
  }[]
}

export type ListBugReportsQueryVariables = Exact<{ [key: string]: never }>

export type ListBugReportsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'BugReportsList'
    items: {
      __typename?: 'BugReport'
      id: string
      message?: string | null
      properties: Record<string, any>
      metadata: Record<string, any>
      createdAt: string | Date
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      files: {
        __typename?: 'BugReportFile'
        id: string
        mime: string
        name: string
        downloadUrl: string
      }[]
    }[]
  }
}

export type WorkspaceCategoryUsageFragment = {
  __typename?: 'WorkspaceCategoryUsage'
  category?: string | null
  hours: number
}

export type WorkspaceWeeklyUsageFragment = {
  __typename?: 'WorkspaceWeeklyUsage'
  id: string
  weekYear: number
  week: number
  sunday: string
  hours: number
}

export type WorkspaceMonthlyUsageFragment = {
  __typename?: 'WorkspaceMonthlyUsage'
  id: string
  year: number
  month: number
  hours: number
}

export type WorkspaceWeeklyUsageWithCategoriesFragment = {
  __typename?: 'WorkspaceWeeklyUsage'
  id: string
  weekYear: number
  week: number
  sunday: string
  hours: number
  categories: {
    __typename?: 'WorkspaceCategoryUsage'
    category?: string | null
    hours: number
  }[]
}

export type WorkspaceMonthlyUsageWithCategoriesFragment = {
  __typename?: 'WorkspaceMonthlyUsage'
  id: string
  year: number
  month: number
  hours: number
  categories: {
    __typename?: 'WorkspaceCategoryUsage'
    category?: string | null
    hours: number
  }[]
}

export type WorkspaceUsageFragment = {
  __typename?: 'WorkspaceUsage'
  id: string
  weeklyUsages: {
    __typename?: 'WorkspaceWeeklyUsage'
    id: string
    weekYear: number
    week: number
    sunday: string
    hours: number
    categories: {
      __typename?: 'WorkspaceCategoryUsage'
      category?: string | null
      hours: number
    }[]
  }[]
  monthlyUsages: {
    __typename?: 'WorkspaceMonthlyUsage'
    id: string
    year: number
    month: number
    hours: number
    categories: {
      __typename?: 'WorkspaceCategoryUsage'
      category?: string | null
      hours: number
    }[]
  }[]
}

export type WorkspaceReportFragment = {
  __typename?: 'WorkspaceReport'
  id: string
  workspace?: {
    __typename?: 'Workspace'
    id: string
    hasHadCoverage: boolean
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | { __typename?: 'ExecutiveUser'; id: string }
            | { __typename?: 'HqUser'; id: string }
            | { __typename?: 'SandboxUser'; id: string }
            | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | { __typename?: 'ExecutiveUser'; id: string }
            | { __typename?: 'HqUser'; id: string }
            | { __typename?: 'SandboxUser'; id: string }
            | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | { __typename?: 'ExecutiveUser'; id: string }
            | { __typename?: 'HqUser'; id: string }
            | { __typename?: 'SandboxUser'; id: string }
            | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
  } | null
  weeklyUsages: {
    __typename?: 'WorkspaceWeeklyUsage'
    id: string
    weekYear: number
    week: number
    sunday: string
    hours: number
  }[]
  monthlyUsages: {
    __typename?: 'WorkspaceMonthlyUsage'
    id: string
    year: number
    month: number
    hours: number
  }[]
}

export type GetUserReportsQueryVariables = Exact<{
  userId: Scalars['ID']
  weeksCount?: Scalars['NonNegativeInt']
  monthsCount?: Scalars['NonNegativeInt']
  quartersCount?: Scalars['NonNegativeInt']
  until?: InputMaybe<Scalars['DateTime']>
}>

export type GetUserReportsQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        reports: {
          __typename?: 'WorkspaceReport'
          id: string
          workspace?: {
            __typename?: 'Workspace'
            id: string
            hasHadCoverage: boolean
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | { __typename?: 'ExecutiveUser'; id: string }
                    | { __typename?: 'HqUser'; id: string }
                    | { __typename?: 'SandboxUser'; id: string }
                    | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | { __typename?: 'ExecutiveUser'; id: string }
                    | { __typename?: 'HqUser'; id: string }
                    | { __typename?: 'SandboxUser'; id: string }
                    | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | { __typename?: 'ExecutiveUser'; id: string }
                    | { __typename?: 'HqUser'; id: string }
                    | { __typename?: 'SandboxUser'; id: string }
                    | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
          } | null
          weeklyUsages: {
            __typename?: 'WorkspaceWeeklyUsage'
            id: string
            weekYear: number
            week: number
            sunday: string
            hours: number
          }[]
          monthlyUsages: {
            __typename?: 'WorkspaceMonthlyUsage'
            id: string
            year: number
            month: number
            hours: number
          }[]
        }[]
      }
    | { __typename?: 'ExecutiveUser'; id: string }
    | { __typename?: 'HqUser'; id: string }
    | { __typename?: 'SandboxUser'; id: string }
}

export type GetWorkspaceReportQueryVariables = Exact<{
  workspaceId: Scalars['ID']
}>

export type GetWorkspaceReportQuery = {
  __typename?: 'Query'
  workspace: {
    __typename?: 'Workspace'
    id: string
    usage: {
      __typename?: 'WorkspaceUsage'
      id: string
      weeklyUsages: {
        __typename?: 'WorkspaceWeeklyUsage'
        id: string
        weekYear: number
        week: number
        sunday: string
        hours: number
        categories: {
          __typename?: 'WorkspaceCategoryUsage'
          category?: string | null
          hours: number
        }[]
      }[]
      monthlyUsages: {
        __typename?: 'WorkspaceMonthlyUsage'
        id: string
        year: number
        month: number
        hours: number
        categories: {
          __typename?: 'WorkspaceCategoryUsage'
          category?: string | null
          hours: number
        }[]
      }[]
    }
  }
}

export type MatchingProposal_SentReplyFragment = {
  __typename?: 'MatchingProposal'
  id: string
  sentAt?: string | Date | null
  reply?: MatchingProposalReply | null
}

export type MatchingProposal_AssistantFragment = {
  __typename?: 'MatchingProposal'
  id: string
  sentAt?: string | Date | null
  reply?: MatchingProposalReply | null
  assistant:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type MatchingProposal_ConfirmationFragment = {
  __typename?: 'MatchingProposal'
  id: string
  confirmedAt?: string | Date | null
  confirmedBy?:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
}

export type MatchingProposal_ReasonForMatchFragment = {
  __typename?: 'MatchingProposal'
  id: string
  reasonForMatch?: string | null
  executiveReasonForMatch?: string | null
  additionalContext?: string | null
}

export type MatchingProposal_IsInstantInviteFragment = {
  __typename?: 'MatchingProposal'
  id: string
  isInstantInvite: boolean
}

export type MatchingProposal_FullFragment = {
  __typename?: 'MatchingProposal'
  queuedAt: string | Date
  orderIndex: string
  sentAt?: string | Date | null
  slackChannelId?: string | null
  repliedAt?: string | Date | null
  replyComment?: string | null
  introCallAt?: string | Date | null
  id: string
  reply?: MatchingProposalReply | null
  confirmedAt?: string | Date | null
  reasonForMatch?: string | null
  executiveReasonForMatch?: string | null
  additionalContext?: string | null
  isInstantInvite: boolean
  application?: {
    __typename?: 'MatchingApplication'
    id: string
    at?: string | Date | null
    motivation?: string | null
  } | null
  assistant:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  confirmedBy?:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
}

export type MatchingApplication_MotivationFragment = {
  __typename?: 'MatchingApplication'
  id: string
  at?: string | Date | null
  motivation?: string | null
}

export type MatchingApplicationFragment = {
  __typename?: 'MatchingApplication'
  id: string
  at?: string | Date | null
  motivation?: string | null
  assistant:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type MatchingProfile_NameFragment = {
  __typename?: 'MatchingProfile'
  id: string
  name?: string | null
}

export type MatchingProfile_ForSortingFragment = {
  __typename?: 'MatchingProfile'
  id: string
  introCallDate?: string | null
  matchDueDate?: string | null
  internalMatchingDate?: string | null
  country?: string | null
}

export type MatchingProfile_PrincipalFragment = {
  __typename?: 'MatchingProfile'
  airtableLeadId?: string | null
  id: string
  name?: string | null
}

export type MatchingProfileFragment = {
  __typename?: 'MatchingProfile'
  airtableLeadId?: string | null
  city?: string | null
  jobRole?: string | null
  companyIndustry?: string | null
  companySize?: string | null
  linkedInUrl?: string | null
  desiredAvailability?: string | null
  tools: string[]
  tasks: string[]
  eaPersonalityTraits: string[]
  id: string
  name?: string | null
  introCallDate?: string | null
  matchDueDate?: string | null
  internalMatchingDate?: string | null
  country?: string | null
  hoursNeededRange?: {
    __typename?: 'MatchingProfileHoursNeededRange'
    from: number
    to?: number | null
  } | null
  bioSections: {
    __typename?: 'MatchingProfileBioSection'
    title?: string | null
    text: string
  }[]
}

export type Matching_IsHighTouchFragment = {
  __typename?: 'Matching'
  id: string
  isHighTouch: boolean
  isHighTouchMaybe: boolean
  targetCandidatesCount: number
}

export type Matching_BookmarksCountFragment = {
  __typename?: 'Matching'
  id: string
  bookmarksCount: number
}

export type Matching_IsTransitionFragment = {
  __typename?: 'Matching'
  id: string
  isTransition: boolean
}

export type Matching_CatalogVisibilityFragment = {
  __typename?: 'Matching'
  id: string
  isHiddenFromCatalog: boolean
}

export type Matching_TeamInviteFragment = {
  __typename?: 'MatchingTeamInvite'
  id: string
  isAllowedPersonal: boolean
  hoursLimit: number
  companyName?: string | null
}

export type Matching_IsTeamInviteFragment = {
  __typename?: 'Matching'
  id: string
  isTeamInvite?: boolean | null
}

export type Matching_InstantInviteFragment = {
  __typename?: 'Matching'
  id: string
  proposals: {
    __typename?: 'MatchingProposal'
    id: string
    sentAt?: string | Date | null
    reply?: MatchingProposalReply | null
  }[]
}

export type Matching_ProposalsReplyFragment = {
  __typename?: 'Matching'
  id: string
  proposals: {
    __typename?: 'MatchingProposal'
    id: string
    sentAt?: string | Date | null
    reply?: MatchingProposalReply | null
    isInstantInvite: boolean
  }[]
}

export type Matching_ApplicationsIdFragment = {
  __typename?: 'Matching'
  id: string
  applications: { __typename?: 'MatchingApplication'; id: string }[]
}

export type Matching_CompletedAtFragment = {
  __typename?: 'Matching'
  id: string
  completedAt?: string | Date | null
}

export type Matching_ForMatchingListItemFragment = {
  __typename?: 'Matching'
  hasToBeMatched: boolean
  id: string
  bookmarksCount: number
  isHighTouch: boolean
  isHighTouchMaybe: boolean
  targetCandidatesCount: number
  isTransition: boolean
  isHiddenFromCatalog: boolean
  completedAt?: string | Date | null
  isTeamInvite?: boolean | null
  profiles: {
    __typename?: 'MatchingProfile'
    id: string
    name?: string | null
    introCallDate?: string | null
    matchDueDate?: string | null
    internalMatchingDate?: string | null
    country?: string | null
  }[]
  proposals: {
    __typename?: 'MatchingProposal'
    id: string
    sentAt?: string | Date | null
    reply?: MatchingProposalReply | null
    isInstantInvite: boolean
  }[]
  applications: { __typename?: 'MatchingApplication'; id: string }[]
}

export type MatchingTransition_SentMessagesFragment = {
  __typename?: 'MatchingTransition'
  id: string
  sentMessages: {
    __typename?: 'MatchingTransitionSentMessage'
    id: string
    at: string | Date
    messageTemplate:
      | { __typename?: 'EmailMessageTemplate'; id: string }
      | { __typename?: 'SlackMessageTemplate'; id: string }
      | { __typename?: 'SmsMessageTemplate'; id: string }
      | { __typename?: 'TextMessageTemplate'; id: string }
  }[]
}

export type MatchingTransition_InformingFragment = {
  __typename?: 'MatchingTransition'
  id: string
  informingStatus: TransitionInformingStatus
  informingCategory?: TransitionInformingCategory | null
  hasDisabledSlackMessages: boolean
  csm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
}

export type MatchingTransition_MatchingStatusFragment = {
  __typename?: 'MatchingTransition'
  id: string
  matchingStatus: TransitionMatchingStatus
}

export type MatchingTransition_HandoverStatusFragment = {
  __typename?: 'MatchingTransition'
  id: string
  handoverStatus?: TransitionHandoverStatus | null
}

export type MatchingTransition_HandoverFragment = {
  __typename?: 'MatchingTransition'
  handoverAt?: string | Date | null
  shouldDsmAttendHandover?: boolean | null
  id: string
  handoverStatus?: TransitionHandoverStatus | null
}

export type MatchingTransition_ChangeoverStatusesFragment = {
  __typename?: 'MatchingTransition'
  id: string
  didSwitchAssistantsAt?: string | Date | null
  didSwitch1PasswordAt?: string | Date | null
  didRequestExecutiveFeedbackAt?: string | Date | null
  didFollowUpWithNewAssistantAt?: string | Date | null
}

export type MatchingTransition_SlackChannelIdFragment = {
  __typename?: 'MatchingTransition'
  id: string
  slackChannelId?: string | null
  hasDisabledSlackMessages: boolean
}

export type MatchingTransition_NotepadFragment = {
  __typename?: 'MatchingTransition'
  id: string
  notepad: string
}

export type MatchingTransition_ListItemFragment = {
  __typename?: 'MatchingTransition'
  id: string
  requestedOn: string
  requestedByUserCategory?: UserCategory | null
  deadlineOn?: string | null
  highTouchReasons: string[]
  airtableTransitionRequestId?: string | null
  informingStatus: TransitionInformingStatus
  informingCategory?: TransitionInformingCategory | null
  hasDisabledSlackMessages: boolean
  matchingStatus: TransitionMatchingStatus
  handoverStatus?: TransitionHandoverStatus | null
  didSwitchAssistantsAt?: string | Date | null
  didSwitch1PasswordAt?: string | Date | null
  didRequestExecutiveFeedbackAt?: string | Date | null
  didFollowUpWithNewAssistantAt?: string | Date | null
  fromAssistant?:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  csm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
}

export type MatchingTransition_FullFragment = {
  __typename?: 'MatchingTransition'
  scheduleCallToken: string
  reasons: string[]
  id: string
  requestedOn: string
  requestedByUserCategory?: UserCategory | null
  deadlineOn?: string | null
  highTouchReasons: string[]
  airtableTransitionRequestId?: string | null
  handoverAt?: string | Date | null
  shouldDsmAttendHandover?: boolean | null
  notepad: string
  slackChannelId?: string | null
  hasDisabledSlackMessages: boolean
  informingStatus: TransitionInformingStatus
  informingCategory?: TransitionInformingCategory | null
  matchingStatus: TransitionMatchingStatus
  handoverStatus?: TransitionHandoverStatus | null
  didSwitchAssistantsAt?: string | Date | null
  didSwitch1PasswordAt?: string | Date | null
  didRequestExecutiveFeedbackAt?: string | Date | null
  didFollowUpWithNewAssistantAt?: string | Date | null
  airtableGenericNotes: {
    __typename?: 'AirtableGenericNote'
    id: string
    date?: string | null
    notepad?: string | null
  }[]
  fromAssistant?:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  csm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  sentMessages: {
    __typename?: 'MatchingTransitionSentMessage'
    id: string
    at: string | Date
    messageTemplate:
      | { __typename?: 'EmailMessageTemplate'; id: string }
      | { __typename?: 'SlackMessageTemplate'; id: string }
      | { __typename?: 'SmsMessageTemplate'; id: string }
      | { __typename?: 'TextMessageTemplate'; id: string }
  }[]
}

export type Matching_OnboardingCallAtFragment = {
  __typename?: 'Matching'
  id: string
  onboarding: {
    __typename?: 'MatchingOnboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  }
}

export type Matching_ForTransitionListItemFragment = {
  __typename?: 'Matching'
  completedAt?: string | Date | null
  id: string
  isHighTouch: boolean
  isHighTouchMaybe: boolean
  targetCandidatesCount: number
  executive?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  workspace?: { __typename?: 'Workspace'; id: string } | null
  transition?: {
    __typename?: 'MatchingTransition'
    id: string
    requestedOn: string
    requestedByUserCategory?: UserCategory | null
    deadlineOn?: string | null
    highTouchReasons: string[]
    airtableTransitionRequestId?: string | null
    informingStatus: TransitionInformingStatus
    informingCategory?: TransitionInformingCategory | null
    hasDisabledSlackMessages: boolean
    matchingStatus: TransitionMatchingStatus
    handoverStatus?: TransitionHandoverStatus | null
    didSwitchAssistantsAt?: string | Date | null
    didSwitch1PasswordAt?: string | Date | null
    didRequestExecutiveFeedbackAt?: string | Date | null
    didFollowUpWithNewAssistantAt?: string | Date | null
    fromAssistant?:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    csm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
  } | null
  sentNotDeclinedProposals: {
    __typename?: 'MatchingProposal'
    id: string
    sentAt?: string | Date | null
    reply?: MatchingProposalReply | null
    assistant:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  }[]
  candidates?:
    | {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
    | null
  confirmedAssistant?:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  onboarding: {
    __typename?: 'MatchingOnboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  }
}

export type Matching_ForTransitionFullFragment = {
  __typename?: 'Matching'
  completedAt?: string | Date | null
  id: string
  isHighTouch: boolean
  isHighTouchMaybe: boolean
  targetCandidatesCount: number
  executive?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        airtableClient?: {
          __typename?: 'AirtableClient'
          id: string
          successSegment?: string | null
        } | null
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          pricing: { __typename?: 'Pricing'; id: string; label: string }
        } | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        airtableClient?: {
          __typename?: 'AirtableClient'
          id: string
          successSegment?: string | null
        } | null
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          pricing: { __typename?: 'Pricing'; id: string; label: string }
        } | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        airtableClient?: {
          __typename?: 'AirtableClient'
          id: string
          successSegment?: string | null
        } | null
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          pricing: { __typename?: 'Pricing'; id: string; label: string }
        } | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  profiles: {
    __typename?: 'MatchingProfile'
    airtableLeadId?: string | null
    city?: string | null
    jobRole?: string | null
    companyIndustry?: string | null
    companySize?: string | null
    linkedInUrl?: string | null
    desiredAvailability?: string | null
    tools: string[]
    tasks: string[]
    eaPersonalityTraits: string[]
    id: string
    name?: string | null
    introCallDate?: string | null
    matchDueDate?: string | null
    internalMatchingDate?: string | null
    country?: string | null
    hoursNeededRange?: {
      __typename?: 'MatchingProfileHoursNeededRange'
      from: number
      to?: number | null
    } | null
    bioSections: {
      __typename?: 'MatchingProfileBioSection'
      title?: string | null
      text: string
    }[]
  }[]
  principalProfile?: {
    __typename?: 'MatchingProfile'
    airtableLeadId?: string | null
    id: string
    name?: string | null
  } | null
  transition?: {
    __typename?: 'MatchingTransition'
    scheduleCallToken: string
    reasons: string[]
    id: string
    requestedOn: string
    requestedByUserCategory?: UserCategory | null
    deadlineOn?: string | null
    highTouchReasons: string[]
    airtableTransitionRequestId?: string | null
    handoverAt?: string | Date | null
    shouldDsmAttendHandover?: boolean | null
    notepad: string
    slackChannelId?: string | null
    hasDisabledSlackMessages: boolean
    informingStatus: TransitionInformingStatus
    informingCategory?: TransitionInformingCategory | null
    matchingStatus: TransitionMatchingStatus
    handoverStatus?: TransitionHandoverStatus | null
    didSwitchAssistantsAt?: string | Date | null
    didSwitch1PasswordAt?: string | Date | null
    didRequestExecutiveFeedbackAt?: string | Date | null
    didFollowUpWithNewAssistantAt?: string | Date | null
    airtableGenericNotes: {
      __typename?: 'AirtableGenericNote'
      id: string
      date?: string | null
      notepad?: string | null
    }[]
    fromAssistant?:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    csm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    sentMessages: {
      __typename?: 'MatchingTransitionSentMessage'
      id: string
      at: string | Date
      messageTemplate:
        | { __typename?: 'EmailMessageTemplate'; id: string }
        | { __typename?: 'SlackMessageTemplate'; id: string }
        | { __typename?: 'SmsMessageTemplate'; id: string }
        | { __typename?: 'TextMessageTemplate'; id: string }
    }[]
  } | null
  candidates?:
    | {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        isInstantInvite: boolean
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
    | null
  workspace?: { __typename?: 'Workspace'; id: string } | null
  sentNotDeclinedProposals: {
    __typename?: 'MatchingProposal'
    id: string
    sentAt?: string | Date | null
    reply?: MatchingProposalReply | null
    assistant:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  }[]
  confirmedAssistant?:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  onboarding: {
    __typename?: 'MatchingOnboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  }
}

export type Matching_FullFragment = {
  __typename?: 'Matching'
  hasToBeMatched: boolean
  completedAt?: string | Date | null
  id: string
  bookmarksCount: number
  isHighTouch: boolean
  isHighTouchMaybe: boolean
  targetCandidatesCount: number
  isTransition: boolean
  isHiddenFromCatalog: boolean
  isTeamInvite?: boolean | null
  teamInvite?: {
    __typename?: 'MatchingTeamInvite'
    id: string
    isAllowedPersonal: boolean
    hoursLimit: number
    companyName?: string | null
  } | null
  profiles: {
    __typename?: 'MatchingProfile'
    airtableLeadId?: string | null
    city?: string | null
    jobRole?: string | null
    companyIndustry?: string | null
    companySize?: string | null
    linkedInUrl?: string | null
    desiredAvailability?: string | null
    tools: string[]
    tasks: string[]
    eaPersonalityTraits: string[]
    id: string
    name?: string | null
    introCallDate?: string | null
    matchDueDate?: string | null
    internalMatchingDate?: string | null
    country?: string | null
    hoursNeededRange?: {
      __typename?: 'MatchingProfileHoursNeededRange'
      from: number
      to?: number | null
    } | null
    bioSections: {
      __typename?: 'MatchingProfileBioSection'
      title?: string | null
      text: string
    }[]
  }[]
  principalProfile?: {
    __typename?: 'MatchingProfile'
    airtableLeadId?: string | null
    id: string
    name?: string | null
  } | null
  applications: {
    __typename?: 'MatchingApplication'
    id: string
    at?: string | Date | null
    motivation?: string | null
    assistant:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  }[]
  proposals: {
    __typename?: 'MatchingProposal'
    queuedAt: string | Date
    orderIndex: string
    sentAt?: string | Date | null
    slackChannelId?: string | null
    repliedAt?: string | Date | null
    replyComment?: string | null
    introCallAt?: string | Date | null
    id: string
    reply?: MatchingProposalReply | null
    isInstantInvite: boolean
    confirmedAt?: string | Date | null
    reasonForMatch?: string | null
    executiveReasonForMatch?: string | null
    additionalContext?: string | null
    application?: {
      __typename?: 'MatchingApplication'
      id: string
      at?: string | Date | null
      motivation?: string | null
    } | null
    assistant:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    confirmedBy?:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  }[]
  executive?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        startDate?: string | null
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        airtableClient?: {
          __typename?: 'AirtableClient'
          id: string
          successSegment?: string | null
        } | null
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          pricing: { __typename?: 'Pricing'; id: string; label: string }
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        startDate?: string | null
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        airtableClient?: {
          __typename?: 'AirtableClient'
          id: string
          successSegment?: string | null
        } | null
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          pricing: { __typename?: 'Pricing'; id: string; label: string }
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        startDate?: string | null
        category?: UserCategory | null
        principalUser?:
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        airtableClient?: {
          __typename?: 'AirtableClient'
          id: string
          successSegment?: string | null
        } | null
        currentPricing?: {
          __typename?: 'EntityPricing'
          id: string
          pricing: { __typename?: 'Pricing'; id: string; label: string }
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  workspace?: { __typename?: 'Workspace'; id: string } | null
  transition?: {
    __typename?: 'MatchingTransition'
    id: string
    requestedOn: string
    requestedByUserCategory?: UserCategory | null
    deadlineOn?: string | null
    highTouchReasons: string[]
    airtableTransitionRequestId?: string | null
    scheduleCallToken: string
    reasons: string[]
    informingStatus: TransitionInformingStatus
    informingCategory?: TransitionInformingCategory | null
    hasDisabledSlackMessages: boolean
    matchingStatus: TransitionMatchingStatus
    handoverStatus?: TransitionHandoverStatus | null
    didSwitchAssistantsAt?: string | Date | null
    didSwitch1PasswordAt?: string | Date | null
    didRequestExecutiveFeedbackAt?: string | Date | null
    didFollowUpWithNewAssistantAt?: string | Date | null
    handoverAt?: string | Date | null
    shouldDsmAttendHandover?: boolean | null
    notepad: string
    slackChannelId?: string | null
    fromAssistant?:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    csm?: {
      __typename?: 'HqUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    airtableGenericNotes: {
      __typename?: 'AirtableGenericNote'
      id: string
      date?: string | null
      notepad?: string | null
    }[]
    sentMessages: {
      __typename?: 'MatchingTransitionSentMessage'
      id: string
      at: string | Date
      messageTemplate:
        | { __typename?: 'EmailMessageTemplate'; id: string }
        | { __typename?: 'SlackMessageTemplate'; id: string }
        | { __typename?: 'SmsMessageTemplate'; id: string }
        | { __typename?: 'TextMessageTemplate'; id: string }
    }[]
  } | null
  sentNotDeclinedProposals: {
    __typename?: 'MatchingProposal'
    id: string
    sentAt?: string | Date | null
    reply?: MatchingProposalReply | null
    assistant:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  }[]
  candidates?:
    | {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        isInstantInvite: boolean
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
    | null
  confirmedAssistant?:
    | {
        __typename?: 'AssistantUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        slackUserId?: string | null
        id: string
        isArchived: boolean
        currency?: string | null
        hourlyRate?: number | null
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  onboarding: {
    __typename?: 'MatchingOnboarding'
    id: string
    isCompleted: boolean
    callStartAt?: string | Date | null
  }
}

export type ListMatchingsQueryVariables = Exact<{
  isArchived?: InputMaybe<Scalars['Boolean']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  isTransition?: InputMaybe<Scalars['Boolean']>
  isHiddenFromCatalog?: InputMaybe<Scalars['Boolean']>
  isPrincipalLead?: InputMaybe<Scalars['Boolean']>
  after?: InputMaybe<Scalars['String']>
}>

export type ListMatchingsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'MatchingsList'
    items: {
      __typename?: 'Matching'
      hasToBeMatched: boolean
      id: string
      bookmarksCount: number
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isTransition: boolean
      isHiddenFromCatalog: boolean
      completedAt?: string | Date | null
      isTeamInvite?: boolean | null
      profiles: {
        __typename?: 'MatchingProfile'
        id: string
        name?: string | null
        introCallDate?: string | null
        matchDueDate?: string | null
        internalMatchingDate?: string | null
        country?: string | null
      }[]
      proposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        isInstantInvite: boolean
      }[]
      applications: { __typename?: 'MatchingApplication'; id: string }[]
    }[]
  }
}

export type ListTransitionMatchingsQueryVariables = Exact<{
  isCompleted?: InputMaybe<Scalars['Boolean']>
  after?: InputMaybe<Scalars['String']>
}>

export type ListTransitionMatchingsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'MatchingsList'
    after?: string | null
    items: {
      __typename?: 'Matching'
      completedAt?: string | Date | null
      id: string
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      workspace?: { __typename?: 'Workspace'; id: string } | null
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        requestedOn: string
        requestedByUserCategory?: UserCategory | null
        deadlineOn?: string | null
        highTouchReasons: string[]
        airtableTransitionRequestId?: string | null
        informingStatus: TransitionInformingStatus
        informingCategory?: TransitionInformingCategory | null
        hasDisabledSlackMessages: boolean
        matchingStatus: TransitionMatchingStatus
        handoverStatus?: TransitionHandoverStatus | null
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
        fromAssistant?:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
      sentNotDeclinedProposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      candidates?:
        | {
            __typename?: 'MatchingProposal'
            id: string
            sentAt?: string | Date | null
            reply?: MatchingProposalReply | null
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
          }[]
        | null
      confirmedAssistant?:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      onboarding: {
        __typename?: 'MatchingOnboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      }
    }[]
  }
}

export type GetLatestExecutiveCompletedTransitionQueryVariables = Exact<{
  executiveId?: InputMaybe<Scalars['String']>
}>

export type GetLatestExecutiveCompletedTransitionQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'MatchingsList'
    total: number
    items: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        requestedOn: string
      } | null
    }[]
  }
}

export type GetMatchingByIdQueryVariables = Exact<{
  matchingId: Scalars['GUID']
}>

export type GetMatchingByIdQuery = {
  __typename?: 'Query'
  matching?: {
    __typename?: 'Matching'
    hasToBeMatched: boolean
    completedAt?: string | Date | null
    id: string
    bookmarksCount: number
    isHighTouch: boolean
    isHighTouchMaybe: boolean
    targetCandidatesCount: number
    isTransition: boolean
    isHiddenFromCatalog: boolean
    isTeamInvite?: boolean | null
    teamInvite?: {
      __typename?: 'MatchingTeamInvite'
      id: string
      isAllowedPersonal: boolean
      hoursLimit: number
      companyName?: string | null
    } | null
    profiles: {
      __typename?: 'MatchingProfile'
      airtableLeadId?: string | null
      city?: string | null
      jobRole?: string | null
      companyIndustry?: string | null
      companySize?: string | null
      linkedInUrl?: string | null
      desiredAvailability?: string | null
      tools: string[]
      tasks: string[]
      eaPersonalityTraits: string[]
      id: string
      name?: string | null
      introCallDate?: string | null
      matchDueDate?: string | null
      internalMatchingDate?: string | null
      country?: string | null
      hoursNeededRange?: {
        __typename?: 'MatchingProfileHoursNeededRange'
        from: number
        to?: number | null
      } | null
      bioSections: {
        __typename?: 'MatchingProfileBioSection'
        title?: string | null
        text: string
      }[]
    }[]
    principalProfile?: {
      __typename?: 'MatchingProfile'
      airtableLeadId?: string | null
      id: string
      name?: string | null
    } | null
    applications: {
      __typename?: 'MatchingApplication'
      id: string
      at?: string | Date | null
      motivation?: string | null
      assistant:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    }[]
    proposals: {
      __typename?: 'MatchingProposal'
      queuedAt: string | Date
      orderIndex: string
      sentAt?: string | Date | null
      slackChannelId?: string | null
      repliedAt?: string | Date | null
      replyComment?: string | null
      introCallAt?: string | Date | null
      id: string
      reply?: MatchingProposalReply | null
      isInstantInvite: boolean
      confirmedAt?: string | Date | null
      reasonForMatch?: string | null
      executiveReasonForMatch?: string | null
      additionalContext?: string | null
      application?: {
        __typename?: 'MatchingApplication'
        id: string
        at?: string | Date | null
        motivation?: string | null
      } | null
      assistant:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      confirmedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }[]
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          startDate?: string | null
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          airtableClient?: {
            __typename?: 'AirtableClient'
            id: string
            successSegment?: string | null
          } | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            pricing: { __typename?: 'Pricing'; id: string; label: string }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          startDate?: string | null
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          airtableClient?: {
            __typename?: 'AirtableClient'
            id: string
            successSegment?: string | null
          } | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            pricing: { __typename?: 'Pricing'; id: string; label: string }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          startDate?: string | null
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          airtableClient?: {
            __typename?: 'AirtableClient'
            id: string
            successSegment?: string | null
          } | null
          currentPricing?: {
            __typename?: 'EntityPricing'
            id: string
            pricing: { __typename?: 'Pricing'; id: string; label: string }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    workspace?: { __typename?: 'Workspace'; id: string } | null
    transition?: {
      __typename?: 'MatchingTransition'
      id: string
      requestedOn: string
      requestedByUserCategory?: UserCategory | null
      deadlineOn?: string | null
      highTouchReasons: string[]
      airtableTransitionRequestId?: string | null
      scheduleCallToken: string
      reasons: string[]
      informingStatus: TransitionInformingStatus
      informingCategory?: TransitionInformingCategory | null
      hasDisabledSlackMessages: boolean
      matchingStatus: TransitionMatchingStatus
      handoverStatus?: TransitionHandoverStatus | null
      didSwitchAssistantsAt?: string | Date | null
      didSwitch1PasswordAt?: string | Date | null
      didRequestExecutiveFeedbackAt?: string | Date | null
      didFollowUpWithNewAssistantAt?: string | Date | null
      handoverAt?: string | Date | null
      shouldDsmAttendHandover?: boolean | null
      notepad: string
      slackChannelId?: string | null
      fromAssistant?:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      csm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      airtableGenericNotes: {
        __typename?: 'AirtableGenericNote'
        id: string
        date?: string | null
        notepad?: string | null
      }[]
      sentMessages: {
        __typename?: 'MatchingTransitionSentMessage'
        id: string
        at: string | Date
        messageTemplate:
          | { __typename?: 'EmailMessageTemplate'; id: string }
          | { __typename?: 'SlackMessageTemplate'; id: string }
          | { __typename?: 'SmsMessageTemplate'; id: string }
          | { __typename?: 'TextMessageTemplate'; id: string }
      }[]
    } | null
    sentNotDeclinedProposals: {
      __typename?: 'MatchingProposal'
      id: string
      sentAt?: string | Date | null
      reply?: MatchingProposalReply | null
      assistant:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    }[]
    candidates?:
      | {
          __typename?: 'MatchingProposal'
          queuedAt: string | Date
          orderIndex: string
          sentAt?: string | Date | null
          slackChannelId?: string | null
          repliedAt?: string | Date | null
          replyComment?: string | null
          introCallAt?: string | Date | null
          id: string
          reply?: MatchingProposalReply | null
          confirmedAt?: string | Date | null
          reasonForMatch?: string | null
          executiveReasonForMatch?: string | null
          additionalContext?: string | null
          isInstantInvite: boolean
          assistant:
            | {
                __typename?: 'AssistantUser'
                slackUserId?: string | null
                id: string
                isArchived: boolean
                currency?: string | null
                hourlyRate?: number | null
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    countryCode?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                slackUserId?: string | null
                id: string
                isArchived: boolean
                currency?: string | null
                hourlyRate?: number | null
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    countryCode?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                slackUserId?: string | null
                id: string
                isArchived: boolean
                currency?: string | null
                hourlyRate?: number | null
                category?: UserCategory | null
                dsm?: {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                } | null
                city?: {
                  __typename?: 'Place'
                  id: string
                  address?: {
                    __typename?: 'PlaceAddress'
                    id: string
                    countryCode?: string | null
                  } | null
                } | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          application?: {
            __typename?: 'MatchingApplication'
            id: string
            at?: string | Date | null
            motivation?: string | null
          } | null
          confirmedBy?:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
        }[]
      | null
    confirmedAssistant?:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    onboarding: {
      __typename?: 'MatchingOnboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    }
  } | null
}

export type UpdateMatchingMutationVariables = Exact<{
  input: UpdateMatchingInput
}>

export type UpdateMatchingMutation = {
  __typename?: 'Mutation'
  updateMatching: {
    __typename?: 'UpdateMatchingOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isHiddenFromCatalog: boolean
    }
  }
}

export type UpsertMatchingProposalMutationVariables = Exact<{
  input: UpsertMatchingProposalInput
}>

export type UpsertMatchingProposalMutation = {
  __typename?: 'Mutation'
  upsertMatchingProposal: {
    __typename?: 'UpsertMatchingProposalOutput'
    matching: {
      __typename?: 'Matching'
      hasToBeMatched: boolean
      completedAt?: string | Date | null
      id: string
      bookmarksCount: number
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isTransition: boolean
      isHiddenFromCatalog: boolean
      isTeamInvite?: boolean | null
      teamInvite?: {
        __typename?: 'MatchingTeamInvite'
        id: string
        isAllowedPersonal: boolean
        hoursLimit: number
        companyName?: string | null
      } | null
      profiles: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        city?: string | null
        jobRole?: string | null
        companyIndustry?: string | null
        companySize?: string | null
        linkedInUrl?: string | null
        desiredAvailability?: string | null
        tools: string[]
        tasks: string[]
        eaPersonalityTraits: string[]
        id: string
        name?: string | null
        introCallDate?: string | null
        matchDueDate?: string | null
        internalMatchingDate?: string | null
        country?: string | null
        hoursNeededRange?: {
          __typename?: 'MatchingProfileHoursNeededRange'
          from: number
          to?: number | null
        } | null
        bioSections: {
          __typename?: 'MatchingProfileBioSection'
          title?: string | null
          text: string
        }[]
      }[]
      principalProfile?: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        id: string
        name?: string | null
      } | null
      applications: {
        __typename?: 'MatchingApplication'
        id: string
        at?: string | Date | null
        motivation?: string | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      proposals: {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        isInstantInvite: boolean
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      workspace?: { __typename?: 'Workspace'; id: string } | null
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        requestedOn: string
        requestedByUserCategory?: UserCategory | null
        deadlineOn?: string | null
        highTouchReasons: string[]
        airtableTransitionRequestId?: string | null
        scheduleCallToken: string
        reasons: string[]
        informingStatus: TransitionInformingStatus
        informingCategory?: TransitionInformingCategory | null
        hasDisabledSlackMessages: boolean
        matchingStatus: TransitionMatchingStatus
        handoverStatus?: TransitionHandoverStatus | null
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
        handoverAt?: string | Date | null
        shouldDsmAttendHandover?: boolean | null
        notepad: string
        slackChannelId?: string | null
        fromAssistant?:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        airtableGenericNotes: {
          __typename?: 'AirtableGenericNote'
          id: string
          date?: string | null
          notepad?: string | null
        }[]
        sentMessages: {
          __typename?: 'MatchingTransitionSentMessage'
          id: string
          at: string | Date
          messageTemplate:
            | { __typename?: 'EmailMessageTemplate'; id: string }
            | { __typename?: 'SlackMessageTemplate'; id: string }
            | { __typename?: 'SmsMessageTemplate'; id: string }
            | { __typename?: 'TextMessageTemplate'; id: string }
        }[]
      } | null
      sentNotDeclinedProposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      candidates?:
        | {
            __typename?: 'MatchingProposal'
            queuedAt: string | Date
            orderIndex: string
            sentAt?: string | Date | null
            slackChannelId?: string | null
            repliedAt?: string | Date | null
            replyComment?: string | null
            introCallAt?: string | Date | null
            id: string
            reply?: MatchingProposalReply | null
            confirmedAt?: string | Date | null
            reasonForMatch?: string | null
            executiveReasonForMatch?: string | null
            additionalContext?: string | null
            isInstantInvite: boolean
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            application?: {
              __typename?: 'MatchingApplication'
              id: string
              at?: string | Date | null
              motivation?: string | null
            } | null
            confirmedBy?:
              | {
                  __typename?: 'AssistantUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
          }[]
        | null
      confirmedAssistant?:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      onboarding: {
        __typename?: 'MatchingOnboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      }
    }
  }
}

export type SendMatchingProposalMutationVariables = Exact<{
  input: SendMatchingProposalInput
}>

export type SendMatchingProposalMutation = {
  __typename?: 'Mutation'
  sendMatchingProposal: {
    __typename?: 'SendMatchingProposalOutput'
    matching: {
      __typename?: 'Matching'
      hasToBeMatched: boolean
      completedAt?: string | Date | null
      id: string
      bookmarksCount: number
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isTransition: boolean
      isHiddenFromCatalog: boolean
      isTeamInvite?: boolean | null
      teamInvite?: {
        __typename?: 'MatchingTeamInvite'
        id: string
        isAllowedPersonal: boolean
        hoursLimit: number
        companyName?: string | null
      } | null
      profiles: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        city?: string | null
        jobRole?: string | null
        companyIndustry?: string | null
        companySize?: string | null
        linkedInUrl?: string | null
        desiredAvailability?: string | null
        tools: string[]
        tasks: string[]
        eaPersonalityTraits: string[]
        id: string
        name?: string | null
        introCallDate?: string | null
        matchDueDate?: string | null
        internalMatchingDate?: string | null
        country?: string | null
        hoursNeededRange?: {
          __typename?: 'MatchingProfileHoursNeededRange'
          from: number
          to?: number | null
        } | null
        bioSections: {
          __typename?: 'MatchingProfileBioSection'
          title?: string | null
          text: string
        }[]
      }[]
      principalProfile?: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        id: string
        name?: string | null
      } | null
      applications: {
        __typename?: 'MatchingApplication'
        id: string
        at?: string | Date | null
        motivation?: string | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      proposals: {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        isInstantInvite: boolean
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      workspace?: { __typename?: 'Workspace'; id: string } | null
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        requestedOn: string
        requestedByUserCategory?: UserCategory | null
        deadlineOn?: string | null
        highTouchReasons: string[]
        airtableTransitionRequestId?: string | null
        scheduleCallToken: string
        reasons: string[]
        informingStatus: TransitionInformingStatus
        informingCategory?: TransitionInformingCategory | null
        hasDisabledSlackMessages: boolean
        matchingStatus: TransitionMatchingStatus
        handoverStatus?: TransitionHandoverStatus | null
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
        handoverAt?: string | Date | null
        shouldDsmAttendHandover?: boolean | null
        notepad: string
        slackChannelId?: string | null
        fromAssistant?:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        airtableGenericNotes: {
          __typename?: 'AirtableGenericNote'
          id: string
          date?: string | null
          notepad?: string | null
        }[]
        sentMessages: {
          __typename?: 'MatchingTransitionSentMessage'
          id: string
          at: string | Date
          messageTemplate:
            | { __typename?: 'EmailMessageTemplate'; id: string }
            | { __typename?: 'SlackMessageTemplate'; id: string }
            | { __typename?: 'SmsMessageTemplate'; id: string }
            | { __typename?: 'TextMessageTemplate'; id: string }
        }[]
      } | null
      sentNotDeclinedProposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      candidates?:
        | {
            __typename?: 'MatchingProposal'
            queuedAt: string | Date
            orderIndex: string
            sentAt?: string | Date | null
            slackChannelId?: string | null
            repliedAt?: string | Date | null
            replyComment?: string | null
            introCallAt?: string | Date | null
            id: string
            reply?: MatchingProposalReply | null
            confirmedAt?: string | Date | null
            reasonForMatch?: string | null
            executiveReasonForMatch?: string | null
            additionalContext?: string | null
            isInstantInvite: boolean
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            application?: {
              __typename?: 'MatchingApplication'
              id: string
              at?: string | Date | null
              motivation?: string | null
            } | null
            confirmedBy?:
              | {
                  __typename?: 'AssistantUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
          }[]
        | null
      confirmedAssistant?:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      onboarding: {
        __typename?: 'MatchingOnboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      }
    }
  }
}

export type DequeueMatchingProposalMutationVariables = Exact<{
  input: DequeueMatchingProposalInput
}>

export type DequeueMatchingProposalMutation = {
  __typename?: 'Mutation'
  dequeueMatchingProposal: {
    __typename?: 'DequeueMatchingProposalOutput'
    matching: {
      __typename?: 'Matching'
      hasToBeMatched: boolean
      completedAt?: string | Date | null
      id: string
      bookmarksCount: number
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isTransition: boolean
      isHiddenFromCatalog: boolean
      isTeamInvite?: boolean | null
      teamInvite?: {
        __typename?: 'MatchingTeamInvite'
        id: string
        isAllowedPersonal: boolean
        hoursLimit: number
        companyName?: string | null
      } | null
      profiles: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        city?: string | null
        jobRole?: string | null
        companyIndustry?: string | null
        companySize?: string | null
        linkedInUrl?: string | null
        desiredAvailability?: string | null
        tools: string[]
        tasks: string[]
        eaPersonalityTraits: string[]
        id: string
        name?: string | null
        introCallDate?: string | null
        matchDueDate?: string | null
        internalMatchingDate?: string | null
        country?: string | null
        hoursNeededRange?: {
          __typename?: 'MatchingProfileHoursNeededRange'
          from: number
          to?: number | null
        } | null
        bioSections: {
          __typename?: 'MatchingProfileBioSection'
          title?: string | null
          text: string
        }[]
      }[]
      principalProfile?: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        id: string
        name?: string | null
      } | null
      applications: {
        __typename?: 'MatchingApplication'
        id: string
        at?: string | Date | null
        motivation?: string | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      proposals: {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        isInstantInvite: boolean
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      workspace?: { __typename?: 'Workspace'; id: string } | null
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        requestedOn: string
        requestedByUserCategory?: UserCategory | null
        deadlineOn?: string | null
        highTouchReasons: string[]
        airtableTransitionRequestId?: string | null
        scheduleCallToken: string
        reasons: string[]
        informingStatus: TransitionInformingStatus
        informingCategory?: TransitionInformingCategory | null
        hasDisabledSlackMessages: boolean
        matchingStatus: TransitionMatchingStatus
        handoverStatus?: TransitionHandoverStatus | null
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
        handoverAt?: string | Date | null
        shouldDsmAttendHandover?: boolean | null
        notepad: string
        slackChannelId?: string | null
        fromAssistant?:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        airtableGenericNotes: {
          __typename?: 'AirtableGenericNote'
          id: string
          date?: string | null
          notepad?: string | null
        }[]
        sentMessages: {
          __typename?: 'MatchingTransitionSentMessage'
          id: string
          at: string | Date
          messageTemplate:
            | { __typename?: 'EmailMessageTemplate'; id: string }
            | { __typename?: 'SlackMessageTemplate'; id: string }
            | { __typename?: 'SmsMessageTemplate'; id: string }
            | { __typename?: 'TextMessageTemplate'; id: string }
        }[]
      } | null
      sentNotDeclinedProposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      candidates?:
        | {
            __typename?: 'MatchingProposal'
            queuedAt: string | Date
            orderIndex: string
            sentAt?: string | Date | null
            slackChannelId?: string | null
            repliedAt?: string | Date | null
            replyComment?: string | null
            introCallAt?: string | Date | null
            id: string
            reply?: MatchingProposalReply | null
            confirmedAt?: string | Date | null
            reasonForMatch?: string | null
            executiveReasonForMatch?: string | null
            additionalContext?: string | null
            isInstantInvite: boolean
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            application?: {
              __typename?: 'MatchingApplication'
              id: string
              at?: string | Date | null
              motivation?: string | null
            } | null
            confirmedBy?:
              | {
                  __typename?: 'AssistantUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
          }[]
        | null
      confirmedAssistant?:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      onboarding: {
        __typename?: 'MatchingOnboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      }
    }
  }
}

export type ConfirmMatchingAssistantMutationVariables = Exact<{
  input: ConfirmMatchingAssistantInput
}>

export type ConfirmMatchingAssistantMutation = {
  __typename?: 'Mutation'
  confirmMatchingAssistant: {
    __typename?: 'ConfirmMatchingAssistantOutput'
    matching: {
      __typename?: 'Matching'
      hasToBeMatched: boolean
      completedAt?: string | Date | null
      id: string
      bookmarksCount: number
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isTransition: boolean
      isHiddenFromCatalog: boolean
      isTeamInvite?: boolean | null
      teamInvite?: {
        __typename?: 'MatchingTeamInvite'
        id: string
        isAllowedPersonal: boolean
        hoursLimit: number
        companyName?: string | null
      } | null
      profiles: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        city?: string | null
        jobRole?: string | null
        companyIndustry?: string | null
        companySize?: string | null
        linkedInUrl?: string | null
        desiredAvailability?: string | null
        tools: string[]
        tasks: string[]
        eaPersonalityTraits: string[]
        id: string
        name?: string | null
        introCallDate?: string | null
        matchDueDate?: string | null
        internalMatchingDate?: string | null
        country?: string | null
        hoursNeededRange?: {
          __typename?: 'MatchingProfileHoursNeededRange'
          from: number
          to?: number | null
        } | null
        bioSections: {
          __typename?: 'MatchingProfileBioSection'
          title?: string | null
          text: string
        }[]
      }[]
      principalProfile?: {
        __typename?: 'MatchingProfile'
        airtableLeadId?: string | null
        id: string
        name?: string | null
      } | null
      applications: {
        __typename?: 'MatchingApplication'
        id: string
        at?: string | Date | null
        motivation?: string | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      proposals: {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        isInstantInvite: boolean
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            startDate?: string | null
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            airtableClient?: {
              __typename?: 'AirtableClient'
              id: string
              successSegment?: string | null
            } | null
            currentPricing?: {
              __typename?: 'EntityPricing'
              id: string
              pricing: { __typename?: 'Pricing'; id: string; label: string }
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      workspace?: { __typename?: 'Workspace'; id: string } | null
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        requestedOn: string
        requestedByUserCategory?: UserCategory | null
        deadlineOn?: string | null
        highTouchReasons: string[]
        airtableTransitionRequestId?: string | null
        scheduleCallToken: string
        reasons: string[]
        informingStatus: TransitionInformingStatus
        informingCategory?: TransitionInformingCategory | null
        hasDisabledSlackMessages: boolean
        matchingStatus: TransitionMatchingStatus
        handoverStatus?: TransitionHandoverStatus | null
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
        handoverAt?: string | Date | null
        shouldDsmAttendHandover?: boolean | null
        notepad: string
        slackChannelId?: string | null
        fromAssistant?:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
        csm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        airtableGenericNotes: {
          __typename?: 'AirtableGenericNote'
          id: string
          date?: string | null
          notepad?: string | null
        }[]
        sentMessages: {
          __typename?: 'MatchingTransitionSentMessage'
          id: string
          at: string | Date
          messageTemplate:
            | { __typename?: 'EmailMessageTemplate'; id: string }
            | { __typename?: 'SlackMessageTemplate'; id: string }
            | { __typename?: 'SmsMessageTemplate'; id: string }
            | { __typename?: 'TextMessageTemplate'; id: string }
        }[]
      } | null
      sentNotDeclinedProposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
      }[]
      candidates?:
        | {
            __typename?: 'MatchingProposal'
            queuedAt: string | Date
            orderIndex: string
            sentAt?: string | Date | null
            slackChannelId?: string | null
            repliedAt?: string | Date | null
            replyComment?: string | null
            introCallAt?: string | Date | null
            id: string
            reply?: MatchingProposalReply | null
            confirmedAt?: string | Date | null
            reasonForMatch?: string | null
            executiveReasonForMatch?: string | null
            additionalContext?: string | null
            isInstantInvite: boolean
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  slackUserId?: string | null
                  id: string
                  isArchived: boolean
                  currency?: string | null
                  hourlyRate?: number | null
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      countryCode?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            application?: {
              __typename?: 'MatchingApplication'
              id: string
              at?: string | Date | null
              motivation?: string | null
            } | null
            confirmedBy?:
              | {
                  __typename?: 'AssistantUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
          }[]
        | null
      confirmedAssistant?:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      onboarding: {
        __typename?: 'MatchingOnboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      }
    }
  }
}

export type SendLoginEmailMutationVariables = Exact<{
  input: SendLoginEmailInput
}>

export type SendLoginEmailMutation = {
  __typename?: 'Mutation'
  sendLoginEmail: { __typename?: 'SendLoginEmailOutput'; email: string }
}

export type SendCsatFeedbackRequestMutationVariables = Exact<{
  input: SendCsatFeedbackRequestInput
}>

export type SendCsatFeedbackRequestMutation = {
  __typename?: 'Mutation'
  sendCsatFeedbackRequest: {
    __typename?: 'SendCsatFeedbackRequestOutput'
    isSuccess: boolean
  }
}

export type GetUserPhoneNumberQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetUserPhoneNumberQuery = {
  __typename?: 'Query'
  user:
    | { __typename?: 'AssistantUser'; id: string; phoneNumber?: string | null }
    | { __typename?: 'ExecutiveUser'; id: string; phoneNumber?: string | null }
    | { __typename?: 'HqUser'; id: string; phoneNumber?: string | null }
    | { __typename?: 'SandboxUser'; id: string; phoneNumber?: string | null }
}

export type SendSmsMutationVariables = Exact<{
  input: SendSmsInput
}>

export type SendSmsMutation = {
  __typename?: 'Mutation'
  sendSms: { __typename?: 'SendSmsOutput'; isSuccess: boolean }
}

export type LeverOpportunityFragment = {
  __typename?: 'LeverOpportunity'
  id: string
  displayName: string
  emails: string[]
  phoneNumbers: string[]
  location?: string | null
  postingTitle?: string | null
}

export type ListLeverOpportunitiesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  hiredAfter?: InputMaybe<Scalars['DateTime']>
  offeredAfter?: InputMaybe<Scalars['DateTime']>
  onboardingAfter?: InputMaybe<Scalars['DateTime']>
  isUser?: InputMaybe<Scalars['Boolean']>
  includePostingTitle: Scalars['Boolean']
}>

export type ListLeverOpportunitiesQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'LeverOpportunitiesList'
    after?: string | null
    items: ({
      __typename?: 'LeverOpportunity'
      id: string
      displayName: string
      emails: string[]
      phoneNumbers: string[]
      location?: string | null
      postingTitle?: string | null
    } | null)[]
  }
}

export type BasicAirtableLeadFragment = {
  __typename?: 'AirtableLead'
  id: string
  name?: string | null
  nextStep?: string | null
}

export type AirtableLeadFragment = {
  __typename?: 'AirtableLead'
  userId?: string | null
  email?: string | null
  country?: string | null
  city?: string | null
  reasonForMatch?: string | null
  id: string
  name?: string | null
  nextStep?: string | null
  doubles?:
    | ({
        __typename?: 'AirtableDouble'
        id: string
        userId?: string | null
        name?: string | null
      } | null)[]
    | null
  teamInvites?:
    | ({
        __typename?: 'AirtableTeamInvite'
        id: string
        hoursLimit?: number | null
        isAllowedPersonal: boolean
        teamLeads?:
          | ({
              __typename?: 'AirtableClient'
              id: string
              userId?: string | null
            } | null)[]
          | null
      } | null)[]
    | null
}

export type AirtableLead_BundlePrincipalFragment = {
  __typename?: 'AirtableLead'
  fields: Record<string, any>
  companyName?: string | null
  userId?: string | null
  email?: string | null
  country?: string | null
  city?: string | null
  reasonForMatch?: string | null
  id: string
  name?: string | null
  nextStep?: string | null
  teammatesForMatching: {
    __typename?: 'AirtableLead'
    id: string
    name?: string | null
    nextStep?: string | null
  }[]
  doubles?:
    | ({
        __typename?: 'AirtableDouble'
        id: string
        userId?: string | null
        name?: string | null
      } | null)[]
    | null
  teamInvites?:
    | ({
        __typename?: 'AirtableTeamInvite'
        id: string
        hoursLimit?: number | null
        isAllowedPersonal: boolean
        teamLeads?:
          | ({
              __typename?: 'AirtableClient'
              id: string
              userId?: string | null
            } | null)[]
          | null
      } | null)[]
    | null
}

export type ListAirtableLeadsQueryVariables = Exact<{
  isClient?: InputMaybe<Scalars['Boolean']>
  inNextSteps?: InputMaybe<Scalars['String'][] | Scalars['String']>
  nameContains?: InputMaybe<Scalars['String']>
}>

export type ListAirtableLeadsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'AirtableLeadsList'
    items: ({
      __typename?: 'AirtableLead'
      userId?: string | null
      email?: string | null
      country?: string | null
      city?: string | null
      reasonForMatch?: string | null
      id: string
      name?: string | null
      nextStep?: string | null
      doubles?:
        | ({
            __typename?: 'AirtableDouble'
            id: string
            userId?: string | null
            name?: string | null
          } | null)[]
        | null
      teamInvites?:
        | ({
            __typename?: 'AirtableTeamInvite'
            id: string
            hoursLimit?: number | null
            isAllowedPersonal: boolean
            teamLeads?:
              | ({
                  __typename?: 'AirtableClient'
                  id: string
                  userId?: string | null
                } | null)[]
              | null
          } | null)[]
        | null
    } | null)[]
  }
}

export type GetAirtableLeadByLeadRecordIdQueryVariables = Exact<{
  leadRecordId: Scalars['String']
}>

export type GetAirtableLeadByLeadRecordIdQuery = {
  __typename?: 'Query'
  leadRecord: {
    __typename?: 'AirtableLead'
    userId?: string | null
    email?: string | null
    country?: string | null
    city?: string | null
    reasonForMatch?: string | null
    id: string
    name?: string | null
    nextStep?: string | null
    doubles?:
      | ({
          __typename?: 'AirtableDouble'
          id: string
          userId?: string | null
          name?: string | null
        } | null)[]
      | null
    teamInvites?:
      | ({
          __typename?: 'AirtableTeamInvite'
          id: string
          hoursLimit?: number | null
          isAllowedPersonal: boolean
          teamLeads?:
            | ({
                __typename?: 'AirtableClient'
                id: string
                userId?: string | null
              } | null)[]
            | null
        } | null)[]
      | null
  }
}

export type GetAirtableBundlePrincipalByLeadRecordIdQueryVariables = Exact<{
  leadRecordId: Scalars['String']
}>

export type GetAirtableBundlePrincipalByLeadRecordIdQuery = {
  __typename?: 'Query'
  bundlePrincipal: {
    __typename?: 'AirtableLead'
    fields: Record<string, any>
    companyName?: string | null
    userId?: string | null
    email?: string | null
    country?: string | null
    city?: string | null
    reasonForMatch?: string | null
    id: string
    name?: string | null
    nextStep?: string | null
    teammatesForMatching: {
      __typename?: 'AirtableLead'
      id: string
      name?: string | null
      nextStep?: string | null
    }[]
    doubles?:
      | ({
          __typename?: 'AirtableDouble'
          id: string
          userId?: string | null
          name?: string | null
        } | null)[]
      | null
    teamInvites?:
      | ({
          __typename?: 'AirtableTeamInvite'
          id: string
          hoursLimit?: number | null
          isAllowedPersonal: boolean
          teamLeads?:
            | ({
                __typename?: 'AirtableClient'
                id: string
                userId?: string | null
              } | null)[]
            | null
        } | null)[]
      | null
  }
}

type Assistantable_ForTransition_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  slackUserId?: string | null
  id: string
  isArchived: boolean
  currency?: string | null
  hourlyRate?: number | null
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Assistantable_ForTransition_HqUser_Fragment = {
  __typename?: 'HqUser'
  slackUserId?: string | null
  id: string
  isArchived: boolean
  currency?: string | null
  hourlyRate?: number | null
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Assistantable_ForTransition_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  slackUserId?: string | null
  id: string
  isArchived: boolean
  currency?: string | null
  hourlyRate?: number | null
  category?: UserCategory | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type Assistantable_ForTransitionFragment =
  | Assistantable_ForTransition_AssistantUser_Fragment
  | Assistantable_ForTransition_HqUser_Fragment
  | Assistantable_ForTransition_SandboxUser_Fragment

export type AirtableTransitionRequestFragment = {
  __typename?: 'AirtableTransitionRequest'
  id: string
  submittedOn?: string | null
  submittedBy?: string | null
  completionDeadline?: string | null
  reasons?: string[] | null
  additionalFeedback?: string | null
  owner?: {
    __typename?: 'AirtableCollaborator'
    id: string
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  } | null
  airtableClient?: {
    __typename?: 'AirtableClient'
    id: string
    transitionsCount: number
    user?:
      | {
          __typename: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | { __typename?: 'ExecutiveUser'; id: string }
            | { __typename?: 'HqUser'; id: string }
            | { __typename?: 'SandboxUser'; id: string }
            | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | { __typename?: 'ExecutiveUser'; id: string }
            | { __typename?: 'HqUser'; id: string }
            | { __typename?: 'SandboxUser'; id: string }
            | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | { __typename?: 'ExecutiveUser'; id: string }
            | { __typename?: 'HqUser'; id: string }
            | { __typename?: 'SandboxUser'; id: string }
            | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  } | null
  airtableDouble?: {
    __typename?: 'AirtableDouble'
    id: string
    user?:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  } | null
}

export type CreateTransitionSlackChannelMutationVariables = Exact<{
  input: CreateTransitionSlackChannelInput
}>

export type CreateTransitionSlackChannelMutation = {
  __typename?: 'Mutation'
  createTransitionSlackChannel: {
    __typename?: 'CreateTransitionSlackChannelOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        slackChannelId?: string | null
        hasDisabledSlackMessages: boolean
      } | null
    }
  }
}

export type UpdateTransitionInformingMutationVariables = Exact<{
  input: UpdateTransitionInformingInput
}>

export type UpdateTransitionInformingMutation = {
  __typename?: 'Mutation'
  updateTransitionInforming: {
    __typename?: 'UpdateTransitionInformingOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        informingStatus: TransitionInformingStatus
        informingCategory?: TransitionInformingCategory | null
        hasDisabledSlackMessages: boolean
        csm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
      } | null
    }
  }
}

export type UpdateTransitionMatchingStatusMutationVariables = Exact<{
  input: UpdateTransitionMatchingStatusInput
}>

export type UpdateTransitionMatchingStatusMutation = {
  __typename?: 'Mutation'
  updateTransitionMatchingStatus: {
    __typename?: 'UpdateTransitionMatchingStatusOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        matchingStatus: TransitionMatchingStatus
      } | null
    }
  }
}

export type UpdateTransitionHandoverMutationVariables = Exact<{
  input: UpdateTransitionHandoverInput
}>

export type UpdateTransitionHandoverMutation = {
  __typename?: 'Mutation'
  updateTransitionHandover: {
    __typename?: 'UpdateTransitionHandoverOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        handoverAt?: string | Date | null
        shouldDsmAttendHandover?: boolean | null
        id: string
        handoverStatus?: TransitionHandoverStatus | null
      } | null
    }
  }
}

export type UpdateTransitionChangeoverMutationVariables = Exact<{
  input: UpdateTransitionChangeoverInput
}>

export type UpdateTransitionChangeoverMutation = {
  __typename?: 'Mutation'
  updateTransitionChangeover: {
    __typename?: 'UpdateTransitionChangeoverOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      onboarding: {
        __typename?: 'MatchingOnboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      }
    }
  }
}

export type UpdateTransitionNotepadMutationVariables = Exact<{
  input: UpdateTransitionNotepadInput
}>

export type UpdateTransitionNotepadMutation = {
  __typename?: 'Mutation'
  updateTransitionNotepad: {
    __typename?: 'UpdateTransitionNotepadOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        notepad: string
      } | null
    }
  }
}

export type SwitchTransitionAssistantsMutationVariables = Exact<{
  input: SwitchTransitionAssistantsInput
}>

export type SwitchTransitionAssistantsMutation = {
  __typename?: 'Mutation'
  switchTransitionAssistants: {
    __typename?: 'SwitchTransitionAssistantsOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      completedAt?: string | Date | null
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
      } | null
    }
  }
}

export type CompleteTransitionCallMutationVariables = Exact<{
  input: CompleteTransitionCallInput
}>

export type CompleteTransitionCallMutation = {
  __typename?: 'Mutation'
  completeTransitionCall: {
    __typename?: 'CompleteTransitionCallOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
      } | null
    }
  }
}

export type FollowUpWithNewTransitionAssistantMutationVariables = Exact<{
  input: FollowUpWithNewTransitionAssistantInput
}>

export type FollowUpWithNewTransitionAssistantMutation = {
  __typename?: 'Mutation'
  followUpWithNewTransitionAssistant: {
    __typename?: 'FollowUpWithNewTransitionAssistantOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
      } | null
    }
  }
}

export type ArchiveTransitionMutationVariables = Exact<{
  input: ArchiveTransitionInput
}>

export type ArchiveTransitionMutation = {
  __typename?: 'Mutation'
  archiveTransition: {
    __typename?: 'ArchiveTransitionOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
      } | null
    }
  }
}

export type UnarchiveTransitionMutationVariables = Exact<{
  input: UnarchiveTransitionInput
}>

export type UnarchiveTransitionMutation = {
  __typename?: 'Mutation'
  unarchiveTransition: {
    __typename?: 'UnarchiveTransitionOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        didSwitchAssistantsAt?: string | Date | null
        didSwitch1PasswordAt?: string | Date | null
        didRequestExecutiveFeedbackAt?: string | Date | null
        didFollowUpWithNewAssistantAt?: string | Date | null
      } | null
    }
  }
}

export type SentTransitionMessageMutationVariables = Exact<{
  input: SentTransitionMessageInput
}>

export type SentTransitionMessageMutation = {
  __typename?: 'Mutation'
  sentTransitionMessage: {
    __typename?: 'SentTransitionMessageOutput'
    matching: {
      __typename?: 'Matching'
      id: string
      transition?: {
        __typename?: 'MatchingTransition'
        id: string
        sentMessages: {
          __typename?: 'MatchingTransitionSentMessage'
          id: string
          at: string | Date
          messageTemplate:
            | { __typename?: 'EmailMessageTemplate'; id: string }
            | { __typename?: 'SlackMessageTemplate'; id: string }
            | { __typename?: 'SmsMessageTemplate'; id: string }
            | { __typename?: 'TextMessageTemplate'; id: string }
        }[]
      } | null
    }
  }
}

export type ReadTopicsMutationVariables = Exact<{
  input: ReadTopicsInput
}>

export type ReadTopicsMutation = {
  __typename?: 'Mutation'
  readTopics: { __typename?: 'ReadTopicsOutput'; isSuccess: boolean }
}

export type ClearTopicsMutationVariables = Exact<{
  input: ClearTopicsInput
}>

export type ClearTopicsMutation = {
  __typename?: 'Mutation'
  clearTopics: { __typename?: 'ClearTopicsOutput'; isSuccess: boolean }
}

export type DossierFolderHeaderFragment = {
  __typename?: 'DossierFolderHeader'
  title: string
  description: string
  isEnabled: boolean
}

export type DossierFolderFragment = {
  __typename?: 'DossierFolder'
  id: string
  orderIndex?: string | null
  label?: string | null
  isPrivate: boolean
  header?: {
    __typename?: 'DossierFolderHeader'
    title: string
    description: string
    isEnabled: boolean
  } | null
}

export type DossierSectionFragment = {
  __typename?: 'DossierSection'
  id: string
  orderIndex?: string | null
  label?: string | null
  emoji?: string | null
  isPrivate: boolean
  defaultInputType?: DossierInputableType | null
}

export type DossierFieldFragment = {
  __typename?: 'DossierField'
  id: string
  orderIndex?: string | null
  label?: string | null
  input?:
    | {
        __typename?: 'DossierInputableAnniversaryDate'
        maxValues?: number | null
      }
    | { __typename?: 'DossierInputableEmail'; maxValues?: number | null }
    | { __typename?: 'DossierInputablePhoneNumber'; maxValues?: number | null }
    | {
        __typename?: 'DossierInputablePlace'
        hint?: DossierInputablePlaceHint | null
        maxValues?: number | null
      }
    | {
        __typename?: 'DossierInputableText'
        isAllowingFreeForm: boolean
        freeFormPlaceholder?: string | null
        maxValues?: number | null
        options: {
          __typename?: 'DossierInputableTextOption'
          text: string
          isRecommended: boolean
        }[]
      }
    | null
}

export type GetStandardDossierFoldersQueryVariables = Exact<{
  [key: string]: never
}>

export type GetStandardDossierFoldersQuery = {
  __typename?: 'Query'
  standardDossierFolders: {
    __typename?: 'DossierFolder'
    id: string
    orderIndex?: string | null
    label?: string | null
    isPrivate: boolean
    sections: {
      __typename?: 'DossierSection'
      id: string
      orderIndex?: string | null
      label?: string | null
      emoji?: string | null
      isPrivate: boolean
      defaultInputType?: DossierInputableType | null
      fields: {
        __typename?: 'DossierField'
        id: string
        orderIndex?: string | null
        label?: string | null
        input?:
          | {
              __typename?: 'DossierInputableAnniversaryDate'
              maxValues?: number | null
            }
          | { __typename?: 'DossierInputableEmail'; maxValues?: number | null }
          | {
              __typename?: 'DossierInputablePhoneNumber'
              maxValues?: number | null
            }
          | {
              __typename?: 'DossierInputablePlace'
              hint?: DossierInputablePlaceHint | null
              maxValues?: number | null
            }
          | {
              __typename?: 'DossierInputableText'
              isAllowingFreeForm: boolean
              freeFormPlaceholder?: string | null
              maxValues?: number | null
              options: {
                __typename?: 'DossierInputableTextOption'
                text: string
                isRecommended: boolean
              }[]
            }
          | null
      }[]
    }[]
    header?: {
      __typename?: 'DossierFolderHeader'
      title: string
      description: string
      isEnabled: boolean
    } | null
  }[]
}

export type UpsertDossierFolderMutationVariables = Exact<{
  input: UpsertDossierFolderInput
}>

export type UpsertDossierFolderMutation = {
  __typename?: 'Mutation'
  upsertDossierFolder: {
    __typename?: 'UpsertDossierFolderOutput'
    folder: {
      __typename?: 'DossierFolder'
      id: string
      orderIndex?: string | null
      label?: string | null
      isPrivate: boolean
      header?: {
        __typename?: 'DossierFolderHeader'
        title: string
        description: string
        isEnabled: boolean
      } | null
    }
  }
}

export type UpsertDossierSectionMutationVariables = Exact<{
  input: UpsertDossierSectionInput
}>

export type UpsertDossierSectionMutation = {
  __typename?: 'Mutation'
  upsertDossierSection: {
    __typename?: 'UpsertDossierSectionOutput'
    section: {
      __typename?: 'DossierSection'
      id: string
      orderIndex?: string | null
      label?: string | null
      emoji?: string | null
      isPrivate: boolean
      defaultInputType?: DossierInputableType | null
    }
  }
}

export type UpsertDossierFieldMutationVariables = Exact<{
  input: UpsertDossierFieldInput
}>

export type UpsertDossierFieldMutation = {
  __typename?: 'Mutation'
  upsertDossierField: {
    __typename?: 'UpsertDossierFieldOutput'
    field: {
      __typename?: 'DossierField'
      id: string
      orderIndex?: string | null
      label?: string | null
      input?:
        | {
            __typename?: 'DossierInputableAnniversaryDate'
            maxValues?: number | null
          }
        | { __typename?: 'DossierInputableEmail'; maxValues?: number | null }
        | {
            __typename?: 'DossierInputablePhoneNumber'
            maxValues?: number | null
          }
        | {
            __typename?: 'DossierInputablePlace'
            hint?: DossierInputablePlaceHint | null
            maxValues?: number | null
          }
        | {
            __typename?: 'DossierInputableText'
            isAllowingFreeForm: boolean
            freeFormPlaceholder?: string | null
            maxValues?: number | null
            options: {
              __typename?: 'DossierInputableTextOption'
              text: string
              isRecommended: boolean
            }[]
          }
        | null
    }
  }
}

export type OrientationFragment = {
  __typename?: 'Orientation'
  id: string
  isCompleted: boolean
  hasEnabledGoogle2Fa: boolean
  hasJoinedSlack: boolean
  hasFilledProfile: boolean
  hasSeenIntro: boolean
  hasCompletedSetup: boolean
  hasAppAccess: boolean
  sentPreDay1Email: boolean
  sentPostDay1Email: boolean
  sentPostDay2Email: boolean
  sentPostDay3Email: boolean
}

type Userable_InOrientation_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
    hasEnabledGoogle2Fa: boolean
    hasJoinedSlack: boolean
    hasFilledProfile: boolean
    hasSeenIntro: boolean
    hasCompletedSetup: boolean
    hasAppAccess: boolean
    sentPreDay1Email: boolean
    sentPostDay1Email: boolean
    sentPostDay2Email: boolean
    sentPostDay3Email: boolean
  } | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_InOrientation_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_InOrientation_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
    hasEnabledGoogle2Fa: boolean
    hasJoinedSlack: boolean
    hasFilledProfile: boolean
    hasSeenIntro: boolean
    hasCompletedSetup: boolean
    hasAppAccess: boolean
    sentPreDay1Email: boolean
    sentPostDay1Email: boolean
    sentPostDay2Email: boolean
    sentPostDay3Email: boolean
  } | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

type Userable_InOrientation_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  orientation?: {
    __typename?: 'Orientation'
    id: string
    isCompleted: boolean
    hasEnabledGoogle2Fa: boolean
    hasJoinedSlack: boolean
    hasFilledProfile: boolean
    hasSeenIntro: boolean
    hasCompletedSetup: boolean
    hasAppAccess: boolean
    sentPreDay1Email: boolean
    sentPostDay1Email: boolean
    sentPostDay2Email: boolean
    sentPostDay3Email: boolean
  } | null
  dsm?: {
    __typename?: 'HqUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type Userable_InOrientationFragment =
  | Userable_InOrientation_AssistantUser_Fragment
  | Userable_InOrientation_ExecutiveUser_Fragment
  | Userable_InOrientation_HqUser_Fragment
  | Userable_InOrientation_SandboxUser_Fragment

export type ListAssistantsInOrientationQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  categories?: InputMaybe<UserCategory[] | UserCategory>
}>

export type ListAssistantsInOrientationQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'UsersList'
    after?: string | null
    items: (
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
            hasEnabledGoogle2Fa: boolean
            hasJoinedSlack: boolean
            hasFilledProfile: boolean
            hasSeenIntro: boolean
            hasCompletedSetup: boolean
            hasAppAccess: boolean
            sentPreDay1Email: boolean
            sentPostDay1Email: boolean
            sentPostDay2Email: boolean
            sentPostDay3Email: boolean
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
            hasEnabledGoogle2Fa: boolean
            hasJoinedSlack: boolean
            hasFilledProfile: boolean
            hasSeenIntro: boolean
            hasCompletedSetup: boolean
            hasAppAccess: boolean
            sentPreDay1Email: boolean
            sentPostDay1Email: boolean
            sentPostDay2Email: boolean
            sentPostDay3Email: boolean
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
            hasEnabledGoogle2Fa: boolean
            hasJoinedSlack: boolean
            hasFilledProfile: boolean
            hasSeenIntro: boolean
            hasCompletedSetup: boolean
            hasAppAccess: boolean
            sentPreDay1Email: boolean
            sentPostDay1Email: boolean
            sentPostDay2Email: boolean
            sentPostDay3Email: boolean
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
  }
}

export type ListExecutivesStripeCustomerQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']>
  userIds?: InputMaybe<Scalars['ID'][] | Scalars['ID']>
  invoiceStatus?: InputMaybe<StripeInvoiceStatus[] | StripeInvoiceStatus>
  invoicesFirst?: InputMaybe<Scalars['NonNegativeInt']>
  invoicesCreatedAfter?: InputMaybe<Scalars['DateTime']>
  invoicesCreatedBefore?: InputMaybe<Scalars['DateTime']>
}>

export type ListExecutivesStripeCustomerQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'UsersList'
    items: (
      | { __typename?: 'AssistantUser'; id: string }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
            invoices: {
              __typename?: 'StripeInvoice'
              id: string
              description?: string | null
              amount: number
              currency: string
              status: StripeInvoiceStatus
              createdAt: string | Date
              periodStartAt?: string | Date | null
              periodEndAt?: string | Date | null
              lineItems: {
                __typename?: 'StripeInvoiceLineItem'
                id: string
                amount: number
                currency: string
                description?: string | null
              }[]
            }[]
          } | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
            invoices: {
              __typename?: 'StripeInvoice'
              id: string
              description?: string | null
              amount: number
              currency: string
              status: StripeInvoiceStatus
              createdAt: string | Date
              periodStartAt?: string | Date | null
              periodEndAt?: string | Date | null
              lineItems: {
                __typename?: 'StripeInvoiceLineItem'
                id: string
                amount: number
                currency: string
                description?: string | null
              }[]
            }[]
          } | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          stripeCustomer?: {
            __typename?: 'StripeCustomer'
            id: string
            paymentStatus: StripeCustomerPaymentStatus
            invoices: {
              __typename?: 'StripeInvoice'
              id: string
              description?: string | null
              amount: number
              currency: string
              status: StripeInvoiceStatus
              createdAt: string | Date
              periodStartAt?: string | Date | null
              periodEndAt?: string | Date | null
              lineItems: {
                __typename?: 'StripeInvoiceLineItem'
                id: string
                amount: number
                currency: string
                description?: string | null
              }[]
            }[]
          } | null
        }
    )[]
  }
}

export type UpdateOrientationMutationVariables = Exact<{
  input: UpdateOrientationInput
}>

export type UpdateOrientationMutation = {
  __typename?: 'Mutation'
  updateOrientation: {
    __typename?: 'UpdateOrientationOutput'
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
            hasEnabledGoogle2Fa: boolean
            hasJoinedSlack: boolean
            hasFilledProfile: boolean
            hasSeenIntro: boolean
            hasCompletedSetup: boolean
            hasAppAccess: boolean
            sentPreDay1Email: boolean
            sentPostDay1Email: boolean
            sentPostDay2Email: boolean
            sentPostDay3Email: boolean
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
            hasEnabledGoogle2Fa: boolean
            hasJoinedSlack: boolean
            hasFilledProfile: boolean
            hasSeenIntro: boolean
            hasCompletedSetup: boolean
            hasAppAccess: boolean
            sentPreDay1Email: boolean
            sentPostDay1Email: boolean
            sentPostDay2Email: boolean
            sentPostDay3Email: boolean
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
            hasEnabledGoogle2Fa: boolean
            hasJoinedSlack: boolean
            hasFilledProfile: boolean
            hasSeenIntro: boolean
            hasCompletedSetup: boolean
            hasAppAccess: boolean
            sentPreDay1Email: boolean
            sentPostDay1Email: boolean
            sentPostDay2Email: boolean
            sentPostDay3Email: boolean
          } | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    )[]
  }
}

export type DelayFragment = {
  __typename?: 'Delay'
  value: number
  unit: DelayUnit
  hour?: number | null
  minute?: number | null
  weekday?: number | null
  day?: number | null
  timeZone?: string | null
}

export type AutomationParameterFragment = {
  __typename?: 'AutomationParameter'
  name: string
  type: string
  isRequired: boolean
}

export type AutomationTriggerFragment = {
  __typename?: 'AutomationTrigger'
  id: string
  description?: string | null
  parameters: ({
    __typename?: 'AutomationParameter'
    name: string
    type: string
    isRequired: boolean
  } | null)[]
}

export type AutomationAction_NoConfigFragment = {
  __typename?: 'AutomationAction'
  id: string
  name: string
  description?: string | null
}

export type AutomationActionFragment = {
  __typename?: 'AutomationAction'
  config?: Record<string, any> | null
  id: string
  name: string
  description?: string | null
}

export type AutomationActionGroupFragment = {
  __typename?: 'AutomationActionGroup'
  actions: {
    __typename?: 'AutomationAction'
    config?: Record<string, any> | null
    id: string
    name: string
    description?: string | null
  }[]
}

export type Automation_NameFragment = {
  __typename?: 'Automation'
  id: string
  name: string
}

export type AutomationFragment = {
  __typename?: 'Automation'
  description?: string | null
  isEnabled: boolean
  id: string
  name: string
  triggerOn: {
    __typename?: 'AutomationTrigger'
    id: string
    description?: string | null
    parameters: ({
      __typename?: 'AutomationParameter'
      name: string
      type: string
      isRequired: boolean
    } | null)[]
  }
  cancelOn: {
    __typename?: 'AutomationTrigger'
    id: string
    description?: string | null
    parameters: ({
      __typename?: 'AutomationParameter'
      name: string
      type: string
      isRequired: boolean
    } | null)[]
  }[]
  delay: {
    __typename?: 'Delay'
    value: number
    unit: DelayUnit
    hour?: number | null
    minute?: number | null
    weekday?: number | null
    day?: number | null
    timeZone?: string | null
  }
  actionGroups: {
    __typename?: 'AutomationActionGroup'
    actions: {
      __typename?: 'AutomationAction'
      config?: Record<string, any> | null
      id: string
      name: string
      description?: string | null
    }[]
  }[]
}

export type AutomationJobLogFragment = {
  __typename?: 'AutomationJobLog'
  at: string | Date
  level?: string | null
  message?: string | null
  meta?: Record<string, any> | null
  action?: {
    __typename?: 'AutomationAction'
    id: string
    name: string
    description?: string | null
  } | null
}

export type AutomationJob_ItemFragment = {
  __typename?: 'AutomationJob'
  id: string
  delayUntil: string | Date
  createdAt: string | Date
  startedAt?: string | Date | null
  endedAt?: string | Date | null
  cancelledAt?: string | Date | null
  updatedAt: string | Date
  reviewedAt?: string | Date | null
  hasError: boolean
  workspace?: {
    __typename?: 'Workspace'
    id: string
    isPendingTransition: boolean
    hasCoverage: boolean
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  } | null
  user?:
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  executive?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  assistant?:
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  automation: { __typename?: 'Automation'; id: string; name: string }
}

export type AutomationJob_FullFragment = {
  __typename?: 'AutomationJob'
  params?: Record<string, any> | null
  results?: Record<string, any> | null
  id: string
  delayUntil: string | Date
  createdAt: string | Date
  startedAt?: string | Date | null
  endedAt?: string | Date | null
  cancelledAt?: string | Date | null
  updatedAt: string | Date
  reviewedAt?: string | Date | null
  hasError: boolean
  reviewedBy?:
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  logs: {
    __typename?: 'AutomationJobLog'
    at: string | Date
    level?: string | null
    message?: string | null
    meta?: Record<string, any> | null
    action?: {
      __typename?: 'AutomationAction'
      id: string
      name: string
      description?: string | null
    } | null
  }[]
  automation: {
    __typename?: 'Automation'
    description?: string | null
    isEnabled: boolean
    id: string
    name: string
    triggerOn: {
      __typename?: 'AutomationTrigger'
      id: string
      description?: string | null
      parameters: ({
        __typename?: 'AutomationParameter'
        name: string
        type: string
        isRequired: boolean
      } | null)[]
    }
    cancelOn: {
      __typename?: 'AutomationTrigger'
      id: string
      description?: string | null
      parameters: ({
        __typename?: 'AutomationParameter'
        name: string
        type: string
        isRequired: boolean
      } | null)[]
    }[]
    delay: {
      __typename?: 'Delay'
      value: number
      unit: DelayUnit
      hour?: number | null
      minute?: number | null
      weekday?: number | null
      day?: number | null
      timeZone?: string | null
    }
    actionGroups: {
      __typename?: 'AutomationActionGroup'
      actions: {
        __typename?: 'AutomationAction'
        config?: Record<string, any> | null
        id: string
        name: string
        description?: string | null
      }[]
    }[]
  }
  workspace?: {
    __typename?: 'Workspace'
    id: string
    isPendingTransition: boolean
    hasCoverage: boolean
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  } | null
  user?:
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  executive?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  assistant?:
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
}

export type ListAutomationTriggersQueryVariables = Exact<{
  [key: string]: never
}>

export type ListAutomationTriggersQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'AutomationTriggersList'
    items: {
      __typename?: 'AutomationTrigger'
      id: string
      description?: string | null
      parameters: ({
        __typename?: 'AutomationParameter'
        name: string
        type: string
        isRequired: boolean
      } | null)[]
    }[]
  }
}

export type ListAutomationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  triggerIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  messageTemplateIds?: InputMaybe<Scalars['GUID'][] | Scalars['GUID']>
}>

export type ListAutomationsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'AutomationsList'
    after?: string | null
    items: {
      __typename?: 'Automation'
      description?: string | null
      isEnabled: boolean
      id: string
      name: string
      triggerOn: {
        __typename?: 'AutomationTrigger'
        id: string
        description?: string | null
        parameters: ({
          __typename?: 'AutomationParameter'
          name: string
          type: string
          isRequired: boolean
        } | null)[]
      }
      cancelOn: {
        __typename?: 'AutomationTrigger'
        id: string
        description?: string | null
        parameters: ({
          __typename?: 'AutomationParameter'
          name: string
          type: string
          isRequired: boolean
        } | null)[]
      }[]
      delay: {
        __typename?: 'Delay'
        value: number
        unit: DelayUnit
        hour?: number | null
        minute?: number | null
        weekday?: number | null
        day?: number | null
        timeZone?: string | null
      }
      actionGroups: {
        __typename?: 'AutomationActionGroup'
        actions: {
          __typename?: 'AutomationAction'
          config?: Record<string, any> | null
          id: string
          name: string
          description?: string | null
        }[]
      }[]
    }[]
  }
}

export type ListAutomationJobsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']>
  before?: InputMaybe<Scalars['String']>
  automationIds?: InputMaybe<Scalars['GUID'][] | Scalars['GUID']>
  workspaceId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  hasCompleted?: InputMaybe<Scalars['Boolean']>
  isPending?: InputMaybe<Scalars['Boolean']>
  hasFailed?: InputMaybe<Scalars['Boolean']>
  hasError?: InputMaybe<Scalars['Boolean']>
  isReviewed?: InputMaybe<Scalars['Boolean']>
  hasParams?: InputMaybe<Scalars['JSONObject']>
}>

export type ListAutomationJobsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'AutomationJobsList'
    before?: string | null
    items: {
      __typename?: 'AutomationJob'
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      automation: { __typename?: 'Automation'; id: string; name: string }
    }[]
  }
}

export type GetAutomationJobByIdQueryVariables = Exact<{
  id: Scalars['GUID']
}>

export type GetAutomationJobByIdQuery = {
  __typename?: 'Query'
  automationJob: {
    __typename?: 'AutomationJob'
    params?: Record<string, any> | null
    results?: Record<string, any> | null
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    reviewedBy?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    logs: {
      __typename?: 'AutomationJobLog'
      at: string | Date
      level?: string | null
      message?: string | null
      meta?: Record<string, any> | null
      action?: {
        __typename?: 'AutomationAction'
        id: string
        name: string
        description?: string | null
      } | null
    }[]
    automation: {
      __typename?: 'Automation'
      description?: string | null
      isEnabled: boolean
      id: string
      name: string
      triggerOn: {
        __typename?: 'AutomationTrigger'
        id: string
        description?: string | null
        parameters: ({
          __typename?: 'AutomationParameter'
          name: string
          type: string
          isRequired: boolean
        } | null)[]
      }
      cancelOn: {
        __typename?: 'AutomationTrigger'
        id: string
        description?: string | null
        parameters: ({
          __typename?: 'AutomationParameter'
          name: string
          type: string
          isRequired: boolean
        } | null)[]
      }[]
      delay: {
        __typename?: 'Delay'
        value: number
        unit: DelayUnit
        hour?: number | null
        minute?: number | null
        weekday?: number | null
        day?: number | null
        timeZone?: string | null
      }
      actionGroups: {
        __typename?: 'AutomationActionGroup'
        actions: {
          __typename?: 'AutomationAction'
          config?: Record<string, any> | null
          id: string
          name: string
          description?: string | null
        }[]
      }[]
    }
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  }
}

export type ReviewAutomationJobMutationVariables = Exact<{
  input: ReviewAutomationJobInput
}>

export type ReviewAutomationJobMutation = {
  __typename?: 'Mutation'
  reviewAutomationJob: {
    __typename?: 'ReviewAutomationJobOutput'
    automationJob: {
      __typename?: 'AutomationJob'
      params?: Record<string, any> | null
      results?: Record<string, any> | null
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      reviewedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      logs: {
        __typename?: 'AutomationJobLog'
        at: string | Date
        level?: string | null
        message?: string | null
        meta?: Record<string, any> | null
        action?: {
          __typename?: 'AutomationAction'
          id: string
          name: string
          description?: string | null
        } | null
      }[]
      automation: {
        __typename?: 'Automation'
        description?: string | null
        isEnabled: boolean
        id: string
        name: string
        triggerOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }
        cancelOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }[]
        delay: {
          __typename?: 'Delay'
          value: number
          unit: DelayUnit
          hour?: number | null
          minute?: number | null
          weekday?: number | null
          day?: number | null
          timeZone?: string | null
        }
        actionGroups: {
          __typename?: 'AutomationActionGroup'
          actions: {
            __typename?: 'AutomationAction'
            config?: Record<string, any> | null
            id: string
            name: string
            description?: string | null
          }[]
        }[]
      }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }
  }
}

export type CancelAutomationJobMutationVariables = Exact<{
  input: CancelAutomationJobInput
}>

export type CancelAutomationJobMutation = {
  __typename?: 'Mutation'
  cancelAutomationJob: {
    __typename?: 'CancelAutomationJobOutput'
    automationJob: {
      __typename?: 'AutomationJob'
      params?: Record<string, any> | null
      results?: Record<string, any> | null
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      reviewedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      logs: {
        __typename?: 'AutomationJobLog'
        at: string | Date
        level?: string | null
        message?: string | null
        meta?: Record<string, any> | null
        action?: {
          __typename?: 'AutomationAction'
          id: string
          name: string
          description?: string | null
        } | null
      }[]
      automation: {
        __typename?: 'Automation'
        description?: string | null
        isEnabled: boolean
        id: string
        name: string
        triggerOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }
        cancelOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }[]
        delay: {
          __typename?: 'Delay'
          value: number
          unit: DelayUnit
          hour?: number | null
          minute?: number | null
          weekday?: number | null
          day?: number | null
          timeZone?: string | null
        }
        actionGroups: {
          __typename?: 'AutomationActionGroup'
          actions: {
            __typename?: 'AutomationAction'
            config?: Record<string, any> | null
            id: string
            name: string
            description?: string | null
          }[]
        }[]
      }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }
  }
}

export type CancelScheduledChurnMutationVariables = Exact<{
  input: CancelScheduledChurnInput
}>

export type CancelScheduledChurnMutation = {
  __typename?: 'Mutation'
  cancelScheduledChurn: {
    __typename?: 'CancelScheduledChurnOutput'
    canceledJobs: ({
      __typename?: 'AutomationJob'
      params?: Record<string, any> | null
      results?: Record<string, any> | null
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      reviewedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      logs: {
        __typename?: 'AutomationJobLog'
        at: string | Date
        level?: string | null
        message?: string | null
        meta?: Record<string, any> | null
        action?: {
          __typename?: 'AutomationAction'
          id: string
          name: string
          description?: string | null
        } | null
      }[]
      automation: {
        __typename?: 'Automation'
        description?: string | null
        isEnabled: boolean
        id: string
        name: string
        triggerOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }
        cancelOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }[]
        delay: {
          __typename?: 'Delay'
          value: number
          unit: DelayUnit
          hour?: number | null
          minute?: number | null
          weekday?: number | null
          day?: number | null
          timeZone?: string | null
        }
        actionGroups: {
          __typename?: 'AutomationActionGroup'
          actions: {
            __typename?: 'AutomationAction'
            config?: Record<string, any> | null
            id: string
            name: string
            description?: string | null
          }[]
        }[]
      }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    } | null)[]
  }
}

export type ScheduleAutopilotJobsMutationVariables = Exact<{
  input: ScheduleAutopilotJobsInput
}>

export type ScheduleAutopilotJobsMutation = {
  __typename?: 'Mutation'
  scheduleAutopilotJobs: {
    __typename?: 'ScheduleAutopilotJobsOutput'
    scheduledJobs: {
      __typename?: 'AutomationJob'
      params?: Record<string, any> | null
      results?: Record<string, any> | null
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      reviewedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      logs: {
        __typename?: 'AutomationJobLog'
        at: string | Date
        level?: string | null
        message?: string | null
        meta?: Record<string, any> | null
        action?: {
          __typename?: 'AutomationAction'
          id: string
          name: string
          description?: string | null
        } | null
      }[]
      automation: {
        __typename?: 'Automation'
        description?: string | null
        isEnabled: boolean
        id: string
        name: string
        triggerOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }
        cancelOn: {
          __typename?: 'AutomationTrigger'
          id: string
          description?: string | null
          parameters: ({
            __typename?: 'AutomationParameter'
            name: string
            type: string
            isRequired: boolean
          } | null)[]
        }[]
        delay: {
          __typename?: 'Delay'
          value: number
          unit: DelayUnit
          hour?: number | null
          minute?: number | null
          weekday?: number | null
          day?: number | null
          timeZone?: string | null
        }
        actionGroups: {
          __typename?: 'AutomationActionGroup'
          actions: {
            __typename?: 'AutomationAction'
            config?: Record<string, any> | null
            id: string
            name: string
            description?: string | null
          }[]
        }[]
      }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }[]
  }
}

export type RunAutopilotJobManuallyMutationVariables = Exact<{
  input: RunAutopilotJobManuallyInput
}>

export type RunAutopilotJobManuallyMutation = {
  __typename?: 'Mutation'
  runAutopilotJobManually: {
    __typename?: 'RunAutopilotJobManuallyOutput'
    isSuccess: boolean
  }
}

export type MessageTemplateLanguageFragment = {
  __typename?: 'MessageTemplateLanguage'
  id: string
  languageCode: string
  fields: Record<string, any>
  placeholders: string[]
}

type MessageTemplate_Labels_EmailMessageTemplate_Fragment = {
  __typename?: 'EmailMessageTemplate'
  id: string
  name: string
  description: string
}

type MessageTemplate_Labels_SlackMessageTemplate_Fragment = {
  __typename?: 'SlackMessageTemplate'
  id: string
  name: string
  description: string
}

type MessageTemplate_Labels_SmsMessageTemplate_Fragment = {
  __typename?: 'SmsMessageTemplate'
  id: string
  name: string
  description: string
}

type MessageTemplate_Labels_TextMessageTemplate_Fragment = {
  __typename?: 'TextMessageTemplate'
  id: string
  name: string
  description: string
}

export type MessageTemplate_LabelsFragment =
  | MessageTemplate_Labels_EmailMessageTemplate_Fragment
  | MessageTemplate_Labels_SlackMessageTemplate_Fragment
  | MessageTemplate_Labels_SmsMessageTemplate_Fragment
  | MessageTemplate_Labels_TextMessageTemplate_Fragment

type MessageTemplate_EmailMessageTemplate_Fragment = {
  __typename?: 'EmailMessageTemplate'
  id: string
  name: string
  description: string
  languages: {
    __typename?: 'MessageTemplateLanguage'
    id: string
    languageCode: string
    fields: Record<string, any>
    placeholders: string[]
  }[]
}

type MessageTemplate_SlackMessageTemplate_Fragment = {
  __typename?: 'SlackMessageTemplate'
  id: string
  name: string
  description: string
  languages: {
    __typename?: 'MessageTemplateLanguage'
    id: string
    languageCode: string
    fields: Record<string, any>
    placeholders: string[]
  }[]
}

type MessageTemplate_SmsMessageTemplate_Fragment = {
  __typename?: 'SmsMessageTemplate'
  id: string
  name: string
  description: string
  languages: {
    __typename?: 'MessageTemplateLanguage'
    id: string
    languageCode: string
    fields: Record<string, any>
    placeholders: string[]
  }[]
}

type MessageTemplate_TextMessageTemplate_Fragment = {
  __typename?: 'TextMessageTemplate'
  id: string
  name: string
  description: string
  languages: {
    __typename?: 'MessageTemplateLanguage'
    id: string
    languageCode: string
    fields: Record<string, any>
    placeholders: string[]
  }[]
}

export type MessageTemplateFragment =
  | MessageTemplate_EmailMessageTemplate_Fragment
  | MessageTemplate_SlackMessageTemplate_Fragment
  | MessageTemplate_SmsMessageTemplate_Fragment
  | MessageTemplate_TextMessageTemplate_Fragment

export type ListMessageTemplatesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  kinds?: InputMaybe<MessageTemplateKind[] | MessageTemplateKind>
  ids?: InputMaybe<Scalars['GUID'][] | Scalars['GUID']>
  q?: InputMaybe<Scalars['String']>
}>

export type ListMessageTemplatesQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'MessageTemplatesList'
    after?: string | null
    items: (
      | {
          __typename?: 'EmailMessageTemplate'
          id: string
          name: string
          description: string
        }
      | {
          __typename?: 'SlackMessageTemplate'
          id: string
          name: string
          description: string
        }
      | {
          __typename?: 'SmsMessageTemplate'
          id: string
          name: string
          description: string
        }
      | {
          __typename?: 'TextMessageTemplate'
          id: string
          name: string
          description: string
        }
    )[]
  }
}

export type GetMessageTemplateQueryVariables = Exact<{
  id: Scalars['GUID']
}>

export type GetMessageTemplateQuery = {
  __typename?: 'Query'
  messageTemplate:
    | {
        __typename?: 'EmailMessageTemplate'
        id: string
        name: string
        description: string
        languages: {
          __typename?: 'MessageTemplateLanguage'
          id: string
          languageCode: string
          fields: Record<string, any>
          placeholders: string[]
        }[]
      }
    | {
        __typename?: 'SlackMessageTemplate'
        id: string
        name: string
        description: string
        languages: {
          __typename?: 'MessageTemplateLanguage'
          id: string
          languageCode: string
          fields: Record<string, any>
          placeholders: string[]
        }[]
      }
    | {
        __typename?: 'SmsMessageTemplate'
        id: string
        name: string
        description: string
        languages: {
          __typename?: 'MessageTemplateLanguage'
          id: string
          languageCode: string
          fields: Record<string, any>
          placeholders: string[]
        }[]
      }
    | {
        __typename?: 'TextMessageTemplate'
        id: string
        name: string
        description: string
        languages: {
          __typename?: 'MessageTemplateLanguage'
          id: string
          languageCode: string
          fields: Record<string, any>
          placeholders: string[]
        }[]
      }
}

export type UpsertMessageTemplateMutationVariables = Exact<{
  input: UpsertMessageTemplateInput
}>

export type UpsertMessageTemplateMutation = {
  __typename?: 'Mutation'
  upsertMessageTemplate: {
    __typename?: 'UpsertMessageTemplateOutput'
    messageTemplate:
      | {
          __typename?: 'EmailMessageTemplate'
          id: string
          name: string
          description: string
          languages: {
            __typename?: 'MessageTemplateLanguage'
            id: string
            languageCode: string
            fields: Record<string, any>
            placeholders: string[]
          }[]
        }
      | {
          __typename?: 'SlackMessageTemplate'
          id: string
          name: string
          description: string
          languages: {
            __typename?: 'MessageTemplateLanguage'
            id: string
            languageCode: string
            fields: Record<string, any>
            placeholders: string[]
          }[]
        }
      | {
          __typename?: 'SmsMessageTemplate'
          id: string
          name: string
          description: string
          languages: {
            __typename?: 'MessageTemplateLanguage'
            id: string
            languageCode: string
            fields: Record<string, any>
            placeholders: string[]
          }[]
        }
      | {
          __typename?: 'TextMessageTemplate'
          id: string
          name: string
          description: string
          languages: {
            __typename?: 'MessageTemplateLanguage'
            id: string
            languageCode: string
            fields: Record<string, any>
            placeholders: string[]
          }[]
        }
  }
}

export type ScheduleOnboardingConfig_WritableFragment = {
  __typename?: 'ScheduleOnboardingConfig'
  id: string
  workingStartHour?: number | null
  workingEndHour?: number | null
  coveredTimeZones: string[]
  specialistEventCalendarId?: string | null
  externalEventCalendarId?: string | null
  availabilityCalendarIds: string[]
}

type Adminable_Config_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  languages: string[]
  id: string
  thirtyDayCheckInCallUrl?: string | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  adminRoles: {
    __typename?: 'AdminRole'
    id: string
    name: string
    description?: string | null
    inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
    operations: {
      __typename?: 'GuardedOperation'
      id: string
      typeName: string
      fieldName: string
      description?: string | null
    }[]
  }[]
  scheduleOnboardingConfig?: {
    __typename?: 'ScheduleOnboardingConfig'
    id: string
    workingStartHour?: number | null
    workingEndHour?: number | null
    coveredTimeZones: string[]
    specialistEventCalendarId?: string | null
    externalEventCalendarId?: string | null
    availabilityCalendarIds: string[]
    calendars: {
      __typename?: 'ScheduleOnboardingCalendar'
      id: string
      title: string
      isWritable: boolean
    }[]
  } | null
}

type Adminable_Config_HqUser_Fragment = {
  __typename?: 'HqUser'
  languages: string[]
  id: string
  thirtyDayCheckInCallUrl?: string | null
  city?: {
    __typename?: 'Place'
    id: string
    timeZone?: string | null
    googleMapsPlaceId?: string | null
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      formatted?: string | null
    } | null
  } | null
  adminRoles: {
    __typename?: 'AdminRole'
    id: string
    name: string
    description?: string | null
    inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
    operations: {
      __typename?: 'GuardedOperation'
      id: string
      typeName: string
      fieldName: string
      description?: string | null
    }[]
  }[]
  scheduleOnboardingConfig?: {
    __typename?: 'ScheduleOnboardingConfig'
    id: string
    workingStartHour?: number | null
    workingEndHour?: number | null
    coveredTimeZones: string[]
    specialistEventCalendarId?: string | null
    externalEventCalendarId?: string | null
    availabilityCalendarIds: string[]
    calendars: {
      __typename?: 'ScheduleOnboardingCalendar'
      id: string
      title: string
      isWritable: boolean
    }[]
  } | null
}

export type Adminable_ConfigFragment =
  | Adminable_Config_AssistantUser_Fragment
  | Adminable_Config_HqUser_Fragment

export type GetAdminableConfigQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetAdminableConfigQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        languages: string[]
        id: string
        thirtyDayCheckInCallUrl?: string | null
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        adminRoles: {
          __typename?: 'AdminRole'
          id: string
          name: string
          description?: string | null
          inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
          operations: {
            __typename?: 'GuardedOperation'
            id: string
            typeName: string
            fieldName: string
            description?: string | null
          }[]
        }[]
        scheduleOnboardingConfig?: {
          __typename?: 'ScheduleOnboardingConfig'
          id: string
          workingStartHour?: number | null
          workingEndHour?: number | null
          coveredTimeZones: string[]
          specialistEventCalendarId?: string | null
          externalEventCalendarId?: string | null
          availabilityCalendarIds: string[]
          calendars: {
            __typename?: 'ScheduleOnboardingCalendar'
            id: string
            title: string
            isWritable: boolean
          }[]
        } | null
      }
    | { __typename?: 'ExecutiveUser' }
    | {
        __typename?: 'HqUser'
        languages: string[]
        id: string
        thirtyDayCheckInCallUrl?: string | null
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        adminRoles: {
          __typename?: 'AdminRole'
          id: string
          name: string
          description?: string | null
          inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
          operations: {
            __typename?: 'GuardedOperation'
            id: string
            typeName: string
            fieldName: string
            description?: string | null
          }[]
        }[]
        scheduleOnboardingConfig?: {
          __typename?: 'ScheduleOnboardingConfig'
          id: string
          workingStartHour?: number | null
          workingEndHour?: number | null
          coveredTimeZones: string[]
          specialistEventCalendarId?: string | null
          externalEventCalendarId?: string | null
          availabilityCalendarIds: string[]
          calendars: {
            __typename?: 'ScheduleOnboardingCalendar'
            id: string
            title: string
            isWritable: boolean
          }[]
        } | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        biography?: string | null
        funFacts?: string | null
        skills: string[]
        mobileOs?: MobileOs | null
        leverId?: string | null
        yearsOfExperience?: number | null
        slackUsername?: string | null
        slackUserId?: string | null
        hasSlackPush: boolean
        personalEmail?: string | null
        schedulingUrl?: string | null
        primaryEmail?: string | null
        hourlyRate?: number | null
        currency?: string | null
        ein?: string | null
        justworksVendorName?: string | null
        justworksGivenName?: string | null
        justworksFamilyName?: string | null
      }
}

export type UpdateAdminableMutationVariables = Exact<{
  input: UpdateAdminableInput
}>

export type UpdateAdminableMutation = {
  __typename?: 'Mutation'
  updateAdminable: {
    __typename?: 'UpdateAdminableOutput'
    user:
      | {
          __typename?: 'AssistantUser'
          thirtyDayCheckInCallUrl?: string | null
          id: string
          scheduleOnboardingConfig?: {
            __typename?: 'ScheduleOnboardingConfig'
            id: string
            workingStartHour?: number | null
            workingEndHour?: number | null
            coveredTimeZones: string[]
            specialistEventCalendarId?: string | null
            externalEventCalendarId?: string | null
            availabilityCalendarIds: string[]
          } | null
        }
      | {
          __typename?: 'HqUser'
          thirtyDayCheckInCallUrl?: string | null
          id: string
          scheduleOnboardingConfig?: {
            __typename?: 'ScheduleOnboardingConfig'
            id: string
            workingStartHour?: number | null
            workingEndHour?: number | null
            coveredTimeZones: string[]
            specialistEventCalendarId?: string | null
            externalEventCalendarId?: string | null
            availabilityCalendarIds: string[]
          } | null
        }
  }
}

export type AssistantUser_CsatAverageFragment = {
  __typename?: 'AssistantUser'
  id: string
  csatAverage?: number | null
}

export type AssistantUser_PerformanceScoreBadgeFragment = {
  __typename?: 'AssistantUser'
  id: string
  performanceScoreValue?: number | null
}

export type PerformanceScoreMonthQueryVariables = Exact<{
  userId: Scalars['ID']
  date?: InputMaybe<Scalars['Date']>
}>

export type PerformanceScoreMonthQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        performanceScore?: {
          __typename?: 'AssistantPerformanceScoreSeries'
          id: string
          series: {
            __typename?: 'AssistantPerformanceScore'
            id: string
            date: string
            value: number
            metrics: {
              __typename?: 'AssistantPerformanceMetric'
              eventsReviewURL?: string | null
              weight: number
              id: string
              metricKind: AssistantPerformanceMetricKind
              value: number
            }[]
          }[]
        } | null
      }
    | { __typename?: 'ExecutiveUser' }
    | { __typename?: 'HqUser' }
    | { __typename?: 'SandboxUser' }
}

export type ScoreSeriesBasicInfoFragment = {
  __typename?: 'AssistantPerformanceScore'
  id: string
  date: string
  value: number
}

export type PerformanceScoreMonthSeriesFragment = {
  __typename?: 'AssistantPerformanceScore'
  id: string
  date: string
  value: number
  metrics: {
    __typename?: 'AssistantPerformanceMetric'
    eventsReviewURL?: string | null
    weight: number
    id: string
    metricKind: AssistantPerformanceMetricKind
    value: number
  }[]
}

export type AssistantUser_PerformanceScoreMetricsFragment = {
  __typename?: 'AssistantPerformanceMetric'
  eventsReviewURL?: string | null
  weight: number
  id: string
  metricKind: AssistantPerformanceMetricKind
  value: number
}

export type PerformanceScoreHistoricalQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type PerformanceScoreHistoricalQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        performanceScore?: {
          __typename?: 'AssistantPerformanceScoreSeries'
          id: string
          series: {
            __typename?: 'AssistantPerformanceScore'
            id: string
            date: string
            value: number
            metrics: {
              __typename?: 'AssistantPerformanceMetric'
              id: string
              metricKind: AssistantPerformanceMetricKind
              value: number
            }[]
          }[]
        } | null
      }
    | { __typename?: 'ExecutiveUser' }
    | { __typename?: 'HqUser' }
    | { __typename?: 'SandboxUser' }
}

export type PerformanceScoreHistoricalSeriesFragment = {
  __typename?: 'AssistantPerformanceScore'
  id: string
  date: string
  value: number
  metrics: {
    __typename?: 'AssistantPerformanceMetric'
    id: string
    metricKind: AssistantPerformanceMetricKind
    value: number
  }[]
}

export type PerformanceScoreEventsQueryVariables = Exact<{
  userId: Scalars['ID']
  date?: InputMaybe<Scalars['Date']>
}>

export type PerformanceScoreEventsQuery = {
  __typename?: 'Query'
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        performanceScore?: {
          __typename?: 'AssistantPerformanceScoreSeries'
          id: string
          series: {
            __typename?: 'AssistantPerformanceScore'
            id: string
            metrics: {
              __typename?: 'AssistantPerformanceMetric'
              id: string
              metricKind: AssistantPerformanceMetricKind
              events: {
                __typename?: 'AssistantPerformanceMetricEvent'
                id: string
                date: string
                executive?:
                  | {
                      __typename?: 'ExecutiveUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'HqUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | {
                      __typename?: 'SandboxUser'
                      id: string
                      isArchived: boolean
                      category?: UserCategory | null
                      profile: {
                        __typename?: 'UserProfile'
                        id: string
                        emails: string[]
                        avatarUrl?: string | null
                        email?: string | null
                        givenName?: string | null
                        familyName?: string | null
                        displayName?: string | null
                      }
                    }
                  | null
                tags: {
                  __typename?: 'AssistantPerformanceTag'
                  id: string
                  sentiment: AssistantPerformanceSentiment
                  text: string
                }[]
              }[]
            }[]
          }[]
        } | null
      }
    | { __typename?: 'ExecutiveUser' }
    | { __typename?: 'HqUser' }
    | { __typename?: 'SandboxUser' }
}

export type PerformanceScoreEventsSeriesFragment = {
  __typename?: 'AssistantPerformanceScore'
  id: string
  metrics: {
    __typename?: 'AssistantPerformanceMetric'
    id: string
    metricKind: AssistantPerformanceMetricKind
    events: {
      __typename?: 'AssistantPerformanceMetricEvent'
      id: string
      date: string
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      tags: {
        __typename?: 'AssistantPerformanceTag'
        id: string
        sentiment: AssistantPerformanceSentiment
        text: string
      }[]
    }[]
  }[]
}

export type AssistantUser_PerformanceScoreMetricsPartialFragment = {
  __typename?: 'AssistantPerformanceMetric'
  id: string
  metricKind: AssistantPerformanceMetricKind
  value: number
}

export type AssistantUser_PerformanceScoreMetricEventsFragment = {
  __typename?: 'AssistantPerformanceMetricEvent'
  id: string
  date: string
  executive?:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | null
  tags: {
    __typename?: 'AssistantPerformanceTag'
    id: string
    sentiment: AssistantPerformanceSentiment
    text: string
  }[]
}

export type AssistantUser_PerformanceScoreTagsFragment = {
  __typename?: 'AssistantPerformanceTag'
  id: string
  sentiment: AssistantPerformanceSentiment
  text: string
}

export type MetricEventPropertyFragment = {
  __typename?: 'MetricEventProperty'
  id: string
  name: string
  description?: string | null
}

export type MetricEventFragment = {
  __typename?: 'MetricEvent'
  id: string
  trackedEventName: string
  name: string
  description?: string | null
  apps: string[]
  sql: string
  properties: {
    __typename?: 'MetricEventProperty'
    id: string
    name: string
    description?: string | null
  }[]
}

export type ListMetricEventsQueryVariables = Exact<{ [key: string]: never }>

export type ListMetricEventsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'MetricEventsList'
    items: {
      __typename?: 'MetricEvent'
      id: string
      trackedEventName: string
      name: string
      description?: string | null
      apps: string[]
      sql: string
      properties: {
        __typename?: 'MetricEventProperty'
        id: string
        name: string
        description?: string | null
      }[]
    }[]
  }
}

export type UpdateMetricEventMutationVariables = Exact<{
  input: UpdateMetricEventInput
}>

export type UpdateMetricEventMutation = {
  __typename?: 'Mutation'
  updateMetricEvent: {
    __typename?: 'UpdateMetricEventOutput'
    metricEvent: {
      __typename?: 'MetricEvent'
      id: string
      trackedEventName: string
      name: string
      description?: string | null
      apps: string[]
      sql: string
      properties: {
        __typename?: 'MetricEventProperty'
        id: string
        name: string
        description?: string | null
      }[]
    }
  }
}

export type UpdateMetricEventPropertyMutationVariables = Exact<{
  input: UpdateMetricEventPropertyInput
}>

export type UpdateMetricEventPropertyMutation = {
  __typename?: 'Mutation'
  updateMetricEventProperty: {
    __typename?: 'UpdateMetricEventPropertyOutput'
    metricEvent: {
      __typename?: 'MetricEvent'
      id: string
      trackedEventName: string
      name: string
      description?: string | null
      apps: string[]
      sql: string
      properties: {
        __typename?: 'MetricEventProperty'
        id: string
        name: string
        description?: string | null
      }[]
    }
  }
}

export type TimeTrackingEntry_DateInfoFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  startedAt: string | Date
  endedAt?: string | Date | null
}

export type TimeTrackingEntry_TimeInfoFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  duration?: string | null
  startedAt: string | Date
  endedAt?: string | Date | null
}

export type TimeTrackingTagFragment = {
  __typename?: 'TimeTrackingTag'
  id: string
  label: string
}

export type TimeTrackingEntry_TaskInfoFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  title?: string | null
  tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
}

export type TimeTrackingEntry_TogglInfoFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  isToggl: boolean
}

export type TimeTrackingEntry_StatusInfoFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  lockedAt?: string | Date | null
  isToggl: boolean
}

export type TimeTrackingEntryFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  topicId?: string | null
  title?: string | null
  duration?: string | null
  lockedAt?: string | Date | null
  startedAt: string | Date
  endedAt?: string | Date | null
  isToggl: boolean
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  workspace?: {
    __typename?: 'Workspace'
    id: string
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
  } | null
  tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
}

export type TimeTrackingEntriesListQueryVariables = Exact<{
  assistantIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  executiveIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  workspaceIds?: InputMaybe<
    InputMaybe<Scalars['String']>[] | InputMaybe<Scalars['String']>
  >
  endedBefore?: InputMaybe<Scalars['DateTime']>
  endedAfter?: InputMaybe<Scalars['DateTime']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
}>

export type TimeTrackingEntriesListQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'TimeTrackingEntriesList'
    id: string
    after?: string | null
    items: {
      __typename?: 'TimeTrackingEntry'
      id: string
      topicId?: string | null
      title?: string | null
      duration?: string | null
      lockedAt?: string | Date | null
      startedAt: string | Date
      endedAt?: string | Date | null
      isToggl: boolean
      user:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
      } | null
      tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
    }[]
  }
}

export type TimeTrackingEntryUpsertedSubscriptionVariables = Exact<{
  assistantIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  executiveIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  workspaceIds?: InputMaybe<
    InputMaybe<Scalars['String']>[] | InputMaybe<Scalars['String']>
  >
  endedBefore?: InputMaybe<Scalars['DateTime']>
  endedAfter?: InputMaybe<Scalars['DateTime']>
}>

export type TimeTrackingEntryUpsertedSubscription = {
  __typename?: 'Subscription'
  item: {
    __typename?: 'TimeTrackingEntry'
    id: string
    topicId?: string | null
    title?: string | null
    duration?: string | null
    lockedAt?: string | Date | null
    startedAt: string | Date
    endedAt?: string | Date | null
    isToggl: boolean
    user:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    workspace?: {
      __typename?: 'Workspace'
      id: string
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
    } | null
    tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
  }
}

export type TimeTrackingGeneralInfosQueryVariables = Exact<{
  assistantIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  executiveIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  workspaceIds?: InputMaybe<
    InputMaybe<Scalars['String']>[] | InputMaybe<Scalars['String']>
  >
  endedBefore?: InputMaybe<Scalars['DateTime']>
  endedAfter?: InputMaybe<Scalars['DateTime']>
}>

export type TimeTrackingGeneralInfosQuery = {
  __typename?: 'Query'
  timeTrackingEntriesList: {
    __typename?: 'TimeTrackingEntriesList'
    id: string
    totalDuration?: string | null
    lockedAt?: string | Date | null
  }
}

export type TimeTrackingEntryTemplateFragment = {
  __typename?: 'TimeTrackingEntryTemplate'
  id: TimeTrackingEntryTemplateId
  title: string
}

export type GetTimeTrackingEntryTemplatesListQueryVariables = Exact<{
  isWorkspaced?: InputMaybe<Scalars['Boolean']>
}>

export type GetTimeTrackingEntryTemplatesListQuery = {
  __typename?: 'Query'
  timeTrackingEntryTemplatesList: {
    __typename?: 'TimeTrackingEntryTemplatesList'
    items: {
      __typename?: 'TimeTrackingEntryTemplate'
      id: TimeTrackingEntryTemplateId
      title: string
    }[]
  }
}

export type UpdateTimeTrackingEntryMutationVariables = Exact<{
  input: UpdateTimeTrackingEntryInput
}>

export type UpdateTimeTrackingEntryMutation = {
  __typename?: 'Mutation'
  updateTimeTrackingEntry: {
    __typename?: 'UpdateTimeTrackingEntryOutput'
    timeTrackingEntry: {
      __typename?: 'TimeTrackingEntry'
      id: string
      topicId?: string | null
      title?: string | null
      duration?: string | null
      lockedAt?: string | Date | null
      startedAt: string | Date
      endedAt?: string | Date | null
      isToggl: boolean
      user:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
      } | null
      tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
    }
  }
}

export type UpdateTimeTrackingEntryBillToMutationVariables = Exact<{
  input: UpdateTimeTrackingEntryBillToInput
}>

export type UpdateTimeTrackingEntryBillToMutation = {
  __typename?: 'Mutation'
  updateTimeTrackingEntryBillTo: {
    __typename?: 'UpdateTimeTrackingEntryBillToOutput'
    timeTrackingEntry: {
      __typename?: 'TimeTrackingEntry'
      id: string
      topicId?: string | null
      title?: string | null
      duration?: string | null
      lockedAt?: string | Date | null
      startedAt: string | Date
      endedAt?: string | Date | null
      isToggl: boolean
      user:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
      } | null
      tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
    }
  }
}

export type DeleteTimeTrackingEntryMutationVariables = Exact<{
  input: DeleteTimeTrackingEntryInput
}>

export type DeleteTimeTrackingEntryMutation = {
  __typename?: 'Mutation'
  deleteTimeTrackingEntry: {
    __typename?: 'DeleteTimeTrackingEntryOutput'
    timeTrackingEntry: { __typename?: 'TimeTrackingEntry'; id: string }
  }
}

export type LockTimeTrackingEntriesMutationVariables = Exact<{
  input: LockTimeTrackingEntriesInput
}>

export type LockTimeTrackingEntriesMutation = {
  __typename?: 'Mutation'
  lockTimeTrackingEntries: {
    __typename?: 'LockTimeTrackingEntriesOutput'
    isSuccess: boolean
  }
}

type Assistantable_TimeTrackingEntryBulk_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
      }[]
    | null
}

type Assistantable_TimeTrackingEntryBulk_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
      }[]
    | null
}

type Assistantable_TimeTrackingEntryBulk_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
      }[]
    | null
}

export type Assistantable_TimeTrackingEntryBulkFragment =
  | Assistantable_TimeTrackingEntryBulk_AssistantUser_Fragment
  | Assistantable_TimeTrackingEntryBulk_HqUser_Fragment
  | Assistantable_TimeTrackingEntryBulk_SandboxUser_Fragment

type Userable_TimeTrackingEntryBulk_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    displayName?: string | null
  }
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
      }[]
    | null
}

type Userable_TimeTrackingEntryBulk_ExecutiveUser_Fragment = {
  __typename?: 'ExecutiveUser'
  id: string
  profile: {
    __typename?: 'UserProfile'
    id: string
    displayName?: string | null
  }
}

type Userable_TimeTrackingEntryBulk_HqUser_Fragment = {
  __typename?: 'HqUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    displayName?: string | null
  }
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
      }[]
    | null
}

type Userable_TimeTrackingEntryBulk_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  id: string
  hourlyRate?: number | null
  currency?: string | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    displayName?: string | null
  }
  hourlyRateUpdates?:
    | {
        __typename?: 'AssistantableHourlyRateUpdate'
        hourlyRate: number
        updatedAt: string | Date
      }[]
    | null
}

export type Userable_TimeTrackingEntryBulkFragment =
  | Userable_TimeTrackingEntryBulk_AssistantUser_Fragment
  | Userable_TimeTrackingEntryBulk_ExecutiveUser_Fragment
  | Userable_TimeTrackingEntryBulk_HqUser_Fragment
  | Userable_TimeTrackingEntryBulk_SandboxUser_Fragment

export type TimeTrackingEntry_BulkFragment = {
  __typename?: 'TimeTrackingEntry'
  id: string
  title?: string | null
  isToggl: boolean
  duration?: string | null
  startedAt: string | Date
  endedAt?: string | Date | null
  tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        hourlyRate?: number | null
        currency?: string | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          displayName?: string | null
        }
        hourlyRateUpdates?:
          | {
              __typename?: 'AssistantableHourlyRateUpdate'
              hourlyRate: number
              updatedAt: string | Date
            }[]
          | null
      }
    | {
        __typename?: 'HqUser'
        id: string
        hourlyRate?: number | null
        currency?: string | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          displayName?: string | null
        }
        hourlyRateUpdates?:
          | {
              __typename?: 'AssistantableHourlyRateUpdate'
              hourlyRate: number
              updatedAt: string | Date
            }[]
          | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        hourlyRate?: number | null
        currency?: string | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          displayName?: string | null
        }
        hourlyRateUpdates?:
          | {
              __typename?: 'AssistantableHourlyRateUpdate'
              hourlyRate: number
              updatedAt: string | Date
            }[]
          | null
      }
  workspace?: {
    __typename?: 'Workspace'
    id: string
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          hourlyRate?: number | null
          currency?: string | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            displayName?: string | null
          }
          hourlyRateUpdates?:
            | {
                __typename?: 'AssistantableHourlyRateUpdate'
                hourlyRate: number
                updatedAt: string | Date
              }[]
            | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          hourlyRate?: number | null
          currency?: string | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            displayName?: string | null
          }
          hourlyRateUpdates?:
            | {
                __typename?: 'AssistantableHourlyRateUpdate'
                hourlyRate: number
                updatedAt: string | Date
              }[]
            | null
        }
    )[]
  } | null
}

export type TimeTrackingEntriesListBulkQueryVariables = Exact<{
  assistantIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  executiveIds?: InputMaybe<Scalars['String'][] | Scalars['String']>
  workspaceIds?: InputMaybe<
    InputMaybe<Scalars['String']>[] | InputMaybe<Scalars['String']>
  >
  endedBefore?: InputMaybe<Scalars['DateTime']>
  endedAfter?: InputMaybe<Scalars['DateTime']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
}>

export type TimeTrackingEntriesListBulkQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'TimeTrackingEntriesList'
    after?: string | null
    items: {
      __typename?: 'TimeTrackingEntry'
      id: string
      title?: string | null
      isToggl: boolean
      duration?: string | null
      startedAt: string | Date
      endedAt?: string | Date | null
      tags: { __typename?: 'TimeTrackingTag'; id: string; label: string }[]
      user:
        | {
            __typename?: 'AssistantUser'
            id: string
            hourlyRate?: number | null
            currency?: string | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              displayName?: string | null
            }
            hourlyRateUpdates?:
              | {
                  __typename?: 'AssistantableHourlyRateUpdate'
                  hourlyRate: number
                  updatedAt: string | Date
                }[]
              | null
          }
        | {
            __typename?: 'HqUser'
            id: string
            hourlyRate?: number | null
            currency?: string | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              displayName?: string | null
            }
            hourlyRateUpdates?:
              | {
                  __typename?: 'AssistantableHourlyRateUpdate'
                  hourlyRate: number
                  updatedAt: string | Date
                }[]
              | null
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            hourlyRate?: number | null
            currency?: string | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              displayName?: string | null
            }
            hourlyRateUpdates?:
              | {
                  __typename?: 'AssistantableHourlyRateUpdate'
                  hourlyRate: number
                  updatedAt: string | Date
                }[]
              | null
          }
      workspace?: {
        __typename?: 'Workspace'
        id: string
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              hourlyRate?: number | null
              currency?: string | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                displayName?: string | null
              }
              hourlyRateUpdates?:
                | {
                    __typename?: 'AssistantableHourlyRateUpdate'
                    hourlyRate: number
                    updatedAt: string | Date
                  }[]
                | null
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              hourlyRate?: number | null
              currency?: string | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                displayName?: string | null
              }
              hourlyRateUpdates?:
                | {
                    __typename?: 'AssistantableHourlyRateUpdate'
                    hourlyRate: number
                    updatedAt: string | Date
                  }[]
                | null
            }
        )[]
      } | null
    }[]
  }
}

export type BasicTeamFragment = {
  __typename?: 'Team'
  id: string
  name?: string | null
  iconUrl?: string | null
  isArchived: boolean
}

export type BasicTeamWithPrincipalFragment = {
  __typename?: 'Team'
  id: string
  name?: string | null
  iconUrl?: string | null
  isArchived: boolean
  principalUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type BillingPauseFragment = {
  __typename?: 'Team'
  id: string
  billingPauseStatus?: {
    __typename?: 'BillingPauseStatus'
    id: string
    startAt: string | Date
    endAt?: string | Date | null
  } | null
}

export type Team_PendingChurnAutomationJobFragment = {
  __typename?: 'Team'
  id: string
  pendingChurnAutomationJob?: {
    __typename?: 'AutomationJob'
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    automation: { __typename?: 'Automation'; id: string; name: string }
  } | null
}

export type TeamFragment = {
  __typename?: 'Team'
  id: string
  createdAt: string | Date
  updatedAt: string | Date
  isSandbox: boolean
  status: TeamStatus
  name?: string | null
  iconUrl?: string | null
  isArchived: boolean
  principalUser:
    | {
        __typename?: 'ExecutiveUser'
        id: string
        airtableLeadRecordId?: string | null
      }
    | {
        __typename?: 'HqUser'
        id: string
        airtableLeadRecordId?: string | null
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        airtableLeadRecordId?: string | null
      }
  successManager?: {
    __typename?: 'HqUser'
    id: string
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  workspaces: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }[]
  subscription?: {
    __typename?: 'TeamSubscription'
    currentBillingCycle?: {
      __typename?: 'SubscriptionBillingCycle'
      endDate: string | Date
    } | null
  } | null
  billingPauseStatus?: {
    __typename?: 'BillingPauseStatus'
    id: string
    startAt: string | Date
    endAt?: string | Date | null
  } | null
  pendingChurnAutomationJob?: {
    __typename?: 'AutomationJob'
    id: string
    delayUntil: string | Date
    createdAt: string | Date
    startedAt?: string | Date | null
    endedAt?: string | Date | null
    cancelledAt?: string | Date | null
    updatedAt: string | Date
    reviewedAt?: string | Date | null
    hasError: boolean
    workspace?: {
      __typename?: 'Workspace'
      id: string
      isPendingTransition: boolean
      hasCoverage: boolean
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    } | null
    user?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    executive?:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    assistant?:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
    automation: { __typename?: 'Automation'; id: string; name: string }
  } | null
}

export type ListTeamsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['PositiveInt']>
  q?: InputMaybe<Scalars['String']>
  isArchived?: InputMaybe<Scalars['Boolean']>
}>

export type ListTeamsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'TeamsList'
    after?: string | null
    items: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
    }[]
  }
}

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID']
  date?: InputMaybe<Scalars['DateTime']>
}>

export type GetTeamQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'Team'
    id: string
    createdAt: string | Date
    updatedAt: string | Date
    isSandbox: boolean
    status: TeamStatus
    name?: string | null
    iconUrl?: string | null
    isArchived: boolean
    principalUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          airtableLeadRecordId?: string | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          airtableLeadRecordId?: string | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          airtableLeadRecordId?: string | null
        }
    successManager?: {
      __typename?: 'HqUser'
      id: string
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    workspaces: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
    subscription?: {
      __typename?: 'TeamSubscription'
      currentBillingCycle?: {
        __typename?: 'SubscriptionBillingCycle'
        endDate: string | Date
      } | null
    } | null
    billingPauseStatus?: {
      __typename?: 'BillingPauseStatus'
      id: string
      startAt: string | Date
      endAt?: string | Date | null
    } | null
    pendingChurnAutomationJob?: {
      __typename?: 'AutomationJob'
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      automation: { __typename?: 'Automation'; id: string; name: string }
    } | null
  }
}

export type GetTeamBillingInfoQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetTeamBillingInfoQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'Team'
    id: string
    stripeCustomer?: {
      __typename?: 'StripeCustomer'
      id: string
      paymentStatus: StripeCustomerPaymentStatus
    } | null
    subscription?: {
      __typename?: 'TeamSubscription'
      id: string
      type: TeamSubscriptionType
      productName?: string | null
      productPrice?: number | null
      state?: SubscriptionState | null
      maxioUrl?: string | null
      customerDisplayName?: string | null
      currentBillingCycle?: {
        __typename?: 'SubscriptionBillingCycle'
        startDate: string | Date
        endDate: string | Date
        hours: number
      } | null
    } | null
    pricingSelections: {
      __typename?: 'PricingSelection'
      id: string
      selectedAt: string | Date
      activeAt: string | Date
      expireAt?: string | Date | null
      notepad: string
      pricing?: {
        __typename?: 'Pricing'
        id: string
        ref: string
        label: string
        description?: string | null
        priceDescription?: string | null
        hoursDescription?: string | null
        sellingPoints: string[]
        sellingPointsNotice?: string | null
        learnMoreUrl?: string | null
        isPublic: boolean
        isAvailable: boolean
        isSharable: boolean
        instantInviteMaxUsers: number
        model?: PricingModel | null
        modelConfig: Record<string, any>
        includesTeamFeatures: boolean
        includesPremiumPerks: boolean
      } | null
    }[]
    currentPricing?: {
      __typename?: 'EntityPricing'
      id: string
      selectedAt: string | Date
      activeAt: string | Date
      expireAt?: string | Date | null
      notepad: string
      pricing: {
        __typename?: 'Pricing'
        id: string
        ref: string
        label: string
        description?: string | null
        priceDescription?: string | null
        hoursDescription?: string | null
        sellingPoints: string[]
        sellingPointsNotice?: string | null
        learnMoreUrl?: string | null
        isPublic: boolean
        isAvailable: boolean
        isSharable: boolean
        instantInviteMaxUsers: number
        model?: PricingModel | null
        modelConfig: Record<string, any>
        includesTeamFeatures: boolean
        includesPremiumPerks: boolean
      }
      owner:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
          }
    } | null
    billingPauseStatus?: {
      __typename?: 'BillingPauseStatus'
      id: string
      startAt: string | Date
      endAt?: string | Date | null
    } | null
  }
}

export type TeamBillingFragment = {
  __typename?: 'Team'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
  subscription?: {
    __typename?: 'TeamSubscription'
    id: string
    type: TeamSubscriptionType
    productName?: string | null
    productPrice?: number | null
    state?: SubscriptionState | null
    maxioUrl?: string | null
    customerDisplayName?: string | null
    currentBillingCycle?: {
      __typename?: 'SubscriptionBillingCycle'
      startDate: string | Date
      endDate: string | Date
      hours: number
    } | null
  } | null
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
  billingPauseStatus?: {
    __typename?: 'BillingPauseStatus'
    id: string
    startAt: string | Date
    endAt?: string | Date | null
  } | null
}

export type Team_StripeCustomerFragment = {
  __typename?: 'Team'
  id: string
  stripeCustomer?: {
    __typename?: 'StripeCustomer'
    id: string
    paymentStatus: StripeCustomerPaymentStatus
  } | null
}

export type Team_SubscriptionFragment = {
  __typename?: 'Team'
  id: string
  subscription?: {
    __typename?: 'TeamSubscription'
    id: string
    type: TeamSubscriptionType
    productName?: string | null
    productPrice?: number | null
    state?: SubscriptionState | null
    maxioUrl?: string | null
    customerDisplayName?: string | null
    currentBillingCycle?: {
      __typename?: 'SubscriptionBillingCycle'
      startDate: string | Date
      endDate: string | Date
      hours: number
    } | null
  } | null
}

export type Team_PricingSelectionsFragment = {
  __typename?: 'Team'
  id: string
  pricingSelections: {
    __typename?: 'PricingSelection'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing?: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    } | null
  }[]
  currentPricing?: {
    __typename?: 'EntityPricing'
    id: string
    selectedAt: string | Date
    activeAt: string | Date
    expireAt?: string | Date | null
    notepad: string
    pricing: {
      __typename?: 'Pricing'
      id: string
      ref: string
      label: string
      description?: string | null
      priceDescription?: string | null
      hoursDescription?: string | null
      sellingPoints: string[]
      sellingPointsNotice?: string | null
      learnMoreUrl?: string | null
      isPublic: boolean
      isAvailable: boolean
      isSharable: boolean
      instantInviteMaxUsers: number
      model?: PricingModel | null
      modelConfig: Record<string, any>
      includesTeamFeatures: boolean
      includesPremiumPerks: boolean
    }
    owner:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'Team'
          id: string
          name?: string | null
          iconUrl?: string | null
          isArchived: boolean
        }
  } | null
}

export type GuardedOperationFragment = {
  __typename?: 'GuardedOperation'
  id: string
  typeName: string
  fieldName: string
  description?: string | null
}

export type AdminRoleFragment = {
  __typename?: 'AdminRole'
  id: string
  name: string
  description?: string | null
  inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
  operations: {
    __typename?: 'GuardedOperation'
    id: string
    typeName: string
    fieldName: string
    description?: string | null
  }[]
}

export type ListAdminRolesQueryVariables = Exact<{ [key: string]: never }>

export type ListAdminRolesQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'AdminRolesList'
    items: {
      __typename?: 'AdminRole'
      id: string
      name: string
      description?: string | null
      inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
      operations: {
        __typename?: 'GuardedOperation'
        id: string
        typeName: string
        fieldName: string
        description?: string | null
      }[]
    }[]
  }
  guardedOperations: {
    __typename?: 'GuardedOperation'
    id: string
    typeName: string
    fieldName: string
    description?: string | null
  }[]
}

export type UpsertAdminRoleMutationVariables = Exact<{
  input: UpsertAdminRoleInput
}>

export type UpsertAdminRoleMutation = {
  __typename?: 'Mutation'
  upsertAdminRole: {
    __typename?: 'UpsertAdminRoleOutput'
    adminRole: {
      __typename?: 'AdminRole'
      id: string
      name: string
      description?: string | null
      inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
      operations: {
        __typename?: 'GuardedOperation'
        id: string
        typeName: string
        fieldName: string
        description?: string | null
      }[]
    }
  }
}

export type UpdateAdminableRolesMutationVariables = Exact<{
  input: UpdateAdminableRolesInput
}>

export type UpdateAdminableRolesMutation = {
  __typename?: 'Mutation'
  updateAdminableRoles: {
    __typename?: 'UpdateAdminableRolesOutput'
    user:
      | {
          __typename?: 'AssistantUser'
          id: string
          adminRoles: {
            __typename?: 'AdminRole'
            id: string
            name: string
            description?: string | null
            inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
            operations: {
              __typename?: 'GuardedOperation'
              id: string
              typeName: string
              fieldName: string
              description?: string | null
            }[]
          }[]
        }
      | {
          __typename?: 'HqUser'
          id: string
          adminRoles: {
            __typename?: 'AdminRole'
            id: string
            name: string
            description?: string | null
            inheritedRoles: { __typename?: 'AdminRole'; id: string }[]
            operations: {
              __typename?: 'GuardedOperation'
              id: string
              typeName: string
              fieldName: string
              description?: string | null
            }[]
          }[]
        }
  }
}

export type GetMeQueryVariables = Exact<{ [key: string]: never }>

export type GetMeQuery = {
  __typename?: 'Query'
  me:
    | {
        __typename?: 'AssistantUser'
        id: string
        allowedOperations: {
          __typename?: 'GuardedOperation'
          id: string
          description?: string | null
        }[]
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'HqUser'
        id: string
        allowedOperations: {
          __typename?: 'GuardedOperation'
          id: string
          description?: string | null
        }[]
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
        city?: {
          __typename?: 'Place'
          id: string
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            countryCode?: string | null
          } | null
        } | null
      }
}

export type UpdateTeamMutationVariables = Exact<{
  teamId: Scalars['ID']
  input: UpdateTeamInput
}>

export type UpdateTeamMutation = {
  __typename?: 'Mutation'
  updateTeam: {
    __typename?: 'Team'
    id: string
    createdAt: string | Date
    updatedAt: string | Date
    isSandbox: boolean
    status: TeamStatus
    name?: string | null
    iconUrl?: string | null
    isArchived: boolean
    principalUser:
      | {
          __typename?: 'ExecutiveUser'
          id: string
          airtableLeadRecordId?: string | null
        }
      | {
          __typename?: 'HqUser'
          id: string
          airtableLeadRecordId?: string | null
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          airtableLeadRecordId?: string | null
        }
    successManager?: {
      __typename?: 'HqUser'
      id: string
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
    workspaces: {
      __typename?: 'Workspace'
      id: string
      isArchived: boolean
      accountKeys: string[]
      isPool: boolean
      workingHoursTimeZone?: string | null
      usageAlertEmail: boolean
      usageAlertAssistantEmail: boolean
      usageLimitsEmail: boolean
      feedbackRequestEmail: boolean
      groupedNotificationsEmail: boolean
      eveningDigestEmail: boolean
      weeklyDigestEmail: boolean
      copilotEnabled: boolean
      isPendingTransition: boolean
      hasCoverage: boolean
      onboarding?: {
        __typename?: 'Onboarding'
        id: string
        isCompleted: boolean
        callStartAt?: string | Date | null
      } | null
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
      assistants: (
        | {
            __typename?: 'AssistantUser'
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      executives: (
        | {
            __typename?: 'ExecutiveUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            facet?: UserFacet | null
            position?: string | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            facet?: UserFacet | null
            industries: string[]
            id: string
            languages: string[]
            pronoun: UserPronoun
            isSaas: boolean
            hasPoolWorkspace: boolean
            isOnPause: boolean
            startDate?: string | null
            isArchived: boolean
            category?: UserCategory | null
            principalUser?:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            orientation?: {
              __typename?: 'Orientation'
              id: string
              isCompleted: boolean
            } | null
            birthday?: {
              __typename?: 'AnniversaryDate'
              day?: number | null
              month: number
              year?: number | null
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              timeZone?: string | null
              googleMapsPlaceId?: string | null
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                formatted?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      spectators: {
        __typename?: 'HqUser'
        facet?: UserFacet | null
        position?: string | null
        industries: string[]
        id: string
        languages: string[]
        pronoun: UserPronoun
        isSaas: boolean
        hasPoolWorkspace: boolean
        isOnPause: boolean
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        dsm?: {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        } | null
        orientation?: {
          __typename?: 'Orientation'
          id: string
          isCompleted: boolean
        } | null
        birthday?: {
          __typename?: 'AnniversaryDate'
          day?: number | null
          month: number
          year?: number | null
        } | null
        city?: {
          __typename?: 'Place'
          id: string
          timeZone?: string | null
          googleMapsPlaceId?: string | null
          address?: {
            __typename?: 'PlaceAddress'
            id: string
            formatted?: string | null
          } | null
        } | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }[]
    }[]
    subscription?: {
      __typename?: 'TeamSubscription'
      currentBillingCycle?: {
        __typename?: 'SubscriptionBillingCycle'
        endDate: string | Date
      } | null
    } | null
    billingPauseStatus?: {
      __typename?: 'BillingPauseStatus'
      id: string
      startAt: string | Date
      endAt?: string | Date | null
    } | null
    pendingChurnAutomationJob?: {
      __typename?: 'AutomationJob'
      id: string
      delayUntil: string | Date
      createdAt: string | Date
      startedAt?: string | Date | null
      endedAt?: string | Date | null
      cancelledAt?: string | Date | null
      updatedAt: string | Date
      reviewedAt?: string | Date | null
      hasError: boolean
      workspace?: {
        __typename?: 'Workspace'
        id: string
        isPendingTransition: boolean
        hasCoverage: boolean
        assistants: (
          | {
              __typename?: 'AssistantUser'
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        executives: (
          | {
              __typename?: 'ExecutiveUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              facet?: UserFacet | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              principalUser?:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        spectators: {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }[]
      } | null
      user?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      executive?:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      assistant?:
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
      automation: { __typename?: 'Automation'; id: string; name: string }
    } | null
  }
}

export type UpdateTeamBillingMutationVariables = Exact<{
  teamId: Scalars['ID']
  input: UpdateTeamBillingInput
}>

export type UpdateTeamBillingMutation = {
  __typename?: 'Mutation'
  updateTeamBilling: {
    __typename?: 'Team'
    id: string
    stripeCustomer?: {
      __typename?: 'StripeCustomer'
      id: string
      paymentStatus: StripeCustomerPaymentStatus
    } | null
    subscription?: {
      __typename?: 'TeamSubscription'
      id: string
      type: TeamSubscriptionType
      productName?: string | null
      productPrice?: number | null
      state?: SubscriptionState | null
      maxioUrl?: string | null
      customerDisplayName?: string | null
      currentBillingCycle?: {
        __typename?: 'SubscriptionBillingCycle'
        startDate: string | Date
        endDate: string | Date
        hours: number
      } | null
    } | null
    pricingSelections: {
      __typename?: 'PricingSelection'
      id: string
      selectedAt: string | Date
      activeAt: string | Date
      expireAt?: string | Date | null
      notepad: string
      pricing?: {
        __typename?: 'Pricing'
        id: string
        ref: string
        label: string
        description?: string | null
        priceDescription?: string | null
        hoursDescription?: string | null
        sellingPoints: string[]
        sellingPointsNotice?: string | null
        learnMoreUrl?: string | null
        isPublic: boolean
        isAvailable: boolean
        isSharable: boolean
        instantInviteMaxUsers: number
        model?: PricingModel | null
        modelConfig: Record<string, any>
        includesTeamFeatures: boolean
        includesPremiumPerks: boolean
      } | null
    }[]
    currentPricing?: {
      __typename?: 'EntityPricing'
      id: string
      selectedAt: string | Date
      activeAt: string | Date
      expireAt?: string | Date | null
      notepad: string
      pricing: {
        __typename?: 'Pricing'
        id: string
        ref: string
        label: string
        description?: string | null
        priceDescription?: string | null
        hoursDescription?: string | null
        sellingPoints: string[]
        sellingPointsNotice?: string | null
        learnMoreUrl?: string | null
        isPublic: boolean
        isAvailable: boolean
        isSharable: boolean
        instantInviteMaxUsers: number
        model?: PricingModel | null
        modelConfig: Record<string, any>
        includesTeamFeatures: boolean
        includesPremiumPerks: boolean
      }
      owner:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'Team'
            id: string
            name?: string | null
            iconUrl?: string | null
            isArchived: boolean
          }
    } | null
    billingPauseStatus?: {
      __typename?: 'BillingPauseStatus'
      id: string
      startAt: string | Date
      endAt?: string | Date | null
    } | null
  }
}

export type ClearDataloadersMutationVariables = Exact<{ [key: string]: never }>

export type ClearDataloadersMutation = {
  __typename?: 'Mutation'
  clearDataloaders: {
    __typename?: 'ClearDataloadersOutput'
    isSuccess: boolean
  }
}

export type CopilotFunctionFragment = {
  __typename?: 'CopilotFunction'
  name: string
  description: string
  parameters?: Record<string, any> | null
}

export type CopilotFragment = {
  __typename?: 'Copilot'
  id: string
  messagesPrompt: string
  functionsEnabled: boolean
  functionsPrompt: string
  messageCap: number
  updatedAt: string | Date
  functions: ({
    __typename?: 'CopilotFunction'
    name: string
    description: string
    parameters?: Record<string, any> | null
  } | null)[]
}

export type GetCopilotQueryVariables = Exact<{ [key: string]: never }>

export type GetCopilotQuery = {
  __typename?: 'Query'
  copilot: {
    __typename?: 'Copilot'
    id: string
    messagesPrompt: string
    functionsEnabled: boolean
    functionsPrompt: string
    messageCap: number
    updatedAt: string | Date
    functions: ({
      __typename?: 'CopilotFunction'
      name: string
      description: string
      parameters?: Record<string, any> | null
    } | null)[]
  }
}

export type UpdateCopilotSettingsMutationVariables = Exact<{
  input: UpdateCopilotSettingsInput
}>

export type UpdateCopilotSettingsMutation = {
  __typename?: 'Mutation'
  updateCopilotSettings: {
    __typename?: 'UpdateCopilotSettingsOutput'
    copilot: {
      __typename?: 'Copilot'
      id: string
      messagesPrompt: string
      functionsEnabled: boolean
      functionsPrompt: string
      messageCap: number
      updatedAt: string | Date
      functions: ({
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      } | null)[]
    }
  }
}

export type FirstCalledFunctionInfoFragment = {
  __typename?: 'FirstCalledFunctionInfo'
  functionName?: string | null
  inerhitIfNull?: boolean | null
}

type CopilotSubpromptable_CategoryCopilotPromptSettings_Fragment = {
  __typename?: 'CategoryCopilotPromptSettings'
  label: string
}

type CopilotSubpromptable_TaskCopilotPromptSettings_Fragment = {
  __typename?: 'TaskCopilotPromptSettings'
  label: string
}

export type CopilotSubpromptableFragment =
  | CopilotSubpromptable_CategoryCopilotPromptSettings_Fragment
  | CopilotSubpromptable_TaskCopilotPromptSettings_Fragment

type CopilotPromptSettings_CategoryCopilotPromptSettings_Fragment = {
  __typename?: 'CategoryCopilotPromptSettings'
  systemPrompt?: string | null
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

type CopilotPromptSettings_CopilotPromptSettingsOverride_Fragment = {
  __typename?: 'CopilotPromptSettingsOverride'
  systemPrompt?: string | null
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

type CopilotPromptSettings_MainCopilotPromptSettings_Fragment = {
  __typename?: 'MainCopilotPromptSettings'
  systemPrompt?: string | null
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

type CopilotPromptSettings_TaskCopilotPromptSettings_Fragment = {
  __typename?: 'TaskCopilotPromptSettings'
  systemPrompt?: string | null
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

export type CopilotPromptSettingsFragment =
  | CopilotPromptSettings_CategoryCopilotPromptSettings_Fragment
  | CopilotPromptSettings_CopilotPromptSettingsOverride_Fragment
  | CopilotPromptSettings_MainCopilotPromptSettings_Fragment
  | CopilotPromptSettings_TaskCopilotPromptSettings_Fragment

export type CopilotPromptSettingsOverrideFragment = {
  __typename?: 'CopilotPromptSettingsOverride'
  id: string
  systemPrompt?: string | null
  users: (
    | {
        __typename?: 'AssistantUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  )[]
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

type CopilotPromptSettingsOverridable_CategoryCopilotPromptSettings_Fragment = {
  __typename?: 'CategoryCopilotPromptSettings'
  overridePrompts: {
    __typename?: 'CopilotPromptSettingsOverride'
    id: string
    systemPrompt?: string | null
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
}

type CopilotPromptSettingsOverridable_MainCopilotPromptSettings_Fragment = {
  __typename?: 'MainCopilotPromptSettings'
  overridePrompts: {
    __typename?: 'CopilotPromptSettingsOverride'
    id: string
    systemPrompt?: string | null
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
}

type CopilotPromptSettingsOverridable_TaskCopilotPromptSettings_Fragment = {
  __typename?: 'TaskCopilotPromptSettings'
  overridePrompts: {
    __typename?: 'CopilotPromptSettingsOverride'
    id: string
    systemPrompt?: string | null
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
}

export type CopilotPromptSettingsOverridableFragment =
  | CopilotPromptSettingsOverridable_CategoryCopilotPromptSettings_Fragment
  | CopilotPromptSettingsOverridable_MainCopilotPromptSettings_Fragment
  | CopilotPromptSettingsOverridable_TaskCopilotPromptSettings_Fragment

export type MainCopilotPromptSettingsFragment = {
  __typename?: 'MainCopilotPromptSettings'
  systemPrompt?: string | null
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
  overridePrompts: {
    __typename?: 'CopilotPromptSettingsOverride'
    id: string
    systemPrompt?: string | null
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
}

export type CategoryCopilotPromptSettingsFragment = {
  __typename?: 'CategoryCopilotPromptSettings'
  id: TimeTrackingCategoryId
  label: string
  systemPrompt?: string | null
  overridePrompts: {
    __typename?: 'CopilotPromptSettingsOverride'
    id: string
    systemPrompt?: string | null
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

export type TaskCopilotPromptSettingsFragment = {
  __typename?: 'TaskCopilotPromptSettings'
  id: TimeTrackingEntryTemplateId
  label: string
  systemPrompt?: string | null
  overridePrompts: {
    __typename?: 'CopilotPromptSettingsOverride'
    id: string
    systemPrompt?: string | null
    users: (
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
  functions?:
    | {
        __typename?: 'CopilotFunction'
        name: string
        description: string
        parameters?: Record<string, any> | null
      }[]
    | null
  firstCalledFunctionInfo: {
    __typename?: 'FirstCalledFunctionInfo'
    functionName?: string | null
    inerhitIfNull?: boolean | null
  }
}

export type AssistantCopilotFragment = {
  __typename?: 'AssistantCopilot'
  id: string
  everydayTasksCopilotPromptSettings: {
    __typename?: 'MainCopilotPromptSettings'
    systemPrompt?: string | null
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
    overridePrompts: {
      __typename?: 'CopilotPromptSettingsOverride'
      id: string
      systemPrompt?: string | null
      users: (
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
    }[]
  }
  execTasksCopilotPromptSettings: {
    __typename?: 'MainCopilotPromptSettings'
    systemPrompt?: string | null
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
    overridePrompts: {
      __typename?: 'CopilotPromptSettingsOverride'
      id: string
      systemPrompt?: string | null
      users: (
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
    }[]
  }
  taskPrompts: {
    __typename?: 'TaskCopilotPromptSettings'
    id: TimeTrackingEntryTemplateId
    label: string
    systemPrompt?: string | null
    overridePrompts: {
      __typename?: 'CopilotPromptSettingsOverride'
      id: string
      systemPrompt?: string | null
      users: (
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
    }[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
  categoryPrompts: {
    __typename?: 'CategoryCopilotPromptSettings'
    id: TimeTrackingCategoryId
    label: string
    systemPrompt?: string | null
    overridePrompts: {
      __typename?: 'CopilotPromptSettingsOverride'
      id: string
      systemPrompt?: string | null
      users: (
        | {
            __typename?: 'AssistantUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      )[]
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
    }[]
    functions?:
      | {
          __typename?: 'CopilotFunction'
          name: string
          description: string
          parameters?: Record<string, any> | null
        }[]
      | null
    firstCalledFunctionInfo: {
      __typename?: 'FirstCalledFunctionInfo'
      functionName?: string | null
      inerhitIfNull?: boolean | null
    }
  }[]
}

export type AssistantCopilotQueryVariables = Exact<{ [key: string]: never }>

export type AssistantCopilotQuery = {
  __typename?: 'Query'
  assistantCopilot: {
    __typename?: 'AssistantCopilot'
    id: string
    everydayTasksCopilotPromptSettings: {
      __typename?: 'MainCopilotPromptSettings'
      systemPrompt?: string | null
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
      overridePrompts: {
        __typename?: 'CopilotPromptSettingsOverride'
        id: string
        systemPrompt?: string | null
        users: (
          | {
              __typename?: 'AssistantUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
      }[]
    }
    execTasksCopilotPromptSettings: {
      __typename?: 'MainCopilotPromptSettings'
      systemPrompt?: string | null
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
      overridePrompts: {
        __typename?: 'CopilotPromptSettingsOverride'
        id: string
        systemPrompt?: string | null
        users: (
          | {
              __typename?: 'AssistantUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
      }[]
    }
    taskPrompts: {
      __typename?: 'TaskCopilotPromptSettings'
      id: TimeTrackingEntryTemplateId
      label: string
      systemPrompt?: string | null
      overridePrompts: {
        __typename?: 'CopilotPromptSettingsOverride'
        id: string
        systemPrompt?: string | null
        users: (
          | {
              __typename?: 'AssistantUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
      }[]
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
    }[]
    categoryPrompts: {
      __typename?: 'CategoryCopilotPromptSettings'
      id: TimeTrackingCategoryId
      label: string
      systemPrompt?: string | null
      overridePrompts: {
        __typename?: 'CopilotPromptSettingsOverride'
        id: string
        systemPrompt?: string | null
        users: (
          | {
              __typename?: 'AssistantUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        )[]
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
      }[]
      functions?:
        | {
            __typename?: 'CopilotFunction'
            name: string
            description: string
            parameters?: Record<string, any> | null
          }[]
        | null
      firstCalledFunctionInfo: {
        __typename?: 'FirstCalledFunctionInfo'
        functionName?: string | null
        inerhitIfNull?: boolean | null
      }
    }[]
  }
}

export type UpdateAssistantCopilotSettingsMutationVariables = Exact<{
  input: UpdateAssistantCopilotSettingsInput
}>

export type UpdateAssistantCopilotSettingsMutation = {
  __typename?: 'Mutation'
  updateAssistantCopilotSettings: {
    __typename?: 'UpdateAssistantCopilotSettingsOutput'
    assistantCopilot: {
      __typename?: 'AssistantCopilot'
      id: string
      everydayTasksCopilotPromptSettings: {
        __typename?: 'MainCopilotPromptSettings'
        systemPrompt?: string | null
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
        overridePrompts: {
          __typename?: 'CopilotPromptSettingsOverride'
          id: string
          systemPrompt?: string | null
          users: (
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          functions?:
            | {
                __typename?: 'CopilotFunction'
                name: string
                description: string
                parameters?: Record<string, any> | null
              }[]
            | null
          firstCalledFunctionInfo: {
            __typename?: 'FirstCalledFunctionInfo'
            functionName?: string | null
            inerhitIfNull?: boolean | null
          }
        }[]
      }
      execTasksCopilotPromptSettings: {
        __typename?: 'MainCopilotPromptSettings'
        systemPrompt?: string | null
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
        overridePrompts: {
          __typename?: 'CopilotPromptSettingsOverride'
          id: string
          systemPrompt?: string | null
          users: (
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          functions?:
            | {
                __typename?: 'CopilotFunction'
                name: string
                description: string
                parameters?: Record<string, any> | null
              }[]
            | null
          firstCalledFunctionInfo: {
            __typename?: 'FirstCalledFunctionInfo'
            functionName?: string | null
            inerhitIfNull?: boolean | null
          }
        }[]
      }
      taskPrompts: {
        __typename?: 'TaskCopilotPromptSettings'
        id: TimeTrackingEntryTemplateId
        label: string
        systemPrompt?: string | null
        overridePrompts: {
          __typename?: 'CopilotPromptSettingsOverride'
          id: string
          systemPrompt?: string | null
          users: (
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          functions?:
            | {
                __typename?: 'CopilotFunction'
                name: string
                description: string
                parameters?: Record<string, any> | null
              }[]
            | null
          firstCalledFunctionInfo: {
            __typename?: 'FirstCalledFunctionInfo'
            functionName?: string | null
            inerhitIfNull?: boolean | null
          }
        }[]
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
      }[]
      categoryPrompts: {
        __typename?: 'CategoryCopilotPromptSettings'
        id: TimeTrackingCategoryId
        label: string
        systemPrompt?: string | null
        overridePrompts: {
          __typename?: 'CopilotPromptSettingsOverride'
          id: string
          systemPrompt?: string | null
          users: (
            | {
                __typename?: 'AssistantUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          )[]
          functions?:
            | {
                __typename?: 'CopilotFunction'
                name: string
                description: string
                parameters?: Record<string, any> | null
              }[]
            | null
          firstCalledFunctionInfo: {
            __typename?: 'FirstCalledFunctionInfo'
            functionName?: string | null
            inerhitIfNull?: boolean | null
          }
        }[]
        functions?:
          | {
              __typename?: 'CopilotFunction'
              name: string
              description: string
              parameters?: Record<string, any> | null
            }[]
          | null
        firstCalledFunctionInfo: {
          __typename?: 'FirstCalledFunctionInfo'
          functionName?: string | null
          inerhitIfNull?: boolean | null
        }
      }[]
    }
  }
}

export type ArchiveMatchingMutationVariables = Exact<{
  input: ArchiveMatchingInput
}>

export type ArchiveMatchingMutation = {
  __typename?: 'Mutation'
  archiveMatching: { __typename?: 'ArchiveMatchingOutput'; isSuccess: boolean }
}

export type DelegationCoachFragment = {
  __typename?: 'DelegationCoach'
  id: string
  systemPrompt: string
  taskSuggestionsFunction: string
}

export type DelegationCoachQueryVariables = Exact<{ [key: string]: never }>

export type DelegationCoachQuery = {
  __typename?: 'Query'
  delegationCoach: {
    __typename?: 'DelegationCoach'
    id: string
    systemPrompt: string
    taskSuggestionsFunction: string
  }
}

export type UpdateDelegationCoachSettingsMutationVariables = Exact<{
  input: UpdateDelegationCoachSettingsInput
}>

export type UpdateDelegationCoachSettingsMutation = {
  __typename?: 'Mutation'
  updateDelegationCoachSettings: {
    __typename?: 'UpdateDelegationCoachSettingsOutput'
    delegationCoach: {
      __typename?: 'DelegationCoach'
      id: string
      systemPrompt: string
      taskSuggestionsFunction: string
    }
  }
}

export type AdhocTodoSettingsFragment = {
  __typename?: 'AdhocTodoSettings'
  id: string
  systemPrompt: string
  titlePrompt: string
  taskAcceptabilityPrompt: string
  isOpenForSubmissions: boolean
  updatedAt: string | Date
}

export type AdhocTodoSettingsQueryVariables = Exact<{ [key: string]: never }>

export type AdhocTodoSettingsQuery = {
  __typename?: 'Query'
  adhocTodoSettings: {
    __typename?: 'AdhocTodoSettings'
    id: string
    systemPrompt: string
    titlePrompt: string
    taskAcceptabilityPrompt: string
    isOpenForSubmissions: boolean
    updatedAt: string | Date
  }
}

export type UpdateAdhocTodoSettingsMutationVariables = Exact<{
  input: UpdateAdhocTodoSettingsInput
}>

export type UpdateAdhocTodoSettingsMutation = {
  __typename?: 'Mutation'
  updateAdhocTodoSettings: {
    __typename?: 'UpdateAdhocTodoSettingsOutput'
    adhocTodoSettings: {
      __typename?: 'AdhocTodoSettings'
      id: string
      systemPrompt: string
      titlePrompt: string
      taskAcceptabilityPrompt: string
      isOpenForSubmissions: boolean
      updatedAt: string | Date
    }
  }
}

type HourlyRateCurrency_AssistantUser_Fragment = {
  __typename?: 'AssistantUser'
  currency?: string | null
  hourlyRate?: number | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type HourlyRateCurrency_HqUser_Fragment = {
  __typename?: 'HqUser'
  currency?: string | null
  hourlyRate?: number | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

type HourlyRateCurrency_SandboxUser_Fragment = {
  __typename?: 'SandboxUser'
  currency?: string | null
  hourlyRate?: number | null
  city?: {
    __typename?: 'Place'
    id: string
    address?: {
      __typename?: 'PlaceAddress'
      id: string
      countryCode?: string | null
    } | null
  } | null
}

export type HourlyRateCurrencyFragment =
  | HourlyRateCurrency_AssistantUser_Fragment
  | HourlyRateCurrency_HqUser_Fragment
  | HourlyRateCurrency_SandboxUser_Fragment

export type AdhocExecutiveFragment = {
  __typename?: 'AdhocExecutive'
  givenName: string
  familyName: string
  email: string
}

export type EmailThreadItemFragment = {
  __typename?: 'EmailThreadItem'
  forwardedAt: string | Date
  text: string
  from: { __typename?: 'EmailData'; email: string; name?: string | null }
  attachments?:
    | { __typename?: 'Attachment'; filename: string; type: string }[]
    | null
  to: { __typename?: 'EmailData'; email: string; name?: string | null }
}

export type ChatThreadItemFragment = {
  __typename?: 'ChatThreadItem'
  content: string
  createdAt: string | Date
  author:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type CommunicationThreadsFragment = {
  __typename?: 'CommunicationThreads'
  emailThread: {
    __typename?: 'EmailThreadItem'
    forwardedAt: string | Date
    text: string
    from: { __typename?: 'EmailData'; email: string; name?: string | null }
    attachments?:
      | { __typename?: 'Attachment'; filename: string; type: string }[]
      | null
    to: { __typename?: 'EmailData'; email: string; name?: string | null }
  }[]
  chatThread: {
    __typename?: 'ChatThreadItem'
    content: string
    createdAt: string | Date
    author:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
  }[]
}

export type CheckoutSessionLineItemFragment = {
  __typename?: 'CheckoutSessionLineItem'
  quantity?: number | null
  price?: {
    __typename?: 'StripePrice'
    unitAmount?: number | null
    currency: string
  } | null
}

export type FeedbackFragment = {
  __typename?: 'AdhocTodoFeedback'
  rating?: number | null
  ratingComment?: string | null
}

export type AdhocTodoFragment = {
  __typename?: 'AdhocTodo'
  id: string
  title?: string | null
  createdAt: string | Date
  updatedAt: string | Date
  status: TodoStatus
  completedAt?: string | Date | null
  isPool: boolean
  isHiddenFromPool: boolean
  feedback?: {
    __typename?: 'AdhocTodoFeedback'
    rating?: number | null
    ratingComment?: string | null
  } | null
  executive?: {
    __typename?: 'AdhocExecutive'
    givenName: string
    familyName: string
    email: string
  } | null
  executiveUser?: {
    __typename?: 'ExecutiveUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  dispatchedTo?: {
    __typename?: 'AssistantUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
  communicationThreads?: {
    __typename?: 'CommunicationThreads'
    emailThread: {
      __typename?: 'EmailThreadItem'
      forwardedAt: string | Date
      text: string
      from: { __typename?: 'EmailData'; email: string; name?: string | null }
      attachments?:
        | { __typename?: 'Attachment'; filename: string; type: string }[]
        | null
      to: { __typename?: 'EmailData'; email: string; name?: string | null }
    }[]
    chatThread: {
      __typename?: 'ChatThreadItem'
      content: string
      createdAt: string | Date
      author:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    }[]
  } | null
  completionReport?: {
    __typename?: 'AdhocTodoCompletionReport'
    notes: string
  } | null
  checkoutSession?: {
    __typename?: 'CheckoutSession'
    lineItems?:
      | ({
          __typename?: 'CheckoutSessionLineItem'
          quantity?: number | null
          price?: {
            __typename?: 'StripePrice'
            unitAmount?: number | null
            currency: string
          } | null
        } | null)[]
      | null
  } | null
}

export type AdhocTodosListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']>
  after?: InputMaybe<Scalars['String']>
  excludedStatuses?: InputMaybe<TodoStatus[] | TodoStatus>
}>

export type AdhocTodosListQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'AdhocTodosList'
    after?: string | null
    items: {
      __typename?: 'AdhocTodo'
      id: string
      title?: string | null
      createdAt: string | Date
      updatedAt: string | Date
      status: TodoStatus
      completedAt?: string | Date | null
      isPool: boolean
      isHiddenFromPool: boolean
      feedback?: {
        __typename?: 'AdhocTodoFeedback'
        rating?: number | null
        ratingComment?: string | null
      } | null
      executive?: {
        __typename?: 'AdhocExecutive'
        givenName: string
        familyName: string
        email: string
      } | null
      executiveUser?: {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      dispatchedTo?: {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      communicationThreads?: {
        __typename?: 'CommunicationThreads'
        emailThread: {
          __typename?: 'EmailThreadItem'
          forwardedAt: string | Date
          text: string
          from: {
            __typename?: 'EmailData'
            email: string
            name?: string | null
          }
          attachments?:
            | { __typename?: 'Attachment'; filename: string; type: string }[]
            | null
          to: { __typename?: 'EmailData'; email: string; name?: string | null }
        }[]
        chatThread: {
          __typename?: 'ChatThreadItem'
          content: string
          createdAt: string | Date
          author:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
        }[]
      } | null
      completionReport?: {
        __typename?: 'AdhocTodoCompletionReport'
        notes: string
      } | null
      checkoutSession?: {
        __typename?: 'CheckoutSession'
        lineItems?:
          | ({
              __typename?: 'CheckoutSessionLineItem'
              quantity?: number | null
              price?: {
                __typename?: 'StripePrice'
                unitAmount?: number | null
                currency: string
              } | null
            } | null)[]
          | null
      } | null
    }[]
  }
}

export type RemoveAdhocTodoAssistantMutationVariables = Exact<{
  input: RemoveAdhocTodoAssistantInput
}>

export type RemoveAdhocTodoAssistantMutation = {
  __typename?: 'Mutation'
  removeAdhocTodoAssistant: {
    __typename?: 'RemoveAdhocTodoAssistantOutput'
    adhocTodo: {
      __typename?: 'AdhocTodo'
      id: string
      title?: string | null
      createdAt: string | Date
      updatedAt: string | Date
      status: TodoStatus
      completedAt?: string | Date | null
      isPool: boolean
      isHiddenFromPool: boolean
      feedback?: {
        __typename?: 'AdhocTodoFeedback'
        rating?: number | null
        ratingComment?: string | null
      } | null
      executive?: {
        __typename?: 'AdhocExecutive'
        givenName: string
        familyName: string
        email: string
      } | null
      executiveUser?: {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      dispatchedTo?: {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      communicationThreads?: {
        __typename?: 'CommunicationThreads'
        emailThread: {
          __typename?: 'EmailThreadItem'
          forwardedAt: string | Date
          text: string
          from: {
            __typename?: 'EmailData'
            email: string
            name?: string | null
          }
          attachments?:
            | { __typename?: 'Attachment'; filename: string; type: string }[]
            | null
          to: { __typename?: 'EmailData'; email: string; name?: string | null }
        }[]
        chatThread: {
          __typename?: 'ChatThreadItem'
          content: string
          createdAt: string | Date
          author:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
        }[]
      } | null
      completionReport?: {
        __typename?: 'AdhocTodoCompletionReport'
        notes: string
      } | null
      checkoutSession?: {
        __typename?: 'CheckoutSession'
        lineItems?:
          | ({
              __typename?: 'CheckoutSessionLineItem'
              quantity?: number | null
              price?: {
                __typename?: 'StripePrice'
                unitAmount?: number | null
                currency: string
              } | null
            } | null)[]
          | null
      } | null
    }
  }
}

export type UpdateAdhocTodoPoolVisibilityMutationVariables = Exact<{
  input: UpdateAdhocTodoPoolVisibilityInput
}>

export type UpdateAdhocTodoPoolVisibilityMutation = {
  __typename?: 'Mutation'
  updateAdhocTodoPoolVisibility: {
    __typename?: 'UpdateAdhocTodoPoolVisibilityOutput'
    adhocTodo: {
      __typename?: 'AdhocTodo'
      id: string
      title?: string | null
      createdAt: string | Date
      updatedAt: string | Date
      status: TodoStatus
      completedAt?: string | Date | null
      isPool: boolean
      isHiddenFromPool: boolean
      feedback?: {
        __typename?: 'AdhocTodoFeedback'
        rating?: number | null
        ratingComment?: string | null
      } | null
      executive?: {
        __typename?: 'AdhocExecutive'
        givenName: string
        familyName: string
        email: string
      } | null
      executiveUser?: {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      dispatchedTo?: {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      communicationThreads?: {
        __typename?: 'CommunicationThreads'
        emailThread: {
          __typename?: 'EmailThreadItem'
          forwardedAt: string | Date
          text: string
          from: {
            __typename?: 'EmailData'
            email: string
            name?: string | null
          }
          attachments?:
            | { __typename?: 'Attachment'; filename: string; type: string }[]
            | null
          to: { __typename?: 'EmailData'; email: string; name?: string | null }
        }[]
        chatThread: {
          __typename?: 'ChatThreadItem'
          content: string
          createdAt: string | Date
          author:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
        }[]
      } | null
      completionReport?: {
        __typename?: 'AdhocTodoCompletionReport'
        notes: string
      } | null
      checkoutSession?: {
        __typename?: 'CheckoutSession'
        lineItems?:
          | ({
              __typename?: 'CheckoutSessionLineItem'
              quantity?: number | null
              price?: {
                __typename?: 'StripePrice'
                unitAmount?: number | null
                currency: string
              } | null
            } | null)[]
          | null
      } | null
    }
  }
}

export type GrantPoolTokensMutationVariables = Exact<{
  input: GrantPoolTokensInput
}>

export type GrantPoolTokensMutation = {
  __typename?: 'Mutation'
  grantPoolTokens: {
    __typename?: 'GrantPoolTokensOutput'
    workspace: {
      __typename?: 'Workspace'
      id: string
      poolTokenBalance?: {
        __typename?: 'PoolTokenBalance'
        id: string
        availablePoolTokenCount: number
      } | null
    }
  }
}

export type ExportChatToStudioMutationVariables = Exact<{
  input: ExportChatToStudioInput
}>

export type ExportChatToStudioMutation = {
  __typename?: 'Mutation'
  exportChatToStudio: {
    __typename?: 'ExportChatToStudioOutput'
    isSuccess: boolean
  }
}

type TimelineItem_FeedbackTimelineItem_Fragment = {
  __typename?: 'FeedbackTimelineItem'
  activityAt: string | Date
  category: TimelineItemCategory
  event: TimelineEvent
  emoji?: string | null
  title: string
  feedback: {
    __typename?: 'CsatFeedback'
    id: string
    rating: number
    tags: string[]
    commentText?: string | null
    createdAt: string | Date
    assistant:
      | {
          __typename?: 'AssistantUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    workspace?: { __typename?: 'Workspace'; id: string } | null
  }
  counterpartUser:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

type TimelineItem_MatchingTimelineItem_Fragment = {
  __typename?: 'MatchingTimelineItem'
  activityAt: string | Date
  category: TimelineItemCategory
  event: TimelineEvent
  emoji?: string | null
  title: string
  matching: {
    __typename?: 'Matching'
    hasToBeMatched: boolean
    id: string
    bookmarksCount: number
    isHighTouch: boolean
    isHighTouchMaybe: boolean
    targetCandidatesCount: number
    isTransition: boolean
    isHiddenFromCatalog: boolean
    completedAt?: string | Date | null
    isTeamInvite?: boolean | null
    profiles: {
      __typename?: 'MatchingProfile'
      id: string
      name?: string | null
      introCallDate?: string | null
      matchDueDate?: string | null
      internalMatchingDate?: string | null
      country?: string | null
    }[]
    proposals: {
      __typename?: 'MatchingProposal'
      id: string
      sentAt?: string | Date | null
      reply?: MatchingProposalReply | null
      isInstantInvite: boolean
    }[]
    applications: { __typename?: 'MatchingApplication'; id: string }[]
  }
}

type TimelineItem_StatusTimelineItem_Fragment = {
  __typename?: 'StatusTimelineItem'
  activityAt: string | Date
  category: TimelineItemCategory
  event: TimelineEvent
  emoji?: string | null
  title: string
}

type TimelineItem_UserTimelineItem_Fragment = {
  __typename?: 'UserTimelineItem'
  activityAt: string | Date
  category: TimelineItemCategory
  event: TimelineEvent
  emoji?: string | null
  title: string
}

type TimelineItem_WorkspaceTimelineItem_Fragment = {
  __typename?: 'WorkspaceTimelineItem'
  activityAt: string | Date
  category: TimelineItemCategory
  event: TimelineEvent
  emoji?: string | null
  title: string
  workspace: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }
}

type TimelineItem_WorkspaceUserTimelineItem_Fragment = {
  __typename?: 'WorkspaceUserTimelineItem'
  startDate: string | Date
  endDate?: string | Date | null
  isCoverage: boolean
  activityAt: string | Date
  category: TimelineItemCategory
  event: TimelineEvent
  emoji?: string | null
  title: string
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
  workspace: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }
}

export type TimelineItemFragment =
  | TimelineItem_FeedbackTimelineItem_Fragment
  | TimelineItem_MatchingTimelineItem_Fragment
  | TimelineItem_StatusTimelineItem_Fragment
  | TimelineItem_UserTimelineItem_Fragment
  | TimelineItem_WorkspaceTimelineItem_Fragment
  | TimelineItem_WorkspaceUserTimelineItem_Fragment

export type GetUserTimelineQueryVariables = Exact<{
  userId: Scalars['ID']
  excludes?: InputMaybe<TimelineEvent[] | TimelineEvent>
}>

export type GetUserTimelineQuery = {
  __typename?: 'Query'
  timelineItemsList: {
    __typename?: 'TimelineItemsList'
    items: (
      | {
          __typename?: 'FeedbackTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          feedback: {
            __typename?: 'CsatFeedback'
            id: string
            rating: number
            tags: string[]
            commentText?: string | null
            createdAt: string | Date
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            workspace?: { __typename?: 'Workspace'; id: string } | null
          }
          counterpartUser:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
        }
      | {
          __typename?: 'MatchingTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          matching: {
            __typename?: 'Matching'
            hasToBeMatched: boolean
            id: string
            bookmarksCount: number
            isHighTouch: boolean
            isHighTouchMaybe: boolean
            targetCandidatesCount: number
            isTransition: boolean
            isHiddenFromCatalog: boolean
            completedAt?: string | Date | null
            isTeamInvite?: boolean | null
            profiles: {
              __typename?: 'MatchingProfile'
              id: string
              name?: string | null
              introCallDate?: string | null
              matchDueDate?: string | null
              internalMatchingDate?: string | null
              country?: string | null
            }[]
            proposals: {
              __typename?: 'MatchingProposal'
              id: string
              sentAt?: string | Date | null
              reply?: MatchingProposalReply | null
              isInstantInvite: boolean
            }[]
            applications: { __typename?: 'MatchingApplication'; id: string }[]
          }
        }
      | {
          __typename?: 'StatusTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
        }
      | {
          __typename?: 'UserTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
        }
      | {
          __typename?: 'WorkspaceTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          workspace: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }
        }
      | {
          __typename?: 'WorkspaceUserTimelineItem'
          startDate: string | Date
          endDate?: string | Date | null
          isCoverage: boolean
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          user:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          workspace: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }
        }
    )[]
  }
}

export type GetTeamTimelineQueryVariables = Exact<{
  teamId: Scalars['ID']
  excludes?: InputMaybe<TimelineEvent[] | TimelineEvent>
}>

export type GetTeamTimelineQuery = {
  __typename?: 'Query'
  teamTimelineItemsList: {
    __typename?: 'TimelineItemsList'
    items: (
      | {
          __typename?: 'FeedbackTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          feedback: {
            __typename?: 'CsatFeedback'
            id: string
            rating: number
            tags: string[]
            commentText?: string | null
            createdAt: string | Date
            assistant:
              | {
                  __typename?: 'AssistantUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            workspace?: { __typename?: 'Workspace'; id: string } | null
          }
          counterpartUser:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
        }
      | {
          __typename?: 'MatchingTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          matching: {
            __typename?: 'Matching'
            hasToBeMatched: boolean
            id: string
            bookmarksCount: number
            isHighTouch: boolean
            isHighTouchMaybe: boolean
            targetCandidatesCount: number
            isTransition: boolean
            isHiddenFromCatalog: boolean
            completedAt?: string | Date | null
            isTeamInvite?: boolean | null
            profiles: {
              __typename?: 'MatchingProfile'
              id: string
              name?: string | null
              introCallDate?: string | null
              matchDueDate?: string | null
              internalMatchingDate?: string | null
              country?: string | null
            }[]
            proposals: {
              __typename?: 'MatchingProposal'
              id: string
              sentAt?: string | Date | null
              reply?: MatchingProposalReply | null
              isInstantInvite: boolean
            }[]
            applications: { __typename?: 'MatchingApplication'; id: string }[]
          }
        }
      | {
          __typename?: 'StatusTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
        }
      | {
          __typename?: 'UserTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
        }
      | {
          __typename?: 'WorkspaceTimelineItem'
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          workspace: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }
        }
      | {
          __typename?: 'WorkspaceUserTimelineItem'
          startDate: string | Date
          endDate?: string | Date | null
          isCoverage: boolean
          activityAt: string | Date
          category: TimelineItemCategory
          event: TimelineEvent
          emoji?: string | null
          title: string
          user:
            | {
                __typename?: 'AssistantUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'ExecutiveUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
          workspace: {
            __typename?: 'Workspace'
            id: string
            isArchived: boolean
            accountKeys: string[]
            isPool: boolean
            workingHoursTimeZone?: string | null
            usageAlertEmail: boolean
            usageAlertAssistantEmail: boolean
            usageLimitsEmail: boolean
            feedbackRequestEmail: boolean
            groupedNotificationsEmail: boolean
            eveningDigestEmail: boolean
            weeklyDigestEmail: boolean
            copilotEnabled: boolean
            isPendingTransition: boolean
            hasCoverage: boolean
            team?: {
              __typename?: 'Team'
              id: string
              name?: string | null
              iconUrl?: string | null
              isArchived: boolean
              principalUser:
                | {
                    __typename?: 'ExecutiveUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'HqUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
                | {
                    __typename?: 'SandboxUser'
                    id: string
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  }
            } | null
            onboarding?: {
              __typename?: 'Onboarding'
              id: string
              isCompleted: boolean
              callStartAt?: string | Date | null
            } | null
            poolTokenBalance?: {
              __typename?: 'PoolTokenBalance'
              id: string
              availablePoolTokenCount: number
            } | null
            assistants: (
              | {
                  __typename?: 'AssistantUser'
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            executives: (
              | {
                  __typename?: 'ExecutiveUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  facet?: UserFacet | null
                  position?: string | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  facet?: UserFacet | null
                  industries: string[]
                  id: string
                  languages: string[]
                  pronoun: UserPronoun
                  isSaas: boolean
                  hasPoolWorkspace: boolean
                  isOnPause: boolean
                  startDate?: string | null
                  isArchived: boolean
                  category?: UserCategory | null
                  principalUser?:
                    | {
                        __typename?: 'ExecutiveUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'HqUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | {
                        __typename?: 'SandboxUser'
                        id: string
                        profile: {
                          __typename?: 'UserProfile'
                          id: string
                          emails: string[]
                          avatarUrl?: string | null
                          email?: string | null
                          givenName?: string | null
                          familyName?: string | null
                          displayName?: string | null
                        }
                      }
                    | null
                  dsm?: {
                    __typename?: 'HqUser'
                    id: string
                    isArchived: boolean
                    category?: UserCategory | null
                    profile: {
                      __typename?: 'UserProfile'
                      id: string
                      emails: string[]
                      avatarUrl?: string | null
                      email?: string | null
                      givenName?: string | null
                      familyName?: string | null
                      displayName?: string | null
                    }
                  } | null
                  orientation?: {
                    __typename?: 'Orientation'
                    id: string
                    isCompleted: boolean
                  } | null
                  birthday?: {
                    __typename?: 'AnniversaryDate'
                    day?: number | null
                    month: number
                    year?: number | null
                  } | null
                  city?: {
                    __typename?: 'Place'
                    id: string
                    timeZone?: string | null
                    googleMapsPlaceId?: string | null
                    address?: {
                      __typename?: 'PlaceAddress'
                      id: string
                      formatted?: string | null
                    } | null
                  } | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
            )[]
            spectators: {
              __typename?: 'HqUser'
              facet?: UserFacet | null
              position?: string | null
              industries: string[]
              id: string
              languages: string[]
              pronoun: UserPronoun
              isSaas: boolean
              hasPoolWorkspace: boolean
              isOnPause: boolean
              startDate?: string | null
              isArchived: boolean
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              orientation?: {
                __typename?: 'Orientation'
                id: string
                isCompleted: boolean
              } | null
              birthday?: {
                __typename?: 'AnniversaryDate'
                day?: number | null
                month: number
                year?: number | null
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                timeZone?: string | null
                googleMapsPlaceId?: string | null
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  formatted?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }[]
          }
        }
    )[]
  }
}

export type GetEventCategoryMappingListQueryVariables = Exact<{
  [key: string]: never
}>

export type GetEventCategoryMappingListQuery = {
  __typename?: 'Query'
  eventCategoryMappingList?:
    | {
        __typename?: 'EventCategoryMapping'
        category: TimelineItemCategory
        event: TimelineEvent
      }[]
    | null
}

export type UpdateWorkspaceTeamMutationVariables = Exact<{
  input: UpdateWorkspaceTeamInput
}>

export type UpdateWorkspaceTeamMutation = {
  __typename?: 'Mutation'
  updateWorkspaceTeam: {
    __typename?: 'Workspace'
    id: string
    isArchived: boolean
    accountKeys: string[]
    isPool: boolean
    workingHoursTimeZone?: string | null
    usageAlertEmail: boolean
    usageAlertAssistantEmail: boolean
    usageLimitsEmail: boolean
    feedbackRequestEmail: boolean
    groupedNotificationsEmail: boolean
    eveningDigestEmail: boolean
    weeklyDigestEmail: boolean
    copilotEnabled: boolean
    isPendingTransition: boolean
    hasCoverage: boolean
    executives: (
      | {
          __typename?: 'ExecutiveUser'
          id: string
          facet?: UserFacet | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          csm?: {
            __typename?: 'ExecutivableCsm'
            id: string
            source:
              | { __typename?: 'AssistantUser' }
              | { __typename?: 'ExecutiveUser' }
              | { __typename?: 'HqUser' }
              | { __typename?: 'SandboxUser' }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
            user: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          } | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          csm?: {
            __typename?: 'ExecutivableCsm'
            id: string
            source:
              | { __typename?: 'AssistantUser' }
              | { __typename?: 'ExecutiveUser' }
              | { __typename?: 'HqUser' }
              | { __typename?: 'SandboxUser' }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
            user: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          } | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          facet?: UserFacet | null
          industries: string[]
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          csm?: {
            __typename?: 'ExecutivableCsm'
            id: string
            source:
              | { __typename?: 'AssistantUser' }
              | { __typename?: 'ExecutiveUser' }
              | { __typename?: 'HqUser' }
              | { __typename?: 'SandboxUser' }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
            user: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          } | null
          principalUser?:
            | {
                __typename?: 'ExecutiveUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'HqUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | {
                __typename?: 'SandboxUser'
                id: string
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              }
            | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    team?: {
      __typename?: 'Team'
      id: string
      name?: string | null
      iconUrl?: string | null
      isArchived: boolean
      principalUser:
        | {
            __typename?: 'ExecutiveUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
    } | null
    onboarding?: {
      __typename?: 'Onboarding'
      id: string
      isCompleted: boolean
      callStartAt?: string | Date | null
    } | null
    poolTokenBalance?: {
      __typename?: 'PoolTokenBalance'
      id: string
      availablePoolTokenCount: number
    } | null
    assistants: (
      | {
          __typename?: 'AssistantUser'
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          facet?: UserFacet | null
          position?: string | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          facet?: UserFacet | null
          industries: string[]
          id: string
          languages: string[]
          pronoun: UserPronoun
          isSaas: boolean
          hasPoolWorkspace: boolean
          isOnPause: boolean
          startDate?: string | null
          isArchived: boolean
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          orientation?: {
            __typename?: 'Orientation'
            id: string
            isCompleted: boolean
          } | null
          birthday?: {
            __typename?: 'AnniversaryDate'
            day?: number | null
            month: number
            year?: number | null
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            timeZone?: string | null
            googleMapsPlaceId?: string | null
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              formatted?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    )[]
    spectators: {
      __typename?: 'HqUser'
      facet?: UserFacet | null
      position?: string | null
      industries: string[]
      id: string
      languages: string[]
      pronoun: UserPronoun
      isSaas: boolean
      hasPoolWorkspace: boolean
      isOnPause: boolean
      startDate?: string | null
      isArchived: boolean
      category?: UserCategory | null
      dsm?: {
        __typename?: 'HqUser'
        id: string
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      } | null
      orientation?: {
        __typename?: 'Orientation'
        id: string
        isCompleted: boolean
      } | null
      birthday?: {
        __typename?: 'AnniversaryDate'
        day?: number | null
        month: number
        year?: number | null
      } | null
      city?: {
        __typename?: 'Place'
        id: string
        timeZone?: string | null
        googleMapsPlaceId?: string | null
        address?: {
          __typename?: 'PlaceAddress'
          id: string
          formatted?: string | null
        } | null
      } | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    }[]
  }
}

export type GetUserInvoicingHistoryListQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetUserInvoicingHistoryListQuery = {
  __typename?: 'Query'
  invoicingHistoryList: {
    __typename?: 'InvoicingHistoryList'
    items?:
      | {
          __typename?: 'InvoicedCycle'
          cycleStartDate: string | Date
          cycleEndDate: string | Date
          status: InvoicedCycleStatus
          invoicedPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }[]
      | null
  }
  user:
    | {
        __typename?: 'AssistantUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'ExecutiveUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'HqUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
    | {
        __typename?: 'SandboxUser'
        id: string
        startDate?: string | null
        isArchived: boolean
        category?: UserCategory | null
        profile: {
          __typename?: 'UserProfile'
          id: string
          emails: string[]
          avatarUrl?: string | null
          email?: string | null
          givenName?: string | null
          familyName?: string | null
          displayName?: string | null
        }
      }
}

export type GetTeamInvoicingHistoryListQueryVariables = Exact<{
  teamId: Scalars['ID']
}>

export type GetTeamInvoicingHistoryListQuery = {
  __typename?: 'Query'
  invoicingHistoryList: {
    __typename?: 'InvoicingHistoryList'
    items?:
      | {
          __typename?: 'InvoicedCycle'
          cycleStartDate: string | Date
          cycleEndDate: string | Date
          status: InvoicedCycleStatus
          invoicedPricing?: {
            __typename?: 'EntityPricing'
            id: string
            selectedAt: string | Date
            activeAt: string | Date
            expireAt?: string | Date | null
            notepad: string
            pricing: {
              __typename?: 'Pricing'
              id: string
              ref: string
              label: string
              description?: string | null
              priceDescription?: string | null
              hoursDescription?: string | null
              sellingPoints: string[]
              sellingPointsNotice?: string | null
              learnMoreUrl?: string | null
              isPublic: boolean
              isAvailable: boolean
              isSharable: boolean
              instantInviteMaxUsers: number
              model?: PricingModel | null
              modelConfig: Record<string, any>
              includesTeamFeatures: boolean
              includesPremiumPerks: boolean
            }
            owner:
              | {
                  __typename?: 'ExecutiveUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'HqUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'SandboxUser'
                  id: string
                  isArchived: boolean
                  category?: UserCategory | null
                  profile: {
                    __typename?: 'UserProfile'
                    id: string
                    emails: string[]
                    avatarUrl?: string | null
                    email?: string | null
                    givenName?: string | null
                    familyName?: string | null
                    displayName?: string | null
                  }
                }
              | {
                  __typename?: 'Team'
                  id: string
                  name?: string | null
                  iconUrl?: string | null
                  isArchived: boolean
                }
          } | null
        }[]
      | null
  }
  team: {
    __typename?: 'Team'
    createdAt: string | Date
    id: string
    name?: string | null
    iconUrl?: string | null
    isArchived: boolean
  }
}

export type TestExecLeadFragment = {
  __typename?: 'AirtableLead'
  id: string
  name?: string | null
  fields: Record<string, any>
}

export type TestExecMatchingFragment = {
  __typename?: 'Matching'
  id: string
  isHighTouch: boolean
  proposals: {
    __typename?: 'MatchingProposal'
    queuedAt: string | Date
    orderIndex: string
    sentAt?: string | Date | null
    slackChannelId?: string | null
    repliedAt?: string | Date | null
    replyComment?: string | null
    introCallAt?: string | Date | null
    id: string
    reply?: MatchingProposalReply | null
    confirmedAt?: string | Date | null
    reasonForMatch?: string | null
    executiveReasonForMatch?: string | null
    additionalContext?: string | null
    isInstantInvite: boolean
    application?: {
      __typename?: 'MatchingApplication'
      id: string
      at?: string | Date | null
      motivation?: string | null
    } | null
    assistant:
      | {
          __typename?: 'AssistantUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          slackUserId?: string | null
          id: string
          isArchived: boolean
          currency?: string | null
          hourlyRate?: number | null
          category?: UserCategory | null
          dsm?: {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          } | null
          city?: {
            __typename?: 'Place'
            id: string
            address?: {
              __typename?: 'PlaceAddress'
              id: string
              countryCode?: string | null
            } | null
          } | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
    confirmedBy?:
      | {
          __typename?: 'AssistantUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'ExecutiveUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'HqUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | {
          __typename?: 'SandboxUser'
          id: string
          isArchived: boolean
          category?: UserCategory | null
          profile: {
            __typename?: 'UserProfile'
            id: string
            emails: string[]
            avatarUrl?: string | null
            email?: string | null
            givenName?: string | null
            familyName?: string | null
            displayName?: string | null
          }
        }
      | null
  }[]
  confirmedAssistant?:
    | { __typename?: 'AssistantUser'; id: string }
    | { __typename?: 'HqUser'; id: string }
    | { __typename?: 'SandboxUser'; id: string }
    | null
}

export type TestExecUserFragment = {
  __typename?: 'ExecutiveUser'
  id: string
  isArchived: boolean
  category?: UserCategory | null
  profile: {
    __typename?: 'UserProfile'
    id: string
    emails: string[]
    avatarUrl?: string | null
    email?: string | null
    givenName?: string | null
    familyName?: string | null
    displayName?: string | null
  }
}

export type TestExecFragment = {
  __typename?: 'TestExec'
  lead?: {
    __typename?: 'AirtableLead'
    id: string
    name?: string | null
    fields: Record<string, any>
  } | null
  matching?: {
    __typename?: 'Matching'
    id: string
    isHighTouch: boolean
    proposals: {
      __typename?: 'MatchingProposal'
      queuedAt: string | Date
      orderIndex: string
      sentAt?: string | Date | null
      slackChannelId?: string | null
      repliedAt?: string | Date | null
      replyComment?: string | null
      introCallAt?: string | Date | null
      id: string
      reply?: MatchingProposalReply | null
      confirmedAt?: string | Date | null
      reasonForMatch?: string | null
      executiveReasonForMatch?: string | null
      additionalContext?: string | null
      isInstantInvite: boolean
      application?: {
        __typename?: 'MatchingApplication'
        id: string
        at?: string | Date | null
        motivation?: string | null
      } | null
      assistant:
        | {
            __typename?: 'AssistantUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            slackUserId?: string | null
            id: string
            isArchived: boolean
            currency?: string | null
            hourlyRate?: number | null
            category?: UserCategory | null
            dsm?: {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            } | null
            city?: {
              __typename?: 'Place'
              id: string
              address?: {
                __typename?: 'PlaceAddress'
                id: string
                countryCode?: string | null
              } | null
            } | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
      confirmedBy?:
        | {
            __typename?: 'AssistantUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'ExecutiveUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'HqUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | {
            __typename?: 'SandboxUser'
            id: string
            isArchived: boolean
            category?: UserCategory | null
            profile: {
              __typename?: 'UserProfile'
              id: string
              emails: string[]
              avatarUrl?: string | null
              email?: string | null
              givenName?: string | null
              familyName?: string | null
              displayName?: string | null
            }
          }
        | null
    }[]
    confirmedAssistant?:
      | { __typename?: 'AssistantUser'; id: string }
      | { __typename?: 'HqUser'; id: string }
      | { __typename?: 'SandboxUser'; id: string }
      | null
  } | null
  user?: {
    __typename?: 'ExecutiveUser'
    id: string
    isArchived: boolean
    category?: UserCategory | null
    profile: {
      __typename?: 'UserProfile'
      id: string
      emails: string[]
      avatarUrl?: string | null
      email?: string | null
      givenName?: string | null
      familyName?: string | null
      displayName?: string | null
    }
  } | null
}

export type GetTestExecQueryVariables = Exact<{ [key: string]: never }>

export type GetTestExecQuery = {
  __typename?: 'Query'
  testExec?: {
    __typename?: 'TestExec'
    lead?: {
      __typename?: 'AirtableLead'
      id: string
      name?: string | null
      fields: Record<string, any>
    } | null
    matching?: {
      __typename?: 'Matching'
      id: string
      isHighTouch: boolean
      proposals: {
        __typename?: 'MatchingProposal'
        queuedAt: string | Date
        orderIndex: string
        sentAt?: string | Date | null
        slackChannelId?: string | null
        repliedAt?: string | Date | null
        replyComment?: string | null
        introCallAt?: string | Date | null
        id: string
        reply?: MatchingProposalReply | null
        confirmedAt?: string | Date | null
        reasonForMatch?: string | null
        executiveReasonForMatch?: string | null
        additionalContext?: string | null
        isInstantInvite: boolean
        application?: {
          __typename?: 'MatchingApplication'
          id: string
          at?: string | Date | null
          motivation?: string | null
        } | null
        assistant:
          | {
              __typename?: 'AssistantUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              slackUserId?: string | null
              id: string
              isArchived: boolean
              currency?: string | null
              hourlyRate?: number | null
              category?: UserCategory | null
              dsm?: {
                __typename?: 'HqUser'
                id: string
                isArchived: boolean
                category?: UserCategory | null
                profile: {
                  __typename?: 'UserProfile'
                  id: string
                  emails: string[]
                  avatarUrl?: string | null
                  email?: string | null
                  givenName?: string | null
                  familyName?: string | null
                  displayName?: string | null
                }
              } | null
              city?: {
                __typename?: 'Place'
                id: string
                address?: {
                  __typename?: 'PlaceAddress'
                  id: string
                  countryCode?: string | null
                } | null
              } | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
        confirmedBy?:
          | {
              __typename?: 'AssistantUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'ExecutiveUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'HqUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | {
              __typename?: 'SandboxUser'
              id: string
              isArchived: boolean
              category?: UserCategory | null
              profile: {
                __typename?: 'UserProfile'
                id: string
                emails: string[]
                avatarUrl?: string | null
                email?: string | null
                givenName?: string | null
                familyName?: string | null
                displayName?: string | null
              }
            }
          | null
      }[]
      confirmedAssistant?:
        | { __typename?: 'AssistantUser'; id: string }
        | { __typename?: 'HqUser'; id: string }
        | { __typename?: 'SandboxUser'; id: string }
        | null
    } | null
    user?: {
      __typename?: 'ExecutiveUser'
      id: string
      isArchived: boolean
      category?: UserCategory | null
      profile: {
        __typename?: 'UserProfile'
        id: string
        emails: string[]
        avatarUrl?: string | null
        email?: string | null
        givenName?: string | null
        familyName?: string | null
        displayName?: string | null
      }
    } | null
  } | null
}

export type CreateTestExecLeadMutationVariables = Exact<{
  input: CreateTestExecLeadInput
}>

export type CreateTestExecLeadMutation = {
  __typename?: 'Mutation'
  createTestExecLead: {
    __typename?: 'CreateTestExecLeadOutput'
    isSuccess: boolean
  }
}

export type CreateTestExecMatchingMutationVariables = Exact<{
  [key: string]: never
}>

export type CreateTestExecMatchingMutation = {
  __typename?: 'Mutation'
  createTestExecMatching: {
    __typename?: 'CreateTestExecMatchingOutput'
    isSuccess: boolean
  }
}

export type ResetTestExecLifecycleMutationVariables = Exact<{
  [key: string]: never
}>

export type ResetTestExecLifecycleMutation = {
  __typename?: 'Mutation'
  resetTestExecLifecycle: {
    __typename?: 'ResetTestExecLifecycleOutput'
    isSuccess: boolean
  }
}

export type PauseTeamMutationVariables = Exact<{
  input: PauseTeamInput
}>

export type PauseTeamMutation = {
  __typename?: 'Mutation'
  pauseTeam: {
    __typename?: 'Team'
    id: string
    billingPauseStatus?: {
      __typename?: 'BillingPauseStatus'
      id: string
      startAt: string | Date
      endAt?: string | Date | null
    } | null
  }
}

export type UnpauseTeamMutationVariables = Exact<{
  input: UnpauseTeamInput
}>

export type UnpauseTeamMutation = {
  __typename?: 'Mutation'
  unpauseTeam: {
    __typename?: 'Team'
    id: string
    billingPauseStatus?: {
      __typename?: 'BillingPauseStatus'
      id: string
      startAt: string | Date
      endAt?: string | Date | null
    } | null
  }
}

export type UnarchiveTeamMutationVariables = Exact<{
  input: UnarchiveTeamInput
}>

export type UnarchiveTeamMutation = {
  __typename?: 'Mutation'
  unarchiveTeam: {
    __typename?: 'Team'
    id: string
    name?: string | null
    iconUrl?: string | null
    isArchived: boolean
  }
}

export type SystemFeatureFlagFragment = {
  __typename?: 'SystemFeatureFlag'
  id: string
  description: string
  isEnabled: boolean
}

export type ListSystemFeatureFlagsQueryVariables = Exact<{
  [key: string]: never
}>

export type ListSystemFeatureFlagsQuery = {
  __typename?: 'Query'
  list: {
    __typename?: 'SystemFeatureFlagsList'
    items: {
      __typename?: 'SystemFeatureFlag'
      id: string
      description: string
      isEnabled: boolean
    }[]
  }
}

export type UpdateSystemFeatureFlagMutationVariables = Exact<{
  input: UpdateSystemFeatureFlagInput
}>

export type UpdateSystemFeatureFlagMutation = {
  __typename?: 'Mutation'
  updateSystemFeatureFlag: {
    __typename?: 'UpdateSystemFeatureFlagOutput'
    systemFeatureFlag: {
      __typename?: 'SystemFeatureFlag'
      id: string
      description: string
      isEnabled: boolean
    }
  }
}

export type CreateMatchingFromLeadMutationVariables = Exact<{
  input: CreateMatchingFromLeadInput
}>

export type CreateMatchingFromLeadMutation = {
  __typename?: 'Mutation'
  createMatchingFromLead: {
    __typename?: 'CreateMatchingFromLeadOutput'
    matching: {
      __typename?: 'Matching'
      hasToBeMatched: boolean
      id: string
      bookmarksCount: number
      isHighTouch: boolean
      isHighTouchMaybe: boolean
      targetCandidatesCount: number
      isTransition: boolean
      isHiddenFromCatalog: boolean
      completedAt?: string | Date | null
      isTeamInvite?: boolean | null
      profiles: {
        __typename?: 'MatchingProfile'
        id: string
        name?: string | null
        introCallDate?: string | null
        matchDueDate?: string | null
        internalMatchingDate?: string | null
        country?: string | null
      }[]
      proposals: {
        __typename?: 'MatchingProposal'
        id: string
        sentAt?: string | Date | null
        reply?: MatchingProposalReply | null
        isInstantInvite: boolean
      }[]
      applications: { __typename?: 'MatchingApplication'; id: string }[]
    }
  }
}

export const WorkspaceFeatureFragmentDoc = gql`
  fragment WorkspaceFeature on WorkspaceFeature {
    id
    feature {
      id
    }
    isEnabled
    isOverridden
  }
`
export const PlaceAddress_CountryCodeFragmentDoc = gql`
  fragment PlaceAddress_CountryCode on PlaceAddress {
    id
    countryCode
  }
`
export const Userable_CountryCodeFragmentDoc = gql`
  fragment Userable_CountryCode on Userable {
    id
    city {
      id
      address {
        ...PlaceAddress_CountryCode
      }
    }
  }
  ${PlaceAddress_CountryCodeFragmentDoc}
`
export const Userable_StartDateFragmentDoc = gql`
  fragment Userable_StartDate on Userable {
    id
    startDate
  }
`
export const Userable_CategoryFragmentDoc = gql`
  fragment Userable_Category on Userable {
    id
    category
  }
`
export const UserProfile_NamesFragmentDoc = gql`
  fragment UserProfile_Names on UserProfile {
    id
    givenName
    familyName
    displayName
  }
`
export const UserProfile_NamesPhotoFragmentDoc = gql`
  fragment UserProfile_NamesPhoto on UserProfile {
    id
    ...UserProfile_Names
    avatarUrl
  }
  ${UserProfile_NamesFragmentDoc}
`
export const UserProfile_EmailFragmentDoc = gql`
  fragment UserProfile_Email on UserProfile {
    id
    email
  }
`
export const UserProfile_NamesPhotoEmailsFragmentDoc = gql`
  fragment UserProfile_NamesPhotoEmails on UserProfile {
    id
    ...UserProfile_NamesPhoto
    ...UserProfile_Email
    emails
  }
  ${UserProfile_NamesPhotoFragmentDoc}
  ${UserProfile_EmailFragmentDoc}
`
export const Userable_ProfileFragmentDoc = gql`
  fragment Userable_Profile on Userable {
    id
    profile {
      ...UserProfile_NamesPhotoEmails
    }
  }
  ${UserProfile_NamesPhotoEmailsFragmentDoc}
`
export const Userable_MinimalFragmentDoc = gql`
  fragment Userable_Minimal on Userable {
    id
    isArchived
    ...Userable_Category
    ...Userable_Profile
  }
  ${Userable_CategoryFragmentDoc}
  ${Userable_ProfileFragmentDoc}
`
export const PlaceAddress_FormattedFragmentDoc = gql`
  fragment PlaceAddress_Formatted on PlaceAddress {
    id
    formatted
  }
`
export const Place_AddressFragmentDoc = gql`
  fragment Place_Address on Place {
    id
    address {
      ...PlaceAddress_Formatted
    }
  }
  ${PlaceAddress_FormattedFragmentDoc}
`
export const Place_GoogleMapsPlaceIdFragmentDoc = gql`
  fragment Place_GoogleMapsPlaceId on Place {
    id
    googleMapsPlaceId
  }
`
export const CityFragmentDoc = gql`
  fragment City on Place {
    id
    timeZone
    ...Place_Address
    ...Place_GoogleMapsPlaceId
  }
  ${Place_AddressFragmentDoc}
  ${Place_GoogleMapsPlaceIdFragmentDoc}
`
export const Executivable_IsOnPauseFragmentDoc = gql`
  fragment Executivable_IsOnPause on Executivable {
    id
    isOnPause
  }
`
export const BasicUserFragmentDoc = gql`
  fragment BasicUser on Userable {
    id
    ...Userable_StartDate
    ...Userable_Minimal
    languages
    birthday {
      day
      month
      year
    }
    pronoun
    isSaas
    hasPoolWorkspace
    city {
      ...City
    }
    ... on Executivable {
      ...Executivable_IsOnPause
      facet
    }
    ... on HqUser {
      position
    }
    ... on Matchable {
      industries
    }
    ... on Assistantable {
      dsm {
        ...Userable_Minimal
      }
      orientation {
        id
        isCompleted
      }
    }
  }
  ${Userable_StartDateFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${CityFragmentDoc}
  ${Executivable_IsOnPauseFragmentDoc}
`
export const Workspace_UsersFragmentDoc = gql`
  fragment Workspace_Users on Workspace {
    id
    isPendingTransition
    hasCoverage
    assistants {
      ...BasicUser
    }
    executives {
      ...BasicUser
      principalUser {
        ...Userable_Profile
      }
    }
    spectators {
      ...BasicUser
    }
  }
  ${BasicUserFragmentDoc}
  ${Userable_ProfileFragmentDoc}
`
export const Automation_NameFragmentDoc = gql`
  fragment Automation_Name on Automation {
    id
    name
  }
`
export const AutomationJob_ItemFragmentDoc = gql`
  fragment AutomationJob_Item on AutomationJob {
    id
    workspace {
      ...Workspace_Users
    }
    user {
      ...Userable_Profile
    }
    executive {
      ...Userable_Profile
    }
    assistant {
      ...Userable_Profile
    }
    delayUntil
    createdAt
    startedAt
    endedAt
    cancelledAt
    updatedAt
    reviewedAt
    hasError
    automation {
      ...Automation_Name
    }
  }
  ${Workspace_UsersFragmentDoc}
  ${Userable_ProfileFragmentDoc}
  ${Automation_NameFragmentDoc}
`
export const Userable_PendingChurnAutomationJobFragmentDoc = gql`
  fragment Userable_PendingChurnAutomationJob on Userable {
    id
    pendingChurnAutomationJob {
      ...AutomationJob_Item
    }
  }
  ${AutomationJob_ItemFragmentDoc}
`
export const OnboardingAvailabilityFragmentDoc = gql`
  fragment OnboardingAvailability on OnboardingAvailability {
    id
    slots {
      startAt
      endAt
    }
  }
`
export const Onboarding_AvailabilityFragmentDoc = gql`
  fragment Onboarding_Availability on Onboarding {
    id
    availability {
      ...OnboardingAvailability
    }
  }
  ${OnboardingAvailabilityFragmentDoc}
`
export const Onboarding_ChecklistFragmentDoc = gql`
  fragment Onboarding_Checklist on Onboarding {
    id
    checklist {
      id
      tasks {
        id
        title
        isCompleted
      }
    }
  }
`
export const Onboarding_CompletionFragmentDoc = gql`
  fragment Onboarding_Completion on Onboarding {
    id
    isCompleted
  }
`
export const Onboarding_CallFragmentDoc = gql`
  fragment Onboarding_Call on Onboarding {
    id
    callStartAt
  }
`
export const Onboarding_EmailStatusFragmentDoc = gql`
  fragment Onboarding_EmailStatus on Onboarding {
    id
    hasSentEmail
    hasSentEmail2
    hasSentEmail3
    hasSentEmail4
    hasSentPostOnboardingEmail
  }
`
export const Onboarding_StepsFragmentDoc = gql`
  fragment Onboarding_Steps on Onboarding {
    id
    steps {
      id
      isCompleted
      data
    }
  }
`
export const Onboarding_SpecialistFragmentDoc = gql`
  fragment Onboarding_Specialist on Onboarding {
    id
    specialist {
      ...BasicUser
    }
  }
  ${BasicUserFragmentDoc}
`
export const WorkspaceWithOnboardingFragmentDoc = gql`
  fragment WorkspaceWithOnboarding on Workspace {
    id
    onboarding {
      id
      url
      ...Onboarding_Completion
      ...Onboarding_Call
      ...Onboarding_EmailStatus
      ...Onboarding_Steps
      ...Onboarding_Specialist
      rescheduleCallUrl
      splash {
        executiveEmail
        assistantGivenName
        assistantAvatarUrl
        assistantBiography
        assistantLocation
      }
    }
  }
  ${Onboarding_CompletionFragmentDoc}
  ${Onboarding_CallFragmentDoc}
  ${Onboarding_EmailStatusFragmentDoc}
  ${Onboarding_StepsFragmentDoc}
  ${Onboarding_SpecialistFragmentDoc}
`
export const AssistantableHourlyRateUpdateFragmentDoc = gql`
  fragment AssistantableHourlyRateUpdate on AssistantableHourlyRateUpdate {
    hourlyRate
    updatedAt
    updatedBy {
      ...Userable_Minimal
    }
  }
  ${Userable_MinimalFragmentDoc}
`
export const Assistantable_HourlyRateUpdatesFragmentDoc = gql`
  fragment Assistantable_HourlyRateUpdates on Assistantable {
    id
    hourlyRateUpdates {
      ...AssistantableHourlyRateUpdate
    }
  }
  ${AssistantableHourlyRateUpdateFragmentDoc}
`
export const UserAccountFragmentDoc = gql`
  fragment UserAccount on UserAccount {
    key
    type
    id
    isPrimary
    label
    profile {
      ...UserProfile_Email
    }
    scopes
    isDisconnected
  }
  ${UserProfile_EmailFragmentDoc}
`
export const BasicUserWithAccountsFragmentDoc = gql`
  fragment BasicUserWithAccounts on Userable {
    ...BasicUser
    accounts {
      ...UserAccount
    }
  }
  ${BasicUserFragmentDoc}
  ${UserAccountFragmentDoc}
`
export const Userable_AirtableIdFragmentDoc = gql`
  fragment Userable_AirtableId on Userable {
    id
    ... on Executivable {
      airtableClientRecordId
      airtableLeadRecordId
    }
    ... on Assistantable {
      airtableDoubleRecordId
    }
  }
`
export const Workspace_SettingsFragmentDoc = gql`
  fragment Workspace_Settings on Workspace {
    id
    workingHoursTimeZone
    usageAlertEmail
    usageAlertAssistantEmail
    usageLimitsEmail
    feedbackRequestEmail
    groupedNotificationsEmail
    eveningDigestEmail
    weeklyDigestEmail
    copilotEnabled
  }
`
export const PoolTokenBalanceFragmentDoc = gql`
  fragment PoolTokenBalance on PoolTokenBalance {
    id
    availablePoolTokenCount
  }
`
export const BasicWorkspaceFragmentDoc = gql`
  fragment BasicWorkspace on Workspace {
    id
    isArchived
    ...Workspace_Settings
    ...Workspace_Users
    accountKeys
    onboarding {
      ...Onboarding_Completion
      ...Onboarding_Call
    }
    isPool
    poolTokenBalance {
      ...PoolTokenBalance
    }
  }
  ${Workspace_SettingsFragmentDoc}
  ${Workspace_UsersFragmentDoc}
  ${Onboarding_CompletionFragmentDoc}
  ${Onboarding_CallFragmentDoc}
  ${PoolTokenBalanceFragmentDoc}
`
export const BasicTeamFragmentDoc = gql`
  fragment BasicTeam on Team {
    id
    name
    iconUrl
    isArchived
  }
`
export const BasicTeamWithPrincipalFragmentDoc = gql`
  fragment BasicTeamWithPrincipal on Team {
    ...BasicTeam
    principalUser {
      ...Userable_Profile
    }
  }
  ${BasicTeamFragmentDoc}
  ${Userable_ProfileFragmentDoc}
`
export const Team_SubscriptionFragmentDoc = gql`
  fragment Team_Subscription on Team {
    id
    subscription {
      id
      type
      productName
      productPrice
      state
      maxioUrl
      customerDisplayName
      currentBillingCycle {
        startDate
        endDate
        hours
      }
    }
  }
`
export const WorkspaceWithTeamAndMaxioSubscriptionFragmentDoc = gql`
  fragment WorkspaceWithTeamAndMaxioSubscription on Workspace {
    ...BasicWorkspace
    team {
      ...BasicTeamWithPrincipal
      ...Team_Subscription
    }
  }
  ${BasicWorkspaceFragmentDoc}
  ${BasicTeamWithPrincipalFragmentDoc}
  ${Team_SubscriptionFragmentDoc}
`
export const Userable_WorkspacesFragmentDoc = gql`
  fragment Userable_Workspaces on Userable {
    id
    workspaces {
      ...WorkspaceWithTeamAndMaxioSubscription
    }
  }
  ${WorkspaceWithTeamAndMaxioSubscriptionFragmentDoc}
`
export const StripeCustomer_PaymentStatusFragmentDoc = gql`
  fragment StripeCustomer_PaymentStatus on StripeCustomer {
    id
    paymentStatus
  }
`
export const Executivable_StripeCustomerFragmentDoc = gql`
  fragment Executivable_StripeCustomer on Executivable {
    id
    stripeCustomer {
      ...StripeCustomer_PaymentStatus
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
`
export const Executivable_StripeCustomerIdFragmentDoc = gql`
  fragment Executivable_StripeCustomerId on Executivable {
    id
    stripeCustomer {
      id
    }
  }
`
export const ExecutivableCsmFragmentDoc = gql`
  fragment ExecutivableCsm on ExecutivableCsm {
    id
    source {
      ... on Team {
        ...BasicTeam
      }
    }
    user {
      ...Userable_Minimal
    }
  }
  ${BasicTeamFragmentDoc}
  ${Userable_MinimalFragmentDoc}
`
export const DiscScoreFragmentDoc = gql`
  fragment DiscScore on DiscScore {
    d
    i
    s
    c
  }
`
export const HourlyRateCurrencyFragmentDoc = gql`
  fragment HourlyRateCurrency on Assistantable {
    currency
    hourlyRate
    ... on Userable {
      city {
        id
        address {
          ...PlaceAddress_CountryCode
        }
      }
    }
  }
  ${PlaceAddress_CountryCodeFragmentDoc}
`
export const Assistantable_MatchingDataFragmentDoc = gql`
  fragment Assistantable_MatchingData on Assistantable {
    id
    interestedInIndustries
    experienceInTools
    skillsetRating
    internalDisc {
      ...DiscScore
    }
    externalDisc {
      ...DiscScore
    }
    isOpenToMatch
    isOpenToSupport
    openToUpdatedAt
    targetTotalWeeklyHours
    targetAdditionalWeeklyHours
    experienceHighlights
    englishLevel
    resumeUrl
    ...HourlyRateCurrency
  }
  ${DiscScoreFragmentDoc}
  ${HourlyRateCurrencyFragmentDoc}
`
export const AssistantUser_CsatAverageFragmentDoc = gql`
  fragment AssistantUser_CsatAverage on AssistantUser {
    id
    csatAverage
  }
`
export const AssistantUser_PerformanceScoreBadgeFragmentDoc = gql`
  fragment AssistantUser_PerformanceScoreBadge on AssistantUser {
    id
    performanceScoreValue
  }
`
export const FullUserFragmentDoc = gql`
  fragment FullUser on Userable {
    ...BasicUserWithAccounts
    acceptedPptcAt
    ...Userable_AirtableId
    ...Userable_Workspaces
    ... on Executivable {
      desiredSkills
      principalUser {
        ...Userable_Minimal
      }
      invoicedUser {
        ...Userable_Minimal
        ...Executivable_StripeCustomer
      }
      ...Executivable_StripeCustomerId
      csm {
        ...ExecutivableCsm
      }
    }
    ... on Matchable {
      personalityTraits
    }
    ... on Assistantable {
      ...Assistantable_MatchingData
      workTimeZones
      matchingNotepad
      dsm {
        ...Userable_Minimal
      }
    }
    ... on AssistantUser {
      phoneNumber
      ...AssistantUser_CsatAverage
      ...AssistantUser_PerformanceScoreBadge
    }
  }
  ${BasicUserWithAccountsFragmentDoc}
  ${Userable_AirtableIdFragmentDoc}
  ${Userable_WorkspacesFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${Executivable_StripeCustomerFragmentDoc}
  ${Executivable_StripeCustomerIdFragmentDoc}
  ${ExecutivableCsmFragmentDoc}
  ${Assistantable_MatchingDataFragmentDoc}
  ${AssistantUser_CsatAverageFragmentDoc}
  ${AssistantUser_PerformanceScoreBadgeFragmentDoc}
`
export const StripeInvoiceFragmentDoc = gql`
  fragment StripeInvoice on StripeInvoice {
    id
    description
    amount
    currency
    status
    createdAt
    periodStartAt
    periodEndAt
    lineItems {
      id
      amount
      currency
      description
    }
  }
`
export const StripeCustomer_InvoicesFragmentDoc = gql`
  fragment StripeCustomer_Invoices on StripeCustomer {
    id
    invoices(
      hasStatus: $invoiceStatus
      first: $invoicesFirst
      createdAfter: $invoicesCreatedAfter
      createdBefore: $invoicesCreatedBefore
      excludePlacements: true
    ) {
      ...StripeInvoice
    }
  }
  ${StripeInvoiceFragmentDoc}
`
export const PricingFragmentDoc = gql`
  fragment Pricing on Pricing {
    id
    ref
    label
    description
    priceDescription
    hoursDescription
    sellingPoints
    sellingPointsNotice
    learnMoreUrl
    isPublic
    isAvailable
    isSharable
    instantInviteMaxUsers
    model
    modelConfig
    includesTeamFeatures
    includesPremiumPerks
  }
`
export const EntityPricingFragmentDoc = gql`
  fragment EntityPricing on EntityPricing {
    id
    selectedAt
    activeAt
    expireAt
    notepad
    pricing {
      ...Pricing
    }
    owner {
      ... on Executivable {
        ...Userable_Minimal
      }
      ... on Team {
        ...BasicTeam
      }
    }
  }
  ${PricingFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${BasicTeamFragmentDoc}
`
export const Executivable_PricingSelectionsFragmentDoc = gql`
  fragment Executivable_PricingSelections on Executivable {
    id
    pricingSelections {
      id
      selectedAt
      activeAt
      expireAt
      notepad
      pricing {
        ...Pricing
      }
    }
    currentPricing {
      ...EntityPricing
    }
  }
  ${PricingFragmentDoc}
  ${EntityPricingFragmentDoc}
`
export const Team_PricingSelectionsFragmentDoc = gql`
  fragment Team_PricingSelections on Team {
    id
    pricingSelections {
      id
      selectedAt
      activeAt
      expireAt
      notepad
      pricing {
        ...Pricing
      }
    }
    currentPricing {
      ...EntityPricing
    }
  }
  ${PricingFragmentDoc}
  ${EntityPricingFragmentDoc}
`
export const BillableEntity_PricingSelectionsFragmentDoc = gql`
  fragment BillableEntity_PricingSelections on BillableEntity {
    ... on Executivable {
      ...Executivable_PricingSelections
    }
    ... on Team {
      ...Team_PricingSelections
    }
  }
  ${Executivable_PricingSelectionsFragmentDoc}
  ${Team_PricingSelectionsFragmentDoc}
`
export const Team_StripeCustomerFragmentDoc = gql`
  fragment Team_StripeCustomer on Team {
    id
    stripeCustomer {
      ...StripeCustomer_PaymentStatus
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
`
export const BillableEntity_StripeCustomerFragmentDoc = gql`
  fragment BillableEntity_StripeCustomer on BillableEntity {
    ... on Executivable {
      ...Executivable_StripeCustomer
    }
    ... on Team {
      ...Team_StripeCustomer
    }
  }
  ${Executivable_StripeCustomerFragmentDoc}
  ${Team_StripeCustomerFragmentDoc}
`
export const Assistantable_PrimaryEmailFragmentDoc = gql`
  fragment Assistantable_PrimaryEmail on Assistantable {
    id
    primaryEmail
  }
`
export const Assistantable_ContractorDataFragmentDoc = gql`
  fragment Assistantable_ContractorData on Assistantable {
    id
    hourlyRate
    currency
    ein
    justworksVendorName
    justworksGivenName
    justworksFamilyName
    personalEmail
  }
`
export const Assistantable_AssistantDataFragmentDoc = gql`
  fragment Assistantable_AssistantData on Assistantable {
    id
    biography
    funFacts
    skills
    mobileOs
    leverId
    yearsOfExperience
    slackUsername
    slackUserId
    hasSlackPush
    personalEmail
    schedulingUrl
    ...Assistantable_PrimaryEmail
    ...Assistantable_ContractorData
  }
  ${Assistantable_PrimaryEmailFragmentDoc}
  ${Assistantable_ContractorDataFragmentDoc}
`
export const FeatureFragmentDoc = gql`
  fragment Feature on Feature {
    id
    description
    appVersions
    targets {
      predicates {
        __typename
        ... on FeatureTargetConstantPredicate {
          constant
        }
        ... on FeatureTargetExecutivePredicate {
          executiveIds
          isNegated
        }
        ... on FeatureTargetAssistantPredicate {
          assistantIds
          isNegated
        }
        ... on FeatureTargetFeaturePredicate {
          featureId
          isNegated
        }
        ... on FeatureTargetCreatedAtPredicate {
          createdAfter
          isNegated
        }
      }
    }
  }
`
export const TopicFormFieldOptionTextFragmentDoc = gql`
  fragment TopicFormFieldOptionText on TopicFormFieldOptionText {
    text
    tags
  }
`
export const TopicFormFieldValueConditionFragmentDoc = gql`
  fragment TopicFormFieldValueCondition on TopicFormFieldValueCondition {
    fieldId
    value {
      ... on TopicExtendedPropValueText {
        text
      }
    }
  }
`
export const TopicFormFieldFragmentDoc = gql`
  fragment TopicFormField on TopicFormField {
    id
    question
    message
    imageUrl
    isRequired
    airtableClientField
    maxValues
    ... on TopicFormFieldText {
      isAllowingAudio
      isAllowingFreeForm
      freeFormPlaceholder
      options {
        ...TopicFormFieldOptionText
      }
    }
    enabledIf {
      ... on TopicFormFieldValueCondition {
        ...TopicFormFieldValueCondition
      }
      ... on TopicFormOperatorCondition {
        operator
        conditions {
          ... on TopicFormFieldValueCondition {
            ...TopicFormFieldValueCondition
          }
          ... on TopicFormOperatorCondition {
            operator
            conditions {
              ... on TopicFormFieldValueCondition {
                ...TopicFormFieldValueCondition
              }
            }
          }
        }
      }
    }
  }
  ${TopicFormFieldOptionTextFragmentDoc}
  ${TopicFormFieldValueConditionFragmentDoc}
`
export const TopicFormFragmentDoc = gql`
  fragment TopicForm on TopicForm {
    id
    ref
    fields {
      ...TopicFormField
    }
  }
  ${TopicFormFieldFragmentDoc}
`
export const TopicTemplateFragmentDoc = gql`
  fragment TopicTemplate on TopicTemplate {
    id
    type
    category
    sectionKey
    title
    description
    imageUrl
    instructions
    introduction
    introductionTitle
    introductionImageUrl
    isDraft
    isNew
    keywords
    labels
    privateTo
    notepad
    assigneeRole
    form {
      ...TopicForm
    }
  }
  ${TopicFormFragmentDoc}
`
export const MinimalWhoFragmentDoc = gql`
  fragment MinimalWho on Who {
    id
    avatarUrl
    givenName
  }
`
export const BasicWhoFragmentDoc = gql`
  fragment BasicWho on Who {
    ...MinimalWho
    category
    startDate
    biography
    funFacts
    position
    skills
    languages
    birthday {
      day
      month
    }
    city {
      id
      address {
        ...PlaceAddress_Formatted
      }
      geo {
        coordinates {
          lat
          lng
        }
      }
      timeZone
    }
    assistant {
      ...MinimalWho
    }
  }
  ${MinimalWhoFragmentDoc}
  ${PlaceAddress_FormattedFragmentDoc}
`
export const Executivable_TeammateFragmentDoc = gql`
  fragment Executivable_Teammate on Executivable {
    id
    hoursLimit
    isAllowedPersonal
  }
`
export const ExecutivableBillingFragmentDoc = gql`
  fragment ExecutivableBilling on Executivable {
    ...Executivable_StripeCustomer
    ...Executivable_PricingSelections
    ...Executivable_Teammate
    invoicedUser {
      ...Userable_Minimal
      ...Executivable_StripeCustomer
    }
    principalUser {
      id
    }
  }
  ${Executivable_StripeCustomerFragmentDoc}
  ${Executivable_PricingSelectionsFragmentDoc}
  ${Executivable_TeammateFragmentDoc}
  ${Userable_MinimalFragmentDoc}
`
export const MatcherAssistantFragmentDoc = gql`
  fragment MatcherAssistant on Userable {
    id
    ...Userable_StartDate
    ...Userable_Profile
    city {
      ...City
    }
    languages
    ... on Matchable {
      industries
      personalityTraits
    }
    ...Assistantable_MatchingData
    ... on Assistantable {
      skills
      biography
      funFacts
      latestOnboardingAt
      workTimeZones
      matchingNotepad
      averageWeeklyWorkedHours
      leverId
      airtableDoubleRecordId
      dsm {
        ...Userable_Minimal
      }
    }
    ... on AssistantUser {
      ...AssistantUser_CsatAverage
      ...AssistantUser_PerformanceScoreBadge
    }
    workspaces(isSandbox: false, role: ASSISTANT) {
      id
      workingHoursTimeZone
      usage {
        id
        startDate
      }
      onboarding {
        id
        isCompleted
      }
      executives {
        id
        ...Userable_StartDate
        currentPricing {
          pricing {
            id
            modelConfig
          }
        }
      }
    }
  }
  ${Userable_StartDateFragmentDoc}
  ${Userable_ProfileFragmentDoc}
  ${CityFragmentDoc}
  ${Assistantable_MatchingDataFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${AssistantUser_CsatAverageFragmentDoc}
  ${AssistantUser_PerformanceScoreBadgeFragmentDoc}
`
export const SettingFragmentDoc = gql`
  fragment Setting on Setting {
    id
    data
  }
`
export const InvoicedCycleFragmentDoc = gql`
  fragment InvoicedCycle on InvoicedCycle {
    invoicedPricing {
      ...EntityPricing
    }
    cycleStartDate
    cycleEndDate
    status
  }
  ${EntityPricingFragmentDoc}
`
export const InvoicingRefundCreditHoursFragmentDoc = gql`
  fragment InvoicingRefundCreditHours on InvoicingRefundCreditHours {
    hours
  }
`
export const InvoicingRefundCreditAmountFragmentDoc = gql`
  fragment InvoicingRefundCreditAmount on InvoicingRefundCreditAmount {
    amount
  }
`
export const InvoicingRefundFragmentDoc = gql`
  fragment InvoicingRefund on InvoicingRefund {
    id
    reason
    description
    credit {
      ... on InvoicingRefundCreditHours {
        ...InvoicingRefundCreditHours
      }
      ... on InvoicingRefundCreditAmount {
        ...InvoicingRefundCreditAmount
      }
    }
    airtableUrl
  }
  ${InvoicingRefundCreditHoursFragmentDoc}
  ${InvoicingRefundCreditAmountFragmentDoc}
`
export const InvoicingAgreementFragmentDoc = gql`
  fragment InvoicingAgreement on InvoicingAgreement {
    id
    activeAt
    expireAt
    discountPercentage
    airtableUrl
    description
  }
`
export const InvoicingDiscountCreditHoursFragmentDoc = gql`
  fragment InvoicingDiscountCreditHours on InvoicingDiscountCreditHours {
    hours
  }
`
export const InvoicingDiscountCreditAmountFragmentDoc = gql`
  fragment InvoicingDiscountCreditAmount on InvoicingDiscountCreditAmount {
    amount
  }
`
export const InvoicingDiscountFragmentDoc = gql`
  fragment InvoicingDiscount on InvoicingDiscount {
    id
    reason
    description
    credit {
      ... on InvoicingDiscountCreditHours {
        ...InvoicingDiscountCreditHours
      }
      ... on InvoicingDiscountCreditAmount {
        ...InvoicingDiscountCreditAmount
      }
    }
    airtableUrl
  }
  ${InvoicingDiscountCreditHoursFragmentDoc}
  ${InvoicingDiscountCreditAmountFragmentDoc}
`
export const Executivable_InvoicedUserFragmentDoc = gql`
  fragment Executivable_InvoicedUser on Executivable {
    ...Userable_Minimal
    ...Userable_StartDate
    ... on Userable {
      profile {
        ...UserProfile_Names
      }
    }
    ...Executivable_IsOnPause
    ...Executivable_StripeCustomerId
    csm {
      ...ExecutivableCsm
    }
    ... on ExecutiveUser {
      invoicingRefunds(from: $from, to: $to) {
        ...InvoicingRefund
      }
      invoicingAgreements(from: $from, to: $to) {
        ...InvoicingAgreement
      }
      invoicingDiscounts(from: $from, to: $to) {
        ...InvoicingDiscount
      }
    }
    isOnMaxioBilling
  }
  ${Userable_MinimalFragmentDoc}
  ${Userable_StartDateFragmentDoc}
  ${UserProfile_NamesFragmentDoc}
  ${Executivable_IsOnPauseFragmentDoc}
  ${Executivable_StripeCustomerIdFragmentDoc}
  ${ExecutivableCsmFragmentDoc}
  ${InvoicingRefundFragmentDoc}
  ${InvoicingAgreementFragmentDoc}
  ${InvoicingDiscountFragmentDoc}
`
export const InvoicingReportTogglUsageFragmentDoc = gql`
  fragment InvoicingReportTogglUsage on InvoicingReportTogglUsage {
    id
    assistant {
      ...Userable_Minimal
    }
    hours
  }
  ${Userable_MinimalFragmentDoc}
`
export const InvoicingReportFragmentDoc = gql`
  fragment InvoicingReport on InvoicingReport {
    id
    executive {
      ...Executivable_InvoicedUser
      ... on Executivable {
        invoicedUser {
          ...Executivable_InvoicedUser
        }
        invoicedPricing(date: $invoicedPricingDate) {
          ...EntityPricing
        }
      }
    }
    isPaused
    churnedAt
    isTransitioning
    togglUsages {
      ...InvoicingReportTogglUsage
    }
  }
  ${Executivable_InvoicedUserFragmentDoc}
  ${EntityPricingFragmentDoc}
  ${InvoicingReportTogglUsageFragmentDoc}
`
export const ContractorReportFragmentDoc = gql`
  fragment ContractorReport on ContractorReport {
    id
    totalHours
    doubleHours
    user {
      id
      ...Userable_Profile
      ...Assistantable_ContractorData
      ...Assistantable_PrimaryEmail
      ... on Userable {
        city {
          id
          address {
            ...PlaceAddress_CountryCode
          }
        }
      }
    }
  }
  ${Userable_ProfileFragmentDoc}
  ${Assistantable_ContractorDataFragmentDoc}
  ${Assistantable_PrimaryEmailFragmentDoc}
  ${PlaceAddress_CountryCodeFragmentDoc}
`
export const PayPeriodFragmentDoc = gql`
  fragment PayPeriod on PayPeriod {
    id
    lockTimeTrackingOn
  }
`
export const BugReportFileFragmentDoc = gql`
  fragment BugReportFile on BugReportFile {
    id
    mime
    name
    downloadUrl
  }
`
export const BugReportFragmentDoc = gql`
  fragment BugReport on BugReport {
    id
    user {
      ...Userable_Minimal
    }
    message
    properties
    metadata
    files {
      ...BugReportFile
    }
    createdAt
  }
  ${Userable_MinimalFragmentDoc}
  ${BugReportFileFragmentDoc}
`
export const WorkspaceWeeklyUsageFragmentDoc = gql`
  fragment WorkspaceWeeklyUsage on WorkspaceWeeklyUsage {
    id
    weekYear
    week
    sunday
    hours
  }
`
export const WorkspaceCategoryUsageFragmentDoc = gql`
  fragment WorkspaceCategoryUsage on WorkspaceCategoryUsage {
    category
    hours
  }
`
export const WorkspaceWeeklyUsageWithCategoriesFragmentDoc = gql`
  fragment WorkspaceWeeklyUsageWithCategories on WorkspaceWeeklyUsage {
    id
    ...WorkspaceWeeklyUsage
    categories {
      ...WorkspaceCategoryUsage
    }
  }
  ${WorkspaceWeeklyUsageFragmentDoc}
  ${WorkspaceCategoryUsageFragmentDoc}
`
export const WorkspaceMonthlyUsageFragmentDoc = gql`
  fragment WorkspaceMonthlyUsage on WorkspaceMonthlyUsage {
    id
    year
    month
    hours
  }
`
export const WorkspaceMonthlyUsageWithCategoriesFragmentDoc = gql`
  fragment WorkspaceMonthlyUsageWithCategories on WorkspaceMonthlyUsage {
    id
    ...WorkspaceMonthlyUsage
    categories {
      ...WorkspaceCategoryUsage
    }
  }
  ${WorkspaceMonthlyUsageFragmentDoc}
  ${WorkspaceCategoryUsageFragmentDoc}
`
export const WorkspaceUsageFragmentDoc = gql`
  fragment WorkspaceUsage on WorkspaceUsage {
    id
    weeklyUsages(last: 16) {
      ...WorkspaceWeeklyUsageWithCategories
    }
    monthlyUsages(last: 4) {
      ...WorkspaceMonthlyUsageWithCategories
    }
  }
  ${WorkspaceWeeklyUsageWithCategoriesFragmentDoc}
  ${WorkspaceMonthlyUsageWithCategoriesFragmentDoc}
`
export const WorkspaceReportFragmentDoc = gql`
  fragment WorkspaceReport on WorkspaceReport {
    id
    workspace {
      id
      hasHadCoverage(assistantId: $userId)
      executives {
        ...Userable_Minimal
        ...Userable_Category
        principalUser {
          id
        }
      }
    }
    weeklyUsages {
      ...WorkspaceWeeklyUsage
    }
    monthlyUsages {
      ...WorkspaceMonthlyUsage
    }
  }
  ${Userable_MinimalFragmentDoc}
  ${Userable_CategoryFragmentDoc}
  ${WorkspaceWeeklyUsageFragmentDoc}
  ${WorkspaceMonthlyUsageFragmentDoc}
`
export const MatchingProposal_SentReplyFragmentDoc = gql`
  fragment MatchingProposal_SentReply on MatchingProposal {
    id
    sentAt
    reply
  }
`
export const Matching_InstantInviteFragmentDoc = gql`
  fragment Matching_InstantInvite on Matching {
    id
    proposals {
      ...MatchingProposal_SentReply
    }
  }
  ${MatchingProposal_SentReplyFragmentDoc}
`
export const MatchingProposal_IsInstantInviteFragmentDoc = gql`
  fragment MatchingProposal_IsInstantInvite on MatchingProposal {
    id
    isInstantInvite
  }
`
export const Matching_ProposalsReplyFragmentDoc = gql`
  fragment Matching_ProposalsReply on Matching {
    id
    proposals {
      ...MatchingProposal_SentReply
      ...MatchingProposal_IsInstantInvite
    }
  }
  ${MatchingProposal_SentReplyFragmentDoc}
  ${MatchingProposal_IsInstantInviteFragmentDoc}
`
export const Matching_ApplicationsIdFragmentDoc = gql`
  fragment Matching_ApplicationsId on Matching {
    id
    applications {
      id
    }
  }
`
export const Matching_BookmarksCountFragmentDoc = gql`
  fragment Matching_BookmarksCount on Matching {
    id
    bookmarksCount
  }
`
export const Matching_IsHighTouchFragmentDoc = gql`
  fragment Matching_IsHighTouch on Matching {
    id
    isHighTouch
    isHighTouchMaybe
    targetCandidatesCount
  }
`
export const Matching_IsTransitionFragmentDoc = gql`
  fragment Matching_IsTransition on Matching {
    id
    isTransition
  }
`
export const Matching_CatalogVisibilityFragmentDoc = gql`
  fragment Matching_CatalogVisibility on Matching {
    id
    isHiddenFromCatalog
  }
`
export const Matching_CompletedAtFragmentDoc = gql`
  fragment Matching_CompletedAt on Matching {
    id
    completedAt
  }
`
export const Matching_IsTeamInviteFragmentDoc = gql`
  fragment Matching_isTeamInvite on Matching {
    id
    isTeamInvite
  }
`
export const MatchingProfile_NameFragmentDoc = gql`
  fragment MatchingProfile_Name on MatchingProfile {
    id
    name
  }
`
export const MatchingProfile_ForSortingFragmentDoc = gql`
  fragment MatchingProfile_ForSorting on MatchingProfile {
    id
    introCallDate
    matchDueDate
    internalMatchingDate
    country
  }
`
export const Matching_ForMatchingListItemFragmentDoc = gql`
  fragment Matching_ForMatchingListItem on Matching {
    ...Matching_ProposalsReply
    ...Matching_ApplicationsId
    ...Matching_BookmarksCount
    ...Matching_IsHighTouch
    ...Matching_IsTransition
    ...Matching_CatalogVisibility
    ...Matching_CompletedAt
    ...Matching_isTeamInvite
    hasToBeMatched
    profiles {
      ...MatchingProfile_Name
      ...MatchingProfile_ForSorting
    }
  }
  ${Matching_ProposalsReplyFragmentDoc}
  ${Matching_ApplicationsIdFragmentDoc}
  ${Matching_BookmarksCountFragmentDoc}
  ${Matching_IsHighTouchFragmentDoc}
  ${Matching_IsTransitionFragmentDoc}
  ${Matching_CatalogVisibilityFragmentDoc}
  ${Matching_CompletedAtFragmentDoc}
  ${Matching_IsTeamInviteFragmentDoc}
  ${MatchingProfile_NameFragmentDoc}
  ${MatchingProfile_ForSortingFragmentDoc}
`
export const Matching_OnboardingCallAtFragmentDoc = gql`
  fragment Matching_OnboardingCallAt on Matching {
    id
    onboarding {
      id
      isCompleted
      callStartAt
    }
  }
`
export const MatchingTransition_InformingFragmentDoc = gql`
  fragment MatchingTransition_Informing on MatchingTransition {
    id
    informingStatus
    informingCategory
    hasDisabledSlackMessages
    csm {
      ...Userable_Minimal
    }
  }
  ${Userable_MinimalFragmentDoc}
`
export const MatchingTransition_MatchingStatusFragmentDoc = gql`
  fragment MatchingTransition_MatchingStatus on MatchingTransition {
    id
    matchingStatus
  }
`
export const MatchingTransition_HandoverStatusFragmentDoc = gql`
  fragment MatchingTransition_HandoverStatus on MatchingTransition {
    id
    handoverStatus
  }
`
export const MatchingTransition_ChangeoverStatusesFragmentDoc = gql`
  fragment MatchingTransition_ChangeoverStatuses on MatchingTransition {
    id
    didSwitchAssistantsAt
    didSwitch1PasswordAt
    didRequestExecutiveFeedbackAt
    didFollowUpWithNewAssistantAt
  }
`
export const Assistantable_ForTransitionFragmentDoc = gql`
  fragment Assistantable_ForTransition on Assistantable {
    ...Userable_Minimal
    slackUserId
    ...HourlyRateCurrency
    dsm {
      ...Userable_Minimal
    }
  }
  ${Userable_MinimalFragmentDoc}
  ${HourlyRateCurrencyFragmentDoc}
`
export const MatchingTransition_ListItemFragmentDoc = gql`
  fragment MatchingTransition_ListItem on MatchingTransition {
    id
    ...MatchingTransition_Informing
    ...MatchingTransition_MatchingStatus
    ...MatchingTransition_HandoverStatus
    ...MatchingTransition_ChangeoverStatuses
    requestedOn
    requestedByUserCategory
    deadlineOn
    highTouchReasons
    airtableTransitionRequestId
    fromAssistant {
      ...Assistantable_ForTransition
    }
    csm {
      ...Userable_Minimal
    }
  }
  ${MatchingTransition_InformingFragmentDoc}
  ${MatchingTransition_MatchingStatusFragmentDoc}
  ${MatchingTransition_HandoverStatusFragmentDoc}
  ${MatchingTransition_ChangeoverStatusesFragmentDoc}
  ${Assistantable_ForTransitionFragmentDoc}
  ${Userable_MinimalFragmentDoc}
`
export const MatchingProposal_AssistantFragmentDoc = gql`
  fragment MatchingProposal_Assistant on MatchingProposal {
    ...MatchingProposal_SentReply
    assistant {
      ...Assistantable_ForTransition
    }
  }
  ${MatchingProposal_SentReplyFragmentDoc}
  ${Assistantable_ForTransitionFragmentDoc}
`
export const Matching_ForTransitionListItemFragmentDoc = gql`
  fragment Matching_ForTransitionListItem on Matching {
    ...Matching_OnboardingCallAt
    ...Matching_IsHighTouch
    completedAt
    executive {
      ...Userable_Minimal
      principalUser {
        ...Userable_Minimal
      }
    }
    workspace {
      id
    }
    transition {
      ...MatchingTransition_ListItem
    }
    sentNotDeclinedProposals: proposals(isSent: true, excludeReplies: [NO]) {
      ...MatchingProposal_Assistant
      ...MatchingProposal_SentReply
    }
    candidates {
      ...MatchingProposal_Assistant
    }
    confirmedAssistant {
      ...Assistantable_ForTransition
    }
  }
  ${Matching_OnboardingCallAtFragmentDoc}
  ${Matching_IsHighTouchFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${MatchingTransition_ListItemFragmentDoc}
  ${MatchingProposal_AssistantFragmentDoc}
  ${MatchingProposal_SentReplyFragmentDoc}
  ${Assistantable_ForTransitionFragmentDoc}
`
export const MatchingProfileFragmentDoc = gql`
  fragment MatchingProfile on MatchingProfile {
    ...MatchingProfile_Name
    ...MatchingProfile_ForSorting
    airtableLeadId
    city
    jobRole
    companyIndustry
    companySize
    linkedInUrl
    hoursNeededRange {
      from
      to
    }
    desiredAvailability
    tools
    tasks
    eaPersonalityTraits
    bioSections {
      title
      text
    }
  }
  ${MatchingProfile_NameFragmentDoc}
  ${MatchingProfile_ForSortingFragmentDoc}
`
export const MatchingProfile_PrincipalFragmentDoc = gql`
  fragment MatchingProfile_Principal on MatchingProfile {
    ...MatchingProfile_Name
    airtableLeadId
  }
  ${MatchingProfile_NameFragmentDoc}
`
export const MatchingTransition_HandoverFragmentDoc = gql`
  fragment MatchingTransition_Handover on MatchingTransition {
    ...MatchingTransition_HandoverStatus
    handoverAt
    shouldDsmAttendHandover
  }
  ${MatchingTransition_HandoverStatusFragmentDoc}
`
export const MatchingTransition_NotepadFragmentDoc = gql`
  fragment MatchingTransition_Notepad on MatchingTransition {
    id
    notepad
  }
`
export const MatchingTransition_SentMessagesFragmentDoc = gql`
  fragment MatchingTransition_SentMessages on MatchingTransition {
    id
    sentMessages {
      id
      at
      messageTemplate {
        id
      }
    }
  }
`
export const MatchingTransition_SlackChannelIdFragmentDoc = gql`
  fragment MatchingTransition_SlackChannelId on MatchingTransition {
    id
    slackChannelId(onlyIfExists: true)
    hasDisabledSlackMessages
  }
`
export const MatchingTransition_FullFragmentDoc = gql`
  fragment MatchingTransition_Full on MatchingTransition {
    ...MatchingTransition_ListItem
    ...MatchingTransition_Handover
    ...MatchingTransition_Notepad
    ...MatchingTransition_SentMessages
    ...MatchingTransition_SlackChannelId
    scheduleCallToken
    reasons
    airtableGenericNotes {
      id
      date
      notepad
    }
  }
  ${MatchingTransition_ListItemFragmentDoc}
  ${MatchingTransition_HandoverFragmentDoc}
  ${MatchingTransition_NotepadFragmentDoc}
  ${MatchingTransition_SentMessagesFragmentDoc}
  ${MatchingTransition_SlackChannelIdFragmentDoc}
`
export const MatchingProposal_ConfirmationFragmentDoc = gql`
  fragment MatchingProposal_Confirmation on MatchingProposal {
    id
    confirmedAt
    confirmedBy {
      ...Userable_Minimal
    }
  }
  ${Userable_MinimalFragmentDoc}
`
export const MatchingProposal_ReasonForMatchFragmentDoc = gql`
  fragment MatchingProposal_ReasonForMatch on MatchingProposal {
    id
    reasonForMatch
    executiveReasonForMatch
    additionalContext
  }
`
export const MatchingApplication_MotivationFragmentDoc = gql`
  fragment MatchingApplication_Motivation on MatchingApplication {
    id
    at
    motivation
  }
`
export const MatchingProposal_FullFragmentDoc = gql`
  fragment MatchingProposal_Full on MatchingProposal {
    ...MatchingProposal_SentReply
    ...MatchingProposal_Assistant
    ...MatchingProposal_Confirmation
    ...MatchingProposal_ReasonForMatch
    ...MatchingProposal_IsInstantInvite
    queuedAt
    orderIndex
    sentAt
    slackChannelId
    repliedAt
    replyComment
    introCallAt
    application {
      ...MatchingApplication_Motivation
    }
  }
  ${MatchingProposal_SentReplyFragmentDoc}
  ${MatchingProposal_AssistantFragmentDoc}
  ${MatchingProposal_ConfirmationFragmentDoc}
  ${MatchingProposal_ReasonForMatchFragmentDoc}
  ${MatchingProposal_IsInstantInviteFragmentDoc}
  ${MatchingApplication_MotivationFragmentDoc}
`
export const Matching_ForTransitionFullFragmentDoc = gql`
  fragment Matching_ForTransitionFull on Matching {
    ...Matching_ForTransitionListItem
    executive {
      id
      airtableClient {
        id
        successSegment
      }
      currentPricing {
        id
        pricing {
          id
          label
        }
      }
      ...Userable_StartDate
    }
    profiles {
      ...MatchingProfile
    }
    principalProfile {
      ...MatchingProfile_Principal
    }
    transition {
      ...MatchingTransition_Full
    }
    candidates {
      ...MatchingProposal_Full
    }
  }
  ${Matching_ForTransitionListItemFragmentDoc}
  ${Userable_StartDateFragmentDoc}
  ${MatchingProfileFragmentDoc}
  ${MatchingProfile_PrincipalFragmentDoc}
  ${MatchingTransition_FullFragmentDoc}
  ${MatchingProposal_FullFragmentDoc}
`
export const Matching_TeamInviteFragmentDoc = gql`
  fragment Matching_TeamInvite on MatchingTeamInvite {
    id
    isAllowedPersonal
    hoursLimit
    companyName
  }
`
export const MatchingApplicationFragmentDoc = gql`
  fragment MatchingApplication on MatchingApplication {
    ...MatchingApplication_Motivation
    assistant {
      ...Userable_Minimal
      ...HourlyRateCurrency
    }
  }
  ${MatchingApplication_MotivationFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${HourlyRateCurrencyFragmentDoc}
`
export const Matching_FullFragmentDoc = gql`
  fragment Matching_Full on Matching {
    ...Matching_ForMatchingListItem
    ...Matching_ForTransitionListItem
    ...Matching_ForTransitionFull
    teamInvite {
      ...Matching_TeamInvite
    }
    profiles {
      ...MatchingProfile
    }
    principalProfile {
      ...MatchingProfile_Principal
    }
    applications {
      ...MatchingApplication
    }
    proposals {
      ...MatchingProposal_Full
    }
  }
  ${Matching_ForMatchingListItemFragmentDoc}
  ${Matching_ForTransitionListItemFragmentDoc}
  ${Matching_ForTransitionFullFragmentDoc}
  ${Matching_TeamInviteFragmentDoc}
  ${MatchingProfileFragmentDoc}
  ${MatchingProfile_PrincipalFragmentDoc}
  ${MatchingApplicationFragmentDoc}
  ${MatchingProposal_FullFragmentDoc}
`
export const LeverOpportunityFragmentDoc = gql`
  fragment LeverOpportunity on LeverOpportunity {
    id
    displayName
    emails
    phoneNumbers
    location
    postingTitle @include(if: $includePostingTitle)
  }
`
export const BasicAirtableLeadFragmentDoc = gql`
  fragment BasicAirtableLead on AirtableLead {
    id
    name
    nextStep
  }
`
export const AirtableLeadFragmentDoc = gql`
  fragment AirtableLead on AirtableLead {
    ...BasicAirtableLead
    userId
    email
    country
    city
    reasonForMatch
    doubles {
      id
      userId
      name
    }
    teamInvites {
      id
      hoursLimit
      isAllowedPersonal
      teamLeads {
        id
        userId
      }
    }
  }
  ${BasicAirtableLeadFragmentDoc}
`
export const AirtableLead_BundlePrincipalFragmentDoc = gql`
  fragment AirtableLead_BundlePrincipal on AirtableLead {
    ...AirtableLead
    fields
    companyName
    teammatesForMatching {
      ...BasicAirtableLead
    }
  }
  ${AirtableLeadFragmentDoc}
  ${BasicAirtableLeadFragmentDoc}
`
export const AirtableTransitionRequestFragmentDoc = gql`
  fragment AirtableTransitionRequest on AirtableTransitionRequest {
    id
    submittedOn
    submittedBy
    completionDeadline
    reasons
    additionalFeedback
    owner {
      id
      user {
        ...Userable_Minimal
      }
    }
    airtableClient {
      id
      transitionsCount
      user {
        __typename
        ...Userable_Minimal
        principalUser {
          id
        }
      }
    }
    airtableDouble {
      id
      user {
        ...Assistantable_ForTransition
      }
    }
  }
  ${Userable_MinimalFragmentDoc}
  ${Assistantable_ForTransitionFragmentDoc}
`
export const DossierFolderHeaderFragmentDoc = gql`
  fragment DossierFolderHeader on DossierFolderHeader {
    title
    description
    isEnabled
  }
`
export const DossierFolderFragmentDoc = gql`
  fragment DossierFolder on DossierFolder {
    id
    orderIndex
    label
    isPrivate
    header {
      ...DossierFolderHeader
    }
  }
  ${DossierFolderHeaderFragmentDoc}
`
export const DossierSectionFragmentDoc = gql`
  fragment DossierSection on DossierSection {
    id
    orderIndex
    label
    emoji
    isPrivate
    defaultInputType
  }
`
export const DossierFieldFragmentDoc = gql`
  fragment DossierField on DossierField {
    id
    orderIndex
    label
    input {
      maxValues
      ... on DossierInputableText {
        isAllowingFreeForm
        freeFormPlaceholder
        options {
          text
          isRecommended
        }
      }
      ... on DossierInputablePlace {
        hint
      }
    }
  }
`
export const OrientationFragmentDoc = gql`
  fragment Orientation on Orientation {
    id
    isCompleted
    hasEnabledGoogle2Fa
    hasJoinedSlack
    hasFilledProfile
    hasSeenIntro
    hasCompletedSetup
    hasAppAccess
    sentPreDay1Email
    sentPostDay1Email
    sentPostDay2Email
    sentPostDay3Email
  }
`
export const Userable_InOrientationFragmentDoc = gql`
  fragment Userable_InOrientation on Userable {
    id
    ...Userable_Profile
    ...Userable_Minimal
    ... on Assistantable {
      orientation {
        ...Orientation
      }
      dsm {
        ...Userable_Minimal
      }
    }
  }
  ${Userable_ProfileFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${OrientationFragmentDoc}
`
export const AutomationAction_NoConfigFragmentDoc = gql`
  fragment AutomationAction_NoConfig on AutomationAction {
    id
    name
    description
  }
`
export const AutomationJobLogFragmentDoc = gql`
  fragment AutomationJobLog on AutomationJobLog {
    at
    level
    message
    meta
    action {
      ...AutomationAction_NoConfig
    }
  }
  ${AutomationAction_NoConfigFragmentDoc}
`
export const AutomationParameterFragmentDoc = gql`
  fragment AutomationParameter on AutomationParameter {
    name
    type
    isRequired
  }
`
export const AutomationTriggerFragmentDoc = gql`
  fragment AutomationTrigger on AutomationTrigger {
    id
    description
    parameters {
      ...AutomationParameter
    }
  }
  ${AutomationParameterFragmentDoc}
`
export const DelayFragmentDoc = gql`
  fragment Delay on Delay {
    value
    unit
    hour
    minute
    weekday
    day
    timeZone
  }
`
export const AutomationActionFragmentDoc = gql`
  fragment AutomationAction on AutomationAction {
    ...AutomationAction_NoConfig
    config
  }
  ${AutomationAction_NoConfigFragmentDoc}
`
export const AutomationActionGroupFragmentDoc = gql`
  fragment AutomationActionGroup on AutomationActionGroup {
    actions {
      ...AutomationAction
    }
  }
  ${AutomationActionFragmentDoc}
`
export const AutomationFragmentDoc = gql`
  fragment Automation on Automation {
    ...Automation_Name
    description
    isEnabled
    triggerOn {
      ...AutomationTrigger
    }
    cancelOn {
      ...AutomationTrigger
    }
    delay {
      ...Delay
    }
    actionGroups {
      ...AutomationActionGroup
    }
  }
  ${Automation_NameFragmentDoc}
  ${AutomationTriggerFragmentDoc}
  ${DelayFragmentDoc}
  ${AutomationActionGroupFragmentDoc}
`
export const AutomationJob_FullFragmentDoc = gql`
  fragment AutomationJob_Full on AutomationJob {
    ...AutomationJob_Item
    params
    results
    reviewedBy {
      ...Userable_Profile
    }
    logs {
      ...AutomationJobLog
    }
    automation {
      ...Automation
    }
  }
  ${AutomationJob_ItemFragmentDoc}
  ${Userable_ProfileFragmentDoc}
  ${AutomationJobLogFragmentDoc}
  ${AutomationFragmentDoc}
`
export const MessageTemplate_LabelsFragmentDoc = gql`
  fragment MessageTemplate_Labels on MessageTemplate {
    id
    name
    description
  }
`
export const MessageTemplateLanguageFragmentDoc = gql`
  fragment MessageTemplateLanguage on MessageTemplateLanguage {
    id
    languageCode
    fields
    placeholders
  }
`
export const MessageTemplateFragmentDoc = gql`
  fragment MessageTemplate on MessageTemplate {
    ...MessageTemplate_Labels
    languages {
      ...MessageTemplateLanguage
    }
  }
  ${MessageTemplate_LabelsFragmentDoc}
  ${MessageTemplateLanguageFragmentDoc}
`
export const GuardedOperationFragmentDoc = gql`
  fragment GuardedOperation on GuardedOperation {
    id
    typeName
    fieldName
    description
  }
`
export const AdminRoleFragmentDoc = gql`
  fragment AdminRole on AdminRole {
    id
    name
    description
    inheritedRoles {
      id
    }
    operations {
      ...GuardedOperation
    }
  }
  ${GuardedOperationFragmentDoc}
`
export const ScheduleOnboardingConfig_WritableFragmentDoc = gql`
  fragment ScheduleOnboardingConfig_Writable on ScheduleOnboardingConfig {
    id
    workingStartHour
    workingEndHour
    coveredTimeZones
    specialistEventCalendarId
    externalEventCalendarId
    availabilityCalendarIds
  }
`
export const Adminable_ConfigFragmentDoc = gql`
  fragment Adminable_Config on Adminable {
    id
    adminRoles {
      ...AdminRole
    }
    ... on Userable {
      languages
      city {
        ...City
      }
    }
    thirtyDayCheckInCallUrl
    scheduleOnboardingConfig {
      id
      ...ScheduleOnboardingConfig_Writable
      calendars {
        id
        title
        isWritable
      }
    }
  }
  ${AdminRoleFragmentDoc}
  ${CityFragmentDoc}
  ${ScheduleOnboardingConfig_WritableFragmentDoc}
`
export const ScoreSeriesBasicInfoFragmentDoc = gql`
  fragment ScoreSeriesBasicInfo on AssistantPerformanceScore {
    id
    date
    value
  }
`
export const AssistantUser_PerformanceScoreMetricsPartialFragmentDoc = gql`
  fragment AssistantUser_PerformanceScoreMetricsPartial on AssistantPerformanceMetric {
    id
    metricKind
    value
  }
`
export const AssistantUser_PerformanceScoreMetricsFragmentDoc = gql`
  fragment AssistantUser_PerformanceScoreMetrics on AssistantPerformanceMetric {
    ...AssistantUser_PerformanceScoreMetricsPartial
    eventsReviewURL
    weight
  }
  ${AssistantUser_PerformanceScoreMetricsPartialFragmentDoc}
`
export const PerformanceScoreMonthSeriesFragmentDoc = gql`
  fragment PerformanceScoreMonthSeries on AssistantPerformanceScore {
    ...ScoreSeriesBasicInfo
    metrics {
      ...AssistantUser_PerformanceScoreMetrics
    }
  }
  ${ScoreSeriesBasicInfoFragmentDoc}
  ${AssistantUser_PerformanceScoreMetricsFragmentDoc}
`
export const PerformanceScoreHistoricalSeriesFragmentDoc = gql`
  fragment PerformanceScoreHistoricalSeries on AssistantPerformanceScore {
    ...ScoreSeriesBasicInfo
    metrics {
      ...AssistantUser_PerformanceScoreMetricsPartial
    }
  }
  ${ScoreSeriesBasicInfoFragmentDoc}
  ${AssistantUser_PerformanceScoreMetricsPartialFragmentDoc}
`
export const AssistantUser_PerformanceScoreTagsFragmentDoc = gql`
  fragment AssistantUser_PerformanceScoreTags on AssistantPerformanceTag {
    id
    sentiment
    text
  }
`
export const AssistantUser_PerformanceScoreMetricEventsFragmentDoc = gql`
  fragment AssistantUser_PerformanceScoreMetricEvents on AssistantPerformanceMetricEvent {
    id
    date
    executive {
      ...Userable_Minimal
    }
    tags {
      ...AssistantUser_PerformanceScoreTags
    }
  }
  ${Userable_MinimalFragmentDoc}
  ${AssistantUser_PerformanceScoreTagsFragmentDoc}
`
export const PerformanceScoreEventsSeriesFragmentDoc = gql`
  fragment PerformanceScoreEventsSeries on AssistantPerformanceScore {
    id
    metrics {
      id
      metricKind
      events {
        ...AssistantUser_PerformanceScoreMetricEvents
      }
    }
  }
  ${AssistantUser_PerformanceScoreMetricEventsFragmentDoc}
`
export const MetricEventPropertyFragmentDoc = gql`
  fragment MetricEventProperty on MetricEventProperty {
    id
    name
    description
  }
`
export const MetricEventFragmentDoc = gql`
  fragment MetricEvent on MetricEvent {
    id
    trackedEventName
    name
    description
    apps
    sql
    properties {
      ...MetricEventProperty
    }
  }
  ${MetricEventPropertyFragmentDoc}
`
export const TimeTrackingTagFragmentDoc = gql`
  fragment TimeTrackingTag on TimeTrackingTag {
    id
    label
  }
`
export const TimeTrackingEntry_TaskInfoFragmentDoc = gql`
  fragment TimeTrackingEntry_TaskInfo on TimeTrackingEntry {
    id
    title
    tags {
      ...TimeTrackingTag
    }
  }
  ${TimeTrackingTagFragmentDoc}
`
export const TimeTrackingEntry_DateInfoFragmentDoc = gql`
  fragment TimeTrackingEntry_DateInfo on TimeTrackingEntry {
    id
    startedAt
    endedAt
  }
`
export const TimeTrackingEntry_TimeInfoFragmentDoc = gql`
  fragment TimeTrackingEntry_TimeInfo on TimeTrackingEntry {
    id
    ...TimeTrackingEntry_DateInfo
    duration
  }
  ${TimeTrackingEntry_DateInfoFragmentDoc}
`
export const TimeTrackingEntry_TogglInfoFragmentDoc = gql`
  fragment TimeTrackingEntry_TogglInfo on TimeTrackingEntry {
    id
    isToggl
  }
`
export const TimeTrackingEntry_StatusInfoFragmentDoc = gql`
  fragment TimeTrackingEntry_StatusInfo on TimeTrackingEntry {
    id
    ...TimeTrackingEntry_TogglInfo
    lockedAt
  }
  ${TimeTrackingEntry_TogglInfoFragmentDoc}
`
export const TimeTrackingEntryFragmentDoc = gql`
  fragment TimeTrackingEntry on TimeTrackingEntry {
    id
    ...TimeTrackingEntry_TaskInfo
    ...TimeTrackingEntry_TimeInfo
    ...TimeTrackingEntry_StatusInfo
    user {
      ...Userable_Minimal
    }
    workspace {
      id
      executives {
        ...Userable_Minimal
      }
    }
    topicId
  }
  ${TimeTrackingEntry_TaskInfoFragmentDoc}
  ${TimeTrackingEntry_TimeInfoFragmentDoc}
  ${TimeTrackingEntry_StatusInfoFragmentDoc}
  ${Userable_MinimalFragmentDoc}
`
export const TimeTrackingEntryTemplateFragmentDoc = gql`
  fragment TimeTrackingEntryTemplate on TimeTrackingEntryTemplate {
    id
    title
  }
`
export const Assistantable_TimeTrackingEntryBulkFragmentDoc = gql`
  fragment Assistantable_TimeTrackingEntryBulk on Assistantable {
    id
    hourlyRate
    hourlyRateUpdates {
      hourlyRate
      updatedAt
    }
    currency
  }
`
export const Userable_TimeTrackingEntryBulkFragmentDoc = gql`
  fragment Userable_TimeTrackingEntryBulk on Userable {
    id
    profile {
      id
      displayName
    }
    ...Assistantable_TimeTrackingEntryBulk
  }
  ${Assistantable_TimeTrackingEntryBulkFragmentDoc}
`
export const TimeTrackingEntry_BulkFragmentDoc = gql`
  fragment TimeTrackingEntry_Bulk on TimeTrackingEntry {
    id
    title
    isToggl
    ...TimeTrackingEntry_TimeInfo
    tags {
      ...TimeTrackingTag
    }
    user {
      ...Userable_TimeTrackingEntryBulk
    }
    workspace {
      id
      executives {
        ...Userable_TimeTrackingEntryBulk
      }
    }
  }
  ${TimeTrackingEntry_TimeInfoFragmentDoc}
  ${TimeTrackingTagFragmentDoc}
  ${Userable_TimeTrackingEntryBulkFragmentDoc}
`
export const BillingPauseFragmentDoc = gql`
  fragment BillingPause on Team {
    id
    billingPauseStatus {
      id
      startAt
      endAt
    }
  }
`
export const Team_PendingChurnAutomationJobFragmentDoc = gql`
  fragment Team_PendingChurnAutomationJob on Team {
    id
    pendingChurnAutomationJob {
      ...AutomationJob_Item
    }
  }
  ${AutomationJob_ItemFragmentDoc}
`
export const TeamFragmentDoc = gql`
  fragment Team on Team {
    id
    ...BasicTeam
    createdAt
    updatedAt
    isSandbox
    status
    principalUser {
      id
      airtableLeadRecordId
    }
    successManager {
      ...Userable_Profile
    }
    workspaces {
      ...BasicWorkspace
    }
    ...BillingPause
    ...Team_PendingChurnAutomationJob
    subscription {
      currentBillingCycle {
        endDate
      }
    }
  }
  ${BasicTeamFragmentDoc}
  ${Userable_ProfileFragmentDoc}
  ${BasicWorkspaceFragmentDoc}
  ${BillingPauseFragmentDoc}
  ${Team_PendingChurnAutomationJobFragmentDoc}
`
export const TeamBillingFragmentDoc = gql`
  fragment TeamBilling on Team {
    ...Team_StripeCustomer
    ...Team_Subscription
    ...Team_PricingSelections
    ...BillingPause
  }
  ${Team_StripeCustomerFragmentDoc}
  ${Team_SubscriptionFragmentDoc}
  ${Team_PricingSelectionsFragmentDoc}
  ${BillingPauseFragmentDoc}
`
export const CopilotFunctionFragmentDoc = gql`
  fragment CopilotFunction on CopilotFunction {
    name
    description
    parameters
  }
`
export const CopilotFragmentDoc = gql`
  fragment Copilot on Copilot {
    id
    messagesPrompt
    functionsEnabled
    functionsPrompt
    functions {
      ...CopilotFunction
    }
    messageCap
    updatedAt
  }
  ${CopilotFunctionFragmentDoc}
`
export const FirstCalledFunctionInfoFragmentDoc = gql`
  fragment FirstCalledFunctionInfo on FirstCalledFunctionInfo {
    functionName
    inerhitIfNull
  }
`
export const CopilotPromptSettingsFragmentDoc = gql`
  fragment CopilotPromptSettings on CopilotPromptSettings {
    systemPrompt
    functions {
      ...CopilotFunction
    }
    firstCalledFunctionInfo {
      ...FirstCalledFunctionInfo
    }
  }
  ${CopilotFunctionFragmentDoc}
  ${FirstCalledFunctionInfoFragmentDoc}
`
export const CopilotPromptSettingsOverrideFragmentDoc = gql`
  fragment CopilotPromptSettingsOverride on CopilotPromptSettingsOverride {
    id
    users {
      id
      ...Userable_Profile
    }
    ...CopilotPromptSettings
  }
  ${Userable_ProfileFragmentDoc}
  ${CopilotPromptSettingsFragmentDoc}
`
export const CopilotPromptSettingsOverridableFragmentDoc = gql`
  fragment CopilotPromptSettingsOverridable on CopilotPromptSettingsOverridable {
    overridePrompts {
      ...CopilotPromptSettingsOverride
    }
  }
  ${CopilotPromptSettingsOverrideFragmentDoc}
`
export const MainCopilotPromptSettingsFragmentDoc = gql`
  fragment MainCopilotPromptSettings on MainCopilotPromptSettings {
    ...CopilotPromptSettings
    ...CopilotPromptSettingsOverridable
  }
  ${CopilotPromptSettingsFragmentDoc}
  ${CopilotPromptSettingsOverridableFragmentDoc}
`
export const CopilotSubpromptableFragmentDoc = gql`
  fragment CopilotSubpromptable on CopilotSubpromptable {
    label
  }
`
export const TaskCopilotPromptSettingsFragmentDoc = gql`
  fragment TaskCopilotPromptSettings on TaskCopilotPromptSettings {
    id
    ...CopilotSubpromptable
    ...CopilotPromptSettingsOverridable
    ...CopilotPromptSettings
  }
  ${CopilotSubpromptableFragmentDoc}
  ${CopilotPromptSettingsOverridableFragmentDoc}
  ${CopilotPromptSettingsFragmentDoc}
`
export const CategoryCopilotPromptSettingsFragmentDoc = gql`
  fragment CategoryCopilotPromptSettings on CategoryCopilotPromptSettings {
    id
    ...CopilotSubpromptable
    ...CopilotPromptSettingsOverridable
    ...CopilotPromptSettings
  }
  ${CopilotSubpromptableFragmentDoc}
  ${CopilotPromptSettingsOverridableFragmentDoc}
  ${CopilotPromptSettingsFragmentDoc}
`
export const AssistantCopilotFragmentDoc = gql`
  fragment AssistantCopilot on AssistantCopilot {
    id
    everydayTasksCopilotPromptSettings {
      ...MainCopilotPromptSettings
    }
    execTasksCopilotPromptSettings {
      ...MainCopilotPromptSettings
    }
    taskPrompts {
      ...TaskCopilotPromptSettings
    }
    categoryPrompts {
      ...CategoryCopilotPromptSettings
    }
  }
  ${MainCopilotPromptSettingsFragmentDoc}
  ${TaskCopilotPromptSettingsFragmentDoc}
  ${CategoryCopilotPromptSettingsFragmentDoc}
`
export const DelegationCoachFragmentDoc = gql`
  fragment DelegationCoach on DelegationCoach {
    id
    systemPrompt
    taskSuggestionsFunction
  }
`
export const AdhocTodoSettingsFragmentDoc = gql`
  fragment AdhocTodoSettings on AdhocTodoSettings {
    id
    systemPrompt
    titlePrompt
    taskAcceptabilityPrompt
    isOpenForSubmissions
    updatedAt
  }
`
export const FeedbackFragmentDoc = gql`
  fragment Feedback on AdhocTodoFeedback {
    rating
    ratingComment
  }
`
export const AdhocExecutiveFragmentDoc = gql`
  fragment AdhocExecutive on AdhocExecutive {
    givenName
    familyName
    email
  }
`
export const EmailThreadItemFragmentDoc = gql`
  fragment EmailThreadItem on EmailThreadItem {
    from {
      email
      name
    }
    attachments {
      filename
      type
    }
    forwardedAt
    text
    to {
      email
      name
    }
  }
`
export const ChatThreadItemFragmentDoc = gql`
  fragment ChatThreadItem on ChatThreadItem {
    author {
      ...Userable_Minimal
    }
    content
    createdAt
  }
  ${Userable_MinimalFragmentDoc}
`
export const CommunicationThreadsFragmentDoc = gql`
  fragment CommunicationThreads on CommunicationThreads {
    emailThread {
      ...EmailThreadItem
    }
    chatThread {
      ...ChatThreadItem
    }
  }
  ${EmailThreadItemFragmentDoc}
  ${ChatThreadItemFragmentDoc}
`
export const CheckoutSessionLineItemFragmentDoc = gql`
  fragment CheckoutSessionLineItem on CheckoutSessionLineItem {
    quantity
    price {
      unitAmount
      currency
    }
  }
`
export const AdhocTodoFragmentDoc = gql`
  fragment AdhocTodo on AdhocTodo {
    id
    title
    createdAt
    updatedAt
    status
    feedback {
      ...Feedback
    }
    executive {
      ...AdhocExecutive
    }
    executiveUser {
      ...Userable_Minimal
    }
    dispatchedTo {
      ...Userable_Minimal
    }
    communicationThreads {
      ...CommunicationThreads
    }
    completedAt
    completionReport {
      notes
    }
    checkoutSession {
      lineItems {
        ...CheckoutSessionLineItem
      }
    }
    isPool
    isHiddenFromPool
  }
  ${FeedbackFragmentDoc}
  ${AdhocExecutiveFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${CommunicationThreadsFragmentDoc}
  ${CheckoutSessionLineItemFragmentDoc}
`
export const WorkspaceWithTeamFragmentDoc = gql`
  fragment WorkspaceWithTeam on Workspace {
    ...BasicWorkspace
    team {
      ...BasicTeamWithPrincipal
    }
  }
  ${BasicWorkspaceFragmentDoc}
  ${BasicTeamWithPrincipalFragmentDoc}
`
export const CsatFeedbackFragmentDoc = gql`
  fragment CsatFeedback on CsatFeedback {
    id
    rating
    tags
    commentText
    createdAt
    assistant {
      id
      ... on Userable {
        profile {
          ...UserProfile_NamesPhotoEmails
        }
      }
    }
    workspace {
      id
    }
  }
  ${UserProfile_NamesPhotoEmailsFragmentDoc}
`
export const TimelineItemFragmentDoc = gql`
  fragment TimelineItem on TimelineItem {
    activityAt
    category
    event
    emoji
    title
    ... on WorkspaceTimelineItem {
      workspace {
        ...WorkspaceWithTeam
      }
    }
    ... on WorkspaceUserTimelineItem {
      user {
        ...Userable_Minimal
      }
      workspace {
        ...WorkspaceWithTeam
      }
      startDate
      endDate
      isCoverage
    }
    ... on MatchingTimelineItem {
      matching {
        ...Matching_ForMatchingListItem
      }
    }
    ... on FeedbackTimelineItem {
      feedback {
        ...CsatFeedback
      }
      counterpartUser {
        ...Userable_Minimal
      }
    }
  }
  ${WorkspaceWithTeamFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${Matching_ForMatchingListItemFragmentDoc}
  ${CsatFeedbackFragmentDoc}
`
export const TestExecLeadFragmentDoc = gql`
  fragment TestExecLead on AirtableLead {
    id
    name
    fields
  }
`
export const TestExecMatchingFragmentDoc = gql`
  fragment TestExecMatching on Matching {
    id
    isHighTouch
    proposals {
      ...MatchingProposal_Full
    }
    confirmedAssistant {
      id
    }
  }
  ${MatchingProposal_FullFragmentDoc}
`
export const TestExecUserFragmentDoc = gql`
  fragment TestExecUser on ExecutiveUser {
    ...Userable_Minimal
  }
  ${Userable_MinimalFragmentDoc}
`
export const TestExecFragmentDoc = gql`
  fragment TestExec on TestExec {
    lead {
      ...TestExecLead
    }
    matching {
      ...TestExecMatching
    }
    user {
      ...TestExecUser
    }
  }
  ${TestExecLeadFragmentDoc}
  ${TestExecMatchingFragmentDoc}
  ${TestExecUserFragmentDoc}
`
export const SystemFeatureFlagFragmentDoc = gql`
  fragment SystemFeatureFlag on SystemFeatureFlag {
    id
    description
    isEnabled
  }
`
export const GetUserFieldsOptionsDocument = gql`
  query GetUserFieldsOptions {
    userFieldsOptions {
      industries
      assistantSkills
      personalityTraits
      tools
    }
  }
`

/**
 * __useGetUserFieldsOptionsQuery__
 *
 * To run a query within a React component, call `useGetUserFieldsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFieldsOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserFieldsOptionsQuery,
    GetUserFieldsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserFieldsOptionsQuery,
    GetUserFieldsOptionsQueryVariables
  >(GetUserFieldsOptionsDocument, options)
}
export function useGetUserFieldsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFieldsOptionsQuery,
    GetUserFieldsOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserFieldsOptionsQuery,
    GetUserFieldsOptionsQueryVariables
  >(GetUserFieldsOptionsDocument, options)
}
export type GetUserFieldsOptionsQueryHookResult = ReturnType<
  typeof useGetUserFieldsOptionsQuery
>
export type GetUserFieldsOptionsLazyQueryHookResult = ReturnType<
  typeof useGetUserFieldsOptionsLazyQuery
>
export type GetUserFieldsOptionsQueryResult = Apollo.QueryResult<
  GetUserFieldsOptionsQuery,
  GetUserFieldsOptionsQueryVariables
>
export const ListUsersDocument = gql`
  query ListUsers(
    $after: String
    $first: PositiveInt
    $q: String
    $isSaas: Boolean
    $isArchived: Boolean
    $categories: [UserCategory!]
    $adminRoles: [String!]
    $hasSuccessManager: Boolean
    $successManagerId: ID
    $invoicedToUserId: ID
    $industries: [String!]
    $tools: [String!]
    $userIds: [ID!]
  ) {
    list: usersList(
      after: $after
      first: $first
      q: $q
      categories: $categories
      adminRoles: $adminRoles
      successManagerId: $successManagerId
      invoicedToUserId: $invoicedToUserId
      hasSuccessManager: $hasSuccessManager
      isSaas: $isSaas
      isArchived: $isArchived
      industries: $industries
      tools: $tools
      userIds: $userIds
    ) {
      after
      items {
        ...BasicUser
      }
    }
  }
  ${BasicUserFragmentDoc}
`

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      q: // value for 'q'
 *      isSaas: // value for 'isSaas'
 *      isArchived: // value for 'isArchived'
 *      categories: // value for 'categories'
 *      adminRoles: // value for 'adminRoles'
 *      hasSuccessManager: // value for 'hasSuccessManager'
 *      successManagerId: // value for 'successManagerId'
 *      invoicedToUserId: // value for 'invoicedToUserId'
 *      industries: // value for 'industries'
 *      tools: // value for 'tools'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUsersQuery,
    ListUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    options,
  )
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUsersQuery,
    ListUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    options,
  )
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>
export type ListUsersLazyQueryHookResult = ReturnType<
  typeof useListUsersLazyQuery
>
export type ListUsersQueryResult = Apollo.QueryResult<
  ListUsersQuery,
  ListUsersQueryVariables
>
export const GetUserDocument = gql`
  query GetUser($id: ID!) {
    user: userById(id: $id) {
      ...FullUser
      ...Userable_PendingChurnAutomationJob
    }
  }
  ${FullUserFragmentDoc}
  ${Userable_PendingChurnAutomationJobFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const GetWorkspaceUserDocument = gql`
  query GetWorkspaceUser($id: ID!) {
    user: userById(id: $id) {
      ...BasicUserWithAccounts
    }
  }
  ${BasicUserWithAccountsFragmentDoc}
`

/**
 * __useGetWorkspaceUserQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceUserQuery,
    GetWorkspaceUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkspaceUserQuery, GetWorkspaceUserQueryVariables>(
    GetWorkspaceUserDocument,
    options,
  )
}
export function useGetWorkspaceUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceUserQuery,
    GetWorkspaceUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkspaceUserQuery,
    GetWorkspaceUserQueryVariables
  >(GetWorkspaceUserDocument, options)
}
export type GetWorkspaceUserQueryHookResult = ReturnType<
  typeof useGetWorkspaceUserQuery
>
export type GetWorkspaceUserLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceUserLazyQuery
>
export type GetWorkspaceUserQueryResult = Apollo.QueryResult<
  GetWorkspaceUserQuery,
  GetWorkspaceUserQueryVariables
>
export const GetUserNavigationDocument = gql`
  query GetUserNavigation($id: ID!) {
    user: userById(id: $id) {
      ...BasicUser
      ... on ExecutiveUser {
        id
        workspaces(includeArchived: true) {
          id
          ...WorkspaceWithTeam
        }
      }
      ... on AssistantUser {
        workspaces {
          id
          ...WorkspaceWithTeam
        }
      }
      ... on HqUser {
        workspaces {
          id
          ...WorkspaceWithTeam
        }
      }
      ... on SandboxUser {
        workspaces {
          id
          ...WorkspaceWithTeam
        }
      }
      ... on Executivable {
        principalUser {
          ...Userable_Minimal
        }
        csm {
          ...ExecutivableCsm
        }
      }
    }
  }
  ${BasicUserFragmentDoc}
  ${WorkspaceWithTeamFragmentDoc}
  ${Userable_MinimalFragmentDoc}
  ${ExecutivableCsmFragmentDoc}
`

/**
 * __useGetUserNavigationQuery__
 *
 * To run a query within a React component, call `useGetUserNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNavigationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserNavigationQuery,
    GetUserNavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserNavigationQuery,
    GetUserNavigationQueryVariables
  >(GetUserNavigationDocument, options)
}
export function useGetUserNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNavigationQuery,
    GetUserNavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserNavigationQuery,
    GetUserNavigationQueryVariables
  >(GetUserNavigationDocument, options)
}
export type GetUserNavigationQueryHookResult = ReturnType<
  typeof useGetUserNavigationQuery
>
export type GetUserNavigationLazyQueryHookResult = ReturnType<
  typeof useGetUserNavigationLazyQuery
>
export type GetUserNavigationQueryResult = Apollo.QueryResult<
  GetUserNavigationQuery,
  GetUserNavigationQueryVariables
>
export const GetUserProfileDocument = gql`
  query GetUserProfile($id: ID!) {
    user: userById(id: $id) {
      ...BasicUser
    }
  }
  ${BasicUserFragmentDoc}
`

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  )
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  )
}
export type GetUserProfileQueryHookResult = ReturnType<
  typeof useGetUserProfileQuery
>
export type GetUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileLazyQuery
>
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>
export const GetUserBillingInfoDocument = gql`
  query GetUserBillingInfo($userId: ID!) {
    user: userById(id: $userId) {
      ... on Executivable {
        ...ExecutivableBilling
      }
    }
    dependentUsersList: usersList(invoicedToUserId: $userId) {
      items {
        ...BasicUser
      }
    }
  }
  ${ExecutivableBillingFragmentDoc}
  ${BasicUserFragmentDoc}
`

/**
 * __useGetUserBillingInfoQuery__
 *
 * To run a query within a React component, call `useGetUserBillingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBillingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBillingInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserBillingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserBillingInfoQuery,
    GetUserBillingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserBillingInfoQuery,
    GetUserBillingInfoQueryVariables
  >(GetUserBillingInfoDocument, options)
}
export function useGetUserBillingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBillingInfoQuery,
    GetUserBillingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserBillingInfoQuery,
    GetUserBillingInfoQueryVariables
  >(GetUserBillingInfoDocument, options)
}
export type GetUserBillingInfoQueryHookResult = ReturnType<
  typeof useGetUserBillingInfoQuery
>
export type GetUserBillingInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserBillingInfoLazyQuery
>
export type GetUserBillingInfoQueryResult = Apollo.QueryResult<
  GetUserBillingInfoQuery,
  GetUserBillingInfoQueryVariables
>
export const GetAssistantOnboardingAvailabilityDocument = gql`
  query GetAssistantOnboardingAvailability($assistantId: ID!) {
    assistant: userById(id: $assistantId) {
      ... on Assistantable {
        onboardingAvailability {
          ...OnboardingAvailability
        }
      }
    }
  }
  ${OnboardingAvailabilityFragmentDoc}
`

/**
 * __useGetAssistantOnboardingAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetAssistantOnboardingAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssistantOnboardingAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssistantOnboardingAvailabilityQuery({
 *   variables: {
 *      assistantId: // value for 'assistantId'
 *   },
 * });
 */
export function useGetAssistantOnboardingAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssistantOnboardingAvailabilityQuery,
    GetAssistantOnboardingAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAssistantOnboardingAvailabilityQuery,
    GetAssistantOnboardingAvailabilityQueryVariables
  >(GetAssistantOnboardingAvailabilityDocument, options)
}
export function useGetAssistantOnboardingAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssistantOnboardingAvailabilityQuery,
    GetAssistantOnboardingAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAssistantOnboardingAvailabilityQuery,
    GetAssistantOnboardingAvailabilityQueryVariables
  >(GetAssistantOnboardingAvailabilityDocument, options)
}
export type GetAssistantOnboardingAvailabilityQueryHookResult = ReturnType<
  typeof useGetAssistantOnboardingAvailabilityQuery
>
export type GetAssistantOnboardingAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetAssistantOnboardingAvailabilityLazyQuery
>
export type GetAssistantOnboardingAvailabilityQueryResult = Apollo.QueryResult<
  GetAssistantOnboardingAvailabilityQuery,
  GetAssistantOnboardingAvailabilityQueryVariables
>
export const GetAssistantDocument = gql`
  query GetAssistant($userId: ID!) {
    assistant: userById(id: $userId) {
      ... on Assistantable {
        ...Assistantable_AssistantData
      }
    }
  }
  ${Assistantable_AssistantDataFragmentDoc}
`

/**
 * __useGetAssistantQuery__
 *
 * To run a query within a React component, call `useGetAssistantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssistantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssistantQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssistantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssistantQuery,
    GetAssistantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAssistantQuery, GetAssistantQueryVariables>(
    GetAssistantDocument,
    options,
  )
}
export function useGetAssistantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssistantQuery,
    GetAssistantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAssistantQuery, GetAssistantQueryVariables>(
    GetAssistantDocument,
    options,
  )
}
export type GetAssistantQueryHookResult = ReturnType<
  typeof useGetAssistantQuery
>
export type GetAssistantLazyQueryHookResult = ReturnType<
  typeof useGetAssistantLazyQuery
>
export type GetAssistantQueryResult = Apollo.QueryResult<
  GetAssistantQuery,
  GetAssistantQueryVariables
>
export const GetAssistantHourlyRateUpdatesDocument = gql`
  query GetAssistantHourlyRateUpdates($userId: ID!) {
    assistant: userById(id: $userId) {
      ... on Assistantable {
        ...Assistantable_HourlyRateUpdates
      }
    }
  }
  ${Assistantable_HourlyRateUpdatesFragmentDoc}
`

/**
 * __useGetAssistantHourlyRateUpdatesQuery__
 *
 * To run a query within a React component, call `useGetAssistantHourlyRateUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssistantHourlyRateUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssistantHourlyRateUpdatesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssistantHourlyRateUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssistantHourlyRateUpdatesQuery,
    GetAssistantHourlyRateUpdatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAssistantHourlyRateUpdatesQuery,
    GetAssistantHourlyRateUpdatesQueryVariables
  >(GetAssistantHourlyRateUpdatesDocument, options)
}
export function useGetAssistantHourlyRateUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssistantHourlyRateUpdatesQuery,
    GetAssistantHourlyRateUpdatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAssistantHourlyRateUpdatesQuery,
    GetAssistantHourlyRateUpdatesQueryVariables
  >(GetAssistantHourlyRateUpdatesDocument, options)
}
export type GetAssistantHourlyRateUpdatesQueryHookResult = ReturnType<
  typeof useGetAssistantHourlyRateUpdatesQuery
>
export type GetAssistantHourlyRateUpdatesLazyQueryHookResult = ReturnType<
  typeof useGetAssistantHourlyRateUpdatesLazyQuery
>
export type GetAssistantHourlyRateUpdatesQueryResult = Apollo.QueryResult<
  GetAssistantHourlyRateUpdatesQuery,
  GetAssistantHourlyRateUpdatesQueryVariables
>
export const ClearAssistantHourlyRateUpdatesDocument = gql`
  mutation ClearAssistantHourlyRateUpdates {
    clearAssistantHourlyRateUpdates {
      isSuccess
    }
  }
`
export type ClearAssistantHourlyRateUpdatesMutationFn = Apollo.MutationFunction<
  ClearAssistantHourlyRateUpdatesMutation,
  ClearAssistantHourlyRateUpdatesMutationVariables
>

/**
 * __useClearAssistantHourlyRateUpdatesMutation__
 *
 * To run a mutation, you first call `useClearAssistantHourlyRateUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAssistantHourlyRateUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAssistantHourlyRateUpdatesMutation, { data, loading, error }] = useClearAssistantHourlyRateUpdatesMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearAssistantHourlyRateUpdatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearAssistantHourlyRateUpdatesMutation,
    ClearAssistantHourlyRateUpdatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearAssistantHourlyRateUpdatesMutation,
    ClearAssistantHourlyRateUpdatesMutationVariables
  >(ClearAssistantHourlyRateUpdatesDocument, options)
}
export type ClearAssistantHourlyRateUpdatesMutationHookResult = ReturnType<
  typeof useClearAssistantHourlyRateUpdatesMutation
>
export type ClearAssistantHourlyRateUpdatesMutationResult =
  Apollo.MutationResult<ClearAssistantHourlyRateUpdatesMutation>
export type ClearAssistantHourlyRateUpdatesMutationOptions =
  Apollo.BaseMutationOptions<
    ClearAssistantHourlyRateUpdatesMutation,
    ClearAssistantHourlyRateUpdatesMutationVariables
  >
export const GetWhoDocument = gql`
  query GetWho($userId: GUID!) {
    who: whoById(id: $userId) {
      ...BasicWho
    }
  }
  ${BasicWhoFragmentDoc}
`

/**
 * __useGetWhoQuery__
 *
 * To run a query within a React component, call `useGetWhoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetWhoQuery(
  baseOptions: Apollo.QueryHookOptions<GetWhoQuery, GetWhoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWhoQuery, GetWhoQueryVariables>(
    GetWhoDocument,
    options,
  )
}
export function useGetWhoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWhoQuery, GetWhoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWhoQuery, GetWhoQueryVariables>(
    GetWhoDocument,
    options,
  )
}
export type GetWhoQueryHookResult = ReturnType<typeof useGetWhoQuery>
export type GetWhoLazyQueryHookResult = ReturnType<typeof useGetWhoLazyQuery>
export type GetWhoQueryResult = Apollo.QueryResult<
  GetWhoQuery,
  GetWhoQueryVariables
>
export const ListWhosDocument = gql`
  query ListWhos($after: String, $first: PositiveInt, $q: String) {
    list: whosList(after: $after, first: $first, q: $q)
      @connection(key: "whos", filter: ["q"]) {
      after
      items {
        ...BasicWho
      }
    }
  }
  ${BasicWhoFragmentDoc}
`

/**
 * __useListWhosQuery__
 *
 * To run a query within a React component, call `useListWhosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWhosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWhosQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useListWhosQuery(
  baseOptions?: Apollo.QueryHookOptions<ListWhosQuery, ListWhosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListWhosQuery, ListWhosQueryVariables>(
    ListWhosDocument,
    options,
  )
}
export function useListWhosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListWhosQuery,
    ListWhosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListWhosQuery, ListWhosQueryVariables>(
    ListWhosDocument,
    options,
  )
}
export type ListWhosQueryHookResult = ReturnType<typeof useListWhosQuery>
export type ListWhosLazyQueryHookResult = ReturnType<
  typeof useListWhosLazyQuery
>
export type ListWhosQueryResult = Apollo.QueryResult<
  ListWhosQuery,
  ListWhosQueryVariables
>
export const CreateExecutiveWorkspaceDocument = gql`
  mutation CreateExecutiveWorkspace($input: CreateExecutiveWorkspaceInput!) {
    createExecutiveWorkspace(input: $input) {
      workspace {
        ...BasicWorkspace
        ...Workspace_Users
      }
      executive {
        ...Userable_Workspaces
      }
      assistant {
        ...Userable_Workspaces
      }
    }
  }
  ${BasicWorkspaceFragmentDoc}
  ${Workspace_UsersFragmentDoc}
  ${Userable_WorkspacesFragmentDoc}
`
export type CreateExecutiveWorkspaceMutationFn = Apollo.MutationFunction<
  CreateExecutiveWorkspaceMutation,
  CreateExecutiveWorkspaceMutationVariables
>

/**
 * __useCreateExecutiveWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateExecutiveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExecutiveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExecutiveWorkspaceMutation, { data, loading, error }] = useCreateExecutiveWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExecutiveWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExecutiveWorkspaceMutation,
    CreateExecutiveWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateExecutiveWorkspaceMutation,
    CreateExecutiveWorkspaceMutationVariables
  >(CreateExecutiveWorkspaceDocument, options)
}
export type CreateExecutiveWorkspaceMutationHookResult = ReturnType<
  typeof useCreateExecutiveWorkspaceMutation
>
export type CreateExecutiveWorkspaceMutationResult =
  Apollo.MutationResult<CreateExecutiveWorkspaceMutation>
export type CreateExecutiveWorkspaceMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExecutiveWorkspaceMutation,
    CreateExecutiveWorkspaceMutationVariables
  >
export const UpdateWorkspaceDocument = gql`
  mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        ...Workspace_Settings
      }
    }
  }
  ${Workspace_SettingsFragmentDoc}
`
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >(UpdateWorkspaceDocument, options)
}
export type UpdateWorkspaceMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceMutation
>
export type UpdateWorkspaceMutationResult =
  Apollo.MutationResult<UpdateWorkspaceMutation>
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>
export const ArchiveWorkspaceDocument = gql`
  mutation ArchiveWorkspace($input: ArchiveWorkspaceInput!) {
    archiveWorkspace(input: $input) {
      isSuccess
    }
  }
`
export type ArchiveWorkspaceMutationFn = Apollo.MutationFunction<
  ArchiveWorkspaceMutation,
  ArchiveWorkspaceMutationVariables
>

/**
 * __useArchiveWorkspaceMutation__
 *
 * To run a mutation, you first call `useArchiveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWorkspaceMutation, { data, loading, error }] = useArchiveWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveWorkspaceMutation,
    ArchiveWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveWorkspaceMutation,
    ArchiveWorkspaceMutationVariables
  >(ArchiveWorkspaceDocument, options)
}
export type ArchiveWorkspaceMutationHookResult = ReturnType<
  typeof useArchiveWorkspaceMutation
>
export type ArchiveWorkspaceMutationResult =
  Apollo.MutationResult<ArchiveWorkspaceMutation>
export type ArchiveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  ArchiveWorkspaceMutation,
  ArchiveWorkspaceMutationVariables
>
export const LinkWorkspaceUserAccountDocument = gql`
  mutation LinkWorkspaceUserAccount(
    $workspaceId: ID!
    $userId: ID!
    $accountId: ID!
  ) {
    linkWorkspaceUserAccount(
      workspaceId: $workspaceId
      userId: $userId
      accountId: $accountId
    ) {
      ...BasicWorkspace
    }
  }
  ${BasicWorkspaceFragmentDoc}
`
export type LinkWorkspaceUserAccountMutationFn = Apollo.MutationFunction<
  LinkWorkspaceUserAccountMutation,
  LinkWorkspaceUserAccountMutationVariables
>

/**
 * __useLinkWorkspaceUserAccountMutation__
 *
 * To run a mutation, you first call `useLinkWorkspaceUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkWorkspaceUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkWorkspaceUserAccountMutation, { data, loading, error }] = useLinkWorkspaceUserAccountMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLinkWorkspaceUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkWorkspaceUserAccountMutation,
    LinkWorkspaceUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkWorkspaceUserAccountMutation,
    LinkWorkspaceUserAccountMutationVariables
  >(LinkWorkspaceUserAccountDocument, options)
}
export type LinkWorkspaceUserAccountMutationHookResult = ReturnType<
  typeof useLinkWorkspaceUserAccountMutation
>
export type LinkWorkspaceUserAccountMutationResult =
  Apollo.MutationResult<LinkWorkspaceUserAccountMutation>
export type LinkWorkspaceUserAccountMutationOptions =
  Apollo.BaseMutationOptions<
    LinkWorkspaceUserAccountMutation,
    LinkWorkspaceUserAccountMutationVariables
  >
export const UnlinkWorkspaceUserAccountDocument = gql`
  mutation UnlinkWorkspaceUserAccount(
    $workspaceId: ID!
    $userId: ID!
    $accountId: ID!
  ) {
    unlinkWorkspaceUserAccount(
      workspaceId: $workspaceId
      userId: $userId
      accountId: $accountId
    ) {
      ...BasicWorkspace
    }
  }
  ${BasicWorkspaceFragmentDoc}
`
export type UnlinkWorkspaceUserAccountMutationFn = Apollo.MutationFunction<
  UnlinkWorkspaceUserAccountMutation,
  UnlinkWorkspaceUserAccountMutationVariables
>

/**
 * __useUnlinkWorkspaceUserAccountMutation__
 *
 * To run a mutation, you first call `useUnlinkWorkspaceUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkWorkspaceUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkWorkspaceUserAccountMutation, { data, loading, error }] = useUnlinkWorkspaceUserAccountMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUnlinkWorkspaceUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkWorkspaceUserAccountMutation,
    UnlinkWorkspaceUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnlinkWorkspaceUserAccountMutation,
    UnlinkWorkspaceUserAccountMutationVariables
  >(UnlinkWorkspaceUserAccountDocument, options)
}
export type UnlinkWorkspaceUserAccountMutationHookResult = ReturnType<
  typeof useUnlinkWorkspaceUserAccountMutation
>
export type UnlinkWorkspaceUserAccountMutationResult =
  Apollo.MutationResult<UnlinkWorkspaceUserAccountMutation>
export type UnlinkWorkspaceUserAccountMutationOptions =
  Apollo.BaseMutationOptions<
    UnlinkWorkspaceUserAccountMutation,
    UnlinkWorkspaceUserAccountMutationVariables
  >
export const AddWorkspaceAssistantDocument = gql`
  mutation AddWorkspaceAssistant($input: AddWorkspaceAssistantInput!) {
    addWorkspaceAssistant(input: $input) {
      workspace {
        ...Workspace_Users
      }
      user {
        ...Userable_Workspaces
      }
    }
  }
  ${Workspace_UsersFragmentDoc}
  ${Userable_WorkspacesFragmentDoc}
`
export type AddWorkspaceAssistantMutationFn = Apollo.MutationFunction<
  AddWorkspaceAssistantMutation,
  AddWorkspaceAssistantMutationVariables
>

/**
 * __useAddWorkspaceAssistantMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceAssistantMutation, { data, loading, error }] = useAddWorkspaceAssistantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkspaceAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWorkspaceAssistantMutation,
    AddWorkspaceAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddWorkspaceAssistantMutation,
    AddWorkspaceAssistantMutationVariables
  >(AddWorkspaceAssistantDocument, options)
}
export type AddWorkspaceAssistantMutationHookResult = ReturnType<
  typeof useAddWorkspaceAssistantMutation
>
export type AddWorkspaceAssistantMutationResult =
  Apollo.MutationResult<AddWorkspaceAssistantMutation>
export type AddWorkspaceAssistantMutationOptions = Apollo.BaseMutationOptions<
  AddWorkspaceAssistantMutation,
  AddWorkspaceAssistantMutationVariables
>
export const RemoveWorkspaceUserDocument = gql`
  mutation RemoveWorkspaceUser($input: RemoveWorkspaceUserInput!) {
    removeWorkspaceUser(input: $input) {
      workspace {
        ...Workspace_Users
      }
      user {
        ...Userable_Workspaces
      }
    }
  }
  ${Workspace_UsersFragmentDoc}
  ${Userable_WorkspacesFragmentDoc}
`
export type RemoveWorkspaceUserMutationFn = Apollo.MutationFunction<
  RemoveWorkspaceUserMutation,
  RemoveWorkspaceUserMutationVariables
>

/**
 * __useRemoveWorkspaceUserMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceUserMutation, { data, loading, error }] = useRemoveWorkspaceUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWorkspaceUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWorkspaceUserMutation,
    RemoveWorkspaceUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveWorkspaceUserMutation,
    RemoveWorkspaceUserMutationVariables
  >(RemoveWorkspaceUserDocument, options)
}
export type RemoveWorkspaceUserMutationHookResult = ReturnType<
  typeof useRemoveWorkspaceUserMutation
>
export type RemoveWorkspaceUserMutationResult =
  Apollo.MutationResult<RemoveWorkspaceUserMutation>
export type RemoveWorkspaceUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkspaceUserMutation,
  RemoveWorkspaceUserMutationVariables
>
export const SpectateWorkspaceDocument = gql`
  mutation SpectateWorkspace($workspaceId: ID!) {
    spectateWorkspace(input: { workspaceId: $workspaceId }) {
      workspace {
        ...Workspace_Users
      }
      user {
        ...Userable_Workspaces
      }
    }
  }
  ${Workspace_UsersFragmentDoc}
  ${Userable_WorkspacesFragmentDoc}
`
export type SpectateWorkspaceMutationFn = Apollo.MutationFunction<
  SpectateWorkspaceMutation,
  SpectateWorkspaceMutationVariables
>

/**
 * __useSpectateWorkspaceMutation__
 *
 * To run a mutation, you first call `useSpectateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpectateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spectateWorkspaceMutation, { data, loading, error }] = useSpectateWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSpectateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpectateWorkspaceMutation,
    SpectateWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SpectateWorkspaceMutation,
    SpectateWorkspaceMutationVariables
  >(SpectateWorkspaceDocument, options)
}
export type SpectateWorkspaceMutationHookResult = ReturnType<
  typeof useSpectateWorkspaceMutation
>
export type SpectateWorkspaceMutationResult =
  Apollo.MutationResult<SpectateWorkspaceMutation>
export type SpectateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  SpectateWorkspaceMutation,
  SpectateWorkspaceMutationVariables
>
export const AddUserAccountDocument = gql`
  mutation AddUserAccount($userId: ID!, $input: AddUserAccountInput!) {
    addUserAccount(userId: $userId, input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type AddUserAccountMutationFn = Apollo.MutationFunction<
  AddUserAccountMutation,
  AddUserAccountMutationVariables
>

/**
 * __useAddUserAccountMutation__
 *
 * To run a mutation, you first call `useAddUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserAccountMutation, { data, loading, error }] = useAddUserAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserAccountMutation,
    AddUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddUserAccountMutation,
    AddUserAccountMutationVariables
  >(AddUserAccountDocument, options)
}
export type AddUserAccountMutationHookResult = ReturnType<
  typeof useAddUserAccountMutation
>
export type AddUserAccountMutationResult =
  Apollo.MutationResult<AddUserAccountMutation>
export type AddUserAccountMutationOptions = Apollo.BaseMutationOptions<
  AddUserAccountMutation,
  AddUserAccountMutationVariables
>
export const DeleteUserAccountDocument = gql`
  mutation DeleteUserAccount($input: DeleteUserAccountInput!) {
    deleteUserAccount(input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type DeleteUserAccountMutationFn = Apollo.MutationFunction<
  DeleteUserAccountMutation,
  DeleteUserAccountMutationVariables
>

/**
 * __useDeleteUserAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAccountMutation, { data, loading, error }] = useDeleteUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserAccountMutation,
    DeleteUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteUserAccountMutation,
    DeleteUserAccountMutationVariables
  >(DeleteUserAccountDocument, options)
}
export type DeleteUserAccountMutationHookResult = ReturnType<
  typeof useDeleteUserAccountMutation
>
export type DeleteUserAccountMutationResult =
  Apollo.MutationResult<DeleteUserAccountMutation>
export type DeleteUserAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserAccountMutation,
  DeleteUserAccountMutationVariables
>
export const SelectPrimaryUserAccountDocument = gql`
  mutation SelectPrimaryUserAccount($input: SelectPrimaryUserAccountInput!) {
    selectPrimaryUserAccount(input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type SelectPrimaryUserAccountMutationFn = Apollo.MutationFunction<
  SelectPrimaryUserAccountMutation,
  SelectPrimaryUserAccountMutationVariables
>

/**
 * __useSelectPrimaryUserAccountMutation__
 *
 * To run a mutation, you first call `useSelectPrimaryUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectPrimaryUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectPrimaryUserAccountMutation, { data, loading, error }] = useSelectPrimaryUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectPrimaryUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectPrimaryUserAccountMutation,
    SelectPrimaryUserAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SelectPrimaryUserAccountMutation,
    SelectPrimaryUserAccountMutationVariables
  >(SelectPrimaryUserAccountDocument, options)
}
export type SelectPrimaryUserAccountMutationHookResult = ReturnType<
  typeof useSelectPrimaryUserAccountMutation
>
export type SelectPrimaryUserAccountMutationResult =
  Apollo.MutationResult<SelectPrimaryUserAccountMutation>
export type SelectPrimaryUserAccountMutationOptions =
  Apollo.BaseMutationOptions<
    SelectPrimaryUserAccountMutation,
    SelectPrimaryUserAccountMutationVariables
  >
export const CreateAssistantDocument = gql`
  mutation CreateAssistant($input: CreateAssistantInput!) {
    user: createAssistant(input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type CreateAssistantMutationFn = Apollo.MutationFunction<
  CreateAssistantMutation,
  CreateAssistantMutationVariables
>

/**
 * __useCreateAssistantMutation__
 *
 * To run a mutation, you first call `useCreateAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssistantMutation, { data, loading, error }] = useCreateAssistantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssistantMutation,
    CreateAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAssistantMutation,
    CreateAssistantMutationVariables
  >(CreateAssistantDocument, options)
}
export type CreateAssistantMutationHookResult = ReturnType<
  typeof useCreateAssistantMutation
>
export type CreateAssistantMutationResult =
  Apollo.MutationResult<CreateAssistantMutation>
export type CreateAssistantMutationOptions = Apollo.BaseMutationOptions<
  CreateAssistantMutation,
  CreateAssistantMutationVariables
>
export const CreateExecutiveDocument = gql`
  mutation CreateExecutive($input: CreateExecutiveInput!) {
    user: createExecutive(input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type CreateExecutiveMutationFn = Apollo.MutationFunction<
  CreateExecutiveMutation,
  CreateExecutiveMutationVariables
>

/**
 * __useCreateExecutiveMutation__
 *
 * To run a mutation, you first call `useCreateExecutiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExecutiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExecutiveMutation, { data, loading, error }] = useCreateExecutiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExecutiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExecutiveMutation,
    CreateExecutiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateExecutiveMutation,
    CreateExecutiveMutationVariables
  >(CreateExecutiveDocument, options)
}
export type CreateExecutiveMutationHookResult = ReturnType<
  typeof useCreateExecutiveMutation
>
export type CreateExecutiveMutationResult =
  Apollo.MutationResult<CreateExecutiveMutation>
export type CreateExecutiveMutationOptions = Apollo.BaseMutationOptions<
  CreateExecutiveMutation,
  CreateExecutiveMutationVariables
>
export const CreateHqDocument = gql`
  mutation CreateHq($input: CreateHqInput!) {
    user: createHq(input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type CreateHqMutationFn = Apollo.MutationFunction<
  CreateHqMutation,
  CreateHqMutationVariables
>

/**
 * __useCreateHqMutation__
 *
 * To run a mutation, you first call `useCreateHqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHqMutation, { data, loading, error }] = useCreateHqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHqMutation,
    CreateHqMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateHqMutation, CreateHqMutationVariables>(
    CreateHqDocument,
    options,
  )
}
export type CreateHqMutationHookResult = ReturnType<typeof useCreateHqMutation>
export type CreateHqMutationResult = Apollo.MutationResult<CreateHqMutation>
export type CreateHqMutationOptions = Apollo.BaseMutationOptions<
  CreateHqMutation,
  CreateHqMutationVariables
>
export const CreateSandboxDocument = gql`
  mutation CreateSandbox($input: CreateSandboxInput!) {
    user: createSandbox(input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type CreateSandboxMutationFn = Apollo.MutationFunction<
  CreateSandboxMutation,
  CreateSandboxMutationVariables
>

/**
 * __useCreateSandboxMutation__
 *
 * To run a mutation, you first call `useCreateSandboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSandboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSandboxMutation, { data, loading, error }] = useCreateSandboxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSandboxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSandboxMutation,
    CreateSandboxMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSandboxMutation,
    CreateSandboxMutationVariables
  >(CreateSandboxDocument, options)
}
export type CreateSandboxMutationHookResult = ReturnType<
  typeof useCreateSandboxMutation
>
export type CreateSandboxMutationResult =
  Apollo.MutationResult<CreateSandboxMutation>
export type CreateSandboxMutationOptions = Apollo.BaseMutationOptions<
  CreateSandboxMutation,
  CreateSandboxMutationVariables
>
export const ListEmailTemplatesDocument = gql`
  query ListEmailTemplates {
    emailTemplates {
      id
      name
    }
  }
`

/**
 * __useListEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useListEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmailTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmailTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEmailTemplatesQuery,
    ListEmailTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListEmailTemplatesQuery,
    ListEmailTemplatesQueryVariables
  >(ListEmailTemplatesDocument, options)
}
export function useListEmailTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEmailTemplatesQuery,
    ListEmailTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListEmailTemplatesQuery,
    ListEmailTemplatesQueryVariables
  >(ListEmailTemplatesDocument, options)
}
export type ListEmailTemplatesQueryHookResult = ReturnType<
  typeof useListEmailTemplatesQuery
>
export type ListEmailTemplatesLazyQueryHookResult = ReturnType<
  typeof useListEmailTemplatesLazyQuery
>
export type ListEmailTemplatesQueryResult = Apollo.QueryResult<
  ListEmailTemplatesQuery,
  ListEmailTemplatesQueryVariables
>
export const GetEmailTemplateByIdDocument = gql`
  query GetEmailTemplateById($id: ID!) {
    emailTemplate: emailTemplateById(id: $id) {
      id
      name
      subject
      html
      placeholders
    }
  }
`

/**
 * __useGetEmailTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetEmailTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmailTemplateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmailTemplateByIdQuery,
    GetEmailTemplateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmailTemplateByIdQuery,
    GetEmailTemplateByIdQueryVariables
  >(GetEmailTemplateByIdDocument, options)
}
export function useGetEmailTemplateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailTemplateByIdQuery,
    GetEmailTemplateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmailTemplateByIdQuery,
    GetEmailTemplateByIdQueryVariables
  >(GetEmailTemplateByIdDocument, options)
}
export type GetEmailTemplateByIdQueryHookResult = ReturnType<
  typeof useGetEmailTemplateByIdQuery
>
export type GetEmailTemplateByIdLazyQueryHookResult = ReturnType<
  typeof useGetEmailTemplateByIdLazyQuery
>
export type GetEmailTemplateByIdQueryResult = Apollo.QueryResult<
  GetEmailTemplateByIdQuery,
  GetEmailTemplateByIdQueryVariables
>
export const SendEmailFromTemplateDocument = gql`
  mutation SendEmailFromTemplate($input: SendEmailFromTemplateInput!) {
    sendEmailFromTemplate(input: $input) {
      isSuccess
    }
  }
`
export type SendEmailFromTemplateMutationFn = Apollo.MutationFunction<
  SendEmailFromTemplateMutation,
  SendEmailFromTemplateMutationVariables
>

/**
 * __useSendEmailFromTemplateMutation__
 *
 * To run a mutation, you first call `useSendEmailFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailFromTemplateMutation, { data, loading, error }] = useSendEmailFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailFromTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailFromTemplateMutation,
    SendEmailFromTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendEmailFromTemplateMutation,
    SendEmailFromTemplateMutationVariables
  >(SendEmailFromTemplateDocument, options)
}
export type SendEmailFromTemplateMutationHookResult = ReturnType<
  typeof useSendEmailFromTemplateMutation
>
export type SendEmailFromTemplateMutationResult =
  Apollo.MutationResult<SendEmailFromTemplateMutation>
export type SendEmailFromTemplateMutationOptions = Apollo.BaseMutationOptions<
  SendEmailFromTemplateMutation,
  SendEmailFromTemplateMutationVariables
>
export const SendEmailMessageDocument = gql`
  mutation SendEmailMessage($input: SendEmailMessageInput!) {
    sendEmailMessage(input: $input) {
      __typename
    }
  }
`
export type SendEmailMessageMutationFn = Apollo.MutationFunction<
  SendEmailMessageMutation,
  SendEmailMessageMutationVariables
>

/**
 * __useSendEmailMessageMutation__
 *
 * To run a mutation, you first call `useSendEmailMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMessageMutation, { data, loading, error }] = useSendEmailMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailMessageMutation,
    SendEmailMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendEmailMessageMutation,
    SendEmailMessageMutationVariables
  >(SendEmailMessageDocument, options)
}
export type SendEmailMessageMutationHookResult = ReturnType<
  typeof useSendEmailMessageMutation
>
export type SendEmailMessageMutationResult =
  Apollo.MutationResult<SendEmailMessageMutation>
export type SendEmailMessageMutationOptions = Apollo.BaseMutationOptions<
  SendEmailMessageMutation,
  SendEmailMessageMutationVariables
>
export const GetEmailActivitiesDocument = gql`
  query GetEmailActivities(
    $dateAfter: DateTime
    $recipients: [EmailAddress!]
    $sendgridTemplateIds: [String!]
    $messageTemplateIds: [String!]
    $userId: ID
    $workspaceId: ID
    $allOfTags: [String!]
    $oneOfTags: [String!]
  ) {
    emailActivities(
      dateAfter: $dateAfter
      recipients: $recipients
      sendgridTemplateIds: $sendgridTemplateIds
      messageTemplateIds: $messageTemplateIds
      userId: $userId
      workspaceId: $workspaceId
      allOfTags: $allOfTags
      oneOfTags: $oneOfTags
    ) {
      id
      processedAt
      deliveredAt
      opened {
        at
      }
      clicked {
        at
      }
      template {
        name
      }
    }
  }
`

/**
 * __useGetEmailActivitiesQuery__
 *
 * To run a query within a React component, call `useGetEmailActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailActivitiesQuery({
 *   variables: {
 *      dateAfter: // value for 'dateAfter'
 *      recipients: // value for 'recipients'
 *      sendgridTemplateIds: // value for 'sendgridTemplateIds'
 *      messageTemplateIds: // value for 'messageTemplateIds'
 *      userId: // value for 'userId'
 *      workspaceId: // value for 'workspaceId'
 *      allOfTags: // value for 'allOfTags'
 *      oneOfTags: // value for 'oneOfTags'
 *   },
 * });
 */
export function useGetEmailActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmailActivitiesQuery,
    GetEmailActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmailActivitiesQuery,
    GetEmailActivitiesQueryVariables
  >(GetEmailActivitiesDocument, options)
}
export function useGetEmailActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailActivitiesQuery,
    GetEmailActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmailActivitiesQuery,
    GetEmailActivitiesQueryVariables
  >(GetEmailActivitiesDocument, options)
}
export type GetEmailActivitiesQueryHookResult = ReturnType<
  typeof useGetEmailActivitiesQuery
>
export type GetEmailActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetEmailActivitiesLazyQuery
>
export type GetEmailActivitiesQueryResult = Apollo.QueryResult<
  GetEmailActivitiesQuery,
  GetEmailActivitiesQueryVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const ArchiveUserDocument = gql`
  mutation ArchiveUser($input: ArchiveUserInput!) {
    archiveUser(input: $input) {
      isSuccess
    }
  }
`
export type ArchiveUserMutationFn = Apollo.MutationFunction<
  ArchiveUserMutation,
  ArchiveUserMutationVariables
>

/**
 * __useArchiveUserMutation__
 *
 * To run a mutation, you first call `useArchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUserMutation, { data, loading, error }] = useArchiveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveUserMutation,
    ArchiveUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveUserMutation, ArchiveUserMutationVariables>(
    ArchiveUserDocument,
    options,
  )
}
export type ArchiveUserMutationHookResult = ReturnType<
  typeof useArchiveUserMutation
>
export type ArchiveUserMutationResult =
  Apollo.MutationResult<ArchiveUserMutation>
export type ArchiveUserMutationOptions = Apollo.BaseMutationOptions<
  ArchiveUserMutation,
  ArchiveUserMutationVariables
>
export const UnarchiveUserDocument = gql`
  mutation UnarchiveUser($input: UnarchiveUserInput!) {
    unarchiveUser(input: $input) {
      user {
        ...FullUser
      }
    }
  }
  ${FullUserFragmentDoc}
`
export type UnarchiveUserMutationFn = Apollo.MutationFunction<
  UnarchiveUserMutation,
  UnarchiveUserMutationVariables
>

/**
 * __useUnarchiveUserMutation__
 *
 * To run a mutation, you first call `useUnarchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveUserMutation, { data, loading, error }] = useUnarchiveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveUserMutation,
    UnarchiveUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveUserMutation,
    UnarchiveUserMutationVariables
  >(UnarchiveUserDocument, options)
}
export type UnarchiveUserMutationHookResult = ReturnType<
  typeof useUnarchiveUserMutation
>
export type UnarchiveUserMutationResult =
  Apollo.MutationResult<UnarchiveUserMutation>
export type UnarchiveUserMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveUserMutation,
  UnarchiveUserMutationVariables
>
export const ChurnExecutiveDocument = gql`
  mutation ChurnExecutive($input: ChurnExecutiveInput!) {
    churnExecutive(input: $input) {
      isSuccess
    }
  }
`
export type ChurnExecutiveMutationFn = Apollo.MutationFunction<
  ChurnExecutiveMutation,
  ChurnExecutiveMutationVariables
>

/**
 * __useChurnExecutiveMutation__
 *
 * To run a mutation, you first call `useChurnExecutiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChurnExecutiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [churnExecutiveMutation, { data, loading, error }] = useChurnExecutiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChurnExecutiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChurnExecutiveMutation,
    ChurnExecutiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChurnExecutiveMutation,
    ChurnExecutiveMutationVariables
  >(ChurnExecutiveDocument, options)
}
export type ChurnExecutiveMutationHookResult = ReturnType<
  typeof useChurnExecutiveMutation
>
export type ChurnExecutiveMutationResult =
  Apollo.MutationResult<ChurnExecutiveMutation>
export type ChurnExecutiveMutationOptions = Apollo.BaseMutationOptions<
  ChurnExecutiveMutation,
  ChurnExecutiveMutationVariables
>
export const ScheduleTeamChurnDocument = gql`
  mutation ScheduleTeamChurn($input: ScheduleTeamChurnInput!) {
    scheduleTeamChurn(input: $input) {
      isSuccess
    }
  }
`
export type ScheduleTeamChurnMutationFn = Apollo.MutationFunction<
  ScheduleTeamChurnMutation,
  ScheduleTeamChurnMutationVariables
>

/**
 * __useScheduleTeamChurnMutation__
 *
 * To run a mutation, you first call `useScheduleTeamChurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTeamChurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTeamChurnMutation, { data, loading, error }] = useScheduleTeamChurnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleTeamChurnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleTeamChurnMutation,
    ScheduleTeamChurnMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ScheduleTeamChurnMutation,
    ScheduleTeamChurnMutationVariables
  >(ScheduleTeamChurnDocument, options)
}
export type ScheduleTeamChurnMutationHookResult = ReturnType<
  typeof useScheduleTeamChurnMutation
>
export type ScheduleTeamChurnMutationResult =
  Apollo.MutationResult<ScheduleTeamChurnMutation>
export type ScheduleTeamChurnMutationOptions = Apollo.BaseMutationOptions<
  ScheduleTeamChurnMutation,
  ScheduleTeamChurnMutationVariables
>
export const CancelScheduledTeamChurnDocument = gql`
  mutation CancelScheduledTeamChurn($input: CancelScheduledTeamChurnInput!) {
    cancelScheduledTeamChurn(input: $input) {
      canceledJobs {
        ...AutomationJob_Full
      }
    }
  }
  ${AutomationJob_FullFragmentDoc}
`
export type CancelScheduledTeamChurnMutationFn = Apollo.MutationFunction<
  CancelScheduledTeamChurnMutation,
  CancelScheduledTeamChurnMutationVariables
>

/**
 * __useCancelScheduledTeamChurnMutation__
 *
 * To run a mutation, you first call `useCancelScheduledTeamChurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledTeamChurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledTeamChurnMutation, { data, loading, error }] = useCancelScheduledTeamChurnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduledTeamChurnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduledTeamChurnMutation,
    CancelScheduledTeamChurnMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelScheduledTeamChurnMutation,
    CancelScheduledTeamChurnMutationVariables
  >(CancelScheduledTeamChurnDocument, options)
}
export type CancelScheduledTeamChurnMutationHookResult = ReturnType<
  typeof useCancelScheduledTeamChurnMutation
>
export type CancelScheduledTeamChurnMutationResult =
  Apollo.MutationResult<CancelScheduledTeamChurnMutation>
export type CancelScheduledTeamChurnMutationOptions =
  Apollo.BaseMutationOptions<
    CancelScheduledTeamChurnMutation,
    CancelScheduledTeamChurnMutationVariables
  >
export const UpdateAssistantDocument = gql`
  mutation UpdateAssistant($userId: ID!, $input: UpdateAssistantInput!) {
    updateAssistant(id: $userId, input: $input) {
      ...Assistantable_AssistantData
    }
  }
  ${Assistantable_AssistantDataFragmentDoc}
`
export type UpdateAssistantMutationFn = Apollo.MutationFunction<
  UpdateAssistantMutation,
  UpdateAssistantMutationVariables
>

/**
 * __useUpdateAssistantMutation__
 *
 * To run a mutation, you first call `useUpdateAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssistantMutation, { data, loading, error }] = useUpdateAssistantMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssistantMutation,
    UpdateAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAssistantMutation,
    UpdateAssistantMutationVariables
  >(UpdateAssistantDocument, options)
}
export type UpdateAssistantMutationHookResult = ReturnType<
  typeof useUpdateAssistantMutation
>
export type UpdateAssistantMutationResult =
  Apollo.MutationResult<UpdateAssistantMutation>
export type UpdateAssistantMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssistantMutation,
  UpdateAssistantMutationVariables
>
export const LoginDocument = gql`
  mutation Login($idToken: String!) {
    login(idToken: $idToken) {
      token
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const CreateStripeCustomerDocument = gql`
  mutation CreateStripeCustomer($input: CreateStripeCustomerInput!) {
    createStripeCustomer(input: $input) {
      entity {
        ...BillableEntity_StripeCustomer
      }
    }
  }
  ${BillableEntity_StripeCustomerFragmentDoc}
`
export type CreateStripeCustomerMutationFn = Apollo.MutationFunction<
  CreateStripeCustomerMutation,
  CreateStripeCustomerMutationVariables
>

/**
 * __useCreateStripeCustomerMutation__
 *
 * To run a mutation, you first call `useCreateStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCustomerMutation, { data, loading, error }] = useCreateStripeCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeCustomerMutation,
    CreateStripeCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateStripeCustomerMutation,
    CreateStripeCustomerMutationVariables
  >(CreateStripeCustomerDocument, options)
}
export type CreateStripeCustomerMutationHookResult = ReturnType<
  typeof useCreateStripeCustomerMutation
>
export type CreateStripeCustomerMutationResult =
  Apollo.MutationResult<CreateStripeCustomerMutation>
export type CreateStripeCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeCustomerMutation,
  CreateStripeCustomerMutationVariables
>
export const UpdateStripeCustomerIdDocument = gql`
  mutation UpdateStripeCustomerId($input: UpdateStripeCustomerIdInput!) {
    updateStripeCustomerId(input: $input) {
      entity {
        ...BillableEntity_StripeCustomer
      }
    }
  }
  ${BillableEntity_StripeCustomerFragmentDoc}
`
export type UpdateStripeCustomerIdMutationFn = Apollo.MutationFunction<
  UpdateStripeCustomerIdMutation,
  UpdateStripeCustomerIdMutationVariables
>

/**
 * __useUpdateStripeCustomerIdMutation__
 *
 * To run a mutation, you first call `useUpdateStripeCustomerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeCustomerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeCustomerIdMutation, { data, loading, error }] = useUpdateStripeCustomerIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStripeCustomerIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStripeCustomerIdMutation,
    UpdateStripeCustomerIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateStripeCustomerIdMutation,
    UpdateStripeCustomerIdMutationVariables
  >(UpdateStripeCustomerIdDocument, options)
}
export type UpdateStripeCustomerIdMutationHookResult = ReturnType<
  typeof useUpdateStripeCustomerIdMutation
>
export type UpdateStripeCustomerIdMutationResult =
  Apollo.MutationResult<UpdateStripeCustomerIdMutation>
export type UpdateStripeCustomerIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateStripeCustomerIdMutation,
  UpdateStripeCustomerIdMutationVariables
>
export const ListFeaturesDocument = gql`
  query ListFeatures {
    list: featuresList {
      items {
        ...Feature
      }
    }
  }
  ${FeatureFragmentDoc}
`

/**
 * __useListFeaturesQuery__
 *
 * To run a query within a React component, call `useListFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFeaturesQuery,
    ListFeaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListFeaturesQuery, ListFeaturesQueryVariables>(
    ListFeaturesDocument,
    options,
  )
}
export function useListFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFeaturesQuery,
    ListFeaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListFeaturesQuery, ListFeaturesQueryVariables>(
    ListFeaturesDocument,
    options,
  )
}
export type ListFeaturesQueryHookResult = ReturnType<
  typeof useListFeaturesQuery
>
export type ListFeaturesLazyQueryHookResult = ReturnType<
  typeof useListFeaturesLazyQuery
>
export type ListFeaturesQueryResult = Apollo.QueryResult<
  ListFeaturesQuery,
  ListFeaturesQueryVariables
>
export const UpsertFeatureDocument = gql`
  mutation UpsertFeature($input: UpsertFeatureInput!) {
    upsertFeature(input: $input) {
      feature {
        ...Feature
      }
    }
  }
  ${FeatureFragmentDoc}
`
export type UpsertFeatureMutationFn = Apollo.MutationFunction<
  UpsertFeatureMutation,
  UpsertFeatureMutationVariables
>

/**
 * __useUpsertFeatureMutation__
 *
 * To run a mutation, you first call `useUpsertFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFeatureMutation, { data, loading, error }] = useUpsertFeatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertFeatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertFeatureMutation,
    UpsertFeatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertFeatureMutation,
    UpsertFeatureMutationVariables
  >(UpsertFeatureDocument, options)
}
export type UpsertFeatureMutationHookResult = ReturnType<
  typeof useUpsertFeatureMutation
>
export type UpsertFeatureMutationResult =
  Apollo.MutationResult<UpsertFeatureMutation>
export type UpsertFeatureMutationOptions = Apollo.BaseMutationOptions<
  UpsertFeatureMutation,
  UpsertFeatureMutationVariables
>
export const ListWorkspaceDocument = gql`
  query ListWorkspace(
    $first: PositiveInt = 20
    $after: String = "0"
    $isOverridingFeature: String
  ) {
    workspacesList(
      first: $first
      after: $after
      isOverridingFeature: $isOverridingFeature
    ) {
      items {
        ...BasicWorkspace
      }
      after
      hasAfter
    }
  }
  ${BasicWorkspaceFragmentDoc}
`

/**
 * __useListWorkspaceQuery__
 *
 * To run a query within a React component, call `useListWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkspaceQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      isOverridingFeature: // value for 'isOverridingFeature'
 *   },
 * });
 */
export function useListWorkspaceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListWorkspaceQuery,
    ListWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListWorkspaceQuery, ListWorkspaceQueryVariables>(
    ListWorkspaceDocument,
    options,
  )
}
export function useListWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListWorkspaceQuery,
    ListWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListWorkspaceQuery, ListWorkspaceQueryVariables>(
    ListWorkspaceDocument,
    options,
  )
}
export type ListWorkspaceQueryHookResult = ReturnType<
  typeof useListWorkspaceQuery
>
export type ListWorkspaceLazyQueryHookResult = ReturnType<
  typeof useListWorkspaceLazyQuery
>
export type ListWorkspaceQueryResult = Apollo.QueryResult<
  ListWorkspaceQuery,
  ListWorkspaceQueryVariables
>
export const ListTopicTemplatesDocument = gql`
  query ListTopicTemplates {
    list: topicTemplatesList @connection(key: "topicTemplates") {
      items {
        ...TopicTemplate
      }
    }
  }
  ${TopicTemplateFragmentDoc}
`

/**
 * __useListTopicTemplatesQuery__
 *
 * To run a query within a React component, call `useListTopicTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTopicTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTopicTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTopicTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTopicTemplatesQuery,
    ListTopicTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListTopicTemplatesQuery,
    ListTopicTemplatesQueryVariables
  >(ListTopicTemplatesDocument, options)
}
export function useListTopicTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTopicTemplatesQuery,
    ListTopicTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListTopicTemplatesQuery,
    ListTopicTemplatesQueryVariables
  >(ListTopicTemplatesDocument, options)
}
export type ListTopicTemplatesQueryHookResult = ReturnType<
  typeof useListTopicTemplatesQuery
>
export type ListTopicTemplatesLazyQueryHookResult = ReturnType<
  typeof useListTopicTemplatesLazyQuery
>
export type ListTopicTemplatesQueryResult = Apollo.QueryResult<
  ListTopicTemplatesQuery,
  ListTopicTemplatesQueryVariables
>
export const UpsertTopicTemplateDocument = gql`
  mutation UpsertTopicTemplate($input: UpsertTopicTemplateInput!) {
    upsertTopicTemplate(input: $input) {
      topicTemplate {
        ...TopicTemplate
      }
    }
  }
  ${TopicTemplateFragmentDoc}
`
export type UpsertTopicTemplateMutationFn = Apollo.MutationFunction<
  UpsertTopicTemplateMutation,
  UpsertTopicTemplateMutationVariables
>

/**
 * __useUpsertTopicTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertTopicTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTopicTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTopicTemplateMutation, { data, loading, error }] = useUpsertTopicTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTopicTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTopicTemplateMutation,
    UpsertTopicTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertTopicTemplateMutation,
    UpsertTopicTemplateMutationVariables
  >(UpsertTopicTemplateDocument, options)
}
export type UpsertTopicTemplateMutationHookResult = ReturnType<
  typeof useUpsertTopicTemplateMutation
>
export type UpsertTopicTemplateMutationResult =
  Apollo.MutationResult<UpsertTopicTemplateMutation>
export type UpsertTopicTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertTopicTemplateMutation,
  UpsertTopicTemplateMutationVariables
>
export const ArchiveTopicTemplateDocument = gql`
  mutation ArchiveTopicTemplate($input: ArchiveTopicTemplateInput!) {
    archiveTopicTemplate(input: $input) {
      isSuccess
    }
  }
`
export type ArchiveTopicTemplateMutationFn = Apollo.MutationFunction<
  ArchiveTopicTemplateMutation,
  ArchiveTopicTemplateMutationVariables
>

/**
 * __useArchiveTopicTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveTopicTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTopicTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTopicTemplateMutation, { data, loading, error }] = useArchiveTopicTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveTopicTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveTopicTemplateMutation,
    ArchiveTopicTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveTopicTemplateMutation,
    ArchiveTopicTemplateMutationVariables
  >(ArchiveTopicTemplateDocument, options)
}
export type ArchiveTopicTemplateMutationHookResult = ReturnType<
  typeof useArchiveTopicTemplateMutation
>
export type ArchiveTopicTemplateMutationResult =
  Apollo.MutationResult<ArchiveTopicTemplateMutation>
export type ArchiveTopicTemplateMutationOptions = Apollo.BaseMutationOptions<
  ArchiveTopicTemplateMutation,
  ArchiveTopicTemplateMutationVariables
>
export const SaveTopicTemplateFormDocument = gql`
  mutation SaveTopicTemplateForm($input: SaveTopicTemplateFormInput!) {
    saveTopicTemplateForm(input: $input) {
      template {
        ...TopicTemplate
      }
    }
  }
  ${TopicTemplateFragmentDoc}
`
export type SaveTopicTemplateFormMutationFn = Apollo.MutationFunction<
  SaveTopicTemplateFormMutation,
  SaveTopicTemplateFormMutationVariables
>

/**
 * __useSaveTopicTemplateFormMutation__
 *
 * To run a mutation, you first call `useSaveTopicTemplateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTopicTemplateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTopicTemplateFormMutation, { data, loading, error }] = useSaveTopicTemplateFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTopicTemplateFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTopicTemplateFormMutation,
    SaveTopicTemplateFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveTopicTemplateFormMutation,
    SaveTopicTemplateFormMutationVariables
  >(SaveTopicTemplateFormDocument, options)
}
export type SaveTopicTemplateFormMutationHookResult = ReturnType<
  typeof useSaveTopicTemplateFormMutation
>
export type SaveTopicTemplateFormMutationResult =
  Apollo.MutationResult<SaveTopicTemplateFormMutation>
export type SaveTopicTemplateFormMutationOptions = Apollo.BaseMutationOptions<
  SaveTopicTemplateFormMutation,
  SaveTopicTemplateFormMutationVariables
>
export const GetWorkspaceDocument = gql`
  query GetWorkspace($workspaceId: ID!) {
    workspace: workspaceById(id: $workspaceId) {
      ...BasicWorkspace
    }
  }
  ${BasicWorkspaceFragmentDoc}
`

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceQuery,
    GetWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options,
  )
}
export function useGetWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceQuery,
    GetWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options,
  )
}
export type GetWorkspaceQueryHookResult = ReturnType<
  typeof useGetWorkspaceQuery
>
export type GetWorkspaceLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceLazyQuery
>
export type GetWorkspaceQueryResult = Apollo.QueryResult<
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables
>
export const GetWorkspaceOnboardingDocument = gql`
  query GetWorkspaceOnboarding($workspaceId: ID!) {
    workspace: workspaceById(id: $workspaceId) {
      ...WorkspaceWithOnboarding
      executives {
        ...BasicUser
        ...Executivable_Teammate
        principalUser {
          ... on Userable {
            ...Userable_Minimal
            workspaces(role: EXECUTIVE) {
              assistants {
                ...Userable_Minimal
              }
            }
          }
        }
      }
      assistants {
        ...Userable_Minimal
      }
      workingHoursTimeZone
    }
  }
  ${WorkspaceWithOnboardingFragmentDoc}
  ${BasicUserFragmentDoc}
  ${Executivable_TeammateFragmentDoc}
  ${Userable_MinimalFragmentDoc}
`

/**
 * __useGetWorkspaceOnboardingQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceOnboardingQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceOnboardingQuery,
    GetWorkspaceOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkspaceOnboardingQuery,
    GetWorkspaceOnboardingQueryVariables
  >(GetWorkspaceOnboardingDocument, options)
}
export function useGetWorkspaceOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceOnboardingQuery,
    GetWorkspaceOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkspaceOnboardingQuery,
    GetWorkspaceOnboardingQueryVariables
  >(GetWorkspaceOnboardingDocument, options)
}
export type GetWorkspaceOnboardingQueryHookResult = ReturnType<
  typeof useGetWorkspaceOnboardingQuery
>
export type GetWorkspaceOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceOnboardingLazyQuery
>
export type GetWorkspaceOnboardingQueryResult = Apollo.QueryResult<
  GetWorkspaceOnboardingQuery,
  GetWorkspaceOnboardingQueryVariables
>
export const GetWorkspaceOnboardingChecklistDocument = gql`
  query GetWorkspaceOnboardingChecklist($workspaceId: ID!) {
    workspace: workspaceById(id: $workspaceId) {
      id
      onboarding {
        ...Onboarding_Checklist
      }
    }
  }
  ${Onboarding_ChecklistFragmentDoc}
`

/**
 * __useGetWorkspaceOnboardingChecklistQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceOnboardingChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceOnboardingChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceOnboardingChecklistQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceOnboardingChecklistQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceOnboardingChecklistQuery,
    GetWorkspaceOnboardingChecklistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkspaceOnboardingChecklistQuery,
    GetWorkspaceOnboardingChecklistQueryVariables
  >(GetWorkspaceOnboardingChecklistDocument, options)
}
export function useGetWorkspaceOnboardingChecklistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceOnboardingChecklistQuery,
    GetWorkspaceOnboardingChecklistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkspaceOnboardingChecklistQuery,
    GetWorkspaceOnboardingChecklistQueryVariables
  >(GetWorkspaceOnboardingChecklistDocument, options)
}
export type GetWorkspaceOnboardingChecklistQueryHookResult = ReturnType<
  typeof useGetWorkspaceOnboardingChecklistQuery
>
export type GetWorkspaceOnboardingChecklistLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceOnboardingChecklistLazyQuery
>
export type GetWorkspaceOnboardingChecklistQueryResult = Apollo.QueryResult<
  GetWorkspaceOnboardingChecklistQuery,
  GetWorkspaceOnboardingChecklistQueryVariables
>
export const GetWorkspaceOnboardingAvailabilityDocument = gql`
  query GetWorkspaceOnboardingAvailability($workspaceId: ID!) {
    workspace: workspaceById(id: $workspaceId) {
      id
      onboarding {
        ...Onboarding_Availability
      }
    }
  }
  ${Onboarding_AvailabilityFragmentDoc}
`

/**
 * __useGetWorkspaceOnboardingAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceOnboardingAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceOnboardingAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceOnboardingAvailabilityQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceOnboardingAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceOnboardingAvailabilityQuery,
    GetWorkspaceOnboardingAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkspaceOnboardingAvailabilityQuery,
    GetWorkspaceOnboardingAvailabilityQueryVariables
  >(GetWorkspaceOnboardingAvailabilityDocument, options)
}
export function useGetWorkspaceOnboardingAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceOnboardingAvailabilityQuery,
    GetWorkspaceOnboardingAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkspaceOnboardingAvailabilityQuery,
    GetWorkspaceOnboardingAvailabilityQueryVariables
  >(GetWorkspaceOnboardingAvailabilityDocument, options)
}
export type GetWorkspaceOnboardingAvailabilityQueryHookResult = ReturnType<
  typeof useGetWorkspaceOnboardingAvailabilityQuery
>
export type GetWorkspaceOnboardingAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceOnboardingAvailabilityLazyQuery
>
export type GetWorkspaceOnboardingAvailabilityQueryResult = Apollo.QueryResult<
  GetWorkspaceOnboardingAvailabilityQuery,
  GetWorkspaceOnboardingAvailabilityQueryVariables
>
export const GetOnboardingCallAvailabilityDocument = gql`
  query GetOnboardingCallAvailability(
    $specialistId: ID
    $assistantId: ID!
    $timeZone: String
  ) {
    onboardingCallAvailability(
      specialistId: $specialistId
      timeZone: $timeZone
      assistantId: $assistantId
    ) {
      ...OnboardingAvailability
    }
  }
  ${OnboardingAvailabilityFragmentDoc}
`

/**
 * __useGetOnboardingCallAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetOnboardingCallAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingCallAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingCallAvailabilityQuery({
 *   variables: {
 *      specialistId: // value for 'specialistId'
 *      assistantId: // value for 'assistantId'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetOnboardingCallAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnboardingCallAvailabilityQuery,
    GetOnboardingCallAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOnboardingCallAvailabilityQuery,
    GetOnboardingCallAvailabilityQueryVariables
  >(GetOnboardingCallAvailabilityDocument, options)
}
export function useGetOnboardingCallAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingCallAvailabilityQuery,
    GetOnboardingCallAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOnboardingCallAvailabilityQuery,
    GetOnboardingCallAvailabilityQueryVariables
  >(GetOnboardingCallAvailabilityDocument, options)
}
export type GetOnboardingCallAvailabilityQueryHookResult = ReturnType<
  typeof useGetOnboardingCallAvailabilityQuery
>
export type GetOnboardingCallAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingCallAvailabilityLazyQuery
>
export type GetOnboardingCallAvailabilityQueryResult = Apollo.QueryResult<
  GetOnboardingCallAvailabilityQuery,
  GetOnboardingCallAvailabilityQueryVariables
>
export const AssignOnboardingSpecialistDocument = gql`
  mutation AssignOnboardingSpecialist(
    $input: AssignOnboardingSpecialistInput!
  ) {
    assignOnboardingSpecialist(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_Specialist
        }
      }
    }
  }
  ${Onboarding_SpecialistFragmentDoc}
`
export type AssignOnboardingSpecialistMutationFn = Apollo.MutationFunction<
  AssignOnboardingSpecialistMutation,
  AssignOnboardingSpecialistMutationVariables
>

/**
 * __useAssignOnboardingSpecialistMutation__
 *
 * To run a mutation, you first call `useAssignOnboardingSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignOnboardingSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignOnboardingSpecialistMutation, { data, loading, error }] = useAssignOnboardingSpecialistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignOnboardingSpecialistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignOnboardingSpecialistMutation,
    AssignOnboardingSpecialistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AssignOnboardingSpecialistMutation,
    AssignOnboardingSpecialistMutationVariables
  >(AssignOnboardingSpecialistDocument, options)
}
export type AssignOnboardingSpecialistMutationHookResult = ReturnType<
  typeof useAssignOnboardingSpecialistMutation
>
export type AssignOnboardingSpecialistMutationResult =
  Apollo.MutationResult<AssignOnboardingSpecialistMutation>
export type AssignOnboardingSpecialistMutationOptions =
  Apollo.BaseMutationOptions<
    AssignOnboardingSpecialistMutation,
    AssignOnboardingSpecialistMutationVariables
  >
export const RemoveOnboardingSpecialistDocument = gql`
  mutation RemoveOnboardingSpecialist(
    $input: RemoveOnboardingSpecialistInput!
  ) {
    removeOnboardingSpecialist(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_Specialist
        }
      }
    }
  }
  ${Onboarding_SpecialistFragmentDoc}
`
export type RemoveOnboardingSpecialistMutationFn = Apollo.MutationFunction<
  RemoveOnboardingSpecialistMutation,
  RemoveOnboardingSpecialistMutationVariables
>

/**
 * __useRemoveOnboardingSpecialistMutation__
 *
 * To run a mutation, you first call `useRemoveOnboardingSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOnboardingSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOnboardingSpecialistMutation, { data, loading, error }] = useRemoveOnboardingSpecialistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOnboardingSpecialistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOnboardingSpecialistMutation,
    RemoveOnboardingSpecialistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveOnboardingSpecialistMutation,
    RemoveOnboardingSpecialistMutationVariables
  >(RemoveOnboardingSpecialistDocument, options)
}
export type RemoveOnboardingSpecialistMutationHookResult = ReturnType<
  typeof useRemoveOnboardingSpecialistMutation
>
export type RemoveOnboardingSpecialistMutationResult =
  Apollo.MutationResult<RemoveOnboardingSpecialistMutation>
export type RemoveOnboardingSpecialistMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveOnboardingSpecialistMutation,
    RemoveOnboardingSpecialistMutationVariables
  >
export const UpdateOnboardingStepDocument = gql`
  mutation UpdateOnboardingStep($input: UpdateOnboardingStepInput!) {
    updateOnboardingStep(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_Steps
        }
      }
    }
  }
  ${Onboarding_StepsFragmentDoc}
`
export type UpdateOnboardingStepMutationFn = Apollo.MutationFunction<
  UpdateOnboardingStepMutation,
  UpdateOnboardingStepMutationVariables
>

/**
 * __useUpdateOnboardingStepMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStepMutation, { data, loading, error }] = useUpdateOnboardingStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingStepMutation,
    UpdateOnboardingStepMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOnboardingStepMutation,
    UpdateOnboardingStepMutationVariables
  >(UpdateOnboardingStepDocument, options)
}
export type UpdateOnboardingStepMutationHookResult = ReturnType<
  typeof useUpdateOnboardingStepMutation
>
export type UpdateOnboardingStepMutationResult =
  Apollo.MutationResult<UpdateOnboardingStepMutation>
export type UpdateOnboardingStepMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingStepMutation,
  UpdateOnboardingStepMutationVariables
>
export const ScheduleOnboardingCallDocument = gql`
  mutation ScheduleOnboardingCall($input: ScheduleOnboardingCallInput!) {
    scheduleOnboardingCall(input: $input) {
      workspace {
        ...WorkspaceWithOnboarding
      }
    }
  }
  ${WorkspaceWithOnboardingFragmentDoc}
`
export type ScheduleOnboardingCallMutationFn = Apollo.MutationFunction<
  ScheduleOnboardingCallMutation,
  ScheduleOnboardingCallMutationVariables
>

/**
 * __useScheduleOnboardingCallMutation__
 *
 * To run a mutation, you first call `useScheduleOnboardingCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleOnboardingCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleOnboardingCallMutation, { data, loading, error }] = useScheduleOnboardingCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleOnboardingCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleOnboardingCallMutation,
    ScheduleOnboardingCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ScheduleOnboardingCallMutation,
    ScheduleOnboardingCallMutationVariables
  >(ScheduleOnboardingCallDocument, options)
}
export type ScheduleOnboardingCallMutationHookResult = ReturnType<
  typeof useScheduleOnboardingCallMutation
>
export type ScheduleOnboardingCallMutationResult =
  Apollo.MutationResult<ScheduleOnboardingCallMutation>
export type ScheduleOnboardingCallMutationOptions = Apollo.BaseMutationOptions<
  ScheduleOnboardingCallMutation,
  ScheduleOnboardingCallMutationVariables
>
export const CompleteOnboardingDocument = gql`
  mutation CompleteOnboarding($input: CompleteOnboardingInput!) {
    completeOnboarding(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_Completion
        }
      }
    }
  }
  ${Onboarding_CompletionFragmentDoc}
`
export type CompleteOnboardingMutationFn = Apollo.MutationFunction<
  CompleteOnboardingMutation,
  CompleteOnboardingMutationVariables
>

/**
 * __useCompleteOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingMutation, { data, loading, error }] = useCompleteOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteOnboardingMutation,
    CompleteOnboardingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompleteOnboardingMutation,
    CompleteOnboardingMutationVariables
  >(CompleteOnboardingDocument, options)
}
export type CompleteOnboardingMutationHookResult = ReturnType<
  typeof useCompleteOnboardingMutation
>
export type CompleteOnboardingMutationResult =
  Apollo.MutationResult<CompleteOnboardingMutation>
export type CompleteOnboardingMutationOptions = Apollo.BaseMutationOptions<
  CompleteOnboardingMutation,
  CompleteOnboardingMutationVariables
>
export const ResetOnboardingDocument = gql`
  mutation ResetOnboarding($input: ResetOnboardingInput!) {
    resetOnboarding(input: $input) {
      workspace {
        ...WorkspaceWithOnboarding
      }
    }
  }
  ${WorkspaceWithOnboardingFragmentDoc}
`
export type ResetOnboardingMutationFn = Apollo.MutationFunction<
  ResetOnboardingMutation,
  ResetOnboardingMutationVariables
>

/**
 * __useResetOnboardingMutation__
 *
 * To run a mutation, you first call `useResetOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetOnboardingMutation, { data, loading, error }] = useResetOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetOnboardingMutation,
    ResetOnboardingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetOnboardingMutation,
    ResetOnboardingMutationVariables
  >(ResetOnboardingDocument, options)
}
export type ResetOnboardingMutationHookResult = ReturnType<
  typeof useResetOnboardingMutation
>
export type ResetOnboardingMutationResult =
  Apollo.MutationResult<ResetOnboardingMutation>
export type ResetOnboardingMutationOptions = Apollo.BaseMutationOptions<
  ResetOnboardingMutation,
  ResetOnboardingMutationVariables
>
export const CancelOnboardingCallDocument = gql`
  mutation CancelOnboardingCall($input: CancelOnboardingCallInput!) {
    cancelOnboardingCall(input: $input) {
      workspace {
        ...WorkspaceWithOnboarding
      }
    }
  }
  ${WorkspaceWithOnboardingFragmentDoc}
`
export type CancelOnboardingCallMutationFn = Apollo.MutationFunction<
  CancelOnboardingCallMutation,
  CancelOnboardingCallMutationVariables
>

/**
 * __useCancelOnboardingCallMutation__
 *
 * To run a mutation, you first call `useCancelOnboardingCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOnboardingCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOnboardingCallMutation, { data, loading, error }] = useCancelOnboardingCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOnboardingCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOnboardingCallMutation,
    CancelOnboardingCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelOnboardingCallMutation,
    CancelOnboardingCallMutationVariables
  >(CancelOnboardingCallDocument, options)
}
export type CancelOnboardingCallMutationHookResult = ReturnType<
  typeof useCancelOnboardingCallMutation
>
export type CancelOnboardingCallMutationResult =
  Apollo.MutationResult<CancelOnboardingCallMutation>
export type CancelOnboardingCallMutationOptions = Apollo.BaseMutationOptions<
  CancelOnboardingCallMutation,
  CancelOnboardingCallMutationVariables
>
export const SendOnboardingEmailDocument = gql`
  mutation SendOnboardingEmail($input: SendOnboardingEmailInput!) {
    sendOnboardingEmail(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_EmailStatus
        }
      }
    }
  }
  ${Onboarding_EmailStatusFragmentDoc}
`
export type SendOnboardingEmailMutationFn = Apollo.MutationFunction<
  SendOnboardingEmailMutation,
  SendOnboardingEmailMutationVariables
>

/**
 * __useSendOnboardingEmailMutation__
 *
 * To run a mutation, you first call `useSendOnboardingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOnboardingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOnboardingEmailMutation, { data, loading, error }] = useSendOnboardingEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOnboardingEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendOnboardingEmailMutation,
    SendOnboardingEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendOnboardingEmailMutation,
    SendOnboardingEmailMutationVariables
  >(SendOnboardingEmailDocument, options)
}
export type SendOnboardingEmailMutationHookResult = ReturnType<
  typeof useSendOnboardingEmailMutation
>
export type SendOnboardingEmailMutationResult =
  Apollo.MutationResult<SendOnboardingEmailMutation>
export type SendOnboardingEmailMutationOptions = Apollo.BaseMutationOptions<
  SendOnboardingEmailMutation,
  SendOnboardingEmailMutationVariables
>
export const SentOnboardingEmailDocument = gql`
  mutation SentOnboardingEmail($input: SentOnboardingEmailInput!) {
    sentOnboardingEmail(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_EmailStatus
        }
      }
    }
  }
  ${Onboarding_EmailStatusFragmentDoc}
`
export type SentOnboardingEmailMutationFn = Apollo.MutationFunction<
  SentOnboardingEmailMutation,
  SentOnboardingEmailMutationVariables
>

/**
 * __useSentOnboardingEmailMutation__
 *
 * To run a mutation, you first call `useSentOnboardingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentOnboardingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentOnboardingEmailMutation, { data, loading, error }] = useSentOnboardingEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSentOnboardingEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SentOnboardingEmailMutation,
    SentOnboardingEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SentOnboardingEmailMutation,
    SentOnboardingEmailMutationVariables
  >(SentOnboardingEmailDocument, options)
}
export type SentOnboardingEmailMutationHookResult = ReturnType<
  typeof useSentOnboardingEmailMutation
>
export type SentOnboardingEmailMutationResult =
  Apollo.MutationResult<SentOnboardingEmailMutation>
export type SentOnboardingEmailMutationOptions = Apollo.BaseMutationOptions<
  SentOnboardingEmailMutation,
  SentOnboardingEmailMutationVariables
>
export const SentPostOnboardingEmailDocument = gql`
  mutation SentPostOnboardingEmail($input: SentPostOnboardingEmailInput!) {
    sentPostOnboardingEmail(input: $input) {
      workspace {
        id
        onboarding {
          ...Onboarding_EmailStatus
        }
      }
    }
  }
  ${Onboarding_EmailStatusFragmentDoc}
`
export type SentPostOnboardingEmailMutationFn = Apollo.MutationFunction<
  SentPostOnboardingEmailMutation,
  SentPostOnboardingEmailMutationVariables
>

/**
 * __useSentPostOnboardingEmailMutation__
 *
 * To run a mutation, you first call `useSentPostOnboardingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentPostOnboardingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentPostOnboardingEmailMutation, { data, loading, error }] = useSentPostOnboardingEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSentPostOnboardingEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SentPostOnboardingEmailMutation,
    SentPostOnboardingEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SentPostOnboardingEmailMutation,
    SentPostOnboardingEmailMutationVariables
  >(SentPostOnboardingEmailDocument, options)
}
export type SentPostOnboardingEmailMutationHookResult = ReturnType<
  typeof useSentPostOnboardingEmailMutation
>
export type SentPostOnboardingEmailMutationResult =
  Apollo.MutationResult<SentPostOnboardingEmailMutation>
export type SentPostOnboardingEmailMutationOptions = Apollo.BaseMutationOptions<
  SentPostOnboardingEmailMutation,
  SentPostOnboardingEmailMutationVariables
>
export const CompleteTransitionDocument = gql`
  mutation CompleteTransition($input: CompleteTransitionInput!) {
    completeTransition(input: $input) {
      workspace {
        id
        isPendingTransition
      }
    }
  }
`
export type CompleteTransitionMutationFn = Apollo.MutationFunction<
  CompleteTransitionMutation,
  CompleteTransitionMutationVariables
>

/**
 * __useCompleteTransitionMutation__
 *
 * To run a mutation, you first call `useCompleteTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTransitionMutation, { data, loading, error }] = useCompleteTransitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteTransitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTransitionMutation,
    CompleteTransitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompleteTransitionMutation,
    CompleteTransitionMutationVariables
  >(CompleteTransitionDocument, options)
}
export type CompleteTransitionMutationHookResult = ReturnType<
  typeof useCompleteTransitionMutation
>
export type CompleteTransitionMutationResult =
  Apollo.MutationResult<CompleteTransitionMutation>
export type CompleteTransitionMutationOptions = Apollo.BaseMutationOptions<
  CompleteTransitionMutation,
  CompleteTransitionMutationVariables
>
export const ListFeebacksDocument = gql`
  query ListFeebacks(
    $workspaceId: ID
    $assistantId: ID
    $minRating: PositiveInt
    $maxRating: PositiveInt
    $createdAfter: DateTime
    $createdBefore: DateTime
  ) {
    list: feedbacksList(
      workspaceId: $workspaceId
      assistantId: $assistantId
      minRating: $minRating
      maxRating: $maxRating
      createdAfter: $createdAfter
      createdBefore: $createdBefore
    ) {
      items {
        ...CsatFeedback
        workspace {
          ...Workspace_Users
        }
      }
    }
  }
  ${CsatFeedbackFragmentDoc}
  ${Workspace_UsersFragmentDoc}
`

/**
 * __useListFeebacksQuery__
 *
 * To run a query within a React component, call `useListFeebacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFeebacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFeebacksQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      assistantId: // value for 'assistantId'
 *      minRating: // value for 'minRating'
 *      maxRating: // value for 'maxRating'
 *      createdAfter: // value for 'createdAfter'
 *      createdBefore: // value for 'createdBefore'
 *   },
 * });
 */
export function useListFeebacksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFeebacksQuery,
    ListFeebacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListFeebacksQuery, ListFeebacksQueryVariables>(
    ListFeebacksDocument,
    options,
  )
}
export function useListFeebacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFeebacksQuery,
    ListFeebacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListFeebacksQuery, ListFeebacksQueryVariables>(
    ListFeebacksDocument,
    options,
  )
}
export type ListFeebacksQueryHookResult = ReturnType<
  typeof useListFeebacksQuery
>
export type ListFeebacksLazyQueryHookResult = ReturnType<
  typeof useListFeebacksLazyQuery
>
export type ListFeebacksQueryResult = Apollo.QueryResult<
  ListFeebacksQuery,
  ListFeebacksQueryVariables
>
export const ListWorkspaceFeebacksDocument = gql`
  query ListWorkspaceFeebacks($workspaceId: ID!) {
    list: feedbacksList(workspaceId: $workspaceId) {
      items {
        ...CsatFeedback
      }
    }
    workspace: workspaceById(id: $workspaceId) {
      ...Workspace_Settings
    }
  }
  ${CsatFeedbackFragmentDoc}
  ${Workspace_SettingsFragmentDoc}
`

/**
 * __useListWorkspaceFeebacksQuery__
 *
 * To run a query within a React component, call `useListWorkspaceFeebacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkspaceFeebacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkspaceFeebacksQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useListWorkspaceFeebacksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListWorkspaceFeebacksQuery,
    ListWorkspaceFeebacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListWorkspaceFeebacksQuery,
    ListWorkspaceFeebacksQueryVariables
  >(ListWorkspaceFeebacksDocument, options)
}
export function useListWorkspaceFeebacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListWorkspaceFeebacksQuery,
    ListWorkspaceFeebacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListWorkspaceFeebacksQuery,
    ListWorkspaceFeebacksQueryVariables
  >(ListWorkspaceFeebacksDocument, options)
}
export type ListWorkspaceFeebacksQueryHookResult = ReturnType<
  typeof useListWorkspaceFeebacksQuery
>
export type ListWorkspaceFeebacksLazyQueryHookResult = ReturnType<
  typeof useListWorkspaceFeebacksLazyQuery
>
export type ListWorkspaceFeebacksQueryResult = Apollo.QueryResult<
  ListWorkspaceFeebacksQuery,
  ListWorkspaceFeebacksQueryVariables
>
export const UpdateCsatFeedbackDocument = gql`
  mutation UpdateCsatFeedback($input: UpdateCsatFeedbackInput!) {
    updateCsatFeedback(input: $input) {
      feedback {
        ...CsatFeedback
      }
    }
  }
  ${CsatFeedbackFragmentDoc}
`
export type UpdateCsatFeedbackMutationFn = Apollo.MutationFunction<
  UpdateCsatFeedbackMutation,
  UpdateCsatFeedbackMutationVariables
>

/**
 * __useUpdateCsatFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateCsatFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCsatFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCsatFeedbackMutation, { data, loading, error }] = useUpdateCsatFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCsatFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCsatFeedbackMutation,
    UpdateCsatFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCsatFeedbackMutation,
    UpdateCsatFeedbackMutationVariables
  >(UpdateCsatFeedbackDocument, options)
}
export type UpdateCsatFeedbackMutationHookResult = ReturnType<
  typeof useUpdateCsatFeedbackMutation
>
export type UpdateCsatFeedbackMutationResult =
  Apollo.MutationResult<UpdateCsatFeedbackMutation>
export type UpdateCsatFeedbackMutationOptions = Apollo.BaseMutationOptions<
  UpdateCsatFeedbackMutation,
  UpdateCsatFeedbackMutationVariables
>
export const DeleteFeedbackDocument = gql`
  mutation DeleteFeedback($input: DeleteFeedbackInput!) {
    deleteFeedback(input: $input) {
      feedback {
        id
      }
    }
  }
`
export type DeleteFeedbackMutationFn = Apollo.MutationFunction<
  DeleteFeedbackMutation,
  DeleteFeedbackMutationVariables
>

/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackMutation, { data, loading, error }] = useDeleteFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFeedbackMutation,
    DeleteFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteFeedbackMutation,
    DeleteFeedbackMutationVariables
  >(DeleteFeedbackDocument, options)
}
export type DeleteFeedbackMutationHookResult = ReturnType<
  typeof useDeleteFeedbackMutation
>
export type DeleteFeedbackMutationResult =
  Apollo.MutationResult<DeleteFeedbackMutation>
export type DeleteFeedbackMutationOptions = Apollo.BaseMutationOptions<
  DeleteFeedbackMutation,
  DeleteFeedbackMutationVariables
>
export const ListMatcherAssistantsDocument = gql`
  query ListMatcherAssistants(
    $after: String
    $first: PositiveInt
    $countryCodes: [String!]
  ) {
    list: usersList(
      after: $after
      first: $first
      categories: [ASSISTANT]
      isSaas: false
      countryCodes: $countryCodes
    ) {
      after
      items {
        ...MatcherAssistant
      }
    }
  }
  ${MatcherAssistantFragmentDoc}
`

/**
 * __useListMatcherAssistantsQuery__
 *
 * To run a query within a React component, call `useListMatcherAssistantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMatcherAssistantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMatcherAssistantsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      countryCodes: // value for 'countryCodes'
 *   },
 * });
 */
export function useListMatcherAssistantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMatcherAssistantsQuery,
    ListMatcherAssistantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListMatcherAssistantsQuery,
    ListMatcherAssistantsQueryVariables
  >(ListMatcherAssistantsDocument, options)
}
export function useListMatcherAssistantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMatcherAssistantsQuery,
    ListMatcherAssistantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListMatcherAssistantsQuery,
    ListMatcherAssistantsQueryVariables
  >(ListMatcherAssistantsDocument, options)
}
export type ListMatcherAssistantsQueryHookResult = ReturnType<
  typeof useListMatcherAssistantsQuery
>
export type ListMatcherAssistantsLazyQueryHookResult = ReturnType<
  typeof useListMatcherAssistantsLazyQuery
>
export type ListMatcherAssistantsQueryResult = Apollo.QueryResult<
  ListMatcherAssistantsQuery,
  ListMatcherAssistantsQueryVariables
>
export const GetSettingByIdDocument = gql`
  query GetSettingById($id: ID!) {
    settingById(id: $id) {
      ...Setting
    }
  }
  ${SettingFragmentDoc}
`

/**
 * __useGetSettingByIdQuery__
 *
 * To run a query within a React component, call `useGetSettingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSettingByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSettingByIdQuery,
    GetSettingByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSettingByIdQuery, GetSettingByIdQueryVariables>(
    GetSettingByIdDocument,
    options,
  )
}
export function useGetSettingByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSettingByIdQuery,
    GetSettingByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSettingByIdQuery, GetSettingByIdQueryVariables>(
    GetSettingByIdDocument,
    options,
  )
}
export type GetSettingByIdQueryHookResult = ReturnType<
  typeof useGetSettingByIdQuery
>
export type GetSettingByIdLazyQueryHookResult = ReturnType<
  typeof useGetSettingByIdLazyQuery
>
export type GetSettingByIdQueryResult = Apollo.QueryResult<
  GetSettingByIdQuery,
  GetSettingByIdQueryVariables
>
export const UpsertSettingDocument = gql`
  mutation UpsertSetting($input: UpsertSettingInput!) {
    upsertSetting(input: $input) {
      setting {
        ...Setting
      }
    }
  }
  ${SettingFragmentDoc}
`
export type UpsertSettingMutationFn = Apollo.MutationFunction<
  UpsertSettingMutation,
  UpsertSettingMutationVariables
>

/**
 * __useUpsertSettingMutation__
 *
 * To run a mutation, you first call `useUpsertSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSettingMutation, { data, loading, error }] = useUpsertSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertSettingMutation,
    UpsertSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertSettingMutation,
    UpsertSettingMutationVariables
  >(UpsertSettingDocument, options)
}
export type UpsertSettingMutationHookResult = ReturnType<
  typeof useUpsertSettingMutation
>
export type UpsertSettingMutationResult =
  Apollo.MutationResult<UpsertSettingMutation>
export type UpsertSettingMutationOptions = Apollo.BaseMutationOptions<
  UpsertSettingMutation,
  UpsertSettingMutationVariables
>
export const GetCityByIdDocument = gql`
  query GetCityById($placeId: ID!) {
    city: placeByGoogleMapsPlaceId(googleMapsPlaceId: $placeId) {
      ...City
    }
  }
  ${CityFragmentDoc}
`

/**
 * __useGetCityByIdQuery__
 *
 * To run a query within a React component, call `useGetCityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityByIdQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetCityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCityByIdQuery,
    GetCityByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCityByIdQuery, GetCityByIdQueryVariables>(
    GetCityByIdDocument,
    options,
  )
}
export function useGetCityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCityByIdQuery,
    GetCityByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCityByIdQuery, GetCityByIdQueryVariables>(
    GetCityByIdDocument,
    options,
  )
}
export type GetCityByIdQueryHookResult = ReturnType<typeof useGetCityByIdQuery>
export type GetCityByIdLazyQueryHookResult = ReturnType<
  typeof useGetCityByIdLazyQuery
>
export type GetCityByIdQueryResult = Apollo.QueryResult<
  GetCityByIdQuery,
  GetCityByIdQueryVariables
>
export const ListInvoicingReportsDocument = gql`
  query ListInvoicingReports(
    $from: DateTime!
    $to: DateTime!
    $invoicedPricingDate: DateTime!
  ) {
    list: invoicingReportsList(from: $from, to: $to) {
      items {
        ...InvoicingReport
      }
    }
  }
  ${InvoicingReportFragmentDoc}
`

/**
 * __useListInvoicingReportsQuery__
 *
 * To run a query within a React component, call `useListInvoicingReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicingReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicingReportsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      invoicedPricingDate: // value for 'invoicedPricingDate'
 *   },
 * });
 */
export function useListInvoicingReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListInvoicingReportsQuery,
    ListInvoicingReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListInvoicingReportsQuery,
    ListInvoicingReportsQueryVariables
  >(ListInvoicingReportsDocument, options)
}
export function useListInvoicingReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListInvoicingReportsQuery,
    ListInvoicingReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListInvoicingReportsQuery,
    ListInvoicingReportsQueryVariables
  >(ListInvoicingReportsDocument, options)
}
export type ListInvoicingReportsQueryHookResult = ReturnType<
  typeof useListInvoicingReportsQuery
>
export type ListInvoicingReportsLazyQueryHookResult = ReturnType<
  typeof useListInvoicingReportsLazyQuery
>
export type ListInvoicingReportsQueryResult = Apollo.QueryResult<
  ListInvoicingReportsQuery,
  ListInvoicingReportsQueryVariables
>
export const GetInvoicingReportUserStripeStatusDocument = gql`
  query GetInvoicingReportUserStripeStatus(
    $userId: ID!
    $invoiceStatus: [StripeInvoiceStatus!]
    $invoicesFirst: NonNegativeInt
    $invoicesCreatedAfter: DateTime
    $invoicesCreatedBefore: DateTime
  ) {
    user: userById(id: $userId) {
      id
      ... on Executivable {
        stripeCustomer {
          ...StripeCustomer_PaymentStatus
          ...StripeCustomer_Invoices
        }
      }
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
  ${StripeCustomer_InvoicesFragmentDoc}
`

/**
 * __useGetInvoicingReportUserStripeStatusQuery__
 *
 * To run a query within a React component, call `useGetInvoicingReportUserStripeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicingReportUserStripeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicingReportUserStripeStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      invoiceStatus: // value for 'invoiceStatus'
 *      invoicesFirst: // value for 'invoicesFirst'
 *      invoicesCreatedAfter: // value for 'invoicesCreatedAfter'
 *      invoicesCreatedBefore: // value for 'invoicesCreatedBefore'
 *   },
 * });
 */
export function useGetInvoicingReportUserStripeStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoicingReportUserStripeStatusQuery,
    GetInvoicingReportUserStripeStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvoicingReportUserStripeStatusQuery,
    GetInvoicingReportUserStripeStatusQueryVariables
  >(GetInvoicingReportUserStripeStatusDocument, options)
}
export function useGetInvoicingReportUserStripeStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicingReportUserStripeStatusQuery,
    GetInvoicingReportUserStripeStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvoicingReportUserStripeStatusQuery,
    GetInvoicingReportUserStripeStatusQueryVariables
  >(GetInvoicingReportUserStripeStatusDocument, options)
}
export type GetInvoicingReportUserStripeStatusQueryHookResult = ReturnType<
  typeof useGetInvoicingReportUserStripeStatusQuery
>
export type GetInvoicingReportUserStripeStatusLazyQueryHookResult = ReturnType<
  typeof useGetInvoicingReportUserStripeStatusLazyQuery
>
export type GetInvoicingReportUserStripeStatusQueryResult = Apollo.QueryResult<
  GetInvoicingReportUserStripeStatusQuery,
  GetInvoicingReportUserStripeStatusQueryVariables
>
export const CreateStripeInvoiceDocument = gql`
  mutation CreateStripeInvoice(
    $input: CreateStripeInvoiceInput!
    $invoiceStatus: [StripeInvoiceStatus!]
    $invoicesFirst: NonNegativeInt
    $invoicesCreatedAfter: DateTime
    $invoicesCreatedBefore: DateTime
  ) {
    createStripeInvoice(input: $input) {
      customer {
        ...StripeCustomer_PaymentStatus
        ...StripeCustomer_Invoices
      }
      invoice {
        ...StripeInvoice
      }
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
  ${StripeCustomer_InvoicesFragmentDoc}
  ${StripeInvoiceFragmentDoc}
`
export type CreateStripeInvoiceMutationFn = Apollo.MutationFunction<
  CreateStripeInvoiceMutation,
  CreateStripeInvoiceMutationVariables
>

/**
 * __useCreateStripeInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateStripeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeInvoiceMutation, { data, loading, error }] = useCreateStripeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceStatus: // value for 'invoiceStatus'
 *      invoicesFirst: // value for 'invoicesFirst'
 *      invoicesCreatedAfter: // value for 'invoicesCreatedAfter'
 *      invoicesCreatedBefore: // value for 'invoicesCreatedBefore'
 *   },
 * });
 */
export function useCreateStripeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeInvoiceMutation,
    CreateStripeInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateStripeInvoiceMutation,
    CreateStripeInvoiceMutationVariables
  >(CreateStripeInvoiceDocument, options)
}
export type CreateStripeInvoiceMutationHookResult = ReturnType<
  typeof useCreateStripeInvoiceMutation
>
export type CreateStripeInvoiceMutationResult =
  Apollo.MutationResult<CreateStripeInvoiceMutation>
export type CreateStripeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeInvoiceMutation,
  CreateStripeInvoiceMutationVariables
>
export const VoidStripeInvoiceDocument = gql`
  mutation VoidStripeInvoice(
    $input: VoidStripeInvoiceInput!
    $invoiceStatus: [StripeInvoiceStatus!]
    $invoicesFirst: NonNegativeInt
    $invoicesCreatedAfter: DateTime
    $invoicesCreatedBefore: DateTime
  ) {
    voidStripeInvoice(input: $input) {
      customer {
        ...StripeCustomer_PaymentStatus
        ...StripeCustomer_Invoices
      }
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
  ${StripeCustomer_InvoicesFragmentDoc}
`
export type VoidStripeInvoiceMutationFn = Apollo.MutationFunction<
  VoidStripeInvoiceMutation,
  VoidStripeInvoiceMutationVariables
>

/**
 * __useVoidStripeInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidStripeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidStripeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidStripeInvoiceMutation, { data, loading, error }] = useVoidStripeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceStatus: // value for 'invoiceStatus'
 *      invoicesFirst: // value for 'invoicesFirst'
 *      invoicesCreatedAfter: // value for 'invoicesCreatedAfter'
 *      invoicesCreatedBefore: // value for 'invoicesCreatedBefore'
 *   },
 * });
 */
export function useVoidStripeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidStripeInvoiceMutation,
    VoidStripeInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VoidStripeInvoiceMutation,
    VoidStripeInvoiceMutationVariables
  >(VoidStripeInvoiceDocument, options)
}
export type VoidStripeInvoiceMutationHookResult = ReturnType<
  typeof useVoidStripeInvoiceMutation
>
export type VoidStripeInvoiceMutationResult =
  Apollo.MutationResult<VoidStripeInvoiceMutation>
export type VoidStripeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  VoidStripeInvoiceMutation,
  VoidStripeInvoiceMutationVariables
>
export const PayStripeInvoiceDocument = gql`
  mutation PayStripeInvoice(
    $input: PayStripeInvoiceInput!
    $invoiceStatus: [StripeInvoiceStatus!]
    $invoicesFirst: NonNegativeInt
    $invoicesCreatedAfter: DateTime
    $invoicesCreatedBefore: DateTime
  ) {
    payStripeInvoice(input: $input) {
      isSuccess
      customer {
        ...StripeCustomer_PaymentStatus
        ...StripeCustomer_Invoices
      }
      invoice {
        ...StripeInvoice
      }
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
  ${StripeCustomer_InvoicesFragmentDoc}
  ${StripeInvoiceFragmentDoc}
`
export type PayStripeInvoiceMutationFn = Apollo.MutationFunction<
  PayStripeInvoiceMutation,
  PayStripeInvoiceMutationVariables
>

/**
 * __usePayStripeInvoiceMutation__
 *
 * To run a mutation, you first call `usePayStripeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayStripeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payStripeInvoiceMutation, { data, loading, error }] = usePayStripeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceStatus: // value for 'invoiceStatus'
 *      invoicesFirst: // value for 'invoicesFirst'
 *      invoicesCreatedAfter: // value for 'invoicesCreatedAfter'
 *      invoicesCreatedBefore: // value for 'invoicesCreatedBefore'
 *   },
 * });
 */
export function usePayStripeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayStripeInvoiceMutation,
    PayStripeInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PayStripeInvoiceMutation,
    PayStripeInvoiceMutationVariables
  >(PayStripeInvoiceDocument, options)
}
export type PayStripeInvoiceMutationHookResult = ReturnType<
  typeof usePayStripeInvoiceMutation
>
export type PayStripeInvoiceMutationResult =
  Apollo.MutationResult<PayStripeInvoiceMutation>
export type PayStripeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  PayStripeInvoiceMutation,
  PayStripeInvoiceMutationVariables
>
export const SendStripeInvoiceDocument = gql`
  mutation SendStripeInvoice(
    $input: SendStripeInvoiceInput!
    $invoiceStatus: [StripeInvoiceStatus!]
    $invoicesFirst: NonNegativeInt
    $invoicesCreatedAfter: DateTime
    $invoicesCreatedBefore: DateTime
  ) {
    sendStripeInvoice(input: $input) {
      customer {
        ...StripeCustomer_PaymentStatus
        ...StripeCustomer_Invoices
      }
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
  ${StripeCustomer_InvoicesFragmentDoc}
`
export type SendStripeInvoiceMutationFn = Apollo.MutationFunction<
  SendStripeInvoiceMutation,
  SendStripeInvoiceMutationVariables
>

/**
 * __useSendStripeInvoiceMutation__
 *
 * To run a mutation, you first call `useSendStripeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendStripeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendStripeInvoiceMutation, { data, loading, error }] = useSendStripeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      invoiceStatus: // value for 'invoiceStatus'
 *      invoicesFirst: // value for 'invoicesFirst'
 *      invoicesCreatedAfter: // value for 'invoicesCreatedAfter'
 *      invoicesCreatedBefore: // value for 'invoicesCreatedBefore'
 *   },
 * });
 */
export function useSendStripeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendStripeInvoiceMutation,
    SendStripeInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendStripeInvoiceMutation,
    SendStripeInvoiceMutationVariables
  >(SendStripeInvoiceDocument, options)
}
export type SendStripeInvoiceMutationHookResult = ReturnType<
  typeof useSendStripeInvoiceMutation
>
export type SendStripeInvoiceMutationResult =
  Apollo.MutationResult<SendStripeInvoiceMutation>
export type SendStripeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  SendStripeInvoiceMutation,
  SendStripeInvoiceMutationVariables
>
export const ListContractorReportsDocument = gql`
  query ListContractorReports($from: DateTime!, $to: DateTime!) {
    list: contractorReportsList(from: $from, to: $to) {
      items {
        ...ContractorReport
      }
    }
  }
  ${ContractorReportFragmentDoc}
`

/**
 * __useListContractorReportsQuery__
 *
 * To run a query within a React component, call `useListContractorReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContractorReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContractorReportsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useListContractorReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListContractorReportsQuery,
    ListContractorReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListContractorReportsQuery,
    ListContractorReportsQueryVariables
  >(ListContractorReportsDocument, options)
}
export function useListContractorReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListContractorReportsQuery,
    ListContractorReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListContractorReportsQuery,
    ListContractorReportsQueryVariables
  >(ListContractorReportsDocument, options)
}
export type ListContractorReportsQueryHookResult = ReturnType<
  typeof useListContractorReportsQuery
>
export type ListContractorReportsLazyQueryHookResult = ReturnType<
  typeof useListContractorReportsLazyQuery
>
export type ListContractorReportsQueryResult = Apollo.QueryResult<
  ListContractorReportsQuery,
  ListContractorReportsQueryVariables
>
export const GetPayPeriodDocument = gql`
  query GetPayPeriod {
    payPeriod {
      ...PayPeriod
    }
  }
  ${PayPeriodFragmentDoc}
`

/**
 * __useGetPayPeriodQuery__
 *
 * To run a query within a React component, call `useGetPayPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayPeriodQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPayPeriodQuery,
    GetPayPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayPeriodQuery, GetPayPeriodQueryVariables>(
    GetPayPeriodDocument,
    options,
  )
}
export function useGetPayPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayPeriodQuery,
    GetPayPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayPeriodQuery, GetPayPeriodQueryVariables>(
    GetPayPeriodDocument,
    options,
  )
}
export type GetPayPeriodQueryHookResult = ReturnType<
  typeof useGetPayPeriodQuery
>
export type GetPayPeriodLazyQueryHookResult = ReturnType<
  typeof useGetPayPeriodLazyQuery
>
export type GetPayPeriodQueryResult = Apollo.QueryResult<
  GetPayPeriodQuery,
  GetPayPeriodQueryVariables
>
export const UpdatePayPeriodDocument = gql`
  mutation UpdatePayPeriod($input: UpdatePayPeriodInput!) {
    updatePayPeriod(input: $input) {
      payPeriod {
        ...PayPeriod
      }
    }
  }
  ${PayPeriodFragmentDoc}
`
export type UpdatePayPeriodMutationFn = Apollo.MutationFunction<
  UpdatePayPeriodMutation,
  UpdatePayPeriodMutationVariables
>

/**
 * __useUpdatePayPeriodMutation__
 *
 * To run a mutation, you first call `useUpdatePayPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayPeriodMutation, { data, loading, error }] = useUpdatePayPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayPeriodMutation,
    UpdatePayPeriodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayPeriodMutation,
    UpdatePayPeriodMutationVariables
  >(UpdatePayPeriodDocument, options)
}
export type UpdatePayPeriodMutationHookResult = ReturnType<
  typeof useUpdatePayPeriodMutation
>
export type UpdatePayPeriodMutationResult =
  Apollo.MutationResult<UpdatePayPeriodMutation>
export type UpdatePayPeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayPeriodMutation,
  UpdatePayPeriodMutationVariables
>
export const SendContractorsPaymentsEmailsDocument = gql`
  mutation SendContractorsPaymentsEmails(
    $input: SendContractorsPaymentsEmailsInput!
  ) {
    sendContractorsPaymentsEmails(input: $input) {
      isSuccess
    }
  }
`
export type SendContractorsPaymentsEmailsMutationFn = Apollo.MutationFunction<
  SendContractorsPaymentsEmailsMutation,
  SendContractorsPaymentsEmailsMutationVariables
>

/**
 * __useSendContractorsPaymentsEmailsMutation__
 *
 * To run a mutation, you first call `useSendContractorsPaymentsEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractorsPaymentsEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractorsPaymentsEmailsMutation, { data, loading, error }] = useSendContractorsPaymentsEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendContractorsPaymentsEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContractorsPaymentsEmailsMutation,
    SendContractorsPaymentsEmailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendContractorsPaymentsEmailsMutation,
    SendContractorsPaymentsEmailsMutationVariables
  >(SendContractorsPaymentsEmailsDocument, options)
}
export type SendContractorsPaymentsEmailsMutationHookResult = ReturnType<
  typeof useSendContractorsPaymentsEmailsMutation
>
export type SendContractorsPaymentsEmailsMutationResult =
  Apollo.MutationResult<SendContractorsPaymentsEmailsMutation>
export type SendContractorsPaymentsEmailsMutationOptions =
  Apollo.BaseMutationOptions<
    SendContractorsPaymentsEmailsMutation,
    SendContractorsPaymentsEmailsMutationVariables
  >
export const UploadPublicFileDocument = gql`
  mutation UploadPublicFile($input: UploadPublicFileInput!) {
    upload: uploadPublicFile(input: $input) {
      signedUrl
      publicUrl
      filename
    }
  }
`
export type UploadPublicFileMutationFn = Apollo.MutationFunction<
  UploadPublicFileMutation,
  UploadPublicFileMutationVariables
>

/**
 * __useUploadPublicFileMutation__
 *
 * To run a mutation, you first call `useUploadPublicFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPublicFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPublicFileMutation, { data, loading, error }] = useUploadPublicFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPublicFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadPublicFileMutation,
    UploadPublicFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadPublicFileMutation,
    UploadPublicFileMutationVariables
  >(UploadPublicFileDocument, options)
}
export type UploadPublicFileMutationHookResult = ReturnType<
  typeof useUploadPublicFileMutation
>
export type UploadPublicFileMutationResult =
  Apollo.MutationResult<UploadPublicFileMutation>
export type UploadPublicFileMutationOptions = Apollo.BaseMutationOptions<
  UploadPublicFileMutation,
  UploadPublicFileMutationVariables
>
export const ListPricingsDocument = gql`
  query ListPricings {
    list: pricingsList @connection(key: "pricings") {
      items {
        ...Pricing
      }
    }
  }
  ${PricingFragmentDoc}
`

/**
 * __useListPricingsQuery__
 *
 * To run a query within a React component, call `useListPricingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPricingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPricingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPricingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListPricingsQuery,
    ListPricingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListPricingsQuery, ListPricingsQueryVariables>(
    ListPricingsDocument,
    options,
  )
}
export function useListPricingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPricingsQuery,
    ListPricingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListPricingsQuery, ListPricingsQueryVariables>(
    ListPricingsDocument,
    options,
  )
}
export type ListPricingsQueryHookResult = ReturnType<
  typeof useListPricingsQuery
>
export type ListPricingsLazyQueryHookResult = ReturnType<
  typeof useListPricingsLazyQuery
>
export type ListPricingsQueryResult = Apollo.QueryResult<
  ListPricingsQuery,
  ListPricingsQueryVariables
>
export const UpsertPricingDocument = gql`
  mutation UpsertPricing($input: UpsertPricingInput!) {
    upsertPricing(input: $input) {
      pricing {
        ...Pricing
      }
    }
  }
  ${PricingFragmentDoc}
`
export type UpsertPricingMutationFn = Apollo.MutationFunction<
  UpsertPricingMutation,
  UpsertPricingMutationVariables
>

/**
 * __useUpsertPricingMutation__
 *
 * To run a mutation, you first call `useUpsertPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPricingMutation, { data, loading, error }] = useUpsertPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPricingMutation,
    UpsertPricingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertPricingMutation,
    UpsertPricingMutationVariables
  >(UpsertPricingDocument, options)
}
export type UpsertPricingMutationHookResult = ReturnType<
  typeof useUpsertPricingMutation
>
export type UpsertPricingMutationResult =
  Apollo.MutationResult<UpsertPricingMutation>
export type UpsertPricingMutationOptions = Apollo.BaseMutationOptions<
  UpsertPricingMutation,
  UpsertPricingMutationVariables
>
export const SelectEntityPricingDocument = gql`
  mutation SelectEntityPricing($input: SelectEntityPricingInput!) {
    selectEntityPricing(input: $input) {
      entity {
        ...BillableEntity_PricingSelections
      }
    }
  }
  ${BillableEntity_PricingSelectionsFragmentDoc}
`
export type SelectEntityPricingMutationFn = Apollo.MutationFunction<
  SelectEntityPricingMutation,
  SelectEntityPricingMutationVariables
>

/**
 * __useSelectEntityPricingMutation__
 *
 * To run a mutation, you first call `useSelectEntityPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectEntityPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectEntityPricingMutation, { data, loading, error }] = useSelectEntityPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectEntityPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectEntityPricingMutation,
    SelectEntityPricingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SelectEntityPricingMutation,
    SelectEntityPricingMutationVariables
  >(SelectEntityPricingDocument, options)
}
export type SelectEntityPricingMutationHookResult = ReturnType<
  typeof useSelectEntityPricingMutation
>
export type SelectEntityPricingMutationResult =
  Apollo.MutationResult<SelectEntityPricingMutation>
export type SelectEntityPricingMutationOptions = Apollo.BaseMutationOptions<
  SelectEntityPricingMutation,
  SelectEntityPricingMutationVariables
>
export const CancelScheduledEntityPricingUpdateDocument = gql`
  mutation CancelScheduledEntityPricingUpdate(
    $input: CancelScheduledEntityPricingUpdateInput!
  ) {
    cancelScheduledEntityPricingUpdate(input: $input) {
      entity {
        ...BillableEntity_PricingSelections
      }
    }
  }
  ${BillableEntity_PricingSelectionsFragmentDoc}
`
export type CancelScheduledEntityPricingUpdateMutationFn =
  Apollo.MutationFunction<
    CancelScheduledEntityPricingUpdateMutation,
    CancelScheduledEntityPricingUpdateMutationVariables
  >

/**
 * __useCancelScheduledEntityPricingUpdateMutation__
 *
 * To run a mutation, you first call `useCancelScheduledEntityPricingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledEntityPricingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledEntityPricingUpdateMutation, { data, loading, error }] = useCancelScheduledEntityPricingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduledEntityPricingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduledEntityPricingUpdateMutation,
    CancelScheduledEntityPricingUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelScheduledEntityPricingUpdateMutation,
    CancelScheduledEntityPricingUpdateMutationVariables
  >(CancelScheduledEntityPricingUpdateDocument, options)
}
export type CancelScheduledEntityPricingUpdateMutationHookResult = ReturnType<
  typeof useCancelScheduledEntityPricingUpdateMutation
>
export type CancelScheduledEntityPricingUpdateMutationResult =
  Apollo.MutationResult<CancelScheduledEntityPricingUpdateMutation>
export type CancelScheduledEntityPricingUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CancelScheduledEntityPricingUpdateMutation,
    CancelScheduledEntityPricingUpdateMutationVariables
  >
export const ExpireUserPricingDocument = gql`
  mutation ExpireUserPricing($input: ExpireUserPricingInput!) {
    expireUserPricing(input: $input) {
      user {
        ...Executivable_PricingSelections
      }
    }
  }
  ${Executivable_PricingSelectionsFragmentDoc}
`
export type ExpireUserPricingMutationFn = Apollo.MutationFunction<
  ExpireUserPricingMutation,
  ExpireUserPricingMutationVariables
>

/**
 * __useExpireUserPricingMutation__
 *
 * To run a mutation, you first call `useExpireUserPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpireUserPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expireUserPricingMutation, { data, loading, error }] = useExpireUserPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpireUserPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExpireUserPricingMutation,
    ExpireUserPricingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExpireUserPricingMutation,
    ExpireUserPricingMutationVariables
  >(ExpireUserPricingDocument, options)
}
export type ExpireUserPricingMutationHookResult = ReturnType<
  typeof useExpireUserPricingMutation
>
export type ExpireUserPricingMutationResult =
  Apollo.MutationResult<ExpireUserPricingMutation>
export type ExpireUserPricingMutationOptions = Apollo.BaseMutationOptions<
  ExpireUserPricingMutation,
  ExpireUserPricingMutationVariables
>
export const ListBugReportsDocument = gql`
  query ListBugReports {
    list: bugReportsList @connection(key: "bugReports") {
      items {
        ...BugReport
      }
    }
  }
  ${BugReportFragmentDoc}
`

/**
 * __useListBugReportsQuery__
 *
 * To run a query within a React component, call `useListBugReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBugReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBugReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBugReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListBugReportsQuery,
    ListBugReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListBugReportsQuery, ListBugReportsQueryVariables>(
    ListBugReportsDocument,
    options,
  )
}
export function useListBugReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBugReportsQuery,
    ListBugReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListBugReportsQuery, ListBugReportsQueryVariables>(
    ListBugReportsDocument,
    options,
  )
}
export type ListBugReportsQueryHookResult = ReturnType<
  typeof useListBugReportsQuery
>
export type ListBugReportsLazyQueryHookResult = ReturnType<
  typeof useListBugReportsLazyQuery
>
export type ListBugReportsQueryResult = Apollo.QueryResult<
  ListBugReportsQuery,
  ListBugReportsQueryVariables
>
export const GetUserReportsDocument = gql`
  query GetUserReports(
    $userId: ID!
    $weeksCount: NonNegativeInt! = 16
    $monthsCount: NonNegativeInt! = 4
    $quartersCount: NonNegativeInt! = 0
    $until: DateTime
  ) {
    user: userById(id: $userId) {
      id
      ... on AssistantUser {
        reports(
          weeksCount: $weeksCount
          monthsCount: $monthsCount
          quartersCount: $quartersCount
          until: $until
        ) {
          ...WorkspaceReport
        }
      }
    }
  }
  ${WorkspaceReportFragmentDoc}
`

/**
 * __useGetUserReportsQuery__
 *
 * To run a query within a React component, call `useGetUserReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReportsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      weeksCount: // value for 'weeksCount'
 *      monthsCount: // value for 'monthsCount'
 *      quartersCount: // value for 'quartersCount'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useGetUserReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserReportsQuery,
    GetUserReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserReportsQuery, GetUserReportsQueryVariables>(
    GetUserReportsDocument,
    options,
  )
}
export function useGetUserReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserReportsQuery,
    GetUserReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserReportsQuery, GetUserReportsQueryVariables>(
    GetUserReportsDocument,
    options,
  )
}
export type GetUserReportsQueryHookResult = ReturnType<
  typeof useGetUserReportsQuery
>
export type GetUserReportsLazyQueryHookResult = ReturnType<
  typeof useGetUserReportsLazyQuery
>
export type GetUserReportsQueryResult = Apollo.QueryResult<
  GetUserReportsQuery,
  GetUserReportsQueryVariables
>
export const GetWorkspaceReportDocument = gql`
  query GetWorkspaceReport($workspaceId: ID!) {
    workspace: workspaceById(id: $workspaceId) {
      id
      usage {
        ...WorkspaceUsage
      }
    }
  }
  ${WorkspaceUsageFragmentDoc}
`

/**
 * __useGetWorkspaceReportQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceReportQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceReportQuery,
    GetWorkspaceReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkspaceReportQuery,
    GetWorkspaceReportQueryVariables
  >(GetWorkspaceReportDocument, options)
}
export function useGetWorkspaceReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceReportQuery,
    GetWorkspaceReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkspaceReportQuery,
    GetWorkspaceReportQueryVariables
  >(GetWorkspaceReportDocument, options)
}
export type GetWorkspaceReportQueryHookResult = ReturnType<
  typeof useGetWorkspaceReportQuery
>
export type GetWorkspaceReportLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceReportLazyQuery
>
export type GetWorkspaceReportQueryResult = Apollo.QueryResult<
  GetWorkspaceReportQuery,
  GetWorkspaceReportQueryVariables
>
export const ListMatchingsDocument = gql`
  query ListMatchings(
    $isArchived: Boolean
    $isCompleted: Boolean
    $isTransition: Boolean
    $isHiddenFromCatalog: Boolean
    $isPrincipalLead: Boolean = true
    $after: String
  ) {
    list: matchingsList(
      isArchived: $isArchived
      isCompleted: $isCompleted
      isTransition: $isTransition
      isHiddenFromCatalog: $isHiddenFromCatalog
      isPrincipalLead: $isPrincipalLead
      first: 100
      after: $after
    ) {
      items {
        ...Matching_ForMatchingListItem
      }
    }
  }
  ${Matching_ForMatchingListItemFragmentDoc}
`

/**
 * __useListMatchingsQuery__
 *
 * To run a query within a React component, call `useListMatchingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMatchingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMatchingsQuery({
 *   variables: {
 *      isArchived: // value for 'isArchived'
 *      isCompleted: // value for 'isCompleted'
 *      isTransition: // value for 'isTransition'
 *      isHiddenFromCatalog: // value for 'isHiddenFromCatalog'
 *      isPrincipalLead: // value for 'isPrincipalLead'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListMatchingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMatchingsQuery,
    ListMatchingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListMatchingsQuery, ListMatchingsQueryVariables>(
    ListMatchingsDocument,
    options,
  )
}
export function useListMatchingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMatchingsQuery,
    ListMatchingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListMatchingsQuery, ListMatchingsQueryVariables>(
    ListMatchingsDocument,
    options,
  )
}
export type ListMatchingsQueryHookResult = ReturnType<
  typeof useListMatchingsQuery
>
export type ListMatchingsLazyQueryHookResult = ReturnType<
  typeof useListMatchingsLazyQuery
>
export type ListMatchingsQueryResult = Apollo.QueryResult<
  ListMatchingsQuery,
  ListMatchingsQueryVariables
>
export const ListTransitionMatchingsDocument = gql`
  query ListTransitionMatchings($isCompleted: Boolean = false, $after: String) {
    list: matchingsList(
      isArchived: false
      isCompleted: $isCompleted
      isHiddenFromCatalog: null
      isTransition: true
      first: 100
      after: $after
    ) {
      items {
        ...Matching_ForTransitionListItem
      }
      after
    }
  }
  ${Matching_ForTransitionListItemFragmentDoc}
`

/**
 * __useListTransitionMatchingsQuery__
 *
 * To run a query within a React component, call `useListTransitionMatchingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransitionMatchingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransitionMatchingsQuery({
 *   variables: {
 *      isCompleted: // value for 'isCompleted'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListTransitionMatchingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTransitionMatchingsQuery,
    ListTransitionMatchingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListTransitionMatchingsQuery,
    ListTransitionMatchingsQueryVariables
  >(ListTransitionMatchingsDocument, options)
}
export function useListTransitionMatchingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTransitionMatchingsQuery,
    ListTransitionMatchingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListTransitionMatchingsQuery,
    ListTransitionMatchingsQueryVariables
  >(ListTransitionMatchingsDocument, options)
}
export type ListTransitionMatchingsQueryHookResult = ReturnType<
  typeof useListTransitionMatchingsQuery
>
export type ListTransitionMatchingsLazyQueryHookResult = ReturnType<
  typeof useListTransitionMatchingsLazyQuery
>
export type ListTransitionMatchingsQueryResult = Apollo.QueryResult<
  ListTransitionMatchingsQuery,
  ListTransitionMatchingsQueryVariables
>
export const GetLatestExecutiveCompletedTransitionDocument = gql`
  query getLatestExecutiveCompletedTransition($executiveId: String) {
    list: matchingsList(
      isArchived: false
      isCompleted: true
      isTransition: true
      isHiddenFromCatalog: null
      isPrincipalLead: null
      executiveId: $executiveId
      first: 1
    ) {
      total
      items {
        id
        transition {
          id
          requestedOn
        }
      }
    }
  }
`

/**
 * __useGetLatestExecutiveCompletedTransitionQuery__
 *
 * To run a query within a React component, call `useGetLatestExecutiveCompletedTransitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestExecutiveCompletedTransitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestExecutiveCompletedTransitionQuery({
 *   variables: {
 *      executiveId: // value for 'executiveId'
 *   },
 * });
 */
export function useGetLatestExecutiveCompletedTransitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestExecutiveCompletedTransitionQuery,
    GetLatestExecutiveCompletedTransitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLatestExecutiveCompletedTransitionQuery,
    GetLatestExecutiveCompletedTransitionQueryVariables
  >(GetLatestExecutiveCompletedTransitionDocument, options)
}
export function useGetLatestExecutiveCompletedTransitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestExecutiveCompletedTransitionQuery,
    GetLatestExecutiveCompletedTransitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLatestExecutiveCompletedTransitionQuery,
    GetLatestExecutiveCompletedTransitionQueryVariables
  >(GetLatestExecutiveCompletedTransitionDocument, options)
}
export type GetLatestExecutiveCompletedTransitionQueryHookResult = ReturnType<
  typeof useGetLatestExecutiveCompletedTransitionQuery
>
export type GetLatestExecutiveCompletedTransitionLazyQueryHookResult =
  ReturnType<typeof useGetLatestExecutiveCompletedTransitionLazyQuery>
export type GetLatestExecutiveCompletedTransitionQueryResult =
  Apollo.QueryResult<
    GetLatestExecutiveCompletedTransitionQuery,
    GetLatestExecutiveCompletedTransitionQueryVariables
  >
export const GetMatchingByIdDocument = gql`
  query GetMatchingById($matchingId: GUID!) {
    matching: matchingById(matchingId: $matchingId) {
      ...Matching_Full
    }
  }
  ${Matching_FullFragmentDoc}
`

/**
 * __useGetMatchingByIdQuery__
 *
 * To run a query within a React component, call `useGetMatchingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchingByIdQuery({
 *   variables: {
 *      matchingId: // value for 'matchingId'
 *   },
 * });
 */
export function useGetMatchingByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMatchingByIdQuery,
    GetMatchingByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMatchingByIdQuery, GetMatchingByIdQueryVariables>(
    GetMatchingByIdDocument,
    options,
  )
}
export function useGetMatchingByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchingByIdQuery,
    GetMatchingByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMatchingByIdQuery,
    GetMatchingByIdQueryVariables
  >(GetMatchingByIdDocument, options)
}
export type GetMatchingByIdQueryHookResult = ReturnType<
  typeof useGetMatchingByIdQuery
>
export type GetMatchingByIdLazyQueryHookResult = ReturnType<
  typeof useGetMatchingByIdLazyQuery
>
export type GetMatchingByIdQueryResult = Apollo.QueryResult<
  GetMatchingByIdQuery,
  GetMatchingByIdQueryVariables
>
export const UpdateMatchingDocument = gql`
  mutation UpdateMatching($input: UpdateMatchingInput!) {
    updateMatching(input: $input) {
      matching {
        ...Matching_IsHighTouch
        ...Matching_CatalogVisibility
      }
    }
  }
  ${Matching_IsHighTouchFragmentDoc}
  ${Matching_CatalogVisibilityFragmentDoc}
`
export type UpdateMatchingMutationFn = Apollo.MutationFunction<
  UpdateMatchingMutation,
  UpdateMatchingMutationVariables
>

/**
 * __useUpdateMatchingMutation__
 *
 * To run a mutation, you first call `useUpdateMatchingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchingMutation, { data, loading, error }] = useUpdateMatchingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMatchingMutation,
    UpdateMatchingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMatchingMutation,
    UpdateMatchingMutationVariables
  >(UpdateMatchingDocument, options)
}
export type UpdateMatchingMutationHookResult = ReturnType<
  typeof useUpdateMatchingMutation
>
export type UpdateMatchingMutationResult =
  Apollo.MutationResult<UpdateMatchingMutation>
export type UpdateMatchingMutationOptions = Apollo.BaseMutationOptions<
  UpdateMatchingMutation,
  UpdateMatchingMutationVariables
>
export const UpsertMatchingProposalDocument = gql`
  mutation UpsertMatchingProposal($input: UpsertMatchingProposalInput!) {
    upsertMatchingProposal(input: $input) {
      matching {
        ...Matching_Full
      }
    }
  }
  ${Matching_FullFragmentDoc}
`
export type UpsertMatchingProposalMutationFn = Apollo.MutationFunction<
  UpsertMatchingProposalMutation,
  UpsertMatchingProposalMutationVariables
>

/**
 * __useUpsertMatchingProposalMutation__
 *
 * To run a mutation, you first call `useUpsertMatchingProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMatchingProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMatchingProposalMutation, { data, loading, error }] = useUpsertMatchingProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMatchingProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMatchingProposalMutation,
    UpsertMatchingProposalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertMatchingProposalMutation,
    UpsertMatchingProposalMutationVariables
  >(UpsertMatchingProposalDocument, options)
}
export type UpsertMatchingProposalMutationHookResult = ReturnType<
  typeof useUpsertMatchingProposalMutation
>
export type UpsertMatchingProposalMutationResult =
  Apollo.MutationResult<UpsertMatchingProposalMutation>
export type UpsertMatchingProposalMutationOptions = Apollo.BaseMutationOptions<
  UpsertMatchingProposalMutation,
  UpsertMatchingProposalMutationVariables
>
export const SendMatchingProposalDocument = gql`
  mutation SendMatchingProposal($input: SendMatchingProposalInput!) {
    sendMatchingProposal(input: $input) {
      matching {
        ...Matching_Full
      }
    }
  }
  ${Matching_FullFragmentDoc}
`
export type SendMatchingProposalMutationFn = Apollo.MutationFunction<
  SendMatchingProposalMutation,
  SendMatchingProposalMutationVariables
>

/**
 * __useSendMatchingProposalMutation__
 *
 * To run a mutation, you first call `useSendMatchingProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchingProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchingProposalMutation, { data, loading, error }] = useSendMatchingProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchingProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMatchingProposalMutation,
    SendMatchingProposalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendMatchingProposalMutation,
    SendMatchingProposalMutationVariables
  >(SendMatchingProposalDocument, options)
}
export type SendMatchingProposalMutationHookResult = ReturnType<
  typeof useSendMatchingProposalMutation
>
export type SendMatchingProposalMutationResult =
  Apollo.MutationResult<SendMatchingProposalMutation>
export type SendMatchingProposalMutationOptions = Apollo.BaseMutationOptions<
  SendMatchingProposalMutation,
  SendMatchingProposalMutationVariables
>
export const DequeueMatchingProposalDocument = gql`
  mutation DequeueMatchingProposal($input: DequeueMatchingProposalInput!) {
    dequeueMatchingProposal(input: $input) {
      matching {
        ...Matching_Full
      }
    }
  }
  ${Matching_FullFragmentDoc}
`
export type DequeueMatchingProposalMutationFn = Apollo.MutationFunction<
  DequeueMatchingProposalMutation,
  DequeueMatchingProposalMutationVariables
>

/**
 * __useDequeueMatchingProposalMutation__
 *
 * To run a mutation, you first call `useDequeueMatchingProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDequeueMatchingProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dequeueMatchingProposalMutation, { data, loading, error }] = useDequeueMatchingProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDequeueMatchingProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DequeueMatchingProposalMutation,
    DequeueMatchingProposalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DequeueMatchingProposalMutation,
    DequeueMatchingProposalMutationVariables
  >(DequeueMatchingProposalDocument, options)
}
export type DequeueMatchingProposalMutationHookResult = ReturnType<
  typeof useDequeueMatchingProposalMutation
>
export type DequeueMatchingProposalMutationResult =
  Apollo.MutationResult<DequeueMatchingProposalMutation>
export type DequeueMatchingProposalMutationOptions = Apollo.BaseMutationOptions<
  DequeueMatchingProposalMutation,
  DequeueMatchingProposalMutationVariables
>
export const ConfirmMatchingAssistantDocument = gql`
  mutation ConfirmMatchingAssistant($input: ConfirmMatchingAssistantInput!) {
    confirmMatchingAssistant(input: $input) {
      matching {
        ...Matching_Full
      }
    }
  }
  ${Matching_FullFragmentDoc}
`
export type ConfirmMatchingAssistantMutationFn = Apollo.MutationFunction<
  ConfirmMatchingAssistantMutation,
  ConfirmMatchingAssistantMutationVariables
>

/**
 * __useConfirmMatchingAssistantMutation__
 *
 * To run a mutation, you first call `useConfirmMatchingAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMatchingAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMatchingAssistantMutation, { data, loading, error }] = useConfirmMatchingAssistantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmMatchingAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmMatchingAssistantMutation,
    ConfirmMatchingAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmMatchingAssistantMutation,
    ConfirmMatchingAssistantMutationVariables
  >(ConfirmMatchingAssistantDocument, options)
}
export type ConfirmMatchingAssistantMutationHookResult = ReturnType<
  typeof useConfirmMatchingAssistantMutation
>
export type ConfirmMatchingAssistantMutationResult =
  Apollo.MutationResult<ConfirmMatchingAssistantMutation>
export type ConfirmMatchingAssistantMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmMatchingAssistantMutation,
    ConfirmMatchingAssistantMutationVariables
  >
export const SendLoginEmailDocument = gql`
  mutation SendLoginEmail($input: SendLoginEmailInput!) {
    sendLoginEmail(input: $input) {
      email
    }
  }
`
export type SendLoginEmailMutationFn = Apollo.MutationFunction<
  SendLoginEmailMutation,
  SendLoginEmailMutationVariables
>

/**
 * __useSendLoginEmailMutation__
 *
 * To run a mutation, you first call `useSendLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLoginEmailMutation, { data, loading, error }] = useSendLoginEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendLoginEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendLoginEmailMutation,
    SendLoginEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendLoginEmailMutation,
    SendLoginEmailMutationVariables
  >(SendLoginEmailDocument, options)
}
export type SendLoginEmailMutationHookResult = ReturnType<
  typeof useSendLoginEmailMutation
>
export type SendLoginEmailMutationResult =
  Apollo.MutationResult<SendLoginEmailMutation>
export type SendLoginEmailMutationOptions = Apollo.BaseMutationOptions<
  SendLoginEmailMutation,
  SendLoginEmailMutationVariables
>
export const SendCsatFeedbackRequestDocument = gql`
  mutation SendCsatFeedbackRequest($input: SendCsatFeedbackRequestInput!) {
    sendCsatFeedbackRequest(input: $input) {
      isSuccess
    }
  }
`
export type SendCsatFeedbackRequestMutationFn = Apollo.MutationFunction<
  SendCsatFeedbackRequestMutation,
  SendCsatFeedbackRequestMutationVariables
>

/**
 * __useSendCsatFeedbackRequestMutation__
 *
 * To run a mutation, you first call `useSendCsatFeedbackRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCsatFeedbackRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCsatFeedbackRequestMutation, { data, loading, error }] = useSendCsatFeedbackRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCsatFeedbackRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCsatFeedbackRequestMutation,
    SendCsatFeedbackRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendCsatFeedbackRequestMutation,
    SendCsatFeedbackRequestMutationVariables
  >(SendCsatFeedbackRequestDocument, options)
}
export type SendCsatFeedbackRequestMutationHookResult = ReturnType<
  typeof useSendCsatFeedbackRequestMutation
>
export type SendCsatFeedbackRequestMutationResult =
  Apollo.MutationResult<SendCsatFeedbackRequestMutation>
export type SendCsatFeedbackRequestMutationOptions = Apollo.BaseMutationOptions<
  SendCsatFeedbackRequestMutation,
  SendCsatFeedbackRequestMutationVariables
>
export const GetUserPhoneNumberDocument = gql`
  query GetUserPhoneNumber($userId: ID!) {
    user: userById(id: $userId) {
      id
      phoneNumber
    }
  }
`

/**
 * __useGetUserPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGetUserPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPhoneNumberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPhoneNumberQuery,
    GetUserPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPhoneNumberQuery,
    GetUserPhoneNumberQueryVariables
  >(GetUserPhoneNumberDocument, options)
}
export function useGetUserPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPhoneNumberQuery,
    GetUserPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPhoneNumberQuery,
    GetUserPhoneNumberQueryVariables
  >(GetUserPhoneNumberDocument, options)
}
export type GetUserPhoneNumberQueryHookResult = ReturnType<
  typeof useGetUserPhoneNumberQuery
>
export type GetUserPhoneNumberLazyQueryHookResult = ReturnType<
  typeof useGetUserPhoneNumberLazyQuery
>
export type GetUserPhoneNumberQueryResult = Apollo.QueryResult<
  GetUserPhoneNumberQuery,
  GetUserPhoneNumberQueryVariables
>
export const SendSmsDocument = gql`
  mutation SendSms($input: SendSmsInput!) {
    sendSms(input: $input) {
      isSuccess
    }
  }
`
export type SendSmsMutationFn = Apollo.MutationFunction<
  SendSmsMutation,
  SendSmsMutationVariables
>

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSmsMutation,
    SendSmsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendSmsMutation, SendSmsMutationVariables>(
    SendSmsDocument,
    options,
  )
}
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>
export type SendSmsMutationResult = Apollo.MutationResult<SendSmsMutation>
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<
  SendSmsMutation,
  SendSmsMutationVariables
>
export const ListLeverOpportunitiesDocument = gql`
  query ListLeverOpportunities(
    $after: String
    $hiredAfter: DateTime
    $offeredAfter: DateTime
    $onboardingAfter: DateTime
    $isUser: Boolean
    $includePostingTitle: Boolean!
  ) {
    list: leverOpportunitiesList(
      after: $after
      hiredAfter: $hiredAfter
      offeredAfter: $offeredAfter
      onboardingAfter: $onboardingAfter
      isUser: $isUser
    )
      @connection(
        key: "leverOpportunities"
        filter: [
          "hiredAfter"
          "isUser"
          "includePostingTitle"
          "offeredAfter"
          "onboardingAfter"
        ]
      ) {
      items {
        ...LeverOpportunity
      }
      after
    }
  }
  ${LeverOpportunityFragmentDoc}
`

/**
 * __useListLeverOpportunitiesQuery__
 *
 * To run a query within a React component, call `useListLeverOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLeverOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeverOpportunitiesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      hiredAfter: // value for 'hiredAfter'
 *      offeredAfter: // value for 'offeredAfter'
 *      onboardingAfter: // value for 'onboardingAfter'
 *      isUser: // value for 'isUser'
 *      includePostingTitle: // value for 'includePostingTitle'
 *   },
 * });
 */
export function useListLeverOpportunitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListLeverOpportunitiesQuery,
    ListLeverOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListLeverOpportunitiesQuery,
    ListLeverOpportunitiesQueryVariables
  >(ListLeverOpportunitiesDocument, options)
}
export function useListLeverOpportunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLeverOpportunitiesQuery,
    ListLeverOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListLeverOpportunitiesQuery,
    ListLeverOpportunitiesQueryVariables
  >(ListLeverOpportunitiesDocument, options)
}
export type ListLeverOpportunitiesQueryHookResult = ReturnType<
  typeof useListLeverOpportunitiesQuery
>
export type ListLeverOpportunitiesLazyQueryHookResult = ReturnType<
  typeof useListLeverOpportunitiesLazyQuery
>
export type ListLeverOpportunitiesQueryResult = Apollo.QueryResult<
  ListLeverOpportunitiesQuery,
  ListLeverOpportunitiesQueryVariables
>
export const ListAirtableLeadsDocument = gql`
  query ListAirtableLeads(
    $isClient: Boolean
    $inNextSteps: [String!]
    $nameContains: String
  ) {
    list: airtableLeadsList(
      isClient: $isClient
      inNextSteps: $inNextSteps
      nameContains: $nameContains
    )
      @connection(
        key: "airtableleads"
        filter: ["isClient", "inNextSteps", "nameContains"]
      ) {
      items {
        ...AirtableLead
      }
    }
  }
  ${AirtableLeadFragmentDoc}
`

/**
 * __useListAirtableLeadsQuery__
 *
 * To run a query within a React component, call `useListAirtableLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAirtableLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAirtableLeadsQuery({
 *   variables: {
 *      isClient: // value for 'isClient'
 *      inNextSteps: // value for 'inNextSteps'
 *      nameContains: // value for 'nameContains'
 *   },
 * });
 */
export function useListAirtableLeadsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAirtableLeadsQuery,
    ListAirtableLeadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListAirtableLeadsQuery,
    ListAirtableLeadsQueryVariables
  >(ListAirtableLeadsDocument, options)
}
export function useListAirtableLeadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAirtableLeadsQuery,
    ListAirtableLeadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListAirtableLeadsQuery,
    ListAirtableLeadsQueryVariables
  >(ListAirtableLeadsDocument, options)
}
export type ListAirtableLeadsQueryHookResult = ReturnType<
  typeof useListAirtableLeadsQuery
>
export type ListAirtableLeadsLazyQueryHookResult = ReturnType<
  typeof useListAirtableLeadsLazyQuery
>
export type ListAirtableLeadsQueryResult = Apollo.QueryResult<
  ListAirtableLeadsQuery,
  ListAirtableLeadsQueryVariables
>
export const GetAirtableLeadByLeadRecordIdDocument = gql`
  query GetAirtableLeadByLeadRecordId($leadRecordId: String!) {
    leadRecord: airtableLeadByLeadRecordId(leadRecordId: $leadRecordId) {
      ...AirtableLead
    }
  }
  ${AirtableLeadFragmentDoc}
`

/**
 * __useGetAirtableLeadByLeadRecordIdQuery__
 *
 * To run a query within a React component, call `useGetAirtableLeadByLeadRecordIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirtableLeadByLeadRecordIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirtableLeadByLeadRecordIdQuery({
 *   variables: {
 *      leadRecordId: // value for 'leadRecordId'
 *   },
 * });
 */
export function useGetAirtableLeadByLeadRecordIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAirtableLeadByLeadRecordIdQuery,
    GetAirtableLeadByLeadRecordIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAirtableLeadByLeadRecordIdQuery,
    GetAirtableLeadByLeadRecordIdQueryVariables
  >(GetAirtableLeadByLeadRecordIdDocument, options)
}
export function useGetAirtableLeadByLeadRecordIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAirtableLeadByLeadRecordIdQuery,
    GetAirtableLeadByLeadRecordIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAirtableLeadByLeadRecordIdQuery,
    GetAirtableLeadByLeadRecordIdQueryVariables
  >(GetAirtableLeadByLeadRecordIdDocument, options)
}
export type GetAirtableLeadByLeadRecordIdQueryHookResult = ReturnType<
  typeof useGetAirtableLeadByLeadRecordIdQuery
>
export type GetAirtableLeadByLeadRecordIdLazyQueryHookResult = ReturnType<
  typeof useGetAirtableLeadByLeadRecordIdLazyQuery
>
export type GetAirtableLeadByLeadRecordIdQueryResult = Apollo.QueryResult<
  GetAirtableLeadByLeadRecordIdQuery,
  GetAirtableLeadByLeadRecordIdQueryVariables
>
export const GetAirtableBundlePrincipalByLeadRecordIdDocument = gql`
  query GetAirtableBundlePrincipalByLeadRecordId($leadRecordId: String!) {
    bundlePrincipal: airtableLeadByLeadRecordId(leadRecordId: $leadRecordId) {
      ...AirtableLead_BundlePrincipal
    }
  }
  ${AirtableLead_BundlePrincipalFragmentDoc}
`

/**
 * __useGetAirtableBundlePrincipalByLeadRecordIdQuery__
 *
 * To run a query within a React component, call `useGetAirtableBundlePrincipalByLeadRecordIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirtableBundlePrincipalByLeadRecordIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirtableBundlePrincipalByLeadRecordIdQuery({
 *   variables: {
 *      leadRecordId: // value for 'leadRecordId'
 *   },
 * });
 */
export function useGetAirtableBundlePrincipalByLeadRecordIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAirtableBundlePrincipalByLeadRecordIdQuery,
    GetAirtableBundlePrincipalByLeadRecordIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAirtableBundlePrincipalByLeadRecordIdQuery,
    GetAirtableBundlePrincipalByLeadRecordIdQueryVariables
  >(GetAirtableBundlePrincipalByLeadRecordIdDocument, options)
}
export function useGetAirtableBundlePrincipalByLeadRecordIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAirtableBundlePrincipalByLeadRecordIdQuery,
    GetAirtableBundlePrincipalByLeadRecordIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAirtableBundlePrincipalByLeadRecordIdQuery,
    GetAirtableBundlePrincipalByLeadRecordIdQueryVariables
  >(GetAirtableBundlePrincipalByLeadRecordIdDocument, options)
}
export type GetAirtableBundlePrincipalByLeadRecordIdQueryHookResult =
  ReturnType<typeof useGetAirtableBundlePrincipalByLeadRecordIdQuery>
export type GetAirtableBundlePrincipalByLeadRecordIdLazyQueryHookResult =
  ReturnType<typeof useGetAirtableBundlePrincipalByLeadRecordIdLazyQuery>
export type GetAirtableBundlePrincipalByLeadRecordIdQueryResult =
  Apollo.QueryResult<
    GetAirtableBundlePrincipalByLeadRecordIdQuery,
    GetAirtableBundlePrincipalByLeadRecordIdQueryVariables
  >
export const CreateTransitionSlackChannelDocument = gql`
  mutation CreateTransitionSlackChannel(
    $input: CreateTransitionSlackChannelInput!
  ) {
    createTransitionSlackChannel(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_SlackChannelId
        }
      }
    }
  }
  ${MatchingTransition_SlackChannelIdFragmentDoc}
`
export type CreateTransitionSlackChannelMutationFn = Apollo.MutationFunction<
  CreateTransitionSlackChannelMutation,
  CreateTransitionSlackChannelMutationVariables
>

/**
 * __useCreateTransitionSlackChannelMutation__
 *
 * To run a mutation, you first call `useCreateTransitionSlackChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransitionSlackChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransitionSlackChannelMutation, { data, loading, error }] = useCreateTransitionSlackChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransitionSlackChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTransitionSlackChannelMutation,
    CreateTransitionSlackChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateTransitionSlackChannelMutation,
    CreateTransitionSlackChannelMutationVariables
  >(CreateTransitionSlackChannelDocument, options)
}
export type CreateTransitionSlackChannelMutationHookResult = ReturnType<
  typeof useCreateTransitionSlackChannelMutation
>
export type CreateTransitionSlackChannelMutationResult =
  Apollo.MutationResult<CreateTransitionSlackChannelMutation>
export type CreateTransitionSlackChannelMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTransitionSlackChannelMutation,
    CreateTransitionSlackChannelMutationVariables
  >
export const UpdateTransitionInformingDocument = gql`
  mutation UpdateTransitionInforming($input: UpdateTransitionInformingInput!) {
    updateTransitionInforming(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_Informing
        }
      }
    }
  }
  ${MatchingTransition_InformingFragmentDoc}
`
export type UpdateTransitionInformingMutationFn = Apollo.MutationFunction<
  UpdateTransitionInformingMutation,
  UpdateTransitionInformingMutationVariables
>

/**
 * __useUpdateTransitionInformingMutation__
 *
 * To run a mutation, you first call `useUpdateTransitionInformingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransitionInformingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransitionInformingMutation, { data, loading, error }] = useUpdateTransitionInformingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransitionInformingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransitionInformingMutation,
    UpdateTransitionInformingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTransitionInformingMutation,
    UpdateTransitionInformingMutationVariables
  >(UpdateTransitionInformingDocument, options)
}
export type UpdateTransitionInformingMutationHookResult = ReturnType<
  typeof useUpdateTransitionInformingMutation
>
export type UpdateTransitionInformingMutationResult =
  Apollo.MutationResult<UpdateTransitionInformingMutation>
export type UpdateTransitionInformingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTransitionInformingMutation,
    UpdateTransitionInformingMutationVariables
  >
export const UpdateTransitionMatchingStatusDocument = gql`
  mutation UpdateTransitionMatchingStatus(
    $input: UpdateTransitionMatchingStatusInput!
  ) {
    updateTransitionMatchingStatus(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_MatchingStatus
        }
      }
    }
  }
  ${MatchingTransition_MatchingStatusFragmentDoc}
`
export type UpdateTransitionMatchingStatusMutationFn = Apollo.MutationFunction<
  UpdateTransitionMatchingStatusMutation,
  UpdateTransitionMatchingStatusMutationVariables
>

/**
 * __useUpdateTransitionMatchingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTransitionMatchingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransitionMatchingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransitionMatchingStatusMutation, { data, loading, error }] = useUpdateTransitionMatchingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransitionMatchingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransitionMatchingStatusMutation,
    UpdateTransitionMatchingStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTransitionMatchingStatusMutation,
    UpdateTransitionMatchingStatusMutationVariables
  >(UpdateTransitionMatchingStatusDocument, options)
}
export type UpdateTransitionMatchingStatusMutationHookResult = ReturnType<
  typeof useUpdateTransitionMatchingStatusMutation
>
export type UpdateTransitionMatchingStatusMutationResult =
  Apollo.MutationResult<UpdateTransitionMatchingStatusMutation>
export type UpdateTransitionMatchingStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTransitionMatchingStatusMutation,
    UpdateTransitionMatchingStatusMutationVariables
  >
export const UpdateTransitionHandoverDocument = gql`
  mutation UpdateTransitionHandover($input: UpdateTransitionHandoverInput!) {
    updateTransitionHandover(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_Handover
        }
      }
    }
  }
  ${MatchingTransition_HandoverFragmentDoc}
`
export type UpdateTransitionHandoverMutationFn = Apollo.MutationFunction<
  UpdateTransitionHandoverMutation,
  UpdateTransitionHandoverMutationVariables
>

/**
 * __useUpdateTransitionHandoverMutation__
 *
 * To run a mutation, you first call `useUpdateTransitionHandoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransitionHandoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransitionHandoverMutation, { data, loading, error }] = useUpdateTransitionHandoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransitionHandoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransitionHandoverMutation,
    UpdateTransitionHandoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTransitionHandoverMutation,
    UpdateTransitionHandoverMutationVariables
  >(UpdateTransitionHandoverDocument, options)
}
export type UpdateTransitionHandoverMutationHookResult = ReturnType<
  typeof useUpdateTransitionHandoverMutation
>
export type UpdateTransitionHandoverMutationResult =
  Apollo.MutationResult<UpdateTransitionHandoverMutation>
export type UpdateTransitionHandoverMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTransitionHandoverMutation,
    UpdateTransitionHandoverMutationVariables
  >
export const UpdateTransitionChangeoverDocument = gql`
  mutation UpdateTransitionChangeover(
    $input: UpdateTransitionChangeoverInput!
  ) {
    updateTransitionChangeover(input: $input) {
      matching {
        ...Matching_OnboardingCallAt
      }
    }
  }
  ${Matching_OnboardingCallAtFragmentDoc}
`
export type UpdateTransitionChangeoverMutationFn = Apollo.MutationFunction<
  UpdateTransitionChangeoverMutation,
  UpdateTransitionChangeoverMutationVariables
>

/**
 * __useUpdateTransitionChangeoverMutation__
 *
 * To run a mutation, you first call `useUpdateTransitionChangeoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransitionChangeoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransitionChangeoverMutation, { data, loading, error }] = useUpdateTransitionChangeoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransitionChangeoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransitionChangeoverMutation,
    UpdateTransitionChangeoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTransitionChangeoverMutation,
    UpdateTransitionChangeoverMutationVariables
  >(UpdateTransitionChangeoverDocument, options)
}
export type UpdateTransitionChangeoverMutationHookResult = ReturnType<
  typeof useUpdateTransitionChangeoverMutation
>
export type UpdateTransitionChangeoverMutationResult =
  Apollo.MutationResult<UpdateTransitionChangeoverMutation>
export type UpdateTransitionChangeoverMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTransitionChangeoverMutation,
    UpdateTransitionChangeoverMutationVariables
  >
export const UpdateTransitionNotepadDocument = gql`
  mutation UpdateTransitionNotepad($input: UpdateTransitionNotepadInput!) {
    updateTransitionNotepad(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_Notepad
        }
      }
    }
  }
  ${MatchingTransition_NotepadFragmentDoc}
`
export type UpdateTransitionNotepadMutationFn = Apollo.MutationFunction<
  UpdateTransitionNotepadMutation,
  UpdateTransitionNotepadMutationVariables
>

/**
 * __useUpdateTransitionNotepadMutation__
 *
 * To run a mutation, you first call `useUpdateTransitionNotepadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransitionNotepadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransitionNotepadMutation, { data, loading, error }] = useUpdateTransitionNotepadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransitionNotepadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransitionNotepadMutation,
    UpdateTransitionNotepadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTransitionNotepadMutation,
    UpdateTransitionNotepadMutationVariables
  >(UpdateTransitionNotepadDocument, options)
}
export type UpdateTransitionNotepadMutationHookResult = ReturnType<
  typeof useUpdateTransitionNotepadMutation
>
export type UpdateTransitionNotepadMutationResult =
  Apollo.MutationResult<UpdateTransitionNotepadMutation>
export type UpdateTransitionNotepadMutationOptions = Apollo.BaseMutationOptions<
  UpdateTransitionNotepadMutation,
  UpdateTransitionNotepadMutationVariables
>
export const SwitchTransitionAssistantsDocument = gql`
  mutation SwitchTransitionAssistants(
    $input: SwitchTransitionAssistantsInput!
  ) {
    switchTransitionAssistants(input: $input) {
      matching {
        id
        completedAt
        transition {
          ...MatchingTransition_ChangeoverStatuses
        }
      }
    }
  }
  ${MatchingTransition_ChangeoverStatusesFragmentDoc}
`
export type SwitchTransitionAssistantsMutationFn = Apollo.MutationFunction<
  SwitchTransitionAssistantsMutation,
  SwitchTransitionAssistantsMutationVariables
>

/**
 * __useSwitchTransitionAssistantsMutation__
 *
 * To run a mutation, you first call `useSwitchTransitionAssistantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchTransitionAssistantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchTransitionAssistantsMutation, { data, loading, error }] = useSwitchTransitionAssistantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchTransitionAssistantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchTransitionAssistantsMutation,
    SwitchTransitionAssistantsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SwitchTransitionAssistantsMutation,
    SwitchTransitionAssistantsMutationVariables
  >(SwitchTransitionAssistantsDocument, options)
}
export type SwitchTransitionAssistantsMutationHookResult = ReturnType<
  typeof useSwitchTransitionAssistantsMutation
>
export type SwitchTransitionAssistantsMutationResult =
  Apollo.MutationResult<SwitchTransitionAssistantsMutation>
export type SwitchTransitionAssistantsMutationOptions =
  Apollo.BaseMutationOptions<
    SwitchTransitionAssistantsMutation,
    SwitchTransitionAssistantsMutationVariables
  >
export const CompleteTransitionCallDocument = gql`
  mutation CompleteTransitionCall($input: CompleteTransitionCallInput!) {
    completeTransitionCall(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_ChangeoverStatuses
        }
      }
    }
  }
  ${MatchingTransition_ChangeoverStatusesFragmentDoc}
`
export type CompleteTransitionCallMutationFn = Apollo.MutationFunction<
  CompleteTransitionCallMutation,
  CompleteTransitionCallMutationVariables
>

/**
 * __useCompleteTransitionCallMutation__
 *
 * To run a mutation, you first call `useCompleteTransitionCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTransitionCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTransitionCallMutation, { data, loading, error }] = useCompleteTransitionCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteTransitionCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTransitionCallMutation,
    CompleteTransitionCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompleteTransitionCallMutation,
    CompleteTransitionCallMutationVariables
  >(CompleteTransitionCallDocument, options)
}
export type CompleteTransitionCallMutationHookResult = ReturnType<
  typeof useCompleteTransitionCallMutation
>
export type CompleteTransitionCallMutationResult =
  Apollo.MutationResult<CompleteTransitionCallMutation>
export type CompleteTransitionCallMutationOptions = Apollo.BaseMutationOptions<
  CompleteTransitionCallMutation,
  CompleteTransitionCallMutationVariables
>
export const FollowUpWithNewTransitionAssistantDocument = gql`
  mutation FollowUpWithNewTransitionAssistant(
    $input: FollowUpWithNewTransitionAssistantInput!
  ) {
    followUpWithNewTransitionAssistant(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_ChangeoverStatuses
        }
      }
    }
  }
  ${MatchingTransition_ChangeoverStatusesFragmentDoc}
`
export type FollowUpWithNewTransitionAssistantMutationFn =
  Apollo.MutationFunction<
    FollowUpWithNewTransitionAssistantMutation,
    FollowUpWithNewTransitionAssistantMutationVariables
  >

/**
 * __useFollowUpWithNewTransitionAssistantMutation__
 *
 * To run a mutation, you first call `useFollowUpWithNewTransitionAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUpWithNewTransitionAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUpWithNewTransitionAssistantMutation, { data, loading, error }] = useFollowUpWithNewTransitionAssistantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowUpWithNewTransitionAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FollowUpWithNewTransitionAssistantMutation,
    FollowUpWithNewTransitionAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FollowUpWithNewTransitionAssistantMutation,
    FollowUpWithNewTransitionAssistantMutationVariables
  >(FollowUpWithNewTransitionAssistantDocument, options)
}
export type FollowUpWithNewTransitionAssistantMutationHookResult = ReturnType<
  typeof useFollowUpWithNewTransitionAssistantMutation
>
export type FollowUpWithNewTransitionAssistantMutationResult =
  Apollo.MutationResult<FollowUpWithNewTransitionAssistantMutation>
export type FollowUpWithNewTransitionAssistantMutationOptions =
  Apollo.BaseMutationOptions<
    FollowUpWithNewTransitionAssistantMutation,
    FollowUpWithNewTransitionAssistantMutationVariables
  >
export const ArchiveTransitionDocument = gql`
  mutation ArchiveTransition($input: ArchiveTransitionInput!) {
    archiveTransition(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_ChangeoverStatuses
        }
      }
    }
  }
  ${MatchingTransition_ChangeoverStatusesFragmentDoc}
`
export type ArchiveTransitionMutationFn = Apollo.MutationFunction<
  ArchiveTransitionMutation,
  ArchiveTransitionMutationVariables
>

/**
 * __useArchiveTransitionMutation__
 *
 * To run a mutation, you first call `useArchiveTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTransitionMutation, { data, loading, error }] = useArchiveTransitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveTransitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveTransitionMutation,
    ArchiveTransitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveTransitionMutation,
    ArchiveTransitionMutationVariables
  >(ArchiveTransitionDocument, options)
}
export type ArchiveTransitionMutationHookResult = ReturnType<
  typeof useArchiveTransitionMutation
>
export type ArchiveTransitionMutationResult =
  Apollo.MutationResult<ArchiveTransitionMutation>
export type ArchiveTransitionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveTransitionMutation,
  ArchiveTransitionMutationVariables
>
export const UnarchiveTransitionDocument = gql`
  mutation UnarchiveTransition($input: UnarchiveTransitionInput!) {
    unarchiveTransition(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_ChangeoverStatuses
        }
      }
    }
  }
  ${MatchingTransition_ChangeoverStatusesFragmentDoc}
`
export type UnarchiveTransitionMutationFn = Apollo.MutationFunction<
  UnarchiveTransitionMutation,
  UnarchiveTransitionMutationVariables
>

/**
 * __useUnarchiveTransitionMutation__
 *
 * To run a mutation, you first call `useUnarchiveTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveTransitionMutation, { data, loading, error }] = useUnarchiveTransitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveTransitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveTransitionMutation,
    UnarchiveTransitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveTransitionMutation,
    UnarchiveTransitionMutationVariables
  >(UnarchiveTransitionDocument, options)
}
export type UnarchiveTransitionMutationHookResult = ReturnType<
  typeof useUnarchiveTransitionMutation
>
export type UnarchiveTransitionMutationResult =
  Apollo.MutationResult<UnarchiveTransitionMutation>
export type UnarchiveTransitionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveTransitionMutation,
  UnarchiveTransitionMutationVariables
>
export const SentTransitionMessageDocument = gql`
  mutation SentTransitionMessage($input: SentTransitionMessageInput!) {
    sentTransitionMessage(input: $input) {
      matching {
        id
        transition {
          ...MatchingTransition_SentMessages
        }
      }
    }
  }
  ${MatchingTransition_SentMessagesFragmentDoc}
`
export type SentTransitionMessageMutationFn = Apollo.MutationFunction<
  SentTransitionMessageMutation,
  SentTransitionMessageMutationVariables
>

/**
 * __useSentTransitionMessageMutation__
 *
 * To run a mutation, you first call `useSentTransitionMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentTransitionMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentTransitionMessageMutation, { data, loading, error }] = useSentTransitionMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSentTransitionMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SentTransitionMessageMutation,
    SentTransitionMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SentTransitionMessageMutation,
    SentTransitionMessageMutationVariables
  >(SentTransitionMessageDocument, options)
}
export type SentTransitionMessageMutationHookResult = ReturnType<
  typeof useSentTransitionMessageMutation
>
export type SentTransitionMessageMutationResult =
  Apollo.MutationResult<SentTransitionMessageMutation>
export type SentTransitionMessageMutationOptions = Apollo.BaseMutationOptions<
  SentTransitionMessageMutation,
  SentTransitionMessageMutationVariables
>
export const ReadTopicsDocument = gql`
  mutation ReadTopics($input: ReadTopicsInput!) {
    readTopics(input: $input) {
      isSuccess
    }
  }
`
export type ReadTopicsMutationFn = Apollo.MutationFunction<
  ReadTopicsMutation,
  ReadTopicsMutationVariables
>

/**
 * __useReadTopicsMutation__
 *
 * To run a mutation, you first call `useReadTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readTopicsMutation, { data, loading, error }] = useReadTopicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadTopicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadTopicsMutation,
    ReadTopicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReadTopicsMutation, ReadTopicsMutationVariables>(
    ReadTopicsDocument,
    options,
  )
}
export type ReadTopicsMutationHookResult = ReturnType<
  typeof useReadTopicsMutation
>
export type ReadTopicsMutationResult = Apollo.MutationResult<ReadTopicsMutation>
export type ReadTopicsMutationOptions = Apollo.BaseMutationOptions<
  ReadTopicsMutation,
  ReadTopicsMutationVariables
>
export const ClearTopicsDocument = gql`
  mutation ClearTopics($input: ClearTopicsInput!) {
    clearTopics(input: $input) {
      isSuccess
    }
  }
`
export type ClearTopicsMutationFn = Apollo.MutationFunction<
  ClearTopicsMutation,
  ClearTopicsMutationVariables
>

/**
 * __useClearTopicsMutation__
 *
 * To run a mutation, you first call `useClearTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTopicsMutation, { data, loading, error }] = useClearTopicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearTopicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearTopicsMutation,
    ClearTopicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearTopicsMutation, ClearTopicsMutationVariables>(
    ClearTopicsDocument,
    options,
  )
}
export type ClearTopicsMutationHookResult = ReturnType<
  typeof useClearTopicsMutation
>
export type ClearTopicsMutationResult =
  Apollo.MutationResult<ClearTopicsMutation>
export type ClearTopicsMutationOptions = Apollo.BaseMutationOptions<
  ClearTopicsMutation,
  ClearTopicsMutationVariables
>
export const GetStandardDossierFoldersDocument = gql`
  query GetStandardDossierFolders {
    standardDossierFolders {
      ...DossierFolder
      sections {
        ...DossierSection
        fields {
          ...DossierField
        }
      }
    }
  }
  ${DossierFolderFragmentDoc}
  ${DossierSectionFragmentDoc}
  ${DossierFieldFragmentDoc}
`

/**
 * __useGetStandardDossierFoldersQuery__
 *
 * To run a query within a React component, call `useGetStandardDossierFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardDossierFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardDossierFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStandardDossierFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStandardDossierFoldersQuery,
    GetStandardDossierFoldersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetStandardDossierFoldersQuery,
    GetStandardDossierFoldersQueryVariables
  >(GetStandardDossierFoldersDocument, options)
}
export function useGetStandardDossierFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStandardDossierFoldersQuery,
    GetStandardDossierFoldersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStandardDossierFoldersQuery,
    GetStandardDossierFoldersQueryVariables
  >(GetStandardDossierFoldersDocument, options)
}
export type GetStandardDossierFoldersQueryHookResult = ReturnType<
  typeof useGetStandardDossierFoldersQuery
>
export type GetStandardDossierFoldersLazyQueryHookResult = ReturnType<
  typeof useGetStandardDossierFoldersLazyQuery
>
export type GetStandardDossierFoldersQueryResult = Apollo.QueryResult<
  GetStandardDossierFoldersQuery,
  GetStandardDossierFoldersQueryVariables
>
export const UpsertDossierFolderDocument = gql`
  mutation UpsertDossierFolder($input: UpsertDossierFolderInput!) {
    upsertDossierFolder(input: $input) {
      folder {
        ...DossierFolder
      }
    }
  }
  ${DossierFolderFragmentDoc}
`
export type UpsertDossierFolderMutationFn = Apollo.MutationFunction<
  UpsertDossierFolderMutation,
  UpsertDossierFolderMutationVariables
>

/**
 * __useUpsertDossierFolderMutation__
 *
 * To run a mutation, you first call `useUpsertDossierFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDossierFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDossierFolderMutation, { data, loading, error }] = useUpsertDossierFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDossierFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDossierFolderMutation,
    UpsertDossierFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDossierFolderMutation,
    UpsertDossierFolderMutationVariables
  >(UpsertDossierFolderDocument, options)
}
export type UpsertDossierFolderMutationHookResult = ReturnType<
  typeof useUpsertDossierFolderMutation
>
export type UpsertDossierFolderMutationResult =
  Apollo.MutationResult<UpsertDossierFolderMutation>
export type UpsertDossierFolderMutationOptions = Apollo.BaseMutationOptions<
  UpsertDossierFolderMutation,
  UpsertDossierFolderMutationVariables
>
export const UpsertDossierSectionDocument = gql`
  mutation UpsertDossierSection($input: UpsertDossierSectionInput!) {
    upsertDossierSection(input: $input) {
      section {
        ...DossierSection
      }
    }
  }
  ${DossierSectionFragmentDoc}
`
export type UpsertDossierSectionMutationFn = Apollo.MutationFunction<
  UpsertDossierSectionMutation,
  UpsertDossierSectionMutationVariables
>

/**
 * __useUpsertDossierSectionMutation__
 *
 * To run a mutation, you first call `useUpsertDossierSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDossierSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDossierSectionMutation, { data, loading, error }] = useUpsertDossierSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDossierSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDossierSectionMutation,
    UpsertDossierSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDossierSectionMutation,
    UpsertDossierSectionMutationVariables
  >(UpsertDossierSectionDocument, options)
}
export type UpsertDossierSectionMutationHookResult = ReturnType<
  typeof useUpsertDossierSectionMutation
>
export type UpsertDossierSectionMutationResult =
  Apollo.MutationResult<UpsertDossierSectionMutation>
export type UpsertDossierSectionMutationOptions = Apollo.BaseMutationOptions<
  UpsertDossierSectionMutation,
  UpsertDossierSectionMutationVariables
>
export const UpsertDossierFieldDocument = gql`
  mutation UpsertDossierField($input: UpsertDossierFieldInput!) {
    upsertDossierField(input: $input) {
      field {
        ...DossierField
      }
    }
  }
  ${DossierFieldFragmentDoc}
`
export type UpsertDossierFieldMutationFn = Apollo.MutationFunction<
  UpsertDossierFieldMutation,
  UpsertDossierFieldMutationVariables
>

/**
 * __useUpsertDossierFieldMutation__
 *
 * To run a mutation, you first call `useUpsertDossierFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDossierFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDossierFieldMutation, { data, loading, error }] = useUpsertDossierFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDossierFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDossierFieldMutation,
    UpsertDossierFieldMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDossierFieldMutation,
    UpsertDossierFieldMutationVariables
  >(UpsertDossierFieldDocument, options)
}
export type UpsertDossierFieldMutationHookResult = ReturnType<
  typeof useUpsertDossierFieldMutation
>
export type UpsertDossierFieldMutationResult =
  Apollo.MutationResult<UpsertDossierFieldMutation>
export type UpsertDossierFieldMutationOptions = Apollo.BaseMutationOptions<
  UpsertDossierFieldMutation,
  UpsertDossierFieldMutationVariables
>
export const ListAssistantsInOrientationDocument = gql`
  query ListAssistantsInOrientation(
    $after: String
    $first: PositiveInt
    $categories: [UserCategory!] = [ASSISTANT]
  ) {
    list: usersList(
      after: $after
      first: $first
      categories: $categories
      isPendingOrientation: true
    ) {
      after
      items {
        ...Userable_InOrientation
      }
    }
  }
  ${Userable_InOrientationFragmentDoc}
`

/**
 * __useListAssistantsInOrientationQuery__
 *
 * To run a query within a React component, call `useListAssistantsInOrientationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAssistantsInOrientationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAssistantsInOrientationQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useListAssistantsInOrientationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAssistantsInOrientationQuery,
    ListAssistantsInOrientationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListAssistantsInOrientationQuery,
    ListAssistantsInOrientationQueryVariables
  >(ListAssistantsInOrientationDocument, options)
}
export function useListAssistantsInOrientationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAssistantsInOrientationQuery,
    ListAssistantsInOrientationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListAssistantsInOrientationQuery,
    ListAssistantsInOrientationQueryVariables
  >(ListAssistantsInOrientationDocument, options)
}
export type ListAssistantsInOrientationQueryHookResult = ReturnType<
  typeof useListAssistantsInOrientationQuery
>
export type ListAssistantsInOrientationLazyQueryHookResult = ReturnType<
  typeof useListAssistantsInOrientationLazyQuery
>
export type ListAssistantsInOrientationQueryResult = Apollo.QueryResult<
  ListAssistantsInOrientationQuery,
  ListAssistantsInOrientationQueryVariables
>
export const ListExecutivesStripeCustomerDocument = gql`
  query ListExecutivesStripeCustomer(
    $first: PositiveInt
    $userIds: [ID!]
    $invoiceStatus: [StripeInvoiceStatus!]
    $invoicesFirst: NonNegativeInt
    $invoicesCreatedAfter: DateTime
    $invoicesCreatedBefore: DateTime
  ) {
    list: usersList(first: $first, userIds: $userIds, isArchived: null) {
      items {
        id
        ... on Executivable {
          stripeCustomer {
            ...StripeCustomer_PaymentStatus
            ...StripeCustomer_Invoices
          }
        }
      }
    }
  }
  ${StripeCustomer_PaymentStatusFragmentDoc}
  ${StripeCustomer_InvoicesFragmentDoc}
`

/**
 * __useListExecutivesStripeCustomerQuery__
 *
 * To run a query within a React component, call `useListExecutivesStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExecutivesStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExecutivesStripeCustomerQuery({
 *   variables: {
 *      first: // value for 'first'
 *      userIds: // value for 'userIds'
 *      invoiceStatus: // value for 'invoiceStatus'
 *      invoicesFirst: // value for 'invoicesFirst'
 *      invoicesCreatedAfter: // value for 'invoicesCreatedAfter'
 *      invoicesCreatedBefore: // value for 'invoicesCreatedBefore'
 *   },
 * });
 */
export function useListExecutivesStripeCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListExecutivesStripeCustomerQuery,
    ListExecutivesStripeCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListExecutivesStripeCustomerQuery,
    ListExecutivesStripeCustomerQueryVariables
  >(ListExecutivesStripeCustomerDocument, options)
}
export function useListExecutivesStripeCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListExecutivesStripeCustomerQuery,
    ListExecutivesStripeCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListExecutivesStripeCustomerQuery,
    ListExecutivesStripeCustomerQueryVariables
  >(ListExecutivesStripeCustomerDocument, options)
}
export type ListExecutivesStripeCustomerQueryHookResult = ReturnType<
  typeof useListExecutivesStripeCustomerQuery
>
export type ListExecutivesStripeCustomerLazyQueryHookResult = ReturnType<
  typeof useListExecutivesStripeCustomerLazyQuery
>
export type ListExecutivesStripeCustomerQueryResult = Apollo.QueryResult<
  ListExecutivesStripeCustomerQuery,
  ListExecutivesStripeCustomerQueryVariables
>
export const UpdateOrientationDocument = gql`
  mutation UpdateOrientation($input: UpdateOrientationInput!) {
    updateOrientation(input: $input) {
      users {
        ...Userable_InOrientation
      }
    }
  }
  ${Userable_InOrientationFragmentDoc}
`
export type UpdateOrientationMutationFn = Apollo.MutationFunction<
  UpdateOrientationMutation,
  UpdateOrientationMutationVariables
>

/**
 * __useUpdateOrientationMutation__
 *
 * To run a mutation, you first call `useUpdateOrientationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrientationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrientationMutation, { data, loading, error }] = useUpdateOrientationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrientationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrientationMutation,
    UpdateOrientationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOrientationMutation,
    UpdateOrientationMutationVariables
  >(UpdateOrientationDocument, options)
}
export type UpdateOrientationMutationHookResult = ReturnType<
  typeof useUpdateOrientationMutation
>
export type UpdateOrientationMutationResult =
  Apollo.MutationResult<UpdateOrientationMutation>
export type UpdateOrientationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrientationMutation,
  UpdateOrientationMutationVariables
>
export const ListAutomationTriggersDocument = gql`
  query ListAutomationTriggers {
    list: automationTriggersList @connection(key: "automationTriggers") {
      items {
        ...AutomationTrigger
      }
    }
  }
  ${AutomationTriggerFragmentDoc}
`

/**
 * __useListAutomationTriggersQuery__
 *
 * To run a query within a React component, call `useListAutomationTriggersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAutomationTriggersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAutomationTriggersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAutomationTriggersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAutomationTriggersQuery,
    ListAutomationTriggersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListAutomationTriggersQuery,
    ListAutomationTriggersQueryVariables
  >(ListAutomationTriggersDocument, options)
}
export function useListAutomationTriggersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAutomationTriggersQuery,
    ListAutomationTriggersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListAutomationTriggersQuery,
    ListAutomationTriggersQueryVariables
  >(ListAutomationTriggersDocument, options)
}
export type ListAutomationTriggersQueryHookResult = ReturnType<
  typeof useListAutomationTriggersQuery
>
export type ListAutomationTriggersLazyQueryHookResult = ReturnType<
  typeof useListAutomationTriggersLazyQuery
>
export type ListAutomationTriggersQueryResult = Apollo.QueryResult<
  ListAutomationTriggersQuery,
  ListAutomationTriggersQueryVariables
>
export const ListAutomationsDocument = gql`
  query ListAutomations(
    $after: String
    $first: PositiveInt
    $triggerIds: [String!]
    $messageTemplateIds: [GUID!]
  ) {
    list: automationsList(
      after: $after
      first: $first
      triggerIds: $triggerIds
      messageTemplateIds: $messageTemplateIds
    )
      @connection(
        key: "automations"
        filter: ["triggerIds", "messageTemplateIds"]
      ) {
      after
      items {
        ...Automation
      }
    }
  }
  ${AutomationFragmentDoc}
`

/**
 * __useListAutomationsQuery__
 *
 * To run a query within a React component, call `useListAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAutomationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      triggerIds: // value for 'triggerIds'
 *      messageTemplateIds: // value for 'messageTemplateIds'
 *   },
 * });
 */
export function useListAutomationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAutomationsQuery,
    ListAutomationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAutomationsQuery, ListAutomationsQueryVariables>(
    ListAutomationsDocument,
    options,
  )
}
export function useListAutomationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAutomationsQuery,
    ListAutomationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListAutomationsQuery,
    ListAutomationsQueryVariables
  >(ListAutomationsDocument, options)
}
export type ListAutomationsQueryHookResult = ReturnType<
  typeof useListAutomationsQuery
>
export type ListAutomationsLazyQueryHookResult = ReturnType<
  typeof useListAutomationsLazyQuery
>
export type ListAutomationsQueryResult = Apollo.QueryResult<
  ListAutomationsQuery,
  ListAutomationsQueryVariables
>
export const ListAutomationJobsDocument = gql`
  query ListAutomationJobs(
    $first: PositiveInt
    $before: String
    $automationIds: [GUID!]
    $workspaceId: String
    $userId: String
    $hasCompleted: Boolean
    $isPending: Boolean
    $hasFailed: Boolean
    $hasError: Boolean
    $isReviewed: Boolean
    $hasParams: JSONObject
  ) {
    list: automationJobsList(
      before: $before
      first: $first
      automationIds: $automationIds
      workspaceId: $workspaceId
      userId: $userId
      hasCompleted: $hasCompleted
      isPending: $isPending
      hasFailed: $hasFailed
      hasError: $hasError
      isReviewed: $isReviewed
      hasParams: $hasParams
    )
      @connection(
        key: "automationJobs"
        filter: [
          "automationIds"
          "workspaceId"
          "userId"
          "hasCompleted"
          "isPending"
          "hasFailed"
          "hasError"
          "isReviewed"
          "hasParams"
        ]
      ) {
      before
      items {
        ...AutomationJob_Item
      }
    }
  }
  ${AutomationJob_ItemFragmentDoc}
`

/**
 * __useListAutomationJobsQuery__
 *
 * To run a query within a React component, call `useListAutomationJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAutomationJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAutomationJobsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      automationIds: // value for 'automationIds'
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *      hasCompleted: // value for 'hasCompleted'
 *      isPending: // value for 'isPending'
 *      hasFailed: // value for 'hasFailed'
 *      hasError: // value for 'hasError'
 *      isReviewed: // value for 'isReviewed'
 *      hasParams: // value for 'hasParams'
 *   },
 * });
 */
export function useListAutomationJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAutomationJobsQuery,
    ListAutomationJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListAutomationJobsQuery,
    ListAutomationJobsQueryVariables
  >(ListAutomationJobsDocument, options)
}
export function useListAutomationJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAutomationJobsQuery,
    ListAutomationJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListAutomationJobsQuery,
    ListAutomationJobsQueryVariables
  >(ListAutomationJobsDocument, options)
}
export type ListAutomationJobsQueryHookResult = ReturnType<
  typeof useListAutomationJobsQuery
>
export type ListAutomationJobsLazyQueryHookResult = ReturnType<
  typeof useListAutomationJobsLazyQuery
>
export type ListAutomationJobsQueryResult = Apollo.QueryResult<
  ListAutomationJobsQuery,
  ListAutomationJobsQueryVariables
>
export const GetAutomationJobByIdDocument = gql`
  query GetAutomationJobById($id: GUID!) {
    automationJob: automationJobById(id: $id) {
      ...AutomationJob_Full
    }
  }
  ${AutomationJob_FullFragmentDoc}
`

/**
 * __useGetAutomationJobByIdQuery__
 *
 * To run a query within a React component, call `useGetAutomationJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationJobByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAutomationJobByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutomationJobByIdQuery,
    GetAutomationJobByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAutomationJobByIdQuery,
    GetAutomationJobByIdQueryVariables
  >(GetAutomationJobByIdDocument, options)
}
export function useGetAutomationJobByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutomationJobByIdQuery,
    GetAutomationJobByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAutomationJobByIdQuery,
    GetAutomationJobByIdQueryVariables
  >(GetAutomationJobByIdDocument, options)
}
export type GetAutomationJobByIdQueryHookResult = ReturnType<
  typeof useGetAutomationJobByIdQuery
>
export type GetAutomationJobByIdLazyQueryHookResult = ReturnType<
  typeof useGetAutomationJobByIdLazyQuery
>
export type GetAutomationJobByIdQueryResult = Apollo.QueryResult<
  GetAutomationJobByIdQuery,
  GetAutomationJobByIdQueryVariables
>
export const ReviewAutomationJobDocument = gql`
  mutation ReviewAutomationJob($input: ReviewAutomationJobInput!) {
    reviewAutomationJob(input: $input) {
      automationJob {
        ...AutomationJob_Full
      }
    }
  }
  ${AutomationJob_FullFragmentDoc}
`
export type ReviewAutomationJobMutationFn = Apollo.MutationFunction<
  ReviewAutomationJobMutation,
  ReviewAutomationJobMutationVariables
>

/**
 * __useReviewAutomationJobMutation__
 *
 * To run a mutation, you first call `useReviewAutomationJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewAutomationJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewAutomationJobMutation, { data, loading, error }] = useReviewAutomationJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewAutomationJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewAutomationJobMutation,
    ReviewAutomationJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReviewAutomationJobMutation,
    ReviewAutomationJobMutationVariables
  >(ReviewAutomationJobDocument, options)
}
export type ReviewAutomationJobMutationHookResult = ReturnType<
  typeof useReviewAutomationJobMutation
>
export type ReviewAutomationJobMutationResult =
  Apollo.MutationResult<ReviewAutomationJobMutation>
export type ReviewAutomationJobMutationOptions = Apollo.BaseMutationOptions<
  ReviewAutomationJobMutation,
  ReviewAutomationJobMutationVariables
>
export const CancelAutomationJobDocument = gql`
  mutation CancelAutomationJob($input: CancelAutomationJobInput!) {
    cancelAutomationJob(input: $input) {
      automationJob {
        ...AutomationJob_Full
      }
    }
  }
  ${AutomationJob_FullFragmentDoc}
`
export type CancelAutomationJobMutationFn = Apollo.MutationFunction<
  CancelAutomationJobMutation,
  CancelAutomationJobMutationVariables
>

/**
 * __useCancelAutomationJobMutation__
 *
 * To run a mutation, you first call `useCancelAutomationJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutomationJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutomationJobMutation, { data, loading, error }] = useCancelAutomationJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAutomationJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAutomationJobMutation,
    CancelAutomationJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelAutomationJobMutation,
    CancelAutomationJobMutationVariables
  >(CancelAutomationJobDocument, options)
}
export type CancelAutomationJobMutationHookResult = ReturnType<
  typeof useCancelAutomationJobMutation
>
export type CancelAutomationJobMutationResult =
  Apollo.MutationResult<CancelAutomationJobMutation>
export type CancelAutomationJobMutationOptions = Apollo.BaseMutationOptions<
  CancelAutomationJobMutation,
  CancelAutomationJobMutationVariables
>
export const CancelScheduledChurnDocument = gql`
  mutation CancelScheduledChurn($input: CancelScheduledChurnInput!) {
    cancelScheduledChurn(input: $input) {
      canceledJobs {
        ...AutomationJob_Full
      }
    }
  }
  ${AutomationJob_FullFragmentDoc}
`
export type CancelScheduledChurnMutationFn = Apollo.MutationFunction<
  CancelScheduledChurnMutation,
  CancelScheduledChurnMutationVariables
>

/**
 * __useCancelScheduledChurnMutation__
 *
 * To run a mutation, you first call `useCancelScheduledChurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledChurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledChurnMutation, { data, loading, error }] = useCancelScheduledChurnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduledChurnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduledChurnMutation,
    CancelScheduledChurnMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelScheduledChurnMutation,
    CancelScheduledChurnMutationVariables
  >(CancelScheduledChurnDocument, options)
}
export type CancelScheduledChurnMutationHookResult = ReturnType<
  typeof useCancelScheduledChurnMutation
>
export type CancelScheduledChurnMutationResult =
  Apollo.MutationResult<CancelScheduledChurnMutation>
export type CancelScheduledChurnMutationOptions = Apollo.BaseMutationOptions<
  CancelScheduledChurnMutation,
  CancelScheduledChurnMutationVariables
>
export const ScheduleAutopilotJobsDocument = gql`
  mutation ScheduleAutopilotJobs($input: ScheduleAutopilotJobsInput!) {
    scheduleAutopilotJobs(input: $input) {
      scheduledJobs {
        ...AutomationJob_Full
      }
    }
  }
  ${AutomationJob_FullFragmentDoc}
`
export type ScheduleAutopilotJobsMutationFn = Apollo.MutationFunction<
  ScheduleAutopilotJobsMutation,
  ScheduleAutopilotJobsMutationVariables
>

/**
 * __useScheduleAutopilotJobsMutation__
 *
 * To run a mutation, you first call `useScheduleAutopilotJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleAutopilotJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleAutopilotJobsMutation, { data, loading, error }] = useScheduleAutopilotJobsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleAutopilotJobsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleAutopilotJobsMutation,
    ScheduleAutopilotJobsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ScheduleAutopilotJobsMutation,
    ScheduleAutopilotJobsMutationVariables
  >(ScheduleAutopilotJobsDocument, options)
}
export type ScheduleAutopilotJobsMutationHookResult = ReturnType<
  typeof useScheduleAutopilotJobsMutation
>
export type ScheduleAutopilotJobsMutationResult =
  Apollo.MutationResult<ScheduleAutopilotJobsMutation>
export type ScheduleAutopilotJobsMutationOptions = Apollo.BaseMutationOptions<
  ScheduleAutopilotJobsMutation,
  ScheduleAutopilotJobsMutationVariables
>
export const RunAutopilotJobManuallyDocument = gql`
  mutation RunAutopilotJobManually($input: RunAutopilotJobManuallyInput!) {
    runAutopilotJobManually(input: $input) {
      isSuccess
    }
  }
`
export type RunAutopilotJobManuallyMutationFn = Apollo.MutationFunction<
  RunAutopilotJobManuallyMutation,
  RunAutopilotJobManuallyMutationVariables
>

/**
 * __useRunAutopilotJobManuallyMutation__
 *
 * To run a mutation, you first call `useRunAutopilotJobManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunAutopilotJobManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runAutopilotJobManuallyMutation, { data, loading, error }] = useRunAutopilotJobManuallyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunAutopilotJobManuallyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunAutopilotJobManuallyMutation,
    RunAutopilotJobManuallyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RunAutopilotJobManuallyMutation,
    RunAutopilotJobManuallyMutationVariables
  >(RunAutopilotJobManuallyDocument, options)
}
export type RunAutopilotJobManuallyMutationHookResult = ReturnType<
  typeof useRunAutopilotJobManuallyMutation
>
export type RunAutopilotJobManuallyMutationResult =
  Apollo.MutationResult<RunAutopilotJobManuallyMutation>
export type RunAutopilotJobManuallyMutationOptions = Apollo.BaseMutationOptions<
  RunAutopilotJobManuallyMutation,
  RunAutopilotJobManuallyMutationVariables
>
export const ListMessageTemplatesDocument = gql`
  query ListMessageTemplates(
    $after: String
    $first: PositiveInt
    $kinds: [MessageTemplateKind!]
    $ids: [GUID!]
    $q: String
  ) {
    list: messageTemplatesList(
      after: $after
      first: $first
      kinds: $kinds
      ids: $ids
      q: $q
    ) @connection(key: "messageTemplates", filter: ["kinds", "ids", "q"]) {
      after
      items {
        ...MessageTemplate_Labels
      }
    }
  }
  ${MessageTemplate_LabelsFragmentDoc}
`

/**
 * __useListMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useListMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessageTemplatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      kinds: // value for 'kinds'
 *      ids: // value for 'ids'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useListMessageTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMessageTemplatesQuery,
    ListMessageTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListMessageTemplatesQuery,
    ListMessageTemplatesQueryVariables
  >(ListMessageTemplatesDocument, options)
}
export function useListMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMessageTemplatesQuery,
    ListMessageTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListMessageTemplatesQuery,
    ListMessageTemplatesQueryVariables
  >(ListMessageTemplatesDocument, options)
}
export type ListMessageTemplatesQueryHookResult = ReturnType<
  typeof useListMessageTemplatesQuery
>
export type ListMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useListMessageTemplatesLazyQuery
>
export type ListMessageTemplatesQueryResult = Apollo.QueryResult<
  ListMessageTemplatesQuery,
  ListMessageTemplatesQueryVariables
>
export const GetMessageTemplateDocument = gql`
  query GetMessageTemplate($id: GUID!) {
    messageTemplate: messageTemplateById(id: $id) {
      ...MessageTemplate
    }
  }
  ${MessageTemplateFragmentDoc}
`

/**
 * __useGetMessageTemplateQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >(GetMessageTemplateDocument, options)
}
export function useGetMessageTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >(GetMessageTemplateDocument, options)
}
export type GetMessageTemplateQueryHookResult = ReturnType<
  typeof useGetMessageTemplateQuery
>
export type GetMessageTemplateLazyQueryHookResult = ReturnType<
  typeof useGetMessageTemplateLazyQuery
>
export type GetMessageTemplateQueryResult = Apollo.QueryResult<
  GetMessageTemplateQuery,
  GetMessageTemplateQueryVariables
>
export const UpsertMessageTemplateDocument = gql`
  mutation UpsertMessageTemplate($input: UpsertMessageTemplateInput!) {
    upsertMessageTemplate(input: $input) {
      messageTemplate {
        ...MessageTemplate
      }
    }
  }
  ${MessageTemplateFragmentDoc}
`
export type UpsertMessageTemplateMutationFn = Apollo.MutationFunction<
  UpsertMessageTemplateMutation,
  UpsertMessageTemplateMutationVariables
>

/**
 * __useUpsertMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMessageTemplateMutation, { data, loading, error }] = useUpsertMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMessageTemplateMutation,
    UpsertMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertMessageTemplateMutation,
    UpsertMessageTemplateMutationVariables
  >(UpsertMessageTemplateDocument, options)
}
export type UpsertMessageTemplateMutationHookResult = ReturnType<
  typeof useUpsertMessageTemplateMutation
>
export type UpsertMessageTemplateMutationResult =
  Apollo.MutationResult<UpsertMessageTemplateMutation>
export type UpsertMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertMessageTemplateMutation,
  UpsertMessageTemplateMutationVariables
>
export const GetAdminableConfigDocument = gql`
  query GetAdminableConfig($userId: ID!) {
    user: userById(id: $userId) {
      ...Adminable_Config
      ...Assistantable_AssistantData
    }
  }
  ${Adminable_ConfigFragmentDoc}
  ${Assistantable_AssistantDataFragmentDoc}
`

/**
 * __useGetAdminableConfigQuery__
 *
 * To run a query within a React component, call `useGetAdminableConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminableConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminableConfigQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAdminableConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminableConfigQuery,
    GetAdminableConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAdminableConfigQuery,
    GetAdminableConfigQueryVariables
  >(GetAdminableConfigDocument, options)
}
export function useGetAdminableConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminableConfigQuery,
    GetAdminableConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAdminableConfigQuery,
    GetAdminableConfigQueryVariables
  >(GetAdminableConfigDocument, options)
}
export type GetAdminableConfigQueryHookResult = ReturnType<
  typeof useGetAdminableConfigQuery
>
export type GetAdminableConfigLazyQueryHookResult = ReturnType<
  typeof useGetAdminableConfigLazyQuery
>
export type GetAdminableConfigQueryResult = Apollo.QueryResult<
  GetAdminableConfigQuery,
  GetAdminableConfigQueryVariables
>
export const UpdateAdminableDocument = gql`
  mutation UpdateAdminable($input: UpdateAdminableInput!) {
    updateAdminable(input: $input) {
      user {
        id
        ... on Adminable {
          thirtyDayCheckInCallUrl
          scheduleOnboardingConfig {
            ...ScheduleOnboardingConfig_Writable
          }
        }
      }
    }
  }
  ${ScheduleOnboardingConfig_WritableFragmentDoc}
`
export type UpdateAdminableMutationFn = Apollo.MutationFunction<
  UpdateAdminableMutation,
  UpdateAdminableMutationVariables
>

/**
 * __useUpdateAdminableMutation__
 *
 * To run a mutation, you first call `useUpdateAdminableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminableMutation, { data, loading, error }] = useUpdateAdminableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdminableMutation,
    UpdateAdminableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAdminableMutation,
    UpdateAdminableMutationVariables
  >(UpdateAdminableDocument, options)
}
export type UpdateAdminableMutationHookResult = ReturnType<
  typeof useUpdateAdminableMutation
>
export type UpdateAdminableMutationResult =
  Apollo.MutationResult<UpdateAdminableMutation>
export type UpdateAdminableMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminableMutation,
  UpdateAdminableMutationVariables
>
export const PerformanceScoreMonthDocument = gql`
  query PerformanceScoreMonth($userId: ID!, $date: Date) {
    user: userById(id: $userId) {
      ... on AssistantUser {
        id
        performanceScore(date: $date) {
          id
          series {
            ...PerformanceScoreMonthSeries
          }
        }
      }
    }
  }
  ${PerformanceScoreMonthSeriesFragmentDoc}
`

/**
 * __usePerformanceScoreMonthQuery__
 *
 * To run a query within a React component, call `usePerformanceScoreMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceScoreMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceScoreMonthQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePerformanceScoreMonthQuery(
  baseOptions: Apollo.QueryHookOptions<
    PerformanceScoreMonthQuery,
    PerformanceScoreMonthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PerformanceScoreMonthQuery,
    PerformanceScoreMonthQueryVariables
  >(PerformanceScoreMonthDocument, options)
}
export function usePerformanceScoreMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PerformanceScoreMonthQuery,
    PerformanceScoreMonthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PerformanceScoreMonthQuery,
    PerformanceScoreMonthQueryVariables
  >(PerformanceScoreMonthDocument, options)
}
export type PerformanceScoreMonthQueryHookResult = ReturnType<
  typeof usePerformanceScoreMonthQuery
>
export type PerformanceScoreMonthLazyQueryHookResult = ReturnType<
  typeof usePerformanceScoreMonthLazyQuery
>
export type PerformanceScoreMonthQueryResult = Apollo.QueryResult<
  PerformanceScoreMonthQuery,
  PerformanceScoreMonthQueryVariables
>
export const PerformanceScoreHistoricalDocument = gql`
  query PerformanceScoreHistorical($userId: ID!) {
    user: userById(id: $userId) {
      ... on AssistantUser {
        id
        performanceScore(last: 9) {
          id
          series {
            ...PerformanceScoreHistoricalSeries
          }
        }
      }
    }
  }
  ${PerformanceScoreHistoricalSeriesFragmentDoc}
`

/**
 * __usePerformanceScoreHistoricalQuery__
 *
 * To run a query within a React component, call `usePerformanceScoreHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceScoreHistoricalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceScoreHistoricalQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePerformanceScoreHistoricalQuery(
  baseOptions: Apollo.QueryHookOptions<
    PerformanceScoreHistoricalQuery,
    PerformanceScoreHistoricalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PerformanceScoreHistoricalQuery,
    PerformanceScoreHistoricalQueryVariables
  >(PerformanceScoreHistoricalDocument, options)
}
export function usePerformanceScoreHistoricalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PerformanceScoreHistoricalQuery,
    PerformanceScoreHistoricalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PerformanceScoreHistoricalQuery,
    PerformanceScoreHistoricalQueryVariables
  >(PerformanceScoreHistoricalDocument, options)
}
export type PerformanceScoreHistoricalQueryHookResult = ReturnType<
  typeof usePerformanceScoreHistoricalQuery
>
export type PerformanceScoreHistoricalLazyQueryHookResult = ReturnType<
  typeof usePerformanceScoreHistoricalLazyQuery
>
export type PerformanceScoreHistoricalQueryResult = Apollo.QueryResult<
  PerformanceScoreHistoricalQuery,
  PerformanceScoreHistoricalQueryVariables
>
export const PerformanceScoreEventsDocument = gql`
  query PerformanceScoreEvents($userId: ID!, $date: Date) {
    user: userById(id: $userId) {
      ... on AssistantUser {
        id
        performanceScore(date: $date) {
          id
          series {
            ...PerformanceScoreEventsSeries
          }
        }
      }
    }
  }
  ${PerformanceScoreEventsSeriesFragmentDoc}
`

/**
 * __usePerformanceScoreEventsQuery__
 *
 * To run a query within a React component, call `usePerformanceScoreEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceScoreEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceScoreEventsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePerformanceScoreEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PerformanceScoreEventsQuery,
    PerformanceScoreEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PerformanceScoreEventsQuery,
    PerformanceScoreEventsQueryVariables
  >(PerformanceScoreEventsDocument, options)
}
export function usePerformanceScoreEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PerformanceScoreEventsQuery,
    PerformanceScoreEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PerformanceScoreEventsQuery,
    PerformanceScoreEventsQueryVariables
  >(PerformanceScoreEventsDocument, options)
}
export type PerformanceScoreEventsQueryHookResult = ReturnType<
  typeof usePerformanceScoreEventsQuery
>
export type PerformanceScoreEventsLazyQueryHookResult = ReturnType<
  typeof usePerformanceScoreEventsLazyQuery
>
export type PerformanceScoreEventsQueryResult = Apollo.QueryResult<
  PerformanceScoreEventsQuery,
  PerformanceScoreEventsQueryVariables
>
export const ListMetricEventsDocument = gql`
  query ListMetricEvents {
    list: metricEventsList {
      items {
        ...MetricEvent
      }
    }
  }
  ${MetricEventFragmentDoc}
`

/**
 * __useListMetricEventsQuery__
 *
 * To run a query within a React component, call `useListMetricEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMetricEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMetricEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMetricEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMetricEventsQuery,
    ListMetricEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListMetricEventsQuery, ListMetricEventsQueryVariables>(
    ListMetricEventsDocument,
    options,
  )
}
export function useListMetricEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMetricEventsQuery,
    ListMetricEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListMetricEventsQuery,
    ListMetricEventsQueryVariables
  >(ListMetricEventsDocument, options)
}
export type ListMetricEventsQueryHookResult = ReturnType<
  typeof useListMetricEventsQuery
>
export type ListMetricEventsLazyQueryHookResult = ReturnType<
  typeof useListMetricEventsLazyQuery
>
export type ListMetricEventsQueryResult = Apollo.QueryResult<
  ListMetricEventsQuery,
  ListMetricEventsQueryVariables
>
export const UpdateMetricEventDocument = gql`
  mutation UpdateMetricEvent($input: UpdateMetricEventInput!) {
    updateMetricEvent(input: $input) {
      metricEvent {
        ...MetricEvent
      }
    }
  }
  ${MetricEventFragmentDoc}
`
export type UpdateMetricEventMutationFn = Apollo.MutationFunction<
  UpdateMetricEventMutation,
  UpdateMetricEventMutationVariables
>

/**
 * __useUpdateMetricEventMutation__
 *
 * To run a mutation, you first call `useUpdateMetricEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetricEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetricEventMutation, { data, loading, error }] = useUpdateMetricEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMetricEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMetricEventMutation,
    UpdateMetricEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMetricEventMutation,
    UpdateMetricEventMutationVariables
  >(UpdateMetricEventDocument, options)
}
export type UpdateMetricEventMutationHookResult = ReturnType<
  typeof useUpdateMetricEventMutation
>
export type UpdateMetricEventMutationResult =
  Apollo.MutationResult<UpdateMetricEventMutation>
export type UpdateMetricEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateMetricEventMutation,
  UpdateMetricEventMutationVariables
>
export const UpdateMetricEventPropertyDocument = gql`
  mutation UpdateMetricEventProperty($input: UpdateMetricEventPropertyInput!) {
    updateMetricEventProperty(input: $input) {
      metricEvent {
        ...MetricEvent
      }
    }
  }
  ${MetricEventFragmentDoc}
`
export type UpdateMetricEventPropertyMutationFn = Apollo.MutationFunction<
  UpdateMetricEventPropertyMutation,
  UpdateMetricEventPropertyMutationVariables
>

/**
 * __useUpdateMetricEventPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateMetricEventPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetricEventPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetricEventPropertyMutation, { data, loading, error }] = useUpdateMetricEventPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMetricEventPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMetricEventPropertyMutation,
    UpdateMetricEventPropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMetricEventPropertyMutation,
    UpdateMetricEventPropertyMutationVariables
  >(UpdateMetricEventPropertyDocument, options)
}
export type UpdateMetricEventPropertyMutationHookResult = ReturnType<
  typeof useUpdateMetricEventPropertyMutation
>
export type UpdateMetricEventPropertyMutationResult =
  Apollo.MutationResult<UpdateMetricEventPropertyMutation>
export type UpdateMetricEventPropertyMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMetricEventPropertyMutation,
    UpdateMetricEventPropertyMutationVariables
  >
export const TimeTrackingEntriesListDocument = gql`
  query TimeTrackingEntriesList(
    $assistantIds: [String!]
    $executiveIds: [String!]
    $workspaceIds: [String]
    $endedBefore: DateTime
    $endedAfter: DateTime
    $after: String
    $first: PositiveInt
  ) {
    list: timeTrackingEntriesList(
      assistantIds: $assistantIds
      executiveIds: $executiveIds
      workspaceIds: $workspaceIds
      endedBefore: $endedBefore
      endedAfter: $endedAfter
      after: $after
      first: $first
    ) {
      id
      after
      items {
        ...TimeTrackingEntry
      }
    }
  }
  ${TimeTrackingEntryFragmentDoc}
`

/**
 * __useTimeTrackingEntriesListQuery__
 *
 * To run a query within a React component, call `useTimeTrackingEntriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingEntriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingEntriesListQuery({
 *   variables: {
 *      assistantIds: // value for 'assistantIds'
 *      executiveIds: // value for 'executiveIds'
 *      workspaceIds: // value for 'workspaceIds'
 *      endedBefore: // value for 'endedBefore'
 *      endedAfter: // value for 'endedAfter'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTimeTrackingEntriesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimeTrackingEntriesListQuery,
    TimeTrackingEntriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TimeTrackingEntriesListQuery,
    TimeTrackingEntriesListQueryVariables
  >(TimeTrackingEntriesListDocument, options)
}
export function useTimeTrackingEntriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeTrackingEntriesListQuery,
    TimeTrackingEntriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TimeTrackingEntriesListQuery,
    TimeTrackingEntriesListQueryVariables
  >(TimeTrackingEntriesListDocument, options)
}
export type TimeTrackingEntriesListQueryHookResult = ReturnType<
  typeof useTimeTrackingEntriesListQuery
>
export type TimeTrackingEntriesListLazyQueryHookResult = ReturnType<
  typeof useTimeTrackingEntriesListLazyQuery
>
export type TimeTrackingEntriesListQueryResult = Apollo.QueryResult<
  TimeTrackingEntriesListQuery,
  TimeTrackingEntriesListQueryVariables
>
export const TimeTrackingEntryUpsertedDocument = gql`
  subscription TimeTrackingEntryUpserted(
    $assistantIds: [String!]
    $executiveIds: [String!]
    $workspaceIds: [String]
    $endedBefore: DateTime
    $endedAfter: DateTime
  ) {
    item: timeTrackingEntryUpserted(
      assistantIds: $assistantIds
      executiveIds: $executiveIds
      workspaceIds: $workspaceIds
      endedBefore: $endedBefore
      endedAfter: $endedAfter
    ) {
      ...TimeTrackingEntry
    }
  }
  ${TimeTrackingEntryFragmentDoc}
`

/**
 * __useTimeTrackingEntryUpsertedSubscription__
 *
 * To run a query within a React component, call `useTimeTrackingEntryUpsertedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingEntryUpsertedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingEntryUpsertedSubscription({
 *   variables: {
 *      assistantIds: // value for 'assistantIds'
 *      executiveIds: // value for 'executiveIds'
 *      workspaceIds: // value for 'workspaceIds'
 *      endedBefore: // value for 'endedBefore'
 *      endedAfter: // value for 'endedAfter'
 *   },
 * });
 */
export function useTimeTrackingEntryUpsertedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TimeTrackingEntryUpsertedSubscription,
    TimeTrackingEntryUpsertedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    TimeTrackingEntryUpsertedSubscription,
    TimeTrackingEntryUpsertedSubscriptionVariables
  >(TimeTrackingEntryUpsertedDocument, options)
}
export type TimeTrackingEntryUpsertedSubscriptionHookResult = ReturnType<
  typeof useTimeTrackingEntryUpsertedSubscription
>
export type TimeTrackingEntryUpsertedSubscriptionResult =
  Apollo.SubscriptionResult<TimeTrackingEntryUpsertedSubscription>
export const TimeTrackingGeneralInfosDocument = gql`
  query TimeTrackingGeneralInfos(
    $assistantIds: [String!]
    $executiveIds: [String!]
    $workspaceIds: [String]
    $endedBefore: DateTime
    $endedAfter: DateTime
  ) {
    timeTrackingEntriesList(
      assistantIds: $assistantIds
      executiveIds: $executiveIds
      workspaceIds: $workspaceIds
      endedBefore: $endedBefore
      endedAfter: $endedAfter
    ) {
      id
      totalDuration
      lockedAt
    }
  }
`

/**
 * __useTimeTrackingGeneralInfosQuery__
 *
 * To run a query within a React component, call `useTimeTrackingGeneralInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingGeneralInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingGeneralInfosQuery({
 *   variables: {
 *      assistantIds: // value for 'assistantIds'
 *      executiveIds: // value for 'executiveIds'
 *      workspaceIds: // value for 'workspaceIds'
 *      endedBefore: // value for 'endedBefore'
 *      endedAfter: // value for 'endedAfter'
 *   },
 * });
 */
export function useTimeTrackingGeneralInfosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimeTrackingGeneralInfosQuery,
    TimeTrackingGeneralInfosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TimeTrackingGeneralInfosQuery,
    TimeTrackingGeneralInfosQueryVariables
  >(TimeTrackingGeneralInfosDocument, options)
}
export function useTimeTrackingGeneralInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeTrackingGeneralInfosQuery,
    TimeTrackingGeneralInfosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TimeTrackingGeneralInfosQuery,
    TimeTrackingGeneralInfosQueryVariables
  >(TimeTrackingGeneralInfosDocument, options)
}
export type TimeTrackingGeneralInfosQueryHookResult = ReturnType<
  typeof useTimeTrackingGeneralInfosQuery
>
export type TimeTrackingGeneralInfosLazyQueryHookResult = ReturnType<
  typeof useTimeTrackingGeneralInfosLazyQuery
>
export type TimeTrackingGeneralInfosQueryResult = Apollo.QueryResult<
  TimeTrackingGeneralInfosQuery,
  TimeTrackingGeneralInfosQueryVariables
>
export const GetTimeTrackingEntryTemplatesListDocument = gql`
  query GetTimeTrackingEntryTemplatesList($isWorkspaced: Boolean) {
    timeTrackingEntryTemplatesList(isWorkspaced: $isWorkspaced) {
      items {
        ...TimeTrackingEntryTemplate
      }
    }
  }
  ${TimeTrackingEntryTemplateFragmentDoc}
`

/**
 * __useGetTimeTrackingEntryTemplatesListQuery__
 *
 * To run a query within a React component, call `useGetTimeTrackingEntryTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeTrackingEntryTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeTrackingEntryTemplatesListQuery({
 *   variables: {
 *      isWorkspaced: // value for 'isWorkspaced'
 *   },
 * });
 */
export function useGetTimeTrackingEntryTemplatesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTimeTrackingEntryTemplatesListQuery,
    GetTimeTrackingEntryTemplatesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTimeTrackingEntryTemplatesListQuery,
    GetTimeTrackingEntryTemplatesListQueryVariables
  >(GetTimeTrackingEntryTemplatesListDocument, options)
}
export function useGetTimeTrackingEntryTemplatesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeTrackingEntryTemplatesListQuery,
    GetTimeTrackingEntryTemplatesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTimeTrackingEntryTemplatesListQuery,
    GetTimeTrackingEntryTemplatesListQueryVariables
  >(GetTimeTrackingEntryTemplatesListDocument, options)
}
export type GetTimeTrackingEntryTemplatesListQueryHookResult = ReturnType<
  typeof useGetTimeTrackingEntryTemplatesListQuery
>
export type GetTimeTrackingEntryTemplatesListLazyQueryHookResult = ReturnType<
  typeof useGetTimeTrackingEntryTemplatesListLazyQuery
>
export type GetTimeTrackingEntryTemplatesListQueryResult = Apollo.QueryResult<
  GetTimeTrackingEntryTemplatesListQuery,
  GetTimeTrackingEntryTemplatesListQueryVariables
>
export const UpdateTimeTrackingEntryDocument = gql`
  mutation UpdateTimeTrackingEntry($input: UpdateTimeTrackingEntryInput!) {
    updateTimeTrackingEntry(input: $input) {
      timeTrackingEntry {
        ...TimeTrackingEntry
      }
    }
  }
  ${TimeTrackingEntryFragmentDoc}
`
export type UpdateTimeTrackingEntryMutationFn = Apollo.MutationFunction<
  UpdateTimeTrackingEntryMutation,
  UpdateTimeTrackingEntryMutationVariables
>

/**
 * __useUpdateTimeTrackingEntryMutation__
 *
 * To run a mutation, you first call `useUpdateTimeTrackingEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeTrackingEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeTrackingEntryMutation, { data, loading, error }] = useUpdateTimeTrackingEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeTrackingEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTimeTrackingEntryMutation,
    UpdateTimeTrackingEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTimeTrackingEntryMutation,
    UpdateTimeTrackingEntryMutationVariables
  >(UpdateTimeTrackingEntryDocument, options)
}
export type UpdateTimeTrackingEntryMutationHookResult = ReturnType<
  typeof useUpdateTimeTrackingEntryMutation
>
export type UpdateTimeTrackingEntryMutationResult =
  Apollo.MutationResult<UpdateTimeTrackingEntryMutation>
export type UpdateTimeTrackingEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeTrackingEntryMutation,
  UpdateTimeTrackingEntryMutationVariables
>
export const UpdateTimeTrackingEntryBillToDocument = gql`
  mutation UpdateTimeTrackingEntryBillTo(
    $input: UpdateTimeTrackingEntryBillToInput!
  ) {
    updateTimeTrackingEntryBillTo(input: $input) {
      timeTrackingEntry {
        ...TimeTrackingEntry
      }
    }
  }
  ${TimeTrackingEntryFragmentDoc}
`
export type UpdateTimeTrackingEntryBillToMutationFn = Apollo.MutationFunction<
  UpdateTimeTrackingEntryBillToMutation,
  UpdateTimeTrackingEntryBillToMutationVariables
>

/**
 * __useUpdateTimeTrackingEntryBillToMutation__
 *
 * To run a mutation, you first call `useUpdateTimeTrackingEntryBillToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeTrackingEntryBillToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeTrackingEntryBillToMutation, { data, loading, error }] = useUpdateTimeTrackingEntryBillToMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeTrackingEntryBillToMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTimeTrackingEntryBillToMutation,
    UpdateTimeTrackingEntryBillToMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTimeTrackingEntryBillToMutation,
    UpdateTimeTrackingEntryBillToMutationVariables
  >(UpdateTimeTrackingEntryBillToDocument, options)
}
export type UpdateTimeTrackingEntryBillToMutationHookResult = ReturnType<
  typeof useUpdateTimeTrackingEntryBillToMutation
>
export type UpdateTimeTrackingEntryBillToMutationResult =
  Apollo.MutationResult<UpdateTimeTrackingEntryBillToMutation>
export type UpdateTimeTrackingEntryBillToMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTimeTrackingEntryBillToMutation,
    UpdateTimeTrackingEntryBillToMutationVariables
  >
export const DeleteTimeTrackingEntryDocument = gql`
  mutation DeleteTimeTrackingEntry($input: DeleteTimeTrackingEntryInput!) {
    deleteTimeTrackingEntry(input: $input) {
      timeTrackingEntry {
        id
      }
    }
  }
`
export type DeleteTimeTrackingEntryMutationFn = Apollo.MutationFunction<
  DeleteTimeTrackingEntryMutation,
  DeleteTimeTrackingEntryMutationVariables
>

/**
 * __useDeleteTimeTrackingEntryMutation__
 *
 * To run a mutation, you first call `useDeleteTimeTrackingEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeTrackingEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeTrackingEntryMutation, { data, loading, error }] = useDeleteTimeTrackingEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimeTrackingEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTimeTrackingEntryMutation,
    DeleteTimeTrackingEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteTimeTrackingEntryMutation,
    DeleteTimeTrackingEntryMutationVariables
  >(DeleteTimeTrackingEntryDocument, options)
}
export type DeleteTimeTrackingEntryMutationHookResult = ReturnType<
  typeof useDeleteTimeTrackingEntryMutation
>
export type DeleteTimeTrackingEntryMutationResult =
  Apollo.MutationResult<DeleteTimeTrackingEntryMutation>
export type DeleteTimeTrackingEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteTimeTrackingEntryMutation,
  DeleteTimeTrackingEntryMutationVariables
>
export const LockTimeTrackingEntriesDocument = gql`
  mutation LockTimeTrackingEntries($input: LockTimeTrackingEntriesInput!) {
    lockTimeTrackingEntries(input: $input) {
      isSuccess
    }
  }
`
export type LockTimeTrackingEntriesMutationFn = Apollo.MutationFunction<
  LockTimeTrackingEntriesMutation,
  LockTimeTrackingEntriesMutationVariables
>

/**
 * __useLockTimeTrackingEntriesMutation__
 *
 * To run a mutation, you first call `useLockTimeTrackingEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockTimeTrackingEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockTimeTrackingEntriesMutation, { data, loading, error }] = useLockTimeTrackingEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLockTimeTrackingEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LockTimeTrackingEntriesMutation,
    LockTimeTrackingEntriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LockTimeTrackingEntriesMutation,
    LockTimeTrackingEntriesMutationVariables
  >(LockTimeTrackingEntriesDocument, options)
}
export type LockTimeTrackingEntriesMutationHookResult = ReturnType<
  typeof useLockTimeTrackingEntriesMutation
>
export type LockTimeTrackingEntriesMutationResult =
  Apollo.MutationResult<LockTimeTrackingEntriesMutation>
export type LockTimeTrackingEntriesMutationOptions = Apollo.BaseMutationOptions<
  LockTimeTrackingEntriesMutation,
  LockTimeTrackingEntriesMutationVariables
>
export const TimeTrackingEntriesListBulkDocument = gql`
  query TimeTrackingEntriesListBulk(
    $assistantIds: [String!]
    $executiveIds: [String!]
    $workspaceIds: [String]
    $endedBefore: DateTime
    $endedAfter: DateTime
    $after: String
    $first: PositiveInt
  ) {
    list: timeTrackingEntriesList(
      assistantIds: $assistantIds
      executiveIds: $executiveIds
      workspaceIds: $workspaceIds
      endedBefore: $endedBefore
      endedAfter: $endedAfter
      after: $after
      first: $first
    ) {
      after
      items {
        ...TimeTrackingEntry_Bulk
      }
    }
  }
  ${TimeTrackingEntry_BulkFragmentDoc}
`

/**
 * __useTimeTrackingEntriesListBulkQuery__
 *
 * To run a query within a React component, call `useTimeTrackingEntriesListBulkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingEntriesListBulkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingEntriesListBulkQuery({
 *   variables: {
 *      assistantIds: // value for 'assistantIds'
 *      executiveIds: // value for 'executiveIds'
 *      workspaceIds: // value for 'workspaceIds'
 *      endedBefore: // value for 'endedBefore'
 *      endedAfter: // value for 'endedAfter'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTimeTrackingEntriesListBulkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimeTrackingEntriesListBulkQuery,
    TimeTrackingEntriesListBulkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TimeTrackingEntriesListBulkQuery,
    TimeTrackingEntriesListBulkQueryVariables
  >(TimeTrackingEntriesListBulkDocument, options)
}
export function useTimeTrackingEntriesListBulkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeTrackingEntriesListBulkQuery,
    TimeTrackingEntriesListBulkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TimeTrackingEntriesListBulkQuery,
    TimeTrackingEntriesListBulkQueryVariables
  >(TimeTrackingEntriesListBulkDocument, options)
}
export type TimeTrackingEntriesListBulkQueryHookResult = ReturnType<
  typeof useTimeTrackingEntriesListBulkQuery
>
export type TimeTrackingEntriesListBulkLazyQueryHookResult = ReturnType<
  typeof useTimeTrackingEntriesListBulkLazyQuery
>
export type TimeTrackingEntriesListBulkQueryResult = Apollo.QueryResult<
  TimeTrackingEntriesListBulkQuery,
  TimeTrackingEntriesListBulkQueryVariables
>
export const ListTeamsDocument = gql`
  query ListTeams(
    $after: String
    $first: PositiveInt
    $q: String
    $isArchived: Boolean
  ) {
    list: teamsList(
      after: $after
      first: $first
      q: $q
      isArchived: $isArchived
    ) {
      after
      items {
        ...BasicTeam
      }
    }
  }
  ${BasicTeamFragmentDoc}
`

/**
 * __useListTeamsQuery__
 *
 * To run a query within a React component, call `useListTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      q: // value for 'q'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useListTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTeamsQuery,
    ListTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTeamsQuery, ListTeamsQueryVariables>(
    ListTeamsDocument,
    options,
  )
}
export function useListTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTeamsQuery,
    ListTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTeamsQuery, ListTeamsQueryVariables>(
    ListTeamsDocument,
    options,
  )
}
export type ListTeamsQueryHookResult = ReturnType<typeof useListTeamsQuery>
export type ListTeamsLazyQueryHookResult = ReturnType<
  typeof useListTeamsLazyQuery
>
export type ListTeamsQueryResult = Apollo.QueryResult<
  ListTeamsQuery,
  ListTeamsQueryVariables
>
export const GetTeamDocument = gql`
  query GetTeam($id: ID!, $date: DateTime) {
    team: teamById(id: $id, date: $date) {
      ...Team
    }
  }
  ${TeamFragmentDoc}
`

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetTeamQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(
    GetTeamDocument,
    options,
  )
}
export function useGetTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamQuery,
    GetTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(
    GetTeamDocument,
    options,
  )
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>
export type GetTeamQueryResult = Apollo.QueryResult<
  GetTeamQuery,
  GetTeamQueryVariables
>
export const GetTeamBillingInfoDocument = gql`
  query GetTeamBillingInfo($id: ID!) {
    team: teamById(id: $id) {
      ...TeamBilling
    }
  }
  ${TeamBillingFragmentDoc}
`

/**
 * __useGetTeamBillingInfoQuery__
 *
 * To run a query within a React component, call `useGetTeamBillingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamBillingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamBillingInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamBillingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamBillingInfoQuery,
    GetTeamBillingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTeamBillingInfoQuery,
    GetTeamBillingInfoQueryVariables
  >(GetTeamBillingInfoDocument, options)
}
export function useGetTeamBillingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamBillingInfoQuery,
    GetTeamBillingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTeamBillingInfoQuery,
    GetTeamBillingInfoQueryVariables
  >(GetTeamBillingInfoDocument, options)
}
export type GetTeamBillingInfoQueryHookResult = ReturnType<
  typeof useGetTeamBillingInfoQuery
>
export type GetTeamBillingInfoLazyQueryHookResult = ReturnType<
  typeof useGetTeamBillingInfoLazyQuery
>
export type GetTeamBillingInfoQueryResult = Apollo.QueryResult<
  GetTeamBillingInfoQuery,
  GetTeamBillingInfoQueryVariables
>
export const ListAdminRolesDocument = gql`
  query ListAdminRoles {
    list: adminRolesList {
      items {
        ...AdminRole
      }
    }
    guardedOperations {
      ...GuardedOperation
    }
  }
  ${AdminRoleFragmentDoc}
  ${GuardedOperationFragmentDoc}
`

/**
 * __useListAdminRolesQuery__
 *
 * To run a query within a React component, call `useListAdminRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAdminRolesQuery,
    ListAdminRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAdminRolesQuery, ListAdminRolesQueryVariables>(
    ListAdminRolesDocument,
    options,
  )
}
export function useListAdminRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAdminRolesQuery,
    ListAdminRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAdminRolesQuery, ListAdminRolesQueryVariables>(
    ListAdminRolesDocument,
    options,
  )
}
export type ListAdminRolesQueryHookResult = ReturnType<
  typeof useListAdminRolesQuery
>
export type ListAdminRolesLazyQueryHookResult = ReturnType<
  typeof useListAdminRolesLazyQuery
>
export type ListAdminRolesQueryResult = Apollo.QueryResult<
  ListAdminRolesQuery,
  ListAdminRolesQueryVariables
>
export const UpsertAdminRoleDocument = gql`
  mutation UpsertAdminRole($input: UpsertAdminRoleInput!) {
    upsertAdminRole(input: $input) {
      adminRole {
        ...AdminRole
      }
    }
  }
  ${AdminRoleFragmentDoc}
`
export type UpsertAdminRoleMutationFn = Apollo.MutationFunction<
  UpsertAdminRoleMutation,
  UpsertAdminRoleMutationVariables
>

/**
 * __useUpsertAdminRoleMutation__
 *
 * To run a mutation, you first call `useUpsertAdminRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAdminRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAdminRoleMutation, { data, loading, error }] = useUpsertAdminRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAdminRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertAdminRoleMutation,
    UpsertAdminRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertAdminRoleMutation,
    UpsertAdminRoleMutationVariables
  >(UpsertAdminRoleDocument, options)
}
export type UpsertAdminRoleMutationHookResult = ReturnType<
  typeof useUpsertAdminRoleMutation
>
export type UpsertAdminRoleMutationResult =
  Apollo.MutationResult<UpsertAdminRoleMutation>
export type UpsertAdminRoleMutationOptions = Apollo.BaseMutationOptions<
  UpsertAdminRoleMutation,
  UpsertAdminRoleMutationVariables
>
export const UpdateAdminableRolesDocument = gql`
  mutation UpdateAdminableRoles($input: UpdateAdminableRolesInput!) {
    updateAdminableRoles(input: $input) {
      user {
        id
        ... on Adminable {
          adminRoles {
            ...AdminRole
          }
        }
      }
    }
  }
  ${AdminRoleFragmentDoc}
`
export type UpdateAdminableRolesMutationFn = Apollo.MutationFunction<
  UpdateAdminableRolesMutation,
  UpdateAdminableRolesMutationVariables
>

/**
 * __useUpdateAdminableRolesMutation__
 *
 * To run a mutation, you first call `useUpdateAdminableRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminableRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminableRolesMutation, { data, loading, error }] = useUpdateAdminableRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminableRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdminableRolesMutation,
    UpdateAdminableRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAdminableRolesMutation,
    UpdateAdminableRolesMutationVariables
  >(UpdateAdminableRolesDocument, options)
}
export type UpdateAdminableRolesMutationHookResult = ReturnType<
  typeof useUpdateAdminableRolesMutation
>
export type UpdateAdminableRolesMutationResult =
  Apollo.MutationResult<UpdateAdminableRolesMutation>
export type UpdateAdminableRolesMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminableRolesMutation,
  UpdateAdminableRolesMutationVariables
>
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      ...Userable_Profile
      ...Userable_CountryCode
      allowedOperations {
        id
        description
      }
    }
  }
  ${Userable_ProfileFragmentDoc}
  ${Userable_CountryCodeFragmentDoc}
`

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  )
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  )
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>
export const UpdateTeamDocument = gql`
  mutation UpdateTeam($teamId: ID!, $input: UpdateTeamInput!) {
    updateTeam(teamId: $teamId, input: $input) {
      ...Team
    }
  }
  ${TeamFragmentDoc}
`
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options,
  )
}
export type UpdateTeamMutationHookResult = ReturnType<
  typeof useUpdateTeamMutation
>
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>
export const UpdateTeamBillingDocument = gql`
  mutation UpdateTeamBilling($teamId: ID!, $input: UpdateTeamBillingInput!) {
    updateTeamBilling(teamId: $teamId, input: $input) {
      ...TeamBilling
    }
  }
  ${TeamBillingFragmentDoc}
`
export type UpdateTeamBillingMutationFn = Apollo.MutationFunction<
  UpdateTeamBillingMutation,
  UpdateTeamBillingMutationVariables
>

/**
 * __useUpdateTeamBillingMutation__
 *
 * To run a mutation, you first call `useUpdateTeamBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamBillingMutation, { data, loading, error }] = useUpdateTeamBillingMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamBillingMutation,
    UpdateTeamBillingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTeamBillingMutation,
    UpdateTeamBillingMutationVariables
  >(UpdateTeamBillingDocument, options)
}
export type UpdateTeamBillingMutationHookResult = ReturnType<
  typeof useUpdateTeamBillingMutation
>
export type UpdateTeamBillingMutationResult =
  Apollo.MutationResult<UpdateTeamBillingMutation>
export type UpdateTeamBillingMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamBillingMutation,
  UpdateTeamBillingMutationVariables
>
export const ClearDataloadersDocument = gql`
  mutation ClearDataloaders {
    clearDataloaders {
      isSuccess
    }
  }
`
export type ClearDataloadersMutationFn = Apollo.MutationFunction<
  ClearDataloadersMutation,
  ClearDataloadersMutationVariables
>

/**
 * __useClearDataloadersMutation__
 *
 * To run a mutation, you first call `useClearDataloadersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDataloadersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDataloadersMutation, { data, loading, error }] = useClearDataloadersMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearDataloadersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearDataloadersMutation,
    ClearDataloadersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearDataloadersMutation,
    ClearDataloadersMutationVariables
  >(ClearDataloadersDocument, options)
}
export type ClearDataloadersMutationHookResult = ReturnType<
  typeof useClearDataloadersMutation
>
export type ClearDataloadersMutationResult =
  Apollo.MutationResult<ClearDataloadersMutation>
export type ClearDataloadersMutationOptions = Apollo.BaseMutationOptions<
  ClearDataloadersMutation,
  ClearDataloadersMutationVariables
>
export const GetCopilotDocument = gql`
  query GetCopilot {
    copilot {
      ...Copilot
    }
  }
  ${CopilotFragmentDoc}
`

/**
 * __useGetCopilotQuery__
 *
 * To run a query within a React component, call `useGetCopilotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCopilotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCopilotQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCopilotQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCopilotQuery,
    GetCopilotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCopilotQuery, GetCopilotQueryVariables>(
    GetCopilotDocument,
    options,
  )
}
export function useGetCopilotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCopilotQuery,
    GetCopilotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCopilotQuery, GetCopilotQueryVariables>(
    GetCopilotDocument,
    options,
  )
}
export type GetCopilotQueryHookResult = ReturnType<typeof useGetCopilotQuery>
export type GetCopilotLazyQueryHookResult = ReturnType<
  typeof useGetCopilotLazyQuery
>
export type GetCopilotQueryResult = Apollo.QueryResult<
  GetCopilotQuery,
  GetCopilotQueryVariables
>
export const UpdateCopilotSettingsDocument = gql`
  mutation UpdateCopilotSettings($input: UpdateCopilotSettingsInput!) {
    updateCopilotSettings(input: $input) {
      copilot {
        ...Copilot
      }
    }
  }
  ${CopilotFragmentDoc}
`
export type UpdateCopilotSettingsMutationFn = Apollo.MutationFunction<
  UpdateCopilotSettingsMutation,
  UpdateCopilotSettingsMutationVariables
>

/**
 * __useUpdateCopilotSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCopilotSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCopilotSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCopilotSettingsMutation, { data, loading, error }] = useUpdateCopilotSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCopilotSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCopilotSettingsMutation,
    UpdateCopilotSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCopilotSettingsMutation,
    UpdateCopilotSettingsMutationVariables
  >(UpdateCopilotSettingsDocument, options)
}
export type UpdateCopilotSettingsMutationHookResult = ReturnType<
  typeof useUpdateCopilotSettingsMutation
>
export type UpdateCopilotSettingsMutationResult =
  Apollo.MutationResult<UpdateCopilotSettingsMutation>
export type UpdateCopilotSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCopilotSettingsMutation,
  UpdateCopilotSettingsMutationVariables
>
export const AssistantCopilotDocument = gql`
  query AssistantCopilot {
    assistantCopilot {
      ...AssistantCopilot
    }
  }
  ${AssistantCopilotFragmentDoc}
`

/**
 * __useAssistantCopilotQuery__
 *
 * To run a query within a React component, call `useAssistantCopilotQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssistantCopilotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssistantCopilotQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssistantCopilotQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssistantCopilotQuery,
    AssistantCopilotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AssistantCopilotQuery, AssistantCopilotQueryVariables>(
    AssistantCopilotDocument,
    options,
  )
}
export function useAssistantCopilotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssistantCopilotQuery,
    AssistantCopilotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AssistantCopilotQuery,
    AssistantCopilotQueryVariables
  >(AssistantCopilotDocument, options)
}
export type AssistantCopilotQueryHookResult = ReturnType<
  typeof useAssistantCopilotQuery
>
export type AssistantCopilotLazyQueryHookResult = ReturnType<
  typeof useAssistantCopilotLazyQuery
>
export type AssistantCopilotQueryResult = Apollo.QueryResult<
  AssistantCopilotQuery,
  AssistantCopilotQueryVariables
>
export const UpdateAssistantCopilotSettingsDocument = gql`
  mutation UpdateAssistantCopilotSettings(
    $input: UpdateAssistantCopilotSettingsInput!
  ) {
    updateAssistantCopilotSettings(input: $input) {
      assistantCopilot {
        ...AssistantCopilot
      }
    }
  }
  ${AssistantCopilotFragmentDoc}
`
export type UpdateAssistantCopilotSettingsMutationFn = Apollo.MutationFunction<
  UpdateAssistantCopilotSettingsMutation,
  UpdateAssistantCopilotSettingsMutationVariables
>

/**
 * __useUpdateAssistantCopilotSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAssistantCopilotSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssistantCopilotSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssistantCopilotSettingsMutation, { data, loading, error }] = useUpdateAssistantCopilotSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssistantCopilotSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssistantCopilotSettingsMutation,
    UpdateAssistantCopilotSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAssistantCopilotSettingsMutation,
    UpdateAssistantCopilotSettingsMutationVariables
  >(UpdateAssistantCopilotSettingsDocument, options)
}
export type UpdateAssistantCopilotSettingsMutationHookResult = ReturnType<
  typeof useUpdateAssistantCopilotSettingsMutation
>
export type UpdateAssistantCopilotSettingsMutationResult =
  Apollo.MutationResult<UpdateAssistantCopilotSettingsMutation>
export type UpdateAssistantCopilotSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAssistantCopilotSettingsMutation,
    UpdateAssistantCopilotSettingsMutationVariables
  >
export const ArchiveMatchingDocument = gql`
  mutation ArchiveMatching($input: ArchiveMatchingInput!) {
    archiveMatching(input: $input) {
      isSuccess
    }
  }
`
export type ArchiveMatchingMutationFn = Apollo.MutationFunction<
  ArchiveMatchingMutation,
  ArchiveMatchingMutationVariables
>

/**
 * __useArchiveMatchingMutation__
 *
 * To run a mutation, you first call `useArchiveMatchingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMatchingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMatchingMutation, { data, loading, error }] = useArchiveMatchingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveMatchingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveMatchingMutation,
    ArchiveMatchingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveMatchingMutation,
    ArchiveMatchingMutationVariables
  >(ArchiveMatchingDocument, options)
}
export type ArchiveMatchingMutationHookResult = ReturnType<
  typeof useArchiveMatchingMutation
>
export type ArchiveMatchingMutationResult =
  Apollo.MutationResult<ArchiveMatchingMutation>
export type ArchiveMatchingMutationOptions = Apollo.BaseMutationOptions<
  ArchiveMatchingMutation,
  ArchiveMatchingMutationVariables
>
export const DelegationCoachDocument = gql`
  query DelegationCoach {
    delegationCoach {
      ...DelegationCoach
    }
  }
  ${DelegationCoachFragmentDoc}
`

/**
 * __useDelegationCoachQuery__
 *
 * To run a query within a React component, call `useDelegationCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelegationCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelegationCoachQuery({
 *   variables: {
 *   },
 * });
 */
export function useDelegationCoachQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DelegationCoachQuery,
    DelegationCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DelegationCoachQuery, DelegationCoachQueryVariables>(
    DelegationCoachDocument,
    options,
  )
}
export function useDelegationCoachLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DelegationCoachQuery,
    DelegationCoachQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DelegationCoachQuery,
    DelegationCoachQueryVariables
  >(DelegationCoachDocument, options)
}
export type DelegationCoachQueryHookResult = ReturnType<
  typeof useDelegationCoachQuery
>
export type DelegationCoachLazyQueryHookResult = ReturnType<
  typeof useDelegationCoachLazyQuery
>
export type DelegationCoachQueryResult = Apollo.QueryResult<
  DelegationCoachQuery,
  DelegationCoachQueryVariables
>
export const UpdateDelegationCoachSettingsDocument = gql`
  mutation UpdateDelegationCoachSettings(
    $input: UpdateDelegationCoachSettingsInput!
  ) {
    updateDelegationCoachSettings(input: $input) {
      delegationCoach {
        ...DelegationCoach
      }
    }
  }
  ${DelegationCoachFragmentDoc}
`
export type UpdateDelegationCoachSettingsMutationFn = Apollo.MutationFunction<
  UpdateDelegationCoachSettingsMutation,
  UpdateDelegationCoachSettingsMutationVariables
>

/**
 * __useUpdateDelegationCoachSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDelegationCoachSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDelegationCoachSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDelegationCoachSettingsMutation, { data, loading, error }] = useUpdateDelegationCoachSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDelegationCoachSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDelegationCoachSettingsMutation,
    UpdateDelegationCoachSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDelegationCoachSettingsMutation,
    UpdateDelegationCoachSettingsMutationVariables
  >(UpdateDelegationCoachSettingsDocument, options)
}
export type UpdateDelegationCoachSettingsMutationHookResult = ReturnType<
  typeof useUpdateDelegationCoachSettingsMutation
>
export type UpdateDelegationCoachSettingsMutationResult =
  Apollo.MutationResult<UpdateDelegationCoachSettingsMutation>
export type UpdateDelegationCoachSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDelegationCoachSettingsMutation,
    UpdateDelegationCoachSettingsMutationVariables
  >
export const AdhocTodoSettingsDocument = gql`
  query AdhocTodoSettings {
    adhocTodoSettings {
      ...AdhocTodoSettings
    }
  }
  ${AdhocTodoSettingsFragmentDoc}
`

/**
 * __useAdhocTodoSettingsQuery__
 *
 * To run a query within a React component, call `useAdhocTodoSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdhocTodoSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdhocTodoSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdhocTodoSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdhocTodoSettingsQuery,
    AdhocTodoSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdhocTodoSettingsQuery,
    AdhocTodoSettingsQueryVariables
  >(AdhocTodoSettingsDocument, options)
}
export function useAdhocTodoSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdhocTodoSettingsQuery,
    AdhocTodoSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdhocTodoSettingsQuery,
    AdhocTodoSettingsQueryVariables
  >(AdhocTodoSettingsDocument, options)
}
export type AdhocTodoSettingsQueryHookResult = ReturnType<
  typeof useAdhocTodoSettingsQuery
>
export type AdhocTodoSettingsLazyQueryHookResult = ReturnType<
  typeof useAdhocTodoSettingsLazyQuery
>
export type AdhocTodoSettingsQueryResult = Apollo.QueryResult<
  AdhocTodoSettingsQuery,
  AdhocTodoSettingsQueryVariables
>
export const UpdateAdhocTodoSettingsDocument = gql`
  mutation UpdateAdhocTodoSettings($input: UpdateAdhocTodoSettingsInput!) {
    updateAdhocTodoSettings(input: $input) {
      adhocTodoSettings {
        ...AdhocTodoSettings
      }
    }
  }
  ${AdhocTodoSettingsFragmentDoc}
`
export type UpdateAdhocTodoSettingsMutationFn = Apollo.MutationFunction<
  UpdateAdhocTodoSettingsMutation,
  UpdateAdhocTodoSettingsMutationVariables
>

/**
 * __useUpdateAdhocTodoSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAdhocTodoSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdhocTodoSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdhocTodoSettingsMutation, { data, loading, error }] = useUpdateAdhocTodoSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdhocTodoSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdhocTodoSettingsMutation,
    UpdateAdhocTodoSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAdhocTodoSettingsMutation,
    UpdateAdhocTodoSettingsMutationVariables
  >(UpdateAdhocTodoSettingsDocument, options)
}
export type UpdateAdhocTodoSettingsMutationHookResult = ReturnType<
  typeof useUpdateAdhocTodoSettingsMutation
>
export type UpdateAdhocTodoSettingsMutationResult =
  Apollo.MutationResult<UpdateAdhocTodoSettingsMutation>
export type UpdateAdhocTodoSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdhocTodoSettingsMutation,
  UpdateAdhocTodoSettingsMutationVariables
>
export const AdhocTodosListDocument = gql`
  query AdhocTodosList(
    $first: PositiveInt = 100
    $after: String
    $excludedStatuses: [TodoStatus!]
  ) {
    list: adhocTodosList(
      first: $first
      after: $after
      excludedStatuses: $excludedStatuses
    ) {
      after
      items {
        ...AdhocTodo
      }
    }
  }
  ${AdhocTodoFragmentDoc}
`

/**
 * __useAdhocTodosListQuery__
 *
 * To run a query within a React component, call `useAdhocTodosListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdhocTodosListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdhocTodosListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      excludedStatuses: // value for 'excludedStatuses'
 *   },
 * });
 */
export function useAdhocTodosListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdhocTodosListQuery,
    AdhocTodosListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdhocTodosListQuery, AdhocTodosListQueryVariables>(
    AdhocTodosListDocument,
    options,
  )
}
export function useAdhocTodosListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdhocTodosListQuery,
    AdhocTodosListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdhocTodosListQuery, AdhocTodosListQueryVariables>(
    AdhocTodosListDocument,
    options,
  )
}
export type AdhocTodosListQueryHookResult = ReturnType<
  typeof useAdhocTodosListQuery
>
export type AdhocTodosListLazyQueryHookResult = ReturnType<
  typeof useAdhocTodosListLazyQuery
>
export type AdhocTodosListQueryResult = Apollo.QueryResult<
  AdhocTodosListQuery,
  AdhocTodosListQueryVariables
>
export const RemoveAdhocTodoAssistantDocument = gql`
  mutation RemoveAdhocTodoAssistant($input: RemoveAdhocTodoAssistantInput!) {
    removeAdhocTodoAssistant(input: $input) {
      adhocTodo {
        ...AdhocTodo
      }
    }
  }
  ${AdhocTodoFragmentDoc}
`
export type RemoveAdhocTodoAssistantMutationFn = Apollo.MutationFunction<
  RemoveAdhocTodoAssistantMutation,
  RemoveAdhocTodoAssistantMutationVariables
>

/**
 * __useRemoveAdhocTodoAssistantMutation__
 *
 * To run a mutation, you first call `useRemoveAdhocTodoAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdhocTodoAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdhocTodoAssistantMutation, { data, loading, error }] = useRemoveAdhocTodoAssistantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdhocTodoAssistantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAdhocTodoAssistantMutation,
    RemoveAdhocTodoAssistantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveAdhocTodoAssistantMutation,
    RemoveAdhocTodoAssistantMutationVariables
  >(RemoveAdhocTodoAssistantDocument, options)
}
export type RemoveAdhocTodoAssistantMutationHookResult = ReturnType<
  typeof useRemoveAdhocTodoAssistantMutation
>
export type RemoveAdhocTodoAssistantMutationResult =
  Apollo.MutationResult<RemoveAdhocTodoAssistantMutation>
export type RemoveAdhocTodoAssistantMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveAdhocTodoAssistantMutation,
    RemoveAdhocTodoAssistantMutationVariables
  >
export const UpdateAdhocTodoPoolVisibilityDocument = gql`
  mutation UpdateAdhocTodoPoolVisibility(
    $input: UpdateAdhocTodoPoolVisibilityInput!
  ) {
    updateAdhocTodoPoolVisibility(input: $input) {
      adhocTodo {
        ...AdhocTodo
      }
    }
  }
  ${AdhocTodoFragmentDoc}
`
export type UpdateAdhocTodoPoolVisibilityMutationFn = Apollo.MutationFunction<
  UpdateAdhocTodoPoolVisibilityMutation,
  UpdateAdhocTodoPoolVisibilityMutationVariables
>

/**
 * __useUpdateAdhocTodoPoolVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateAdhocTodoPoolVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdhocTodoPoolVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdhocTodoPoolVisibilityMutation, { data, loading, error }] = useUpdateAdhocTodoPoolVisibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdhocTodoPoolVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdhocTodoPoolVisibilityMutation,
    UpdateAdhocTodoPoolVisibilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAdhocTodoPoolVisibilityMutation,
    UpdateAdhocTodoPoolVisibilityMutationVariables
  >(UpdateAdhocTodoPoolVisibilityDocument, options)
}
export type UpdateAdhocTodoPoolVisibilityMutationHookResult = ReturnType<
  typeof useUpdateAdhocTodoPoolVisibilityMutation
>
export type UpdateAdhocTodoPoolVisibilityMutationResult =
  Apollo.MutationResult<UpdateAdhocTodoPoolVisibilityMutation>
export type UpdateAdhocTodoPoolVisibilityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAdhocTodoPoolVisibilityMutation,
    UpdateAdhocTodoPoolVisibilityMutationVariables
  >
export const GrantPoolTokensDocument = gql`
  mutation GrantPoolTokens($input: GrantPoolTokensInput!) {
    grantPoolTokens(input: $input) {
      workspace {
        id
        poolTokenBalance {
          ...PoolTokenBalance
        }
      }
    }
  }
  ${PoolTokenBalanceFragmentDoc}
`
export type GrantPoolTokensMutationFn = Apollo.MutationFunction<
  GrantPoolTokensMutation,
  GrantPoolTokensMutationVariables
>

/**
 * __useGrantPoolTokensMutation__
 *
 * To run a mutation, you first call `useGrantPoolTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantPoolTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantPoolTokensMutation, { data, loading, error }] = useGrantPoolTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantPoolTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GrantPoolTokensMutation,
    GrantPoolTokensMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GrantPoolTokensMutation,
    GrantPoolTokensMutationVariables
  >(GrantPoolTokensDocument, options)
}
export type GrantPoolTokensMutationHookResult = ReturnType<
  typeof useGrantPoolTokensMutation
>
export type GrantPoolTokensMutationResult =
  Apollo.MutationResult<GrantPoolTokensMutation>
export type GrantPoolTokensMutationOptions = Apollo.BaseMutationOptions<
  GrantPoolTokensMutation,
  GrantPoolTokensMutationVariables
>
export const ExportChatToStudioDocument = gql`
  mutation ExportChatToStudio($input: ExportChatToStudioInput!) {
    exportChatToStudio(input: $input) {
      isSuccess
    }
  }
`
export type ExportChatToStudioMutationFn = Apollo.MutationFunction<
  ExportChatToStudioMutation,
  ExportChatToStudioMutationVariables
>

/**
 * __useExportChatToStudioMutation__
 *
 * To run a mutation, you first call `useExportChatToStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportChatToStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportChatToStudioMutation, { data, loading, error }] = useExportChatToStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportChatToStudioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportChatToStudioMutation,
    ExportChatToStudioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExportChatToStudioMutation,
    ExportChatToStudioMutationVariables
  >(ExportChatToStudioDocument, options)
}
export type ExportChatToStudioMutationHookResult = ReturnType<
  typeof useExportChatToStudioMutation
>
export type ExportChatToStudioMutationResult =
  Apollo.MutationResult<ExportChatToStudioMutation>
export type ExportChatToStudioMutationOptions = Apollo.BaseMutationOptions<
  ExportChatToStudioMutation,
  ExportChatToStudioMutationVariables
>
export const GetUserTimelineDocument = gql`
  query GetUserTimeline($userId: ID!, $excludes: [TimelineEvent!]) {
    timelineItemsList(userId: $userId, excludes: $excludes) {
      items {
        ...TimelineItem
      }
    }
  }
  ${TimelineItemFragmentDoc}
`

/**
 * __useGetUserTimelineQuery__
 *
 * To run a query within a React component, call `useGetUserTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTimelineQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      excludes: // value for 'excludes'
 *   },
 * });
 */
export function useGetUserTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserTimelineQuery,
    GetUserTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserTimelineQuery, GetUserTimelineQueryVariables>(
    GetUserTimelineDocument,
    options,
  )
}
export function useGetUserTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTimelineQuery,
    GetUserTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserTimelineQuery,
    GetUserTimelineQueryVariables
  >(GetUserTimelineDocument, options)
}
export type GetUserTimelineQueryHookResult = ReturnType<
  typeof useGetUserTimelineQuery
>
export type GetUserTimelineLazyQueryHookResult = ReturnType<
  typeof useGetUserTimelineLazyQuery
>
export type GetUserTimelineQueryResult = Apollo.QueryResult<
  GetUserTimelineQuery,
  GetUserTimelineQueryVariables
>
export const GetTeamTimelineDocument = gql`
  query GetTeamTimeline($teamId: ID!, $excludes: [TimelineEvent!]) {
    teamTimelineItemsList(teamId: $teamId, excludes: $excludes) {
      items {
        ...TimelineItem
      }
    }
  }
  ${TimelineItemFragmentDoc}
`

/**
 * __useGetTeamTimelineQuery__
 *
 * To run a query within a React component, call `useGetTeamTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamTimelineQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      excludes: // value for 'excludes'
 *   },
 * });
 */
export function useGetTeamTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamTimelineQuery,
    GetTeamTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTeamTimelineQuery, GetTeamTimelineQueryVariables>(
    GetTeamTimelineDocument,
    options,
  )
}
export function useGetTeamTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamTimelineQuery,
    GetTeamTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTeamTimelineQuery,
    GetTeamTimelineQueryVariables
  >(GetTeamTimelineDocument, options)
}
export type GetTeamTimelineQueryHookResult = ReturnType<
  typeof useGetTeamTimelineQuery
>
export type GetTeamTimelineLazyQueryHookResult = ReturnType<
  typeof useGetTeamTimelineLazyQuery
>
export type GetTeamTimelineQueryResult = Apollo.QueryResult<
  GetTeamTimelineQuery,
  GetTeamTimelineQueryVariables
>
export const GetEventCategoryMappingListDocument = gql`
  query GetEventCategoryMappingList {
    eventCategoryMappingList {
      category
      event
    }
  }
`

/**
 * __useGetEventCategoryMappingListQuery__
 *
 * To run a query within a React component, call `useGetEventCategoryMappingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventCategoryMappingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventCategoryMappingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventCategoryMappingListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventCategoryMappingListQuery,
    GetEventCategoryMappingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEventCategoryMappingListQuery,
    GetEventCategoryMappingListQueryVariables
  >(GetEventCategoryMappingListDocument, options)
}
export function useGetEventCategoryMappingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventCategoryMappingListQuery,
    GetEventCategoryMappingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEventCategoryMappingListQuery,
    GetEventCategoryMappingListQueryVariables
  >(GetEventCategoryMappingListDocument, options)
}
export type GetEventCategoryMappingListQueryHookResult = ReturnType<
  typeof useGetEventCategoryMappingListQuery
>
export type GetEventCategoryMappingListLazyQueryHookResult = ReturnType<
  typeof useGetEventCategoryMappingListLazyQuery
>
export type GetEventCategoryMappingListQueryResult = Apollo.QueryResult<
  GetEventCategoryMappingListQuery,
  GetEventCategoryMappingListQueryVariables
>
export const UpdateWorkspaceTeamDocument = gql`
  mutation UpdateWorkspaceTeam($input: UpdateWorkspaceTeamInput!) {
    updateWorkspaceTeam(input: $input) {
      ...WorkspaceWithTeam
      executives {
        id
        csm {
          ...ExecutivableCsm
        }
      }
    }
  }
  ${WorkspaceWithTeamFragmentDoc}
  ${ExecutivableCsmFragmentDoc}
`
export type UpdateWorkspaceTeamMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceTeamMutation,
  UpdateWorkspaceTeamMutationVariables
>

/**
 * __useUpdateWorkspaceTeamMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceTeamMutation, { data, loading, error }] = useUpdateWorkspaceTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceTeamMutation,
    UpdateWorkspaceTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateWorkspaceTeamMutation,
    UpdateWorkspaceTeamMutationVariables
  >(UpdateWorkspaceTeamDocument, options)
}
export type UpdateWorkspaceTeamMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceTeamMutation
>
export type UpdateWorkspaceTeamMutationResult =
  Apollo.MutationResult<UpdateWorkspaceTeamMutation>
export type UpdateWorkspaceTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceTeamMutation,
  UpdateWorkspaceTeamMutationVariables
>
export const GetUserInvoicingHistoryListDocument = gql`
  query GetUserInvoicingHistoryList($userId: ID!) {
    invoicingHistoryList(entityId: $userId) {
      items {
        ...InvoicedCycle
      }
    }
    user: userById(id: $userId) {
      ...Userable_StartDate
      ...Userable_Minimal
    }
  }
  ${InvoicedCycleFragmentDoc}
  ${Userable_StartDateFragmentDoc}
  ${Userable_MinimalFragmentDoc}
`

/**
 * __useGetUserInvoicingHistoryListQuery__
 *
 * To run a query within a React component, call `useGetUserInvoicingHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvoicingHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvoicingHistoryListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInvoicingHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserInvoicingHistoryListQuery,
    GetUserInvoicingHistoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserInvoicingHistoryListQuery,
    GetUserInvoicingHistoryListQueryVariables
  >(GetUserInvoicingHistoryListDocument, options)
}
export function useGetUserInvoicingHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInvoicingHistoryListQuery,
    GetUserInvoicingHistoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserInvoicingHistoryListQuery,
    GetUserInvoicingHistoryListQueryVariables
  >(GetUserInvoicingHistoryListDocument, options)
}
export type GetUserInvoicingHistoryListQueryHookResult = ReturnType<
  typeof useGetUserInvoicingHistoryListQuery
>
export type GetUserInvoicingHistoryListLazyQueryHookResult = ReturnType<
  typeof useGetUserInvoicingHistoryListLazyQuery
>
export type GetUserInvoicingHistoryListQueryResult = Apollo.QueryResult<
  GetUserInvoicingHistoryListQuery,
  GetUserInvoicingHistoryListQueryVariables
>
export const GetTeamInvoicingHistoryListDocument = gql`
  query GetTeamInvoicingHistoryList($teamId: ID!) {
    invoicingHistoryList(entityId: $teamId) {
      items {
        ...InvoicedCycle
      }
    }
    team: teamById(id: $teamId) {
      ...BasicTeam
      createdAt
    }
  }
  ${InvoicedCycleFragmentDoc}
  ${BasicTeamFragmentDoc}
`

/**
 * __useGetTeamInvoicingHistoryListQuery__
 *
 * To run a query within a React component, call `useGetTeamInvoicingHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamInvoicingHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamInvoicingHistoryListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamInvoicingHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamInvoicingHistoryListQuery,
    GetTeamInvoicingHistoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTeamInvoicingHistoryListQuery,
    GetTeamInvoicingHistoryListQueryVariables
  >(GetTeamInvoicingHistoryListDocument, options)
}
export function useGetTeamInvoicingHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamInvoicingHistoryListQuery,
    GetTeamInvoicingHistoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTeamInvoicingHistoryListQuery,
    GetTeamInvoicingHistoryListQueryVariables
  >(GetTeamInvoicingHistoryListDocument, options)
}
export type GetTeamInvoicingHistoryListQueryHookResult = ReturnType<
  typeof useGetTeamInvoicingHistoryListQuery
>
export type GetTeamInvoicingHistoryListLazyQueryHookResult = ReturnType<
  typeof useGetTeamInvoicingHistoryListLazyQuery
>
export type GetTeamInvoicingHistoryListQueryResult = Apollo.QueryResult<
  GetTeamInvoicingHistoryListQuery,
  GetTeamInvoicingHistoryListQueryVariables
>
export const GetTestExecDocument = gql`
  query GetTestExec {
    testExec {
      ...TestExec
    }
  }
  ${TestExecFragmentDoc}
`

/**
 * __useGetTestExecQuery__
 *
 * To run a query within a React component, call `useGetTestExecQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestExecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestExecQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTestExecQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTestExecQuery,
    GetTestExecQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTestExecQuery, GetTestExecQueryVariables>(
    GetTestExecDocument,
    options,
  )
}
export function useGetTestExecLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTestExecQuery,
    GetTestExecQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTestExecQuery, GetTestExecQueryVariables>(
    GetTestExecDocument,
    options,
  )
}
export type GetTestExecQueryHookResult = ReturnType<typeof useGetTestExecQuery>
export type GetTestExecLazyQueryHookResult = ReturnType<
  typeof useGetTestExecLazyQuery
>
export type GetTestExecQueryResult = Apollo.QueryResult<
  GetTestExecQuery,
  GetTestExecQueryVariables
>
export const CreateTestExecLeadDocument = gql`
  mutation CreateTestExecLead($input: CreateTestExecLeadInput!) {
    createTestExecLead(input: $input) {
      isSuccess
    }
  }
`
export type CreateTestExecLeadMutationFn = Apollo.MutationFunction<
  CreateTestExecLeadMutation,
  CreateTestExecLeadMutationVariables
>

/**
 * __useCreateTestExecLeadMutation__
 *
 * To run a mutation, you first call `useCreateTestExecLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestExecLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestExecLeadMutation, { data, loading, error }] = useCreateTestExecLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestExecLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTestExecLeadMutation,
    CreateTestExecLeadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateTestExecLeadMutation,
    CreateTestExecLeadMutationVariables
  >(CreateTestExecLeadDocument, options)
}
export type CreateTestExecLeadMutationHookResult = ReturnType<
  typeof useCreateTestExecLeadMutation
>
export type CreateTestExecLeadMutationResult =
  Apollo.MutationResult<CreateTestExecLeadMutation>
export type CreateTestExecLeadMutationOptions = Apollo.BaseMutationOptions<
  CreateTestExecLeadMutation,
  CreateTestExecLeadMutationVariables
>
export const CreateTestExecMatchingDocument = gql`
  mutation CreateTestExecMatching {
    createTestExecMatching {
      isSuccess
    }
  }
`
export type CreateTestExecMatchingMutationFn = Apollo.MutationFunction<
  CreateTestExecMatchingMutation,
  CreateTestExecMatchingMutationVariables
>

/**
 * __useCreateTestExecMatchingMutation__
 *
 * To run a mutation, you first call `useCreateTestExecMatchingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestExecMatchingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestExecMatchingMutation, { data, loading, error }] = useCreateTestExecMatchingMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateTestExecMatchingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTestExecMatchingMutation,
    CreateTestExecMatchingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateTestExecMatchingMutation,
    CreateTestExecMatchingMutationVariables
  >(CreateTestExecMatchingDocument, options)
}
export type CreateTestExecMatchingMutationHookResult = ReturnType<
  typeof useCreateTestExecMatchingMutation
>
export type CreateTestExecMatchingMutationResult =
  Apollo.MutationResult<CreateTestExecMatchingMutation>
export type CreateTestExecMatchingMutationOptions = Apollo.BaseMutationOptions<
  CreateTestExecMatchingMutation,
  CreateTestExecMatchingMutationVariables
>
export const ResetTestExecLifecycleDocument = gql`
  mutation ResetTestExecLifecycle {
    resetTestExecLifecycle {
      isSuccess
    }
  }
`
export type ResetTestExecLifecycleMutationFn = Apollo.MutationFunction<
  ResetTestExecLifecycleMutation,
  ResetTestExecLifecycleMutationVariables
>

/**
 * __useResetTestExecLifecycleMutation__
 *
 * To run a mutation, you first call `useResetTestExecLifecycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTestExecLifecycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTestExecLifecycleMutation, { data, loading, error }] = useResetTestExecLifecycleMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetTestExecLifecycleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetTestExecLifecycleMutation,
    ResetTestExecLifecycleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetTestExecLifecycleMutation,
    ResetTestExecLifecycleMutationVariables
  >(ResetTestExecLifecycleDocument, options)
}
export type ResetTestExecLifecycleMutationHookResult = ReturnType<
  typeof useResetTestExecLifecycleMutation
>
export type ResetTestExecLifecycleMutationResult =
  Apollo.MutationResult<ResetTestExecLifecycleMutation>
export type ResetTestExecLifecycleMutationOptions = Apollo.BaseMutationOptions<
  ResetTestExecLifecycleMutation,
  ResetTestExecLifecycleMutationVariables
>
export const PauseTeamDocument = gql`
  mutation PauseTeam($input: PauseTeamInput!) {
    pauseTeam(input: $input) {
      ...BillingPause
    }
  }
  ${BillingPauseFragmentDoc}
`
export type PauseTeamMutationFn = Apollo.MutationFunction<
  PauseTeamMutation,
  PauseTeamMutationVariables
>

/**
 * __usePauseTeamMutation__
 *
 * To run a mutation, you first call `usePauseTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseTeamMutation, { data, loading, error }] = usePauseTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseTeamMutation,
    PauseTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PauseTeamMutation, PauseTeamMutationVariables>(
    PauseTeamDocument,
    options,
  )
}
export type PauseTeamMutationHookResult = ReturnType<
  typeof usePauseTeamMutation
>
export type PauseTeamMutationResult = Apollo.MutationResult<PauseTeamMutation>
export type PauseTeamMutationOptions = Apollo.BaseMutationOptions<
  PauseTeamMutation,
  PauseTeamMutationVariables
>
export const UnpauseTeamDocument = gql`
  mutation UnpauseTeam($input: UnpauseTeamInput!) {
    unpauseTeam(input: $input) {
      ...BillingPause
    }
  }
  ${BillingPauseFragmentDoc}
`
export type UnpauseTeamMutationFn = Apollo.MutationFunction<
  UnpauseTeamMutation,
  UnpauseTeamMutationVariables
>

/**
 * __useUnpauseTeamMutation__
 *
 * To run a mutation, you first call `useUnpauseTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpauseTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpauseTeamMutation, { data, loading, error }] = useUnpauseTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpauseTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpauseTeamMutation,
    UnpauseTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnpauseTeamMutation, UnpauseTeamMutationVariables>(
    UnpauseTeamDocument,
    options,
  )
}
export type UnpauseTeamMutationHookResult = ReturnType<
  typeof useUnpauseTeamMutation
>
export type UnpauseTeamMutationResult =
  Apollo.MutationResult<UnpauseTeamMutation>
export type UnpauseTeamMutationOptions = Apollo.BaseMutationOptions<
  UnpauseTeamMutation,
  UnpauseTeamMutationVariables
>
export const UnarchiveTeamDocument = gql`
  mutation UnarchiveTeam($input: UnarchiveTeamInput!) {
    unarchiveTeam(input: $input) {
      ...BasicTeam
    }
  }
  ${BasicTeamFragmentDoc}
`
export type UnarchiveTeamMutationFn = Apollo.MutationFunction<
  UnarchiveTeamMutation,
  UnarchiveTeamMutationVariables
>

/**
 * __useUnarchiveTeamMutation__
 *
 * To run a mutation, you first call `useUnarchiveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveTeamMutation, { data, loading, error }] = useUnarchiveTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveTeamMutation,
    UnarchiveTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveTeamMutation,
    UnarchiveTeamMutationVariables
  >(UnarchiveTeamDocument, options)
}
export type UnarchiveTeamMutationHookResult = ReturnType<
  typeof useUnarchiveTeamMutation
>
export type UnarchiveTeamMutationResult =
  Apollo.MutationResult<UnarchiveTeamMutation>
export type UnarchiveTeamMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveTeamMutation,
  UnarchiveTeamMutationVariables
>
export const ListSystemFeatureFlagsDocument = gql`
  query ListSystemFeatureFlags {
    list: systemFeatureFlagsList {
      items {
        ...SystemFeatureFlag
      }
    }
  }
  ${SystemFeatureFlagFragmentDoc}
`

/**
 * __useListSystemFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useListSystemFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSystemFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSystemFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSystemFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListSystemFeatureFlagsQuery,
    ListSystemFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListSystemFeatureFlagsQuery,
    ListSystemFeatureFlagsQueryVariables
  >(ListSystemFeatureFlagsDocument, options)
}
export function useListSystemFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSystemFeatureFlagsQuery,
    ListSystemFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListSystemFeatureFlagsQuery,
    ListSystemFeatureFlagsQueryVariables
  >(ListSystemFeatureFlagsDocument, options)
}
export type ListSystemFeatureFlagsQueryHookResult = ReturnType<
  typeof useListSystemFeatureFlagsQuery
>
export type ListSystemFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useListSystemFeatureFlagsLazyQuery
>
export type ListSystemFeatureFlagsQueryResult = Apollo.QueryResult<
  ListSystemFeatureFlagsQuery,
  ListSystemFeatureFlagsQueryVariables
>
export const UpdateSystemFeatureFlagDocument = gql`
  mutation UpdateSystemFeatureFlag($input: UpdateSystemFeatureFlagInput!) {
    updateSystemFeatureFlag(input: $input) {
      systemFeatureFlag {
        ...SystemFeatureFlag
      }
    }
  }
  ${SystemFeatureFlagFragmentDoc}
`
export type UpdateSystemFeatureFlagMutationFn = Apollo.MutationFunction<
  UpdateSystemFeatureFlagMutation,
  UpdateSystemFeatureFlagMutationVariables
>

/**
 * __useUpdateSystemFeatureFlagMutation__
 *
 * To run a mutation, you first call `useUpdateSystemFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemFeatureFlagMutation, { data, loading, error }] = useUpdateSystemFeatureFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSystemFeatureFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSystemFeatureFlagMutation,
    UpdateSystemFeatureFlagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSystemFeatureFlagMutation,
    UpdateSystemFeatureFlagMutationVariables
  >(UpdateSystemFeatureFlagDocument, options)
}
export type UpdateSystemFeatureFlagMutationHookResult = ReturnType<
  typeof useUpdateSystemFeatureFlagMutation
>
export type UpdateSystemFeatureFlagMutationResult =
  Apollo.MutationResult<UpdateSystemFeatureFlagMutation>
export type UpdateSystemFeatureFlagMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemFeatureFlagMutation,
  UpdateSystemFeatureFlagMutationVariables
>
export const CreateMatchingFromLeadDocument = gql`
  mutation CreateMatchingFromLead($input: CreateMatchingFromLeadInput!) {
    createMatchingFromLead(input: $input) {
      matching {
        ...Matching_ForMatchingListItem
      }
    }
  }
  ${Matching_ForMatchingListItemFragmentDoc}
`
export type CreateMatchingFromLeadMutationFn = Apollo.MutationFunction<
  CreateMatchingFromLeadMutation,
  CreateMatchingFromLeadMutationVariables
>

/**
 * __useCreateMatchingFromLeadMutation__
 *
 * To run a mutation, you first call `useCreateMatchingFromLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMatchingFromLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMatchingFromLeadMutation, { data, loading, error }] = useCreateMatchingFromLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMatchingFromLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMatchingFromLeadMutation,
    CreateMatchingFromLeadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMatchingFromLeadMutation,
    CreateMatchingFromLeadMutationVariables
  >(CreateMatchingFromLeadDocument, options)
}
export type CreateMatchingFromLeadMutationHookResult = ReturnType<
  typeof useCreateMatchingFromLeadMutation
>
export type CreateMatchingFromLeadMutationResult =
  Apollo.MutationResult<CreateMatchingFromLeadMutation>
export type CreateMatchingFromLeadMutationOptions = Apollo.BaseMutationOptions<
  CreateMatchingFromLeadMutation,
  CreateMatchingFromLeadMutationVariables
>
