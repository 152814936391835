import { compact } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import Tabs from '../../../components/Tabs'
import PendingChurnAutomationJob from '../../../components/churn/PendingChurnAutomationJob'
import {
  useCancelScheduledTeamChurnMutation,
  useGetTeamQuery,
  useScheduleTeamChurnMutation,
} from '../../../graphql'
import { isPending } from '../../autopilot/Jobs/utils'

import Billing from './Billing'
import Header from './Header'
import Info from './Info'
import Timeline from './Timeline'
// import Reporting from './Reporting'
import { Content, Outer, TabContent } from './styled'

const Team = () => {
  const {
    url,
    params: { teamId },
  } = useRouteMatch<{ teamId: string }>()
  const {
    data,
    loading: getTeamLoading,
    error,
  } = useGetTeamQuery({
    variables: { id: teamId },
    skip: !teamId,
  })

  const [scheduleTeamChurn, { loading: isSchedulingChurn }] =
    useScheduleTeamChurnMutation({
      onCompleted: () => {
        setTimeout(() => window.location.reload(), 2)
      },
    })

  const [cancelScheduledTeamChurn, { loading: cancelChurnLoading }] =
    useCancelScheduledTeamChurnMutation()

  const onCancelScheduledChurn = useCallback(() => {
    cancelScheduledTeamChurn({
      variables: {
        input: {
          teamId,
        },
      },
    })
  }, [cancelScheduledTeamChurn, teamId])

  const mock = !data?.team
  const team = data?.team

  const isPendingChurn = useMemo(() => {
    return (
      team?.pendingChurnAutomationJob &&
      isPending(team.pendingChurnAutomationJob)
    )
  }, [team])

  if (!team) {
    return null
  }

  const tabs = compact([
    {
      label: 'Info',
      content: (
        <TabContent>
          <Info team={team} />
        </TabContent>
      ),
    },
    // Billing & package
    {
      label: 'Billing',
      content: (
        <TabContent>
          <Billing teamId={team.id} />
        </TabContent>
      ),
    },
    // TODO - show team reporting here?
    // {
    //   label: 'Reporting',
    //   content: (
    //     <TabContent>
    //       <Reporting />
    //     </TabContent>
    //   ),
    // },
    {
      label: 'Timeline',
      content: (
        <TabContent>
          <Timeline teamId={teamId} />
        </TabContent>
      ),
    },
  ])

  // TODO - filter automations by teamId?
  // const rightTabs = [
  //   {
  //     label: '🤖',
  //     slug: 'autopilot',
  //     content: (
  //       <TabContent>
  //         <AutomationJobsInline
  //           filter={{
  //             userId: user.id,
  //           }}
  //         />
  //       </TabContent>
  //     ),
  //   },
  // ]

  const loading = getTeamLoading || cancelChurnLoading || isSchedulingChurn

  return (
    <Outer>
      <Header
        team={team}
        skeleton={mock}
        isPendingChurn={isPendingChurn || false}
        scheduleTeamChurn={scheduleTeamChurn}
        isSchedulingChurn={isSchedulingChurn}
      />
      {isPendingChurn && (
        <PendingChurnAutomationJob
          name={team.name}
          delayUntil={team.pendingChurnAutomationJob?.delayUntil || ''}
          onCancelScheduledChurn={onCancelScheduledChurn}
        />
      )}
      <Content>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : (
          <Tabs leftTabs={tabs} basePath={url} />
        )}
        <LoadingSpinner show={loading} />
      </Content>
    </Outer>
  )
}

export default Team
