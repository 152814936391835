import Popup from '@atlaskit/popup'
import { DateTime } from 'luxon'
import React, { ComponentProps, useCallback } from 'react'

import {
  TimeTrackingEntry_TogglInfoFragment,
  TimeTrackingEntry_DateInfoFragment,
  useUpdateTimeTrackingEntryMutation,
  TimeTrackingEntry_StatusInfoFragment,
} from '../../../../../graphql'
import useSwitch from '../../../../../lib/useSwitch'

import TimeEntryEditPopup from './TimeEntryEditPopup'
import TimeInfosButton from './TimeInfosButton'

type Props = ComponentProps<'div'> & {
  entry: TimeTrackingEntry_DateInfoFragment &
    TimeTrackingEntry_TogglInfoFragment &
    TimeTrackingEntry_StatusInfoFragment
  lastLockedAt?: DateTime
}

const Time = ({
  entry: { endedAt, startedAt, id: entryId, lockedAt },
  lastLockedAt,
  ...divProps
}: Props) => {
  const startedAtDateTime = DateTime.fromJSDate(new Date(startedAt))
  const endedAtDateTime = endedAt
    ? DateTime.fromJSDate(new Date(endedAt))
    : undefined

  const [isOpen, onOpen, onClose] = useSwitch(false)

  const [updateTimeEntry, { loading: updateTimeTrackingLoading }] =
    useUpdateTimeTrackingEntryMutation()

  const onSave = useCallback(
    ({ startedAt, endedAt }: { startedAt: DateTime; endedAt: DateTime }) => {
      const proceedEntryUpdate: boolean = (() => {
        if (lockedAt) {
          return confirm(
            'You are editing a locked entry, are you sure you want to proceed?',
          )
        }

        if (!lastLockedAt || endedAt > lastLockedAt) {
          return true
        }

        return confirm(
          `The entry will be locked after this change because it is moving to a locked time period (before ${lastLockedAt.toFormat(
            'dd LLL yyyy HH:mm ZZZZ',
          )}). Do you want to proceed?`,
        )
      })()

      if (!proceedEntryUpdate) {
        onClose()
        return
      }

      updateTimeEntry({
        variables: {
          input: {
            startedAt: startedAt.toISO(),
            endedAt: endedAt?.toISO(),
            timeTrackingEntryId: entryId,
          },
        },
        onCompleted: onClose,
      })
    },
    [entryId, lastLockedAt, lockedAt, onClose, updateTimeEntry],
  )

  return (
    <Popup
      content={() => (
        <TimeEntryEditPopup
          startedAtDateTime={startedAtDateTime}
          endedAtDateTime={endedAtDateTime}
          saving={updateTimeTrackingLoading}
          onCancel={onClose}
          onSave={onSave}
        />
      )}
      isOpen={isOpen}
      onClose={onClose}
      trigger={(triggerProps) => (
        <TimeInfosButton
          {...divProps}
          {...triggerProps}
          onClick={onOpen}
          startedAtDateTime={startedAtDateTime}
          endedAtDateTime={endedAtDateTime}
        />
      )}
    />
  )
}

export default Time
