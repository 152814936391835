import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import Box from '../../../../components/Box'
import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../../components/DropdownMenu'
import InlineMessage from '../../../../components/InlineMessage'
import { LoadingSpinner } from '../../../../components/Spinner'
import UserRow from '../../../../components/users/Cell'
import {
  BasicWorkspaceFragment,
  useLinkWorkspaceUserAccountMutation,
  useUnlinkWorkspaceUserAccountMutation,
  useRemoveWorkspaceUserMutation,
  useReadTopicsMutation,
  UserCategory,
  useClearTopicsMutation,
  useGetWorkspaceUserQuery,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import { Account } from '../../User/Accounts'

import RemoveAssistantModal from './RemoveAssistantModal'

const More = styled(MoreDropdownMenu)`
  position: absolute;
  top: 12px;
  right: 12px;
`

interface Props {
  userId: string
  workspace: BasicWorkspaceFragment
  isRemovable?: boolean
  showAccounts?: boolean
}

const User = ({ userId, workspace, isRemovable, showAccounts }: Props) => {
  const [isRemoveModalOpen, openRemoveModal, closeRemoveModal] =
    useSwitch(false)

  const { data: userData, loading: loadingUser } = useGetWorkspaceUserQuery({
    variables: {
      id: userId,
    },
  })
  const [link, { loading: loadingLink }] = useLinkWorkspaceUserAccountMutation()
  const [unlink, { loading: loadingUnlink }] =
    useUnlinkWorkspaceUserAccountMutation()
  const [removeWorkspaceUser, { loading: loadingRemoveUser }] =
    useRemoveWorkspaceUserMutation({
      variables: {
        input: {
          workspaceId: workspace.id,
          userId,
        },
      },
    })
  const [readTopics, { loading: loadingReadTopics }] = useReadTopicsMutation({
    variables: {
      input: {
        workspaceId: workspace.id,
        userId,
      },
    },
  })

  const [clearTopics, { loading: loadingClearTopics }] = useClearTopicsMutation(
    {
      variables: {
        input: {
          workspaceId: workspace.id,
          leaveTodo: 25,
        },
      },
    },
  )

  const user = userData?.user

  const mayRemoveUser = useCallback(() => {
    if (user?.category === UserCategory.ASSISTANT) {
      openRemoveModal()
    } else {
      removeWorkspaceUser()
    }
  }, [openRemoveModal, removeWorkspaceUser, user])

  const hasAccountLinked =
    !showAccounts ||
    !user ||
    user?.accounts.some(({ key }) => workspace.accountKeys.includes(key))

  return (
    <Box style={{ minHeight: 60 }} hasWarning={!hasAccountLinked}>
      {user && (
        <>
          <NavLink to={`/users/${user.id}/workspaces/${workspace.id}`}>
            {/* @ts-ignore */}
            <UserRow user={user} />
          </NavLink>
          {!!showAccounts &&
            user.accounts.map((account) => (
              <Account
                key={account.key}
                account={account}
                selectable
                selected={workspace.accountKeys.includes(account.key)}
                onToggle={(checked) =>
                  (checked ? link : unlink)({
                    variables: {
                      workspaceId: workspace.id,
                      userId: user.id,
                      accountId: account.id,
                    },
                  })
                }
              />
            ))}
          {!!showAccounts && !hasAccountLinked && (
            <InlineMessage type={'warning'} title={'An account MUST be linked'}>
              {
                'Please toggle an email account ON for this user to have an email address in this workspace.'
              }
            </InlineMessage>
          )}
          <More
            isLoading={
              loadingReadTopics || loadingRemoveUser || loadingClearTopics
            }
          >
            <DropdownItemGroup>
              <DropdownItem onClick={() => readTopics()}>
                {'Mark all topics as read'}
              </DropdownItem>
              <DropdownItem onClick={() => clearTopics()}>
                {'Clear >25 Todo Topics'}
              </DropdownItem>
              {!!isRemovable && (
                <DropdownItem onClick={mayRemoveUser}>
                  {'Remove from workspace'}
                </DropdownItem>
              )}
            </DropdownItemGroup>
          </More>
          <RemoveAssistantModal
            workspaceId={workspace.id}
            assistantId={userId}
            isOpen={isRemoveModalOpen}
            onClose={closeRemoveModal}
          />
        </>
      )}

      <LoadingSpinner
        show={loadingUser || loadingLink || loadingUnlink || loadingRemoveUser}
      />
    </Box>
  )
}

export default User
