import Button from '@atlaskit/button'
import uniq from 'lodash/uniq'
import { useCallback, useState } from 'react'

import {
  TimelineEvent,
  TimelineItemCategory,
  useGetEventCategoryMappingListQuery,
} from '../../../graphql'
import Badge, { Tint, TintList } from '../../Badge'
import { LoadingSpinner } from '../../Spinner'
import { Checkbox, SearchTextField } from '../../form'

import { Outer, Item, Label, ListOuter } from './styled'

type FilterEditorProps = {
  excludeList: TimelineEvent[]
  setExcludeList: (excludeList: TimelineEvent[]) => void
  clearExcludeList: () => void
  uniqueCategories: string[]
}

const getInitials = (text: string) =>
  text
    .split('_')
    .map((word) => word[0])
    .join('')

type CategoryBadge = {
  [key in TimelineItemCategory]: {
    color: string
    initials: string
  }
}

// every category returns category mapped to a color and initials
const CATEGORIES = Object.values(TimelineItemCategory).reduce(
  (acc, category, index) => ({
    ...acc,
    [category]: { color: TintList[index], initials: getInitials(category) },
  }),
  {} as CategoryBadge,
)

const FilterEditor = ({
  excludeList,
  setExcludeList,
  clearExcludeList,
  uniqueCategories,
}: FilterEditorProps) => {
  const [searchTerm, _setSearchTerm] = useState<string>()
  const { data, loading, error } = useGetEventCategoryMappingListQuery()

  const setSearchTerm = useCallback((value: string) => {
    _setSearchTerm(value.toLowerCase())
  }, [])

  return (
    <Outer>
      <SearchTextField
        value={searchTerm}
        onChangeValue={setSearchTerm}
        isCompact
        placeholder={'Filter'}
        waitMs={1}
      />
      <Button onClick={clearExcludeList} appearance={'primary'}>
        {'Show all events'}
      </Button>
      {loading && <LoadingSpinner />}
      {error && <div>{error.message}</div>}
      <ListOuter>
        {data?.eventCategoryMappingList
          ?.filter(
            ({ event }) =>
              !searchTerm || event.toLowerCase().includes(searchTerm),
          )
          .filter(({ category }) => {
            return uniqueCategories.includes(category)
          })
          .map(({ event, category }) => (
            <Item key={event}>
              <Label>
                <Badge
                  tint={CATEGORIES[category].color as Tint}
                  text={CATEGORIES[category].initials}
                />
                {event.replace(/_/g, ' ')}
              </Label>
              <Checkbox
                value={event}
                isChecked={!excludeList.includes(event)}
                onChangeValue={(isChecked) => {
                  setExcludeList(
                    isChecked
                      ? excludeList.filter((e) => e !== event)
                      : uniq([...excludeList, event]),
                  )
                }}
              />
            </Item>
          ))}
      </ListOuter>
    </Outer>
  )
}

export default FilterEditor
