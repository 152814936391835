import React, { useMemo } from 'react'

import Badge, { Tint } from '../../../../components/Badge'
import { TeamStatus } from '../../../../graphql'

type Props = {
  status?: TeamStatus
}

const StatusBadge = ({ status }: Props) => {
  const badgeTint = useMemo(() => {
    switch (status) {
      case TeamStatus.ACTIVE:
        return 'green'
      case TeamStatus.PENDING_CHURN:
        return 'red'
      case TeamStatus.CHURNED:
        return 'grey'
      case TeamStatus.PRE_ONBOARDED:
        return 'yellow'
      default:
        return 'blue'
    }
  }, [status])

  if (!status) {
    return null
  }

  const statusText = status.toString()
  const formattedStatus = statusText.replace('PRE_', 'PRE-').replace('_', ' ')

  return <Badge text={formattedStatus} tint={badgeTint as Tint} />
}

export default StatusBadge
