import { colors, typography } from '@atlaskit/theme'
import { transparentize } from 'polished'
import styled from 'styled-components'

const COLOR = colors.N40
const EMPTY_OPACITY = 0.4

export const Outer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 0px 3px;
  align-items: stretch;
`

export const Step = styled.div`
  display: flex;
  column-gap: 24px;
`

export const IconOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  align-items: center;
`

export const Icon = styled.div<{ $filled: boolean }>`
  flex: 0 0 auto;
  background: ${COLOR};
  border-radius: 50px;
  height: 32px;
  width: 32px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $filled }) => ($filled ? 1 : EMPTY_OPACITY)};
`

export const Line = styled.div<{ $startFilled: boolean; $endFilled: boolean }>`
  flex: 1 0 20px;
  min-height: 7px;
  min-width: 7px;
  margin-top: -2px;
  margin-bottom: -2px;
  border: 0px solid white;
  border-width: 0 2px;
  z-index: 2;
  background: linear-gradient(
    180deg,
    ${({ $startFilled }) =>
        transparentize($startFilled ? 0 : 1 - EMPTY_OPACITY)(COLOR)}
      0%,
    ${({ $endFilled }) =>
        transparentize($endFilled ? 0 : 1 - EMPTY_OPACITY)(COLOR)}
      100%
  );
`

export const StepContent = styled.div`
  flex: 1 1 auto;
  margin-top: -11px;

  & > div > div > span {
    width: 100%;
  }
`

export const StepHeader = styled.header`
  ${typography.h300};
  margin-top: 0;
  justify-content: space-between;
  display: flex;
  gap: 16px;
`

export const StepTitle = styled.span``
