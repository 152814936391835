import { DropdownItem } from '@atlaskit/dropdown-menu'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import { useCallback } from 'react'

import { TestExecFragment } from '../../../graphql'
import { NonNullableRequired } from '../../../types'

import Header from './Header'
import { BodyContainer, Inner } from './styled'

type Props = NonNullableRequired<
  Pick<TestExecFragment, 'lead' | 'matching' | 'user'>
>

const OnboardingView = ({ lead, matching, user }: Props) => {
  const copyUserIdToClipboard = useCallback(() => {
    navigator.clipboard.writeText(user.id)
  }, [user.id])

  return (
    <Inner>
      <Header
        testExec={{ lead, matching, user }}
        actionItems={
          <DropdownItem
            key={'copy-user-id'}
            onClick={copyUserIdToClipboard}
            elemBefore={<CopyIcon size={'small'} label={''} />}
            description={user.id}
          >
            {'Copy User Id'}
          </DropdownItem>
        }
      />
      <BodyContainer>
        {/* // TODO - list teammates using a similar component */}
      </BodyContainer>
    </Inner>
  )
}

export default OnboardingView
