import chunk from 'lodash/chunk'
import { useMemo } from 'react'

import { useListExecutivesStripeCustomerQuery } from '../../../../graphql'
import { notFalsy } from '../../../../lib/utils'
import { ReportsQuery } from '../utils'

import getStripeCustomerInvoicesVariables from './getStripeCustomerInvoicesVariables'

const MIN_PROGRESS = 0.05

const USER_BATCH_SIZE = 100
const MAX_BATCHES_COUNT = 30

const useStripeCustomers = (userIds: string[], query: ReportsQuery) => {
  const userIdChunks = chunk(userIds, USER_BATCH_SIZE)

  const results: ReturnType<typeof useListExecutivesStripeCustomerQuery>[] =
    Array.from({ length: MAX_BATCHES_COUNT }).map((_, index) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useListExecutivesStripeCustomerQuery({
        variables: {
          userIds: userIdChunks[index],
          first: USER_BATCH_SIZE,
          ...getStripeCustomerInvoicesVariables(query.to),
        },
        skip: Boolean(
          !userIdChunks[index],
          // TODO Not sure what was the intention of the following line
          // || (index && !results[index - 1].data),
        ),
      }),
    )

  const progress = useMemo(() => {
    if (userIdChunks.length === 0) return MIN_PROGRESS

    return Math.max(
      MIN_PROGRESS,
      results.slice(0, userIdChunks.length).filter(({ data }) => data).length /
        userIdChunks.length,
    )
  }, [results, userIdChunks.length])

  const items = useMemo(() => {
    return progress < 1
      ? []
      : results
          .map(({ data }) => data?.list.items)
          .filter(notFalsy)
          .flat()
          .filter(notFalsy)
    // ⚠️ KEEP THE SPREAD OF "results"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, ...results]) // I SAID: ⚠️ KEEP THE SPREAD OF "results"

  return useMemo(
    () => ({
      progress,
      loading: progress < 1,
      items,
    }),
    [items, progress],
  )
}

export default useStripeCustomers
