import FilterIcon from '@atlaskit/icon/glyph/filter'

import { TimelineEvent } from '../../../graphql'
import Badge from '../../Badge'
import PopupButton from '../../PopupButton'
import FilterEditor from '../FilterEditor'

import { Outer, RowItem } from './styled'

type Props = {
  children: React.ReactNode
  excludeList: TimelineEvent[]
  setExcludeList: (excludes: TimelineEvent[]) => void
  clearExcludeList: () => void
  uniqueCategories: string[]
}

const TimelineContainer = ({
  children,
  excludeList,
  setExcludeList,
  clearExcludeList,
  uniqueCategories,
}: Props) => {
  const isFiltersEmpty = excludeList.length === 0

  return (
    <Outer>
      <PopupButton
        content={() => (
          <FilterEditor
            excludeList={excludeList}
            setExcludeList={setExcludeList}
            clearExcludeList={clearExcludeList}
            uniqueCategories={uniqueCategories}
          />
        )}
        buttonProps={{
          appearance: 'primary',
          iconAfter: <FilterIcon label={'Filter'} />,
          children: (
            <RowItem>
              {'Timeline Filters'}
              <Badge
                tint={isFiltersEmpty ? 'green' : 'red'}
                text={excludeList.length}
              />
            </RowItem>
          ),
        }}
      />
      {children}
    </Outer>
  )
}

export default TimelineContainer
