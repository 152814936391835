import { useCallback, useMemo } from 'react'

import {
  AirtableLead_BundlePrincipalFragment,
  AirtableLeadFragment,
  useGetAirtableBundlePrincipalByLeadRecordIdLazyQuery,
  useGetTeamLazyQuery,
} from '../../../../graphql'
import useValues, { Setters } from '../../../../lib/useValues'

export enum TeamRadioValue {
  NEW_TEAM = 'NEW_TEAM',
  EXISTING_TEAM = 'EXISTING_TEAM',
}

export const TEAM_RADIO_OPTIONS = [
  {
    value: TeamRadioValue.NEW_TEAM,
    label: 'Create new team',
  },
  {
    value: TeamRadioValue.EXISTING_TEAM,
    label: 'Add to existing team',
  },
]

type TeamValues = {
  teamPrincipalUserId: string | null
  bundlePrincipalId: string | null
  bundlePrincipalUserId: string | null
  plusOneIds: string[]
  teamRadioValue: TeamRadioValue
  existingTeamId: string | null
  newTeamName: string | undefined
}

type Input = {
  onBundlePrincipalChange: (
    leadRecord: AirtableLead_BundlePrincipalFragment,
  ) => void
}

type Output = {
  teamValues: TeamValues
  teamSetters: Setters<TeamValues>
  resetTeamValues: () => void
  onSelectTeam: (teamId: string | null | undefined) => void
  onSelectBundlePrincipalLead: (lead: AirtableLeadFragment | null) => void
  onSelectAirtableTeammate: (leads: AirtableLeadFragment[] | null) => void
  isCreatingNewTeam: boolean
  isTeamValid: boolean
  loading: boolean
}

const useCreateMatchingModalTeam = ({
  onBundlePrincipalChange,
}: Input): Output => {
  const [teamValues, { reset: resetTeamValues, setters: teamSetters }] =
    useValues<TeamValues>(
      {
        teamPrincipalUserId: null,
        bundlePrincipalId: null,
        bundlePrincipalUserId: null,
        plusOneIds: [],
        teamRadioValue: TeamRadioValue.NEW_TEAM,
        existingTeamId: null,
        newTeamName: undefined,
      },
      [
        'teamPrincipalUserId',
        'bundlePrincipalId',
        'bundlePrincipalUserId',
        'plusOneIds',
        'teamRadioValue',
        'existingTeamId',
        'newTeamName',
      ],
    )

  const {
    bundlePrincipalId,
    plusOneIds,
    newTeamName,
    existingTeamId,
    teamRadioValue,
  } = teamValues

  const [
    getAirtableBundlePrincipalByLeadRecordId,
    { loading: getAirtableBundlePrincipalLoading },
  ] = useGetAirtableBundlePrincipalByLeadRecordIdLazyQuery({
    onCompleted: ({
      bundlePrincipal: { name, teammatesForMatching, companyName, userId },
    }) => {
      // populate teammates if none are selected yet
      if (!plusOneIds.length) {
        teamSetters.plusOneIds(
          teammatesForMatching.map((teammate) => teammate.id) || [],
        )
      }

      // set team name if not already set
      if (!newTeamName) {
        teamSetters.newTeamName(companyName || `${name}'s Team`)
      }

      teamSetters.bundlePrincipalUserId(userId || null)
    },
  })

  const [getTeamById, { loading: getTeamLoading }] = useGetTeamLazyQuery({
    onCompleted: ({ team: { principalUser } }) => {
      // populate the team principal AT card
      teamSetters.teamPrincipalUserId(principalUser.id)

      // get lead record details to try to update matching team data
      if (principalUser.airtableLeadRecordId) {
        getAirtableBundlePrincipalByLeadRecordId({
          variables: {
            leadRecordId: principalUser.airtableLeadRecordId,
          },
        })
      }
    },
  })

  const updateBundlePrincipalId = useCallback(
    async (leadId: string | null) => {
      teamSetters.bundlePrincipalId(leadId)

      // don't update matching form if no bundle principal is selected or if an existing team is selected
      if (!leadId || existingTeamId) return

      // get lead record details to try to update matching form
      const { data } = await getAirtableBundlePrincipalByLeadRecordId({
        variables: {
          leadRecordId: leadId,
        },
      })
      if (!data?.bundlePrincipal) return

      onBundlePrincipalChange(data.bundlePrincipal)
    },
    [
      existingTeamId,
      getAirtableBundlePrincipalByLeadRecordId,
      onBundlePrincipalChange,
      teamSetters,
    ],
  )

  const onSelectBundlePrincipalLead = useCallback(
    (lead: AirtableLeadFragment | null) =>
      updateBundlePrincipalId(lead?.id || null),
    [updateBundlePrincipalId],
  )

  const onSelectAirtableTeammate = useCallback(
    (leads: AirtableLeadFragment[] | null) =>
      teamSetters.plusOneIds(leads?.map((lead) => lead.id) || []),
    [teamSetters],
  )

  const onSelectTeam = useCallback(
    (teamId: string | null | undefined) => {
      teamSetters.existingTeamId(teamId || null)
      teamSetters.teamPrincipalUserId(null)

      if (!teamId) return

      // try to populate the team principal AT card
      getTeamById({
        variables: {
          id: teamId,
        },
      })
    },
    [getTeamById, teamSetters],
  )

  const isCreatingNewTeam = useMemo(
    () => teamRadioValue === TeamRadioValue.NEW_TEAM,
    [teamRadioValue],
  )

  const isTeamValid = useMemo(
    () =>
      Boolean(
        isCreatingNewTeam
          ? bundlePrincipalId
          : plusOneIds.length && existingTeamId,
      ),
    [bundlePrincipalId, existingTeamId, isCreatingNewTeam, plusOneIds.length],
  )

  return {
    teamValues,
    teamSetters,
    resetTeamValues,
    onSelectTeam,
    onSelectBundlePrincipalLead,
    onSelectAirtableTeammate,
    isCreatingNewTeam,
    isTeamValid,
    loading: getAirtableBundlePrincipalLoading || getTeamLoading,
  }
}

export default useCreateMatchingModalTeam
